import { combineReducers } from 'redux';
import { reducer as bucketReducer } from './components/Buckets/reducer';
import { reducer as userReducer } from './components/Users/reducer';
import { reducer as permissionReducer } from './components/Permissions/reducer';

export const reducer = combineReducers({
  bucket: bucketReducer,
  user: userReducer,
  permission: permissionReducer,
});
