import React from "react";
import {useLocation, useHistory, useParams} from "react-router-dom";
import moment, { Moment } from "moment-timezone";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import cx from "classnames";

import api from "../../../../../../services/api/index";
import { isAllowedRole } from "../../../../../../utils";
import {AppState, useAppDispatch, useAppSelector} from "../../../../../../store";
import { WhoAmI } from "../../../../../../state/UserInfo/types";
import LyveTabs from "../../../../../../components/LyveTabs";
import { LyveDateRangeSelect } from "../../../../../../components/LyveDropdown";
import {
  timezone,
  getCurrentMonthStartDate,
  isDaily,
  convertFileSize,
  getFrequencyByRange,
  getLastMonthsDate,
  sortByDateTime
} from "../../../../../../utils";
import { DefaultButton } from "../../../../../../components/LyveButton";
import { UsageFrequency } from "../../../Metrics/hooks/useUsageMetric";
import { rules, SUBACCOUNTS_MANAGE, SUBACCOUNTS_VIEW } from "../../../../../../authRules";
import { convertFileSizeInGB } from "../../../Metrics";
import Usage from "./components/Usage";
import Settings from "./components/Settings";
import { IListOption } from "../../../../../../components/LyveDropdown/LyveDateRangeSelect/LyveDateRangeSelect";

import "./styles.scss";
import { Spinner } from "react-bootstrap";
import { ChevronCompactRight } from "react-bootstrap-icons";
import Tabs from "../../../../../../components/Tabs";
import {
  errorMasterAccountInfo,
  fetchingMasterAccountInfo,
  saveMasterAccountInfo
} from "../../../../../../state/Subaccounts/actions";

interface Subaccount {
  id: string;
  name: string;
  short_name: string;
  admin_first_name: string;
  admin_last_name: string;
  admin_email: string;
  org_name: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  phone_number: string;
  parent_short_name: string;
  is_master: boolean;
  is_enabled: boolean;
  created_date: string;
  start_date: string;
  end_date: string;
  account_type: string;
  sub_account_max_limit: number;
  sub_account_support: boolean;
  sub_account_trial: boolean;
  sub_account_trial_ext_limit: number;
  sub_account_diff_org: boolean;
  view_support_ticket: boolean;
  created_by?: string
}

interface ICostUsage {
  time: string;
  usage?: number;
  cost?: number;
}

interface IBucketUsageResponse {
  time: string;
  usage?: string;
  bucket_name: string;
  region_name: string;
}

interface ISubaccountUsage {
  time: string;
  usageInByte: string;
  usageInGB: string;
  bucket_name: string;
  region_name: string;
}

const View: React.FC = () => {
  const history = useHistory();
  const location: any = useLocation();
  const whoAmI = useSelector<AppState, WhoAmI>(state => state.userInfo.whoAmI);
  let { masterAccount } = useAppSelector(
    (state: AppState) => state.subaccounts
  );
  const dispatch = useAppDispatch();
  const role = `${whoAmI?.type}-${whoAmI?.role}`;

  const [loading, setLoading] = React.useState(false);

  const [subaccount, setSubaccount] = React.useState<Subaccount | null>(null);
  const [createdBy, setCreatedBy] = React.useState("");
  const [averageUsage, setAverageUsage] = React.useState<string>("0B");
  const [subaccountUsageXData, setAccountUsageXData] = React.useState<
    Array<string | null>
  >([]);
  const [subaccountUsageYData, setAccountUsageYData] = React.useState<
    Array<number | null>
  >([]);
  const [
    subaccountBucketUsageData,
    setSubaccountBucketUsageData
  ] = React.useState<ISubaccountUsage[]>([]);
  const [totalBuckets, setTotalBuckets] = React.useState<number | string>(0);
  const [
    subaccountCostUsageData,
    setSubaccountCostUsageData
  ] = React.useState();
  const subaccountDownloadBtnRef: any = React.useRef(null);

  let yearsCount = subaccount?.created_date
    ? moment(subaccount.created_date).get("year") < moment().get("year")
      ? 2
      : 1
    : 1;

  let dateOptions: IListOption[] = [
    {
      label: "This month",
      value: 0,
      calendarType: "year",
      selectsRange: false,
      min: subaccount?.created_date
        ? moment(subaccount?.created_date)
        : undefined,
      max: moment(),
      yearsCount
    },
    {
      label: "Last 6 months",
      value: 1,
      calendarType: "year",
      selectsRange: true,
      min: subaccount?.created_date
        ? moment(subaccount?.created_date)
        : undefined,
      max: moment(),
      yearsCount
    },
    {
      label: "Custom time range",
      value: 2,
      calendarType: "year",
      selectsRange: true,
      min: subaccount?.created_date
        ? moment(subaccount?.created_date)
        : undefined,
      max: moment(),
      yearsCount,
      isCustom: true
    }
  ];

  const [startDate, setStartDate] = React.useState<Moment | undefined>(
    getCurrentMonthStartDate(subaccount?.created_date)
  );
  const [endDate, setEndDate] = React.useState<Moment | undefined>(moment());

  const [
    subaccountSelectedOption,
    setSubaccountSelectedOption
  ] = React.useState<IListOption>({
    ...dateOptions[0],
    startDate: undefined,
    endDate
  });

  const [frequency, SetFequency] = React.useState<UsageFrequency>(
    UsageFrequency.DAILY
  );

  let { sub_account_short_name } = useParams<any>();
  React.useEffect(() => {
    if (!sub_account_short_name) {
      history.push("/customer/subaccounts");;
    }
    if (!masterAccount) {
      if (whoAmI?.company_short_name) {
        fetchingMasterAccountInfo();
        api
            .getSubaccount(whoAmI?.company_short_name)
            .then(res => {
              if (res) {
                dispatch(saveMasterAccountInfo(res));
                masterAccount = res;
              }
            })
            .catch(err => {
              errorMasterAccountInfo(err);
            });
      }
    }
  }, []);

  const constructCostMetricsData = (costMetrics: ICostUsage[]) => {
    const costMetricsData: ICostUsage[] = [];

    if (!costMetrics || costMetrics.length < 1) {
      return costMetricsData;
    }

    if (frequency === UsageFrequency.DAILY) {
      costMetrics.forEach(data => {
        costMetricsData.push({
          ...data,
          usage: data.usage ? parseFloat(data.usage.toString()) : undefined
        });
      });
    } else {
      costMetrics.forEach(data => {
        costMetricsData.push({
          ...data,
          usage: data.usage ? parseFloat(data.usage.toString()) : undefined,
          cost: data.cost ? parseFloat(data.cost.toString()) : undefined
        });
      });
    }

    return costMetricsData;
  };

  const generateSubaccountUsageData = (subaccountUsageList: ICostUsage[]) => {
    const inputFormat = "YYYY-MM-DD hh:mm:ss";
    const xdata: Array<string | null> = [];
    const ydata: Array<number | null> = [];
    const start = moment(startDate);
    const end = moment(endDate);

    const frequency = getFrequencyByRange(start, end);

    const sortedUsageList = subaccountUsageList.sort(
      sortByDateTime({ param: "time" })
    );

    if (subaccountUsageList.length) {
      if (frequency === UsageFrequency.DAILY) {
        let limit = moment(startDate);
        let index = 0;
        while (limit <= end.endOf("day")) {
          const isDateMatch =
            moment(sortedUsageList[index]?.time, inputFormat)
              .startOf("day")
              .format(inputFormat) === limit.startOf("day").format(inputFormat);

          // For creating x points
          xdata.push(isDateMatch ? limit.format(inputFormat) : null);

          // For creating y points
          if (isDateMatch && sortedUsageList[index]?.usage !== undefined) {
            ydata.push(Number(sortedUsageList[index].usage));
            index++;
          } else {
            ydata.push(null);
          }
          limit.add(1, "day");
        }
      } else {
        let limit = moment(sortedUsageList[0].time, inputFormat);
        let index = 0;
        while (limit <= end.endOf("month")) {
          const isDateMatch =
            moment(sortedUsageList[index]?.time, inputFormat)
              .startOf("month")
              .format("MM YYYY") === limit.startOf("month").format("MM YYYY");

          // For creating x points
          xdata.push(isDateMatch ? limit.format(inputFormat) : null);

          // For creating y points
          if (isDateMatch && sortedUsageList[index]?.usage !== undefined) {
            ydata.push(Number(sortedUsageList[index].usage));
          } else {
            ydata.push(null);
          }
          if (isDateMatch) {
            index++;
          }
          limit.add(1, "month");
        }
      }
    }
    setAccountUsageXData(xdata);
    setAccountUsageYData(ydata);
  };

  const generateBucketUsageData = (bucketUsageList: IBucketUsageResponse[]) => {
    const inputFormat = "YYYY-MM-DD hh:mm:ss";
    const newBucketUsage: ISubaccountUsage[] = [];

    if (bucketUsageList.length) {
      bucketUsageList.forEach(bucketUsage => {
        newBucketUsage.push({
          time: moment(bucketUsage.time, inputFormat).format(
            isDailyUsage ? "YYYY-MM-DD" : "MMM-YYYY"
          ),
          bucket_name: bucketUsage.bucket_name,
          region_name: bucketUsage?.region_name ?? "",
          usageInByte: bucketUsage.usage ? bucketUsage.usage : "",
          usageInGB: bucketUsage.usage
            ? convertFileSizeInGB(bucketUsage.usage)
            : ""
        });
      });
    }

    setSubaccountBucketUsageData(newBucketUsage);
  };

  const getCreatedBy = async (apiCreatedBy?: string) => {
    if (!!apiCreatedBy) {
      if (apiCreatedBy?.toLowerCase() === "system") {
        setCreatedBy("System")
      } else {
        const data = await api.listUsers()
        const createdBy = (data?.users || []).find(
          (user: any) => user?.email === apiCreatedBy
        )
        if (createdBy) {
          setCreatedBy(`${createdBy?.first_name} ${createdBy?.last_name}`)
        }
      }
    }
  }
  
  React.useEffect(() => {
    if (
      (isAllowedRole(rules, role, SUBACCOUNTS_MANAGE) || isAllowedRole(rules, role, SUBACCOUNTS_VIEW)) &&
        sub_account_short_name
    ) {
      setLoading(true)
      api
        .getSubaccount(sub_account_short_name)
        .then(async res => {
          if (res) {
            setSubaccount(res)
            await getCreatedBy(res?.created_by)
          }
        })
        .catch(err => {
          history.push("/customer/subaccounts");
        }).finally(() => {
          setLoading(false)
      });
      return;
    }
  }, []);

  React.useEffect(() => {
    if (subaccount) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      yearsCount = subaccount?.created_date
        ? moment(subaccount.created_date).get("year") < moment().get("year")
          ? 2
          : 1
        : 1;

      // eslint-disable-next-line react-hooks/exhaustive-deps
      dateOptions = [
        {
          label: "This month",
          value: 0,
          calendarType: "year",
          selectsRange: false,
          min: subaccount?.created_date
            ? moment(subaccount?.created_date)
            : undefined,
          max: moment(),
          yearsCount
        },
        {
          label: "Last 6 months",
          value: 1,
          calendarType: "year",
          selectsRange: true,
          min: subaccount?.created_date
            ? moment(subaccount?.created_date)
            : undefined,
          max: moment(),
          yearsCount
        },
        {
          label: "Custom time range",
          value: 2,
          calendarType: "year",
          selectsRange: true,
          min: subaccount?.created_date
            ? moment(subaccount?.created_date)
            : undefined,
          max: moment(),
          yearsCount,
          isCustom: true
        }
      ];
      setStartDate(getCurrentMonthStartDate(subaccount?.created_date));
      setSubaccountSelectedOption({
        ...dateOptions[0],
        startDate,
        endDate
      });
    }
  }, [subaccount]);

  React.useEffect(() => {
    if (subaccount) {
      api.getSubaccountBuckets(subaccount.id).then(res => {
        setTotalBuckets(res.total_buckets);
      });
    }
  }, [subaccount]);

  React.useEffect(() => {
    if (
      subaccount &&
      subaccountSelectedOption?.startDate &&
      subaccountSelectedOption?.endDate
    ) {
      const fromDate = moment(startDate).format("YYYY-MM-DD");
      const toDate = moment(endDate).format("YYYY-MM-DD");
      api
        .getSubaccountCostMetrics(
          subaccount.id,
          fromDate,
          toDate,
          frequency,
          timezone
        )
        .then(res => {
          const data = constructCostMetricsData(res?.cost_metrics ?? []);
          setSubaccountCostUsageData(res?.cost_metrics ?? []);
          generateSubaccountUsageData(data);
          setAverageUsage(convertFileSize(res?.current_month_avg_usage ?? 0));
        })
        .catch(err => {
          generateSubaccountUsageData([]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subaccount, subaccountSelectedOption]);

  const formatDate = (subaccount: Subaccount) => {
    return moment
        .utc(subaccount.created_date)
        .local()
        .format("YYYY-MM-DD hh:mm A");
  };

  const handleSubaccountValueChange = (option: IListOption) => {
    setSubaccountSelectedOption(option);
    if (option.value === 0) {
      setStartDate(getCurrentMonthStartDate(subaccount?.created_date));
      setEndDate(moment());
    } else if (option.value === 1) {
      setStartDate(getLastMonthsDate(6, subaccount?.created_date));
      setEndDate(moment());
    } else {
      setStartDate(
        subaccountSelectedOption.value !== option.value
          ? undefined
          : subaccountSelectedOption.startDate
      );
      setEndDate(
        subaccountSelectedOption.value !== option.value
          ? undefined
          : subaccountSelectedOption.endDate
      );
    }
  };

  const getUpdatedOptions = () =>
    dateOptions.map(option =>
      option.value === subaccountSelectedOption.value
        ? subaccountSelectedOption
        : option
    );

  const subaccountRangeOptions = getUpdatedOptions();

  const handleSubaccountDateClick = (range: (Moment | undefined)[]) => {
    const custom = dateOptions.find(option => option.isCustom);
    if (custom) {
      setSubaccountSelectedOption(custom);
      setStartDate(range[0]);
      setEndDate(range[1]);
    }
  };

  const handleSubaccountChange = (option: IListOption) => {
    setSubaccountSelectedOption({
      ...subaccountSelectedOption,
      startDate,
      endDate
    });
    SetFequency(getFrequencyByRange(moment(startDate), moment(endDate)));
    setSubaccountBucketUsageData([]);
  };

  const handleSubaccountCancel = (option: IListOption) => {
    const {
      startDate: prevStartDate,
      endDate: prevEndDate,
      ...restSubaccountSelectedOption
    } = subaccountSelectedOption;
    setSubaccountSelectedOption(restSubaccountSelectedOption);
    setStartDate(prevStartDate);
    setEndDate(prevEndDate);
  };

  const isDailyUsage = isDaily(
    subaccountSelectedOption.startDate,
    subaccountSelectedOption.endDate
  );

  const downloadSubaccountBucketUsage = () => {
    if (startDate !== undefined && endDate !== undefined && subaccount) {
      const fromDate = moment(startDate).format("YYYY-MM-DD");
      const toDate = moment(endDate).format("YYYY-MM-DD");

      api
        .downloadSubaccountBucketUsage(
          subaccount.id,
          fromDate,
          toDate,
          frequency,
          timezone
        )
        .then(res => {
          if (res.bucket_usage && res.bucket_usage.length) {
            generateBucketUsageData(res.bucket_usage);
          } else {
            generateBucketUsageData([]);
          }
          subaccountDownloadBtnRef?.current?.link?.click();
        });
    }
  };

  const getSubaccountFileName = (startDate: string, endDate: string) => {
    const start = moment(startDate);
    const end = moment(endDate);
    if (isDailyUsage) {
      return `subaccount-report-${start.format("MMMM-YYYY")}.csv`;
    } else {
      return `subaccount-report-${start.format("MMMM-YYYY")}-${end.format(
        "MMMM-YYYY"
      )}.csv`;
    }
  };

  const CSVHeaders = [
    { label: `${isDailyUsage ? "Date" : "Month"}`, key: "time" },
    { label: "Region Name", key: "region_name" },
    { label: "Bucket Name", key: "bucket_name" },
    { label: "Usage (byte)", key: "usageInByte" },
    { label: "Usage (GB)", key: "usageInGB" }
  ];

  const usageContent = () => {
    if (!!subaccount) {
      return (
        <>
          <div className="dashboard-graph subaccount-view-report">
            <div className="dashboard-graph-title">
              <span>Reports</span>
            </div>
            <div className="d-flex">
              <LyveDateRangeSelect
                startDate={startDate}
                endDate={endDate}
                value={subaccountSelectedOption}
                onChange={handleSubaccountValueChange}
                onDateClick={handleSubaccountDateClick}
                isSearchable={false}
                defaultValue={subaccountSelectedOption}
                options={subaccountRangeOptions}
                onSubmit={handleSubaccountChange}
                onCancel={handleSubaccountCancel}
              />
              <DefaultButton
                variant="outline-secondary"
                onClick={downloadSubaccountBucketUsage}
                className="csv-download-btn"
              >
                Download
              </DefaultButton>
              <CSVLink
                data={subaccountBucketUsageData}
                ref={subaccountDownloadBtnRef}
                className="csv-download-link"
                target="_blank"
                headers={CSVHeaders}
                filename={getSubaccountFileName(
                  startDate?.toString() ?? "",
                  endDate?.toString() ?? ""
                ).toLowerCase()}
              />
            </div>
          </div>
          <Usage
            subaccount={subaccount}
            subaccountXData={subaccountUsageXData}
            subaccountYData={subaccountUsageYData}
            frequency={frequency}
            subaccountUsageList={subaccountCostUsageData}
            isDailyUsage={isDailyUsage}
          />
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="account-subaccount-wrapper">
      <div className="lyve-breadcrumb">
        <DefaultButton
          onClick={() => history.push("/customer/subaccounts")}
          variant="link"
          className="text-btn"
        >
          Sub-accounts
        </DefaultButton>
        <ChevronCompactRight fill="var(--gray-10)" />
      </div>
      {loading ? (
        <div className="data-loader">
          <Spinner animation="border" role="status" className="spinner">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div className="heading-area">
            <div className={cx("main-header", { "m-remove": true })}>{subaccount?.name}</div>
            <p className="sub-header">{subaccount?.short_name}</p>
          </div>
          <div className="subaccount-metrics">
            <div className="metrics-column">
              <p className="column-title">Month-to-date Usage</p>
              <p className="column-value">
                {averageUsage}
                {/* <span>MoM +10%</span> */}
              </p>
            </div>
            <div className="metrics-column">
              <p className="column-title">Buckets</p>
              <p className="column-value">{totalBuckets}</p>
            </div>
            <div className="metrics-column">
              <p className="column-title">Created On</p>
              <p className="column-value">{subaccount && formatDate(subaccount)}</p>
            </div>
            <div className="metrics-column">
              <p className="column-title">Created By</p>
              <p className="column-value">
                {createdBy}
              </p>
            </div>
          </div>
          {!!subaccount && (
            <Tabs
              defaultActiveKey="usage"
              id="subaccount-tabs"
              tabs={[
                {
                  eventKey: "usage",
                  title: "Usage",
                  content: usageContent()
                },
                {
                  eventKey: "settings",
                  title: "Settings",
                  content: <Settings subaccount={subaccount} masterAccount={masterAccount} />
                }
              ]}
            />
          )}
        </>
      )}
    </div>
  );
};

export default View;
