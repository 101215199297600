import {
  SET_NOTIFICATION,
  SET_ERROR_NOTIFICATION,
  CLEAR_NOTIFICATION,
  NotificationState,
  NotificationActionTypes,
  SET_WARNING_NOTIFICATION
} from "./types";

const initialState: NotificationState = {
  show: false,
  message: "",
  error: false,
  warning: false
};

export function reducer(state = initialState, action: NotificationActionTypes) {
  switch (action.type) {
    case SET_NOTIFICATION:
      return {
        show: true,
        message: action.message,
        error: false,
        warning: false
      };
    case SET_ERROR_NOTIFICATION:
      return {
        show: true,
        message: action.message,
        error: true,
        warning: false
      };
    case SET_WARNING_NOTIFICATION:
      return {
        show: true,
        message: action.message,
        error: false,
        warning: true
      };
    case CLEAR_NOTIFICATION:
      return {
        show: false,
        message: "",
        error: false,
        warning: false
      };
    default:
      return state;
  }
}
