import React from "react";
import { DefaultButton } from "../../../../../../../components/LyveButton";
import { ReplicationRule } from "../../../types";
import Spinner from "react-bootstrap/Spinner";
import { Region } from "../../../index";
import regionApis from "../../../../../../../services/RegionApis";
import "./styles.scss";

interface Props {
  bucketName: string;
  hideModal: () => void;
  onSuccess?: (versioned: boolean) => void;
  onError?: () => void;
  replicationRule: ReplicationRule | null;
  regionList: Region[];
  regionName: string;
  region: string;
}

const DeleteReplicationRulePrompt: React.FC<Props> = ({
  bucketName,
  hideModal,
  replicationRule,
  onSuccess,
  regionList,
  regionName
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const deleteReplicationRule = async () => {
    if (replicationRule) {
      setIsSubmitting(true);
      setError(null);
      try {
        const regionUrlList = regionList.map(regionObj =>
          regionObj.name === regionName ? regionObj.url : ""
        );
        const regionUrl = regionUrlList.find(url => url !== "");
        await regionApis.deleteReplicationRule(
          regionUrl || "",
          replicationRule.source_bucket_name,
          replicationRule.target_bucket_name
        );
        const details = await regionApis.getBucketDetails(
          regionUrl || "",
          bucketName
        );
        if (typeof onSuccess !== "undefined") {
          onSuccess(details.versioned ? details.versioned : false);
        }
        hideModal();
        setIsSubmitting(false);
      } catch (error) {
        setError("Deleting Replication Rule failed.");
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div className="delete-rule-module">
      <h4 className="delete-rule-title">
        Are you sure you want to delete this replication rule?
      </h4>
      <div className="delete-rule-subtitle">
        This action will permanently delete the replication rule.
      </div>
      <div className="rule-label">Source Bucket</div>
      <div className="rule-name">
        {replicationRule ? replicationRule.source_bucket_name : ""}
      </div>
      <div className="rule-label">Destination Bucket</div>
      <div className="rule-name">
        {replicationRule ? replicationRule.target_bucket_name : ""}
      </div>
      {error && <div className="text-danger">{error}</div>}
      <div>
        <DefaultButton
          className="mr-2"
          variant="danger"
          disabled={isSubmitting}
          onClick={() => {
            deleteReplicationRule();
          }}
        >
          {isSubmitting ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-1"
            />
          ) : (
            "Yes"
          )}
        </DefaultButton>
        <DefaultButton
          disabled={isSubmitting}
          variant="outline-secondary"
          onClick={hideModal}
        >
          No
        </DefaultButton>
      </div>
    </div>
  );
};

export default DeleteReplicationRulePrompt;
