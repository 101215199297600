import React, { useState, useRef } from "react";
import { CardDeck } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { QuestionCircleFill } from "react-bootstrap-icons";
import {
  getPartnerDetails,
  checkWorkerSupport,
  checkIfIDBIsAvailable
} from "../../Helpers/SupportHelpers";
import salesforceApi from "../../../../../../services/SalesforceAPI";

// Models
import { TicketCounts } from "../../Models/TicketCounts";
import { Ticket } from "../../Models/Ticket";

import listSubject, {
  ListUpdateObserver
} from "../../Helpers/ListUpdateObserver";
import idbHelper from "../../Helpers/IDBHelper";
import WebWorker from "../../Helpers/WebWorker";

import common from "../../../../../../services/common";
import { MetricCard } from "../../../../../../components/Card";
import Can from "../../../../../../components/Can";
import { SLA_VIEW } from "../../../../../../authRules";
import AvailabilityCard from "./AvailabilityCard";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../../store";
import { WhoAmI } from "../../../../../../state/UserInfo/types";

const SupportTicketCount: React.FC<{
  isTicketsEmpty?: (status: boolean) => void;
  propTicketsCount?: TicketCounts;
}> = props => {
  const whoAmI = useSelector<AppState, WhoAmI>(state => state.userInfo.whoAmI);
  // MARK: - Variables
  const [ticketCount, setTicketCount] = useState<TicketCounts>(
    props.propTicketsCount || {
      resolved: 0,
      inprogress: 0,
      new: 0,
      total: 0
    }
  );
  const [isLoading, setIsLoading] = useState(false);

  // MARK:- __getTicketCountFromSalesforce
  const __getTicketCountFromSalesforce = (
    partnerName: string = "",
    customerName: string = "",
    isAnyCustomer: boolean = false
  ) => {
    return new Promise<TicketCounts>((resolve, reject) => {
      salesforceApi
        .getTicketCount(partnerName, customerName, isAnyCustomer)
        .then(res => {
          let counter: TicketCounts = {
            resolved: 0,
            inprogress: 0,
            new: 0,
            total: 0
          };

          counter.new = res.data.TotalNewCount || 0;
          counter.inprogress = res.data.TotalInProgressCount || 0;
          counter.resolved = res.data.TotalResolvedCount || 0;
          counter.total = counter.new + counter.inprogress + counter.resolved;

          resolve(counter);
        })
        .catch(err => reject(err));
    });
  };

  // MARK:- getTicketCount
  const getTicketCount = async (): Promise<TicketCounts> => {
    try {
      const partnerDetails = getPartnerDetails(whoAmI);
      let ticketCounts: TicketCounts;
      if (common.isAnyCustomer(partnerDetails.user_type)) {
        // Need to change according to tentant
        ticketCounts = await __getTicketCountFromSalesforce(
          partnerDetails.company_name,
          "",
          true
        );
      } else {
        ticketCounts = await __getTicketCountFromSalesforce(
          partnerDetails.company_name,
          ""
        );
      }
      return Promise.resolve(ticketCounts);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  // UPDATE Ticket count in header via observer
  const onListUpdated: ListUpdateObserver = async (updated: boolean) => {
    // console.log("--------------------------");
    // console.log("%cList Updating from SupportTicketCount Component", "color:skyblue;font-weight: bold;");
    try {
      setIsLoading(true);
      const counter = await getTicketCount();
      setTicketCount({
        ...ticketCount,
        ...counter
      });
      setIsLoading(false);

      if (typeof props.isTicketsEmpty !== "undefined") {
        counter.total === 0
          ? props.isTicketsEmpty(true)
          : props.isTicketsEmpty(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  // Get Tickets fron IDB
  // const getAllTicketsIDB = async () => {
  //   try {
  //     let tickets = await idbHelper.__retriveTicketsIDB();
  //     // console.log("--------------allTickets IDB")
  //     // console.log(tickets)
  //     return Promise.resolve(tickets);
  //   } catch (error) {
  //     console.log(error);
  //     return Promise.reject(error);
  //   }
  // };

  // Web worker to get ticket list from IDB
  // function ticketWorkerMessage(currentWorker: MessageEvent) {
  //   // console.log("---Header Update---")
  //   // console.log(currentWorker.data)

  //   const getDataFromDB = async () => {
  //     // console.log("---Updating Header DONE---")

  //     try {
  //       let allTickets: Array<Ticket> = [];
  //       if (checkWorkerSupport()) {
  //         allTickets = await getAllTicketsIDB();
  //         if (allTickets.length === 0) {
  //           // console.log("%cNo data in IDB", "font-weight: 500;color: orange;")
  //           getTicketCount();
  //         } else {
  //           // console.log("--------------allTickets")
  //           // console.log(allTickets)

  //           let counter: TicketCounts = {
  //             resolved: 0,
  //             inprogress: 0,
  //             new: 0,
  //             total: 0
  //           };

  //           let newCounter = 0;
  //           let inProgressCounter = 0;
  //           let resolvedCounter = 0;

  //           allTickets.forEach(function(arrayItem) {
  //             var state = arrayItem.State;
  //             if (state === "New") {
  //               newCounter = newCounter + 1;
  //             } else if (state === "In Progress") {
  //               inProgressCounter = inProgressCounter + 1;
  //             } else if (state === "Resolved") {
  //               resolvedCounter = resolvedCounter + 1;
  //             }
  //           });

  //           counter.new = newCounter;
  //           counter.inprogress = inProgressCounter;
  //           counter.resolved = resolvedCounter;
  //           counter.total = allTickets.length;
  //           if (isMounted.current) {
  //             setTicketCount({
  //               ...ticketCount,
  //               ...counter
  //             });
  //           }
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getDataFromDB();
  // }

  // function onError(e: ErrorEvent) {
  //   // console.log('ERROR: Line ', e.lineno, ' in ', e.filename, ': ', e.message);
  //   // console.log("ticketWorker Error Count");
  //   // console.log(e);
  // }

  let isMounted = useRef(false);
  React.useEffect(() => {
    isMounted.current = true;
    // Attach Observer
    listSubject.attach(onListUpdated);

    // Call functon to check if IDB is available
    // let isAvailabeIDB;

    const fetchData = async () => {
      // console.log("Called");
      try {
        if (!props.propTicketsCount) {
          setIsLoading(true);
        }
        // let allTickets: Array<Ticket> = [];
        const counter = await getTicketCount();
        setTicketCount({
          ...ticketCount,
          ...counter
        });
        setIsLoading(false);

        // if (checkWorkerSupport()) {
        //   isAvailabeIDB = await checkIfIDBIsAvailable();
        //   // console.log("checkIfIDBIsAvailable : " + isAvailabeIDB);

        //   // If avaialble get from IDB or call Salesfoece
        //   if (isAvailabeIDB) {
        //     allTickets = await getAllTicketsIDB();
        //     // console.log(allTickets);

        //     if (allTickets.length === 0) {
        //       console.log(
        //         "%cNo data in IDB",
        //         "font-weight: 500;color: orange;"
        //       );
        //       const counter = await getTicketCount();
        //       setTicketCount({
        //         ...ticketCount,
        //         ...counter
        //       });
        //       setIsLoading(false);
        //     } else {
        //       // console.log("--------------allTickets")
        //       // console.log(allTickets)

        //       let counter: TicketCounts = {
        //         resolved: 0,
        //         inprogress: 0,
        //         new: 0,
        //         total: 0
        //       };

        //       let newCounter = 0;
        //       let inProgressCounter = 0;
        //       let resolvedCounter = 0;

        //       allTickets.forEach(function(arrayItem) {
        //         var state = arrayItem.State;
        //         if (state === "New") {
        //           newCounter = newCounter + 1;
        //         } else if (state === "In Progress") {
        //           inProgressCounter = inProgressCounter + 1;
        //         } else if (state === "Resolved") {
        //           resolvedCounter = resolvedCounter + 1;
        //         }
        //       });

        //       counter.new = newCounter;
        //       counter.inprogress = inProgressCounter;
        //       counter.resolved = resolvedCounter;
        //       counter.total = allTickets.length;

        //       setTicketCount({
        //         ...ticketCount,
        //         ...counter
        //       });
        //       setIsLoading(false);
        //     }
        //   } else {
        //     // Get from Salesforce
        //     console.log("IDB not available getting tickets from Salesforce");
        //     const counter = await getTicketCount();
        //     setTicketCount({
        //       ...ticketCount,
        //       ...counter
        //     });
        //     setIsLoading(false);
        //   }
        // } else {
        // const counter = await getTicketCount();
        // setTicketCount({
        //   ...ticketCount,
        //   ...counter
        // });
        // setIsLoading(false);
        // }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchData();

    // if (checkWorkerSupport()) {
    //   const [ticketWorker] = WebWorker([TicketWorker]);

    //   const token: string = localStorage.getItem("token") || "";
    //   const company_name: string = localStorage.getItem("company_name") || "";
    //   const customer: string = localStorage.getItem("customer") || "";
    //   const user_type: string = localStorage.getItem("user_type") || "";
    //   let direct_customer_str: string =
    //     localStorage.getItem("direct_customer") || "false";
    //   let direct_customer: boolean = JSON.parse(direct_customer_str);

    //   ticketWorker.addEventListener("message", ticketWorkerMessage);
    //   ticketWorker.addEventListener("error", onError);
    //   ticketWorker.postMessage({
    //     type: "START",
    //     partner: company_name,
    //     customer: customer,
    //     token: token,
    //     user_type: user_type,
    //     direct_customer: direct_customer
    //   });

    //   return () => {
    //     ticketWorker.postMessage("STOP");
    //   };
    // }

    return () => {
      listSubject.detach(onListUpdated);
      isMounted.current = false;
    };
  }, []);

  return (
    <CardDeck className="support-ticket-count-root">
      <MetricCard
        label="New Tickets"
        value={ticketCount.new}
        loading={isLoading}
      />
      <MetricCard
        value={ticketCount.inprogress}
        label="In Progress Tickets"
        loading={isLoading}
      />

      <Can perform={SLA_VIEW}>
        <AvailabilityCard />
      </Can>
    </CardDeck>
  );
};

export default SupportTicketCount;
