import React from "react";
import { Spinner } from "react-bootstrap";

interface IOwnProps {
    animation: "border" | "grow";
    classNames?: string;
}

const DefaultLoader: React.FC<IOwnProps> = (props) => {
    return (
        <div className="spinner-container">
            <Spinner animation={props.animation} role="status" className={`lyve-spinner ${typeof props.classNames !== "undefined" ? props.classNames : ""}`}>
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>
    );
};

export default DefaultLoader;