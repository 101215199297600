import React, { useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import Link from "@material-ui/core/Link";
import AgreeTerms from "../../../../../Login/components/AgreeTerms";
import lyvecloudLogo from "../../../../../../images/LyveCloud-CenterAlign.png";
import api from "../../../../../../services/api";

import "./styles.scss";

interface Props {}

const NotificationUnsubscribe = (props: Props) => {
  const [openAgreeTerms, setOpenAgreeTerms] = useState(false);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [code, setCode] = useState<string>("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code") || ""; // email&token&orgname
    const values = atob(code).split("&");
    setEmail(values[0]);
    setCode(code);
  }, []);

  const unsubscribe = () => {
    if (email !== "") {
      api
        .notificationUnsubscribe(code)
        .then(() => {
          setUnsubscribed(true);
        })
        .catch(err => {
          if (err !== 200) {
            setError(
              `Unable to unsubscribe lyve cloud notification for email ${email}`
            );
          }
        });
    } else {
      setError("Email not found");
    }
  };

  return (
    <div className="notification-unsub">
      <div className="d-flex flex-column" style={{ width: "40%" }}>
        <Card className="text-center card">
          <div className="d-flex flex-column justify-content-center">
            <div className="logo1">
              <img src={lyvecloudLogo} width="100px" height="40px" />
            </div>
            {error ? (
              <div className="body">{error}</div>
            ) : unsubscribed ? (
              <>
                <div className="title">You have successfully unsubscribed</div>
                <div className="body">
                  Your email address {email} has been removed from our
                  notification list.
                </div>
              </>
            ) : (
              <>
                <div className="title">Unsubscribe</div>
                <div className="body">
                  Are you sure you want to unsubscribe{" "}
                  <span className="email">{email}</span> from Lyve Cloud
                  notifications?
                </div>
                <div className="btn-div-center">
                  <Button
                    className="btn btn-block"
                    onClick={() => {
                      unsubscribe();
                    }}
                  >
                    Unsubscribe
                  </Button>
                </div>
              </>
            )}
          </div>
        </Card>
        <div className="d-flex bd-highlight ">
          <div className=" bd-highlight links">
            <Link href="#" onClick={() => setOpenAgreeTerms(true)}>
              <span className="links">Legal Disclaimer</span>
            </Link>
          </div>
          <div className=" bd-highlight links">
            <Link href="mailto:support.lyvecloud@seagate.com">
              <span className="links">Contact Support</span>
            </Link>
          </div>
          <div className="ml-auto  bd-highlight links">
            &copy; 2021 Seagate Technology LLC
          </div>
        </div>
      </div>
      <AgreeTerms
        show={openAgreeTerms}
        handleClose={() => setOpenAgreeTerms(false)}
      />
    </div>
  );
};

export default NotificationUnsubscribe;
