// For common utility functions and constants
import moment, { Moment } from "moment-timezone";
import filesize from "filesize";
import { UsageFrequency } from "../scenes/Dashboard/components/Metrics/hooks/useUsageMetric";
import api from "../services/api";
import camelCase from "lodash/camelCase";
import snakeCase from "lodash/snakeCase";
import { CardType } from "../scenes/SmartStartHere/types.d";
import { debounce } from "lodash";

export const timezone = "America/Los_Angeles";
moment.tz.setDefault(timezone);

export const getFrequencyByRange = (start?: Moment, end?: Moment) => {
  if (!start || !end) {
    return UsageFrequency.DAILY;
  }

  return start.get("month") === end.get("month") &&
    start.get("year") === end.get("year")
    ? UsageFrequency.DAILY
    : UsageFrequency.MONTHLY;
};

export interface ISortConfig {
  param: string;
  inputFormat?: string;
  format?: string;
}

export const sortByDateTime = ({
  param,
  inputFormat = "YYYY-MM-DD hh:mm:ss"
}: ISortConfig) => (curr: any, next: any) => {
  const currDate = moment(curr[param], inputFormat);
  const nextDate = moment(next[param], inputFormat);

  return currDate.diff(nextDate);
};

export const formatDate = (date: string | Moment, format = "YYYY-MM-DD") => {
  return date ? moment(date).format(format) : "";
};

export const formatDateTime = (date: string | Moment, format = "YYYY-MM-DD HH:mm:ss") => {
  return date ? moment(date).format(format) : "";
};

export const todayDate = moment(moment.now()).format("MMM DD, YYYY");

export const formatLocalDateTime = (date: string, format = "YYYY-MM-DD HH:mm:ss") => {
  const offset = new Date(date).getTimezoneOffset();
  const formattedDate =
    moment.parseZone(date)
      .utcOffset(-offset)
      .format(format);
  return date ? formattedDate : "";
}

export const formatTzDate = (date: string, format = "MMM DD, YYYY (z)") => {
  return date ? moment.utc(date).tz(timezone).format(format) : moment();
}

export const getTenantCreatedDate = (createdAt?: string) => {
  return createdAt ? moment.utc(createdAt).tz(timezone) : moment();
};

export const getLastMonthsDate = (months: number, createdAt?: string) => {
  const userCreatedDate = getTenantCreatedDate(createdAt);
  const lastSixMonthStart = moment()
    .startOf("month")
    .subtract(months - 1, "months");
  return lastSixMonthStart.isAfter(userCreatedDate)
    ? lastSixMonthStart
    : userCreatedDate;
};

export const getCurrentMonthStartDate = (createdAt?: string) => {
  const userCreatedDate = getTenantCreatedDate(createdAt);
  const currentMonthStartDate = moment().startOf("month");
  return currentMonthStartDate.isAfter(userCreatedDate)
    ? currentMonthStartDate
    : userCreatedDate;
};

export const isDaily = (start?: Moment, end?: Moment) => {
  if (!start || !end) {
    return true;
  }

  return start.get("month") === end.get("month");
};

export const getLocalDate = (
  date: string,
  format: string = "YYYY/MM/DD hh:mm A"
) => {
  return moment(moment.utc(date).toDate()).format(format);
};

export const convertFileSize = (
  value: any,
  output: any = "string",
  spacer: string = ""
): any => {
  let convertedValue = filesize(value || 0, {
    base: 10,
    spacer,
    output
  });
  return convertedValue;
};

export function toCamelCaseKeys(o: any) {
  let origKey, newKey, value;
  let newO: any;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === "object") {
        value = toCamelCaseKeys(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = camelCase(origKey);
        value = o[origKey];
        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = toCamelCaseKeys(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
}

export function toSnakeCaseKeys(o: any) {
  let origKey, newKey, value;
  let newO: any;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === "object") {
        value = toSnakeCaseKeys(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = snakeCase(origKey);
        value = o[origKey];
        if (
          value instanceof Array ||
          (!!value && value.constructor === Object)
        ) {
          value = toSnakeCaseKeys(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
}

export const removeAddBucketCard = () =>
  api.updateSmartStartCard({
    cardName: CardType.ADD_BUCKET,
    status: "closed"
  });

export function asyncDebounce<T>(
  func: (...args: any[]) => Promise<T>,
  wait: number
): (...args: any[]) => Promise<T> {
  const debounced = debounce((resolve, reject, args) => {
    func(...args)
      .then(resolve)
      .catch(reject);
  }, wait);
  return (...args) =>
    new Promise((resolve, reject) => {
      debounced(resolve, reject, args);
    });
}

export const isAllowedRole = (
  rules: { [role: string]: string[] },
  role: string,
  action: string
) => {
  const allowedActions = rules[role];
  if (!allowedActions) {
    return false;
  }

  return allowedActions.includes(action);
};

export const capitalizeWord = (word?: string) => {
  if (!!word) {
    return `${word[0]}${word.substring(1).toLowerCase()}`
  }
  return ""
}

type ValidatedRules = { message: string; isValid: boolean }

export const getValidatedRules = (
  rules: Record<string, string>,
  errorTypes: string[],
) => {
  const validatedRules: ValidatedRules[] = [];
  for (const rule of Object.keys(rules)) {
    validatedRules.push({
      message: rules[rule],
      isValid: !errorTypes.includes(rule)
    });
  }

  return validatedRules
};

export const getLocalTodayDate = () => {
  const time = moment.utc();
  const localtz = moment.tz.guess();
  const date = time.clone().tz(localtz);
  const formatDate = moment(date).format("MMM DD, YYYY");
  return formatDate;
};