import React from 'react';
import filesize from 'filesize';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import moment from 'moment';
import { convertFileSize, formatDate } from "../../../../../utils";
import { UsageFrequency } from "../../../../../scenes/Dashboard/components/Metrics/hooks/useUsageMetric";

interface PartnerTrendChart {
	partnerXData?: (string | null)[];
	partnerYData?: (number | null)[];
	averageValue: number;
	periodicity?: string;
	isCustomerPortal?: boolean
	loading?: boolean
}

const PartnerTrendChart: React.FC<PartnerTrendChart> = ({
	partnerXData,
	partnerYData,
	averageValue,
	periodicity,
	isCustomerPortal = false,
	loading = false
}) => {
	// const convertFileSize = (value: number) => {
	// 	let convertedValue = filesize(value, { round: 2 });
	//
	// 	if (convertedValue.search('KB') > -1) {
	// 		convertedValue = convertedValue.replace('KB', 'KiB');
	// 		return convertedValue;
	// 	} else if (convertedValue.search('MB') > -1) {
	// 		convertedValue = convertedValue.replace('MB', 'MiB');
	// 		return convertedValue;
	// 	} else if (convertedValue.search('GB') > -1) {
	// 		convertedValue = convertedValue.replace('GB', 'GiB');
	// 		return convertedValue;
	// 	} else if (convertedValue.search('TB') > -1) {
	// 		convertedValue = convertedValue.replace('TB', 'TiB');
	// 		return convertedValue;
	// 	} else if (convertedValue.search('PB') > -1) {
	// 		convertedValue = convertedValue.replace('PB', 'PiB');
	// 		return convertedValue;
	// 	} else {
	// 		return convertedValue;
	// 	}
	// };

	const options = {
		chart: {
			type: 'line',
			marginRight: 60,
			height: 250,
			styledMode: true
		},
		title: {
			text: isCustomerPortal ? '' : 'Total Usage Trend',
			align: 'left'
		},
		credits: {
			enabled: false
		},
		xAxis: {
			labels: {
				formatter: function(y: any): string | null {
					if (loading) {
						return null
					}
					let day = '';
					try {
						if (isCustomerPortal) {
							const vals = y.value.split(' ')
							day = vals[1].slice(0, 2);
							day = day.replace(/^0+/, '');
							day = Number(day) !== 1 ? day : `${day} ${vals[0].slice(0, 3)}`
						} else {
							day = y.value.split(' ')[1].slice(0, 2);
							day = day.replace(/^0+/, '');
						}
					} catch (e) {}

					return day;
				},
				style: {
					fontSize: '9px'
				}
			},
			tickInterval: 1,
			categories: partnerXData,
			crosshair: {
				width: 0.5,
				color: '#a8a8a8'
			},
			title: ''
		},
		legend: {
			enabled: false
		},
		yAxis: {
			labels: {
				formatter: function(value: any): string {
					let num: number = Number(value.pos);
					return convertFileSize(num);
				},
				style: {
					fontSize: '9px'
				}
			},
			allowDecimals: false,
			gridLineDashStyle: 'longdash',
			crosshair: false,
			title: '',
			plotLines: [
				{
					color: 'lightgrey', // Color value
					value: averageValue, // Value of where the line will appear
					width: 2, // Width of the line
					label: {
						useHTML: true,
						text: 'Avg.</br>' + convertFileSize(averageValue),
						align: 'right',
						x: 50,
						y: -15,
						overflow: 'allow',
						style: {
							fontSize: '9px'
						}
					}
				}
			]
		},
		plotOptions: {
			pointStart: 1,
			series: {
				label: {
					connectorAllowed: false
				},
				color: '#6ebe49',
				marker: {
					enabled: false,
					symbol: 'circle',
					radius: 2,
					lineWidth: 1
				}
			}
		},

		series: [
			{
				data: partnerYData
			}
		],

		tooltip: {
			shared: true,
			useHTML: true,
			formatter: function(): any {
				var value: any = this;
				var str: string = '';

				if (periodicity === 'MONTHLY') {
					str = '<table > ';
					var points: any = value.points;

					points.map((obj: any) => {
						var colorcode = obj.color;
						str =
							str +
							'<tr style="color: #ffffff;"> <td><span style="color: ' +
							colorcode +
							';font-size:10px;">&#9673;</span>&nbsp;' +
							moment(value.x).format('MMM YYYY') +
							'&emsp;</td> <td> <b>' +
							convertFileSize(Number(obj.y)) +
							' </b></td></tr>';

						// str =
						// 	str +
						// 	'<tr style="color: #ffffff;"> <td><span style="color: #D3D3D3;font-size:10px;">&#9673;</span>&nbsp;Monthly Average &emsp;</td> <td> <b>' +
						// 	convertFileSize(averageValue) +
						// 	' </b></td></tr>';
					});

					str = str + '</table>';
				} else {
					str =
						'<span style="color: #ffffff;"><b>' +
						value.x +
						'</b></span><br/><div style="color: #6ebe49; margin-top: 5px"><b>' +
						convertFileSize(Number(value.y)) +
						'</b></div>';
				}

				return str;
			},
			color: 'white',
			backgroundColor: 'black',
			borderWidth: 2,
			borderColor: 'black',
			style: {
				color: '#CCCCCC',
				opacity: 0.85
			}
		},

		responsive: {
			rules: [
				{
					condition: {
						maxWidth: 400,
						maxHeight: 400
					},
					chartOptions: {
						legend: {
							layout: 'horizontal',
							align: 'center',
							verticalAlign: 'bottom'
						}
					}
				}
			]
		}
	};

	return (
		<div>
			<HighchartsReact highcharts={Highcharts} options={options} />
		</div>
	);
};

export default PartnerTrendChart;
