import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { UsageFrequency } from "../../../../../Metrics/hooks/useUsageMetric";
import { formatDate } from "../../../../../../../../utils";
interface IYData {
  name: string;
  data: (number | null)[];
  custom?: any;
}

interface UsageGraph {
  subaccountUsageXData: Array<string | null>;
  subaccountUsageYData: IYData[];
  subaccountUsageListCount: number;
  frequency: UsageFrequency;
  showLegend?: boolean;
}

const commonYAxisConfig = (config: any) => ({
  min: 0,
  allowDecimals: false,
  gridLineDashStyle: "longdash",
  crosshair: false,
  ...config
});

const commmonSeriesConfig = (color: string, symbol: string) => ({
  label: {
    connectorAllowed: false
  },
  color,
  marker: {
    enabled: true,
    fillColor: "#FFFFFF",
    lineColor: null,
    symbol,
    radius: 3,
    lineWidth: 2,
    states: {
      hover: {
        fillColor: color,
        radius: 2,
        lineWidth: 2
      }
    }
  }
});

const UsageGraph: React.FC<UsageGraph> = ({
  subaccountUsageXData,
  subaccountUsageYData,
  subaccountUsageListCount,
  frequency,
  showLegend = false
}) => {
  const options = {
    chart: {
      type: "line",
      marginRight: 60,
      marginTop: 26,
      height: 250,
      styledMode: true
    },
    title: {
      text: "",
      align: "left"
    },
    credits: {
      enabled: false
    },
    xAxis: {
      labels: {
        rotation: 0,
        formatter: function(y: any): string | null {
          if (y?.value && isNaN(y.value)) {
            try {
              if (frequency === UsageFrequency.DAILY) {
                const date = formatDate(y.value, "D");
                return Number(date) !== 1 ? date : formatDate(y.value, "D MMM");
              } else {
                const month = formatDate(y.value, "MMM");
                return month !== "Jan"
                  ? month
                  : formatDate(y.value, "MMM YYYY");
              }
            } catch (e) {
              //console.error(e);
              return null;
            }
          } else {
            return null;
          }
        },
        style: {
          fontSize: "12px",
          color: "#1a1a1a",
          transform: 'translate(0, 0) rotate(0deg)'
        }
      },
      tickInterval: 1,
      categories: subaccountUsageXData,
      crosshair: {
        width: 0.5,
        color: "#a8a8a8"
      },
      title: {
        text: ""
      }
    },
    legend: {
      enabled: showLegend,
      align: "center",
      verticalAlign: "bottom",
      x: 0,
      y: 0
    },
    yAxis: [
      ...subaccountUsageYData.map((ydata, index) =>
        commonYAxisConfig({
          max: subaccountUsageListCount === 0 ? 10 : null,
          title: {
            text: ydata.custom.name
          },
          labels: {
            formatter: function(value: any): string {
              let num: number = Number(value.pos);
              return ydata.custom.formatter(num);
            },
            style: {
              fontSize: "12px",
              color: "#1a1a1a"
            }
          },
          opposite: ydata.custom.opposite,
          plotLines: [
            {
              value: ydata.custom.avg,
              color: "#b3b3b3",
              width: 2,
              label: {
                text:
                  ydata.data.length &&
                  (ydata.custom.avg || ydata.custom.avg === 0)
                    ? `Avg. ${ydata.custom.formatter(ydata.custom.avg)}`
                    : "",
                align: "right",
                y: -20,
                style: {
                  width: "30px",
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  color: "#4d4d4d"
                }
              }
            }
          ]
        })
      )
    ],
    plotOptions: {
      pointStart: 1
    },
    series: subaccountUsageYData.map((usage, index) => ({
      data: usage.data,
      yAxis: index,
      connectNulls: true,
      name: usage.name,
      custom: usage.custom,
      ...commmonSeriesConfig(usage.custom.color, usage.custom.symbol)
    })),
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function(): any {
        // @ts-ignore
        const { x, points } = this;

        return `<div style="color: #ffffff; font-size: 14px; font-family: Roboto, sans-serif">
        <span style="line-height: 20px">${formatDate(
          x,
          frequency === UsageFrequency.DAILY ? "DD MMM, YYYY" : "MMMM, YYYY"
        )} (PST)</span>${points.map((s: any) => {
          const { legendStyle, formatter } = s.series.userOptions.custom;
          return `<br/><span style="line-height: 20px"><div style="${legendStyle}"></div>${
            s.series.name
          }: ${formatter(s.y)}</span>`;
        })}</div>`;
      },
      color: "white",
      backgroundColor: "black",
      borderWidth: 2,
      borderColor: "black",
      style: {
        color: "#CCCCCC",
        opacity: 0.85
      }
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
            maxHeight: 250
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom"
            }
          }
        }
      ]
    }
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default UsageGraph;
