import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Topbar, useNavbar, Sidebar } from "../../components/NavigationBar";
import common from "../../services/common";
import { WhoAmI } from "../../state/UserInfo/types";
import LyveDropdown, {
  LyveDropdownOption
} from "../../components/Menu/LyveDropdown";
import {
  BarChartLine,
  BarChartLineFill,
  Archive,
  ArchiveFill,
  Bucket,
  BucketFill,
  Cpu,
  CpuFill,
  CreditCard,
  CreditCardFill,
  Envelope,
  EnvelopeFill,
  Gear,
  GearFill,
  Headset,
  House,
  HouseFill,
  InfoCircle,
  Key,
  KeyFill,
  Layers,
  LayersFill,
  Lock,
  LockFill,
  People,
  PeopleFill,
  Person,
  ShieldCheck,
  ShieldFillCheck,
  ShieldLock,
  ShieldLockFill,
  XDiamond,
  XDiamondFill,
  ChevronRight
} from "react-bootstrap-icons";
import {
  BUCKETS_VIEW,
  NOTIFICATIONS_VIEW,
  PERMISSIONS_VIEW,
  rules,
  SA_VIEW,
  SUPPORT_VIEW,
  USERS_VIEW,
  SETTINGS_VIEW,
  BILLING_VIEW,
  SMART_START_VIEW,
  ANALYTICS_VIEW,
  SUBACCOUNTS_VIEW,
  ARCHIVE_BUCKETS_VIEW
} from "../../authRules";
import { LyveTrialBanner } from "../../components/LyveTrialBanner";
import api from "../../services/api";
import { AppState, useAppSelector } from "../../store";
import { errorEvalInfo, saveEvalInfo } from "../../state/Eval/actions";
import { ModalContainer } from "../../components/Modal";
import packageJson from "../../../package.json";

import "./styles.scss";
import SmartStartKit from "../SmartStartHere";
import useSmartStartKit from "../SmartStartHere/useSmartStartKit";
import useGlobalContext from "../../state/GlobalStateContext";
import Can from "../../components/Can";
import { useQuery } from "react-query";
import { EvalData } from "../../state/Eval/types";
import { selectLyveCloud } from "../../state/lyveCloud";
import {
  saveMasterAccountInfo,
  fetchingMasterAccountInfo,
  errorMasterAccountInfo
} from "../../state/Subaccounts/actions";
import LyveBanner from "../../components/LyveBanner";
import { Modal } from "react-bootstrap";
import { DefaultButton } from "../../components/LyveButton";
import useCybersourceFormData from "../Dashboard/components/Billing/useCybersourceFormData";
import useFixedCustomerDetails from "../Dashboard/components/Metrics/hooks/useFixedCustomerDetails";
import { ISidebarOption } from "../../components/NavigationBar/Sidebar";
import { getLink, linkNames } from "../../components/LyveHeadingLink/LinkUtil";
import {
  setErrorNotification,
  setNotification
} from "../../components/Notification/actions";
import { ButtonLoader } from "../../components/LyveLoader";
import LyvePopup from "../../components/Modal/LyvePopup";
import { getMasterAccountType } from "../../utils/subaccounts";

import { ReactComponent as DataMoverFill } from "../../images/icon-navbar-data-mover-fill.svg";
import { ReactComponent as DataMoverOutline } from "../../images/icon-navbar-data-mover-outline.svg";
import Tag from "../../components/Tag";

import cx from "classnames";
import CreateResources from "../../components/CreateResources";
import PrepareResources, { AccountApi } from "./PrepareResources";
import moment from "moment-timezone";
import { formatTzDate, todayDate } from "../../utils";

export enum ECustomerType {
  EVAL = "EVAL",
  PAID = "PAID",
  FIXED_PRICE = "FIXED_PRICE"
}

export enum EEvalActionType {
  EXTEND = "EXTEND",
  INTEND = "INTEND",
  TERMINATE = "TERMINATE"
}

interface IEvalUrlsType {
  extend_url: string;
  intend_url: string;
  terminate_url: string;
}

interface Account {
  id: string;
  name: string;
  short_name: string;
  admin_first_name: string;
  admin_last_name: string;
  admin_email: string;
  org_name: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  phone_number: string;
  parent_short_name: string;
  is_master: boolean;
  is_enabled: boolean;
  created_date: string;
  start_date: string;
  end_date: string;
  account_type: string;
  sub_account_max_limit: number;
  sub_account_support: boolean;
  sub_account_trial: boolean;
  sub_account_trial_ext_limit: number;
  sub_account_diff_org: boolean;
  view_support_ticket: boolean;
}

export const isAllowedNavTab = (
  rules: { [role: string]: string[] },
  role: string,
  action: string
) => {
  const allowedActions = rules[role];
  if (!allowedActions) {
    // role is not present in the rules
    return false;
  }

  return allowedActions.includes(action);
};

const SupportIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-headset-custom"
      viewBox="0 0 16 16"
    >
      <path d="M8,0A6,6,0,0,0,2,6v5a1,1,0,0,0,1,1H4a1,1,0,0,0,1-1V8A1,1,0,0,0,4,7H3V6A5,5,0,0,1,13,6V7H12a1,1,0,0,0-1,1v3a1,1,0,0,0,1,1h1a1.5,1.5,0,0,1-1.5,1.5H9.37A1,1,0,0,0,8.5,13h-1a1,1,0,0,0,0,2h1a1,1,0,0,0,.87-.5H11.5A2.5,2.5,0,0,0,14,12V6A6,6,0,0,0,8,0ZM4,8v3H3V8Zm9,3H12V8h1Z" />
    </svg>
  );
};

const MainLayout: React.FC = props => {
  const {
    showSmartStartKit,
    toggleShowSmartStartKit,
    showTooltip,
    toggleTooltip,
    userConfig
  } = useSmartStartKit();
  const { showAccountDropdown, toggleShowAccountDropdown } = useGlobalContext();

  const expOptionRef = React.useRef(null);

  const [evalUrls, setEvalUrls] = useState<IEvalUrlsType | null>(null);
  const dispatch = useDispatch();
  const { showModal } = ModalContainer.useContainer();
  const whoAmI = useSelector<AppState, WhoAmI>(state => state.userInfo.whoAmI);
  const { evalData } = useSelector((state: AppState) => ({
    evalData: state.eval.evalData
  }));
  const isTopBannerAdded = evalData
    ? evalData.customer_type === ECustomerType.EVAL
    : false;
  const { children } = props;
  //const { customerDashboardFlag } = useFlags();
  const lyveCloud: any = useAppSelector(selectLyveCloud);

  const [showMarketplace, setShowMarketplace] = React.useState(false);

  const [resetPassModal, setResetPassModal] = React.useState(false);
  const triggerResetPassModal = () => setResetPassModal(!resetPassModal);
  const [resettingPassword, setResettingPassword] = React.useState(false);
  const [account, setAccount] = React.useState<Account | null>(null);
  const [featuresList, setFeaturesList] = React.useState<string[]>([]);
  const [expirationDate, setExpirationDate] = React.useState<string>("");

  const [showAccApiPopup, setShowAccApiPopup] = React.useState(false);
  const toggleAccApiPopup = () => setShowAccApiPopup(!showAccApiPopup);

  const [generateDropdown, setGenerateDropdown] = useState(false);

  const [regeneratePopup, setRegeneratePopup] = React.useState(false);
  const toggleRegeneratePopup = () => setRegeneratePopup(!regeneratePopup);
  const regenerateAccApi = () => {
    toggleRegeneratePopup();
    toggleAccApiPopup();
  };

  const [deleteApiPopup, setDeleteApiPopup] = React.useState(false);
  const toggleDeleteApiPopup = () => setDeleteApiPopup(!deleteApiPopup);
  const [deletingApi, setDeletingApi] = React.useState(false);
  const deleteAccApi = () => {
    setDeletingApi(true);
    api
      .deleteAccountAPICredentials()
      .then(async () => {
        const res = await api.getAccountAPIExpirationDate();
        "expirationDate" in res && setExpirationDate(res.expirationDate);
        dispatch(setNotification("Account API Credentials deleted"));
      })
      .catch(() => {
        dispatch(
          setErrorNotification("Failed to delete. Please try again later.")
        );
      })
      .finally(() => {
        setDeletingApi(false);
        toggleDeleteApiPopup();
      });
  };

  const current_env = "CURRENT_ENV";
  const currentEnv = localStorage.getItem(current_env);

  const {
    customerName,
    userRole,
    companyShortName,
    partnerName,
    userName,
    firstName,
    userEmail,
    userType,
    handleLogout,
    handleLogoClick,
    archivalEnabled
  } = useNavbar();

  const {
    searchQuery,
    formData,
    setIsCybersourceRedirected,
    getIsCybersourceRedirected,
    handleClick,
    showPaymentModal,
    formRef,
    setPaymentModal
  } = useCybersourceFormData(evalData?.customer_type, userRole);

  const {
    isLoading: monthCostLoading,
    error: monthCostError
  } = useFixedCustomerDetails(evalData?.customer_type, userRole);

  useQuery<EvalData>(
    "eval",
    () => {
      // dispatch(fetchingEvalInfo());
      return api.getEvalStatus(companyShortName);
    },
    {
      enabled: !!companyShortName,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnReconnect: false,
      retry: false,
      onSuccess: res => {
        if (res) {
          dispatch(saveEvalInfo(res));
        }
      },
      onError: err => {
        dispatch(errorEvalInfo(err));
        saveEvalInfo(null);
      }
    }
  );

  const role = `${userType}-${userRole}`;

  useEffect(() => {
    if (
      evalData?.sf_request_id &&
      evalData?.sf_request_status !== "EXTEND" &&
      evalData?.customer_type === ECustomerType.EVAL
    ) {
      api
        .getEvalUrls(evalData.sf_request_id)
        .then(res => {
          if (res) {
            setEvalUrls(res);
          }
        })
        .catch(err => {
          setEvalUrls(null);
        });
    }
  }, [evalData?.sf_request_id, evalData?.sf_request_status]);

  useEffect(() => {
    if (whoAmI?.company_short_name) {
      fetchingMasterAccountInfo();
      api
        .getSubaccount(whoAmI?.company_short_name)
        .then(res => {
          if (res) {
            dispatch(saveMasterAccountInfo(res));
            setAccount(res);
          }
        })
        .catch(err => {
          errorMasterAccountInfo(err);
        });
    }
  }, [whoAmI]);

  React.useEffect(() => {
    api.shouldReleaseMarketplace().then((res: any) => {
      setShowMarketplace(res?.isEnabled);
    });

    api.getEnabledFeatures().then((res: any) => {
      setFeaturesList(res.features);
    });

    api.getAccountAPIExpirationDate().then((res: any) => {
      setExpirationDate(res.expirationDate);
    });
  }, []);

  React.useEffect(() => {
    if (!currentEnv) {
      api.getCurrentEnv().then((res: any) => {
        localStorage.setItem(current_env, res?.env);
      });
    }
  }, [currentEnv]);

  const resetPassword = () => {
    if (firstName) {
      setResettingPassword(true);
      api
        .userResetPassword(firstName, userEmail)
        .then(() =>
          dispatch(
            setNotification(
              `A password reset Email has been sent to ${userEmail}`
            )
          )
        )
        .catch(() =>
          dispatch(
            setNotification(
              `Failed to send reset email invite to ${userEmail}. Please try again later`
            )
          )
        )
        .finally(() => {
          setResettingPassword(false);
          triggerResetPassModal();
        });
    } else {
      dispatch(
        setNotification(
          `Failed to send reset email invite to ${userEmail}. Please try again later`
        )
      );
      setResettingPassword(false);
      triggerResetPassModal();
    }
  };

  const getNumberOfDaysLeft = () => {
    const expiratingDate = moment(expirationDate)
      .local()
      .toDate();
    const currentDate = new Date();
    const Difference_In_Time = expiratingDate.getTime() - currentDate.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Math.round(Difference_In_Days);
  };

  const getExpirationStatus = () => {
    var today = moment()
      .local()
      .format("YYYY-MM-DD");
    console.log(expirationDate, today);
    if (expirationDate === "no_records") {
      return ["", "Generate Account API Credentials"];
    } else if (expirationDate > today) {
      const daysLeft = getNumberOfDaysLeft();
      return [
        `Expires in: ${daysLeft} day${daysLeft <= 1 ? "" : "s"}`,
        "Account API Credentials"
      ];
    } else if (expirationDate == today) {
      return ["Expires in: 1 day", "Account API Credentials"];
    } else if (!!expirationDate && expirationDate < today) {
      return ["Expired", "Account API Credentials"];
    } else {
      // expirationDate === "nil" || ""
      return ["Never expires", "Account API Credentials"];
    }
  };

  const [expStatus, expText] = getExpirationStatus();

  const openAccountApiLink = (event: React.MouseEvent) => {
    window.open(getLink(linkNames.ACCOUNT_API_LEARN_MORE), "_blank");
    event.stopPropagation();
  };

  const getTopbarAccountOptions = () => {
    const options: LyveDropdownOption[] = [
      {
        header: (
          <>
            <span className="option-header">{customerName}</span>
            {packageJson.version && (
              <span className="dev-version">{packageJson.version}</span>
            )}
          </>
        ),
        label: (
          <span>
            <span className="option-title">{userName}</span> <br />
            {userRole && (
              <>
                <span className="option-title option-subtitle text-capitalize">
                  Role: {userRole}
                </span>{" "}
                <br />
              </>
            )}
            <span className="option-title option-subtitle text-break">{userEmail}</span>
          </span>
        )
      },
      {
        hasDivider: true,
        action: "reset-password",
        label: <span className="option-title">Reset Password</span>,
        onClick: triggerResetPassModal
      },
      {
        action: "logout",
        label: <span className="option-title">Log Out</span>,
        onClick: () => {
          handleLogout();
        }
      }
    ];

    const isAuditor = userRole === "auditor";
    const letGenerateCreds = !(
      userRole === "storage-admin" ||
      (isAuditor && !expStatus)
    );

    if (letGenerateCreds) {
      options.splice(1, 0, {
        hasDivider: true,
        action: "generate-token",
        label: (
          <LyveDropdown
            id="expiration-dropdown"
            className="expiration-status-dropdown"
            drop="left"
            hasManualToggle
            manualShow={generateDropdown}
            onToggle={() => {
              if (!isAuditor) {
                setGenerateDropdown(!generateDropdown);
              }
            }}
            trigger={
              <div className="expiration-status-container" ref={expOptionRef}>
                <div>
                  <div className="option-title">{expText}</div>
                  <DefaultButton variant="link" onClick={openAccountApiLink}>
                    Learn more
                  </DefaultButton>
                  <i className="fas fa-caret-right ml-1" />
                  {expStatus && (
                    <div
                      className={cx("expiration-status", {
                        "expiration-status-danger": expStatus === "Expired"
                      })}
                    >
                      {expStatus}
                    </div>
                  )}
                </div>
                {!isAuditor && <ChevronRight />}
              </div>
            }
            options={[
              {
                action: "regenerate-account-apis",
                label: <span className="option-title">Regenerate</span>,
                onClick: toggleRegeneratePopup
              },
              {
                action: "delete-account-apis",
                label: <span className="option-title">Delete</span>,
                onClick: toggleDeleteApiPopup
              }
            ]}
          />
        ),
        onClick: !expStatus && !isAuditor ? toggleAccApiPopup : () => { }
      });
    }

    if (userConfig.movedToProfileDropdown) {
      const openSmartStartKit = () => {
        if (!showSmartStartKit) {
          toggleShowSmartStartKit?.();
        }
      };

      options.splice(1, 0, {
        hasDivider: true,
        action: "showSmartStartKit",
        tooltip: {
          enabled: showTooltip,
          text:
            "The Start Here menu was removed from the top and can be found here now",
          placement: "left",
          id: "smart-start-tooltip"
        },
        label: <span className="option-title">Start Here</span>,
        onClick: openSmartStartKit
      });
    }

    return options;
  };

  const toggleDropdown = () => {
    if (showSmartStartKit) {
      toggleShowSmartStartKit();
    }
    toggleShowAccountDropdown();
  };

  const topbarRightElement = () => {
    return (
      <div className="d-flex topbar-right-element">
        <Can perform={SMART_START_VIEW}>
          <SmartStartKit />
        </Can>
        <div className="topbar-right-icon">
          <a
            className="topbar-right-label"
            href={getLink(linkNames.Help)}
            target="_blank"
            rel="noreferrer"
          >
            <InfoCircle size={16} />
            <span className="topbar-right-label">Help</span>
          </a>
        </div>
        <LyveDropdown
          ref={expOptionRef}
          autoClose="outside"
          parentDropdownClass={"lyve-topbar-dropdown"}
          id="topbar-account-option"
          trigger={
            <div
              className={`topbar-right-icon ${showAccountDropdown ? "topbar-pressed-icon" : ""
                }`}
              onClick={toggleDropdown}
            >
              <Person size={16} />
              <span className="topbar-right-label">{userName}</span>
            </div>
          }
          drop={"down"}
          hasManualToggle={true}
          manualShow={showAccountDropdown}
          onToggle={(isOpen, _event, _meta) => {
            if (!isOpen) {
              toggleShowAccountDropdown();
              setGenerateDropdown(false);
            }

            if (showTooltip) {
              toggleTooltip();
            }
          }}
          alignRight={true}
          options={getTopbarAccountOptions()}
        />
      </div>
    );
  };

  const sidebarOptions = () => {
    let options: ISidebarOption[] = [
      {
        label: "Home",
        icon: <House />,
        selectedIcon: <HouseFill />,
        actionPerformed: "",
        url: "/customer/dashboard",
        subUrls: [],
        isSection: false
      },
      {
        label: "Storage",
        icon: "",
        selectedIcon: "",
        url: "",
        actionPerformed: "",
        subUrls: [],
        isSection: true,
        options: [
          ...(isAllowedNavTab(rules, role, BUCKETS_VIEW)
            ? [
              {
                label: "Buckets",
                icon: <Bucket />,
                selectedIcon: <BucketFill />,
                actionPerformed: BUCKETS_VIEW,
                url: "/customer/buckets",
                subUrls: ["/customer/bucket"],
                isSection: false
              }
            ]
            : []),
          ...(isAllowedNavTab(rules, role, ARCHIVE_BUCKETS_VIEW) &&
            archivalEnabled
            ? [
              {
                label: "Archive Buckets",
                icon: <Archive />,
                selectedIcon: <ArchiveFill />,
                actionPerformed: ARCHIVE_BUCKETS_VIEW,
                url: "/customer/archive-buckets",
                subUrls: ["/customer/archive-bucket"],
                tag: <Tag label="Beta" type="secondary" />,
                isSection: false
              }
            ]
            : []),
          ...(isAllowedNavTab(rules, role, PERMISSIONS_VIEW)
            ? [
              {
                label: "Permissions",
                icon: <ShieldCheck />,
                selectedIcon: <ShieldFillCheck />,
                actionPerformed: PERMISSIONS_VIEW,
                url: "/customer/permissions",
                subUrls: [],
                isSection: false
              }
            ]
            : []),
          ...(isAllowedNavTab(rules, role, SA_VIEW)
            ? [
              {
                label: "Service Accounts",
                icon: <Key />,
                selectedIcon: <KeyFill />,
                actionPerformed: SA_VIEW,
                url: "/customer/service-accounts",
                subUrls: [],
                isSection: false
              }
            ]
            : [])
        ]
      },
      {
        label: "Identity & Access",
        icon: "",
        selectedIcon: "",
        actionPerformed: "",
        url: "",
        subUrls: [],
        isSection: true,
        options: [
          ...(isAllowedNavTab(rules, role, USERS_VIEW)
            ? [
              {
                label: "Users",
                icon: <People />,
                selectedIcon: <PeopleFill />,
                url: "/customer/users",
                subUrls: [],
                actionPerformed: USERS_VIEW,
                isSection: false
              }
            ]
            : []),
          ...(isAllowedNavTab(rules, role, USERS_VIEW)
            ? [
              {
                label: "MFA",
                icon: <ShieldLock />,
                selectedIcon: <ShieldLockFill />,
                actionPerformed: USERS_VIEW,
                url: "/customer/mfa",
                subUrls: [],
                isSection: false
              }
            ]
            : []),
          ...(isAllowedNavTab(rules, role, USERS_VIEW)
            ? [
              {
                label: "Federated Login",
                icon: <Lock />,
                selectedIcon: <LockFill />,
                actionPerformed: USERS_VIEW,
                url: "/customer/saml-federation",
                subUrls: [],
                isSection: false
              }
            ]
            : []),
          ...(isAllowedNavTab(rules, role, NOTIFICATIONS_VIEW)
            ? [
              {
                label: "Notification Recipients",
                icon: <Envelope />,
                selectedIcon: <EnvelopeFill />,
                url: "/customer/notifications",
                subUrls: [],
                actionPerformed: NOTIFICATIONS_VIEW,
                isSection: false
              }
            ]
            : [])
        ]
      },
      {
        label: "Your Account",
        icon: "",
        selectedIcon: "",
        actionPerformed: "",
        url: "",
        subUrls: [],
        isSection: true,
        options: [
          ...(isAllowedNavTab(rules, role, SETTINGS_VIEW)
            ? [
              {
                label: "Settings",
                icon: <Gear />,
                selectedIcon: <GearFill />,
                actionPerformed: SETTINGS_VIEW,
                url: "/customer/settings",
                subUrls: [],
                isSection: false
              }
            ]
            : []),
          ...(isAllowedNavTab(rules, role, SUBACCOUNTS_VIEW) &&
            account &&
            account.is_master &&
            !!account.sub_account_max_limit
            ? [
              {
                label: "Sub-accounts",
                icon: <Layers />,
                selectedIcon: <LayersFill />,
                actionPerformed: SUBACCOUNTS_VIEW,
                url: "/customer/subaccounts",
                subUrls: ["/customer/subaccounts"],
                isSection: false
              }
            ]
            : []),
          ...(isAllowedNavTab(rules, role, SUPPORT_VIEW) &&
            account &&
            (account.is_master || account.sub_account_support)
            ? [
              {
                label: "Support",
                icon: <SupportIcon />,
                selectedIcon: <Headset />,
                actionPerformed: SUPPORT_VIEW,
                url: "/customer/support",
                subUrls: [
                  "/customer/support-dashboard/ticket-details",
                  "/customer/support-resources"
                ],
                isSection: false
              }
            ]
            : [])
        ]
      }
    ];

    //if (!customerDashboardFlag && customerDashboardFlag !== undefined) {
    // options = options.filter(option => option.label !== "Home");
    //}

    return options;
  };

  const accountType = getMasterAccountType(account);
  const isMSP = accountType.isMSP;
  const isReseller = accountType.isReseller;
  const isEnterprise = accountType.isEnterprise;
  let trialActions: any[] = [];

  if (
    (account?.is_master && isMSP) ||
    (account?.is_master && isEnterprise) ||
    isReseller
  ) {
    trialActions = [
      {
        name: "Extend trial",
        type: EEvalActionType.EXTEND,
        url: evalUrls?.extend_url ?? ""
      },
      {
        name: "End trial",
        type: EEvalActionType.TERMINATE,
        url: evalUrls?.terminate_url ?? ""
      },
      {
        name: "Contact sales",
        type: EEvalActionType.INTEND,
        url: evalUrls?.intend_url ?? ""
      }
    ];
  }

  // For toggling Not Available Eval Url modal
  const showNotAvailableEvalUrlModal = () => {
    showModal({
      body: _closeModal => <p>Form not available. Please try later.</p>,
      baseProps: { size: "sm" }
    });
  };

  const handleEvalUrl = (evalUrl: string, actionType: EEvalActionType) => {
    if (evalUrl) {
      window.open(evalUrl, "_blank", "noopener,noreferrer");
    } else {
      showNotAvailableEvalUrlModal();
    }
  };

  const closeManagePaymentModal = () => {
    setIsCybersourceRedirected("no");
    setPaymentModal(false);
  };

  const renderShowManagePaymentModal = () => {
    return (
      <Modal
        show={getIsCybersourceRedirected() === "yes" && showPaymentModal}
        backdrop="static"
        dialogClassName="cancel-lyve-cloud-modal lyve-modal modal-sm"
        onHide={closeManagePaymentModal}
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>Manage payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p style={{ marginBottom: 24 }}>
              You have {searchQuery === "1" ? "successfully" : "unsuccessfully"}{" "}
              updated credit card information.
            </p>
            <DefaultButton
              variant="primary"
              className=""
              onClick={closeManagePaymentModal}
            >
              OK
            </DefaultButton>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const paymentFailureText = () => {
    return (
      <span>
        Your credit card payment has failed.{" "}
        <span className="click-here-link" onClick={handleClick}>
          Click here
        </span>{" "}
        to update your payment method.
      </span>
    );
  };

  const cancelLyveCloudText = () => {
    if (evalData?.customer_type === ECustomerType.FIXED_PRICE) {
      return `The account will be cancelled on ${formatTzDate(
        evalData.end_date
      )} by end of day. Please contact sales support for help.`;
    } else {
      return `The account will be cancelled on ${todayDate} by end of day. Please contact sales support for help.`;
    }
  };

  const accountApiCallback = () => {
    let promise = !!expStatus
      ? api.regenerateAccountAPICredentials()
      : api.fetchAccessKeyAndSecretKeyForAccountAPI();
    return promise.then(async data => {
      const res = await api.getAccountAPIExpirationDate();
      "expirationDate" in res && setExpirationDate(res.expirationDate);
      return data;
    });
  };

  const isShowTrialBannerForFixedPrice =
    lyveCloud?.data?.currentPlan &&
    evalData?.customer_type === ECustomerType.FIXED_PRICE &&
    !lyveCloud.data.estimate_cost_card &&
    !(lyveCloud.data.payment_failed || lyveCloud.data.cancelled);

  return (
    <div className="lyve-main-wrapper">
      <div className="lyve-global-header">
        <LyvePopup
          header
          show={regeneratePopup}
          onHide={toggleRegeneratePopup}
          baseProps={{ dialogClassName: "confirm-popup" }}
          title="Regenerate Account API Credentials"
          footer={
            <div>
              <DefaultButton variant="danger" onClick={regenerateAccApi}>
                Yes
              </DefaultButton>
              <DefaultButton
                variant="outline-secondary"
                onClick={toggleRegeneratePopup}
              >
                No
              </DefaultButton>
            </div>
          }
        >
          <div className="body-title">
            Are you sure you want to regenerate account API credentials?
          </div>
          <div className="body-subtitle">
            This will replace the current account API credential and cannot be
            undone.
          </div>
        </LyvePopup>
        <LyvePopup
          header
          show={deleteApiPopup}
          baseProps={{ dialogClassName: "confirm-popup" }}
          onHide={toggleDeleteApiPopup}
          title="Delete Account API Credentials"
          footer={
            <div>
              <DefaultButton
                variant="danger"
                onClick={deleteAccApi}
                disabled={deletingApi}
              >
                {deletingApi ? <ButtonLoader animation="border" /> : "Yes"}
              </DefaultButton>
              <DefaultButton
                variant="outline-secondary"
                onClick={toggleDeleteApiPopup}
              >
                No
              </DefaultButton>
            </div>
          }
        >
          <div className="body-title">
            Are you sure you want to delete account API credentials?
          </div>
          <div className="body-subtitle">
            This will delete the current account API credential and cannot be
            undone.
          </div>
        </LyvePopup>
        <LyvePopup
          header
          show={resetPassModal}
          onHide={triggerResetPassModal}
          title="Are you sure you want to reset your password?"
          footer={
            <div>
              <DefaultButton
                onClick={resetPassword}
                disabled={resettingPassword}
              >
                {resettingPassword ? (
                  <ButtonLoader animation="border" />
                ) : (
                  "Yes"
                )}
              </DefaultButton>
              <DefaultButton
                variant="outline-secondary"
                onClick={triggerResetPassModal}
              >
                No
              </DefaultButton>
            </div>
          }
        >
          <div>
            {" "}
            A change password email will be sent to you with a URL token to
            reset your password.{" "}
          </div>
        </LyvePopup>
        <CreateResources<AccountApi>
          entity="credentials"
          animationTitle="Creating Account API Credentials"
          failedTitle="Account API Credentials Creation Failed"
          timeoutTitle="Account API creation is taking longer than expected"
          timeoutSubTitle="Please check back again later for your account api credential status."
          initialData={{ access_key: "", secret_key: "" }}
          show={showAccApiPopup}
          onHide={toggleAccApiPopup}
          apiCallback={accountApiCallback}
        >
          {props => <PrepareResources {...props} />}
        </CreateResources>

        <Topbar
          partnerName={common.isTenant(userType) ? partnerName : ""}
          handleLogoClick={handleLogoClick}
          rightElement={topbarRightElement()}
        />
        {lyveCloud.data.cancelled && (
          <LyveBanner msg={cancelLyveCloudText()} type="error" />
        )}
        {!lyveCloud.data.cancelled && lyveCloud.data.payment_failed && (
          <LyveBanner msg={paymentFailureText()} type="error" />
        )}
        
      </div>
      <div className="lyve-wrapper">
        <Sidebar
          options={sidebarOptions()}
          isTopBannerAdded={
            isTopBannerAdded ||
            isShowTrialBannerForFixedPrice ||
            lyveCloud.data.cancelled ||
            lyveCloud.data.payment_failed
          }
        />
        <div
          className={`lyve-page-wrapper ${evalData && evalData.customer_type === ECustomerType.EVAL
              ? "lyve-top-trial-banner"
              : ""
            }`}
        >
          <div className="mainContent">{children}</div>
        </div>
      </div>
      {evalData?.customer_type === ECustomerType.FIXED_PRICE && (
        <>
          {renderShowManagePaymentModal()}
          {formData && (
            <form
              id="payment_confirmation"
              ref={formRef}
              action={formData.cybersource_endpoint}
              method="post"
            >
              {Object.entries(formData.form_data).map(([key, value]) => (
                <input
                  key={key}
                  type="hidden"
                  id={key}
                  name={key}
                  value={value}
                />
              ))}
              <input
                type="hidden"
                id="signature"
                name="signature"
                value={formData.signature}
              />
            </form>
          )}
        </>
      )}
      {/* <ChatBot key={'support-chat-bot'} userAccountId={api.getUserInfo()?.id ?? ''} userEmail={api.getUserInfo()?.email ?? ''} userFirstName={api.getUserInfo()?.name?.split(' ')[0] ?? ''} userLastName={api.getUserInfo()?.name?.split(' ')[1] ?? ''} /> */}
    </div>
  );
};

export default MainLayout;
