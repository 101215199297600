import React, { isValidElement } from "react";
import LyvePopup from "../../../../../components/Modal/LyvePopup";
import { DefaultButton } from "../../../../../components/LyveButton";
import { Form, Spinner } from "react-bootstrap";
import Input from "../../../../../components/Input";
import api from "../../../../../services/api";
import { SubAccount } from "./View/components/Settings";
import { useAppDispatch } from "../../../../../store";
import {
  setErrorNotification,
  setNotification
} from "../../../../../components/Notification/actions";

import "./styles.scss";

declare interface ToggleEnableSubAccountProps {
  subaccount: SubAccount;
  isEnabled: boolean;
  onSuccess?: () => void;
  onClose?: () => void;
  show?: boolean;
  children?: any;
}

const ToggleEnableSubAccount: React.FC<ToggleEnableSubAccountProps> = props => {
  const { subaccount, isEnabled, onSuccess, onClose, children } = props;

  const dispatch = useAppDispatch();

  const [show, setShow] = React.useState(props.show || false);
  const toggleShow = () => setShow(!show);

  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const toggleConfirmation = () => setIsConfirmed(!isConfirmed);

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [accName, setAccName] = React.useState("");
  const resetAccNameChange = () => setAccName("");
  const handleAccNameChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setAccName(event.currentTarget?.value);

  React.useEffect(() => {
    if(props.show != undefined) {
      setShow(props.show)
    }
  }, [props.show])

  const directToggleEnableSubaccount = async () => {
    try {
      setIsSubmitting(true);
      await api.toggleSubAccount(subaccount.short_name, show);
      await onSuccess?.();
      setNotification(
        `${subaccount.short_name} has been ${isEnabled ? "disabled" : "enabled"}.`
      )(dispatch);
    } catch {
      setErrorNotification("Something went wrong, Please try later.")(dispatch);
    } finally {
      setIsSubmitting(false);
      onClose?.()
    }
  };

  const toggleEnableSubaccount = async () => {
    try {
      await directToggleEnableSubaccount();
    } finally {
      toggleShow();
      toggleConfirmation();
      resetAccNameChange();
    }
  };

  const handleConfirmation = () => {
    if (isConfirmed) {
      toggleEnableSubaccount();
    } else {
      toggleConfirmation();
    }
  };

  const cancelConfirmation = () => {
    if (isConfirmed) {
      toggleShow();
      toggleConfirmation();
      resetAccNameChange();
    } else {
      toggleShow();
    }
    onClose?.()
  };

  const handleClick = async () => {
    if (isEnabled) {
      toggleShow();
    } else {
      await directToggleEnableSubaccount();
    }
  };

  const enableConfirmation =
    (show && !isConfirmed) || (!!accName && accName === subaccount.name);

  const child = children ? React.Children.only(children) : null;
  // @ts-ignore
  const childProps = { onClick: handleClick, loading: isSubmitting };

  const childElement =
    isValidElement(child) && React.cloneElement(child, childProps);

  return (
    <>
      {childElement}
      <LyvePopup
        header
        show={show}
        onHide={cancelConfirmation}
        title="Disable Sub-account"
        baseProps={{ dialogClassName: "subaccount-action-modal" }}
        subtitle={isConfirmed ? "" : "Are you sure you want to disable this sub-account?"}
        footer={
          <div>
            <DefaultButton
              variant="danger"
              className="confirm-action-btn"
              onClick={handleConfirmation}
              disabled={!enableConfirmation}
              loading={isSubmitting}
            >
              {isConfirmed ? <span className="second-screen-btn">Confirm</span> : "Yes"}
            </DefaultButton>
            <DefaultButton
              variant="outline-secondary"
              onClick={cancelConfirmation}
            >
              {isConfirmed ? <span className="second-screen-btn">Cancel</span> : "No"}
            </DefaultButton>
          </div>
        }
      >
        {isConfirmed ? (
          <Form>
            <Input
              controlId="delete-account-name"
              label={`Enter account name "${subaccount.name}" below to confirm`}
              onChange={handleAccNameChange}
            />
          </Form>
        ) : (
          <div>
            <div className="acc-name"> Account Name </div>
            <div className="acc-value"> {subaccount.name} </div>
          </div>
        )}
      </LyvePopup>
    </>
  );
};

export default ToggleEnableSubAccount;
