import React from "react";
import cx from "classnames";

import "./index.scss";

interface IOwnProps {
  title: string;
  subTitle: string;
  subTitleNewLine?: string;
  marginRightStyle?: boolean;
  titleMarginRightRemoved?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const LyveHeading = ({
  title,
  subTitle,
  subTitleNewLine,
  marginRightStyle,
  titleMarginRightRemoved,
  children,
  className
}: IOwnProps) => {
  const isMarginRemoved: boolean =
    typeof marginRightStyle !== "undefined" ? marginRightStyle : false;
  const isSubTitle: boolean = subTitle !== "" ? false : true;

  return (
    <div
      className={`${cx("lyve-page-title", {"m-title-remove": titleMarginRightRemoved})} ${(typeof className !== "undefined") ? className : ""}`}
    >
      <div className="heading-area">
        <h4 className={cx("lyve-page-main-title", { "m-remove": isSubTitle })}>
          {title}
        </h4>
        <p className="lyve-page-sub-title">{subTitle}</p>
        {subTitleNewLine ? (
          <p className="lyve-page-sub-title">{subTitleNewLine}</p>
        ) : null}
      </div>
      <div className={cx("button-area", { "m-remove": isMarginRemoved })}>
        {children}
      </div>
    </div>
  );
};

export default LyveHeading;
