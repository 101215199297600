export const ROLES_CLAIM_ID = "https://lyvecloud/role";

export const BUCKETS_VIEW = "buckets:view";
export const BUCKETS_EDIT = "buckets:edit";

export const ARCHIVE_BUCKETS_VIEW = "archive_buckets:view";
export const ARCHIVE_BUCKETS_EDIT = "archive_buckets:edit";

export const USERS_VIEW = "users:view";
export const USERS_EDIT = "users:edit";

export const PERMISSIONS_VIEW = "permissions:view";
export const PERMISSIONS_EDIT = "permissions:edit";

export const SA_VIEW = "service-accounts:view";
export const SA_EDIT = "service-accounts:edit";

export const NOTIFICATIONS_VIEW = "notifications:view";
export const NOTIFICATIONS_EDIT = "notifications:edit";

export const CUSTOMERS_VIEW = "customers:view";
export const CUSTOMERS_EDIT = "customers:edit";

export const SUPPORT_VIEW = "support:view";
export const SUPPORT_EDIT = "support:edit";

export const SETTINGS_VIEW = "settings:view";
export const SETTINGS_EDIT = "settings:edit";

export const TOGGLE_COMPLIANCE = "toggleCompliance:edit"
export const TOGGLE_S3_LOGS = "toggleS3Logs:edit"
export const BILLING_VIEW = "billing:view";
export const BILLING_EDIT = "billing:edit";
export const SMART_START_VIEW = "smart-start:view";
export const SLA_VIEW = "sla:view";

export const SUBACCOUNTS_VIEW = "subaccounts:view";
export const SUBACCOUNTS_MANAGE = "subaccounts:manage";
export const SUBACCOUNTS_EDIT = "subaccounts:edit";
export const SUBACCOUNTS_CREATE = "subaccounts:create";
export const SUBACCOUNTS_STORAGE_VIEW = "subaccounts-storage:view";
export const ANALYTICS_VIEW = "analytics:view";
export const ANALYTICS_EDIT = "analytics:edit";

export const rules = {
  "tenant-admin": [
    BUCKETS_VIEW,
    BUCKETS_EDIT,
    USERS_VIEW,
    USERS_EDIT,
    PERMISSIONS_VIEW,
    PERMISSIONS_EDIT,
    SA_VIEW,
    SA_EDIT,
    NOTIFICATIONS_VIEW,
    NOTIFICATIONS_EDIT,
    SUPPORT_VIEW,
    SUPPORT_EDIT,
    SETTINGS_VIEW,
    SETTINGS_EDIT,
    SUBACCOUNTS_CREATE,
    SUBACCOUNTS_EDIT,
    SUBACCOUNTS_MANAGE,
    SUBACCOUNTS_VIEW,
    SUBACCOUNTS_STORAGE_VIEW,
    TOGGLE_COMPLIANCE,
    TOGGLE_S3_LOGS,
    ANALYTICS_EDIT,
    ANALYTICS_VIEW
  ],
  "tenant-storage-admin": [
    BUCKETS_VIEW,
    BUCKETS_EDIT,
    PERMISSIONS_VIEW,
    PERMISSIONS_EDIT,
    SA_VIEW,
    SA_EDIT,
    TOGGLE_COMPLIANCE,
    SUBACCOUNTS_STORAGE_VIEW,
    TOGGLE_S3_LOGS,
  ],
  "tenant-auditor": [
    BUCKETS_VIEW,
    USERS_VIEW,
    PERMISSIONS_VIEW,
    SUPPORT_VIEW,
    SA_VIEW,
    NOTIFICATIONS_VIEW,
    SUBACCOUNTS_STORAGE_VIEW,
    SETTINGS_VIEW,
    ANALYTICS_VIEW
  ],
  "direct-admin": [
    BUCKETS_VIEW,
    BUCKETS_EDIT,
    ARCHIVE_BUCKETS_VIEW,
    ARCHIVE_BUCKETS_EDIT,
    USERS_VIEW,
    USERS_EDIT,
    PERMISSIONS_VIEW,
    PERMISSIONS_EDIT,
    SA_VIEW,
    SA_EDIT,
    NOTIFICATIONS_VIEW,
    NOTIFICATIONS_EDIT,
    SUPPORT_VIEW,
    SUPPORT_EDIT,
    SETTINGS_VIEW,
    SETTINGS_EDIT,
    BILLING_VIEW,
    BILLING_EDIT,
    SMART_START_VIEW,
    SLA_VIEW,
    SUBACCOUNTS_CREATE,
    SUBACCOUNTS_EDIT,
    SUBACCOUNTS_MANAGE,
    SUBACCOUNTS_STORAGE_VIEW,
    SUBACCOUNTS_VIEW,
    ANALYTICS_EDIT,
    ANALYTICS_VIEW
  ],
  "direct-storage-admin": [
    BUCKETS_VIEW,
    BUCKETS_EDIT,
    ARCHIVE_BUCKETS_VIEW,
    ARCHIVE_BUCKETS_EDIT,
    PERMISSIONS_VIEW,
    PERMISSIONS_EDIT,
    SA_VIEW,
    SA_EDIT,
    SUPPORT_VIEW,
    SUPPORT_EDIT,
    SMART_START_VIEW,
    SLA_VIEW,
    SUBACCOUNTS_STORAGE_VIEW,
  ],
  "direct-auditor": [
    BUCKETS_VIEW,
    USERS_VIEW,
    PERMISSIONS_VIEW,
    SA_VIEW,
    NOTIFICATIONS_VIEW,
    SUPPORT_VIEW,
    SETTINGS_VIEW,
    BILLING_VIEW,
    SMART_START_VIEW,
    SLA_VIEW,
    SUBACCOUNTS_STORAGE_VIEW,
    SUBACCOUNTS_VIEW,
    ANALYTICS_VIEW
  ],
  "partner-admin": [
    CUSTOMERS_VIEW,
    CUSTOMERS_EDIT,
    USERS_VIEW,
    USERS_EDIT,
    SUPPORT_VIEW,
    SUPPORT_EDIT,
    NOTIFICATIONS_VIEW,
    NOTIFICATIONS_EDIT
  ],
  "partner-customer-manager": [
    CUSTOMERS_VIEW,
    CUSTOMERS_EDIT,
    SUPPORT_VIEW,
    SUPPORT_EDIT
  ],
  "partner-auditor": [
    CUSTOMERS_VIEW,
    USERS_VIEW,
    SUPPORT_VIEW,
    NOTIFICATIONS_VIEW
  ],
  "super-admin": [
    BUCKETS_VIEW,
    BUCKETS_EDIT,
    USERS_VIEW,
    USERS_EDIT,
    PERMISSIONS_VIEW,
    PERMISSIONS_EDIT,
    SA_VIEW,
    SA_EDIT,
    NOTIFICATIONS_VIEW,
    NOTIFICATIONS_EDIT,
    CUSTOMERS_VIEW,
    CUSTOMERS_EDIT,
    SUPPORT_VIEW,
    SUPPORT_EDIT,
    SETTINGS_VIEW,
    SETTINGS_EDIT,
    ANALYTICS_EDIT,
    ANALYTICS_VIEW,
    SMART_START_VIEW,
    SLA_VIEW,
    SUBACCOUNTS_CREATE,
    SUBACCOUNTS_EDIT,
    SUBACCOUNTS_MANAGE,
    SUBACCOUNTS_VIEW,
    SUBACCOUNTS_STORAGE_VIEW,
  ]
};
