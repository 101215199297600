import React from "react";
import Navbar from "react-bootstrap/Navbar";

import { ReactComponent as LogoURL } from "../../images/Lyve-Cloud-Logo-Horiz-2-CLR.svg";
//import packageJson from "../../../package.json";
import Notification from "../Notification";
import ErrorNotification from "../Notification/ErrorNotification";
import WarningNotification from "../Notification/WarningNotification";
import "./styles.scss";

interface IOwnProps {
  children?: JSX.Element;
  partnerName: string;
  className?: string;
  rightElement?: React.ReactNode;
  handleLogoClick: () => void;
}

const Topbar: React.FC<IOwnProps> = ({
  children,
  className,
  rightElement,
  partnerName,
  handleLogoClick
}): JSX.Element => {
  return (
    <div className={`top-navbar ${className || ""}`}>
      <Notification isLoginNotification={false} />
      <ErrorNotification />
      <WarningNotification />
      {children || (
        <Navbar expand="md">
          <div className="lyve-topbar-logo">
            <LogoURL tabIndex={0} onClick={handleLogoClick} />
          </div>
          <div className="navbar-items">
            <div className="partner-name">
              {partnerName}
              {/* <span className={`dev-version ${partnerName ? 'dev-version-spacing' : ''}`}>{packageJson.version}</span> */}
            </div>
            {rightElement && rightElement}
          </div>
        </Navbar>
      )}
    </div>
  );
};

export default Topbar;
