import React from "react";
import { ExclamationCircle, ExclamationDiamond } from "react-bootstrap-icons";
import clsx from "clsx";

import "./styles.scss";

declare type LyveBannerType = "warning" | "error";

declare interface LyveBannerProps {
  msg: string | React.ReactNode;
  type: LyveBannerType;
  includeStartIcon?: boolean;
  startIcon?: React.ReactNode;
  className?: string;
  topLevelBanner?: boolean;
}

const BannerStartIcon: React.FC<{ type: LyveBannerType }> = props => {
  switch (props.type) {
    case "error":
      return <ExclamationCircle />;
    case "warning":
      return <ExclamationDiamond />;
  }
};

const LyveBanner: React.FC<LyveBannerProps> = ({
  startIcon: StartIcon,
  includeStartIcon = true,
  type,
  topLevelBanner = true,
  ...rest
}) => {
  return (
    <div
      className={clsx(
        `lc-banner lc-banner-${type}`,
        topLevelBanner && "lc-top-banner",
        rest.className
      )}
    >
      {includeStartIcon && (StartIcon ?? <BannerStartIcon type={type} />)}
      <span> {rest.msg} </span>
    </div>
  );
};

export default LyveBanner;
