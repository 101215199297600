import React from "react";
import { useDispatch } from "react-redux";
import { Alert, Button, Form } from "react-bootstrap";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import api from "../../../../../services/api";
import { SAMLConfig } from "../../types";
import { DefaultButton } from "../../../../LyveButton";
import DefaultAlert from "../../../../LyveAlert/DefaultAlert";
import LyveHeading from "../../../../LyveHeading";
import { IS_SAML_CONFIGURED } from "../../../../../state/IAM/actions";

type ConfigureSAMLProps = {
  onCancelClick: () => void;
  mode: "update" | "upload";
};

const ConfigureSAML: React.FC<ConfigureSAMLProps> = props => {
  const [fileName, setFileName] = React.useState("No file selected");
  const [fileDate, setFileDate] = React.useState("");
  const [fileContent, setFileContent] = React.useState<string | null>(null);
  const [uploadState, setUploadState] = React.useState<
    "none" | "fileLoaded" | "uploading" | "verified" | "failed"
  >("none");
  const [configuration, setConfiguration] = React.useState<SAMLConfig>({
    entityID: "",
    expires: "",
    name: "",
    providerURL: ""
  });
  const dispatch = useDispatch();

  const { onCancelClick } = props;

  const handleFile = (e: any) => {
    const files = e.target.files;
    if (files.length > 0) {
      const samlFile = files[0];
      setFileName(samlFile.name);
      if (samlFile.lastModified) {
        const lastModified = new Date(samlFile.lastModified);
        const splitDate = lastModified.toISOString().split("T");
        const time = splitDate[1].split(".")[0];
        setFileDate(`${splitDate[0]} ${time}`);
      }
      samlFile
        .text()
        .then((text: string) => {
          setFileContent(btoa(text));
          setUploadState("fileLoaded");
        })
        .catch((err: any) => {
          setUploadState("failed");
        });
    }
  };

  const handleApply = () => {
    if (fileContent) {
      setUploadState("uploading");
      if (props.mode === "update") {
        api.updateSAMLMetadata(fileContent).then(
          response => {
            setConfiguration(response);
            setUploadState("verified");
          },
          () => {
            setUploadState("failed");
          }
        );
      } else {
        api.uploadSAMLMetadata(fileContent).then(
          response => {
            setConfiguration(response);
            setUploadState("verified");
            dispatch({
              type: IS_SAML_CONFIGURED,
              payload: true
            });
          },
          () => {
            setUploadState("failed");
          }
        );
      }
    }
  };

  const renderUploadButton = () => {
    return (
      <Form.Group controlId="attachment">
        {/* <div className="btn btn-outline-secondary btn-file">
          <span className="btn-title">
            <PublishOutlinedIcon fontSize="small" />{" "}
            {uploadState === "failed" ? "Upload again" : "Upload"}
          </span>
          <Form.Control
            type="file"
            size="sm"
            accept=".xml"
            onChange={handleFile}
          />
        </div> */}
        <DefaultButton variant="outline-secondary" className="btn-file">
          <span className="btn-title">
            <PublishOutlinedIcon fontSize="small" />{" "}
            {uploadState === "failed" ? "Upload again" : "Upload"}
          </span>
          <Form.Control
            type="file"
            size="sm"
            accept=".xml"
            onChange={handleFile}
          />
        </DefaultButton>
      </Form.Group>
    );
  };

  const renderActionButtons = () => {
    if (uploadState === "verified") {
      return (
        <div className="mt-4">
          <Button className="saml-apply" onClick={onCancelClick}>
            Okay
          </Button>
        </div>
      );
    }
    return (
      <div className="mt-4">
        <DefaultButton
          disabled={uploadState !== "fileLoaded"}
          onClick={handleApply}
          className="mr-2"
        >
          Apply
        </DefaultButton>
        <DefaultButton variant="outline-secondary" onClick={onCancelClick}>
          Cancel
        </DefaultButton>

        {/* <Button
          className="saml-apply"
          disabled={uploadState !== "fileLoaded"}
          onClick={handleApply}
        >
          Apply
        </Button>
        <Button variant="link" className="saml-cancel" onClick={onCancelClick}>
          Cancel
        </Button> */}
      </div>
    );
  };

  const renderAlert = () => {
    if (uploadState === "verified") {
      return (
        // <Alert variant={"success"} className="mt-4 w-50">
        //   <span className="font-weight-bold">Well done!</span> Your XML File has
        //   been successfully verified.
        // </Alert>
        <DefaultAlert variant="success" customClasses="mt-4 mb-4 w-50">
          <span className="font-weight-bold">Well done!</span> Your XML File has
          been successfully verified.
        </DefaultAlert>
      );
    }
    if (uploadState === "failed") {
      return (
        // <Alert variant={"danger"} className="mt-4 w-50">
        // <span className="font-weight-bold">File upload error!</span> The XML
        // metadata file seems to be invalid or has already been used.
        // </Alert>

        <DefaultAlert variant="danger" customClasses="mt-4 mb-4 w-50">
          <span className="font-weight-bold">File upload error!</span> The XML
          metadata file seems to be invalid or has already been used.
        </DefaultAlert>
      );
    }
  };

  const renderConfigurationDetails = () => {
    if (uploadState === "verified") {
      return (
        <>
          <div className="mt-5 modalTitle">
            Identity Provider Configuration Details
          </div>
          <div className="mb-3">
            Please add the following metadata to your IdP settings
          </div>
          <div className="row">
            <div className="col-1">Provider URL</div>
            <div className="col font-weight-bold">
              {configuration.providerURL}
            </div>
          </div>
          <div className="row">
            <div className="col-1">Entity ID</div>
            <div className="col font-weight-bold">{configuration.entityID}</div>
          </div>
        </>
      );
    }
  };

  return (
    <div className="container-fluid">
      <div className="row no-gutter">
        <div className="col">
          {/* <div className="px-3"> */}
          <div className="row" style={{ paddingTop: "32px" }}>
            <div className="col-12">
              {/* <div className="pageTitle">Configure Federated Login</div> */}
              <LyveHeading
                title="Configure Federated Login"
                subTitle=""
                marginRightStyle={true}
              ></LyveHeading>
              <div className="d-flex my-4 align-items-center">
                Please upload your SAML metadata file below to complete this
                configuration
              </div>
              <div className="fileForm">
                <p>SAML Metadata File Upload</p>
                <div className="row w-25">
                  <div className="col font-weight-bold">{fileName}</div>
                  <div className="col">{fileDate}</div>
                </div>
                {renderAlert()}
                {renderConfigurationDetails()}
                {(uploadState === "none" || uploadState === "failed") &&
                  renderUploadButton()}
              </div>
              {renderActionButtons()}
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default ConfigureSAML;
