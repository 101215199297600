export const TOGGLE_TENANT_DISPLAY_MODAL = "TOGGLE_TENANT_DISPLAY_MODAL";
export const TENANT_DISPLAY_MODAL = "TENANT_DISPLAY_MODAL";
export const TENANT_HIDE_MODAL = "TENANT_HIDE_MODAL";

export interface TenantsState {
  displayModal: boolean;
}

interface ToggleTenantDisplayModalAction {
  type: typeof TOGGLE_TENANT_DISPLAY_MODAL;
}

export interface TenantDisplayModalAction {
  type: typeof TENANT_DISPLAY_MODAL;
}

export interface TenantHideModalAction {
  type: typeof TENANT_HIDE_MODAL;
}

export type ToggleTenantDisplayModalActionTypes = ToggleTenantDisplayModalAction;
