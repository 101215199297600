import React, { useEffect, useState } from "react";
import { Data } from ".";
import api from "../../services/api";
import { DefaultButton } from "../LyveButton";
import storage from "local-storage-fallback";
import { ButtonLoader } from "../LyveLoader";

interface Props {
  // hideModal: any;
  // submit: any;
  // firstName?: string;
  // lastName?: string;
  // email?: string;
  editDetails: Data | null;
  closeModal?: () => void;
  onSuccess?: () => void;
  onFailure?: () => void;
}

const RemoveModal = ({
  // hideModal,
  // submit,
  // firstName,
  // lastName,
  // email,
  editDetails,
  onSuccess,
  onFailure,
  closeModal
}: Props) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [userDetails, setUserDetails] = useState<Data | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  // const [customerId, setCustomerId] = useState<string>("null");

  /** Effect */
  useEffect(() => {
    // const companyId = storage.getItem("company_id");
    // const customer_id = companyId ? companyId : "";
    // setCustomerId(customer_id);
    if (editDetails) { initalDetails(editDetails); }

  }, []);

  /** Functions */
  const initalDetails = (userDetails: Data) => {
    setFirstName(userDetails.firstname);
    setLastName(userDetails.lastname);
    setEmail(userDetails.email);
    setUserDetails(userDetails);
  };

  const removeRecipient = () => {
    setProcessing(true);
    api.removeNotificationRecipient(userDetails ? userDetails.id : "").then(() => {
      //onsuccess
      setProcessing(false);
      if (typeof closeModal !== "undefined") {
        closeModal();
      }
      if (typeof onSuccess !== "undefined") { onSuccess(); }
    }).catch(err => {
      setProcessing(false);
      if (typeof closeModal !== "undefined") {
        closeModal();
      }
      if (typeof onFailure !== "undefined") { onFailure(); }
    });
  };

  return (
    // <div
    //   className="popup lyve-popup"
    //   id="exampleModal"
    //   role="dialog"
    //   aria-labelledby="remove-notification-modal"
    //   aria-hidden="true"
    // >
    //   <div
    //     className="modal-dialog modal-dialog-centered"
    //     role="document"
    //     style={{ width: "640px" }}
    //   >
    //     <div className="modal-content">
    //       <div className="modal-header">
    //         <h5 className="modal-title" id="remove-notification-modal">
    //           Are you sure you want to remove the following recipient?
    //         </h5>
    //         <button
    //           type="button"
    //           className="close"
    //           data-dismiss="modal"
    //           aria-label="Close"
    //           onClick={hideModal}
    //         >
    //           <span aria-hidden="true">&times;</span>
    //         </button>
    //       </div>
    //       <div className="modal-body">
    <>
      <strong className="name">
        {typeof firstName !== "undefined" &&
          typeof lastName !== "undefined"
          ? `${firstName} ${lastName}`
          : ""}
      </strong>
      <br />
      <span className="email">
        {typeof email !== "undefined" ? `${email}` : ""}
      </span>
      {/* // </div> */}
      {/* // <div className="modal-footer" style={{ paddingTop: 0 }}> */}
      <div className="mt-4">
        <DefaultButton disabled={processing} variant="danger" onClick={removeRecipient} className="mr-2">
          {processing ? <ButtonLoader animation="border" /> : ""}&nbsp;Yes
            </DefaultButton>
        <DefaultButton variant="outline-secondary" onClick={(typeof closeModal !== "undefined") ? closeModal : undefined}>
          No
            </DefaultButton>
      </div>
    </>
    //     </div>
    //   </div>
    // </div>
  );
};

export default RemoveModal;
