import React, { useState } from "react";
import {
  Briefcase,
  BriefcaseFill,
  Envelope,
  EnvelopeFill,
  Headset,
  House,
  HouseFill,
  InfoCircle,
  Lock,
  LockFill,
  People,
  PeopleFill,
  Person,
  QuestionCircle,
  QuestionCircleFill,
  ShieldLock,
  ShieldLockFill
} from "react-bootstrap-icons";
import { Sidebar, Topbar, useNavbar } from "../../components/NavigationBar";
import common from "../../services/common";
import LyveDropdown from "../../components/Menu/LyveDropdown";
import {
  CUSTOMERS_VIEW,
  NOTIFICATIONS_VIEW,
  rules,
  SUPPORT_VIEW,
  USERS_VIEW
} from "../../authRules";
import { isAllowedNavTab } from "../../scenes/Layout";
import packageJson from "../../../package.json";
// import Sidebar from "./Sidebar";

import "../../scenes/Layout/styles.scss";
import { getLink, linkNames } from "../../components/LyveHeadingLink/LinkUtil";
import LyvePopup from "../../components/Modal/LyvePopup";
import { DefaultButton } from "../../components/LyveButton";
import { ButtonLoader } from "../../components/LyveLoader";
import api from "../../services/api";
import { setNotification } from "../../components/Notification/actions";
import { useDispatch } from "react-redux";

const SupportIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-headset-custom"
      viewBox="0 0 16 16"
    >
      <path d="M8,0A6,6,0,0,0,2,6v5a1,1,0,0,0,1,1H4a1,1,0,0,0,1-1V8A1,1,0,0,0,4,7H3V6A5,5,0,0,1,13,6V7H12a1,1,0,0,0-1,1v3a1,1,0,0,0,1,1h1a1.5,1.5,0,0,1-1.5,1.5H9.37A1,1,0,0,0,8.5,13h-1a1,1,0,0,0,0,2h1a1,1,0,0,0,.87-.5H11.5A2.5,2.5,0,0,0,14,12V6A6,6,0,0,0,8,0ZM4,8v3H3V8Zm9,3H12V8h1Z" />
    </svg>
  );
};

const MainLayout: React.FC = props => {
  const dispatch = useDispatch();
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);
  const { children } = props;

  const [resetPassModal, setResetPassModal] = React.useState(false);
  const triggerResetPassModal = () => setResetPassModal(!resetPassModal);
  const [resettingPassword, setResettingPassword] = React.useState(false);

  const current_env = "CURRENT_ENV";
  const currentEnv = localStorage.getItem(current_env);

  const {
    customerName,
    partnerName,
    userName,
    firstName,
    userRole,
    userEmail,
    userType,
    handleLogout,
    handleLogoClick
  } = useNavbar();

  const role = `${userType}-${userRole}`;

  React.useEffect(() => {
    if (!currentEnv) {
      api.getCurrentEnv().then((res: any) => {
        localStorage.setItem(current_env, res?.env);
      });
    }
  }, [currentEnv]);

  const resetPassword = () => {
    if (firstName) {
      setResettingPassword(true);
      api
        .userResetPassword(firstName, userEmail)
        .then(() =>
          dispatch(
            setNotification(
              `A password reset Email has been sent to ${userEmail}`
            )
          )
        )
        .catch(() =>
          dispatch(
            setNotification(
              `Failed to send reset email invite to ${userEmail}. Please try again later`
            )
          )
        )
        .finally(() => {
          setResettingPassword(false);
          triggerResetPassModal();
        });
    } else {
      dispatch(
        setNotification(
          `Failed to send reset email invite to ${userEmail}. Please try again later`
        )
      );
      setResettingPassword(false);
      triggerResetPassModal();
    }
  };

  const getTopbarAccountOptions = () => {
    const options = [
      {
        header: (
          <>
            <span className="option-header">{customerName}</span>
            {packageJson.version && (
              <span className="dev-version">{packageJson.version}</span>
            )}
          </>
        ),
        label: (
          <span>
            <span className="option-title">{userName}</span> <br />
            {userRole && (
              <>
                <span className="option-title option-subtitle text-capitalize">
                  Role: {userRole}
                </span>{" "}
                <br />
              </>
            )}
            <span className="option-title option-subtitle text-break">{userEmail}</span>
          </span>
        )
      },
      {
        hasDivider: true,
        action: "reset-password",
        label: <span className="option-title">Reset Password</span>,
        onClick: triggerResetPassModal
      },
      {
        action: "logout",
        label: <span className="option-title">Log Out</span>,
        onClick: () => {
          handleLogout();
        }
      }
    ];

    return options;
  };

  const topbarRightElement = () => {
    return (
      <div className="d-flex topbar-right-element">
        <div className="topbar-right-icon">
          <a
            className="topbar-right-label"
            href={getLink(linkNames.Help)}
            target="_blank"
          >
            <InfoCircle size={16} />
            <span className="topbar-right-label">Help</span>
          </a>
        </div>
        <LyveDropdown
          parentDropdownClass={"lyve-topbar-dropdown"}
          id="topbar-account-option"
          trigger={
            <div
              className={`topbar-right-icon ${showAccountDropdown ? "topbar-pressed-icon" : ""
                }`}
              onClick={() => setShowAccountDropdown(!showAccountDropdown)}
            >
              <Person size={16} />
              <span className="topbar-right-label">{userName}</span>
            </div>
          }
          drop={"down"}
          hasManualToggle={true}
          manualShow={showAccountDropdown}
          onToggle={(isOpen, _event, _meta) => {
            if (!isOpen) {
              setShowAccountDropdown(false);
            }
          }}
          alignRight={true}
          width="max-content"
          options={getTopbarAccountOptions()}
        />
      </div>
    );
  };

  const getSidebarOption = () => {
    const options = [
      {
        label: "Home",
        icon: <House />,
        selectedIcon: <HouseFill />,
        actionPerformed: "",
        url: "/partner/dashboard",
        subUrls: [],
        isSection: false
      },
      ...(isAllowedNavTab(rules, role, CUSTOMERS_VIEW)
        ? [
          {
            label: "Customers",
            icon: <Briefcase />,
            selectedIcon: <BriefcaseFill />,
            actionPerformed: CUSTOMERS_VIEW,
            isTopMargin: true,
            url: "/partner/tenant",
            subUrls: [],
            isSection: false
          }
        ]
        : []),
      {
        label: "Identity & Access",
        icon: "",
        selectedIcon: "",
        actionPerformed: "",
        url: "",
        subUrls: [],
        isSection: true,
        options: [
          ...(isAllowedNavTab(rules, role, USERS_VIEW)
            ? [
              {
                label: "Users",
                icon: <People />,
                selectedIcon: <PeopleFill />,
                url: "/partner/users",
                subUrls: [],
                actionPerformed: USERS_VIEW,
                isSection: false
              }
            ]
            : []),
          ...(isAllowedNavTab(rules, role, USERS_VIEW)
            ? [
              {
                label: "MFA",
                icon: <ShieldLock />,
                selectedIcon: <ShieldLockFill />,
                actionPerformed: USERS_VIEW,
                url: "/partner/mfa",
                subUrls: [],
                isSection: false
              }
            ]
            : []),
          ...(isAllowedNavTab(rules, role, USERS_VIEW)
            ? [
              {
                label: "Federated Login",
                icon: <Lock />,
                selectedIcon: <LockFill />,
                actionPerformed: USERS_VIEW,
                url: "/partner/saml-federation",
                subUrls: [],
                isSection: false
              }
            ]
            : []),
          ...(isAllowedNavTab(rules, role, NOTIFICATIONS_VIEW)
            ? [
              {
                label: "Notification Recipients",
                icon: <Envelope />,
                selectedIcon: <EnvelopeFill />,
                url: "/partner/notifications",
                subUrls: [],
                actionPerformed: NOTIFICATIONS_VIEW,
                isSection: false
              }
            ]
            : [])
        ]
      },
      {
        label: "Your Account",
        icon: "",
        selectedIcon: "",
        actionPerformed: "",
        url: "",
        subUrls: [],
        isSection: true,
        options: [
          ...(isAllowedNavTab(rules, role, SUPPORT_VIEW)
            ? [
              {
                label: "Support",
                icon: <SupportIcon />,
                selectedIcon: <Headset />,
                actionPerformed: SUPPORT_VIEW,
                url: "/partner/support",
                subUrls: [
                  "/partner/support-dashboard/ticket-details",
                  "/partner/support-resources"
                ],
                isSection: false
              }
            ]
            : [])
        ]
      }
    ];

    return options;
  };

  return (
    <div className="lyve-main-wrapper">
      <div className="lyve-global-header">
        <LyvePopup
          header
          show={resetPassModal}
          onHide={triggerResetPassModal}
          title="Are you sure you want to reset your password?"
          footer={
            <div>
              <DefaultButton
                onClick={resetPassword}
                disabled={resettingPassword}
              >
                {resettingPassword ? (
                  <ButtonLoader animation="border" />
                ) : (
                  "Yes"
                )}
              </DefaultButton>
              <DefaultButton
                variant="outline-secondary"
                onClick={triggerResetPassModal}
              >
                No
              </DefaultButton>
            </div>
          }
        >
          <div>
            {" "}
            A change password email will be sent to you with a URL token to
            reset your password.{" "}
          </div>
        </LyvePopup>
        <Topbar
          partnerName={common.isTenant(userType) ? partnerName : ""}
          handleLogoClick={handleLogoClick}
          rightElement={topbarRightElement()}
        />
      </div>
      <div className="lyve-wrapper">
        <Sidebar options={getSidebarOption()} />
        {/* <div className="lyve-global-sidebar">
          <Sidebar />
        </div> */}
        <div className="lyve-page-wrapper">
          <div className="mainContent">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
