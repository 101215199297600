import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { useMediaPredicate } from "react-media-hook";
import { ArrowBarLeft, ArrowBarRight } from "react-bootstrap-icons";
import Can from "../Can";
import history from "../../history";

export interface ISidebarOption {
  label: string;
  url: string;
  icon: React.ReactNode | string;
  isTopMargin?: boolean;
  selectedIcon: React.ReactNode | string;
  subUrls: string[];
  actionPerformed: string;
  isSection: boolean;
  options?: ISidebarOption[];
  tag?: React.ReactElement
  isOpenNewTab?: boolean;
}

interface IOwnProps {
  className?: string;
  isTopBannerAdded?: boolean;
  options: ISidebarOption[];
}

const SideBar: React.FC<IOwnProps> = ({
  className,
  options,
  isTopBannerAdded
}) => {
  const [isExpand, setExpand] = useState(true);
  const [isUserPerformedAction, setUserPerformedAction] = React.useState(false);
  const autoCollapseSidebar = useMediaPredicate("(min-width: 1280px)");

  useEffect(() => {
    if (autoCollapseSidebar) {
      if (!isUserPerformedAction) {
        setExpand(true);
      }
    } else {
      setExpand(false);
    }
  }, [autoCollapseSidebar]);

  const isActive = (path: string, additionalUrls: string[]) => {
    let isActivePath = false;

    if (!path) {
      return isActivePath;
    }

    if (path && additionalUrls.length) {
      isActivePath = history.location.pathname.endsWith(path);

      additionalUrls.forEach(url => {
        isActivePath =
          isActivePath || history.location.pathname.indexOf(url) != -1;
      });
    } else {
      isActivePath = history.location.pathname.endsWith(path);
    }

    return isActivePath;
  };

  const renderSidebar = (option: ISidebarOption) => {
   return option.actionPerformed && option.label!="Billing" ? (
      
      <Can perform={option.actionPerformed}>
        <div
          className={`sidebar-nav-wrapper ${
            option.isTopMargin ? "sidebar-nav-wrapper-margin" : ""
          } ${!isExpand ? "sidebar-nav-wrapper-collapse" : ""} ${
            isActive(option.url, option.subUrls) ? "active-nav-link" : ""
          }`}
          // onClick={() =>
          //   option.url && option.isOpenNewTab
          //     ? window.open(option.url, "_blank")
          //     : history.push(option.url)
          // }
          data-for="lyve-sidebar-nav-action-tooltip"
          data-tip={option.label}
        >
          <a href={option.url} className="sidebar-nav-item" 
            onClick={e => {
              e.preventDefault()
              option.url && option.isOpenNewTab
                ? window.open(option.url, "_blank")
                : history.push(option.url)
            }}>
            {option.icon ? (
              isActive(option.url, option.subUrls) ? (
                option.selectedIcon
              ) : (
                option.icon
              )
            ) : (
              <div className="sidebar-nav-empty-icon" />
            )}
            {isExpand ? (
              <>
                <div className="sidebar-nav-text">{option.label}</div>
                {option.tag}
              </>
            ) : null}
          </a>
          {!isExpand && (
            <ReactTooltip
              id="lyve-sidebar-nav-action-tooltip"
              place="right"
              effect="solid"
            />
          )}
        </div>
      </Can>
    ) : (
      <div
        className={`sidebar-nav-wrapper ${
          option.isTopMargin ? "sidebar-nav-wrapper-margin" : ""
        } ${!isExpand ? "sidebar-nav-wrapper-collapse" : ""} ${
          isActive(option.url, option.subUrls) ? "active-nav-link" : ""
        }`}
        // onClick={() =>
        //   option.url && option.isOpenNewTab
        //     ? window.open(option.url, "_blank")
        //     : history.push(option.url)
        // }
        data-for="lyve-sidebar-nav-tooltip"
        data-tip={option.label}
      >
        <a href={option.url} className="sidebar-nav-item"
          onClick={e => {
            e.preventDefault()
            option.url && option.isOpenNewTab
              ? window.open(option.url, "_blank")
              : history.push(option.url)
          }}>
          {option.icon ? (
            isActive(option.url, option.subUrls) ? (
              option.selectedIcon
            ) : (
              option.icon
            )
          ) : (
            <div className="sidebar-nav-empty-icon" />
          )}
          {isExpand ? (
            <>
              <div className="sidebar-nav-text">{option.label}</div>
              {option.tag}
            </>
          ) : null}
        </a>
        {!isExpand && (
          <ReactTooltip
            id="lyve-sidebar-nav-tooltip"
            place="right"
            effect="solid"
          />
        )}
      </div>
    );
  };

  const renderSidebarWithSection = (option: ISidebarOption) => {
    if (option.options?.length) {
      return isExpand ? (
        <div className="sidebar-section">
          <div className="sidebar-section-item">
            <div className="sidebar-section-text">{option.label}</div>
          </div>
          {option.options?.map((sectionOption, key) => {
            return <div key={key}>{renderSidebar(sectionOption)}</div>;
          })}
        </div>
      ) : (
        <div className="sidebar-section">
          <div className="sidebar-section-collapse-item">
            <div className="sidebar-section-collapse-text" />
          </div>
          {option.options?.map((sectionOption, key) => {
            return <div key={key}>{renderSidebar(sectionOption)}</div>;
          })}
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div
      className={`lyve-sidebar ${className || ""} ${
        !isExpand ? "lyve-sidebar-collapse" : ""
      } ${isTopBannerAdded ? "add-sidebar-banner-height" : ""}`}
    >
      <div className="navigation-container">
        <div className="lyve-sidebar-list">
          {options.map((option, key) => {
            return (
              <div key={key}>
                
                {option.isSection
                  ? renderSidebarWithSection(option)
                  : renderSidebar(option)}
              </div>
            );
          })}
        </div>
      </div>

      <div
        className={`toggle-btn-wrapper ${
          !isExpand
            ? "toggle-btn-wrappe-collapse collapse-toggle-btn-wrapper"
            : "expand-toggle-btn-wrapper"
        }`}
      >
        <div
          className="toggle-btn"
          onClick={() => {
            setExpand(!isExpand);
            setUserPerformedAction(true);
          }}
          data-for="lyve-sidebar-toggle-tooltip"
          data-tip={isExpand ? "Collapse" : "Expand"}
        >
          <div className="toggle-btn-icon-wrapper">
            {isExpand ? <ArrowBarLeft /> : <ArrowBarRight />}
          </div>
          <ReactTooltip
            id="lyve-sidebar-toggle-tooltip"
            place="right"
            effect="solid"
          />
        </div>
      </div>
    </div>
  );
};

export default SideBar;
