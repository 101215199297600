import { openDB } from "idb";
import { detect } from "detect-browser";
import piexifjs from "piexifjs";
import { WhoAmI } from "../../../../../state/UserInfo/types";

export const toBase64 = (file: any) =>
  new Promise<string>((resolve, reject) => {
    const fileType = file.name
      .split(".")
      .pop()
      .toLowerCase();
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result instanceof ArrayBuffer) {
        resolve("");
      } else if (
        reader.result !== null &&
        typeof reader.result !== "undefined"
      ) {
        if (fileType === "jpg" || fileType === "jpeg") {
          resolve(piexifjs.remove(reader.result));
        } else {
          resolve(reader.result);
        }
      }
    };
    reader.onerror = error => reject(error);
  });

export const checkFileSize = (file: any) => {
  let fileSize = file.size;
  // console.log(file);
  const fileSizeMB = fileSize / 1000000;
  // console.log("File Size: " + fileSize);
  // console.log("File Size in MB : " + fileSizeMB);

  if (fileSizeMB <= 4) {
    return false;
  } else {
    return true;
  }
};

export const getPartnerDetails = (whoAmI: WhoAmI) => {
  const userType = whoAmI?.type ?? "";
  return {
    company_name: whoAmI?.company_name ?? "",
    user_type: userType,
    isAnyCustomer: (userType === "direct" || userType === "tenant") ? true : false,
  };
};

export const checkWorkerSupport = () => {
  return !!Worker;
};

// Function to check if IDB is available
export const checkIfIDBIsAvailable = async () => {
  // try {
  const browser = detect();
  if (browser) {
    if (browser.name === "chrome") {
      return Promise.resolve(true);
    } else if (browser.name === "firefox") {
      try {
        const db = await openDB("test", 1);
        return Promise.resolve(true);
      } catch (error) {
        // console.log("cant use IDB");
        return Promise.resolve(false);
      }
    } else {
      return Promise.resolve(true);
    }
  } else {
    return Promise.resolve(false);
  }
};
