import React from "react";
import { useController, UseControllerOptions } from "react-hook-form";
import { Form, FormControlProps } from "react-bootstrap";
import omit from "lodash/omit";
import { CheckCircleFill } from "react-bootstrap-icons";
import { FormInputs } from "./types";
import cx from "classnames";

export declare type ValidationMessagesProps = {
  message: string;
  value: string;
  validator: (value: string) => boolean;
};

declare type ControlledInputProps = {
  label: string;
  controlProps?: FormControlProps & React.HTMLAttributes<HTMLDivElement>;
  error?: string;
  setBlur?: () => void;
  setFocus?: () => void;
} & UseControllerOptions<FormInputs>;

type Fn = ((...args: Array<any>) => void) | undefined;

const callAll = (...fns: Array<Fn>) => (...args: Array<any>) =>
  fns.forEach( fn => fn?.(...args));

const ControlledInput: React.FC<ControlledInputProps> = ({
  label,
  controlProps,
  error,
  setBlur,
  setFocus,
  ...rest
}) => {
  const { field } = useController(rest);

  return (
    <>
      {label && <Form.Label> {label} </Form.Label>}
      {React.cloneElement(React.Children.only(rest.children) as any, {
        ...controlProps,
        ...omit(field, ["ref", "onBlur"]),
        onFocus: setFocus,
        onBlur: callAll(field.onBlur, setBlur)
      })}
      {error && (
        <div className={cx("bucket-name-error", !error && "d-none")}>
          {error}
        </div>
      )}
    </>
  );
};

const ValidationMessage: React.FC<ValidationMessagesProps> = ({
  value,
  message,
  validator
}) => {
  const valid = validator(value);
  return (
    <div className={`name-error ${valid ? "valid" : "default"}`}>
      <CheckCircleFill size="16px" />
      <span className="message"> {message} </span>
    </div>
  );
};

const validateLength = (value: string) =>
  !!value && value.length >= 3 && value.length <= 63;

const validateSpecialChars = (value: string) =>
  !!value && /^[^A-Z\s_]+$/.test(value);

const validateChars = (value: string) => !!value && /^[a-z\d-]+$/.test(value);

const validateEdges = (value: string) => {
  const firstChar = value.charAt(0);
  const lastChar = value.charAt(value.length - 1);
  return !!value && /^[a-z0-9]*$/.test(firstChar) && lastChar !== "-";
};

const validators = [
  validateLength,
  validateSpecialChars,
  validateChars,
  validateEdges
];

export {
  ControlledInput,
  ValidationMessage,
  validateChars,
  validateEdges,
  validateLength,
  validateSpecialChars,
  validators
};
