import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import { DefaultButton } from "../LyveButton";

interface ErrorStateOverlayProps {
  title: string;
  subtitle: string;
  closeBtnLabel: string;
  retryBtnLabel: string;
  error: any;
  isLoading: boolean;
  handleClose: () => void;
  handleRetry?: () => void;
}

const ErrorStateOverlay: React.FC<ErrorStateOverlayProps> = ({
  title,
  subtitle,
  closeBtnLabel,
  retryBtnLabel,
  error,
  isLoading,
  handleClose,
  handleRetry
}) => {
  return (
    <Modal
      show={true}
      backdrop="static"
      centered={true}
      size={"sm"}
      dialogClassName="loading-error-state-modal"
    >
      <Modal.Body>
        <div className="d-flex flex-column">
          <div className="title">{title}</div>
          <div className="subtitle">{subtitle}</div>
          <div>
            {error !== "" && <p className="validation-error">{error}</p>}
            {retryBtnLabel && (
              <DefaultButton
                onClick={handleRetry}
                className="mr-2"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner animation="border" role="status" className="spinner">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  retryBtnLabel
                )}
              </DefaultButton>
            )}
            <DefaultButton variant="outline-secondary" onClick={handleClose}>
              {closeBtnLabel}
            </DefaultButton>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ErrorStateOverlay;
