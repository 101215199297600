import React from "react";
import cx from "classnames";
import get from "lodash/get";
import uniq from "lodash/uniq";
import { isEqual, sortBy } from "lodash";
import { Form, Spinner } from "react-bootstrap";
import { CheckCircleFill } from "react-bootstrap-icons";

import api from "../../../../../../services/RegionApis";
import Input from "../../../../../../components/Input";
import { LyveSelect } from "../../../../../../components/LyveDropdown";
import { DefaultButton } from "../../../../../../components/LyveButton";
import DefaultAlert from "../../../../../../components/LyveAlert";

import "./styles.scss";
import { useAppSelector } from "../../../../../../store";
import { selectRegionsWithSG } from "../../../../../../state/regionswithsg";
import LyveLink from "../../../../../../components/LyveHeadingLink/LyveLink";
import {
  getLink,
  linkNames
} from "../../../../../../components/LyveHeadingLink/LinkUtil";

interface CreatePromptProps {
  items?: any;
  itemsLoading: boolean;
  permission?: any;
  permissions?: any;
  baseUrl: string;
  onSuccess?: (data: any) => void;
  onError?: (err: any) => void;
}

const CreatePrompt: React.FC<CreatePromptProps> = ({
  items,
  itemsLoading,
  permission,
  permissions,
  baseUrl,
  onSuccess = () => null,
  onError = () => null
}) => {
  const type = !!permission ? "edit" : "create";
  const initialValues =
    permission?.option === "prefix" || permission?.option === "all"
      ? { ...permission, resources: [] }
      : permission || {
          name: "",
          effect: "Allow",
          resources: [],
          prefix: "",
          actions: [{ type: "readwrite" }],
          option: "selection"
        };

  // const classes = useStyles();
  const [error, setError] = React.useState<string>("");
  const [isFormProcessing, setFormProcessing] = React.useState<boolean>(false);
  const { data: regionswithsg } = useAppSelector(selectRegionsWithSG);
  const [name, setName] = React.useState<string>(initialValues.name);
  const [effect, setEffect] = React.useState<string>(initialValues.effect);
  const [resources, setResources] = React.useState<string[]>(
    initialValues.resources.map((resource: any) => ({
      ...resource,
      label: resource.bucket_name,
      value: resource.bucket_name
    }))
  );

  const [selectedResources, setSelectedResources] = React.useState<string[]>(
    initialValues.resources.map((resource: any) => ({
      ...resource,
      label: resource.bucket_name,
      value: resource.bucket_name
    }))
  );

  const [actions, setActions] = React.useState<string>(
    get(initialValues, "actions[0].type", "readwrite")
  );

  const [prefix, setPrefix] = React.useState<string>(
    initialValues.prefix || ""
  );
  const [option, setOption] = React.useState<string>(
    initialValues.option || ""
  );

  let nameError = "";
  let prefixError = "";

  const nameValidation = (): boolean => {
    if (name.length == 0) {
      return false;
    }
    if (!name.trim() || !/^[a-zA-Z\d-_ ]+$/.test(name)) {
      // nameError =
      //   "Invalid format. Please use only alphanumeric, '-', '_' or space.";
      return false;
    }
    return true;
  };

  const nameLengthValidation = () => !!name && name.length <= 128;

  const prefixValidation = (): boolean => {
    if (prefix.length === 0) {
      return false;
    }
    if (!prefix.trim() || !/^(?!\-)[a-z\d\-\s]*$/.test(prefix)) {
      // prefixError =
      //   "Invalid format. Please use only lowercase characters, alphanumeric in the prefix.";
      return false;
    }

    if (prefix.includes(" ")) {
      return false;
    }

    return true;
  };

  const prefixLengthChk = () => !!prefix && prefix.length <= 64;

  const getPermissionDescription = () => {
    const operations: any = {
      readwrite: "all operations",
      read: "read only",
      write: "write only"
    };
    let descriptionText = "";
    const buckets = uniq(resources.map((resource: any) => resource.value));
    if (buckets.length > 0) {
      descriptionText = `Allow ${operations[actions]} on bucket${
        buckets.length > 1 ? "s" : ""
      } ${buckets.join()}`;
    }
    if (prefix && option === "prefix") {
      if (actions === "readwrite") {
        descriptionText = `Allow create, delete and full access to all buckets with name starting with ${prefix}`;
      } else {
        descriptionText = `Allow ${operations[actions]} on all buckets with name starting with ${prefix}`;
      }
    }
    if (option === "all") {
      if (actions === "readwrite") {
        descriptionText = `Allow create, delete and full access to all buckets`;
      } else {
        descriptionText = `Allow ${operations[actions]} on all buckets`;
      }
    }
    return descriptionText;
  };

  const createPermission = (sg_url: string) => {
    api
      .addPermission(
        name,
        description,
        effect,
        resources.map((resource: any) => resource.value),
        [actions],
        option,
        prefix,
        sg_url
      )
      .then(data => {
        setFormProcessing(false);
        onSuccess(data);
      })
      .catch(err => {
        if (regionswithsg.length !== 0) {
          let index = regionswithsg
            .map(function(e) {
              return e.url;
            })
            .indexOf(sg_url);
          if (index == regionswithsg.length - 1) {
            setFormProcessing(false);
            setError(err);
            onError(err);
          } else {
            createPermission(regionswithsg[index + 1].url);
          }
        } else {
          setFormProcessing(false);
          setError(err);
          onError(err);
        }
      });
  };

  const updatePermission = (sg_url: string) => {
    api
      .updatePermission(
        permission.id,
        name,
        description,
        effect,
        resources.map((resource: any) => resource.value),
        [actions],
        option,
        prefix,
        sg_url
      )
      .then(data => {
        setFormProcessing(false);
        onSuccess(data);
      })
      .catch(err => {
        if (regionswithsg.length !== 0) {
          let index = regionswithsg
            .map(function(e) {
              return e.url;
            })
            .indexOf(sg_url);
          if (index == regionswithsg.length - 1) {
            setFormProcessing(false);
            setError(err);
            onError(err);
          } else {
            updatePermission(regionswithsg[index + 1].url);
          }
        } else {
          setFormProcessing(false);
          setError(err);
          onError(err);
        }
      });
  };

  const formIsValid = () => {
    let error = "";
    let isValid = true;
    if (!name.trim() || !/^[a-zA-Z\d-_ ]+$/.test(name)) {
      // error =
      //   "Invalid format. Please use only alphanumeric, '-', '_' or space.";
      // setError(error);
      isValid = false;
    }

    if (name.length > 128) {
      // error = "Name cannot exceed 128 characters.";
      // setError(error);
      isValid = false;
    }

    if (resources.length < 1 && option === "selection") {
      error =
        "Bucket cannot be null. Please choose bucket(s) to create a permission.";
      setError(error);
      isValid = false;
    }

    permissions.forEach((p: any) => {
      if (permission?.name !== p.name && p.name === name) {
        error =
          "The permission name is already in use. Please use a different name or edit the existing permission.";
        setError(error);
        isValid = false;
      }
    });

    if (option === "prefix" && !prefix) {
      if (!prefix.trim() || !/^(?!\-)[a-z\d\-\s]*$/.test(prefix)) {
        // error =
        //   "Invalid format. Please use only lowercase characters, alphanumeric in the prefix.";
        // setError(error);
        isValid = false;
      } else {
        error =
          "Prefix cannot be null. Please input some values in prefix field to create a permission.";
        setError(error);
        isValid = false;
      }
    }

    return isValid;
  };

  const handleOnSave = () => {
    if (!formIsValid()) {
      return;
    }
    setFormProcessing(true);
    if (permission) {
      updatePermission(baseUrl);
    } else {
      createPermission(baseUrl);
    }
  };

  const description = getPermissionDescription();

  const enableBtnCheck = () => {
    if (error !== "") {
      return false;
    } else if (
      nameValidation() &&
      nameLengthValidation() &&
      description !== ""
    ) {
      if (option === "prefix" && !(prefixValidation() && prefixLengthChk())) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };

  const bucketResourceValidation = () => {
    var origSelection = initialValues.resources.map((resource: any) => ({
      label: resource.bucket_name,
      value: resource.bucket_name
    }));

    var currentSelection = resources.map((resource: any) => ({
      label: resource.value,
      value: resource.value
    }));

    return isEqual(
      sortBy(origSelection, "label"),
      sortBy(currentSelection, "label")
    );
  };

  const handlePrefixChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value == "selection") {
      var previousSelection = initialValues.resources.map((resource: any) => ({
        ...resource,
        label: resource.bucket_name,
        value: resource.bucket_name
      }));
      setResources(previousSelection);
      setSelectedResources(previousSelection);
    } else {
      setResources([]);
    }
    setError("");
    setOption(e.target.value);
  };

  const isDisabled = () => {
    if (type === "edit") {
      const isPrefixPresent = initialValues.prefix ? true : false;
      const changes =
        initialValues.name !== name ||
        initialValues.actions[0].type !== actions ||
        initialValues.option !== option ||
        (initialValues.option === option && !bucketResourceValidation());

      const prefixChange = isPrefixPresent
        ? initialValues.prefix !== prefix
        : false;

      const valuesChanged = prefixChange || changes;

      return !enableBtnCheck() || !valuesChanged;
    } else {
      return !enableBtnCheck();
    }
  };

  return (
    <div className="create-permission-module">
      <h4 className="create-permission-title">
        {!!permission ? "Edit Bucket Permission" : "Create Bucket Permission"}
        <LyveLink
          link={
            !!permission
              ? getLink(linkNames.Edit_Bucket_Permission)
              : getLink(linkNames.Create_Bucket_Permission)
          }
        />
      </h4>

      <Form
        id="permission-form"
        onSubmit={(event: React.FormEvent) => {
          event.preventDefault();
          handleOnSave();
        }}
      >
        <div>
          <div className="form-section">
            <Input
              controlId="permission-name"
              label="Name"
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setName(e.target.value);
                if (error != "") setError("");
              }}
            />
            <div className="validation-container">
              <div className="validation-item">
                <CheckCircleFill
                  className={cx("validation-icon", {
                    valid: nameValidation()
                  })}
                />
                <span>
                  Only alphanumeric names are allowed, including also '-', '_',
                  or space
                </span>
              </div>
              <div className="validation-item">
                <CheckCircleFill
                  className={cx("validation-icon", {
                    valid: nameLengthValidation()
                  })}
                />
                <span>Maximum 128 characters</span>
              </div>
            </div>

            {nameError !== "" ? (
              <p className="validation-error">{nameError}</p>
            ) : null}
          </div>
          <div className="form-section">
            <label className="form-input-label label-text">
              Which buckets does this permission apply to?
            </label>
            <Input
              controlId="selection"
              label="One or more existing buckets"
              controlAs="radio"
              value="selection"
              defaultChecked={option === "selection"}
              onChange={handlePrefixChange}
              className="radio-input"
            />
            <Input
              controlId="prefix"
              label="All buckets in this account with a prefix"
              controlAs="radio"
              value="prefix"
              defaultChecked={option === "prefix"}
              onChange={handlePrefixChange}
              className="radio-input"
            />
            <Input
              controlId="all"
              label="All buckets in this account"
              controlAs="radio"
              value="all"
              defaultChecked={option === "all"}
              onChange={handlePrefixChange}
              className="radio-input"
            />
          </div>
          {option === "all" ? (
            <div className="warningText">
              <DefaultAlert variant="danger">
                Are you sure? This allows the application to have permission to
                all data in the account.
              </DefaultAlert>
            </div>
          ) : null}
          {option === "selection" ? (
            <div className="form-section">
              <label className="form-input-label">Buckets</label>
              <LyveSelect
                isLoading={itemsLoading}
                options={items}
                isMulti
                value={resources || []}
                onChange={(values: any) => {
                  setSelectedResources(values || []);
                  setResources(values || []);
                  setError("");
                }}
              />
            </div>
          ) : null}
          {option === "prefix" ? (
            <div className="form-section">
              <Input
                controlId="bucket-name-prefix"
                label="Prefix"
                value={prefix}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPrefix(e.target.value)
                }
              />
              <div className="validation-container">
                <div className="validation-item">
                  <CheckCircleFill
                    className={cx("validation-icon", {
                      valid: prefixValidation()
                    })}
                  />
                  <span>
                    Only lowercase characters, alphanumeric and "-"(dash) are
                    allowed in the prefix (dash cannot be the first character).
                  </span>
                </div>
                <div className="validation-item">
                  <CheckCircleFill
                    className={cx("validation-icon", {
                      valid: prefixLengthChk()
                    })}
                  />
                  <span>Maximum 64 characters</span>
                </div>
              </div>
              {prefixError !== "" ? (
                <p className="validation-error">{prefixError}</p>
              ) : null}
            </div>
          ) : null}

          <div className="form-section">
            <label className="form-input-label">Actions</label>
            <Input
              controlId="readwrite"
              label="All operations"
              controlAs="radio"
              value="readwrite"
              defaultChecked={actions === "readwrite"}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setActions(event.target.value);
                setError("");
              }}
              className="radio-input"
            />
            <Input
              controlId="read"
              label="Read only"
              controlAs="radio"
              value="read"
              defaultChecked={actions === "read"}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setActions(event.target.value);
                setError("");
              }}
              className="radio-input"
            />
            <Input
              controlId="write"
              label="Write only"
              controlAs="radio"
              value="write"
              defaultChecked={actions === "write"}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setActions(event.target.value);
                setError("");
              }}
              className="radio-input"
            />
          </div>
        </div>
        {error !== "" ? <p className="validation-error">{error}</p> : null}
        <DefaultButton
          type="submit"
          form="permission-form"
          data-testid="create-permission-submit"
          disabled={isDisabled()}
          className="submit-button"
        >
          {isFormProcessing ? (
            <Spinner animation="border" role="status" className="spinner">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : !!permission ? (
            "Save"
          ) : (
            "Create"
          )}
        </DefaultButton>
      </Form>
      {description && (
        <div className="bucket-permission-description">
          <label className="title">Description</label>
          <p className="value">{description}</p>
        </div>
      )}
    </div>
  );
};

export default CreatePrompt;
