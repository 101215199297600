import {
    CLEAR_MONTHRANGE,
    SET_MONTHITEMS,
    SET_MONTHRANGE,
    MonthItemActionTypes
} from "./actions";

import { SelectedMonth, SelectedMonthRange } from "./types";

export function setMonthRange(selectedRange: SelectedMonthRange): MonthItemActionTypes {
    return {
        type: SET_MONTHRANGE,
        payload: selectedRange
    };
};

export function clearMonthRange(): MonthItemActionTypes {
    return {
        type: CLEAR_MONTHRANGE
    };
};

export function setMonthItems(monthItems: Array<SelectedMonth>): MonthItemActionTypes {
    return {
        type: SET_MONTHITEMS,
        payload: monthItems
    };
};