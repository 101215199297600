import moment from "moment-timezone";
import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { USERS_EDIT } from "../../../../../authRules";
import Can from "../../../../Can";
import federatedImage from "../../../../../images/federated.png";
import api from "../../../../../services/api";
import { SAMLConfig } from "../../types";
import { IS_SAML_CONFIGURED } from "../../../../../state/IAM/actions";
import LyveHeading from "../../../../LyveHeading";
import { DefaultButton } from "../../../../LyveButton";
import { ModalContainer } from "../../../../Modal";
import { DefaultLoader } from "../../../../LyveLoader";
import { Link } from "react-router-dom";
import "./styles.scss";
import LyveLink from "../../../../LyveHeadingLink/LyveLink";
import { getLink, linkNames } from "../../../../LyveHeadingLink/LinkUtil";

type DeletePromptProps = {
  close: () => void;
  onDelete?: () => void;
  closeModal?: () => void;
  onSuccess?: () => void;
  onFailure?: () => void;
};

const DeletePrompt: React.FC<DeletePromptProps> = props => {
  const { close, closeModal, onSuccess, onFailure } = props;

  return (
    <div>
      {/* <div
      className="popup delete-prompt"
      id="exampleModal"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    > */}
      {/* <div className="modal-dialog modal-dialog-centered" role="document"> */}
      {/* <div className="modal-content"> */}
      {/* <div className="modal-header modalCenter">
            <h5 className="modal-title" id="exampleModalLabel">
              Delete IdP Configuration
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={close}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div> */}
      {/* <div className="modal-body "> */}
      <div className="mb-4">
        Are you sure you want to delete your IdP setup?
      </div>
      {/* </div> */}
      {/* <div className="modal-footer"> */}

      <DefaultButton
        type="button"
        variant="danger"
        className="mr-2"
        onClick={() => {
          api
            .deleteSAMLConfiguration()
            .then(() => {
              // props.onDelete();
              if (typeof closeModal !== "undefined") {
                closeModal();
              }
              if (typeof onSuccess !== "undefined") {
                onSuccess();
              }
            })
            .catch(() => {
              if (typeof closeModal !== "undefined") {
                closeModal();
              }
              if (typeof onFailure !== "undefined") {
                onFailure();
              }
            });
        }}
      >
        Yes
      </DefaultButton>
      <DefaultButton
        type="button"
        variant="outline-secondary"
        onClick={() => {
          if (typeof closeModal !== "undefined") {
            closeModal();
          }
        }}
      >
        No
      </DefaultButton>
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};

type ViewSAMLProps = {
  onConfigureClick: (isConfigured: boolean) => void;
};

const ViewSAML: React.FC<ViewSAMLProps> = props => {
  const dispatch = useDispatch();
  const [isConfigured, setIsConfigured] = React.useState(false);
  const [displayModal, setDisplayModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const [configuration, setConfiguration] = React.useState<SAMLConfig>({
    expires: "2034-07-07T14:22:44.000Z",
    name: "vel-saml",
    entityID: "",
    providerURL: ""
  });
  const { showModal } = ModalContainer.useContainer();
  const initDeleteIDPModal = () => {
    showModal({
      header: true,
      title: "Delete IdP Configuration",
      body: closeModal => (
        <DeletePrompt
          close={() => setDisplayModal(false)}
          // onDelete
          onSuccess={() => {
            setIsConfigured(false);
            dispatch({
              type: IS_SAML_CONFIGURED,
              payload: false
            });
            setDisplayModal(false);
          }}
          onFailure={() => {
            console.log("IDP deletion failed.");
          }}
          closeModal={closeModal}
        />
      ),
      baseProps: {
        dialogClassName: "lyve-modal"
      }
    });
  };

  let isMounted = useRef(false);
  React.useEffect(() => {
    isMounted.current = true;
    updateConfiguration();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const updateConfiguration = () => {
    setIsLoading(true);
    api
      .getSAMLConfiguration()
      .then((res: SAMLConfig) => {
        if (isMounted.current) {
          setConfiguration(res);
          setIsLoading(false);
          setIsConfigured(true);
          dispatch({
            type: IS_SAML_CONFIGURED,
            payload: true
          });
        }
      })
      .catch(err => {
        if (isMounted.current) {
          setIsConfigured(false);
          dispatch({
            type: IS_SAML_CONFIGURED,
            payload: false
          });
          setIsLoading(false);
        }
      });
  };

  const { onConfigureClick } = props;

  const renderStatus = () => {
    if (!isConfigured) {
      return (
        <div className="row w-25">
          <div className="col">Status</div>
          <div className="col font-weight-bold">Not Configured</div>
        </div>
      );
    }

    const {
      name: provider,
      expires: expires_on,
      entityID,
      providerURL
    } = configuration;

    return (
      <>
        <div className="row">
          <div className="col-1">Status</div>
          <div className="col font-weight-bold">Configured</div>
        </div>
        <div className="row">
          <div className="col-1">Provider</div>
          <div className="col font-weight-bold">{provider}</div>
        </div>
        <div className="row">
          <div className="col-1">Expires on</div>
          <div className="col font-weight-bold">
            {expires_on ? moment(expires_on.split(" ")[0]).format("L") : ""}
          </div>
        </div>
        <Can perform={USERS_EDIT}>
          <div className="row w-25">
            <DefaultButton
              variant="link"
              className="updateMetadata"
              onClick={() => onConfigureClick(isConfigured)}
            >
              Update Metadata File
            </DefaultButton>
          </div>
        </Can>
        {/* <div className="mt-4 mb-2 modalTitle">
          Identity Provider Configuration Data
        </div> */}
        <LyveHeading
          title="Identity Provider Configuration Data"
          subTitle=""
          marginRightStyle={true}
          className="mt-4"
        ></LyveHeading>
        <div className="row">
          <div className="col-1">Provider URL</div>
          <div className="col font-weight-bold">{providerURL}</div>
        </div>
        <div className="row">
          <div className="col-1">Entity ID</div>
          <div className="col font-weight-bold">{entityID}</div>
        </div>
      </>
    );
  };

  const renderAction = () => {
    if (isConfigured) {
      return (
        <DefaultButton className="mt-4" onClick={() => initDeleteIDPModal()}>
          Delete IdP
        </DefaultButton>
      );
    }
    return (
      <DefaultButton
        className="mt-4"
        onClick={() => onConfigureClick(isConfigured)}
      >
        Configure
      </DefaultButton>
    );
  };

  if (isLoading) {
    return (
      <div className="row align-items-center" style={{ height: "70vh" }}>
        <div className="col text-center">
          <div
            className="spinner-border"
            style={{ color: "#6ebe49" }}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid IAM-support-page-bodyContent">
      <div className="row no-gutter">
        <div className="col">
          <div>
            <div className="row">
              <div className="col-12">
                {/* <div className="pageTitle">Federated Login</div> */}

                <LyveHeading
                  title="Federated Login"
                  subTitle=""
                  marginRightStyle={true}
                ></LyveHeading>

                <div className="d-flex my-4 align-items-center">
                  <img src={federatedImage} height={100} className="mr-3" />
                  <div style={{ maxWidth: "400px" }}>
                    Lyve Cloud supports identity federation with SAML 2.0 to
                    enable federated single-sign on (SSO) from your
                    organization's identity provider (IdP).
                    <LyveLink link={getLink(linkNames.Federation_Login)} />
                  </div>
                </div>
                {renderStatus()}
                <Can perform={USERS_EDIT}>{renderAction()}</Can>
                {/* {displayModal && (
                  <DeletePrompt
                    close={() => setDisplayModal(false)}
                    onDelete={() => {
                      setIsConfigured(false);
                      dispatch({
                        type: IS_SAML_CONFIGURED,
                        payload: false,
                      });
                      setDisplayModal(false);
                    }}
                  />
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSAML;
