import React, { useEffect, useRef } from "react";
import cx from "classnames";
import { ColProps, Form, FormControlProps } from "react-bootstrap";

import "./styles.scss";

interface ISelectOptions {
  label: React.ReactNode;
  value: string | number;
}
interface IOwnProps
  extends React.HTMLAttributes<Element>,
    FormControlProps,
    ColProps {
  /** Unique id of the input */
  controlId: string;
  /** Label of the input */
  label?: React.ReactNode | string;
  /** Name attribute of the input */
  name?: string;
  /**
   * The wrapping element to be used for form group
   * @default "div"
   */
  groupAs?: React.ElementType;
  /**
   * The wrapping element to be used for form control
   * @default "div"
   */
  controlAs?: React.ElementType | "checkbox" | "radio";
  /** Options for select input */
  options?: ISelectOptions[];
  /** Default value for select input */
  selectDefaultValue?: string | number;
  /** Number of rows for a textarea */
  rows?: number | string;
  /** Inline error string */
  error?: string;
  /** Required */
  required?: boolean;
  /** Class name */
  className?: string;
  /** to autofocus the input on load */
  autoFocus?: boolean;
  /** Max length of of the input. Applicable to text input */
  maxLength?: number;
  /** Min length of of the input. Applicable to text input */
  minLength?: number;
  isHelpText?: boolean;
  /** A callback fired when the value changes */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /** Additional behaviour for onChange event */
  onChangeAdd?: any;
}

const Input = ({
  groupAs,
  controlAs,
  options = [],
  selectDefaultValue = "",
  rows,
  xs,
  sm,
  md,
  lg,
  xl,
  label,
  error,
  controlId,
  className = "",
  isHelpText = false,
  required,
  autoFocus,
  onChangeAdd,
  ...rest
}: IOwnProps) => {
  const inputRef = useRef<any>();

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  const renderLabel = () => (
    <>
      <Form.Label className="label">{label}</Form.Label>
      {required && <Form.Label className="required">*</Form.Label>}
    </>
  );

  const renderInputControl = () => {
    switch (controlAs) {
      case "select":
        return (
          <>
            {renderLabel()}
            <Form.Control
              as="select"
              defaultValue={selectDefaultValue}
              {...rest}
            >
              {options &&
                options.length > 0 &&
                options.map(option => (
                  <option key={`option-${option.value}`} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </Form.Control>
          </>
        );
      case "checkbox":
      case "radio":
        const type = controlAs || "checkbox";
        const {
          disabled,
          defaultChecked,
          value,
          onChange,
          onChangeCapture,
          isInvalid
        } = rest;

        const controlledProps = {
          ...(value ? { value } : {}),
          ...(onChange ? { onChange } : {}),
          ...(onChangeCapture ? { onChangeCapture } : {}),
          isInvalid
        };

        return (
          <Form.Check>
            <Form.Check.Input
              type={type}
              id={controlId}
              disabled={disabled}
              checked={defaultChecked}
              readOnly={true}
              {...controlledProps}
            />
            <label
              className={cx("custom-check", controlAs)}
              htmlFor={controlId}
            />
            {label && (
              <Form.Check.Label htmlFor={controlId}>{label}</Form.Check.Label>
            )}
          </Form.Check>
        );
      default:
        if (onChangeAdd) {
          onChangeAdd()
        }
        return (
          <>
            {label && renderLabel()}
            <Form.Control
              ref={inputRef}
              as={controlAs as any}
              rows={rows}
              {...rest}
            />
          </>
        );
    }
  };

  return (
    <Form.Group
      as={groupAs as any}
      {...{ xs, sm, md, lg, xl }}
      className={`custom-form-group ${className}`}
      controlId={controlId}
    >
      {renderInputControl()}
      {(rest.isInvalid || isHelpText) && (
        <Form.Control.Feedback
          type="invalid"
          className={isHelpText ? "form-help-text" : ""}
        >
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default Input;
