import React, { useRef } from "react";
import cx from "classnames";
import { Form, Spinner, Alert, Col, Row } from "react-bootstrap";
import { CheckCircleFill } from "react-bootstrap-icons";
import { isEmpty, isArray } from "lodash";

import api from "../../../../../../services/RegionApis";
import Input from "../../../../../../components/Input";
import { DefaultButton } from "../../../../../../components/LyveButton";
import Dropzone from "../../../../../../components/DropZone/index";

import "./styles.scss";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../../../store";
import { selectRegionsWithSG } from "../../../../../../state/regionswithsg";
import LyveLink from "../../../../../../components/LyveHeadingLink/LyveLink";
import {
  getLink,
  linkNames
} from "../../../../../../components/LyveHeadingLink/LinkUtil";

interface CreatePolicyPromptProps {
  items?: any;
  permission?: any;
  permissions?: any;
  selectedPolicy?: any;
  baseUrl: string;
  onSuccess?: (data: any) => void;
  onError?: (err: any) => void;
}

const CreatePolicyPrompt: React.FC<CreatePolicyPromptProps> = ({
  items,
  permission,
  permissions,
  selectedPolicy,
  baseUrl,
  onSuccess = () => null,
  onError = () => null
}) => {
  const initialValues = permission || {
    id: "",
    name: "",
    effect: "Allow",
    resources: [],
    actions: [{ type: "readwrite" }],
    description: ""
  };

  const initialSelectedValues = selectedPolicy || {
    name: "",
    desc: "Allow access based on policy file",
    policy: ""
  };

  // const classes = useStyles();
  const [error, setError] = React.useState<string>("");
  const [isFormProcessing, setFormProcessing] = React.useState<boolean>(false);
  const { data: regionswithsg } = useAppSelector(selectRegionsWithSG);
  const [name, setName] = React.useState<string>(initialSelectedValues.name);
  const [description, setDescription] = React.useState<string>(
    initialSelectedValues.desc
  );
  const [policy_content, setPolicyContent] = React.useState<string>(
    initialSelectedValues.policy
  );
  const err_pre_text = "File Import Failed: ";

  let nameError = "";
  const [fileName, setFileName] = React.useState<string>("policy.json");

  const nameValidation = (): boolean => {
    //setValidationError("");
    if (name.length == 0) {
      return false;
    }
    if (!name.trim() || !/^[a-zA-Z\d-_ ]+$/.test(name)) {
      return false;
    }
    return true;
  };

  const nameLengthValidation = () => !!name && name.length <= 128;

  const descriptionValidation = (): boolean => {
    if (description.length == 0) {
      return false;
    }
    if (description.length > 1000) {
      return false;
    } else {
      return true;
    }
  };

  const policyValidation = (file_contents: string) => {
    let isPolicyValid = false;
    try {
      JSON.parse(file_contents);
      isPolicyValid = true;
    } catch {
      setError(err_pre_text + "Invalid JSON File.");
      isPolicyValid = false;
    }
    if (isPolicyValid) {
      const policy = JSON.parse(file_contents);
      if (!policy.hasOwnProperty("Version")) {
        setError(err_pre_text + "Version is required.");
        return;
      } else if (isEmpty(policy.Version)) {
        setError(err_pre_text + "Version field value is empty.");
        return;
      }
      if (!policy.hasOwnProperty("Statement")) {
        setError(err_pre_text + "Statement is required.");
        return;
      } else if (!isArray(policy.Statement)) {
        setError(err_pre_text + "Statement format is invalid.");
        return;
      } else if (isEmpty(policy.Statement)) {
        setError(err_pre_text + "Statement cannot be empty.");
        return;
      } else {
        interface example {
          [key: string]: any;
        }
        policy.Statement.forEach((obj: example, index: number) => {
          if (!obj.hasOwnProperty("Effect")) {
            setError(err_pre_text + "Effect field is required.");
            return;
          } else if (isEmpty(obj.Effect)) {
            setError(err_pre_text + "Effect can't be empty.");
          } else if (obj.Effect !== "Allow" && obj.Effect !== "Deny") {
            setError(err_pre_text + "Effect value is invalid.");
            return;
          }
          if (!obj.hasOwnProperty("Resource")) {
            setError(err_pre_text + "Resource field is required.");
            return;
          } else if (isEmpty(obj.Resource)) {
            setError(err_pre_text + "Resource can't be empty.");
            return;
          }
          if (!obj.hasOwnProperty("Action")) {
            setError(err_pre_text + "Action field is required.");
            return;
          } else if (isEmpty(obj.Action)) {
            setError(err_pre_text + "Action can't be empty.");
            return;
          }
        });
      }
    }
  };

  const getFileContent = (acceptedFiles: any) => {
    setError("");
    acceptedFiles.forEach((file: any) => {
      const fileName = file.name;
      const fileType = file.type;
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        if (
          !reader.result ||
          reader.result.toString().replace(/\s+/g, "") == "{}"
        ) {
          setError(err_pre_text + "Policy permission file is empty.");
          return;
        }
        const filecontents = reader.result.toString().replace(/\s+/g, "");
        if (fileType == "application/json" && typeof filecontents == "string") {
          setPolicyContent(filecontents);
          setFileName(fileName);
          policyValidation(filecontents);
        } else {
          setPolicyContent("");
          setError(
            err_pre_text +
              "Only JSON file format is permitted. Please try again."
          );
        }
      };
      reader.readAsText(file);
    });
  };

  const dropRejected = (FileRejection: any) => {
    if (!isEmpty(FileRejection)) {
      if (FileRejection[0].errors[0].code == "file-too-large") {
        setError(err_pre_text + "Maximum policy file size exceeded");
      }
      if (FileRejection[0].errors[0].code == "too-many-files") {
        setError(
          err_pre_text + "Only one JSON file can be imported. Please try again."
        );
      }
    }
  };

  const enableCreateBtnCheck = () => {
    if (error !== "") {
      return false;
    }
    if (
      nameValidation() &&
      nameLengthValidation() &&
      descriptionValidation() &&
      policy_content !== ""
    ) {
      return true;
    }
    return false;
  };

  const enableSaveBtnCheck = () => {
    if (error !== "") {
      return false;
    }
    if (
      selectedPolicy.name !== name ||
      selectedPolicy.desc !== description ||
      selectedPolicy.policy !== policy_content
    ) {
      if (
        nameValidation() &&
        nameLengthValidation() &&
        descriptionValidation() &&
        policy_content !== ""
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const handleDownload = () => {
    const element = document.createElement("a");
    const file = new Blob([policy_content], { type: "application/json" });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const handleReplace = () => {
    setPolicyContent("");
    setError("");
  };

  const createPolicyPermission = (sg_url: string) => {
    api
      .addPolicyPermission(name, description, policy_content, sg_url)
      .then(data => {
        setFormProcessing(false);
        onSuccess(data);
      })
      .catch(err => {
        if (regionswithsg.length !== 0 && err.status != 400) {
          let index = regionswithsg
            .map(function(e) {
              return e.url;
            })
            .indexOf(sg_url);
          if (index == regionswithsg.length - 1) {
            setFormProcessing(false);
            if (err.code == 14 || err.status == 503) {
              setError(err.error);
            } else {
              setError(err_pre_text + err.error);
            }
            onError(err.error);
          } else {
            createPolicyPermission(regionswithsg[index + 1].url);
          }
        } else {
          setFormProcessing(false);
          if (err.code == 14 || err.status == 503) {
            setError(err.error);
          } else {
            setError(err_pre_text + err.error);
          }
          onError(err.error);
        }
      });
  };

  const updatePolicyPermission = (sg_url: string) => {
    api
      .updatePolicyPermission(
        permission.id,
        name,
        description,
        policy_content,
        sg_url
      )
      .then(data => {
        setFormProcessing(false);
        onSuccess(data);
      })
      .catch(err => {
        if (regionswithsg.length !== 0 && err.status != 400) {
          let index = regionswithsg
            .map(function(e) {
              return e.url;
            })
            .indexOf(sg_url);
          if (index == regionswithsg.length - 1) {
            setFormProcessing(false);
            if (err.code == 14 || err.status == 503) {
              setError(err.error);
            } else {
              setError(err_pre_text + err.error);
            }
            onError(err.error);
          } else {
            createPolicyPermission(regionswithsg[index + 1].url);
          }
        } else {
          setFormProcessing(false);
          if (err.code == 14 || err.status == 503) {
            setError(err.error);
          } else {
            setError(err_pre_text + err.error);
          }
          onError(err.error);
        }
      });
  };

  const formIsValid = () => {
    let isValid = true;

    // if (!permission) {
    permissions.forEach((p: any) => {
      if (permission?.name !== p.name && p.name === name) {
        setError(
          "The permission name is already in use.  Please use a different name or edit the existing permission."
        );
        isValid = false;
      }
    });
    // }

    return isValid;
  };

  const handleOnSave = () => {
    if (!formIsValid()) {
      return;
    }
    setFormProcessing(true);
    if (permission) {
      updatePolicyPermission(baseUrl);
    } else {
      createPolicyPermission(baseUrl);
    }
  };

  const childRef = useRef<any>(null);
  return (
    <div className="create-policy-permission-module">
      <Form
        id="policy-permission-form"
        className="policy-permission-form"
        onSubmit={(event: React.FormEvent) => {
          event.preventDefault();
          handleOnSave();
        }}
      >
        <div>
          <Form.Group controlId="form-section" className="title-group">
            <h4 className="create-policy-permission-title">
              {!!permission
                ? "Edit Policy Permission"
                : "Create Policy Permission"}
            </h4>

            <Form.Text>
              Upload a policy file compatible with an AWS IAM policy{" "}
              <LyveLink link={getLink(linkNames.Create_Policy_Permission)} />
            </Form.Text>
          </Form.Group>
        </div>
        <div>
          <div className="form-section">
            <Input
              controlId="policy-permission-name"
              label="Name"
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setName(e.target.value);
                if (error != "") setError("");
              }}
            />
            <div className="validation-container">
              <div className="validation-item">
                <CheckCircleFill
                  className={cx("validation-icon", {
                    valid: nameValidation()
                  })}
                />
                <span>
                  Only alphanumeric names are allowed, including also '-', '_',
                  or space
                </span>
              </div>
              <div className="validation-item">
                <CheckCircleFill
                  className={cx("validation-icon", {
                    valid: nameLengthValidation()
                  })}
                />
                <span>Maximum 128 characters</span>
              </div>
            </div>
            {nameError !== "" && (
              <p className="validation-error">{nameError}</p>
            )}
          </div>
        </div>
        <div>
          <div className="form-section">
            <Input
              controlAs="textarea"
              controlId="policy-permission-description"
              label="Description"
              rows={1}
              value={description}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setDescription(e.target.value)
              }
            />
            {descriptionValidation() ? (
              ""
            ) : (
              <p className="validation-error">
                Description is limited to 1000 characters.
              </p>
            )}
          </div>
        </div>
        <div>
          <div className="form-section">
            <Form.Group className="policy-file-group">
              <Form.Label>Policy File</Form.Label>
              {policy_content !== "" && (
                <Alert variant="secondary" className="alert">
                  <Row>
                    <Col xs={8} className="alert-text">
                      {fileName}
                    </Col>
                    <Col xs={4}>
                      <a
                        href="#"
                        className="alert-link"
                        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                          childRef?.current?.showFileDialog();
                          handleReplace();
                        }}
                      >
                        Replace
                      </a>
                      <a
                        href="#"
                        className="mr-2 alert-link"
                        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                          handleDownload();
                        }}
                      >
                        Download
                      </a>
                    </Col>
                  </Row>
                </Alert>
              )}
              {/* {policyError !== "" && (
                <Alert variant="danger" className="alert">
                  {policyError}
                </Alert>
              )} */}
              <div
                style={{
                  display: `${policy_content == "" ? "inline" : "none"}`
                }}
              >
                <Dropzone
                  ref={childRef}
                  onDrop={getFileContent}
                  onDropRejected={dropRejected}
                  maxFiles={1}
                  maxSize={10000000}
                  p1="Drop your JSON file here or"
                  p2="click to browse"
                />
              </div>
              {error !== "" && (
                <Alert variant="danger" className="alert error-alert">
                  {error}
                </Alert>
              )}
            </Form.Group>
          </div>
        </div>
        {!!permission ? (
          <DefaultButton
            type="submit"
            form="policy-permission-form"
            disabled={!enableSaveBtnCheck()}
            className="submit-button"
          >
            {isFormProcessing ? (
              <Spinner animation="border" role="status" className="spinner">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              "Save"
            )}
          </DefaultButton>
        ) : (
          <DefaultButton
            type="submit"
            form="policy-permission-form"
            disabled={!enableCreateBtnCheck()}
            data-testid="create-policy-permission-submit"
            className="submit-button"
          >
            {isFormProcessing ? (
              <Spinner animation="border" role="status" className="spinner">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              "Create"
            )}
          </DefaultButton>
        )}
      </Form>
    </div>
  );
};

export default CreatePolicyPrompt;
