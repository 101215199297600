import React from "react";
import { getLink, linkNames } from "./LinkUtil";

interface ILyveLinkProps {
  link: string;
  isMarginLeft?: boolean;
}

const LyveLink: React.FC<ILyveLinkProps> = ({ link, isMarginLeft = true }) => {
  if (!link) {
    return null;
  }

  return (
    <a
      className={`helplink ${isMarginLeft ? "ml-1" : ""}`}
      target="_blank"
      data-testid="learn-more"
      href={link}
    >
      Learn more &nbsp;
      <i className="fas fa-caret-right" />
    </a>
  );
};

export default LyveLink;
