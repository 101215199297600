import React, { useEffect, useState } from "react";
import * as notificationActions from "../../../../Notification/actions";
import { Dispatch } from "redux";
import { compose } from "recompose";
import { connect, useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../store";
import { IAMInfo } from "../../../../../state/IAM/types";
import api from "../../../../../services/api";
import { UPDATE_MFA_PREFERENCE } from "../../../../../state/IAM/actions";
import { DefaultButton } from "../../../../LyveButton";
import ButtonLoader from "../../../../LyveLoader/ButtonLoader";
import "./styles.scss";

interface OwnProps {
  closeModal?: () => void;
  initCount?: number;
}
interface StateProps {}
interface DispatchProps {
  setNotification?: (message: string) => any;
}

type ConfirmModelProps = StateProps & DispatchProps & OwnProps;

const ConfirmModal: React.FC<ConfirmModelProps> = props => {
  const [processing, setProcessing] = useState<boolean>(false);
  const IAMInfo = useSelector<AppState, IAMInfo>(
    state => state.IAMInfo.IAMInfo
  );
  const dispatch = useDispatch();
  const { setNotification, closeModal } = props;
  // Update MFA Reference Promise
  const updateMFAPreference = async (newPreference: string): Promise<any> => {
    try {
      await api.updateMFAPreference(newPreference);
      const mfa = await api.getMFAPreference();
      return Promise.resolve(mfa);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const updateMFA = async () => {
    try {
      setProcessing(true);
      const updatedMFA =
        IAMInfo.mfa_preference === "passwordOnly" ? "SMS" : "passwordOnly";
      const mfa = await updateMFAPreference(updatedMFA);
      dispatch({
        type: UPDATE_MFA_PREFERENCE,
        payload: mfa.mfa_preference
      });
      if (typeof setNotification !== "undefined") {
        setNotification(`MFA preference has been changed successfully.`);
      }

      setProcessing(false);
      if (typeof closeModal !== "undefined") {
        closeModal();
      }
    } catch (error) {
      console.log(`error`, error); // todo: handle error
      setProcessing(false);
      if (typeof closeModal !== "undefined") {
        closeModal();
      }
    }
  };

  useEffect(() => {
    console.log(props);
    api.getMFAPreference().then(mfa => {
      dispatch({
        type: UPDATE_MFA_PREFERENCE,
        payload: mfa.mfa_preference
      });
    });
  }, []);

  return (
    <div className="confirm-modal-main-wrapper">
      <h4 className="modal-title mb-4">MFA Update Confirmation</h4>
      <div className="confirm-modal-wrapper mb-4">
        {/* <span>You are changing your MFA configuration</span> */}
        <div>
          You are enabling the MFA configuration for your account. All users
          will be required to enroll in the MFA policy to be authenticated in
          Lyve Cloud.
        </div>
      </div>

      <DefaultButton
        disabled={processing}
        onClick={() => updateMFA()}
        className="mr-2"
      >
        {processing && <ButtonLoader animation="border" />}
        {` `}Confirm
      </DefaultButton>
      <DefaultButton variant="outline-secondary" onClick={closeModal}>
        Cancel
      </DefaultButton>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setNotification: (message: string) =>
    dispatch(notificationActions.setNotification(message))
});

export default compose<ConfirmModelProps, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps>(null, mapDispatchToProps)
)(ConfirmModal);
