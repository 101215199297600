import React, { useState, useRef } from "react";
import history from "../../../../../../history";
import {
  Row,
  Col,
  Button,
  Table,
  Form,
  Spinner,
  ProgressBar
} from "react-bootstrap";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import {
  TicketDetails,
  CommentHistory,
  EditTicket,
  TicketAttachment,
  NewTicketAttachment,
  AddTicketComment
} from "../../Models/Ticket";
import salesforceApi from "../../../../../../services/SalesforceAPI";
import { useParams } from "react-router";
import CloseIcon from "@material-ui/icons/Close";
import {
  checkFileSize,
  toBase64,
  checkIfIDBIsAvailable,
  getPartnerDetails
} from "../../Helpers/SupportHelpers";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment-timezone";
import api from "../../../../../../services/api/index";
import { Link } from "react-router-dom";

import editIcon from "../../../../../../images/edit-icon.svg";
import addIcon from "../../../../../../images/add-icon.svg";

import CircularProgress from "@material-ui/core/CircularProgress";
import idbHelper from "../../Helpers/IDBHelper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ReactDOM from "react-dom";
import { SUPPORT_EDIT } from "../../../../../../authRules";
import Can from "../../../../../../components/Can";
import {
  ButtonLoader,
  DefaultLoader
} from "../../../../../../components/LyveLoader";
import { BasicCard } from "../../../../../../components/Card";
import { DefaultButton } from "../../../../../../components/LyveButton";
import { HeadingBreadCrumb } from "../../../../../../components/LyveBreadcrumb";
import Input from "../../../../../../components/Input";
import { LyveBadge } from "../../../../../../components/LyveBadge";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../../store";
import { WhoAmI } from "../../../../../../state/UserInfo/types";

let customers_array: any[] = [];
let isAvailabeIDB: boolean = false;
var dateFormat = 'YYYY-MM-DD hh:mm A';

const SupportTicketDetailsUpdate: React.FC<{
  selectedCaseNumber?: string;
  closePopup?: () => void;
  updateCaseList?: () => void;
}> = props => {
  const whoAmI = useSelector<AppState, WhoAmI>(state => state.userInfo.whoAmI);

  // Validation Properties
  const [showFileValidationMessage, setFileValidationMessage] = useState(false);
  const [
    showFileTypeValidationMessage,
    setFileTypeValidationMessage
  ] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadingFileName, setUploadingFileName] = useState<string>("");
  const [loading, setLoading] = useState(true);
  // const [tentants, setTentants] = useState<Array<string>>([]);

  const [commentAdd, setCommentAdd] = useState(false);
  const hideCommentAdd = () => {
    setCommentAdd(false);
    // Set previous state
    setTicketDetailsComment(ticketDetails2);
    validator.current.hideMessageFor("userComments");
    setInitCreationComment(true);
    setServerErrorComment(false);
    // setServerError(false);
  };

  const showCommentAdd = () => setCommentAdd(true);

  const [formEdit, setFormEdit] = useState(false);
  const partnerDetails = getPartnerDetails(whoAmI);
  const editFormShow = () => {
    const newTicketDetails: TicketDetails = Object.assign({}, ticketDetails);
    // console.log(newTicketDetails.DetailsofIssue);
    const tmpstr = newTicketDetails.DetailsofIssue.replace(/<br \/>/g, "\n");
    newTicketDetails.DetailsofIssue = tmpstr;
    setTicketDetails(newTicketDetails);

    // console.log(newTicketDetails);

    setFormEdit(true);
    setInitFrom(false);
  };
  const editFormHide = () => {
    setFormEdit(false);
    setInitFrom(false);
    setFileUploadStatus(true);
    setFileTypeValidationMessage(false);
    setFileValidationMessage(false);

    // Set previous state
    setTicketDetails(ticketDetails2);

    // Remove new attachements from array
    setNewTicketAttachment({
      ...newTicketAttachment,
      Attachments: []
    });
    setAttachmentValidationFlag(false);
    setServerError(false);
    // setServerErrorComment(false);
  };

  const [isFormProcessing, setFormProcessing] = React.useState<boolean>(false);
  const [initCreation, setInitCreation] = useState(false);
  const [initCreationComment, setInitCreationComment] = useState(true);
  const [isFormMainProcessing, setFormMainProcessing] = React.useState<boolean>(
    false
  );
  const [initForm, setInitFrom] = useState(false);
  const [fileUploadStatus, setFileUploadStatus] = React.useState<boolean>(true);
  const [serverError, setServerError] = React.useState<boolean>(false);
  const [serverErrorComment, setServerErrorComment] = React.useState<boolean>(false);
  let errorString = useRef("");
  let errorStringComment = useRef("");
  const [
    attachmentValidationFlag,
    setAttachmentValidationFlag
  ] = React.useState<boolean>(false);
  const [updateForms, setupdateForms] = useState(false);
  let { ticketid }: any = useParams();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      spinner: {
        color: theme.palette.primary.contrastText,
        position: "relative",
        top: theme.spacing(0.375)
      },
      errorTxtSubject: {
        color: "#fc397a",
        fontSize: "13px",
        marginRight: "24px"
      }
    })
  );
  const classes = useStyles();

  const [ticketDetails, setTicketDetails] = useState<TicketDetails>({
    UserComments: "",
    User: "",
    TimeCreated: "",
    TimeClosed: "",
    Subject: "",
    State: "",
    Severity: "",
    Resolution: "",
    Message: "",
    Email: "",
    DetailsofIssue: "",
    Customer: "",
    CommentHistory: [],
    CaseNumber: "",
    // CaseOrigin: "",
    // CaseID: "",
    // AssignedTo: "",
    Attachments: [],
    AccountName: ""
  });

  const [ticketDetails2, setTicketDetails2] = useState<TicketDetails>({
    UserComments: "",
    User: "",
    TimeCreated: "",
    TimeClosed: "",
    Subject: "",
    State: "",
    Severity: "",
    Resolution: "",
    Message: "",
    Email: "",
    DetailsofIssue: "",
    Customer: "",
    CommentHistory: [],
    CaseNumber: "",
    // CaseOrigin: "",
    // CaseID: "",
    // AssignedTo: "",
    Attachments: [],
    AccountName: ""
  });

  const [ticketDetailsComment, setTicketDetailsComment] = useState<TicketDetails>({
    UserComments: "",
    User: "",
    TimeCreated: "",
    TimeClosed: "",
    Subject: "",
    State: "",
    Severity: "",
    Resolution: "",
    Message: "",
    Email: "",
    DetailsofIssue: "",
    Customer: "",
    CommentHistory: [],
    CaseNumber: "",
    // CaseOrigin: "",
    // CaseID: "",
    // AssignedTo: "",
    Attachments: [],
    AccountName: ""
  });

  const [newTicketAttachment, setNewTicketAttachment] = useState<
    NewTicketAttachment
  >({
    Attachments: []
  });

  const [editTicket, setEditTicket] = useState<EditTicket>({
    CaseNumber: "",
    Customer: "None",
    Subject: "",
    DetailsofIssue: "",
    UserComments: "",
    User: "",
    Attachments: []
  });

  const [addTicketComment, setTicketComment] = useState<AddTicketComment>({
    CaseNumber: "",
    Customer: "None",
    Subject: "",
    DetailsofIssue: "",
    UserComments: "",
    User: ""
  });

  /** Validation */
  const [, forceUpdate] = useState<number>();
  let validator = useRef(
    new SimpleReactValidator({
      className: "text-danger",
      messages: {
        required: "This field is required",
        max: "You have reached the maximum characters limit."
      }
    })
  );

  // Constants for vaidation
  const subjectValidate = validator.current.message(
    "subject",
    ticketDetails.Subject,
    "required|max:255"
  );
  const detailsOfIssueValidate = validator.current.message(
    "detailsOfIssue",
    ticketDetails.DetailsofIssue,
    "required|max:5000"
  );
  const UserCommentsValidate = validator.current.message(
    "userComments",
    ticketDetailsComment.UserComments,
    "required|max:3800"
  );
  const [userComments, setUserComments] = useState<Array<CommentHistory>>([]);

  // GET Customers from API and populate the combo box
  // const getCustomers = async () => {
  //   setupdateForms(true);
  //   if (!partnerDetails.direct_customer) {
  //     await salesforceApi
  //       .getTenants()
  //       .then(res => {
  //         // console.log(res.body);
  //         if (res.body.tenants.length > 0) {
  //           // console.log("in");
  //           let temp_array: Array<string> = [];
  //           for (var i = 0; i < res.body.tenants.length; i++) {
  //             temp_array.push(res.body.tenants[i].name);
  //           }
  //           setupdateForms(false);
  //         }
  //         setupdateForms(false);
  //       })
  //       .catch(err => console.log(err));
  //   } else {
  //     setupdateForms(false);
  //   }
  // };

  // Auto Hide Error Message in 3 second from Subject
  const checkErrorSubject = () => {
    if (!validator.current.fieldValid("subject")) {
      setTimeout(() => {
        document.querySelectorAll(".text-danger").forEach(function (a) {
          a.remove();
        });
      }, 3000);
    }
  };

  // Auto Hide Error Message in 3 second from Description
  const checkErrorDesc = () => {
    if (!validator.current.fieldValid("detailsOfIssue")) {
      setTimeout(() => {
        document.querySelectorAll(".text-danger").forEach(function (a) {
          a.remove();
        });
      }, 3000);
    }
  };

  // Auto Hide Error Message in 3 second from Comment
  const checkErrorComment = () => {
    if (!validator.current.fieldValid("userComments")) {
      setTimeout(() => {
        document.querySelectorAll(".text-danger").forEach(function (a) {
          a.remove();
        });
      }, 3000);
      setInitCreationComment(true);
    } else {
      setInitCreationComment(false);
    }
  };

  /** Update Fields on Model */
  const updateField = (e: any) => {
    let eventValue = e.target.value;
    setTicketDetailsComment(ticketDetailsComment => ({
      ...ticketDetailsComment,
      UserComments: eventValue
    }));
  };

  // Update ticket details when they are changed
  const updateOnChange = (e: any) => {
    // const str = e.target.value.replace(/["]/g, "");
    // console.log(str);
    let eventValue = e.target.value;
    setTicketDetails({
      ...ticketDetails,
      [e.target.name]: eventValue
    });
  };

  const handleBreadCrumbsClick = () => {
    if (!partnerDetails.isAnyCustomer) {
      history.push("/partner/support");
    } else {
      history.push("/customer/support");
    }
  }

  // Function to get ticket details from Salesforce
  const getAllTickets = (): Promise<TicketDetails> => {
    let newTicketID = ticketid || "";
    let casenumber = newTicketID;
    if (typeof props.selectedCaseNumber !== "undefined") {
      casenumber = props.selectedCaseNumber;
      // console.log("CASE: ", casenumber);
    }
    // console.log("CASE: NO CASE")
    return new Promise((resolve, reject) => {
      salesforceApi
        .getTicketDetails(casenumber)
        .then(res => {
          let tickets: TicketDetails;
          tickets = res.data;
          resolve(tickets);
        })
        .catch(err => reject(err));
    });
  };

  // Delete Attachment form list
  const deleteAttachment = (e: any) => {
    // Find clicked attachemnt and remove it form list
    let removeFile = e.target.parentNode.parentNode.firstChild.innerHTML;
    let index = 0;
    let i = 0;
    newTicketAttachment.Attachments.forEach(attachment => {
      if (attachment.Name === removeFile) {
        index = i;
      }
      i++;
    });
    newTicketAttachment.Attachments.splice(index, 1);

    // Filter for checking error in attachemnts
    let errorCount = newTicketAttachment.Attachments.filter(
      attachment => attachment.ErrorMmessage !== ""
    );
    if (errorCount.length === 0) {
      setInitFrom(false);
    }

    checkAttachment();
  };

  // Call Salesforce API to Open Attachment
  const getAttachment = (FileID: string | undefined) => {
    console.log("getAttachment called " + FileID);

    if (typeof FileID === "undefined") {
      console.log("Just uploaded[status: undefined] not ready to preview...");
      return;
    }

    if (FileID === "") {
      console.log("Just uploaded[status: empty] not ready to preview...");
      return;
    }

    const filenumber = FileID;
    setupdateForms(true);
    salesforceApi
      .getAttachedFile(filenumber)
      .then(res => {
        console.log(res.data);
        var byteCharacters = atob(res.data.Body);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);
        var mimeType = "";
        mimeType = "application/octet-stream;base64";

        var file = new Blob([byteArray], { type: mimeType });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        var fileName = res.data.Name;
        link.download = fileName;
        link.click();

        // Close the loader and open the file
        setupdateForms(false);
        // var fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
      })
      .catch(err => {
        setupdateForms(false);
        console.log(err);
      });
  };

  // Add comment
  const addComment = async (e: any) => {
    e.preventDefault();
    setFormProcessing(true);
    setInitCreation(true);
    setInitCreationComment(true);

    if (validator.current.fieldValid("userComments")) {
      // setFormEdit(false);
      setInitFrom(false);
      setFileUploadStatus(true);
      setFileTypeValidationMessage(false);
      setFileValidationMessage(false);

      // Remove new attachements from array
      setNewTicketAttachment({
        ...newTicketAttachment,
        Attachments: []
      });
      setAttachmentValidationFlag(false);

      const newEditTicket: AddTicketComment = Object.assign(
        {},
        addTicketComment
      );

      const userName: string = whoAmI?.name ?? "";

      let temp: string = ticketDetailsComment.UserComments.replace(/\\n/g, "");
      temp = temp.trim();

      if (temp.length === 0) {
        setFormProcessing(false);
        hideCommentAdd();
        return
      }

      newEditTicket.UserComments =
        ticketDetails.UserComments + ". Added By " + userName;
      newEditTicket.Customer = ticketDetails.Customer;
      newEditTicket.User = "";

      // await setTicketDetails(ticketDetails => ({
      //   ...ticketDetails,
      //   UserComments: ""
      // }));
      // setupdateForms(true);
      await handleSubmitComment(newEditTicket, true);

      // let dateStr: string = moment().format("YYYY-MM-DD h:mm:ss");

      // let newComment: CommentHistory = {
      //   CaseComment: ticketDetails.UserComments + ". Added By " + userName,
      //   CreatedDate: dateStr,
      //   CreatorName: ticketDetails.User,
      //   IsPublic: true
      // };

      // setUserComments(userComments => [newComment, ...userComments]);
      // setupdateForms(false);
      setFormProcessing(false);
      hideCommentAdd();
    } else {
      /** Show error messages if form not validated */
      validator.current.showMessages();
      forceUpdate(1);
      setFormProcessing(false);
      setInitCreation(false);
      setInitCreationComment(false);
    }
  };

  // Function to save changes and call Saleforce API
  const updateTicketDetails = async (e: any) => {
    e.preventDefault();
    setServerError(false);

    if (
      validator.current.fieldValid("subject") &&
      validator.current.fieldValid("detailsOfIssue")
    ) {
      setFormMainProcessing(true);
      setInitFrom(true);

      // setCommentAdd(false);
      // Set previous state
      validator.current.hideMessageFor("userComments");
      setInitCreationComment(true);
      await handleSubmit(false);

      // console.log(newEditTicket);
      // console.log(ticketDetails);
      setTicketDetails2(ticketDetails);
      setTicketDetailsComment(ticketDetails);
      setFormMainProcessing(false);
      setFormEdit(false);
    } else {
      /** Show error messages if form not validated */
      console.log("invalid form");
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  // Function to retry upload
  const onUploadAttachments = async () => {
    let newAttachments = ticketDetails.Attachments.filter(
      attachment => typeof attachment.FileID === "undefined"
    );

    const newEditTicket: EditTicket = Object.assign({}, editTicket);
    newEditTicket.Attachments = newAttachments;
    newEditTicket.Subject = ticketDetails.Subject;
    newEditTicket.Customer = ticketDetails.Customer;
    newEditTicket.DetailsofIssue = ticketDetails.DetailsofIssue;

    setUploadProgress(1);
    setUploadingFileName(newAttachments[0].Name);

    await handleSubmitAttachment(newEditTicket);
  };

  // // Retry Button
  // const uploadFileRetry = async () => {
  //   console.log("Retry");
  // };

  // // Cancle button
  // const cancleUpload = async () => {
  //   console.log("cancleUpload");

  //   setFileUploadStatus(true);
  //   console.log(ticketDetails.Attachments);

  //   // let newAttachments = ticketDetails.Attachments.splice(-1,1);
  //   // setTicketDetails({
  //   //   ...ticketDetails,
  //   //   Attachments: newAttachments
  //   // });

  //   // Set previous state
  //   setTicketDetails(ticketDetails2);

  //   console.log(ticketDetails.Attachments);

  //   setUploadProgress(0);
  //   setUploadingFileName("");
  // };

  // Function to Auto Upload attachments
  const handleSubmitAttachment = (newEditTicket: EditTicket) => {
    return new Promise((resolve, reject) => {
      setFileUploadStatus(true);

      salesforceApi
        .updateTicket(newEditTicket)
        .then(res => {
          setUploadProgress(100);
          setFileUploadStatus(true);

          let attachments = ticketDetails.Attachments.map(attachment => {
            if (typeof attachment.FileID === "undefined") {
              let newAttachments = attachment;
              newAttachments.FileID = "";
              return newAttachments;
            } else {
              return attachment;
            }
          });
          console.log(attachments);
          setTicketDetails({
            ...ticketDetails,
            Attachments: attachments
          });
          setUploadProgress(0);
          setUploadingFileName("");

          resolve(res);
        })
        .catch(err => {
          console.log(err);
          setFileUploadStatus(false);
          reject(err);
        });
    });
  };

  //
  const handleSubmit = (addComment: boolean) => {
    return new Promise((resolve, reject) => {
      setInitCreation(true);
      // setInitCreationComment(true);
      setServerError(false);

      // console.log(newTicketAttachment);
      let newAttachments = newTicketAttachment.Attachments.filter(
        attachment => attachment.ErrorMmessage === ""
      );

      newAttachments = newAttachments.map(u => ({
        Body: u.Body,
        CreatedDate: u.CreatedDate,
        Description: u.Description,
        Name: u.Name
      }));
      // TicketAttachmentSend
      // console.log(newAttachments);

      const newEditTicket: EditTicket = Object.assign({}, editTicket);
      newEditTicket.Attachments = newAttachments;

      let tmpstr = ticketDetails.Subject
        .replace(/\\/g, "\\\\")
        .replace(/\n/g, "\\n")
        .replace(/"/g, '\\"');
      newEditTicket.Subject = tmpstr;

      newEditTicket.Customer = ticketDetails.Customer;
      let tmpstr1 = ticketDetails.DetailsofIssue
        .replace(/\\/g, "\\\\")
        .replace(/\n/g, "\\n")
        .replace(/"/g, '\\"');
      newEditTicket.DetailsofIssue = tmpstr1;

      if (addComment) {
        newEditTicket.User = "";
      }

      // console.log(newEditTicket);

      salesforceApi
        .updateTicket(newEditTicket)
        .then(res => {
          resolve(res);
          setInitCreation(false);
          // setInitCreationComment(false);
          setServerError(false);

          // Remove new attachements from array
          setNewTicketAttachment({
            ...newTicketAttachment,
            Attachments: []
          });
          setAttachmentValidationFlag(false);

          // if (isAvailabeIDB) {
          //   getOneTicketsIDB();
          // }

          getTicketDetails();
        })
        .catch(err => {
          // console.log(err);
          reject(err);
          setInitCreation(false);
          // setInitCreationComment(false);

          if (err.code === 14){
						errorString.current = err.message;
					  }else{
						errorString.current = "An unexpected error occurred on the server side. Please try again.";
					}

          setServerError(true);
          setFormMainProcessing(false);
        });
    });
  };

  const handleSubmitComment = (
    newEditTicket1: AddTicketComment,
    addComment: boolean
  ) => {
    return new Promise((resolve, reject) => {
      setFormProcessing(true);
      setInitCreationComment(true);
      setServerErrorComment(false);

      const newEditTicket: AddTicketComment = Object.assign(
        {},
        addTicketComment
      );

      let tmpstr = ticketDetailsComment.Subject
        .replace(/<br \/>/g, "\n")
        .replace(/\\/g, "\\\\")
        .replace(/\n/g, "\\n")
        .replace(/"/g, '\\"');
      newEditTicket.Subject = tmpstr;

      newEditTicket.Customer = ticketDetailsComment.Customer;
      let tmpstr1 = ticketDetailsComment.DetailsofIssue
        .replace(/<br \/>/g, "\n")
        .replace(/\\/g, "\\\\")
        .replace(/\n/g, "\\n")
        .replace(/"/g, '\\"');
      newEditTicket.DetailsofIssue = tmpstr1;

      let tmpstrComment = ticketDetailsComment.UserComments
        .replace(/<br \/>/g, "\n")
        .replace(/\\/g, "\\\\")
        .replace(/\n/g, "\\n")
        .replace(/"/g, '\\"');
      // check if comments are added. If added add Added By string

      const userName: string = whoAmI?.name ?? "";

      // if (tmpstrComment !== "") {
      //   tmpstrComment = tmpstrComment + " Added By " + userName;
      // }
      
      newEditTicket.UserComments = tmpstrComment;
      newEditTicket.User = userName;

      // if (addComment) {
      //   newEditTicket.User = "";
      // }

      // console.log(newEditTicket);

      salesforceApi
        .updateTicket(newEditTicket)
        .then(res => {
          resolve(res);
          setFormProcessing(false);
          setInitCreationComment(false);
          setServerErrorComment(false);

          // Remove new attachements from array
          setNewTicketAttachment({
            ...newTicketAttachment,
            Attachments: []
          });
          setAttachmentValidationFlag(false);

          // if (isAvailabeIDB) {
          //   getOneTicketsIDB();
          // }

          getTicketDetails();
        })
        .catch(err => {
          // console.log(err);
          // setInitCreation(false);
          setInitCreationComment(false);
          
          if (err.code === 14){
						errorStringComment.current = err.message;
					}else{
            errorStringComment.current = "An unexpected error occurred on the server side. Please try again.";
					}
          setServerErrorComment(true);
          setFormProcessing(false);
          // setFormMainProcessing(false);
          reject(err);
        });
    });
  };

  // Function to check if Attachment already exixts
  const checkAttachmentExists = (file: any): boolean => {
    let name: string = file.name;
    if (typeof newTicketAttachment.Attachments === "undefined") {
      return true;
    } else {
      let exists = newTicketAttachment.Attachments.findIndex(
        a => a.Name === name
      );
      if (exists !== -1) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleClick = (event: any) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  // Function to add attachment
  const addAttachment = async (file: any) => {
    let name: string = file.name;
    let isFileExists = checkAttachmentExists(file);
    if (!isFileExists) {
      // Already Exists
      return false;
    } else {
      // Not Exists
      let body: string = await toBase64(file);
      body = body.substring(body.indexOf(",") + 1);
      // let dateStr: string = moment().format("MM/DD/YYYY h:mm:ss");
      let ticketAttachment: TicketAttachment = {
        Name: name,
        Body: body,
        Description: "",
        CreatedDate: moment.utc(moment()).local().format("YYYY-MM-DD hh:mm A")
      };
      let newAttachments = ticketDetails.Attachments;
      newAttachments.push(ticketAttachment);
      setTicketDetails({
        ...ticketDetails,
        Attachments: newAttachments
      });

      return true;
    }
  };

  // Function to increase size of textarea if user enters large text
  const handleKeyDown = (e: any) => {
    e.target.style.height = "inherit";
    // e.target.style.height = `${e.target.scrollHeight}px`;
    // In case you have a limitation
    // e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
    e.target.style.height = `${Math.min(e.target.scrollHeight, 254)}px`;
  };

  // Function to check attachemnt for its size and extension
  const handleFileUpload = async (e: any) => {
    // console.log(e.target.files);
    const fileSize = e.target.files.length;
    // console.log(fileSize);
    let allFiles = e.target.files;
    let errStr = "";
    setAttachmentValidationFlag(false);
    setInitFrom(false);

    for (let i = 0; i < fileSize; i++) {
      setFileTypeValidationMessage(false);
      setFileValidationMessage(false);
      errStr = "";

      // console.log(i);
      // console.log(allFiles);

      if (allFiles[i] !== null) {
        // Check if file already exists in the list
        if (checkAttachmentExists(allFiles[i])) {
          const file = allFiles[i];
          // console.log(file.name);

          // Check file types
          if (checkFileType(file.name.split(".").pop())) {
            // console.log("file format vaild");
            setFileTypeValidationMessage(false);

            // Check file Size
            const filesizevalid = checkFileSize(file);
            if (filesizevalid) {
              setFileValidationMessage(true);
              errStr = "This file exceeds the 4MB attachment limit.";
              // console.log("file size invaild");
            } else {
              let fileSize = file.size;
              if(fileSize > 0){
                // console.log("file size is valid");
                setFileValidationMessage(false);
                setAttachmentValidationFlag(false);
                setInitFrom(false);
              }else{
                setFileTypeValidationMessage(true);
                errStr = "The uploaded file cannot be an empty file (0 byte).";
                setAttachmentValidationFlag(true);
                setInitFrom(true);
              }
            }
          } else {
            // console.log("file format invalid");
            setFileTypeValidationMessage(true);
            errStr = "This file format is not supported.";
            setAttachmentValidationFlag(true);
            setInitFrom(true);
          }

          // Add in array
          let body: string;
          const filesizevalid = checkFileSize(file);
          if (filesizevalid) {
            body = "";
          } else {
            // Check file for zero lenght
            let fileSize = file.size;
            if(fileSize > 0){
              body = await toBase64(file);
              body = body.substring(body.indexOf(",") + 1);
            }else{
              body = "";
            }
          }
          // let dateStr: string = moment().format("MM/DD/YYYY h:mm:ss");

          let filename = file.name;
          let ticketAttachment: TicketAttachment = {
            Name: filename,
            Body: body,
            Description: "",
            CreatedDate: moment.utc(moment()).local().format("YYYY-MM-DD hh:mm A"),
            ErrorMmessage: errStr
          };
          let newAttachments = newTicketAttachment.Attachments;
          newAttachments.push(ticketAttachment);
          // console.log(newTicketAttachment);
          setNewTicketAttachment({
            ...newTicketAttachment,
            Attachments: newAttachments
          });
          // console.log(newTicketAttachment);
        }
      }
    }

    checkAttachment();
  };

  // Check Error in attachements
  const checkAttachment = () => {
    // Filter for checking error in attachemnts
    // console.log(newTicketAttachment);
    let errorCount = newTicketAttachment.Attachments.filter(
      attachment => attachment.ErrorMmessage !== ""
    );
    // console.log(errorCount.length);
    if (errorCount.length === 0) {
      setInitFrom(false);
    } else {
      setInitFrom(true);
    }
  };

  // Function to check file types
  function checkFileType(fileType: any): boolean {
    switch (fileType) {
      case "exe":
        return false;
      case "bin":
        return false;
      case "com":
        return false;
      case "cmd":
        return false;
      case "msi":
        return false;
      default:
        return true;
    }
  }

  // Function to get ticket details
  const getTicketDetails = async () => {
    setupdateForms(true);
    const ticket = await getAllTickets();
    // Set Edit
    if (ticket.Message == "No record found") {
      history.push("/pagenotfound");
      return;
    }

    // console.log(ticket.TimeCreated);
    var offset = moment().utcOffset();
    // ticket.TimeCreated = moment
    //   .utc(ticket.TimeCreated)
    //   .utcOffset(offset)
    //   .format("L LT");
    // ticket.TimeClosed = moment
    //   .utc(ticket.TimeClosed)
    //   .utcOffset(offset)
    //   .format("L LT");

    ticket.TimeCreated = moment.utc(ticket.TimeCreated).local().format(dateFormat);
    ticket.TimeClosed = moment.utc(ticket.TimeClosed).local().format(dateFormat);

    // console.log(ticket.DetailsofIssue);
    let tmpdetails = ticket.DetailsofIssue
      .replace(/\\\\n/g, "\\n")
      .replace(/\\'/g, "'");
    ticket.DetailsofIssue = tmpdetails;
    // console.log(tmpdetails);

    const userName: string = whoAmI?.name ?? "";

    const newTicket = {
      CaseNumber: ticket.CaseNumber,
      Customer: ticket.Customer,
      Subject: ticket.Subject,
      DetailsofIssue: tmpdetails,
      UserComments: "",
      User: userName,
      Attachments: []
    };

    const newTicketComment = {
      CaseNumber: ticket.CaseNumber,
      Customer: ticket.Customer,
      Subject: ticket.Subject,
      DetailsofIssue: tmpdetails,
      UserComments: "",
      User: userName
    };

    setEditTicket(newTicket);
    setTicketComment(newTicketComment);

    // console.log(newTicket);

    // Set comments
    // console.log(ticket)
    // var newCommentHistory = ticket.CommentHistory.filter(
    //   ticket => ticket.CaseComment !== null && ticket.CaseComment.length > 0
    // );
    if (typeof ticket.CommentHistory !== "undefined") {
      var newCommentHistory = ticket.CommentHistory.filter(ticket => {
        if (
          typeof ticket.CaseComment !== "undefined" &&
          ticket.IsPublic === true
        ) {
          return ticket.CaseComment !== null && ticket.CaseComment.length > 0;
        } else {
          return false;
        }
      });

      var newCommentHistory2 = newCommentHistory.map(ticket => {
        let tmpstr = ticket.CaseComment
          .replace(/\\\\n/g, "\\n")
          .replace(/\\'/g, "'");
        ticket.CaseComment = tmpstr;
        // Converting created time from UTC to Local time zone
        ticket.CreatedDate = moment.utc(ticket.CreatedDate).local().format(dateFormat);

        // ticket.CreatedDate = moment
        //   .utc(ticket.CreatedDate)
        //   .utcOffset(offset)
        //   .format("L LT");

        return ticket;
      });
      // console.log(newCommentHistory2);
      setUserComments(newCommentHistory2);
    }

    // set Details
    const newTicketDetails: TicketDetails = Object.assign({}, ticket);
    newTicketDetails.UserComments = "";
    if (typeof newTicketDetails.Attachments === "undefined") {
      newTicketDetails.Attachments = [];
    }

    // Converting Attachment created time from UTC to Local time zone
    newTicketDetails.Attachments.map(attachment => {
      attachment.CreatedDate = moment.utc(attachment.CreatedDate).local().format(dateFormat);

      // attachment.CreatedDate = moment
      //   .utc(attachment.CreatedDate)
      //   .utcOffset(offset)
      //   .format("L LT");
    });

    const tmpstr = newTicketDetails.DetailsofIssue.replace(/\n/g, "<br />");
    newTicketDetails.DetailsofIssue = tmpstr;

    setTicketDetails(newTicketDetails);
    setTicketDetails2(newTicketDetails);
    setTicketDetailsComment(newTicketDetails);

    setupdateForms(false);
  };

  //
  const handleCustomerChange = async (selectedItem: any) => {
    const selectedCustomer = selectedItem.target.value;
    await setTicketDetails(ticketDetails => ({
      ...ticketDetails,
      Customer: selectedCustomer
    }));
  };

  // Function to get ticket form IDB
  // const getOneTicketsIDB = async () => {
  //   var offset = moment().utcOffset();

  //   try {
  //     let ticket = await idbHelper.__retriveOneTicketIDB(ticketid);
  //     // console.log(ticket);
  //     if (ticket != undefined) {

  //       if (ticket !== undefined) {
  //         const newTicket = {
  //           UserComments: ticket.UserComments,
  //           User: ticket.User,
  //           TimeCreated: ticket.TimeCreated,
  //           TimeClosed: "",
  //           Subject: ticket.Subject,
  //           State: ticket.State,
  //           Severity: "",
  //           Resolution: "",
  //           // Partner: ticket.Partner,
  //           Message: ticket.Email,
  //           Email: ticket.Email,
  //           DetailsofIssue: ticket.DetailsofIssue,
  //           Customer: ticket.Customer,
  //           CommentHistory: [],
  //           CaseNumber: ticket.CaseNumber,
  //           // CaseOrigin: ticket?.CaseOrigin ?? '',
  //           // CaseID: ticket.CaseID,
  //           // AssignedTo: ticket.AssignedTo,
  //           Attachments: [],
  //           AccountName: getPartnerDetails().company_name
  //         };
  //         setTicketDetails(newTicket);
  //         setTicketDetails2(newTicket);
  //       }
  //     }
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // };

  const getVariant = (): any => {
    if (ticketDetails.State === "New") {
      return "info";
    } else if (ticketDetails.State === "Resolved") {
      return "success";
    } else if (ticketDetails.State === "In Progress") {
      return "warning";
    }
    return "warning";
  };

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      errorString.current = "";
      errorStringComment.current = "";
      // isAvailabeIDB = await checkIfIDBIsAvailable();
      // console.log("checkIfIDBIsAvailable : " + isAvailabeIDB);

      // if (isAvailabeIDB) {
      //   getOneTicketsIDB();
      // }

      await getTicketDetails();

      // Get Customers from API
      // getCustomers();
      setLoading(false);
    };

    fetchData();
  }, []);

  /** Details Renders */
  const renderDetailsCardHeader = (
    <Row>
      <Col>
        <p className="case-subject">Details</p>
        {!formEdit && (
          <Can perform={SUPPORT_EDIT}>
            <DefaultButton
              type="button"
              variant="outline-secondary"
              className="float-right"
              onClick={editFormShow}
            >
              Edit
            </DefaultButton>
          </Can>
        )}
      </Col>
    </Row>
  );
  const renderDetailsCardBody = (
    <>
      <Row>
        <Col style={{ display: "flex", marginBottom: "16px" }}>
          <div style={{ width: "120px" }}>
            <p className="case-label">Status</p>
          </div>
          <p className="case-text">
            {/* <span className={checkState()}>{ticketDetails.State}</span> */}
            <LyveBadge data-testid="ticket-state" variant={getVariant()}>{ticketDetails.State}</LyveBadge>
          </p>
        </Col>
      </Row>

      <Row>
        <Col style={{ display: "flex", marginBottom: "16px" }}>
          <div style={{ width: "120px" }}>
            <p className="case-label">Severity</p>
          </div>
          <p className="case-text" data-testid="ticket-severity">{ticketDetails.Severity}</p>
        </Col>
      </Row>

      {/* <Row>
        <Col style={{ display: "flex", marginBottom: "16px" }}>
          <div style={{ width: "120px" }}>
            <p className="case-label">CaseOrigin</p>
          </div>
          <p className="case-text">{ticketDetails.CaseOrigin}</p>
        </Col>
      </Row> */}

      <Row>
        <Col style={{ display: "flex", marginBottom: "16px" }}>
          <div style={{ width: "120px" }}>
            <p className="case-label">Subject</p>
          </div>

          {formEdit ? (
            <Input
              maxLength={256}
              controlId="subject"
              size="sm"
              name="Subject"
              value={ticketDetails.Subject}
              isInvalid={subjectValidate ? true : false}
              error={subjectValidate}
              onChange={updateOnChange}
              className="update-form-text"
              onKeyPress={() => {
                validator.current.showMessageFor("subject");
              }}
              onKeyUp={checkErrorSubject}
              onBlur={() => {
                validator.current.fieldValid("subject")
                  ? setInitCreation(false)
                  : setInitCreation(true);
              }}
            />
          ) : (
            <div>
              <p className="case-text case-largetext" data-testid="ticket-subject">
                {ticketDetails.Subject}
              </p>
            </div>
          )}
        </Col>
      </Row>

      <Row>
        <Col style={{ display: "flex", marginBottom: "16px" }}>
          <div style={{ width: "120px" }}>
            <p className="case-label">Description</p>
          </div>
          {/* <Form.Group
              controlId="detailsOfIssue"
              className="update-form"
              style={{ marginLeft: "0px" }}
            >
              <Form.Control
                maxLength={5001}
                as="textarea"
                rows={4}
                name="DetailsofIssue"
                value={ticketDetails.DetailsofIssue}
                onChange={updateOnChange}
                isInvalid={detailsOfIssueValidate ? true : false}
                className="update-form-text"
                onKeyPress={() => {
                  validator.current.showMessageFor("detailsOfIssue");
                }}
                onKeyUp={checkErrorDesc}
                onBlur={() => {
                  validator.current.fieldValid("detailsOfIssue")
                    ? setInitCreation(false)
                    : setInitCreation(true);
                }}
                onKeyDown={handleKeyDown}
              />
              {detailsOfIssueValidate && (
                <Form.Control.Feedback type="invalid">
                  {detailsOfIssueValidate}
                </Form.Control.Feedback>
              )}
            </Form.Group> */}
          {formEdit ? (
            <Input
              controlId="detailsOfIssue"
              controlAs="textarea"
              rows={4}
              maxLength={5001}
              name="DetailsofIssue"
              value={ticketDetails.DetailsofIssue}
              onChange={updateOnChange}
              isInvalid={detailsOfIssueValidate ? true : false}
              className="update-form-text"
              error={detailsOfIssueValidate}
              onKeyPress={() => {
                validator.current.showMessageFor("detailsOfIssue");
              }}
              onKeyUp={checkErrorDesc}
              onBlur={() => {
                validator.current.fieldValid("detailsOfIssue")
                  ? setInitCreation(false)
                  : setInitCreation(true);
              }}
              onKeyDown={handleKeyDown}
            />
          ) : (
            // <p className="case-text">{ticketDetails.DetailsofIssue}</p>
            <div>
              {/* <p
                className="case-text case-largetext"
                dangerouslySetInnerHTML={{
                  __html: ticketDetails.DetailsofIssue
                }}
              /> */}
              <p className="case-text case-largetext" data-testid="ticket-desc">{ticketDetails.DetailsofIssue}</p>
            </div>
          )}
        </Col>
      </Row>
      {!partnerDetails.isAnyCustomer && (
        <Row>
          <Col style={{ display: "flex", marginBottom: "16px" }}>
            <div style={{ width: "120px" }}>
              <p className="case-label">Reported By</p>
            </div>

            {formEdit ? (
              <Form.Group
                controlId="customer"
                className="update-form update-form-select"
                style={{ marginLeft: "24px" }}
              >
                <select
                  className="form-control form-control-sm"
                  name="filter"
                  id="filter"
                  onChange={handleCustomerChange}
                  style={{
                    border: "1px solid #CCCCCC",
                    borderRadius: "4px"
                  }}
                >
                  <option key="none">{ticketDetails.Customer}</option>
                  {customers_array.map((val, index) => {
                    return (
                      <option key={index} value={val}>
                        {val}
                      </option>
                    );
                  })}
                </select>
              </Form.Group>
            ) : (
              <p className="case-text">{ticketDetails.Customer}</p>
            )}
          </Col>
        </Row>
      )}

      <Row>
        <Col style={{ display: "flex", marginBottom: "16px" }}>
          <div style={{ width: "120px" }}>
            <p className="case-label">Submitted By</p>
          </div>
          <p data-testid="user-email" className="case-text">{ticketDetails.Email}</p>
        </Col>
      </Row>

      <Row>
        <Col style={{ display: "flex", marginBottom: "16px" }}>
          <div style={{ width: "120px" }}>
            <p className="case-label">Created Time</p>
          </div>
          <p className="case-text">{ticketDetails.TimeCreated}</p>
        </Col>
      </Row>

      <Row>
        <Col style={{ display: "flex", marginBottom: "16px" }}>
          <div style={{ width: "120px" }}>
            <p className="case-label">Attachments</p>
          </div>
          <div className="attachemnt-wrapper">
            {formEdit && (
              <div>
                <Form.Group
                  controlId="attachment"
                  className="update-form update-form-upload"
                  style={{ marginLeft: "0px" }}
                >
                  {/* <span className="btn btn-outline-secondary btn-file">
                    <span className="btn-title">
                      <PublishOutlinedIcon fontSize="small" /> Upload
                    </span>
                    <Form.Control
                      type="file"
                      size="sm"
                      onChange={handleFileUpload}
                      onClick={handleClick}
                      multiple
                    />
                  </span> */}

                  <DefaultButton
                    variant="outline-secondary"
                    className="btn-file"
                  >
                    <span className="btn-title">
                      <PublishOutlinedIcon fontSize="small" /> Upload
                    </span>
                    <Form.Control
                      type="file"
                      size="sm"
                      onChange={handleFileUpload}
                      onClick={handleClick}
                      multiple
                    />
                  </DefaultButton>
                </Form.Group>
                {/* {uploadProgress > 0 ? (
                  <div style={{position: "relative"}}>
                    <ProgressBar
                      now={uploadProgress}
                      label={<span>{uploadingFileName}</span>}
                    />
                    <button className="cancelAttachment" onClick={cancleUpload}>
                      <CloseIcon />
                    </button>
                  </div>
                ) : (
                    ""
                )} */}
              </div>
            )}

            {formEdit && (
              <div>
                {typeof newTicketAttachment.Attachments !== "undefined" &&
                  newTicketAttachment.Attachments !== null ? (
                  <div className="attachment-area">
                    {/* <table className="table table-sm attachment-table">
                    <tbody> */}
                    {newTicketAttachment.Attachments.map(
                      (attachment: TicketAttachment, i: number) => {
                        return (
                          <div key={i}>
                            <Row noGutters={true}>
                              <Col
                                style={{
                                  display: "flex",
                                  paddingLeft: "16px !important",
                                  paddingRight: "16px !important"
                                }}
                              >
                                <div className="attachmant-holder">
                                  <div className="new-attachments">
                                    <span className="attachment-link" title={attachment.Name}>
                                      {attachment.Name}
                                    </span>
                                    <span style={{ marginLeft: "10px" }}>
                                      {attachment.CreatedDate}
                                    </span>
                                    <Link
                                      className="attachment-icon"
                                      style={{ float: "right" }}
                                      to="#"
                                      onClick={deleteAttachment}
                                    >
                                      <CloseIcon />
                                    </Link>
                                  </div>
                                  <span className="error-messages">
                                    {attachment.ErrorMmessage}
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          // <tr className="mb-3" key={i}>
                          //   <td>
                          //     {/* <AttachFile /> */}
                          //     <Link to="#" onClick={() => getAttachment(attachment.FileID)}>{attachment.Name}</Link>
                          //     <span style={{ marginLeft: "10px" }}>{attachment.CreatedDate}</span>
                          //     <span className="error-messages">{attachment.ErrorMmessage}</span>
                          //     {/* {showFileTypeValidationMessage && (
                          //       <span className="error-messages">This file format is not supported.</span>
                          //     )}
                          //     {showFileValidationMessage && (
                          //       <span className="error-messages">This file exceeds the 2GB attachment limit.</span>
                          //     )} */}
                          //   </td>
                          //   <td style={{ width: "20%" }}>
                          //     <Link style={{ float: "right" }} to="#" onClick={deleteAttachment}>
                          //       <CloseIcon />
                          //     </Link>
                          //   </td>
                          // </tr>
                        );
                      }
                    )}
                    {/* </tbody>
                  </table> */}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            )}

            {!fileUploadStatus ? (
              <div>
                <span className="error-messages">Upload failed.</span>
                <span
                  className="error-messages-retry"
                  onClick={onUploadAttachments}
                >
                  Retry
                </span>
              </div>
            ) : (
              ""
            )}

            {formEdit ? (
              <div>
                {typeof ticketDetails.Attachments !== "undefined" &&
                  ticketDetails.Attachments !== null ? (
                  <div className="attachment-area">
                    <table className="table table-sm attachment-table">
                      <tbody>
                        {ticketDetails.Attachments.map(
                          (attachment: TicketAttachment, i: number) => {
                            return (
                              <tr className="mb-3" key={i}>
                                <td>
                                  {/* <AttachFile /> */}
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      getAttachment(attachment.FileID)
                                    }
                                    title={attachment.Name}
                                  >
                                    {attachment.Name}
                                  </Link>
                                  <span style={{ marginLeft: "10px" }}>
                                    {attachment.CreatedDate}
                                  </span>
                                </td>
                                {/* <td style={{ width: "20%" }}>
                                <Link style={{ float: "right" }} to="#" onClick={() => deleteAttachment(attachment.FileID)}>
                                  <CloseIcon />
                                </Link>
                              </td> */}
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            ) : (
              <Row noGutters={true}>
                <Col style={{ display: "flex" }}>
                  <table className="table table-sm attachment-table-view">
                    <tbody>
                      {ticketDetails.Attachments.map(
                        (attachment: TicketAttachment, i: number) => {
                          return (
                            <tr className="mb-3 case-text" key={i}>
                              <td  data-testid="attachment-name">
                                <Link
                                  to="#"
                                  onClick={() =>
                                    getAttachment(attachment.FileID)
                                  }
                                  title={attachment.Name}
                                >
                                  {attachment.Name}
                                </Link>
                                <span style={{ marginLeft: "10px" }}>
                                  {attachment.CreatedDate}
                                </span>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </Col>
              </Row>
            )}
          </div>
        </Col>

        <Row>
          <Col style={{ display: "flex", marginBottom: "16px" }}>
            {uploadProgress > 0 ? (
              <ProgressBar
                now={uploadProgress}
                label={<span>{uploadingFileName}</span>}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Row>

      {formEdit && (
        <div>
          <Row>
            <Col
              style={{
                display: "flex",
                marginBottom: "16px",
                marginTop: "16px"
              }}
            >
              {formEdit && (
                <DefaultButton
                  onClick={updateTicketDetails}
                  variant="primary"
                  type="submit"
                  // style={{ width: "100px" }}
                  className="mr-2"
                  disabled={initForm}
                >
                  {isFormMainProcessing && (
                    <ButtonLoader animation="border" className="mr-2" />
                  )}
                  {isFormMainProcessing ? "Saving" : "Save"}
                </DefaultButton>
              )}

              {formEdit && (
                <DefaultButton
                  type="button"
                  // style={{ width: "100px", marginLeft: "16px", border: "none" }}
                  // className="btn btn-secondary"
                  variant="outline-secondary"
                  onClick={editFormHide}
                >
                  Cancel
                </DefaultButton>
              )}
            </Col>
          </Row>
          <Row>
            <Col style={{ display: "flex" }}>
              {serverError && (
                <span className={classes.errorTxtSubject}>
                  {errorString.current}
                </span>
              )}
            </Col>
          </Row>
        </div>
      )}
    </>
  );

  /** Resolution Renders */
  const renderResolutionCardHeader = (
    <Row>
      <Col>
        <p className="mb-0">Resolution</p>
      </Col>
    </Row>
  );

  const renderResolutionCardBody = (
    <div>
      <Row>
        <Col md="12">
          {typeof ticketDetails.Resolution !== "undefined" &&
            ticketDetails.Resolution !== null &&
            ticketDetails.State === "Resolved" ? (
            <p className="case-text case-resolution">
              {ticketDetails.Resolution}
            </p>
          ) : (
            <p className="case-text case-resolution">
              There is no resolution yet.
            </p>
          )}
        </Col>
      </Row>

      {typeof ticketDetails.TimeClosed !== "undefined" &&
        ticketDetails.TimeClosed !== null &&
        ticketDetails.State === "Resolved" ? (
        <Row>
          <Col md="12">
            <p>
              <span className="comment-date"> {ticketDetails.TimeClosed}</span>
              {/* <span className="name">{ticketDetails.TimeClosed}</span> */}
              {/* {typeof ticketDetails.TimeClosed !== "undefined" && ticketDetails.TimeClosed !== null ? (
                <span className="comment-date"> {ticketDetails.TimeClosed}</span>
              ) : (
                  ''
                )} */}
            </p>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </div>
  );

  /** Comment Renders */
  const renderCommentsCardBody = (
    <>
      <Row>
        <Col md="12">
          {commentAdd ? (
            <div className="comment-add-area">
              <p className="case-subject">Comments</p>
              <Input
                controlId="comment"
                controlAs="textarea"
                rows={3}
                maxLength={3801}
                name="UserComments"
                value={ticketDetailsComment.UserComments}
                placeholder="Add a comment"
                onChange={updateField}
                className="comment-box"
                isInvalid={UserCommentsValidate ? true : false}
                error={UserCommentsValidate}
                onBlur={() => {
                  validator.current.showMessageFor("userComments");
                }}
                onKeyUp={checkErrorComment}
                onKeyPress={() => {
                  validator.current.fieldValid("userComments")
                    ? setInitCreationComment(false)
                    : setInitCreationComment(true);
                }}
              />
              {/* <Form.Group controlId="comment">
                <Form.Control
                  maxLength={3801}
                  as="textarea"
                  rows={3}
                  name="UserComments"
                  value={ticketDetails.UserComments}
                  placeholder="Add a comment"
                  onChange={updateField}
                  className="comment-box"
                  isInvalid={UserCommentsValidate ? true : false}
                  onBlur={() => {
                    validator.current.showMessageFor("userComments");
                  }}
                  onKeyUp={checkErrorComment}
                  onKeyPress={() => {
                    validator.current.fieldValid("userComments")
                      ? setInitCreationComment(false)
                      : setInitCreationComment(true);
                  }}
                />
                {UserCommentsValidate && (
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: "block", marginLeft: "0px" }}
                  >
                    {UserCommentsValidate}
                  </Form.Control.Feedback>
                )}
              </Form.Group> */}
              <div className="float-left" style={{ marginBottom: "16px", width: "100%" }}>
                <DefaultButton
                  onClick={addComment}
                  variant="primary"
                  className="mr-2"
                  disabled={initCreationComment}
                >
                  {isFormProcessing && (
                    // <CircularProgress className={classes.spinner} size={14} />
                    <ButtonLoader animation="border" className="mr-2" />
                  )}{" "}
                  {isFormProcessing ? "Adding" : "Add"}
                </DefaultButton>
                <DefaultButton
                  variant="outline-secondary"
                  onClick={hideCommentAdd}
                >
                  Cancel
                </DefaultButton>
              </div>
              <div className="float-left" style={{ marginBottom: "24px", width: "100%" }}>
              {serverErrorComment && (
                <span className={classes.errorTxtSubject}>
                  {errorStringComment.current}
                </span>
              )}
            </div>
            </div>
          ) : (
            <div className="comment-add-area">
              <p className="case-subject">Comments</p>
              <Can perform={SUPPORT_EDIT}>
                <DefaultButton
                  type="button"
                  variant="outline-secondary"
                  className="float-right"
                  onClick={showCommentAdd}
                >
                  Add New Comment
                </DefaultButton>
                {/* <p className="btn btn-outline-secondary float-right" onClick={showCommentAdd}>
                    Add New Comment
                </p> */}
              </Can>
            </div>
          )}
        </Col>
      </Row>

      <Row>
        <Col md="12">
          {userComments.length > 0 ? (
            <div className="comment-area">
              {userComments.map((comment, i) => {
                return (
                  <div className="comment" key={i}>
                    {/* <p
                      className="comment-text"
                      dangerouslySetInnerHTML={{
                        __html: comment.CaseComment
                      }}
                    /> */}
                    <p className="comment-text">{comment.CaseComment}</p>
                    <p>
                      <span className="name">{comment.CreatorName}</span>
                      <span className="comment-date">
                        {" "}
                        {comment.CreatedDate}
                      </span>
                    </p>
                  </div>
                );
              })}
            </div>
          ) : (
            <p className="comments">There are no comments yet.</p>
          )}
        </Col>
      </Row>
    </>
  );

  if (loading) {
    return (
      <div className="support-container" style={{ margin: "32px 0 32px 0" }}>
        <div className="data-loader">
          <Spinner animation="border" role="status" className="spinner">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  }

  return (
    <div className="support-ticket-details" style={{ margin: "32px 0 32px 0" }}>
      <Form className="editForm">
        {updateForms && (
          <div className="load-content--2">
            {/* <Spinner animation="grow" /> */}
            <DefaultLoader animation="border" />
          </div>
        )}
        {/* <Row>
          <Col md="12">
            <p className="case-number">#{ticketDetails.CaseNumber}</p>
          </Col>
        </Row> */}
        <Row>
          <Col>
            <HeadingBreadCrumb
              heading={`${ticketDetails.Subject}`}
              breadcrumbs={[
                {
                  title: "Support",
                  // href: "/customer/support", // todo: check if partner and change link accordingly
                  onClick: () => { handleBreadCrumbsClick() }
                },
                {
                  title: `#${ticketDetails.CaseNumber}`,
                  active: true
                }
              ]}
            />
          </Col>
        </Row>
        {/* <Row>
          <Col md="12">
            <p className="case-subject">{ticketDetails.Subject}</p>
          </Col>
        </Row> */}
        <Row>
          <Col md="6">
            <BasicCard
              title={renderDetailsCardHeader}
              body={renderDetailsCardBody}
              className="mb-32"
            />

            <BasicCard
              title={renderResolutionCardHeader}
              body={renderResolutionCardBody}
              className="mb-32"
            />
          </Col>
          <Col md="6">
            <BasicCard
              title=""
              body={renderCommentsCardBody}
              className="mb-32"
            />
          </Col>
        </Row>

        {/* <Row className="no-gutters">
          <Col md="6">
            <div className="case-content-area rmargin">
              <div>
                <Row>
                  <Col >
                    <p className="case-subject">Details</p>
                    {!formEdit && (
                      <Can perform={SUPPORT_EDIT}>
                        <Button
                          type="button"
                          variant="outline-secondary"
                          className="float-right"
                          onClick={editFormShow}
                        >
                          Edit
                        </Button>
                      </Can>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col style={{ display: "flex", marginBottom: "16px" }}>
                    <div style={{ width: "120px" }}>
                      <p className="case-label">Status</p>
                    </div>
                    <p className="case-text">{ticketDetails.State}</p>
                  </Col>
                </Row>

                <Row>
                  <Col style={{ display: "flex", marginBottom: "16px" }}>
                    <div style={{ width: "120px" }}>
                      <p className="case-label">Severity</p>
                    </div>
                    <p className="case-text">{ticketDetails.Severity}</p>
                  </Col>
                </Row>

                <Row>
                  <Col style={{ display: "flex", marginBottom: "16px" }}>
                    <div style={{ width: "120px" }}>
                      <p className="case-label">Subject</p>
                    </div>

                    {formEdit ? (
                      <Form.Group controlId="subject" className="update-form" style={{ marginLeft: "0px" }}>
                        <Form.Control
                          maxLength={256}
                          type="text"
                          size="sm"
                          name="Subject"
                          value={ticketDetails.Subject}
                          onChange={updateOnChange}
                          className="update-form-text"
                          isInvalid={subjectValidate ? true : false}
                          onKeyPress={() => {
                            validator.current.showMessageFor("subject");
                          }}
                          onKeyUp={checkErrorSubject}
                          onBlur={() => {
                            validator.current.fieldValid("subject")
                              ? setInitCreation(false)
                              : setInitCreation(true);
                          }}
                        />
                        {subjectValidate && (
                          <Form.Control.Feedback type="invalid">
                            {subjectValidate}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    ) : (
                        <div>
                          <p className="case-text case-largetext">{ticketDetails.Subject}</p>
                        </div>
                      )}
                  </Col>
                </Row>

                <Row>
                  <Col style={{ display: "flex", marginBottom: "16px" }}>
                    <div style={{ width: "120px" }}>
                      <p className="case-label">Description</p>
                    </div>


                    {formEdit ? (
                      <Form.Group controlId="detailsOfIssue" className="update-form" style={{ marginLeft: "0px" }}>
                        <Form.Control
                          maxLength={5001}
                          as="textarea"
                          rows={4}
                          name="DetailsofIssue"
                          value={ticketDetails.DetailsofIssue}
                          onChange={updateOnChange}
                          isInvalid={detailsOfIssueValidate ? true : false}
                          className="update-form-text"
                          onKeyPress={() => {
                            validator.current.showMessageFor("detailsOfIssue");
                          }}
                          onKeyUp={checkErrorDesc}
                          onBlur={() => {
                            validator.current.fieldValid("detailsOfIssue")
                              ? setInitCreation(false)
                              : setInitCreation(true);
                          }}
                          onKeyDown={handleKeyDown}
                        />
                        {detailsOfIssueValidate && (
                          <Form.Control.Feedback type="invalid">
                            {detailsOfIssueValidate}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    ) : (
                        <div>
                          <p className="case-text case-largetext" dangerouslySetInnerHTML={{ __html: ticketDetails.DetailsofIssue }} />
                        </div>
                      )}
                  </Col>
                </Row>
                {
                  !partnerDetails.direct_customer &&
                  (
                    <Row>
                      <Col style={{ display: "flex", marginBottom: "16px" }}>
                        <div style={{ width: "120px" }}>
                          <p className="case-label">Reported By</p>
                        </div>

                        {formEdit ? (
                          <Form.Group controlId="customer" className="update-form update-form-select" style={{ marginLeft: "24px" }}>
                            <select
                              className="form-control form-control-sm"
                              name="filter"
                              id="filter"
                              onChange={handleCustomerChange}
                              style={{
                                border: "1px solid #CCCCCC",
                                borderRadius: "4px",
                              }}
                            >
                              <option key="none">{ticketDetails.Customer}</option>
                              {customers_array.map((val, index) => {
                                return (
                                  <option key={index} value={val}>
                                    {val}
                                  </option>
                                );
                              })}
                            </select>
                          </Form.Group>
                        ) : (
                            <p className="case-text">{ticketDetails.Customer}</p>
                          )}
                      </Col>
                    </Row>
                  )
                }

                <Row>
                  <Col style={{ display: "flex", marginBottom: "16px" }}>
                    <div style={{ width: "120px" }}>
                      <p className="case-label">Submitted By</p>
                    </div>
                    <p className="case-text">{ticketDetails.Email}</p>
                  </Col>
                </Row>

                <Row>
                  <Col style={{ display: "flex", marginBottom: "16px" }}>
                    <div style={{ width: "120px" }}>
                      <p className="case-label">Created Time</p>
                    </div>
                    <p className="case-text">{ticketDetails.TimeCreated}</p>
                  </Col>
                </Row>

                <Row>
                  <Col style={{ display: "flex", marginBottom: "16px" }}>
                    <div style={{ width: "120px" }}>
                      <p className="case-label">Attachments</p>
                    </div>
                    <div className="attachemnt-wrapper">
                      {formEdit && (
                        <div>
                          <Form.Group controlId="attachment" className="update-form update-form-upload" style={{ marginLeft: "24px" }}>
                            <span className="btn btn-outline-secondary btn-file">
                              <span className="btn-title">
                                <PublishOutlinedIcon fontSize="small" /> Upload
                              </span>
                              <Form.Control
                                type="file"
                                size="sm"
                                onChange={handleFileUpload}
                                onClick={handleClick}
                                multiple
                              />
                            </span>
                          </Form.Group>
                        </div>
                      )}

                      {formEdit && (
                        <div>
                          {typeof newTicketAttachment.Attachments !==
                            "undefined" &&
                            newTicketAttachment.Attachments !== null ? (
                              <div className="attachment-area">
                                {newTicketAttachment.Attachments.map(
                                  (attachment: TicketAttachment, i: number) => {
                                    return (
                                      <div key={i}>
                                        <Row>
                                          <Col

                                            style={{
                                              display: "flex",
                                              paddingLeft: "16px !important",
                                              paddingRight: "16px !important",
                                            }}
                                          >
                                            <div className="attachmant-holder">
                                              <div className="new-attachments">
                                                <span className="attachment-link">
                                                  {attachment.Name}
                                                </span>
                                                <span
                                                  style={{ marginLeft: "10px" }}
                                                >
                                                  {attachment.CreatedDate}
                                                </span>
                                                <Link
                                                  className="attachment-icon"
                                                  style={{ float: "right" }}
                                                  to="#"
                                                  onClick={deleteAttachment}
                                                >
                                                  <CloseIcon />
                                                </Link>
                                              </div>
                                              <span className="error-messages">
                                                {attachment.ErrorMmessage}
                                              </span>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            ) : (
                              <div></div>
                            )}
                        </div>
                      )}

                      {!fileUploadStatus ? (
                        <div>
                          <span className="error-messages">Upload failed.</span>
                          <span
                            className="error-messages-retry"
                            onClick={onUploadAttachments}
                          >
                            Retry
                          </span>
                        </div>
                      ) : (
                          ""
                        )}

                      {formEdit ? (
                        <div>
                          {typeof ticketDetails.Attachments !== "undefined" &&
                            ticketDetails.Attachments !== null ? (
                              <div className="attachment-area">
                                <table className="table table-sm attachment-table">
                                  <tbody>
                                    {ticketDetails.Attachments.map(
                                      (
                                        attachment: TicketAttachment,
                                        i: number
                                      ) => {
                                        return (
                                          <tr className="mb-3" key={i}>
                                            <td>
                                              <Link
                                                to="#"
                                                onClick={() =>
                                                  getAttachment(attachment.FileID)
                                                }
                                              >
                                                {attachment.Name}
                                              </Link>
                                              <span
                                                style={{ marginLeft: "10px" }}
                                              >
                                                {attachment.CreatedDate}
                                              </span>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            ) : (
                              <div></div>
                            )}
                        </div>
                      ) : (
                          <Row>
                            <Col

                              style={{ display: "flex" }}
                            >
                              <table className="table table-sm attachment-table-view">
                                <tbody>
                                  {ticketDetails.Attachments.map(
                                    (attachment: TicketAttachment, i: number) => {
                                      return (
                                        <tr className="mb-3 case-text" key={i}>
                                          <td>
                                            <Link
                                              to="#"
                                              onClick={() =>
                                                getAttachment(attachment.FileID)
                                              }
                                            >
                                              {attachment.Name}
                                            </Link>
                                            <span style={{ marginLeft: "10px" }}>
                                              {attachment.CreatedDate}
                                            </span>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                        )}
                    </div>
                  </Col>

                  <Row>
                    <Col

                      style={{ display: "flex", marginBottom: "16px" }}
                    >
                      {uploadProgress > 0 ? (
                        <ProgressBar
                          now={uploadProgress}
                          label={<span>{uploadingFileName}</span>}
                        />
                      ) : (
                          ""
                        )}
                    </Col>
                  </Row>
                </Row>

                {formEdit && (
                  <div>
                    <Row>
                      <Col

                        style={{
                          display: "flex",
                          marginBottom: "16px",
                          marginTop: "16px",
                        }}
                      >
                        {formEdit && (
                          <Button
                            onClick={updateTicketDetails}
                            variant="primary"
                            type="submit"
                            className="mr-2"
                            disabled={initForm}
                          >
                            {isFormMainProcessing && (
                              <CircularProgress
                                className={classes.spinner}
                                size={14}
                              />
                            )}{" "}
                            {isFormMainProcessing ? "Saving" : "Save"}
                          </Button>
                        )}

                        {formEdit && (
                          <Button
                            type="button"
                            variant="outline-secondary"
                            onClick={editFormHide}
                          >
                            Cancel
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ display: "flex" }}>
                        {serverError && (
                          <span className={classes.errorTxtSubject}>
                            An unexpected error occurred on the server side.
                            Please try again.
                          </span>
                        )}
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            </div>

            <div className="case-content-area rmargin resolution">
              <div>
                <Row>
                  <Col >
                    <p className="case-subject">Resolution</p>
                  </Col>
                </Row>

                <Row>
                  <Col md="12" >
                    {typeof ticketDetails.Resolution !== "undefined" &&
                      ticketDetails.Resolution !== null && ticketDetails.State === "Resolved" ? (
                        <p className="case-text case-resolution">
                          {ticketDetails.Resolution}
                        </p>
                      ) : (
                        <p className="case-text case-resolution">
                          There is no resolution yet.
                        </p>
                      )}
                  </Col>
                </Row>

                {typeof ticketDetails.TimeClosed !== "undefined" && ticketDetails.TimeClosed !== null && ticketDetails.State === "Resolved" ? (
                  <Row>
                    <Col md="12" >
                      <p>
                        <span className="comment-date"> {ticketDetails.TimeClosed}</span>
                      </p>
                    </Col>
                  </Row>
                ) : (
                    ''
                  )}

              </div>
            </div>
          </Col>

          <Col md="6">
            <div className="case-content-area lmargin">
              <Row>
                <Col md="12">
                  {commentAdd ? (
                    <div className="comment-add-area">
                      <p className="case-subject">Comments</p>
                      <Form.Group controlId="comment">
                        <Form.Control
                          maxLength={3801}
                          as="textarea"
                          rows={3}
                          name="UserComments"
                          value={ticketDetails.UserComments}
                          placeholder="Add a comment"
                          onChange={updateField}
                          className="comment-box"
                          isInvalid={UserCommentsValidate ? true : false}
                          onBlur={() => {
                            validator.current.showMessageFor("userComments");
                          }}
                          onKeyUp={checkErrorComment}
                          onKeyPress={() => {
                            validator.current.fieldValid("userComments")
                              ? setInitCreationComment(false)
                              : setInitCreationComment(true);
                          }}
                        />
                        {UserCommentsValidate && (
                          <Form.Control.Feedback
                            type="invalid"
                            style={{ display: "block", marginLeft: "0px" }}
                          >
                            {UserCommentsValidate}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      <div
                        className="float-left"
                        style={{ marginBottom: "24px" }}
                      >
                        <Button
                          onClick={addComment}
                          variant="primary"
                          className="mr-2"
                          disabled={initCreationComment}
                        >
                          {isFormProcessing && (
                            <CircularProgress
                              className={classes.spinner}
                              size={14}
                            />
                          )}{" "}
                          {isFormProcessing ? "Adding" : "Add"}
                        </Button>
                        <Button
                          variant="outline-secondary"
                          onClick={hideCommentAdd}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  ) : (
                      <div className="comment-add-area">
                        <p className="case-subject">Comments</p>
                        <Can perform={SUPPORT_EDIT}>
                          <p className="btn btn-outline-secondary float-right" onClick={showCommentAdd}>
                            Add New Comment
                        </p>
                        </Can>
                      </div>
                    )}
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  {userComments.length > 0 ? (
                    <div className="comment-area">
                      {userComments.map((comment, i) => {
                        return (
                          <div className="comment" key={i}>
                            <p
                              className="comment-text"
                              dangerouslySetInnerHTML={{
                                __html: comment.CaseComment,
                              }}
                            />
                            <p>
                              <span className="name">
                                {comment.CreatorName}
                              </span>
                              <span className="comment-date">
                                {" "}
                                {comment.CreatedDate}
                              </span>
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                      <p className="comments">There are no comments yet.</p>
                    )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row> */}
      </Form>
    </div>
  );
};

export default SupportTicketDetailsUpdate;
