const REGEX = {
    alphaNumericDashSpace: /^[a-zA-Z\d\s-]+$/,
    alphaNumericDash: /^[a-zA-Z\d-]+$/,
    alphaSpace: /^[aA-zZ\s]+$/,
    alphaNumericDashSpecialCharacter: /^[a-zA-Z\d\s'&,_.-]+$/,
    emailRegex: /^(\w[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]{0,62})[a-zA-Z0-9-]@[a-zA-Z0-9-]{1,63}\.*(\.\w{1,62})+[a-zA-Z]$/,
    number: /^\d+$/,
    zipCode: /^[0-9]{5,7}$/
  }

  export default REGEX
