import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";

interface Props {
  show: boolean;
  submit: () => Promise<void>;
  onClose: () => void;
  title: string;
  description: string;
  error?: string;
}

const ConfirmationModal: React.FC<Props> = (props) => {
  const [processing, setProcessing] = React.useState(false);
  const { show, onClose, submit, title, description, error } = props;
  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-60w"
      className="lyve-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{description}</Modal.Body>
      { (typeof error !== undefined || error !== "") && (
        <Modal.Body 
          className="model-error"
        >{error}</Modal.Body>)}{""}
      <Modal.Footer>
        <Button
          disabled={processing}
          onClick={async () => {
            setProcessing(true);
            await submit();
            setProcessing(false);
          }}
        >
          {processing && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}{" "}
          Confirm
        </Button>
        <Button variant="outline-secondary" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
