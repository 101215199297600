import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../store";
import { SliceState, StoreStateStatus } from "./types";

export interface Region {
  name: string;
  description: string;
  url: string;
  default: boolean;
  accessType?: string | undefined;
  S3DomainURL?: string | undefined;
  status?: string | undefined;
  pricingRegion?: string | undefined;
}

declare type State = SliceState<Region[]>;

const initialState: State = { status: StoreStateStatus.IDLE, error: null, data: [] };

const regionsSlice = createSlice({
  name: "regions",
  initialState,
  reducers: {
    loadingRegions: state => {
      state.status = StoreStateStatus.LOADING;
    },
    resolveRegions: (state, action: PayloadAction<Region[]>) => {
      state.status = StoreStateStatus.RESOLVED;
      state.data = action.payload || [];
    },
    rejectRegions: (state, action: PayloadAction<Error>) => {
      state.status = StoreStateStatus.REJECTED;
      state.error = action.payload;
    },
  }
});

export const {
  loadingRegions,
  resolveRegions,
  rejectRegions,
} = regionsSlice.actions;
export default regionsSlice.reducer;

export const selectRegions = (state: AppState) => state.regions;
