import React from "react";

import "./styles.scss";

declare interface TagProps {
  type?: "primary" | "secondary";
  label: string;
}

const Tag: React.FC<TagProps> = ({ label, type = "primary" }) => {
  return <span className={`tag tag-${type}`}> {label} </span>;
};

export default Tag;
