import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";

import cx from "classnames";

import "../../styles.scss";
import OrgInfo from "./OrgInfo";
import AccountInfo from "./AccountInfo";
import AdminDetails from "./AdminDetails";
import { DefaultButton } from "../../../../../../components/LyveButton";
import { WhoAmI } from "../../../../../../state/UserInfo/types";
import api from "../../../../../../services/api";
import partner_api from "../../../../../../services/Partner_api";
import { isAllowedRole } from "../../../../../../utils";
import {
  AppState,
  useAppDispatch,
  useAppSelector
} from "../../../../../../store";
import { saveMasterAccountInfo } from "../../../../../../state/Subaccounts/actions";
import { SubAccountData } from "../../../../../../state/Subaccounts/types";
import { rules, SUBACCOUNTS_CREATE } from "../../../../../../authRules";
import { FormProvider } from "./FormContext";
import REGEX from "../../../../../../constants/regex";
import { ChevronCompactRight, ExclamationTriangleFill } from "react-bootstrap-icons";
import { RateReviewSharp } from "@material-ui/icons";
import DefaultAlert from "../../../../../../components/LyveAlert";

interface IOwnProps { }

export declare interface SubAccountForm {
  orgName: string;
  accName: string;
  accId: string;
  fName: string;
  lName: string;
  email: string;
  confirmEmail: string;
  streetAddr1: string;
  streetAddr2?: string;
  city: string;
  state: string;
  zipCode: string;
  number: string;
  country: string;
  serverErr: string;
}

const getSchema = (canHaveDiffOrg: boolean, canHaveSupport: boolean) => {
  let schema = {
    accName: yup
      .string()
      .trim()
      .required("Account Name is required")
      .test({
        name: "length3to64",
        test: (value?: string) =>
          !!value && value.length >= 3 && value.length <= 64
      })
      .matches(REGEX.alphaNumericDashSpecialCharacter)
      .test({
        name: "matchesAlphaNumericSpecialCharacter",
        test: (value?: string) =>
            !!value && REGEX.alphaNumericDashSpecialCharacter.test(value)
      }),
    accId: yup
      .string()
      .trim()
      .required("Account Id is required")
      .test({
        name: "length3to64",
        test: (value?: string) =>
          !!value && value.length >= 3 && value.length <= 64
      })
      .test({
        name: "matchesLowerAlphaNumericDash",
        test: (value?: string) =>
          !!value && value === value.toLowerCase() && REGEX.alphaNumericDash.test(value)
      })
      .test({
        name: "dash",
        test: (value?: string) =>
          !!value && !(value.startsWith("-") || value.endsWith("-"))
      }),
    fName: yup
      .string()
      .trim()
      .required("First Name is required")
      .max(60, "Should be less than or equal to 60 characters")
      .matches(
        REGEX.alphaNumericDashSpace,
        "Only alphabetical characters, numbers, “-“ and space allowed"
      ),
    lName: yup
      .string()
      .trim()
      .required("Last Name is required")
      .max(60, "Should be less than or equal to 60 characters")
      .matches(
        REGEX.alphaNumericDashSpace,
        "Only alphabetical characters, numbers, “-“ and space allowed"
      ),
    email: yup
      .string()
      .trim()
      .required("Email is required")
      .matches(
        REGEX.emailRegex,
        "Invalid email address"
        ),
    confirmEmail: yup
      .string()
      .required("Confirm Email is required")
      .oneOf([yup.ref("email")], "Email addresses don't match")
  };

  if (canHaveDiffOrg || canHaveSupport) {
    schema = Object.assign(schema, {
      orgName: yup
        .string()
        .trim()
        .required("Organization Name is required")
        .test({
          name: "length3to64",
          test: (value?: any) =>
            !!value && value.length >= 3 && value.length <= 64
        })
        .matches(REGEX.alphaNumericDashSpecialCharacter)
        .test({
          name: "matchesAlphaNumericSpecialCharacter",
          test: (value?: string) =>
            !!value && REGEX.alphaNumericDashSpecialCharacter.test(value)
        })
    });
  }

  if (canHaveSupport) {
    schema = Object.assign(schema, {
      streetAddr1: yup
        .string()
        .trim()
        .required("Street Address is required")
        .max(35, "Should be less than or equal to 35 characters"),
      streetAddr2: yup
        .string()
        .trim()
        .max(35, "Should be less than or equal to 35 characters"),
      city: yup
        .string()
        .trim()
        .required("City is required")
        .max(30, "Should be less than or equal to 30 characters")
        .matches(REGEX.alphaSpace, 'Only characters are allowed'),
      state: yup
        .string()
        .trim()
        .required("State is required")
        .max(30, "Should be less than or equal to 30 characters")
        .matches(REGEX.alphaSpace, 'Only characters are allowed'),
      country: yup
        .string()
        .trim()
        .required("Country is required")
        .max(30, "Should be less than or equal to 30 characters")
        .matches(REGEX.alphaSpace, 'Only characters are allowed'),
      zipCode: yup
        .string()
        .trim()
        .required("Zip Code is required")
        .matches(REGEX.zipCode, "Must be 5 to 7 digits"),
      number: yup
        .string()
        .trim()
        .matches(REGEX.number, "Must be a number")
        .min(7, "Should be at least 7 numbers")
        .max(15, "Should be less than or equal to 15 numbers")
    });
  }

  return yup.object().shape(schema, [["email", "confirmEmail"]]);
};

const defaultValues = {
  orgName: "",
  accName: "",
  accId: "",
  fName: "",
  lName: "",
  city: "",
  state: "",
  email: "",
  confirmEmail: "",
  country: "",
  streetAddr1: "",
  streetAddr2: "",
  zipCode: "",
  number: "",
  serverErr: ""
};

const Create: React.FC<IOwnProps> = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { masterAccount } = useAppSelector(
    (state: AppState) => state.subaccounts
  );
  const canHaveDiffOrg = !!masterAccount?.sub_account_diff_org;
  const canHaveSupport = !!masterAccount?.sub_account_support;
  const displayOrgInfo = canHaveDiffOrg || canHaveSupport;

  const [isAccIdUnique, setIsAccIdUnique] = React.useState(true);

  let { id } = useParams<any>();
  const [loading, setLoading] = React.useState(id ? true : false);
  const [loadingQueue, setLoadingQueue] = React.useState<number>(0);
  const [maxLimitWarning, setMaxLimitWarning] = React.useState<boolean>(false);
  const [retryRequestId, setRetryRequestId] = React.useState<string>("");

  let areSubAccEnabled = (account: SubAccountData) => {
    return !!account?.sub_account_max_limit;
  };

  const schema = getSchema(canHaveDiffOrg, canHaveSupport);

  const form = useForm<SubAccountForm>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: "onChange",
    criteriaMode: "all"
  });

  const { handleSubmit, formState, reset, setError, clearErrors } = form;

  const whoAmI = useSelector<AppState, WhoAmI>(state => state.userInfo.whoAmI);
  const role = `${whoAmI?.type}-${whoAmI?.role}`;

  React.useEffect(() => {
    if (formState.isValid) {
      clearErrors("serverErr");
    }
  }, [formState.isValid]);

  const goToSubAccountsListing = () => history.push("/customer/subaccounts");

  React.useEffect(() => {
    if (!isAllowedRole(rules, role, SUBACCOUNTS_CREATE)) {
      goToSubAccountsListing();
    }
    
    if (!masterAccount) {
      if (whoAmI?.company_short_name) {
        setLoadingQueue(prev => prev+1)
        api
          .getSubaccount(whoAmI?.company_short_name)
          .then(res => {
            if (res && areSubAccEnabled(res)) {
              reset({
                ...defaultValues,
                orgName: !canHaveDiffOrg ? res?.short_name : ""
              });
              dispatch(saveMasterAccountInfo(res));
            } else {
              goToSubAccountsListing();
            }
          })
          .catch(() => {
            goToSubAccountsListing();
          })
          .finally(() => setLoadingQueue(prev => prev-1));
      } else {
        goToSubAccountsListing();
      }
    } else if (!areSubAccEnabled(masterAccount)) {
      goToSubAccountsListing();
    } else {
      setLoadingQueue(prev => prev+1)
      api
        .getSubaccounts(masterAccount?.short_name || "")
        .then(res => {
          let totalAccount = res.total + res.work_requests.filter((wr:any) => wr.status == "Being Provisioned").length
          if( totalAccount >= masterAccount.sub_account_max_limit) {
            setMaxLimitWarning(true)
          }
        }).finally(() => setLoadingQueue(prev => prev-1))
      if (id) {
        setLoadingQueue(prev => prev+1)
        api.getRetrySubaccount(id).then(res => {
          if (res && res?.payload) {
            initForm(res?.payload?.sub_account)
            setRetryRequestId(res?.work_request_id)
          }
        }).finally(() => setLoadingQueue(prev => prev-1))
      } else {
        reset({
          ...defaultValues,
          orgName: !canHaveDiffOrg ? masterAccount?.short_name : ""
        });
      }
    }
  }, [whoAmI, masterAccount]);

  React.useEffect(() => {
    if(loadingQueue <= 0) {
      setLoading(false)
    }
    else {
      setLoading(true)
    }
  }, [loadingQueue])

  const initForm = (acc: any) => {
    reset({
      ...defaultValues,
      orgName: acc.org_name,
      accName: acc.name,
      accId: acc.short_name,
      fName: acc.admin_first_name,
      lName: acc.admin_last_name,
      city: acc.city,
      state: acc.state,
      email: acc.admin_email,
      confirmEmail: acc.admin_email,
      country: acc.country,
      streetAddr1: acc.address_line1,
      streetAddr2: acc.address_line2,
      zipCode: acc.zip_code,
      number: acc.phone_number,
    });
  }

  const submitInfo = async (data: any) => {
    try {
      if (!displayOrgInfo) {
        data.orgName = masterAccount?.short_name;
      }
      await api.createSubAccount(data, retryRequestId);
      goToSubAccountsListing();
    } catch (e) {
      setError("serverErr", {
        message:
          "Sub-account creation failed, please try again or contact Lyve Cloud Support for assistance."
      });
    }
  };

  const isFormDisabled =
    !formState.isValid || !isAccIdUnique || formState.isSubmitting || maxLimitWarning;

  const serverErr = formState.errors?.serverErr?.message;

  if (loading) {
    return (
      <div className="customer-subaccount-wrapper">
        <div className="heading-area">
          <h4 className={cx("lyve-page-main-title")}>Create Sub-account</h4>
        </div>
        <div className="data-loader">
          <Spinner animation="border" role="status" className="spinner">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  }

  const renderAlert = () => {
    if (maxLimitWarning) {
      return (
        <div className="alert-wrapper">
          <DefaultAlert variant="warning" customClasses={'d-flex'}>
            <ExclamationTriangleFill size={16}style={{color: "#F5AF18", marginRight: "10px"}}/>
            <span style={{ color: "#1a1a1a", verticalAlign: "middle"}}>You have reached the sub-account limit for your account. Please contact support to increase the sub-account limit.</span>
          </DefaultAlert>
        </div>
      )
    }
  }

  return (
    <div className="customer-subaccount-wrapper">
      <div className="lyve-breadcrumb">
        <DefaultButton
          onClick={() => history.push("/customer/subaccounts")}
          variant="link"
          className="text-btn"
        >
          Sub-accounts
        </DefaultButton>
        <ChevronCompactRight fill="var(--gray-10)" />
      </div>
      <FormProvider value={form}>
        <div className="heading-area">
          <h4 className={cx("lyve-page-main-title")}>Create Sub-account</h4>
        </div>
        {renderAlert()}
        <>
          {displayOrgInfo && <OrgInfo account={masterAccount} disabled={maxLimitWarning}/>}
          <AccountInfo
            isAccIdUnique={isAccIdUnique}
            setIsAccIdUnique={setIsAccIdUnique}
            disabled={maxLimitWarning}
          />
          <AdminDetails disabled={maxLimitWarning}/>
        </>
        {serverErr && <p className="validation-error">{serverErr}</p>}
        <div className="action-btns">
          <DefaultButton
            className="mr-2"
            disabled={isFormDisabled}
            onClick={handleSubmit(submitInfo)}
          >
            {formState.isSubmitting ? (
              <Spinner animation="border" role="status" size="sm" />
            ) : (
              "Create"
            )}
          </DefaultButton>
          <DefaultButton
            variant="outline-secondary"
            onClick={goToSubAccountsListing}
          >
            Cancel
          </DefaultButton>
        </div>
      </FormProvider>
    </div>
  );
};

export default Create;
