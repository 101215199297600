import React from "react";
import { SmartStartUserConfig } from "./types.d";

declare interface SmartStartProps {
  showSmartStartKit: boolean;
  toggleShowSmartStartKit: () => void;
  fromLocation: any;
  showTooltip: boolean;
  toggleTooltip: () => void;
  userConfig: SmartStartUserConfig;
  setUserConfig: (config: SmartStartUserConfig) => void;
  setShowSmartStartKit: (show: boolean) => void
}

// @ts-ignore
const SmartStartContext = React.createContext<SmartStartProps>(null);

const SmartStartProvider: React.FC = ({ children }) => {
  const fromLocation = React.useRef("");

  const [showTooltip, setShowTooltip] = React.useState(false);
  const toggleTooltip = () => setShowTooltip(!showTooltip);

  const [userConfig, setUserConfig] = React.useState<SmartStartUserConfig>({
    allBuckets: 0,
    ownedBuckets: 0,
    createdUsers: 0,
    closedCards: [],
    autoExpandOnLogin: false,
    movedToProfileDropdown: false
  });

  const [showSmartStartKit, setShowSmartStartKit] = React.useState(
    userConfig.autoExpandOnLogin
  );

  const toggleShowSmartStartKit = () =>
    setShowSmartStartKit(!showSmartStartKit);

  const value = {
    fromLocation,
    showSmartStartKit,
    toggleShowSmartStartKit,
    showTooltip,
    toggleTooltip,
    userConfig,
    setUserConfig,
    setShowSmartStartKit
  };

  return (
    <SmartStartContext.Provider value={value}>
      {children}
      </SmartStartContext.Provider>
  );
};

const useSmartStartKit = () => {
  const context = React.useContext(SmartStartContext);
  if (context === undefined) {
    throw new Error("useStartSmart must be used within a SmartStartProvider");
  }
  return context;
};

export default useSmartStartKit;
export { SmartStartProvider };
