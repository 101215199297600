import React from "react";
import { Moment } from "moment-timezone";

import Month from "./Month";

export interface MonthLayoutProps {
  year: number;
  selected?: Moment | null;
  min?: Moment;
  max?: Moment;
  startDate?: Moment;
  endDate?: Moment;
  selectsRange?: boolean;
  shortMonth?: boolean;
  yearsCount?: number;
  columnLayout?: number;
  onChange?: (index: number, e: React.MouseEvent) => void;
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "Novemeber",
  "December"
];

const isDisabled = (
  index: number,
  year: number,
  limits: { min?: Moment; max?: Moment }
) => {
  if (!limits.min && !limits.max) {
    return false;
  }

  let minimum = false;
  let maximum = false;

  if (limits.min) {
    const minYear = limits.min.get("year");
    const minMonthIndex = limits.min.get("month");
    minimum = year <= minYear && index < minMonthIndex;
  }

  if (limits.max) {
    const maxYear = limits.max.get("year");
    const maxMonthIndex = limits.max.get("month");
    maximum = year >= maxYear && index > maxMonthIndex;
  }

  return minimum || maximum;
};

const getMonthIndex = (date?: Moment) => date?.get("month") || 0;
const getYear = (date?: Moment) => date?.get("year") || null;

const MonthLayout = (props: MonthLayoutProps) => {
  return (
    <div
      className="lyve-month-picker__month-container"
      style={{
        gridTemplateColumns: `repeat(${props.columnLayout}, 1fr)`
      }}
    >
      {months.map((month, index) => {
        const disabled = isDisabled(index, props.year, {
          min: props.min,
          max: props.max
        });

        const selectionProps = props.selectsRange
          ? {
              startIndex: getMonthIndex(props.startDate),
              startIndexYear: getYear(props.startDate),
              endIndex: getMonthIndex(props.endDate),
              endIndexYear: getYear(props.endDate)
            }
          : {
              selected:
                !!props.selected &&
                props.selected.get("month") === index &&
                props.selected.get("year") === props.year
            };

        return (
          <Month
            key={month}
            shortMonth={props.shortMonth}
            month={month}
            year={props.year}
            index={index}
            disabled={disabled}
            selectsRange={props.selectsRange}
            onClick={(e: React.MouseEvent) => {
              if (!disabled) {
                props.onChange?.(index, e);
              }
            }}
            {...selectionProps}
          />
        );
      })}
    </div>
  );
};

export default MonthLayout;
