import React, { useState } from "react";

declare interface GlobalValues {
  showAccountDropdown: boolean;
  toggleShowAccountDropdown: () => void;
  processBuckets: boolean
  setProcessBuckets: (process: boolean) => void
  shouldCelebrate: boolean
  setShouldCelebrate: (shouldCelebrate: boolean) => void
}

// @ts-ignore
const GlobalContext = React.createContext<GlobalValues>();

const GlobalProvider: React.FC = ({ children }) => {
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);
  const toggleShowAccountDropdown = () => {
    setShowAccountDropdown(!showAccountDropdown);
  };

  const [processBuckets, setProcessBuckets] = React.useState(false);
  const [shouldCelebrate, setShouldCelebrate] = React.useState(false);

  const value = {
    showAccountDropdown,
    toggleShowAccountDropdown,
    processBuckets,
    setProcessBuckets,
    shouldCelebrate,
    setShouldCelebrate
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

const useGlobalContext = () => {
  const context = React.useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useGlobalContext must be used within a GlobalProvider");
  }
  return context;
};

export default useGlobalContext;
export { GlobalProvider };
