import {
  TOGGLE_TENANT_DISPLAY_MODAL,
  TENANT_DISPLAY_MODAL,
  TENANT_HIDE_MODAL,
  ToggleTenantDisplayModalActionTypes,
  TenantDisplayModalAction,
  TenantHideModalAction
} from "./types";

export function toggleDisplayModal(): ToggleTenantDisplayModalActionTypes {
  return {
    type: TOGGLE_TENANT_DISPLAY_MODAL
  };
}
export function displayModal(): TenantDisplayModalAction {
  return {
    type: TENANT_DISPLAY_MODAL
  };
}
export function hideModal(): TenantHideModalAction {
  return {
    type: TENANT_HIDE_MODAL
  };
}
