import React from "react";
import VideoCard from "../../components/SmartStartHereCards/VideoCard";
import UsefulResourcesCard from "../../components/SmartStartHereCards/UsefulResourcesCard";
import {
  CardAction,
  CardConfig,
  CardPayload,
  CardType,
  Roles,
  SmartStartUserConfig
} from "./types.d";
import SupportCard from "../../components/SmartStartHereCards/SupportCard";
import AddUserCard from "../../components/SmartStartHereCards/AddUserCard";
import AddBucketCard from "../../components/SmartStartHereCards/AddBucketCard/AddBucketCard";
import SThreeClientsCard from "../../components/SmartStartHereCards/SThreeClientsCard";
import api from "../../services/api";

export const startHereCards: CardConfig[] = [
  {
    id: 1,
    priority: 1,
    width: 1,
    type: CardType.VIDEO,
    dismissible: false,
    supportedRole: [Roles.ADMIN, Roles.STORAGE_ADMIN, Roles.AUDITOR],
    component: VideoCard
  },
  {
    id: 2,
    priority: 2,
    relativePriority: 1,
    width: 1,
    type: CardType.ADD_BUCKET,
    dismissible: false,
    supportedRole: [Roles.ADMIN, Roles.STORAGE_ADMIN],
    component: AddBucketCard
  },
  {
    id: 3,
    priority: 2,
    relativePriority: 2,
    width: 1,
    type: CardType.ADD_USER,
    dismissible: false,
    supportedRole: [Roles.ADMIN],
    component: AddUserCard
  },
  {
    id: 4,
    priority: 2,
    relativePriority: 3,
    width: 1,
    type: CardType.STHREE_CLIENTS,
    dismissible: false,
    supportedRole: [Roles.ADMIN, Roles.AUDITOR, Roles.STORAGE_ADMIN],
    component: SThreeClientsCard
  },
  {
    id: 5,
    priority: 3,
    relativePriority: 1,
    width: 1,
    type: CardType.RESOURCES,
    dismissible: false,
    supportedRole: [Roles.ADMIN, Roles.STORAGE_ADMIN, Roles.AUDITOR],
    component: UsefulResourcesCard
  },
  {
    id: 6,
    priority: 3,
    relativePriority: 2,
    width: 1,
    type: CardType.SUPPORT,
    dismissible: false,
    supportedRole: [Roles.ADMIN, Roles.STORAGE_ADMIN],
    component: SupportCard
  }
];

const sortCardsByPriority = (card1: CardConfig, card2: CardConfig) => {
  if (card1.priority > card2.priority) {
    return 1
  } else if (card1.priority < card2.priority) {
    return -1
  } else if (card1.relativePriority && card2.relativePriority) {
    if (card1.relativePriority >= card2.relativePriority) {
      return 1
    }
  }
  return -1
}

function cardsReducer(
  state: CardConfig[],
  { cards, type, cardID, cardType, cardNewPriority }: CardPayload
): CardConfig[] {
  switch (type) {
    case CardAction.SET: {
      if (cards) {
        return cards;
      }
      return state;
    }
    case CardAction.REMOVE: {
      const cards = [...state];
      const cardIdx = cards.findIndex(card => card.type === cardType);
      cards.splice(cardIdx, 1);
      return cards;
    }
    case CardAction.CHANGE_PRIORITY: {
      const cards = [...state];
      const cardIdx = cards.findIndex(card => card.id === cardID);
      if (typeof cardNewPriority !== "undefined") {
        cards[cardIdx].priority = cardNewPriority;
      }
      return cards.sort(sortCardsByPriority);
    }
    default: {
      throw new Error(`Unsupported type: ${type}`);
    }
  }
}

const useSmartStartCards = (
  userConfig: SmartStartUserConfig,
  userRole: Roles
) => {
  const filterRoles = (card: CardConfig) =>
    card.supportedRole.includes(userRole);

  const filterOpenedCards = (card: CardConfig) =>
    !userConfig.closedCards.includes(card.type);

  const modifyCardByUser = (card: CardConfig) => {
    switch (card.type) {
      case CardType.ADD_BUCKET: {
        return userConfig["ownedBuckets"] <= 0;
      }
      default: {
        return true;
      }
    }
  };

  const generateCards = () =>
    startHereCards
      .filter(filterRoles)
      .filter(filterOpenedCards)
      .filter(modifyCardByUser)
      .sort(sortCardsByPriority);

  const [smartStartCards, cardsDispatch] = React.useReducer(
    cardsReducer,
    null,
    generateCards
  );

  const addCards = (cards: CardConfig[]) =>
    cardsDispatch({ type: CardAction.SET, cards });

  const removeCard = (cardType: CardType) => {
    // cardsDispatch({ type: CardAction.REMOVE, cardType });
    api.updateSmartStartCard({
      cardName: cardType,
      status: "closed"
    });
  };

  const changeCardPriority = (cardID: number, cardNewPriority: number) =>
    cardsDispatch({
      type: CardAction.CHANGE_PRIORITY,
      cardID,
      cardNewPriority
    });

  React.useEffect(() => {
    addCards(generateCards())
  }, [userConfig, userRole])

  return { smartStartCards, removeCard };
};

export default useSmartStartCards;
