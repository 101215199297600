import React, { useState, useEffect } from "react";
import { Search } from "react-bootstrap-icons";
import Card from "react-bootstrap/Card";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { BUCKETS_EDIT } from "../../../../../../../../authRules";
import Can from "../../../../../../../../components/Can";
import { DefaultButton } from "../../../../../../../../components/LyveButton";
import LyveHeadingLink from "../../../../../../../../components/LyveHeadingLink";
import {
  getLink,
  linkNames
} from "../../../../../../../../components/LyveHeadingLink/LinkUtil";
import LyveTable from "../../../../../../../../components/LyveTable";
import {
  BucketReplicationRule,
  ITag,
  ListBucketResponse
} from "../../../../types";
import orderBy from "lodash/orderBy";
import "./styles.scss";
import { X as ResetIcon } from "react-bootstrap-icons";
import { LyveBreadCrumb } from "../../../../../../../../components/LyveBreadcrumb";
import regionApis from "../../../../../../../../services/RegionApis";
import Spinner from "react-bootstrap/Spinner";
import { AppState } from "../../../../../../../../store";
import { WhoAmI } from "../../../../../../../../state/UserInfo/types";
import { useSelector } from "react-redux";
import api from "../../../../../../../../services/api";
import { AllRegions } from "../../../..";

interface PriorityProps {
  bucketName: string;
  replicationRules: BucketReplicationRule[];
  isEditable: boolean;
  regionUrl?: string;
  onSuccess?: (data: any) => void;
  onError?: (err: any) => void;
}

interface IPriorityDetails {
  replication_id: string;
  source_bucket: string;
  target_bucket: string;
  priority: number;
  name: string;
  prefix: string;
  tags: ITag[];
  limited_scope: boolean;
  numberInputErr: string;
}

export interface IUpdatePriorityProps {
  rule: string;
  priority: number;
}

const ChangePriority: React.FC<PriorityProps> = ({
  onSuccess = () => null
}) => {
  const state = useLocation().state as PriorityProps;
  const history = useHistory();
  const { bucketName } = useParams<{ bucketName: string }>();
  const [regionBaseUrl, setRegionBaseUrl] = React.useState<string>("");
  const whoAmI = useSelector<AppState, WhoAmI>(state => state.userInfo.whoAmI);
  const [isEditable, setIsEditable] = React.useState(false);
  const [initReplicationRules, setInitReplicationRules] = React.useState<
    IPriorityDetails[]
  >([]);
  const [replicationRules, setReplicationRules] = React.useState<
    IPriorityDetails[]
  >([]);
  const [filterText, setFilterText] = React.useState("");
  const [filterMatchText, setFilterMatchText] = React.useState("");
  const [error, setError] = React.useState<string>("");
  const [validateError, setValidateError] = React.useState<string>("");
  const [formProcessing, setFormProcessing] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [gotPriorityDetails, setGotPriorityDetails] = useState(false);

  const getBreadcrumbs = () => {
    let breadcrumbs: any = [
      {
        title: "Buckets",
        onClick: () => history.push(`/customer/buckets`)
      },
      {
        title: bucketName,
        onClick: () =>
          history.push({
            pathname: `/customer/bucket/${bucketName}`,
            state: {
              activeTabKey: "settings"
            }
          })
      },
      {
        title: "Replication",
        onClick: () =>
          history.push({
            pathname: `/customer/bucket/${bucketName}`,
            state: {
              activeTabKey: "replication"
            }
          })
      }
    ];

    return breadcrumbs;
  };

  const getRegionUrlAndReplicationRule = async () => {
    try {
      const bucketList: ListBucketResponse = await getBucketList();
      const viewBucketRegion =
        bucketList.buckets.find(bucket => bucket.name === bucketName)?.region ||
        "";
      const resp: AllRegions = await getBucketRegion();
      const regionUrlList = resp.regions.map(regionObj =>
        regionObj.name === viewBucketRegion ? regionObj.url : ""
      );
      const regionUrl = regionUrlList.find(url => url !== "") || "";
      setRegionBaseUrl(regionUrl);
      getReplicationRulesList(regionUrl);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const getBucketRegion = async () => {
    return api.getAllRegions();
  };

  const getBucketList = async () => {
    return api.listBuckets();
  };

  const getReplicationRulesList = async (region: string) => {
    try {
      const allReplicationRules = await regionApis.getReplicationRulesListV2(
        region || "",
        bucketName
      );
      const ruleList: BucketReplicationRule[] = allReplicationRules.rules;

      if (ruleList.length > 0) {
        const filteredList = constructReplicationPriorityList(ruleList);

        if (filteredList.length > 0) {
          setInitReplicationRules(filteredList);
          setReplicationRules(filteredList);
          if (whoAmI.role?.toLowerCase().includes("admin")) {
            setIsEditable(true);
          }
        } else {
          setIsEditable(false);
        }
      }
      setIsLoading(false);
      setGotPriorityDetails(true);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const constructReplicationPriorityList = (list: BucketReplicationRule[]) => {
    let priorityList: IPriorityDetails[] = [];
    priorityList = list
      .filter(obj => obj.source_bucket_name === bucketName)
      .map(obj => {
        return {
          replication_id: obj.replication_id,
          name: obj.name,
          source_bucket: obj.source_bucket_name,
          target_bucket: obj.target_bucket_name,
          priority: obj.priority,
          limited_scope: obj.limited_scope,
          prefix: obj.prefix,
          tags: obj.tags,
          numberInputErr: ""
        };
      });
    return priorityList;
  };

  const onFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterMatchText("");
    const newValue = e.target.value;
    setFilterText(newValue);
    const filteredItem = initReplicationRules.filter(
      item =>
        item.name && item.name.toLowerCase().includes(newValue.toLowerCase())
    );
    if (newValue && filteredItem.length === 1) {
      setFilterMatchText("1 match");
    }
    if (newValue && filteredItem.length > 1) {
      setFilterMatchText(filteredItem.length + " matches");
    }
    setReplicationRules(filteredItem);
  };

  const resetFilter = () => {
    setFilterMatchText("");
    setFilterText("");
    if (initReplicationRules?.length) {
      setReplicationRules(initReplicationRules);
    } else {
      setReplicationRules([]);
    }
  };

  const savePriority = () => {
    setError("");
    setValidateError("");
    if (!isDisabled) {
      setFormProcessing(true);
      setIsDisabled(true);
      const targetBucket = replicationRules[0].target_bucket;
      let priorityList: IUpdatePriorityProps[] = [];
      replicationRules.map(obj => {
        priorityList.push({
          rule: obj.replication_id,
          priority: obj.priority
        });
      });

      // check duplicate
      const uniqueValues = new Set(priorityList.map(obj => obj.priority));

      if (uniqueValues.size < priorityList.length) {
        setValidateError("Duplicate priority found.");
        setFormProcessing(false);
        return;
      }

      regionApis
        .changeReplicationPriority(
          regionBaseUrl || "",
          bucketName,
          targetBucket,
          priorityList
        )
        .then(data => {
          setFormProcessing(false);
          setIsDisabled(false);
          onSuccess(data);
          history.push({
            pathname: `/customer/bucket/${bucketName}`,
            state: {
              activeTabKey: "replication"
            }
          });
        })
        .catch(err => {
          setError("Error while updating priority");
          setFormProcessing(false);
        });
    }
  };

  const validateNumber = (e: any, row: IPriorityDetails) => {
    setValidateError("");
    setError("");
    const newValue = e.target.value;
    var re = /^[1-9]\d*$/g;
    row.priority = +newValue;

    if (newValue !== "" && !re.test(newValue)) {
      row.numberInputErr = "Only whole positive numbers allowed.";
      setIsDisabled(true);
    } else if (
      replicationRules.filter(item => item.priority === +newValue).length > 1
    ) {
      row.numberInputErr = "The number is already assigned to another rule.";
      setIsDisabled(true);
    } else {
      row.numberInputErr = "";
      setIsDisabled(false);
    }

    setReplicationRules(prevState => {
      const newState = prevState.map(obj => {
        if (
          replicationRules.filter(item => item.priority === obj.priority)
            .length == 1 &&
          obj.priority > 0
        ) {
          obj.numberInputErr = "";
        }
        if (obj.replication_id === row.replication_id) {
          return { ...obj, priority: row.priority };
        }
        return obj;
      });
      return newState;
    });
  };

  /** Datatable */
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All"
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: "70px",
        padding: "16px 0 16px 0"
      }
    }
  };

  const columns = [
    {
      name: "Replication rule name",
      selector: (row: IPriorityDetails) => row.name,
      sortable: true,
      minWidth: "100px",
      maxWidth: "300px",
      cell: (row: IPriorityDetails) => <span title={row.name}>{row.name}</span>
    },
    {
      name: "Scope",
      selector: (row: IPriorityDetails) =>
        row.limited_scope + row.prefix + "|" + JSON.stringify(row.tags),
      sortable: true,
      minWidth: "300px",
      maxWidth: "600px",
      cell: (row: BucketReplicationRule) => {
        let scope = "All objects in the bucket";
        if (row.limited_scope) {
          if (row.prefix.length > 0) {
            scope = "Prefix: " + row.prefix;
          }
          if (row.tags.length > 0) {
            if (row.prefix.length > 0) {
              scope = scope + ", Tags: " + JSON.stringify(row.tags);
            } else {
              scope = "Tags: " + JSON.stringify(row.tags);
            }
          }
        }
        var re = /({|}|")/g;
        scope = scope.replaceAll(re, "");

        return (
          <span title={scope} className="replication-wrap-text">
            {scope}
          </span>
        );
      }
    },
    {
      name: "Priority",
      selector: (row: IPriorityDetails) => row.priority,
      minWidth: "300px",
      minHeight: "78px",
      sortable: true,
      cell: (row: IPriorityDetails) => {
        if (isEditable) {
          return (
            <div>
              <input
                className={
                  "priority-input " +
                  (row.numberInputErr !== "" ? "has-error" : "")
                }
                type="number"
                id={row.replication_id}
                key={row.replication_id}
                defaultValue={row.priority}
                onBlur={e => {
                  validateNumber(e, row);
                }}
                onKeyPress={e => {
                  if (e.nativeEvent.key === "Enter") {
                    validateNumber(e.nativeEvent, row);
                  }
                }}
              ></input>
              <span className="priority-validation-error">
                {row.numberInputErr}
              </span>
            </div>
          );
        } else {
          return <span>{row.priority}</span>;
        }
      }
    }
  ];

  useEffect(() => {
    if (state !== undefined) {
      const regionUrl = state.regionUrl ? state.regionUrl : "";
      setRegionBaseUrl(regionUrl);
      getReplicationRulesList(regionUrl);
    } else {
      getRegionUrlAndReplicationRule();
    }
  }, []);

  return (
    <div className="container-fluid IAM-support-page-bodyContent">
      <div className="row no-gutter">
        {isLoading ? (
          <div className="data-loader-content">
            <div className="data-loader">
              <Spinner animation="border" role="status" className="spinner">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          </div>
        ) : (
          <div className="col">
            <div>
              <LyveBreadCrumb breadcrumbs={getBreadcrumbs()} />
            </div>
            <div className="change-priority-container">
              <div className="change-priority-title">
                <div className="change-priority-name">Change Priority</div>
              </div>
            </div>
            {gotPriorityDetails ? (
              <div>
                <div>
                  <Can perform={BUCKETS_EDIT}>
                    <Card className="change-priority-main">
                      <Card.Body>
                        <div className="change-priority-main">
                          <LyveHeadingLink
                            title="Manage the priority of replication rules"
                            subTitle="Priority must be a unique value higher than 0. The higher the number the higher its priority."
                            helpLink={getLink(linkNames.Add_Replication_Rule)}
                          ></LyveHeadingLink>
                          <div className="priority-search-filter-style">
                            <div className="search-filter-div">
                              <input
                                className="search-filter-input"
                                type="text"
                                placeholder="Search replication rule by name"
                                value={filterText}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => onFilter(e)}
                              />
                              {filterText ? (
                                <span className="filter-icon">
                                  <ResetIcon size="24" onClick={resetFilter} />
                                </span>
                              ) : (
                                <span className="filter-icon">
                                  <Search />
                                </span>
                              )}
                            </div>
                            <span className="filter-match-text">
                              {filterMatchText}
                            </span>
                          </div>
                          <div className="change-priority-table">
                            <LyveTable
                              columns={columns}
                              data={replicationRules}
                              defaultSortAsc={false}
                              selectableRows={false}
                              pagination={true}
                              noHeader={true}
                              paginationPerPage={10}
                              paginationRowsPerPageOptions={[10, 25, 50]}
                              highlightOnHover={true}
                              paginationComponentOptions={paginationOptions}
                              persistTableHead={true}
                              noDataComponent={
                                <div className="priority-no-data-div">
                                  {filterText ? (
                                    <span>No matches </span>
                                  ) : (
                                    <span>
                                      You do not have any replication rules
                                      priority.
                                    </span>
                                  )}
                                </div>
                              }
                              paginationIconFirstPage={false}
                              paginationIconLastPage={false}
                              responsive={true}
                              customStyles={customStyles}
                            />
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Can>
                </div>
                {error !== "" && <p className="priority-error-msg">{error}</p>}
                {validateError !== "" && initReplicationRules.length > 10 && (
                  <p className="priority-error-msg">{validateError}</p>
                )}
                <div className="change-priority-button-div">
                  <Can perform={BUCKETS_EDIT}>
                    {isEditable && (
                      <DefaultButton
                        variant="primary"
                        onClick={() => savePriority()}
                        className="replication-btn-margin"
                        disabled={isDisabled}
                      >
                        {formProcessing ? (
                          <Spinner
                            animation="border"
                            role="status"
                            className="priority-spinner"
                          >
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        ) : (
                          "Save"
                        )}
                      </DefaultButton>
                    )}
                    <DefaultButton
                      variant="outline-secondary"
                      onClick={() =>
                        history.push({
                          pathname: `/customer/bucket/${bucketName}`,
                          state: {
                            activeTabKey: "replication"
                          }
                        })
                      }
                      className="replication-btn-margin"
                    >
                      Cancel
                    </DefaultButton>
                  </Can>
                </div>
              </div>
            ) : (
              <div className="view-bucket-name">
                No replication rule priority found.
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChangePriority;
