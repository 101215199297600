import React from "react";
import cx from "classnames";
import { ExclamationTriangle, X } from "react-bootstrap-icons";
import { DefaultButton } from "../../LyveButton";
import LyveTooltip from "../../LyveTooltip";
import PrepareBucket from "./PrepareBucket";
import CreateBucket from "./CreateBucket";
import CelebrateBucketCreation from "./CelebrateBucketCreation";
import useAddBucketCard from "./AddBucketContext";
import { ModalContainer } from "../../Modal";
import { customAlphabet } from "nanoid";
import useSmartStartKit from "../../../scenes/SmartStartHere/useSmartStartKit";
import { useAppDispatch } from "../../../store";
import useGlobalContext from "../../../state/GlobalStateContext";
import { Region } from "../../../state/regions";
import api from "../../../services/api";

declare type AddBucketModalOverlayProps = {
  setModalBackdrop: (backdrop: boolean) => void;
  toggleCreateBucketModal: (rollback?: boolean) => void;
  accountName: string;
  setRollback: (rollback: boolean) => void;
  runId: string;
  setRunId: () => void;
};

const AddBucketModalOverlay: React.FC<AddBucketModalOverlayProps> = props => {
  const {
    newBucket,
    setNewBucket,
    activeStep,
    setActiveStep,
    isFinalStepInProgress,
    setIsFinalStepInProgress,
  } = useAddBucketCard();
  const { setRollback } = props;

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const { setProcessBuckets } = useGlobalContext();

  //const nanoId = customAlphabet("1234567890", 5);
  //const runId = React.useMemo(() => nanoId(), []);
  const runId = props.runId;
  const [isRetry, setIsRetry] = React.useState<boolean>(false);

  const { showModal } = ModalContainer.useContainer();

  const removeCreateBucketModalBackdrop = () => props.setModalBackdrop(false);

  const setBucketDetails = (name: string, region: Region) =>
    setNewBucket({ ...newBucket, name, region, phase: "creation" });

  const retryBucketCreation = () => {
    //props.setRunId();
    setIsRetry(true);
    setNewBucket({
      ...newBucket,
      phase: "creation",
      error: null,
      region: newBucket.region,
      info: [],
      downloaded: false
    });
    setActiveStep({ number: 0, state: "wip" });
  };

  // const resetStates = () => {
  //   resetResources();
  //   setRollback(false);
  //   setProcessBuckets(false);
  // };

  // const deleteBucket = async (bucketName: string) => {
  //   const data = await api.canDeleteBucket(bucketName);
  //   if (!!data.status) {
  //     const res = await api.deleteBucket(bucketName);
  //     if ("name" in res) {
  //       api.getSmartStartConfig().then(setUserConfig);
  //       dispatch(removeBucket(bucketName));
  //     }
  //     resetStates();
  //   }
  // };

  const rollbackBucketCreation = () => {
    props.toggleCreateBucketModal(true);
    //setProcessBuckets(true);
  };

  const keepCreateBucketModal = (
    closeModal: (e: React.MouseEvent<Element, MouseEvent>) => void
  ) => (e: React.MouseEvent<Element, MouseEvent>) => {
    closeModal(e);
    removeCreateBucketModalBackdrop();
  };

  const exitCreateBucketModal = (
    closeModal: (e: React.MouseEvent<Element, MouseEvent>) => void
  ) => (e: React.MouseEvent<Element, MouseEvent>) => {
    closeModal(e);
    removeCreateBucketModalBackdrop();
    rollbackBucketCreation();
    //props.setRunId();
  };

  const addExitCreateBucketModal = () => {
    if (
      newBucket.phase === "preparation" ||
      newBucket.phase === "celebration"
    ) {
      if(newBucket.phase === "celebration"){
        props.setRunId()
      }
      props.toggleCreateBucketModal();
    } else {
      props.setModalBackdrop(true);
      showModal({
        header: true,
        headerProps: { onHide: removeCreateBucketModalBackdrop },
        title: "Exit the process",
        body: closeModal => (
          <>
            <div className="confirmation">
              Are you sure you want to exit the bucket creation process in the
              middle?
            </div>
            <div>
              <DefaultButton
                variant="danger"
                className="confirm-btn"
                onClick={exitCreateBucketModal(closeModal)}
              >
                Yes
              </DefaultButton>
              <DefaultButton
                variant="outline-secondary"
                onClick={keepCreateBucketModal(closeModal)}
              >
                No
              </DefaultButton>
            </div>
          </>
        ),
        baseProps: { dialogClassName: "exit-modal lyve-modal modal-sm" }
      });
    }
  };

  if (newBucket.error && newBucket.phase === "error") {
    return (
      <div className="bucket-error">
        <ExclamationTriangle size={40} color="var(--red-3)" />
        <div className="msg-err">
          Bucket creation process did not complete successfully.
        </div>
        <div className="msg">
          Please click Retry below to try again, or Close to go back to the
          menu.
        </div>
        <div>
          <DefaultButton
            variant="dark"
            onClick={retryBucketCreation}
            className="mr-4"
          >
            Retry
          </DefaultButton>
          <DefaultButton
            variant="outline-light"
            onClick={rollbackBucketCreation}
          >
            Close
          </DefaultButton>
        </div>
      </div>
    );
  }

  return (
    <div className="overlay">
      <div className="d-flex align-items-center justify-content-between title">
        <div>
          {newBucket.phase === "celebration"
            ? "Congrats! Your first bucket was successfully created"
            : "Create your first bucket"}
        </div>
        {(activeStep.state === "done" ||
          (newBucket.phase === "preparation" && !isSubmitting)) && (
          <LyveTooltip
            text="Please make sure to download the service account details before exiting."
            id="close-add-bucket-modal"
            enabled={newBucket.phase === "celebration" && !newBucket.downloaded}
            variant="secondary"
            placement="top"
          >
            <button
              onClick={addExitCreateBucketModal}
              className={cx(
                "overlay-btn",
                newBucket.phase === "celebration" &&
                  !newBucket.downloaded &&
                  "overlay-btn-disabled"
              )}
              hidden={isFinalStepInProgress ? true : false}
              disabled={
                newBucket.phase === "celebration" && !newBucket.downloaded
              }
            >
              <X size="24" />
            </button>
          </LyveTooltip>
        )}
      </div>
      {newBucket.phase === "preparation" ? (
        <PrepareBucket
          handleBucketName={setBucketDetails}
          accountName={props.accountName}
          setIsSubmitting={setIsSubmitting}
          runId={runId}
        />
      ) : newBucket.phase === "creation" ? (
        <CreateBucket
          accountName={props.accountName}
          runId={runId}
          setRollback={setRollback}
          isRetry={isRetry}
        />
      ) : (
        newBucket.phase !== "error" && (
          <CelebrateBucketCreation
            infoPairs={newBucket.info}
            handleBucketCreationCelebration={props.toggleCreateBucketModal}
          />
        )
      )}
    </div>
  );
};

export default AddBucketModalOverlay;
