import React from "react";
import cx from "classnames";
import ReactPlayer, { ReactPlayerProps } from "react-player";

interface IOwnProps extends ReactPlayerProps {}

/**
 * A custom video player. For more information visit [ReactPlayer](https://github.com/CookPete/react-player)
 * @returns {*} React Component
 */
const VideoPlayer = (props: IOwnProps) => (
  <ReactPlayer {...props} className={cx("react-player", props.className)} />
);

export default VideoPlayer;
