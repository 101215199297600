import { useState, useEffect, useCallback } from "react";
import api from "../../../../../services/api";
import { useAppDispatch } from "../../../../../store";
import { setLyveCloudDetails } from "../../../../../state/lyveCloud";

interface IPlanDetails {
  price: number;
  product_id: string;
  product_name: string;
  usage_limit: string;
  uom: string;
  product_price: number;
  unit_price: number;
  friendly_name: string;
}

interface IGetCustomerDetailsResponse {
  current_month_cost: string;
  estimated_cost: string;
  is_terminated: boolean;
  trial_end: string;
  plan_details: IPlanDetails;
  payment_failed: boolean;
  estimate_cost_card: boolean;
}

const useFixedCustomerDeatils = (customerType: string | undefined, userRole: string) => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>("");

  const getFixedCustomerDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const res: IGetCustomerDetailsResponse = await api.getFixedCustomerDetails();
      const customerDetails = {
        cancelled: res.is_terminated ? true : false,
        currentPlan: {
          id: res.plan_details.product_id,
          product_name: res?.plan_details?.product_name ?? "",
          allowedUsage: Number(res.plan_details.usage_limit),
          uom: res?.plan_details?.uom ?? "TB",
          friendly_name: res?.plan_details?.friendly_name ?? "",
          product_price: res?.plan_details?.product_price ?? "",
          unit_price: res?.plan_details?.unit_price ?? 1,
          costUnit: '$'
        },
        estimatedCost: res.estimated_cost ? Number(Number(res.estimated_cost).toFixed(2)): 0,
        trialEnd: res?.trial_end ?? '',
        payment_failed: res.payment_failed ? true : false,
        estimate_cost_card: res?.estimate_cost_card ?? false
      }

      dispatch(setLyveCloudDetails(customerDetails))
      setIsLoading(false);
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (
      !!userRole &&
      userRole !== "storage-admin" &&
      !!customerType &&
      customerType === "FIXED_PRICE"
    ) {
      getFixedCustomerDetails();
    }
  }, [customerType, userRole]);

  return {
    isLoading,
    error
  };
};

export default useFixedCustomerDeatils;
