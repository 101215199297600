import * as React from "react";
import { ReducerAction } from "react";

declare type Status = "idle" | "pending" | "resolved" | "rejected";

declare interface State<D> {
  status?: Status;
  data: D;
  error?: Error | null;
}

declare interface Action<D> {
  type: Status;
  data?: D;
  error: Error | null;
}

type Reducer<D> = React.Reducer<Required<State<D>>, Action<D>>;

function useSafeDispatch<D>(
  dispatch: React.Dispatch<ReducerAction<Reducer<D>>>
) {
  const mounted = React.useRef(false);

  React.useLayoutEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return React.useCallback(
    args => (mounted.current ? dispatch(args) : void 0),
    [dispatch]
  );
}

function asyncReducer<D>(
  state: State<D>,
  action: Action<D>
): Required<State<D>> {
  switch (action.type) {
    case "pending": {
      return { ...state, status: "pending", error: null };
    }
    case "resolved": {
      if (action.data) {
        return { status: "resolved", data: action.data, error: null };
      }
      return { ...state, status: "resolved", error: null };
    }
    case "rejected": {
      return { ...state, status: "rejected", error: action.error };
    }
    case "idle": {
      return { ...state, error: null, status: "idle" };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function useAsync<D>(initialData: D) {
  const [state, unsafeDispatch] = React.useReducer<Reducer<D>>(asyncReducer, {
    status: "idle",
    error: null,
    data: initialData
  });

  const dispatch = useSafeDispatch(unsafeDispatch);

  const { data, error, status } = state;

  const statusRef = React.useRef(status);

  React.useEffect(() => {
    statusRef.current = status;
  }, [status]);

  const run = React.useCallback(
    (promise, onSuccess?, onError?) => {
      dispatch({ type: "pending" });
      promise
        .then((data: D) => {
          if (statusRef.current !== "idle") {
            dispatch({ type: "resolved", data });
            onSuccess?.(data);
          }
        })
        .catch((error: Error) => {
          if (statusRef.current !== "idle") {
            dispatch({ type: "rejected", error });
            onError?.(error);
          }
        });
    },
    [dispatch]
  );

  const setData = React.useCallback(
    data => dispatch({ type: "resolved", data }),
    [dispatch]
  );
  const setError = React.useCallback(
    error => dispatch({ type: "rejected", error }),
    [dispatch]
  );
  const reset = React.useCallback(() => dispatch({ type: "idle" }), [dispatch]);

  return {
    setData,
    setError,
    reset,
    error,
    status,
    data,
    run
  };
}

export default useAsync;
