import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import * as notificationActions from "../../Notification/actions";
import { AppState } from "../../../store";
import { compose } from "recompose";
import { connect, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import ViewMFA from "./ViewMFA";
import { UPDATE_MFA_PREFERENCE } from "../../../state/IAM/actions";
import { IAMInfo } from "../../../state/IAM/types";

interface Props {
  setNotification?: any;
}

const MFA: React.FC<Props> = ({ setNotification }) => {
  // const [processing, setProcessing] = useState<boolean>(false);
  // const [confirmChange, setConfirmChange] = useState<boolean>(false);
  const IAMInfo = useSelector<AppState, IAMInfo>(
    (state) => state.IAMInfo.IAMInfo
  );
  const dispatch = useDispatch();

  useEffect(() => {
    api.getMFAPreference().then((mfa) => {
      dispatch({
        type: UPDATE_MFA_PREFERENCE,
        payload: mfa.mfa_preference,
      });
    });
  }, []);

  // const updateMFAPreference = (newPreference: string) => {
  //   setProcessing(true);
  //   api
  //     .updateMFAPreference(newPreference)
  //     .then(() => {
  //       api.getMFAPreference().then((mfa) => {
  //         dispatch({
  //           type: UPDATE_MFA_PREFERENCE,
  //           payload: mfa.mfa_preference,
  //         });
  //         setNotification(`MFA preference has been changed successfully.`);
  //         setProcessing(false);
  //         setConfirmChange(false);
  //       });
  //     })
  //     .catch((err) => {
  //       setProcessing(false);
  //       setConfirmChange(false);
  //     }); //todo: handle errors
  // };

  const viewProps = {
    // updateMFA: updateMFAPreference,
    // processing: processing,
    // currentMFA: IAMInfo.mfa_preference,
    // confirmChange: confirmChange,
    // setConfirmChange: setConfirmChange,
  };

  //return <ViewMFA {...viewProps} />;
   return <ViewMFA />;
};

// export default MFA;
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setNotification: (message: string) =>
    dispatch(notificationActions.setNotification(message)),
});

export default compose(connect(null, mapDispatchToProps))(MFA);
