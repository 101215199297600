import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import LyveTable from "../../../../../../components/LyveTable";
import { PermissionData } from "../../types";

interface PermissionsTableProps {
  initialValue?: PermissionData[];
  items: PermissionData[];
  setItems: (items: any) => void;
  readOnly?: boolean;
  allBucketFullAccess: boolean;
  loading: boolean;
}

const CustomCheckbox = React.forwardRef(({ onClick, ...rest }: any, ref) => (
  <Form.Check>
    <Form.Check.Input type="checkbox" ref={ref} {...rest} />
    <label className="custom-check checkbox" onClick={onClick} />
  </Form.Check>
));

const PermissionsTable: React.FC<PermissionsTableProps> = ({
  initialValue = [],
  items = [],
  readOnly,
  setItems,
  allBucketFullAccess,
  loading
}) => {
  const [idList, setIdList] = useState<string[]>([]);

  const columns = [
    {
      name: "Creation Date",
      selector: "creation_date",
      sortable: true,
      omit: true
    },
    {
      name: "Permission Name",
      selector: "name",
      sortable: true
    },
    {
      name: "Description",
      selector: "description",
      sortable: true
    }
  ];

  React.useEffect(() => {
    const newidList = initialValue?.map(x => x.id);
    setIdList(newidList);
  }, [initialValue]);

  const getRowSelection = React.useMemo(
    () => (row: any) => {
      return idList?.includes(row.id) || false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idList?.length]
  );

  const handleSelectRow = ({ selectedRows }: any) => {
    setItems(selectedRows);
  };

  return (
    <>
      {loading ? (
        <div className="data-loader">
          <Spinner animation="border" role="status" className="spinner">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <LyveTable
          columns={columns}
          data={allBucketFullAccess ? [] : items}
          defaultSortField="creation_date"
          defaultSortAsc={false}
          selectableRows={true}
          selectableRowSelected={getRowSelection}
          onSelectedRowsChange={handleSelectRow}
          selectableRowDisabled={() => allBucketFullAccess}
          selectableRowsComponent={CustomCheckbox}
          noHeader={true}
          highlightOnHover={true}
          noDataComponent={<div />}
          responsive={true}
          disabled={readOnly}
        />
      )}
    </>
  );
};

export default React.memo(PermissionsTable);
