import React from "react";
import { Router, Switch, Route, Redirect, useLocation } from "react-router-dom";
import history from "./history";
import api from "./services/api/index";
import ResetPassword from "./scenes/ResetPassword";
import ForgetPassword from "./scenes/ForgetPassword";
import MutipleUsersError from "./scenes/MutipleUsersError";

// import Login from "./scenes/Login";
// import LoginAnimated from "./scenes/LoginAnimated";
import NoMatchError from "./scenes/Errors/NoMatch";
import UniformError from "./scenes/Errors/UniformError";
import common, { checkBreakGlassUser } from "./services/common";
// import PartnerLogin from "./PartnerPortal/Login";
import ActivateAccount from "./scenes/ActivateAccount";
import PartnerLandingView from "./PartnerPortal/LandingView";
import PartnerMainLayout from "./PartnerPortal/Layout";
import PartnerMetric from "./PartnerPortal/Dashboard/components/Metric";
import Tenants from "./PartnerPortal/Dashboard/components/Tenant";
import Support from "./PartnerPortal/Dashboard/components/Support";
import MainLayout from "./scenes/Layout";
import Metrics from "./scenes/Dashboard/components/Metrics";

import {
  CustomerMFA,
  CustomerNotificationRecipients,
  CustomerSAMLFederation,
  Users
} from "./scenes/Dashboard/components/IAM";
import ServiceAccounts from "./scenes/Dashboard/components/ServiceAccounts";
import Permissions from "./scenes/Dashboard/components/Permissions";
import Buckets from "./scenes/Dashboard/components/Buckets";
import SupportTicketDetailsUpdate from "./PartnerPortal/Dashboard/components/Support/components/SupportTicketDetailsUpdate";
// import Dashboard from "./scenes/Dashboard/components/Metrics";
import SupportDashboard from "./PartnerPortal/Dashboard/components/Support/components/SupportDashboard";
import ExpiredToken from "./scenes/ExpiredToken";
// import { withLDProvider } from 'launchdarkly-react-client-sdk';
// import { useFlags } from 'launchdarkly-react-client-sdk';
import idbHelper from "./PartnerPortal/Dashboard/components/Support/Helpers/IDBHelper";
import { WindowObj } from "./PartnerPortal/Dashboard/components/Support/Models/Ticket";
import AccountDisabledModal from "./scenes/Dashboard/components/Users/components/AccountDisabledModal";
import NotificationUnsubscribe from "./scenes/Dashboard/components/Users/components/NotificationUnsubscribe";
import Can from "./components/Can";
import Forbidden from "./components/Forbidden";
import {
  ANALYTICS_VIEW,
  BILLING_VIEW,
  BUCKETS_VIEW,
  CUSTOMERS_VIEW,
  NOTIFICATIONS_VIEW,
  PERMISSIONS_VIEW,
  SA_VIEW,
  SETTINGS_VIEW,
  SUPPORT_VIEW,
  USERS_VIEW
} from "./authRules";
// import NoPermissionError from "./scenes/Errors/NoPermission";
// import UnexpectedError from "./scenes/Errors/Unexpected";
import LoginCallback from "./components/LoginCallback";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_WHOAMI } from "./state/UserInfo/actions";
import { AppState } from "./store";
import { WhoAmI } from "./state/UserInfo/types";
import { isEmpty } from "lodash";
import LoginValidation from "./scenes/Errors/LoginValidation";
import { CustomerSupport } from "./scenes/Dashboard/components/Support";
import {
  Subaccount,
  Subaccounts,
  CreateSubaccount
} from "./scenes/Dashboard/components/Subaccounts";
import Resource from "./PartnerPortal/Dashboard/components/Support/Tabs/Resource";
import {
  PartnerMFA,
  PartnerNotification,
  PartnerSAMLFederation
} from "./PartnerPortal/Dashboard/components/IAM";
import NotificationRecipients from "./components/NotificationRecipients";
import ModalBase from "./components/Modal";
import Settings from "./components/Settings";
import ViewBucket from "./scenes/Dashboard/components/Buckets/components/ViewBucket";
import LyveGoodBye from "./components/LyveGoodBye";
import LyveTerms from "./components/LyveTerms";
import { getDocsBaseUrl } from "./components/LyveHeadingLink/LinkUtil";
import ChangePriority from "./scenes/Dashboard/components/Buckets/components/ViewBucket/Replication/ChangePriority";
import { nanoid } from "nanoid";
import { Analytics, ZCompute } from "./scenes/Dashboard/components/Analytics";
import DataMover from "./scenes/Dashboard/components/DataMover";

let isInit = false;
let tabInfo = {};

let deleteTabInterval = setInterval(() => {
  Object.keys(tabInfo).forEach(key => {
    //@ts-ignore
    if (tabInfo[key].lastActiveAt < Date.now() - 2000) {
      //@ts-ignore
      delete tabInfo[key];
    }
  });
}, 2000);

let isMainTab = false;
let isLastActive = 0;
const tabId = nanoid();
let eventListenInterval: any;

const processFn = function(e: any) {
  isLastActive = Date.now();
  const data = JSON.parse(e.data);
  if (data.type === "poll") {
    //@ts-ignore
    tabInfo[data.tabId] = data.lastActiveAt;
  } else if (data.type === "close") {
    //@ts-ignore
    delete tabInfo[data.tabId];
  }
};

const listenToEvents = () => {
  new BroadcastChannel("appEvent").addEventListener("message", e => {
    if (localStorage.getItem("messagingFlag") === null) {
      const data = JSON.parse(e.data);
      if (data.type === "mainTabClose" && data.nextAdmin === tabId) {
        listenToMainEvent();
      }
    }
  });
};

const listenToMainEvent = () => {
  clearInterval(eventListenInterval);
  //@ts-ignore
  delete tabInfo[tabId];
  try {
    new BroadcastChannel("myapp").addEventListener("message", processFn);
    localStorage.setItem("messagingFlag", "true");
    isMainTab = true;
  } catch {
    localStorage.clear();
    window.sessionStorage.clear();
    history.push("/error?code=e999999");
  }
};

const AuthorizedRoute = (props: any) => {
  const { layout: Layout, component: Component, action, ...rest } = props;
  const whoAmI = useSelector<AppState, WhoAmI>(state => state.userInfo.whoAmI);
  const [reloaded, setReloaded] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isInit === false) {
      if (
        localStorage.getItem("messagingFlag") === null ||
        localStorage.getItem("isMannualClosed") === null
      ) {
        listenToMainEvent();
      } else {
        eventListenInterval = setInterval(() => {
          new BroadcastChannel("myapp").postMessage(
            JSON.stringify({
              type: "poll",
              tabId: tabId,
              lastActiveAt: Date.now()
            })
          );
        }, 1000);
        listenToEvents();
      }
      isInit = true;

      window.onbeforeunload = () => {
        new BroadcastChannel("myapp").postMessage(
          JSON.stringify({
            type: "close",
            tabId: tabId,
            lastActiveAt: Date.now()
          })
        );
        if (Object.keys(tabInfo).length === 0) {
          localStorage.clear();
          localStorage.setItem("isMannualClosed", "true");
        } else if (isMainTab === true) {
          new BroadcastChannel("appEvent").postMessage(
            JSON.stringify({
              type: "mainTabClose",
              tabId: tabId,
              nextAdmin: Object.keys(tabInfo).filter(k => k !== tabId)[0],
              lastActiveAt: Date.now()
            })
          );
          localStorage.removeItem("messagingFlag");
          localStorage.removeItem("isMannualClosed");
          clearInterval(deleteTabInterval);
          clearInterval(eventListenInterval);
        }
      };
    }
  }, []);

  const reload = (event: PageTransitionEvent) => {
    if (event.persisted) {
      window.location.reload();
    }
  };

  const loc = useLocation();
  React.useLayoutEffect(() => {
    if (checkBreakGlassUser() && loc.pathname !== "/terms&conditions") {
      history.push("/terms&conditions");
    }

    window.addEventListener("pageshow", reload);

    return () => {
      window.removeEventListener("pageshow", reload);
    };
  }, []);

  const tabOpened = () => {
    setReloaded(true);
    const token = window.sessionStorage.getItem("token");
    if (!token) {
      const tokenBackup = localStorage.getItem("token");
      window.sessionStorage.setItem("token", tokenBackup || "");
    }
  };

  window.onload = function() {
    tabOpened();
  };

  //user cookie still got issue, so add the code temporarily
  // React.useEffect(() => {
  //   const queryParams = new URLSearchParams(location.search);
  //   const encodedSessionId = queryParams.get("sessionid");
  //   if (encodedSessionId) {
  //     const sessionId = atob(encodedSessionId);
  //     api.setAccessToken(sessionId);
  //   }
  // }, [location]);

  //user cookie still got issue, so remove the code temporarily
  // React.useEffect(() => {
  //   const queryParams = new URLSearchParams(location.search);
  //   const state = queryParams.get("State");
  //   const code = queryParams.get("Code");

  //   // new login flow
  //   if (state && code) {
  //     // set token API validate code and state
  //     try {
  //       api.validateUserSession(state, code).then(res => {
  //         if (res.error && res.redirect_url) {
  //           window.open(res.redirect_url, "_self");
  //         }
  //       });
  //     } catch (err) {
  //       common.logoutCleanup(null);
  //     }
  //   }
  // }, [location]);

  React.useEffect(() => {
    // validate whoAmI is present
    if (isEmpty(whoAmI) && reloaded) {
      setReloaded(false);
      api
        .whoami()
        .then(res => {
          dispatch({ type: UPDATE_WHOAMI, payload: res });
          // it will be remove in next version
          api.setUserInfo(res);
        })
        .catch(res => {
          common.logoutCleanup(null);
        });
    }
  }, [whoAmI, reloaded]);

  if (!Component) {
    return <Redirect to="/" />;
  }

  // Add origin in the idb
  let windowObj: WindowObj = {
    WindowID: "WINDOW",
    WindowOrigin: window.location.origin
  };
  idbHelper.__saveWindowObjIDB(windowObj);

  if (isEmpty(whoAmI) || whoAmI === undefined) {
    return (
      <Route
        {...rest}
        render={matchProps => (
          <Can perform={action} no={<LoginValidation />}>
            <Component {...matchProps} />
          </Can>
        )}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          {action ? (
            <Can perform={action} no={<Forbidden />}>
              <Component {...matchProps} />
            </Can>
          ) : (
            <Component {...matchProps} />
          )}
        </Layout>
      )}
    />
  );
};

const Routes: React.FC = () => {
  // const { sumonTestFlag } = useFlags();
  // const { customerDashboardFlag } = useFlags();

  return (
    <Router history={history}>
      <Switch>
        {/* common routes : for partner portal and customer portal */}
        {/* <Route exact path="/home">
          <PartnerLandingView />
        </Route> */}

        {/* <Route exact path="/login-old" component={Login} /> */}
        <Route exact path="/dashboard" component={LoginCallback} />
        <Route exact path="/checkauth" component={LoginCallback} />

        <Route
          exact
          path="/notification/unsubscribe/confirm"
          component={NotificationUnsubscribe}
        />
        <Route
          exact
          path="/mutiple-users-error"
          component={MutipleUsersError}
        />
        <Route exact path="/expired-token/:userName" component={ExpiredToken} />

        {/* <Route exact path="/unauthenticated" component={NoPermissionError} /> */}

        {/* <Route exact path="/error" component={UnexpectedError} /> */}
        <Route exact path="/error" component={UniformError} />
        <Route exact path="/goodbye" component={LyveGoodBye} />

        <Route exact path="/pagenotfound" component={NoMatchError} />

        <Route exact path="/reset-password">
          <ResetPassword />
        </Route>
        <Route exact path="/forget-password">
          <ForgetPassword />
        </Route>

        <Route exact path="/partner/landing-view">
          <PartnerLandingView />
        </Route>

        {/* <Route exact path="/partner" component={PartnerLogin} /> */}

        <Route exact path="/activate-account" component={ActivateAccount} />

        {/* <Route exact path="/partner/login" component={PartnerLogin} /> */}

        <Route exact path="/reset-password">
          <ResetPassword />
        </Route>
        <Route exact path="/forget-password">
          <ForgetPassword />
        </Route>
        <Route exact path="/account-disabled">
          <AccountDisabledModal />
        </Route>

        <Route exact path="/terms&conditions">
          <LyveTerms />
        </Route>

        {/* customer portal routes : only for customer portal */}
        <AuthorizedRoute
          exact
          path="/customer/dashboard"
          layout={MainLayout}
          //component={customerDashboardFlag ? Metrics : Buckets}
          component={Metrics}
        />
        <AuthorizedRoute
          exact
          path="/customer/buckets"
          action={BUCKETS_VIEW}
          layout={MainLayout}
          component={Buckets}
        />
        {/*<AuthorizedRoute*/}
        {/*  exact*/}
        {/*  path="/customer/archive-buckets"*/}
        {/*  action={ARCHIVE_BUCKETS_VIEW}*/}
        {/*  layout={MainLayout}*/}
        {/*  component={ArchiveBuckets}*/}
        {/*/>*/}
        <AuthorizedRoute
          exact
          path="/customer/bucket/:bucketName"
          action={BUCKETS_VIEW}
          layout={MainLayout}
          component={ViewBucket}
        />
        {/*<AuthorizedRoute*/}
        {/*  exact*/}
        {/*  path="/customer/archive-bucket/:bucketName"*/}
        {/*  action={ARCHIVE_BUCKETS_VIEW}*/}
        {/*  layout={MainLayout}*/}
        {/*  component={ViewArchiveBucket}*/}
        {/*/>*/}
        <AuthorizedRoute
          exact
          path="/customer/bucket/:bucketName/priority"
          action={BUCKETS_VIEW}
          layout={MainLayout}
          component={ChangePriority}
        />
        <AuthorizedRoute
          exact
          path="/customer/permissions"
          action={PERMISSIONS_VIEW}
          layout={MainLayout}
          component={Permissions}
        />
        <AuthorizedRoute
          exact
          path="/customer/service-accounts"
          action={SA_VIEW}
          layout={MainLayout}
          component={ServiceAccounts}
        />
        <AuthorizedRoute
          exact
          path="/customer/subaccounts"
          action={SA_VIEW}
          layout={MainLayout}
          component={Subaccounts}
        />
        <AuthorizedRoute
          exact
          path="/customer/subaccounts/create/:id?"
          action={SA_VIEW}
          layout={MainLayout}
          component={CreateSubaccount}
        />
        <AuthorizedRoute
          exact
          path="/customer/subaccounts/:sub_account_short_name"
          action={SA_VIEW}
          layout={MainLayout}
          component={Subaccount}
        />
        <AuthorizedRoute
          exact
          path="/customer/users"
          action={USERS_VIEW}
          layout={MainLayout}
          component={Users}
        />
        <AuthorizedRoute
          exact
          path="/customer/mfa"
          action={USERS_VIEW}
          layout={MainLayout}
          component={CustomerMFA}
        />
        <AuthorizedRoute
          exact
          path="/customer/saml-federation"
          action={USERS_VIEW}
          layout={MainLayout}
          component={CustomerSAMLFederation}
        />
        <AuthorizedRoute
          exact
          path="/customer/notifications"
          action={NOTIFICATIONS_VIEW}
          layout={MainLayout}
          component={CustomerNotificationRecipients}
        />
        <AuthorizedRoute
          exact
          path="/customer/support"
          action={SUPPORT_VIEW}
          layout={MainLayout}
          component={CustomerSupport}
        />
        <AuthorizedRoute
          exact
          path="/customer/support-resources"
          layout={MainLayout}
          component={Resource}
        />
        <AuthorizedRoute
          exact
          path="/customer/support-dashboard"
          action={SUPPORT_VIEW}
          layout={MainLayout}
          component={SupportDashboard}
        />
        <AuthorizedRoute
          exact
          path="/customer/support-dashboard/ticket-details/:ticketid"
          action={SUPPORT_VIEW}
          layout={MainLayout}
          component={SupportTicketDetailsUpdate}
        />
        <AuthorizedRoute
          exact
          path="/customer/settings"
          action={SETTINGS_VIEW}
          layout={MainLayout}
          component={Settings}
        />

        {/* partner portal routes : only for partner portal */}
        <AuthorizedRoute
          exact
          path="/partner/dashboard"
          layout={PartnerMainLayout}
          component={PartnerMetric}
        />
        {/* <AuthorizedRoute
					exact
					path="/partner/iam"
					action={USERS_VIEW}
					layout={PartnerMainLayout}
					component={IAM}
				/> */}
        <AuthorizedRoute
          exact
          path="/partner/users"
          action={USERS_VIEW}
          layout={PartnerMainLayout}
          component={Users}
        />
        <AuthorizedRoute
          exact
          path="/partner/mfa"
          action={USERS_VIEW}
          layout={PartnerMainLayout}
          component={PartnerMFA}
        />
        <AuthorizedRoute
          exact
          path="/partner/saml-federation"
          action={USERS_VIEW}
          layout={PartnerMainLayout}
          component={PartnerSAMLFederation}
        />
        <AuthorizedRoute
          exact
          path="/partner/notifications"
          action={NOTIFICATIONS_VIEW}
          layout={PartnerMainLayout}
          component={PartnerNotification}
        />
        {/* <AuthorizedRoute
          exact
          path="/partner/mfa"
          action={USERS_VIEW}
          layout={PartnerMainLayout}
          component={PartnerMFA}
        />
        <AuthorizedRoute
          exact
          path="/partner/saml-federation"
          action={USERS_VIEW}
          layout={PartnerMainLayout}
          component={PartnerSAMLFederation}
        />
        <AuthorizedRoute
          exact
          path="/partner/notifications"
          action={NOTIFICATIONS_VIEW}
          layout={PartnerMainLayout}
          component={PartnerNotification}
        /> */}
        <AuthorizedRoute
          exact
          path="/partner/tenant"
          action={CUSTOMERS_VIEW}
          layout={PartnerMainLayout}
          component={Tenants}
        />
        <AuthorizedRoute
          exact
          path="/partner/support"
          action={SUPPORT_VIEW}
          layout={PartnerMainLayout}
          component={Support}
        />
        {/* <AuthorizedRoute
          exact
          path="/partner/support-resources"
          layout={PartnerMainLayout}
          component={Resource}
        /> */}
        <AuthorizedRoute
          exact
          path="/partner/notification"
          layout={PartnerMainLayout}
          component={NotificationRecipients}
        />
        <AuthorizedRoute
          exact
          path="/partner/support-dashboard"
          action={SUPPORT_VIEW}
          layout={PartnerMainLayout}
          component={SupportDashboard}
        />
        <AuthorizedRoute
          exact
          path="/partner/support-dashboard/ticket-details/:ticketid"
          action={SUPPORT_VIEW}
          layout={PartnerMainLayout}
          component={SupportTicketDetailsUpdate}
        />

        {/* <AuthorizedRoute path="/*" component={Dashboard} /> */}

        <Route
          path="/help"
          component={(props: any) => {
            // console.log("IN ROUTER=======");
            let urlParam = props.location.search.toLowerCase();
            // console.log(props.location.search);

            // urlParam = urlParam.substring(1);
            // let urlValidation = new RegExp(
            //     /^[a-zA-Z\d-_]+$/
            // );
            // if (urlValidation.test(urlParam)) {
            //   return null;
            // }

            // Blocks javascript, ;, script, iframe, style, <, <, &quot;, ', ", %20
            if (
              urlParam.includes("javascript") ||
              urlParam.includes("%3b") ||
              urlParam.includes("%3c") ||
              urlParam.includes("%0c") ||
              urlParam.includes("%0e") ||
              urlParam.includes("%3e") ||
              urlParam.includes("&lt;") ||
              urlParam.includes("&gt;") ||
              urlParam.includes(";") ||
              urlParam.includes(",") ||
              urlParam.includes("&quot;") ||
              urlParam.includes("&#x27;") ||
              urlParam.includes("%27") ||
              urlParam.includes("%22") ||
              urlParam.includes("%20") ||
              urlParam.includes("%2c") ||
              urlParam.includes("script") ||
              urlParam.includes("iframe") ||
              urlParam.includes("style") ||
              urlParam.includes("%40") ||
              urlParam.includes("&#64;") ||
              urlParam.includes("&commat;") ||
              urlParam.includes("@")
            ) {
              // console.log("URL CONTAINS javascript");
              history.push("/pagenotfound");
              return null;
            }
            // console.log("URL DOES NOT CONTAINS javascript");

            var help_url: any = "";
            var doc_url: any = props.location.search;
            doc_url = doc_url.substring(1);

            //var helpLoggedIn: any = storage.getItem("help_loggedIn");

            // if (helpLoggedIn === "true") {
            // console.log("helpLoggedIn : "+ helpLoggedIn);
            if (
              doc_url.includes(getDocsBaseUrl()) &&
              !(
                doc_url.includes("javascript") ||
                doc_url.includes("%3b") ||
                doc_url.includes("%3c") ||
                doc_url.includes("%0c") ||
                doc_url.includes("%0e") ||
                doc_url.includes("%3e") ||
                doc_url.includes("&lt;") ||
                doc_url.includes("&gt;") ||
                doc_url.includes(";") ||
                doc_url.includes(",") ||
                doc_url.includes("&quot;") ||
                doc_url.includes("&#x27;") ||
                doc_url.includes("%27") ||
                doc_url.includes("%22") ||
                doc_url.includes("%20") ||
                doc_url.includes("%2c") ||
                doc_url.includes("script") ||
                doc_url.includes("iframe") ||
                doc_url.includes("style") ||
                doc_url.includes("%40") ||
                doc_url.includes("&#64;") ||
                doc_url.includes("&commat;") ||
                doc_url.includes("@")
              )
            ) {
              window.location.href = doc_url;
            } else {
              // console.log("Wrong URL");
              history.push("/pagenotfound");
              return null;
            }
            // } else {
            //   // console.log("helpLoggedIn : "+ helpLoggedIn);
            //   api
            //     .whoami()
            //     .then(res => {
            //       if (res.account_id !== undefined) {
            //         api.getHelpURl().then(res => {
            //           help_url = res.help_url;
            //           // console.log("help_url");
            //           // console.log(help_url);
            //
            //           // window.open(help_url, "blank");
            //
            //           if (help_url !== "") {
            //             let a = document.createElement("a");
            //             a.target = "_self";
            //             a.href = help_url;
            //             a.click();
            //
            //             storage.setItem("help_loggedIn", "true");
            //
            //             // setTimeout(() => {
            //             //   console.log("doc_url");
            //             //   console.log(doc_url);
            //             //   if(doc_url.includes("docs.lyvecloud.seagate.com")){
            //             //     let a1= document.createElement('a');
            //             //     a1.target= '_self';
            //             //     a1.href= doc_url;
            //             //     a1.click();
            //             //   }else{
            //             //     console.log("Wrong URL");
            //             //     history.push("/pagenotfound");
            //             //     return null;
            //             //   }
            //             // }, 4000);
            //           }
            //
            //           // var wnd: any = window.open(help_url, "blank");
            //
            //           // console.log(wnd);
            //           // if (wnd !== null) {
            //           //   wnd.location.href = help_url;
            //           //   setTimeout(() => {
            //           //     wnd.close();
            //           //   }, 3000);
            //
            //           //   setTimeout(() => {
            //
            //           //     let a= document.createElement('a');
            //           //     a.target= 'blank';
            //           //     a.href= doc_url;
            //           //     a.click();
            //
            //           //     // wnd.location.replace(doc_url);
            //           //     storage.setItem("help_loggedIn", "true");
            //           //   }, 4000);
            //           // }
            //         });
            //       }
            //     })
            //     .catch(err => {
            //       console.log("Exception error: " + err);
            //     });
            // }
            return null;
          }}
        />

        <Route path="*">
          <NoMatchError />
        </Route>
      </Switch>
      <ModalBase />
    </Router>
  );
};

export default Routes;
// export default withLDProvider({
//   clientSideID:
//     window.REACT_APP_LDCLIENTID !== "" || undefined
//       ? "5f3b1d84437f5f08e2cd8e43"
//       : window.REACT_APP_LDCLIENTID
// })(Routes);
