import React from "react";
import Modal from "react-bootstrap/Modal";
import { X } from "react-bootstrap-icons";

import ModalContainer from "./useModalContainer";
import useToggle from "./useToggle";

const ModalBase = () => {
  const { modals } = ModalContainer.useContainer();
  const { show, onHide } = useToggle();

  return (
    <>
      {modals.map((modal, index) => (
        <Modal
          key={`modal_${index}`}
          show={show[index]}
          onHide={onHide(index)}
          backdrop="static"
          {...(modal.baseProps || {})}
        >
          {modal.header ? (
            <Modal.Header closeButton={true} {...(modal.headerProps || {})}>
              <Modal.Title
                {...({ ...modal.titleProps, as: modal.titleProps?.as as any } ||
                  {})}
              >
                {modal.title || "Supply Title"}
              </Modal.Title>
            </Modal.Header>
          ) : (
            !modal.customClose && (
              <button className="custom-close-icon" onClick={onHide(index)}>
                <X size="24" color="var(--gray-9)" />
              </button>
            )
          )}
          <Modal.Body
            {...({ ...modal.bodyProps, as: modal.bodyProps?.as as any } || {})}
          >
            {modal.body(onHide(index)) || "Supply body"}
          </Modal.Body>
          {modal.footer && (
            <Modal.Footer
              {...({ ...modal.footerProps, as: modal.footerProps?.as as any } ||
                {})}
            >
              {modal.footer(onHide(index))}
            </Modal.Footer>
          )}
        </Modal>
      ))}
    </>
  );
};

export default ModalBase;
