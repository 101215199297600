import React from "react";
import BaseCard from "./BaseCard";
import { ChevronRight, X } from "react-bootstrap-icons";
import { CardProps } from "../../scenes/SmartStartHere/types.d";
import { DefaultButton } from "../LyveButton";
import { ModalContainer } from "../Modal";
import { VideoPlayer } from "../Media";
import useSmartStartKit from "../../scenes/SmartStartHere/useSmartStartKit";
import { getLink, linkNames } from "../LyveHeadingLink/LinkUtil";

const videoLink =
  "https://dzf8vqv24eqhg.cloudfront.net/userfiles/28983/36615/ckfinder/files/Lyve%20Cloud%20-%20Getting%20Started.mp4";

const preventRightClick = (e: React.MouseEvent<HTMLVideoElement>) => {
  e.preventDefault();
};

const VideoCard = (props: CardProps) => {
  const { showModal } = ModalContainer.useContainer();
  const { setShowSmartStartKit, toggleShowSmartStartKit } = useSmartStartKit();

  const closeVideo = (closeModal: any) => () => {
    closeModal();
    setTimeout(() => {
      setShowSmartStartKit(true);
    }, 2000);
  };

  const playGetStartedVideo = (
    event: React.MouseEvent<Element, MouseEvent>
  ) => {
    toggleShowSmartStartKit();
    showModal({
      body: closeModal => (
        <>
          <button
            className="video-card-dialog-btn"
            onClick={closeVideo(closeModal)}
          >
            <X size="24" color="var(--gray-9)" />
          </button>
          <VideoPlayer
            url={videoLink}
            playing={true}
            width="100%"
            height="422px"
            controls={true}
            volume={1}
            onContextMenu={preventRightClick}
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                  disablePictureInPicture: true
                }
              }
            }}
          />
        </>
      ),
      customClose: true,
      baseProps: {
        centered: true,
        dialogClassName: "video-card-dialog"
      }
    });
    const target = event.target as HTMLButtonElement;
    target.blur();
  };

  return (
    <BaseCard
      {...props}
      title="Get Started Video"
      description="Watch this video to get started creating buckets and storing data on Lyve Cloud."
      body={
        <div className="d-flex">
          <DefaultButton
            className="base-card-btn"
            variant="outline-secondary"
            onClick={playGetStartedVideo}
          >
            Watch Now
          </DefaultButton>
          <a
            href={getLink(linkNames.Video_Library)}
            target="_blank"
            className="base-card-link"
          >
            <span className="mr-1"> More Videos </span>
            <ChevronRight size={10} />
          </a>
        </div>
      }
    />
  );
};

export default VideoCard;
