import React from "react";
import Select, { components, OptionTypeBase } from "react-select";
import {
  FormatOptionLabelMeta,
  SelectComponentsProps
} from "react-select/src/Select";
import { ChevronDown } from "react-bootstrap-icons";

import Input from "../Input";
import { customStyles } from "./customSelectStyles";

export interface ISelectOption extends OptionTypeBase {
  label: string;
  value: string | number;
  disabled?: boolean;
}

interface IOwnProps extends SelectComponentsProps {}

const isChecked = (value: string | number, array: ISelectOption[]) =>
  !!array.find(obj => obj.value === value);

const Label = (
  option: ISelectOption,
  labelMeta: FormatOptionLabelMeta<ISelectOption, boolean>
) => {
  const { selectValue } = labelMeta;
  return (
    <div className="custom-multi-option">
      <Input
        controlId={`${option.value}`}
        disabled={option.disabled}
        controlAs="checkbox"
        defaultChecked={isChecked(option.value, selectValue as ISelectOption[])}
      />
      {option.label}
    </div>
  );
};

const MultiValueLabel = ({ data, innerProps }: any) => (
  <div {...innerProps}>{data.label}</div>
);

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <ChevronDown
      color={props.selectProps.isDisabled ? "#808080" : "#1a1a1a"}
      size="16"
    />
  </components.DropdownIndicator>
);

/**
 * A custom select dropdown component.
 * For full list of props, visit [React Select props](https://react-select.com/props)
 */
const LyveSelect = (props: IOwnProps) => {
  const customProps = {
    styles: { ...customStyles, ...props.styles },
    ...(props.isMulti
      ? {
          formatOptionLabel: Label
        }
      : {}),
    components: {
      DropdownIndicator,
      ...(props.isMulti ? { MultiValueLabel } : {}),
      ...props.components
    }
  };


  return (
    <Select
      hideSelectedOptions={false}
      isClearable={false}
      closeMenuOnSelect={!props.isMulti}
      classNamePrefix="lyve-select"
      {...props}
      // custom styles
      {...customProps}
    />
  );
};

export default LyveSelect;
