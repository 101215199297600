import React, { useState } from "react";
import { compose } from "recompose";
import { connect, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { Link, useHistory } from "react-router-dom";
import { ArrowClockwise, CheckCircle, ExclamationTriangleFill, Trash, XCircle } from "react-bootstrap-icons";
import SearchIcon from "@material-ui/icons/Search";
import { TextField, IconButton, InputAdornment } from "@material-ui/core";
import orderBy from "lodash/orderBy";
import { CSVLink } from "react-csv";
import moment from "moment-timezone";

import api from "../../../../../../services/api/index";
import { WhoAmI } from "../../../../../../state/UserInfo/types";
import { AppState, useAppDispatch } from "../../../../../../store";
import { capitalizeWord, isAllowedRole } from "../../../../../../utils";
import { getMasterAccountType } from "../../../../../../utils/subaccounts"
import {
  saveMasterAccountInfo,
  fetchingMasterAccountInfo,
  errorMasterAccountInfo
} from "../../../../../../state/Subaccounts/actions";
import Can from "../../../../../../components/Can";
import LyveHeadingLink from "../../../../../../components/LyveHeadingLink";
import { DefaultButton } from "../../../../../../components/LyveButton";
import LyveTable from "../../../../../../components/LyveTable";
import { LyveStatusDot } from "../../../../../../components/LyveBadge";
import ContextMenu from "../../../../../../components/ContextMenu";
import {
  getLink,
  linkNames
} from "../../../../../../components/LyveHeadingLink/LinkUtil";
import { useNavbar } from "../../../../../../components/NavigationBar";
import {
  rules,
  SUBACCOUNTS_CREATE,
  SUBACCOUNTS_MANAGE,
  SUBACCOUNTS_EDIT,
  SUBACCOUNTS_VIEW
} from "../../../../../../authRules";

import { getMasterAccount } from "./selector";
import "./styles.scss";
import { MoreHoriz } from "@material-ui/icons";
import { setNotification } from "../../../../../../components/Notification/actions";
import ToggleEnableSubAccount from "../ToggleEnableSubAccount";
import { Spinner } from "react-bootstrap";
import { statSync } from "fs";
import DefaultAlert from "../../../../../../components/LyveAlert";
import { useQuery } from "react-query";

interface Data {
  id: string;
  name: string;
  short_name: string;
  admin_first_name: string;
  admin_last_name: string;
  admin_email: string;
  org_name: string;
  address_line1: string;
  address_line2: string;
  city: string;
  customer_type: string;
  state: string;
  country: string;
  zip_code: string;
  phone_number: string;
  parent_short_name: string;
  is_master: boolean;
  is_enabled: boolean;
  created_date: string;
  start_date: string;
  end_date: string;
  account_type: string;
  sub_account_max_limit: number;
  sub_account_support: boolean;
  sub_account_trial: boolean;
  sub_account_trial_ext_limit: number;
  sub_account_diff_org: boolean;
  view_support_ticket: boolean;
  status: string;
}

interface Subaccount {
  id: string;
  name: string;
  short_name: string;
  admin_first_name: string;
  admin_last_name: string;
  admin_email: string;
  org_name: string;
  customer_type: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  phone_number: string;
  parent_short_name: string;
  is_master: boolean;
  is_enabled: boolean;
  created_date: string;
  start_date: string;
  end_date: string;
  account_type: string;
  sub_account_max_limit: number;
  sub_account_support: boolean;
  sub_account_trial: boolean;
  sub_account_trial_ext_limit: number;
  sub_account_diff_org: boolean;
  view_support_ticket: boolean;
  status: string;
}

interface WorkRequest{
  request_id: string;
  account_name: string;
  account_domain_name: string;
  organization_name: string;
  status: string;
}

interface SubaccountListProps {
  masterAccount?: Subaccount;
  saveMasterAccountInfo?: any;
  fetchingMasterAccountInfo?: any;
  errorMasterAccountInfo?: any;
}

const SubaccountList: React.FC<SubaccountListProps> = ({
  masterAccount,
  saveMasterAccountInfo,
  fetchingMasterAccountInfo,
  errorMasterAccountInfo
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [dataInitialized, setDataInitialized] = React.useState<boolean>(false);
  const [subaccounts, setSubaccounts] = React.useState<Data[]>([]);
  const [showMenuId, setShowMenuId] = React.useState("");

  const [selectedAccount, setSelectedAccount] = React.useState<Subaccount | null>(null);
  const resetSelectedAccount = () => setSelectedAccount(null)

  const [statusProcessingId, setStatusProcessingId] = React.useState<
    string | null
  >(null);
  const downloadBtnRef: any = React.useRef(null);
  const whoAmI = useSelector<AppState, WhoAmI>(state => state.userInfo.whoAmI);

  const { userRole, userType } = useNavbar();

  const role = `${userType}-${userRole}`;
  const status = {
    beingProvisioned: "Being Provisioned",
    provisionedFailed: "Provisioned Failed",
    enabled:"Enabled",
    disabled:"Disabled"
  }
  const [enablePolling, setEnablePolling] = React.useState<boolean>(false);
  useQuery<any>(
    "subaccount-list",
    () => {
      fetchSubAccounts()
    },
    {
      enabled: enablePolling,
      refetchOnMount: false,
      refetchInterval: 5000,
      // refetchInterval: false,
      refetchOnReconnect: false,
      retry: false,
    }
  )
  var  st = ""
  const constructSubaccountList = (subaccount: Subaccount[], workRequest: WorkRequest[]) => {
    let subaccountList: Data[] = [];
    if (subaccount) {
      subaccountList = subaccount.map(
        (b: Subaccount): Data => {         
          if (!b.is_enabled ){
              st=status.disabled
          }else if(b.is_enabled){
            st =status.enabled
          }
          return {
            id: b.id,
            name: b.name,
            short_name: b.short_name,
            admin_first_name: b.admin_first_name,
            admin_last_name: b.admin_last_name,
            admin_email: b.admin_email,
            org_name: b.org_name,
            customer_type: b.customer_type,
            address_line1: b.address_line1,
            address_line2: b.address_line2,
            city: b.city,
            state: b.state,
            country: b.country,
            zip_code: b.zip_code,
            phone_number: b.phone_number,
            parent_short_name: b.parent_short_name,
            is_master: b.is_master,
            is_enabled: b.is_enabled,
            created_date: b.created_date ? moment.utc(b.created_date).local().format("YYYY-MM-DD HH:mm"):"",
            start_date: b.start_date,
            end_date: b.end_date,
            account_type: b.account_type,
            sub_account_max_limit: b.sub_account_max_limit,
            sub_account_support: b.sub_account_support,
            sub_account_trial: b.sub_account_trial,
            sub_account_trial_ext_limit: b.sub_account_trial_ext_limit,
            sub_account_diff_org: b.sub_account_diff_org,
            view_support_ticket: b.view_support_ticket,
            status: st 
          };
        }
      );

    }
    if(workRequest) {
      let provisionedSubaccountList = workRequest.map((wr: WorkRequest): Data => {
        return {
          id: wr.request_id,
          name: wr.account_name,
          short_name: wr.account_domain_name,
          admin_first_name: "",
          admin_last_name: "",
          admin_email: "",
          org_name: wr.organization_name,
          customer_type: "",
          address_line1: "",
          address_line2: "",
          city: "",
          state: "",
          country: "",
          zip_code: "",
          phone_number: "",
          parent_short_name: "",
          is_master: false,
          is_enabled: false,
          created_date: "",
          start_date: "",
          end_date: "",
          account_type: "",
          sub_account_max_limit: 0,
          sub_account_support: false,
          sub_account_trial: false,
          sub_account_trial_ext_limit: 0,
          sub_account_diff_org: false,
          view_support_ticket: false,
          status: wr.status,
        };
      })
      provisionedSubaccountList.sort((a, b) => a.status > b.status ? 1 : -1)
      subaccountList.sort((a, b) => a.created_date > b.created_date ? 1 : -1)
      subaccountList = [...subaccountList, ...provisionedSubaccountList]
    }
    setSubaccounts([...subaccountList]);
  };

  const fetchMasterAccount = () => {
    if (whoAmI?.company_short_name) {
      fetchingMasterAccountInfo();
      api
        .getSubaccount(whoAmI?.company_short_name)
        .then(res => {
          if (res) {
            saveMasterAccountInfo(res);
          }
        })
        .catch(err => {
          errorMasterAccountInfo(err);
        });
    }
  };

  React.useEffect(() => {
    if (!masterAccount) {
      fetchMasterAccount();
      return;
    }
    else {
      if (!masterAccount.is_master || !masterAccount.sub_account_max_limit) {
        history.push("/customer/dashboard");
      }
      fetchSubAccounts().then(() => {
        setDataInitialized(true)
      }).catch(() => {
        setDataInitialized(false)
      })
    }
  }, [masterAccount, whoAmI]);

  const noSubaccountsCreated = () => {
    return dataInitialized && subaccounts.length === 0;
  };

  const fetchSubAccounts = () => {
    return new Promise((resolve, reject) => {
      api
        .getSubaccounts(masterAccount?.short_name || "")
        .then(res => {
          const data = constructSubaccountList(res.sub_accounts, res.work_requests);
          if (res.work_requests.find((a: any) => a.status == status.beingProvisioned)) {
            setEnablePolling(true)
          } 
          else {
            setEnablePolling(false)
          }
          resolve(data)
        })
        .catch(reject)
    })
  }

  const handleUpdateSubaccount = (
    id: string,
    short_name: string,
    enabled: boolean
  ) => {
    setStatusProcessingId(id);
    api
      .toggleSubAccount(short_name, enabled)
      .then(() => {
          fetchSubAccounts()
          .finally(() => {
            setStatusProcessingId(null);
            if (enabled) {
              setNotification(`${short_name} has been disabled.`)(dispatch);
            } else {
              setNotification(`${short_name} has been enabled.`)(dispatch);
            }
          });
      })
      .catch(() => {
        setStatusProcessingId(null);
      });
  };

  const handleRetrySubaccount = (
    requestId: string,
  ) => {
    history.push(`/customer/subaccounts/create/${requestId}`);
  };
  const handleDismissSubAccount = (
    requestId: string,
  ) => {
    setStatusProcessingId(requestId)
    api.dismissFailedSubaccount(requestId)
    .then(() => fetchSubAccounts())
  };

  
  const customSort = (
    rows: Data[],
    field: string,
    direction: "desc" | "asc"
  ) => {

    const handleField = (row: any) => {
      const val = row[field];
      if (val && typeof val === "string") {
        return val.toLowerCase();
      }
      return val;
    };

    return orderBy(rows, handleField, direction);
  };

  const accountType = getMasterAccountType(masterAccount);

  /** Datatable */
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All"
  };

  const columns = [
    {
      name: "Account Name",
      selector: "name",
      sortable: true,
      minWidth: "300px",
      maxWidth: "400px",
      cell: (row: Data) => (
        <span title={row.name}>
          {(isAllowedRole(rules, role, SUBACCOUNTS_MANAGE) || isAllowedRole(rules, role, SUBACCOUNTS_VIEW)) &&
          row.status != status.beingProvisioned && row.status != status.provisionedFailed ? (
            <Link
              className="caseNumber"
              to={{
                pathname: `/customer/subaccounts/${row.short_name}`,
                state: { subaccount: row }
              }}
            >
              {row.name}
            </Link>
          ) : (
            <span title={row.name}>{row.name}</span>
          )}
        </span>
      )
    },
    {
      name: "Account ID",
      selector: "short_name",
      sortable: true,
      minWidth: "150px",
      maxWidth: "300px",
      cell: (row: Data) => {
        return <span title={row.short_name}>{row.short_name}</span>;
      }
    },
    {
      name: "Organization Name",
      selector: "org_name",
      sortable: true,
      minWidth: "200px",
      maxWidth: "350px",
      cell: (row: Data) => <span title={row.org_name}>{row.org_name}</span>
    },
    {
      name: "Created On",
      selector: "created_date",
      minWidth: "200px",
      maxWidth: "300px",
      sortable: true,
      sortFunction: (rowA:any, rowB:any) => {
        const a = rowA.created_date.toLowerCase();
        const b = rowB.created_date.toLowerCase();

        if (a && b) {
          if (a > b) {
              return 1;
          }

          if (a < b) {
              return -1;
          }
        }
        else if (a) {
          return -1
        }
        else if (b) {
          return 1
        }

        return 0;
      },

      cell: (row: Data) => (
        row.status != status.beingProvisioned && row.status != status.provisionedFailed ?
        <span title={moment.utc(row.created_date).format("YYYY-MM-DD hh:mm A")}>
           {moment.utc(row.created_date).format("YYYY-MM-DD hh:mm A")}
        </span>:
        <></>
      )
    },
    {
      name: "Status",
      selector: "status",
      minWidth: "100px",
      maxWidth: "200px",
      sortable: true,
      cell: (row: Data) =>
        row.id === statusProcessingId ? (
          <div className="col-12 pl-0">
            <div
              className="spinner-border spinner-border-sm"
              style={{ color: "#6ebe49" }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          renderReadOnlyStatus(row)
        )
    },
    // {
    //   name: "Trial Status",
    //   selector: "customer_type",
    //   sortable: true,
    //   minWidth: "200px",
    //   maxWidth: "350px",
    //   cell: (row: Data) => {
    //     const customerType = capitalizeWord(row.customer_type)
    //     return (
    //       <span title={customerType}>
    //         {customerType}
    //       </span>
    //     )}
    // },
    {
      name: "",
      minWidth: "50px",
      maxWidth: "50px",
      right: true,
      cell: (row: Data) => {
        if (
          accountType.isReseller
        ) {
          return null;
        }

        return (
          <>
            <ToggleEnableSubAccount
              show={selectedAccount?.id === row.id && row.is_enabled}
              subaccount={row}
              isEnabled={row.is_enabled}
              onSuccess={fetchSubAccounts}
              onClose={resetSelectedAccount}
            />
            <Can perform={SUBACCOUNTS_EDIT}>
            <ContextMenu
              options={getSubaccountListActions(row)}
              id="sub-account-toggle"
              hasManualToggle={true}
              parentDropdownClass="table-menu"
              drop={"down"}
              manualShow={showMenuId === row.id}
              alignRight={false}
              onToggle={(isOpen, _event, _meta) => {
                if (!isOpen) {
                  setShowMenuId("");
                }
              }}
              minWidth="200px"
              trigger={
                row.status == status.beingProvisioned ? <></> :
                showMenuId === row.id ? (
                  <button
                    type="button"
                    className="btn btn-icon table-close-icon"
                    onClick={() => setShowMenuId("")}
                  >
                    <i className="material-icons">close</i>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-icon"
                    onClick={() => setShowMenuId(row.id)}
                  >
                    <MoreHoriz />
                  </button>
                )
              }
            />
          </Can>
            </>
        );
      }
    }
  ];

  const getSubaccountListActions = (row: Data) => {
    if(row.status == status.provisionedFailed) {
      return [
        {
          action: SUBACCOUNTS_EDIT,
          label: (
            <>
              <div className="table-menu-option">
                <ArrowClockwise size="16" className="mr-2" />
                <span>Try Again</span>
              </div>
            </>
          ),
          onClick: () => {
            console.log("Handle Retry Subaccount.")
              handleRetrySubaccount(row.id)
          }
        },
        {
          action: SUBACCOUNTS_EDIT,
          label: (
            <>
              <div className="table-menu-option">
                <Trash size="16" className="mr-2" />
                <span>Dismiss</span>
              </div>
            </>
          ),
          onClick: () => {
            console.log("Handle Dismiss Subaccount.")
            handleDismissSubAccount(row.id)
          }
        }
      ]
    }
    return [
      {
        action: SUBACCOUNTS_EDIT,
        label: (
          <>
            <div className="table-menu-option">
              {row.is_enabled ? (
                <XCircle size="16" className="mr-2" />
              ) : (
                <CheckCircle size="16" className="mr-2" />
              )}
              <span>{row.is_enabled ? "Disable" : "Enable"}</span>
            </div>
          </>
        ),
        onClick: () => {
          setShowMenuId("");
          if (row.is_enabled) {
            setSelectedAccount(row);
          } else {
            handleUpdateSubaccount(row.id, row.short_name, row.is_enabled);
          }
        }
      }
    ];
  };

  const renderReadOnlyStatus = (row: Data) => {
    if (row.status == status.beingProvisioned) {
      return (
        <div style={{display: "flex", alignItems: "center"}}>
          <Spinner
            size="sm"
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
            variant="dark"
            style={{marginRight:"6px", width: "10px", height: "10px"}}
          />
          <span title="Being provisioned">Being provisioned</span>
        </div>
      );

    }
    else if (row.status == status.provisionedFailed) {
      return (
        <LyveStatusDot color="var(--red-6)" size={8}>
          <span title="Provision failed">Provision failed</span>
        </LyveStatusDot>
      );
    }
    else if (row.is_enabled) {
      return (
        <LyveStatusDot color="var(--green-3)" size={8}>
          <span title="Enabled">Enabled</span>
        </LyveStatusDot>
      );
    } else if (!row.is_enabled) {
      return (
        <LyveStatusDot color="var(--gray-6)" size={8}>
          <span title="Disabled">Disabled</span>
        </LyveStatusDot>
      );
    }
  };

  const goToCreateSubAccount = () =>
    history.push("/customer/subaccounts/create");

  const CSVHeaders = [
    { label: "Account Name", key: "name" },
    { label: "Account ID", key: "short_name" },
    { label: "Organization Name", key: "org_name" },
    { label: "Enabled", key: "is_enabled" },
    { label: "Created On", key: "created_date" }
  ];

  const disableCreate =
    !subaccounts ||
    !masterAccount ||
      subaccounts?.filter((a: any) => a.status != status.provisionedFailed).length >= masterAccount.sub_account_max_limit ||
    (accountType.isReseller);
  const renderAlert = () => {
    if (subaccounts && masterAccount && 
      subaccounts?.filter((a: any) => a.status != status.provisionedFailed).length >= masterAccount.sub_account_max_limit) {
      return (
        <div className="alert-wrapper">
          <DefaultAlert variant="warning" customClasses={'d-flex'}>
            <ExclamationTriangleFill size={16}style={{color: "#F5AF18", marginRight: "10px"}}/>
            <span style={{ color: "#1a1a1a", verticalAlign: "middle"}}>You have reached the sub-account limit for your account. Please contact support to increase the sub-account limit.</span>
          </DefaultAlert>
        </div>
      )
    }
  }
  return (
    <div className="container-fluid subaccount-list-page">
      <div className="row no-gutter">
        {dataInitialized ? (
          <div className="col">
            <div className="subaccounts-wrapper">
              <LyveHeadingLink
                title="Sub-accounts"
                subTitle="Create and manage sub-accounts."
                helpLink={getLink(linkNames.SUBACCOUNT)}
              />
              {renderAlert()}
              <div className="subheader-div">
                <Can perform={SUBACCOUNTS_CREATE}>
                  <DefaultButton
                    name="create_btn"
                    className="create-button"
                    onClick={goToCreateSubAccount}
                    disabled={disableCreate}
                  >
                    Create Sub-account
                  </DefaultButton>
                </Can>
                {/*<TextField*/}
                {/*  label="Search sub-accounts by name"*/}
                {/*  variant="outlined"*/}
                {/*  className="search-input"*/}
                {/*  disabled={true}*/}
                {/*  InputProps={{*/}
                {/*    endAdornment: (*/}
                {/*      <IconButton>*/}
                {/*        <SearchIcon />*/}
                {/*      </IconButton>*/}
                {/*    )*/}
                {/*  }}*/}
                {/*/>*/}

                <div className="ml-auto">
                  <DefaultButton
                    variant="outline-secondary"
                    className="ml-3"
                    onClick={() => downloadBtnRef?.current?.link?.click()}
                    disabled={!subaccounts || !subaccounts.length}
                  >
                    Download
                  </DefaultButton>
                  <CSVLink
                    data={subaccounts.filter(sa => sa.status == status.enabled || sa.status== status.disabled)}
                    ref={downloadBtnRef}
                    className="csv-download-link"
                    target="_blank"
                    headers={CSVHeaders}
                    filename={`${masterAccount?.short_name}-subaccounts.csv`}
                  />
                </div>
              </div>
              {noSubaccountsCreated() && (
                <Can
                  perform={SUBACCOUNTS_CREATE}
                  no={"There are no subaccounts"}
                >
                  You do not have any subaccounts. Create one now.
                </Can>
              )}
              {!noSubaccountsCreated() && (
                <LyveTable
                  columns={columns}
                  data={subaccounts}
                  sortFunction={customSort}
                  defaultSortFieldId={4}
                  defaultSortAsc={false}
                  selectableRows={false}
                  pagination={true}
                  noHeader={true}
                  paginationPerPage={10}
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  highlightOnHover={true}
                  paginationComponentOptions={paginationOptions}
                  noDataComponent={<div />}
                  paginationIconFirstPage={false}
                  paginationIconLastPage={false}
                  responsive={true}
                >
                  <div className="table-footer">
                    <div>
                      {subaccounts?.filter((a: any) => a.status!=status.provisionedFailed && a.status != status.beingProvisioned).length} out of{" "}
                      {masterAccount?.sub_account_max_limit} sub-accounts created
                    </div>
                  </div>
                </LyveTable>
              )}
            </div>
          </div>
        ) : (
          <div className="col">
            <div className="row align-items-center" style={{ height: 600 }}>
              <div className="col text-center">
                <div
                  className="spinner-border"
                  style={{ color: "#6ebe49" }}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveMasterAccountInfo: (account: Subaccount) =>
    dispatch(saveMasterAccountInfo(account)),
  fetchingMasterAccountInfo: () => dispatch(fetchingMasterAccountInfo()),
  errorMasterAccountInfo: (err: any) => dispatch(errorMasterAccountInfo(err))
});

const mapStateToProps = (state: AppState) => ({
  masterAccount: getMasterAccount(state)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SubaccountList
);
