import React from "react";
import filesize from "filesize";
import { tenant_summary } from "./index";
import history from "../../../../history";
import ReactTooltip from "react-tooltip";
import LyveTable from "../../../../components/LyveTable";
import { convertFileSize } from "../../../../utils";

interface CustomerDetailsProps {
  customerlist?: any;
}

const CustomerDetails: React.FC<CustomerDetailsProps> = ({ customerlist }) => {
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All"
  };
  const [NoDataConst, setNoDataConst] = React.useState("");
  const NoData = (
    // <p style={{marginTop: "170px"}}>You do not have any {NoDataConst} tickets for now.</p>

    <table className="table empty-table">
      <thead>
        <tr>
          <th>
            Customers <i className="fa fa-fw fa-sort-down" />
          </th>
          <th>
            Users <i className="fa fa-fw fa-sort" />
          </th>
          <th>
            Service&nbsp;Accounts <i className="fa fa-fw fa-sort" />
          </th>
          <th>
            Buckets <i className="fa fa-fw fa-sort" />
          </th>
          <th>
            Average Usage
            <span
              data-for="table-without-data"
              className="badge badge-dark f-tooltip-bagde"
              data-tip="The average amount of data stored, per day,<br /> from the beginning of the current month to date."
            >
              ?
            </span>
            <ReactTooltip
              id="table-without-data"
              place="bottom"
              multiline
              effect="solid"
              // className="f-tooltip"
            />
            <i className="fa fa-fw fa-sort" />
          </th>
          <th>
            Open Tickets <i className="fa fa-fw fa-sort" />
          </th>
          <th>On Board</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={7}>
            <div>
              <div
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  fontWeight: 500
                }}
              >
                You do not have any customers.
                <span className="pointer">
                  <a onClick={() => history.push("/partner/tenant")}>
                    {" "}
                    Create one now
                  </a>
                </span>{" "}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );

  const columns = [
    {
      name: "Customers",
      selector: "tenant_or_partner_name",
      minWidth: "200px",
      sortable: true,
      cell: (row: tenant_summary) => (
        <span title={row.tenant_or_partner_name}>
          {row?.tenant_or_partner_name ?? ""}
        </span>
      )
    },
    {
      name: "Users",
      selector: "users_count",
      sortable: true,
      cell: (row: tenant_summary) => (
        <span title={String(row?.users_count ?? 0)}>
          {row?.users_count ?? 0}
        </span>
      )
    },
    {
      name: "Service Accounts",
      selector: "service_accounts_count",
      sortable: true,
      cell: (row: tenant_summary) => (
        <span title={String(row?.service_accounts_count ?? 0)}>
          {row?.service_accounts_count ?? 0}
        </span>
      )
    },
    {
      name: "Buckets",
      selector: "buckets_count",
      sortable: true,
      cell: (row: tenant_summary) => (
        <span title={String(row?.buckets_count ?? 0)}>
          {row?.buckets_count ?? 0}
        </span>
      )
    },
    {
      name: (
        <span>
          Average Usage
          <span
            data-for="table-with-data"
            className="badge badge-dark f-tooltip-bagde"
            data-tip="The average amount of data stored, per day,<br /> from the beginning of the current month to date."
          >
            ?
          </span>
          <ReactTooltip
            id="table-with-data"
            place="bottom"
            multiline
            effect="solid"
            className="f-tooltip"
          />
        </span>
      ),

      cell: (row: tenant_summary) => (
        <div
          className="d-flex align-items-center"
          style={{ width: "100%", height: 27, position: "relative" }}
        >
          {
            <div
              style={{
                width: row.usage_percent,
                height: "100%",
                background: "#E3F3DC",
                border: "1px solid #6EBE49"
              }}
            />
          }

          <div
            className="d-flex align-items-center justify-content-between w-100"
            style={{ position: "absolute" }}
          >
            <span style={{ marginLeft: 5 }}>
              {convertFileSize(row?.usage ?? 0)}
            </span>

            {row.usage_percent !== undefined && (
              <span style={{ marginRight: 5 }}>{row.usage_percent}</span>
            )}
          </div>
        </div>
      ),
      minWidth: "250px",
      selector: "usage",
      sortable: true
    },
    {
      name: "Open Tickets",
      selector: "open_ticket",
      sortable: true,
      cell: (row: tenant_summary) => (
        <span title={String(row?.open_ticket ?? 0)}>
          {row?.open_ticket ?? 0}
        </span>
      )
    },
    {
      name: "On Board",
      selector: "onboard_date",
      sortable: true,
      cell: (row: tenant_summary) => (
        <span title={row.onboard_date}>{row.onboard_date}</span>
      )
    }
  ];

  // const convertFileSize = (value: any) => {
  //   let convertedValue = filesize(value, { spacer: "" });
  //
  //   if (convertedValue.search("KB") > -1) {
  //     convertedValue = convertedValue.replace("KB", "KiB");
  //     return convertedValue;
  //   } else if (convertedValue.search("MB") > -1) {
  //     convertedValue = convertedValue.replace("MB", "MiB");
  //     return convertedValue;
  //   } else if (convertedValue.search("GB") > -1) {
  //     convertedValue = convertedValue.replace("GB", "GiB");
  //     return convertedValue;
  //   } else if (convertedValue.search("TB") > -1) {
  //     convertedValue = convertedValue.replace("TB", "TiB");
  //     return convertedValue;
  //   } else if (convertedValue.search("PB") > -1) {
  //     convertedValue = convertedValue.replace("PB", "PiB");
  //     return convertedValue;
  //   } else {
  //     return convertedValue;
  //   }
  // };

  return (
    <div>
      <LyveTable
        columns={columns}
        data={customerlist}
        defaultSortField="usage"
        defaultSortAsc={true}
        selectableRows={false}
        pagination={true}
        noHeader={true}
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 25, 50]}
        highlightOnHover={true}
        paginationIconFirstPage={false}
        paginationIconLastPage={false}
        paginationComponentOptions={paginationOptions}
        noDataComponent={NoData}
      />
    </div>
  );
};

export default CustomerDetails;
