export const getMasterAccountType = (account: any) => {
  const predicate = !!account;
  return {
    isMSP:
      predicate &&
      account?.sub_account_diff_org &&
      !account?.sub_account_support,
    isReseller:
      predicate &&
      account?.sub_account_diff_org &&
      account?.sub_account_support,
    isEnterprise:
      predicate && !account.sub_account_diff_org && account?.sub_account_support
  };
};
