import React from 'react';
import filesize from 'filesize';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { values } from 'lodash';
import moment from 'moment';
import { convertFileSize } from "../../../../../utils";

interface CustomerTrendChart {
	partnerXData?: string[];
	customerData?: ChartData[];
	periodicity?: string;
	isCustomerPortal?: boolean
}

interface ChartData {
	name: string;
	data: number[];
}

const CustomerTrendChart: React.FC<CustomerTrendChart> = ({ partnerXData, customerData, periodicity, isCustomerPortal }) => {
	// const convertFileSize = (value: any) => {
	// 	let convertedValue = filesize(value, { round: 2 });
	//
	// 	if (convertedValue.search('KB') > -1) {
	// 		convertedValue = convertedValue.replace('KB', 'KiB');
	// 		return convertedValue;
	// 	} else if (convertedValue.search('MB') > -1) {
	// 		convertedValue = convertedValue.replace('MB', 'MiB');
	// 		return convertedValue;
	// 	} else if (convertedValue.search('GB') > -1) {
	// 		convertedValue = convertedValue.replace('GB', 'GiB');
	// 		return convertedValue;
	// 	} else if (convertedValue.search('TB') > -1) {
	// 		convertedValue = convertedValue.replace('TB', 'TiB');
	// 		return convertedValue;
	// 	} else if (convertedValue.search('PB') > -1) {
	// 		convertedValue = convertedValue.replace('PB', 'PiB');
	// 		return convertedValue;
	// 	} else {
	// 		return convertedValue;
	// 	}
	// };

	const options = {
		chart: {
			type: 'line',
			id: 'customerChart',
			styledMode: true
		},
		title: {
			text: isCustomerPortal ? "" : 'Customer Usage Trend',
			align: 'left'
		},
		credits: {
			enabled: false
		},
		xAxis: {
			labels: {
				formatter: function(y: any): string {
					var label = '';
					try {
						label = y.value.split(' ')[1].slice(0, 2);
						label = label.replace(/^0+/, '');

						if (periodicity === 'MONTHLY') {
							label = y.value.split(' ')[0].slice(0, 3);
						}
					} catch (e) {
						//console.error(e);
					}
					return label;
				},
				style: {
					fontSize: '9px'
				}
			},
			tickInterval: 1,
			categories: partnerXData,
			crosshair: {
				width: 0.5,
				color: '#a8a8a8'
			},
			title: ''
		},
		yAxis: {
			labels: {
				formatter: function(value: any): string {
					return convertFileSize(value.pos);
				},
				style: {
					fontSize: '9px'
				}
			},
			allowDecimals: false,
			gridLineDashStyle: 'longdash',
			crosshair: false,
			title: ''
		},
		legend: {
			layout: 'horizontal',
			align: 'center',
			verticalAlign: 'bottom',
			maxHeight: 55
		},

		plotOptions: {
			pointStart: 1,
			series: {
				marker: {
					enabled: false,
					symbol: 'circle',
					radius: 2,
					lineWidth: 1
				}
			}
		},

		series: customerData,

		tooltip: {
			shared: true,
			useHTML: true,

			formatter: function(): any {
				var value: any = this;
				var title: string = value.x;
				var month: string = '';
				var year: string = '';

				if (periodicity === 'MONTHLY') {
					month = title.split(' ')[0].slice(0, 3);
					year = title.split(' ')[2].slice(0, 4);
					title = month + ', ' + year;
				}
				var str: string =
					'<span style="color: #ffffff;"><b>' + title + '</b></span><br/><table style="margin-top: 10px;"> ';
				var points: any = value.points;
				points.map((obj: any) => {
					var colorcode = obj.color;

					str =
						str +
						'<tr style="color: #ffffff;"> <td><span style="color: ' +
						colorcode +
						';font-size:10px;">&#9673;</span>&nbsp;' +
						obj.series.name +
						': </td> <td> <b>' +
						convertFileSize(Number(obj.y)) +
						' </b></td></tr>';
				});

				str = str + '</table>';
				return str;
			},

			backgroundColor: 'black',
			borderWidth: 2,
			borderColor: 'black',
			style: {
				color: '#CCCCCC',
				opacity: 0.85
			}
		},

		responsive: {
			rules: [
				{
					condition: {
						maxWidth: 400,
						maxHeight: 400
					},
					chartOptions: {
						legend: {
							layout: 'horizontal',
							align: 'center',
							verticalAlign: 'bottom'
						}
					}
				}
			]
		}
	};

	return (
		<div>
			<HighchartsReact id="customerChart" highcharts={Highcharts} options={options} />
		</div>
	);
};

export default CustomerTrendChart;
