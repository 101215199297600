import React from "react";
import PaperCard from "./PaperCard";

interface IOwnProps {
  /** Label of the card */
  label: React.ReactNode;
  /** Value corresponding to label */
  value: React.ReactNode;
  /**
   * Available variant
   * @type {("primary" | "secondary" | "no-shadow")}
   */
  variant?: "primary" | "secondary" | "no-shadow" | string;
  /** Icon url */
  src?: string;
  /** To show the loading state */
  loading?: boolean;
  /** To replace the entire body of the card */
  children?: React.ReactNode;
  /**
   * Class names
   * @default ""
   */
  className?: string;
  hoverTitle?: string;
}

/**
 * A metric card variant consisting of label, value and an icon.
 * @return {*} React Component
 */
const MetricCard = ({
  label,
  value,
  variant = "primary",
  src,
  children,
  loading,
  className,
  hoverTitle
}: IOwnProps) => {
  const renderBody = () => {
    return (
      <div className="metric-details-container">
          <div className="metric-value-section">{value}</div>
        {src && (
          <div className="image">
            <img src={src} alt={`${label}-icon`} />
          </div>
        )}
      </div>
    );
  };

  return (
    <PaperCard
      loading={loading}
      className={`metric-card ${variant} ${className}`}
      header={<div className="label-section">{label}</div>}
      body={children || renderBody()}
      hoverTitle={hoverTitle}
    />
  );
};

export default MetricCard;
