export type ListUpdateObserver = (updated: boolean) => void;

class ListSubject {
    private observers: ListUpdateObserver[] = [];
    private intervalId = null;

    public attach(observer: ListUpdateObserver) {
        // console.log("Attached");
        this.observers.push(observer);
    }

    public detach(observer: ListUpdateObserver) {
        // console.log("Detached");
        this.observers = this.observers.filter(observerObj => observerObj !== observer )
    }

    private notify(updated: boolean) {
        this.observers.forEach(observer => observer(updated))
    }

    public updateList() {
        this.notify(true);
    }
}

const listSubject = new ListSubject();
export default listSubject;
