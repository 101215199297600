import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import imgSlide1 from "../../images/noTexts/landing-img-bg-1@2x.png";
import imgSlide2 from "../../images/noTexts/landing-img-bg-2@2x.png";
import imgSlide3 from "../../images/noTexts/landing-img-bg-3@2x.png";
import imgSlide4 from "../../images/noTexts/landing-img-bg-4@2x.png";
import { createUseStyles } from "react-jss";
import PartnerJoinMd from "./components/PartnerJoinMd";

const useStyles = createUseStyles({
  root: {
    height: "100vh",
    overflow: "auto",
  },
});

const LandingView = () => {
  const classes = useStyles();
  const [openPartnerJoinMd, setOpenPartnerJoinMd] = useState(false);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex);
  };

  const handleOpenPartnerJoinMd = () => {
    setOpenPartnerJoinMd(true);
  };

  const handleClosePartnerJoinMd = () => {
    setOpenPartnerJoinMd(false);
  };
  return (
    <div className={classes.root}>
      <div className="row no-gutter">
        <div className="col-12">
          <nav className="navbar navbar-expand floatHdr">
            <div className="col-1 col-sm-1">
              <div className="logoLanding float"></div>
            </div>
            <div className="col-1 col-sm-11 d-flex justify-content-end">
              <div className="d-flex justify-content-end">
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item">
                      <a className="nav-link disabled" href="#">
                        About LYVE <span className="sr-only">(current)</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link disabled" href="#">
                        Solutions
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/login">
                        Login
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="mailto:support.lyvecloud@seagate.com"
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="row no-gutter" style={{ marginTop: "-90px" }}>
        <div className="col-1 col-sm-12">
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            style={{ zIndex: 0 }}
          >
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={imgSlide1}
                alt="First slide"
              />
              <div className="carousel-caption">
                <div className="row d-flex align-content-center">
                  <div className="col-1 col-sm-12 col-md-12 col-lg-12">
                    <div className="bannerTexts">
                      <h5>Orchestration at its finest</h5>
                      <h2>Art of Cloud Storage</h2>
                      <h4>Committed to People, Committed to the Future.</h4>
                      {/* <button
                        className="btn btn-primary bordered glow"
                        style={{ marginTop: "30px" }}
                        onClick={handleOpenPartnerJoinMd}
                      >
                        Be Our Partner
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={imgSlide2}
                alt="Second slide"
              />
              <div className="carousel-caption">
                <div className="row">
                  <div className="col-1 col-sm-12 col-md-12 col-lg-12">
                    <div className="bannerTexts center">
                      <h5>
                        <span className="bigger">
                          Integrity with Excellence
                        </span>
                      </h5>
                      <h2>
                        <span className="smaller">More than just Security</span>
                      </h2>
                      <h4>
                        <span className="base">A Power That Saves You</span>
                      </h4>
                      {/* <button
                        className="btn btn-primary bordered glow"
                        style={{ marginTop: "30px" }}
                        onClick={handleOpenPartnerJoinMd}
                      >
                        Be Our Partner
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={imgSlide3}
                alt="Third slide"
              />
              <div className="carousel-caption">
                <div className="row">
                  <div className="col-1 col-sm-12 col-md-12 col-lg-12">
                    <div className="bannerTexts odd">
                      <h5>
                        <span className="bigger">Management at its best</span>
                      </h5>
                      <h2>
                        <span className="smaller">
                          Achieving All, In One Place
                        </span>
                      </h2>
                      <h4>
                        <span className="base">
                          Do it right and best way possible.
                        </span>
                      </h4>
                      {/* <button
                        className="btn btn-primary bordered glow"
                        style={{ marginTop: "30px" }}
                        onClick={handleOpenPartnerJoinMd}
                      >
                        Be Our Partner
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={imgSlide4}
                alt="Fourth slide"
              />
              <div className="carousel-caption">
                <div className="row">
                  <div className="col-1 col-sm-12 col-md-12 col-lg-12">
                    <div className="bannerTexts lastSlide">
                      <h5>
                        <span className="bigger drGray">
                          Beyond Your Expectation
                        </span>
                      </h5>
                      <h2>
                        <span className="primary smallest">
                          Soar Limitless.
                          <br />
                          Be Immeasurable.
                        </span>
                      </h2>
                      <h4>
                        <span className="base noShadow">
                          Nothing but boundless. Reach the world through LYVE.
                        </span>
                      </h4>
                      {/* <button
                        className="btn btn-primary bordered glow"
                        style={{ marginTop: "30px" }}
                        onClick={handleOpenPartnerJoinMd}
                      >
                        Be Our Partner
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>

      <div className="row no-gutter">
        <div className="col-12 col-sm-12">
          <div className="featuresFold landingText">
            <h2>Solutions Perfected For Your Demands</h2>
            <h5>
              Simple, reliable, and secure solutions for your cloud storage data
            </h5>
            <div className="featuresCtn">
              <div className="row">
                <div className="col-1 col-sm-4">
                  <div className="feature display block">
                    <div className="feature cloudCog"></div>
                    <div className="feature name">AI and ML as a Service</div>
                    <div className="feature intro">
                      <p>
                        High performance platform for intensive AI/ML workload
                        that enables business insight and transformation.
                      </p>
                    </div>
                    {/* <a href="solutions.html">
                      <div className="feature go"></div>
                    </a> */}
                  </div>
                </div>
                <div className="col-1 col-sm-4">
                  <div className="feature display block">
                    <div className="feature centralised"></div>
                    <div className="feature name">
                      Disaster Recovery As A Service
                    </div>
                    <div className="feature intro">
                      <p>
                        Highly reliable with secure and compliant storage-level
                        disaster recovery that guarantees service continuity{" "}
                      </p>
                    </div>
                    {/* <a href="solutions.html">
                      <div className="feature go"></div>
                    </a> */}
                  </div>
                </div>
                <div className="col-1 col-sm-4">
                  <div className="feature display block">
                    <div className="feature cloudStorage"></div>
                    <div className="feature name">
                      Backup and Archive As A Service
                    </div>
                    <div className="feature intro">
                      <p>
                        Highly available and durable data backup/archive at low
                        cost that benefits TCO and ROI
                      </p>
                    </div>
                    {/* <a href="solutions.html">
                      <div className="feature go"></div>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row no-gutter">
        <div className="col-12 col-sm-4">
          <div className="infoFold left">
            <h1>
              Your
              <br />
              <span className="green">Fastest</span>
              <br />
              Growing
              <br />
              Object
              <br />
              <span className="green">Storage</span>
              <br />
              <span className="green">System</span>
            </h1>
          </div>
        </div>

        <a id="aboutLyve"></a>
        <div className="col-12 col-sm-8">
          <div className="infoFold right">
            <div className="upperCtn">
              <h4>LyveCloud</h4>
              <p>
                provides a complete software-defined cloud storage platform that
                supports the storage, manipulation, and analysis of unstructured
                data on a massive scale on commodity hardware. The Lyvecloud
                platform offers several benefits:
              </p>

              <p>
                Hyperscale architecture that enables multi-data center expansion
                through federation
              </p>
              <ul>
                <li>
                  High performance object store to serve the most demanding
                  cloud-native workloads
                </li>
                <li>
                  Ease of use with non-disruptive upgrades, no tuning knobs and
                  simple support requirements
                </li>
                <li>
                  High availability so objects continue to be available despite
                  multiple disk and node failures
                </li>
                <li>
                  Security-enabled storage by encrypting each object with a
                  unique key
                </li>
              </ul>
              {/* <button
                className="btn btn-base lg"
                data-toggle="modal"
                data-target="#modalBeOurPartner"
                onClick={handleOpenPartnerJoinMd}
              >
                Be Our Partner
              </button> */}
            </div>
          </div>
        </div>
      </div>

      {/* <a id="contactUs"></a> */}
      <div className="row no-gutter" style={{ backgroundColor: "#1a1a1a" }}>
        <div className="col-12 col-sm-12">
          <div className="contactUs">
            {/* <div className="row no-gutter">
              <div className="col-12 col-sm-6">
                <div className="leftCtn">
                  <h4>Send Us Your Inquiry?</h4>
                  <p>
                    If you have any inquiry/feedback/concern, please don't
                    hesitate to contact us. You can feel up the form, send us
                    direct email or reach us by phone.
                  </p>
                  <p>
                    We hope to here from you and be your partner to achieve your
                    goal successfully.
                  </p>

                  <h4>Or Send Us Direct Email</h4>
                  <p>lyvecloud.support@seagate.com</p>

                  <h4>Or Phone Us</h4>
                  <p>(+65) 1234 5678</p>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="rightCtn">
                  <form>
                    <div className="form-group">
                      <span className="formLabel">Name</span>
                      <input
                        type="email"
                        className="form-control is-valid"
                        placeholder="Insert Your Email Address"
                      />
                    </div>
                    <div className="form-group">
                      <span className="formLabel">Email Address</span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Inquiry Subject"
                      />
                    </div>
                    <div className="form-group">
                      <span className="formLabel">Message</span>
                      <textarea
                        className="form-control"
                        placeholder="Subject"
                      ></textarea>
                    </div>
                    <button type="submit" className="btn btn-base">
                      Send Inquiry
                    </button>
                  </form>
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="footerCtn">
                <div className="row">
                  <div className="col-1 col-sm-1">
                    <div className="footerLogo"></div>
                  </div>
                  <div className="col-1 col-sm-9">
                    <div className="footerNav">
                      <ul className="footerList">
                        <li className="nav-item">
                          <a className="nav-link" href="#aboutLyve">
                            About LYVE{" "}
                            <span className="sr-only">(current)</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="solutions.html">
                            Solutions
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="/login">
                            Login
                          </a>
                        </li>
                        <li className="nav-item">
                          {/* <a
                            className="nav-link"
                            href="#"
                            data-toggle="modal"
                            data-target="#modalBeOurPartner"
                            onClick={handleOpenPartnerJoinMd}
                          >
                            Be Our Partner
                          </a> */}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-1 col-sm-2">
                    {/* <button
                      className="btn btn-base soft"
                      onClick={handleOpenPartnerJoinMd}
                    >
                      Be Our Partner
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
            <PartnerJoinMd
              show={openPartnerJoinMd}
              handleClose={handleClosePartnerJoinMd}
            />
            {/* <div
              className="modal fade partnerModal"
              id="modalBeOurPartner"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog wider" role="document">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="leftModal">
                      <h6>
                        Welcome!
                        <br />
                        Feed us some details
                      </h6>
                      <h5>We are more than just storage solution</h5>
                      <p>
                        We provide not just what you need but what you'll love
                        the most. We offer services that is vital for your
                        business and your everyday life.
                      </p>

                      <p>
                        By providing us your details, you will know more about
                        our awesome service.
                      </p>
                    </div>

                    <div className="rightModal">
                      <form>
                        <div className="form-group">
                          <span className="modalLabel">
                            Full Name *
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                          />
                        </div>
                        <div className="form-group">
                          <span className="modalLabel">
                            Email *
                          </span>
                          <input
                            type="email"
                            className="form-control"
                            placeholder=""
                          />
                        </div>
                        <div className="form-group">
                          <span className="modalLabel">
                            Company Name
                          </span>
                          <input
                            type="email"
                            className="form-control"
                            placeholder=""
                          />
                        </div>
                        <button
                          type="button"
                          className="btn btn-success btnBaseColor"
                          style={{ width: "100%" }}
                        >
                          Be A Partner
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingView;
