import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../store";
import { SliceState, StoreStateStatus } from "./types";

export interface StartHereBucket {
    bucketName: string,
    serviceAccountName: string,
    serviceAccountId: string,
    permissionName: string,
    permissionId: string
}

type State = SliceState<StartHereBucket[]>;

const initialState: State = {
    status: StoreStateStatus.IDLE,
    error: null,
    data: []
};

const startHereBucketsSlice = createSlice({
    name: "startherebucket",
    initialState,
    reducers: {
        resolveStartHereBucket: (state, action: PayloadAction<StartHereBucket[]>) => {
            state.status = StoreStateStatus.RESOLVED;
            state.data = action.payload || [];
        },
        rejectStartHereBucket: (state, action: PayloadAction<Error>) => {
            state.status = StoreStateStatus.REJECTED;
            state.error = action.payload;
        }
    }
});

export const {
    resolveStartHereBucket,
    rejectStartHereBucket
} = startHereBucketsSlice.actions;
export default startHereBucketsSlice.reducer;

export const selectStartHereBuckets = (state: AppState) => state.startherebucket;
