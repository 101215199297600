import { useCallback, useState } from "react";
import api from "../../../../../services/api";

export enum UsageFrequency {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  ANNUALLY = "ANNUALLY"
}

export interface IUsageQuery {
  from_date: string;
  to_date: string;
  frequency: UsageFrequency;
  timezone?: string;
}

interface IUseUsageMetric {
  onSuccess?: (data: any) => void;
  onError?: (data: any) => void;
}

const useUsageMetric = ({ onSuccess, onError }: IUseUsageMetric) => {
  const [metricsLoading, setMetricsLoading] = useState<boolean>(false);
  const [usageLoading, setUsageLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  const getUsageMetric = async (
    query: IUsageQuery,
    loadMonthToDateUsage: boolean = true
  ) => {
    setMetricsLoading(true);
    if (loadMonthToDateUsage) {
      setUsageLoading(true);
    }
    try {
      const res = await api.getMetrics(query);
      setMetricsLoading(false);
      if (loadMonthToDateUsage) {
        setUsageLoading(false);
      }
      setData(res);
      onSuccess?.(res);
    } catch (err) {
      setMetricsLoading(false);
      if (loadMonthToDateUsage) {
        setUsageLoading(false);
      }
      setError(err);
      onError?.(err);
    }
  };

  return {
    data,
    error,
    usageLoading,
    metricsLoading,
    getUsageMetric
  };
};

export default useUsageMetric;
