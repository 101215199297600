import React from "react";
import { CircleFill } from "react-bootstrap-icons";
import cx from "classnames";

interface IOwnProps {
  /** Placement of the dot */
  placement?: "left" | "right";
  /** Color of the dot */
  color?: string;
  /** Size of dot in px */
  size?: number;
  /** Children */
  children?: React.ReactNode;
  /** Classname */
  className?: string;
}

/**
 * The Custom badge component
 * @returns {*} React Component
 */
const LyveStatusDot = ({
  placement,
  color,
  children,
  className,
  size,
  ...props
}: IOwnProps) => {
  return (
    <div {...props} className={cx("lyve-status-dot", className)}>
      <CircleFill color={color} size={size} style={{ marginRight: 8 }} />
      {children}
    </div>
  );
};

export default LyveStatusDot;
