import React from "react";
import {useHistory, useLocation} from "react-router-dom";
import BaseCard from "../BaseCard";
import {AddBucketCardProps, CardType} from "../../../scenes/SmartStartHere/types.d";
import {DefaultButton} from "../../LyveButton";
import {ChevronRight} from "react-bootstrap-icons";
import PaperCard from "../../Card/PaperCard";
import cloudBucketImg from "../../../images/add-bucket-bg.png";

import "./styles.scss";
import Modal from "react-bootstrap/Modal";
import useSmartStartKit from "../../../scenes/SmartStartHere/useSmartStartKit";
import useAddBucketCard, {BucketResource, NewBucket} from "./AddBucketContext";
import cx from "classnames";
import BucketAnimation from "./BucketAnimation";
import AddBucketModalOverlay from "./AddBucketModalOverlay";
import api from "../../../services/api";
import useGlobalContext from "../../../state/GlobalStateContext";
import {fetchBuckets} from "../helpers";
import {deleteBucketResources, fetchRegions} from "../../../utils/api";

const initialBucket: NewBucket = {
  name: "",
  phase: "preparation",
  info: [],
  region: null,
  error: null,
  downloaded: false
};

const AddBucketCard = (props: AddBucketCardProps) => {
  const history = useHistory();
  const location = useLocation();
  const resourceItems = localStorage.getItem("createdResources");

  const {
    processBuckets,
    setProcessBuckets,
    shouldCelebrate,
    setShouldCelebrate
  } = useGlobalContext();
  const { toggleShowSmartStartKit, setUserConfig } = useSmartStartKit();
  const {
    newBucket,
    setNewBucket,
    activeStep,
    setActiveStep,
    createdResources,
    resetResources
  } = useAddBucketCard();
  const { bucket, permission, serviceAccount } = createdResources;
  const [rollback, setRollback] = React.useState(false);
  const [runId, setRunId] = React.useState("0");
  const [showCreateBucketModal, setShowCreateBucketModal] = React.useState(
    false
  );
  const [
    createBucketModalBackdrop,
    setCreateBucketModalBackdrop
  ] = React.useState(false);

  const createBucket = (event: React.MouseEvent<Element, MouseEvent>) => {
    toggleShowSmartStartKit?.();
    setShowCreateBucketModal(true);
    const target = event.target as HTMLButtonElement;
    target.blur();
  };

  const resetBucketCreation = () => setNewBucket(initialBucket);

  const navigateToBucketsPage = async () => {
    if (location.pathname !== "/customer/buckets") {
      history.push("/customer/buckets");
    } else {
      await fetchBuckets();
    }
  };
  const setClosingRunId = () => {
    getRunId();
  }

  const toggleCreateBucketModal = (rollbackBucketCreation = false) => {
    toggleShowSmartStartKit?.();
    setActiveStep({
      number: 0,
      state: "wip"
    });
    resetBucketCreation();

    if (!rollbackBucketCreation && newBucket.phase !== "preparation") {
      resetResources();
      navigateToBucketsPage();
      setRollback(false);
      if (newBucket.phase === "celebration") {
        props.removeCard(CardType.ADD_BUCKET)
        setShouldCelebrate(true);
      }
    } else {
      setRollback(true);
      setShouldCelebrate(false);
    }
    setShowCreateBucketModal(!showCreateBucketModal);
  };

  const resetStates = () => {
    resetResources();
    setRollback(false);
    setShouldCelebrate(false);
    setProcessBuckets(false);
  };

  const getRunId = async () => {
    try {
      await api
          .getBucketCounter()
          .then(res => {
            const runid = res?.number.toString();
            setRunId(runid);
          })
    } catch (e) {
      console.log(e)
    }
  }

  React.useEffect(() => {
    getRunId();
  },[]);
  React.useEffect(() => {
    fetchRegions();
  },[]);

  // const deleteBucket = async (bucket: BucketResource, resetParams: boolean) => {
  //   const res = await deleteBucketWithURL(bucket.name, bucket.regionUrl);
  //   console.log("res", res);
  //   if ("name" in res) {
  //     api.getSmartStartConfig().then(setUserConfig);
  //     resetResources();
  //     if (resetParams) {
  //       setRollback(false);
  //       setShouldCelebrate(false);
  //     }
  //     setProcessBuckets(false);
  //     dispatch(removeBucket(bucket.name));
  //   }
  // };

  const deleteResources = async (
    bucket: BucketResource | null,
    permissionName?: string | null,
    serviceAccountName?: string,
    resetParams = false
  ) => {
    // if (bucket && bucket.regionUrl) {
    //   const res = await deleteBucketResources(bucket.regionUrl, {
    //     bucketName: bucket?.name,
    //     permission: permissionName,
    //     serviceAccount: serviceAccountName
    //   })
    //   if (res?.message) {
    //     api.getSmartStartConfig().then(setUserConfig);
    //     resetResources();
    //     if (resetParams) {
    //       setRollback(false);
    //       setShouldCelebrate(false);
    //     }
    //     setProcessBuckets(false);
    //     //dispatch(removeBucket(bucket.name));
    //   }
    // }
    setRollback(false);
    setProcessBuckets(false);
  };

  React.useEffect(() => {
    if (rollback) {
      if (
        bucket.status === "resolved" ||
        permission.status === "resolved" ||
        serviceAccount.status === "resolved"
      ) {
        setProcessBuckets(true);
        deleteResources(
          bucket.data,
          permission.data?.name,
          serviceAccount.data?.name,
          true,
        );
      }
      if (
        (bucket.status === "rejected" ||
          permission.status === "rejected" ||
          serviceAccount.status === "rejected") &&
        bucket.status !== "resolved" &&
        permission.status !== "resolved" &&
        serviceAccount.status !== "resolved"
      ) {
        resetStates();
      }
    }
  }, [rollback, bucket.status, permission.status, serviceAccount.status]);

  React.useEffect(() => {
    if (resourceItems) {
      const resources = JSON.parse(resourceItems);
      setProcessBuckets(true);
      deleteResources(
        resources.bucket,
        resources.permissonName,
        resources.serviceAccountName,
        false
      ).finally(() => {
        localStorage.removeItem("createdResources");
        setProcessBuckets(false);
      });
    }
  }, [resourceItems]);

  const navigateToBuckets = () => history.push("/customer/buckets");

  return (
    <>
      <Modal
        show={showCreateBucketModal}
        backdrop="static"
        centered={true}
        dialogClassName="add-bucket-card-modal"
        className={cx(
          createBucketModalBackdrop && "add-bucket-card-modal-backdrop"
        )}
      >
        <Modal.Body>
          <PaperCard
            className="add-bucket-card"
            src={cloudBucketImg}
            imgOverlay={
              <AddBucketModalOverlay
                setModalBackdrop={setCreateBucketModalBackdrop}
                toggleCreateBucketModal={toggleCreateBucketModal}
                accountName={props.accountName}
                setRollback={setRollback}
                runId={runId}
                setRunId={setClosingRunId}
              />
            }
          />
          {newBucket.phase === "creation" &&
            !(activeStep.number === 2 && activeStep.state === "done") && (
              <BucketAnimation />
            )}
        </Modal.Body>
      </Modal>

      <BaseCard
        {...props}
        title="Create your first bucket"
        description={
          shouldCelebrate ? (
            <>
              <div className="bucket-celebration-title"> Congratulations! </div>
              <div className="bucket-celebration-subtitle">
                You successfully created your first bucket.
              </div>
            </>
          ) : (
            "Create a bucket and start using it in minutes."
          )
        }
        body={
          <div className="d-flex flex-column">
            <div className="d-flex pb-2">
              {!shouldCelebrate && (
                <DefaultButton
                  variant="outline-secondary"
                  className="base-card-btn"
                  onClick={createBucket}
                  disabled={processBuckets}
                >
                  Create Bucket
                </DefaultButton>
              )}
              <button className="base-card-link" onClick={navigateToBuckets}>
                <span className="mr-1"> Buckets </span>
                <ChevronRight size={10} />
              </button>
            </div>
            {processBuckets && (
              <div className="base-card-loading">
                Cleaning up the recent bucket creation process
              </div>
            )}
          </div>
        }
      />
    </>
  );
};

export default AddBucketCard;
