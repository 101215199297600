import { EvalData } from "./types";

export const SAVE_EVAL_INFO = "SAVE_EVAL_INFO";
export const FETCHING_EVAL_INFO = "FETCHING_EVAL_INFO";
export const ERROR_EVAL_INFO = "ERROR_EVAL_INFO";

export const saveEvalInfo = (customer: EvalData | null) => ({
  type: SAVE_EVAL_INFO,
  payload: customer
});

export const fetchingEvalInfo = () => ({
  type: FETCHING_EVAL_INFO
});

export const errorEvalInfo = (error: any) => ({
  type: ERROR_EVAL_INFO,
  payload: error
});
