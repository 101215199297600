import React from "react";
import { Dropdown, DropdownProps } from "react-bootstrap";
import Can from "../Can";

import "./styles.scss";

type ContextMenuOption = {
  label: React.ReactNode;
  onClick: (e?: React.MouseEvent, data?: any) => void;
  active?: boolean;
  hasDivider?: boolean;
  action?: string;
  disabled?: boolean;
  header?: string | JSX.Element;
};

type ContextMenuProps = {
  trigger?: React.ReactNode;
  id: string;
  options: ContextMenuOption[];
  className?: string;
  dropdownClass?: string;
  minWidth?: string;
  width?: string;
  data?: any;
  parentDropdownClass?: string;
  hasManualToggle?: boolean;
  manualShow?: boolean;
  disabled?: boolean;
} & DropdownProps;

const ContextMenu: React.FunctionComponent<ContextMenuProps> = ({
  trigger,
  id,
  options,
  className,
  data,
  drop,
  alignRight,
  dropdownClass,
  minWidth,
  onToggle,
  width,
  parentDropdownClass,
  hasManualToggle,
  manualShow,
  disabled
}) => {
  const renderMenuItem = (option: ContextMenuOption, index: number) => {
    return (
      <React.Fragment key={Math.random() + id + index}>
        {option.hasDivider && <Dropdown.Divider />}
        {option.header ? (
          <Dropdown.Header>{option.header}</Dropdown.Header>
        ) : null}
        <Dropdown.Item
          as="span"
          active={option.active}
          disabled={option.disabled}
          key={`context-menu-option-${index}`}
          //@ts-ignore
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            if (option.onClick) {
              option.onClick(e, data);
            }
          }}
          className={`${dropdownClass} c-pointer`}
        >
          {option.label}
        </Dropdown.Item>
      </React.Fragment>
    );
  };
  return (
    <Dropdown
      className={`float-right ${parentDropdownClass || ""}`}
      drop={drop}
      alignRight={alignRight}
      onToggle={onToggle}
      {...(hasManualToggle ? { show: manualShow } : {})}
    >
      <Dropdown.Toggle id={id} as={"div"}>
        {trigger}
      </Dropdown.Toggle>

      <Dropdown.Menu
        className={`${className || ""}`}
        style={{ minWidth, width }}
      >
        {options.map((option, index) =>
          option.action ? (
            <Can key={index} perform={option.action}>
              {renderMenuItem(option, index)}
            </Can>
          ) : (
            renderMenuItem(option, index)
          )
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

ContextMenu.defaultProps = {
  alignRight: true,
  minWidth: "150px",
  parentDropdownClass: "",
  width: ""
};

export default ContextMenu;
