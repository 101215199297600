import { CLEAR_MONTHRANGE, MonthItemActionTypes, SET_MONTHITEMS, SET_MONTHRANGE } from './actions';
import { MonthPickerState } from './types';

const initialState: MonthPickerState = {
    items: [],
    selectedDateRange: {}
}

export function reducer(
    state = initialState,
    action: MonthItemActionTypes
): MonthPickerState {
    switch (action.type) {
        case SET_MONTHRANGE:
            return {
                ...state,
                items: state.items,
                selectedDateRange: action.payload
            };
        case CLEAR_MONTHRANGE:
            let newItems = [...state.items];
            newItems = newItems.map(item => {
                item.selected = false
                return item
            });
            return {
                ...state,
                items: newItems,
                selectedDateRange: { startDate: undefined, endDate: undefined }
            };

        case SET_MONTHITEMS:
            return {
                ...state,
                items: action.payload,
                selectedDateRange: state.selectedDateRange
            };
        default:
            return state
    }
}