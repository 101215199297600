import React from "react";
import BaseCard from "./BaseCard";
import { CardProps } from "../../scenes/SmartStartHere/types.d";
import { DefaultButton } from "../LyveButton";
import { ChevronRight } from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";
import useSmartStartKit from "../../scenes/SmartStartHere/useSmartStartKit";

const AddUserCard = (props: CardProps) => {
  const history = useHistory();
  const { toggleShowSmartStartKit, fromLocation } = useSmartStartKit();

  const navigateToAddUserPage = (event: React.MouseEvent<Element, MouseEvent>) => {
    toggleShowSmartStartKit?.();
    fromLocation.current = "add-user-card";
    const target = event.target as HTMLButtonElement
    target.blur()
    history.push("/customer/users");
  };

  const navigateToUsers = () => history.push("/customer/users")

  return (
    <BaseCard
      {...props}
      title="Add new user"
      description="Add new users to your Lyve Cloud account."
      body={
        <div className="d-flex">
          <DefaultButton
            variant="outline-secondary"
            className="base-card-btn"
            onClick={navigateToAddUserPage}
          >
            Add User
          </DefaultButton>
          <button className="base-card-link" onClick={navigateToUsers}>
            <span className="mr-1"> Users </span>
            <ChevronRight size={10} />
          </button>
        </div>
      }
    />
  );
};

export default AddUserCard;
