import React from "react";

export enum CardType {
  VIDEO = "video",
  ADD_BUCKET = "add-bucket",
  ADD_USER = "add-user",
  STHREE_CLIENTS = "sthree-clients",
  RESOURCES = "useful-resources",
  CONTACT_US = "contact-us",
  SUPPORT = "support"
}

export enum Roles {
  ADMIN = "admin",
  STORAGE_ADMIN = "storage-admin",
  AUDITOR = "auditor"
}

export interface SystemConfig {
  hasCreatedBucket?: boolean;
  hasCreatedUser?: boolean;
  trialPeriod?: number;
}

export declare interface RenderCardProps {
  cards: CardConfig[];
  email: string;
  accountName: string
  removeCard: (cardType: CardType) => void;
}

export declare interface CardBasicProps {
  id: number;
  type: CardType;
  dismissible: boolean;
  supportedRole: Roles[];
}

export declare interface CardProps extends CardBasicProps {
  toggleUserStarterKit?: () => void
  email?: string;
  removeCard: (cardType: CardType) => void;
}

export declare interface AddBucketCardProps extends CardProps {
  accountName: string
}

export declare interface CardConfig extends CardBasicProps {
  width: number;
  priority: number;
  component: React.FC<any>;
  relativePriority?: number
}

export enum CardAction {
  SET = "set",
  REMOVE = "remove",
  CHANGE_PRIORITY = "changePriority"
}

export declare interface Payload {
  type: CardAction;
}

export declare interface CardPayload extends Payload {
  cardID?: number;
  cardType?: CardType
  cardNewPriority?: number;
  cards?: CardConfig[];
}

export declare interface ClosedCard {
  cardName: string
  status: "open" | "closed"
}

export declare interface SmartStartUserConfig {
  ownedBuckets: number;
  allBuckets: number
  createdUsers: number;
  closedCards: CardType[];
  autoExpandOnLogin: boolean
  movedToProfileDropdown: boolean
}
