import React, { isValidElement } from "react";
import LyvePopup from "../../../../../components/Modal/LyvePopup";
import { DefaultButton } from "../../../../../components/LyveButton";
import { Form, Spinner } from "react-bootstrap";
import Input from "../../../../../components/Input";
import api from "../../../../../services/api";
import {
  setErrorNotification,
  setNotification
} from "../../../../../components/Notification/actions";
import { SubAccount } from "./View/components/Settings";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../../../../store";

declare interface DeleteSubAccountProps {
  subaccount: SubAccount;
}

const DeleteSubAccount: React.FC<DeleteSubAccountProps> = ({
  subaccount,
  children
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [show, setShow] = React.useState(false);
  const toggleShow = () => setShow(!show);

  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const toggleConfirmation = () => setIsConfirmed(!isConfirmed);

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [accName, setAccName] = React.useState("");
  const resetAccNameChange = () => setAccName("");
  const handleAccNameChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setAccName(event.currentTarget?.value);

  const deleteSubAccount = () => {
    setIsSubmitting(true);
    api
      .deleteSubAccount(subaccount.short_name)
      .then(() => {
        setNotification("Sub-account has been deleted.")(dispatch);
        history.push("/customer/subaccounts");
      })
      .catch(() =>
        setErrorNotification("Something went wrong, Please try later.")(
          dispatch
        )
      )
      .finally(() => {
        toggleShow();
        toggleConfirmation();
        resetAccNameChange();
        setIsSubmitting(false);
      });
  };

  const handleConfirmation = () => {
    if (isConfirmed) {
      deleteSubAccount();
    } else {
      toggleConfirmation();
    }
  };

  const cancelConfirmation = () => {
    if (isConfirmed) {
      toggleShow();
      toggleConfirmation();
      resetAccNameChange();
    } else {
      toggleShow();
    }
  };

  const enableConfirmation =
    (show && !isConfirmed) || (!!accName && accName === subaccount.name);

  const child = React.Children.only(children);

  return (
    <>
      {isValidElement(child) &&
        React.cloneElement(child, { onClick: toggleShow })}
      <LyvePopup
        header
        show={show}
        onHide={cancelConfirmation}
        title="Delete Sub-account"
        baseProps={{ dialogClassName: "subaccount-action-modal" }}
        subtitle={isConfirmed ? "" : "Are you sure you want to delete this sub-account?"}
        footer={
          <div>
            <DefaultButton
              variant="danger"
              className="confirm-action-btn"
              onClick={handleConfirmation}
              disabled={!enableConfirmation}
              loading={isSubmitting}
            >
              {isConfirmed ? "Confirm" : "Yes"}
            </DefaultButton>
            <DefaultButton
              variant="outline-secondary"
              onClick={cancelConfirmation}
            >
              {isConfirmed ? "Cancel" : "No"}
            </DefaultButton>
          </div>
        }
      >
        {isConfirmed ? (
          <Form>
            <Input
              controlId="delete-account-name"
              label={`Enter account name "${subaccount.name}" below to confirm`}
              onChange={handleAccNameChange}
            />
          </Form>
        ) : (
          <div>
            <div className="acc-name"> Account Name </div>
            <div className="acc-value"> {subaccount.name} </div>
          </div>
        )}
      </LyvePopup>
    </>
  );
};

export default DeleteSubAccount;
