import React from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

interface Props {
  retentionDuration: number;
  handleRetentionValueChange: (e: any) => void;
  retentionUnit: string;
  handleRetentionUnitChange: (e: any) => void;
}

const isValid = (value: number) => {
  const pattern = /^\d+$/;
  const isNumeric = pattern.test(value.toString());
  if (isNumeric) {
    return value > 0; // Only positive integers
  } else {
    return false;
  }
};

const index: React.FC<Props> = ({
  retentionDuration,
  handleRetentionValueChange,
  retentionUnit,
  handleRetentionUnitChange
}) => {
  return (
    <div>
      <InputGroup className="retention-values">
        <Form.Group controlId="validationFormik103" style={{ width: "50%" }}>
          <Form.Control
            type="text"
            name="retentionDuration"
            value={retentionDuration.toString()}
            onChange={handleRetentionValueChange}
          />
        </Form.Group>

        <Form.Control
          as="select"
          name="retentionUnit"
          onChange={handleRetentionUnitChange}
          value={retentionUnit}
        >
          <option value="Days">Day(s)</option>
          <option value="Years">Year(s)</option>
        </Form.Control>
      </InputGroup>
      <span className=" retention-duration-error">
        Only whole positive numbers allowed. (100 Years / 36500 Days max)
      </span>
    </div>
  );
};

export default index;
