import React from "react";
import {
  BucketFill,
  Check2Square,
  Key,
  ShieldCheck
} from "react-bootstrap-icons";
import { Spinner } from "react-bootstrap";
import cx from "classnames";
import { DefaultButton } from "../../../LyveButton";
import useAddBucketCard from "../AddBucketContext";
import { Pair } from "../CelebrateBucketCreation";

import "../bucket-animation-styles.scss";
import { useResources } from "./useResources";

declare type CreateBucketProps = {
  accountName: string;
  runId: string;
  setRollback: (rollback: boolean) => void;
  isRetry: boolean;
};

type State = "default" | "wip" | "done";

export type Step = {
  number: number;
  state: State;
};

type StepProps = {
  stepNumber: number;
  icon: React.ReactNode;
  title: string;
  activeStep: Step;
};

const stepsInfo = [
  {
    done: "The bucket was created successfully.",
    wip: {
      primary: "Creating the bucket…",
      secondary: (
        <>
          <div className="pb-1">Choosing the bucket location.</div>
          <div>Setting up bucket attributes.</div>
        </>
      )
    }
  },
  {
    done: "The permission was created successfully.",
    wip: {
      primary: "Adding bucket permission…",
      secondary: "Allowing read and write access to your first bucket."
    }
  },
  {
    done: "The service account was created successfully.",
    wip: {
      primary: "Generating a service account…",
      secondary: "Service accounts allow applications to access buckets via API"
    }
  }
];

const getStepState = (activeStep: Step, stepNumber: number) => {
  if (activeStep.number === stepNumber) {
    return activeStep.state;
  } else if (activeStep.number > stepNumber) {
    return "done";
  }
  return "default";
};

const Step: React.FC<StepProps> = props => {
  const state = getStepState(props.activeStep, props.stepNumber);

  const Icon = () =>
    state === "wip" ? (
      <Spinner animation="border" className="step-spinner" />
    ) : state === "done" ? (
      <Check2Square color="var(--green-3)" size={32} />
    ) : (
      <>{props.icon}</>
    );

  return (
    <div
      className={cx(
        "step",
        `step-${state}`,
        props.activeStep.number === 0 &&
          props.activeStep.state === "wip" &&
          "step-animation"
      )}
    >
      <div className="icon-container">
        <Icon />
      </div>
      <div className="step-state">
        <div> STEP {props.stepNumber + 1} </div>
        <div> {props.title} </div>
      </div>
    </div>
  );
};

const CreateBucket: React.FC<CreateBucketProps> = props => {
  const {
    activeStep,
    setActiveStep,
    newBucket,
    setNewBucket,
    createdResources,
    isFinalStepInProgress,
    setIsFinalStepInProgress,
  } = useAddBucketCard();
  const { name: bucketName, region } = newBucket;
  const {
    startHereBucket,
    bucket,
    permission,
    serviceAccount
  } = createdResources;
  let windowObj: any = window;

  const isMountedRef = React.useRef(true);
  const { current: isMounted } = isMountedRef;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [finishLoading, setFinishLoading] = React.useState<boolean>(false);

  const info = React.useRef<Error | Pair[]>([]);

  // const { createBucket, createPermission, createServiceAccount } = useResourceCreation(
  const { createBucket } = useResources({
    info,
    accountName: props.accountName,
    runId: props.runId,
    bucketName,
    region
  });

  const moveOneStep = () => {
    setActiveStep({ number: activeStep.number + 1, state: "wip" });
  };

  const areResourcesWaiting =
    bucket.status === "idle" ||
    bucket.status === "loading" ||
    permission.status === "idle" ||
    permission.status === "loading" ||
    serviceAccount.status === "idle" ||
    serviceAccount.status === "loading";

  const shouldNotFinishLastStep =
    activeStep.number === 2 &&
    activeStep.state === "wip" &&
    areResourcesWaiting;

  const finishCurrentStep = () => {
    if (isMounted) {
      if (activeStep.number === 2 || !shouldNotFinishLastStep) {
        setActiveStep({ ...activeStep, state: "done" });
      }
    }
  };

  const processBucket = (data: Pair[] | Error) => {
    if (data instanceof Array) {
      setNewBucket({ ...newBucket, phase: "celebration", info: data });
    } else {
      setNewBucket({ ...newBucket, phase: "error", error: data });
    }
    info.current = [];
  };

  const handleNextStep = async () => {
    if (activeStep.number === 2) {
      setIsFinalStepInProgress(true);
      await createResources();
      if (info.current instanceof Array && info.current.length > 0) {
        processBucket(info.current);
        info.current = [];
      } else {
        processBucket(new Error());
      }
      setIsFinalStepInProgress(false);
    } else {
      moveOneStep();
    }
  };

  const createResources = async () => {
    props.setRollback(false);
    let bucketName = "";
    let permissionId = "";
    let account = null;
    setIsLoading(true);
    setFinishLoading(true);
    try {
      if (!bucket.data || props.isRetry) {
        account = await createBucket(windowObj);
        // if (bucketName) {
        //   permissionId = await createPermission(windowObj);
        //   if (permissionId) {
        //     account = await createServiceAccount(permissionId, windowObj);
        //   }
        // }
      }
      // else if (!permission.data) {
      //   permissionId = await createPermission(windowObj);
      //   if (permissionId) {
      //     account = await createServiceAccount(permissionId, windowObj);
      //   }
      // } else if (!serviceAccount.data) {
      //   account = await createServiceAccount(permission.data.id, windowObj);
      // }
      //account = account || serviceAccount.data;
      if (account?.name) {
        info.current = [
          {
            key: "name",
            name: "Service account name",
            value: account?.name
          },
          {
            key: "s3url",
            name: "S3 URL",
            value: `https://${account?.s3Url}`
          },
          {
            key: "accessKey",
            name: "Access key",
            value: account?.accessKey
          },
          {
            key: "secretKey",
            name: "Secret key",
            value: account?.secretKey
          }
        ];
        setIsLoading(false);
      }
      setFinishLoading(false);
    } catch (e) {
      setIsLoading(false);
      setFinishLoading(false);
      info.current = new Error(e);
    }
  };

  const stopBucketCreationProcess = async (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = "";
  };

  const unload = () => {
    if (windowObj.bucketName || windowObj.permissionId || windowObj.accountId) {
      localStorage.setItem(
        "createdResources",
        JSON.stringify({
          bucket: windowObj.bucket,
          permissionName: windowObj.permissionName,
          serviceAccountName: windowObj.serviceAccountName
        })
      );
    }
  };

  React.useEffect(() => {
    window.addEventListener("beforeunload", stopBucketCreationProcess);
    window.addEventListener("unload", unload);
    //createResources();
    return () => {
      window.removeEventListener("beforeunload", stopBucketCreationProcess);
      window.removeEventListener("unload", unload);
      isMountedRef.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (activeStep.state === "wip" && activeStep.number <= 2) {
      const stepTimeout = setTimeout(finishCurrentStep, 2000);
      return () => {
        if (stepTimeout) {
          clearTimeout(stepTimeout);
        }
      };
    }
  }, [activeStep.number, shouldNotFinishLastStep, info.current]);

  const RenderActiveStep = () => {
    const stepInfo = stepsInfo[activeStep.number];
    if (activeStep.state === "wip") {
      return (
        <>
          <div className="info-primary"> {stepInfo.wip.primary} </div>
          <div className="info-secondary"> {stepInfo.wip.secondary} </div>
        </>
      );
    }
    return (
      <>
        <div className="info-primary">
          {" "}
          {isLoading ? "Getting bucket details..." : stepInfo.done}{" "}
        </div>
        <div className="info-secondary">
          {activeStep.number === 2 ? "Click Finish" : "Click Next to continue"}.
        </div>
        {isLoading && (
            <DefaultButton
                className="next-btn"
                variant="dark"
                onClick={handleNextStep}
                disabled={isLoading || finishLoading}
            >
              <Spinner animation="border" className="step-spinner" />
            </DefaultButton>
        )}
        {!isLoading && (
            <DefaultButton
                className="next-btn"
                variant="dark"
                onClick={handleNextStep}
                disabled={isLoading || finishLoading}
            >
              {activeStep.number === 2 ? "Finish" : "Next"}
            </DefaultButton>
        )}
      </>
    );
  };

  return (
    <>
      <div className="d-flex process-bucket">
        <Step
          stepNumber={0}
          icon={<BucketFill size={16} />}
          title="Storage Bucket"
          activeStep={activeStep}
        />
        <Step
          stepNumber={1}
          icon={<ShieldCheck size={16} />}
          title="Permission"
          activeStep={activeStep}
        />
        <Step
          stepNumber={2}
          icon={<Key size={16} />}
          title="Service Account"
          activeStep={activeStep}
        />
      </div>
      <div className="d-flex justify-content-between">
        <div
          className={cx(
            activeStep.number === 0 &&
              activeStep.state === "wip" &&
              "step-text-animation"
          )}
        >
          <div
            className={cx(
              "divider",
              activeStep.number === 0 &&
                activeStep.state === "wip" &&
                "divider-animation"
            )}
          />
          <RenderActiveStep />
        </div>
      </div>
    </>
  );
};

export default CreateBucket;
