import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { Placement } from "react-bootstrap/Overlay";

import "./styles.scss";
import cx from "classnames";

type TriggerType = 'hover' | 'click' | 'focus';

type LyveTooltipProps = {
  children: React.ReactNode;
  text: string | React.ReactNode;
  placement?: Placement;
  id: string;
  defaultShow?: boolean;
  enabled?: boolean;
  variant?: "primary" | "secondary";
  className?: string;
  trigger?: TriggerType[]
};

// const UpdatingPopover = React.forwardRef(
//   ({ popper, children, show: _, ...props }, ref) => {
//     React.useEffect(() => {
//       console.log('updating!', popper);
//       popper.scheduleUpdate();
//     }, [children, popper]);
//
//     return (
//       <Popover ref={ref} content {...props}>
//         {children}
//       </Popover>
//     );
//   },
// )

// tooltip won't work if the children is disabled
const LyveTooltip: React.FC<LyveTooltipProps> = ({
  placement = "left",
  defaultShow = false,
  enabled = true,
  variant = "primary",
  ...rest
}) => {
  if (enabled) {
    const defaultTrigger: TriggerType[] = rest.trigger ?? defaultShow ? [] : ["hover", "focus"]
    return (
      <OverlayTrigger
        key={rest.id}
        placement={placement}
        defaultShow={defaultShow}
        trigger={defaultTrigger}
        // overlay={(props) => (
        //   <UpdatingPopover id="popover-contained" {...props}>{rest.text}</UpdatingPopover>
        // )}
        overlay={
          <Popover
            placement={placement}
            id={rest.id}
            className={cx("lyve-popover",
              variant === "primary" && "lyve-popover-primary",
              rest.className
            )}
          >
            {typeof rest.text === "string" ? (
              rest.text
            ) : (
              <Popover.Content>{rest.text}</Popover.Content>
            )}
          </Popover>
        }
      >
        {rest.children}
      </OverlayTrigger>
    );
  }
  return <>{rest.children}</>;
};

export default LyveTooltip;
