import { ListBucketResponse } from "../../scenes/Dashboard/components/Buckets/types";
import {
  loadingBuckets,
  rejectBuckets,
  resolveBuckets
} from "../../state/buckets";
import api from "../../services/api";
import store from "../../store";
import moment from "moment";

interface Data {
  name: string;
  region: string;
  creation_date: string;
  usage: number;
  compliance: boolean;
  versioned: boolean;
  logged: boolean;
  retentionDuration: number; //number of "days" or "years"
  retentionUnit: string; // "days" or "years"
  deletionMarker: boolean;
}

export interface Bucket {
  name: string;
  creation_date: string;
  size: string;
  region: string;
  usage: number;
  compliance: boolean;
  versioned: boolean;
  logged: boolean;
  retentionDuration: number; //number of "days" or "years"
  retentionUnit: string;
  deletionMarker: boolean;
}

const constructBucketList = (res: ListBucketResponse) => {
  let bucketList: Data[] = [];
  if (res && res.buckets) {
    // convert size parameter from string to number
    bucketList = res.buckets.map(
      (bucket: Bucket): Data => {
        // bucket size is not listed on response if it is equal to 0
        // therefore, we need to construct it for that case
        return {
          name: bucket.name,
          region: bucket.region,
          creation_date: bucket.creation_date
            ? moment(bucket.creation_date).format("YYYY-MM-DD")
            : "",
          // size: bucket.size,
          usage: bucket.size === undefined ? 0 : parseInt(bucket.size, 10),
          compliance: bucket.compliance,
          versioned: bucket.versioned,
          logged: bucket.logged,
          retentionDuration: bucket.retentionDuration,
          retentionUnit: bucket.retentionUnit,
          deletionMarker: bucket.deletionMarker
        };
      }
    );
  }
  store.dispatch(resolveBuckets(bucketList));
  return bucketList;
};

export const fetchBuckets: () => Promise<Bucket[] | Error> = async () => {
  store.dispatch(loadingBuckets());
  return await api
    .listBuckets()
    .then(constructBucketList)
    .catch(err => {
      store.dispatch(rejectBuckets(err));
      return err;
    });
};
