import React from "react";
import { useLocation } from "react-router-dom";

import api from "../../services/api";
import common from "../../services/common";
import { useDispatch, useSelector } from "react-redux";
import { WhoAmI } from "../../state/UserInfo/types";
import { AppState } from "../../store";
import { isEmpty } from "lodash";
import { UPDATE_WHOAMI } from "../../state/UserInfo/actions";

const useNavbar = () => {
  const dispatch = useDispatch();
  const [userName, setUserName] = React.useState<string>("");
  const [userEmail, setUserEmail] = React.useState<string>("");
  const [userRole, setUserRole] = React.useState("");
  const [customerName, setCustomerName] = React.useState<string>("");
  const [companyShortName, setCompanyShortName] = React.useState<string>("");
  const whoAmI = useSelector<AppState, WhoAmI>(state => state.userInfo.whoAmI);
  let userType = whoAmI?.type ?? "";
  let partnerName = "";
  if (!isEmpty(whoAmI)) {
    userType = whoAmI.type!;
    if (common.isTenant(userType)) {
      partnerName = whoAmI.tenant_partner_name!;
    }
  }

  let location = useLocation();
  const pathname = location.pathname;

  React.useEffect(() => {
    const localUserName: string | null = whoAmI?.name ?? null;
    const localCustomerName: string | null = whoAmI?.company_name ?? null;
    const localCompanyShortName: string | null =
      whoAmI?.company_short_name ?? null;
    const localUserType: string | null = whoAmI?.type ?? null;
    const localUserEmail: string | null = whoAmI?.email ?? null;
    const localUserRole: string | null = whoAmI?.role ?? null;

    if (
      localUserName !== null &&
      localCustomerName !== null &&
      localUserType !== null &&
      localUserEmail !== null &&
      localCompanyShortName !== null
    ) {
      api.checkUrlChange(pathname, localUserType);
      setUserName(localUserName);
      setUserEmail(localUserEmail);
      setUserRole(localUserRole ? localUserRole : "");
      setCustomerName(localCustomerName);
      setCompanyShortName(localCompanyShortName ? localCompanyShortName : "");
    } else {
      // TODO: Use Redux to store this info on api.whoami() call in Routes.tsx
      api
        .whoami()
        .then((res: any) => {
          api.checkUrlChange(pathname, res.type);
          dispatch({ type: UPDATE_WHOAMI, payload: res });
          // if (res.role) {
          //   setUserRole(res.role);
          // }
          // if (res.company_short_name) {
          //   setCompanyShortName(res.company_short_name);
          // }
          // if (res.account_id && res.name) {
          //   setUserName(res.name);
          //   if (res.company_name) {
          //     setCustomerName(res.company_name);
          //   }
          //   if (res.email) {
          //     setUserEmail(res.email);
          //   }
          // }
        })
        .catch(err => {
          console.log(
            "Exception error: got error when checking currect user identity."
          );
        });
    }
  }, [pathname]);

  const handleLogout = () => {
    api.logout().then(res => {
      common.logoutCleanup(res);
    });
  };

  const handleLogoClick = () => {
    api.getDomain().then(res => {
      const origin = window.location.origin
      if (origin.includes(res.cors_domain)) {
        window.location.href = origin;
      }
    })
  };

  return {
    userName,
    firstName: whoAmI.first_name,
    userEmail,
    userRole,
    companyShortName,
    customerName,
    partnerName,
    handleLogout,
    handleLogoClick,
    userType,
    archivalEnabled: whoAmI.archival_enabled
  };
};

export default useNavbar;
