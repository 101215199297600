import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Plus, X as CloseIcon } from "react-bootstrap-icons";
import orderBy from "lodash/orderBy";
import CreatePrompt from "./components/CreatePrompt";
import { LyveBadge } from "../../../../components/LyveBadge";
import { BUCKETS_EDIT } from "../../../../authRules";
import Can from "../../../../components/Can";
import LyveTable from "../../../../components/LyveTable";
import LyveHeadingLink from "../../../../components/LyveHeadingLink";
import { Link, useHistory } from "react-router-dom";
import { DefaultButton } from "../../../../components/LyveButton";
import { ModalContainer } from "../../../../components/Modal";
import { Bucket, ListBucketResponse } from "./types";
import api from "../../../../services/api";
import {
  loadingBuckets,
  rejectBuckets,
  resolveBuckets,
  selectBuckets
} from "../../../../state/buckets";
import "./styles.scss";
import { AppState, useAppDispatch, useAppSelector } from "../../../../store";
import useGlobalContext from "../../../../state/GlobalStateContext";
import useSmartStartKit from "../../../SmartStartHere/useSmartStartKit";
import { convertFileSize, removeAddBucketCard } from "../../../../utils";
import { fetchRegions } from "../../../../utils/api";
import { selectRegions } from "../../../../state/regions";
import moment from "moment";
import { CardType } from "../../../SmartStartHere/types.d";
import * as notificationActions from "../../../../components/Notification/actions";
import { LoadingStateModal } from "../../../../components/LyveErrorStateOverlay";
import { bucketLoadingState } from "../../../../constants/lyveCloud";
import { useSelector } from "react-redux";
import { WhoAmI } from "../../../../state/UserInfo/types";
import {
  getLink,
  linkNames
} from "../../../../components/LyveHeadingLink/LinkUtil";
import { string } from "yup";
import LyvePopup from "../../../../components/Modal/LyvePopup";
import {setErrorNotification} from "../../../../components/Notification/actions";

interface Data {
  name: string;
  region: string;
  creation_date: string;
  usage: number;
  compliance: boolean;
  versioned: boolean;
  logged: boolean;
  retentionDuration: number; //number of "days" or "years"
  retentionUnit: string; // "days" or "years"
  deletionMarker: boolean;
}

const BUCKET_CREATION_TIME_LIMIT = 120000;

export interface Region {
  name: string;
  description: string;
  url: string;
  default: boolean;
  accessType?: string | undefined;
  S3DomainURL?: string | undefined;
  status?: string | undefined;
  pricingRegion?: string | undefined;
}
export interface AllRegions {
  regions: Region[];
}

interface BucketProps {}

const BucketList: React.FC<BucketProps> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const whoAmI = useSelector<AppState, WhoAmI>(state => state.userInfo.whoAmI);

  const { setUserConfig } = useSmartStartKit();

  const { processBuckets } = useGlobalContext();
  const bucketInfo = useAppSelector(selectBuckets);
  const { data: buckets } = bucketInfo;

  const [bucketsInitialized, setBucketsInitialized] = React.useState<boolean>(
    false
  );
  const [allRegionsMap, setAllRegionsMap] = useState<Map<string, string>>();
  const [bucketNameList, setBucketNameList] = useState<string[]>([]);

  const { data: regions, status: regionsApiStatus } = useAppSelector(selectRegions);
  const userType = whoAmI?.type ?? "";

  const [showLoadingStateModal, setLoadingStateModal] = React.useState(false);
  const [showErrorStateModal, setErrorStateModal] = React.useState(false);
  const [counter, setCounter] = React.useState(0);
  const intervalRef = React.useRef<any>(null);
  const counterValueRef = React.useRef(0);
  const intervalModalCloseRef = React.useRef<any>(null);
  const [bucketError, setBucketError] = React.useState<string>("");
  const [bucketFormData, setBucketFormData] = React.useState<any>(null);

  const [showCreateBucketModal, setShowCreateBucketModal] = React.useState(false);
  const toggleCreateBktModal = () => setShowCreateBucketModal(!showCreateBucketModal)
  const [formData, setFormData] = React.useState<any>(null);

  React.useEffect(() => {
    let isMounted = true;
    api
      .listBuckets()
      .then(res => {
        if (isMounted) {
          constructBucketList(res);
          setBucketsInitialized(true);
        }
        //getUserConfigFromLocalStorage(shortName);
      })
      .catch(err => {
        // TODO: Handle errors while listing buckets
        if (isMounted) {
          setBucketsInitialized(true);
        }
      });

    return () => {
      isMounted = false;
    };
  }, []); // empty array ensures the effect is ran only once on component mount

  useEffect(() => {
    fetchRegions().then(regionList => {
      if (!regionList && !showCreateBucketModal) {
        setShowCreateBucketModal(false)
        dispatch(setErrorNotification('Failed to load the Create Bucket form. Please try again later.'))
      }
      if (regionList && regionList?.length) {
        let regions: Region[] = [...regionList];
        let regionsMap = new Map<string, string>();
        regions = regions?.sort((region1, region2) => {
          if (region1.default) {
            return -1;
          } else if (region2.default) {
            return 1;
          }
          return 0;
        });
        regions?.map(region =>
          regionsMap.set(region.name, region?.description)
        );
        setAllRegionsMap(regionsMap);
      }
    });
  }, []);

  React.useEffect(() => {
    if (
      intervalModalCloseRef &&
      intervalModalCloseRef.current &&
      !bucketError &&
      (counter === 5 || counterValueRef.current === 5)
    ) {
      intervalModalCloseRef.current();
      setLoadingStateModal(true);
      setErrorStateModal(false);
      setBucketError("");
    }

    if (counter === 0 || counterValueRef.current === 0) {
      setLoadingStateModal(false);
    }
  }, [counter, counterValueRef.current]);

  React.useEffect(() => {
    if (bucketError && (counter > 4 || counterValueRef.current > 4)) {
      setLoadingStateModal(false);
      intervalModalCloseRef.current();
      setErrorStateModal(true);
    }
  }, [counter, counterValueRef.current, bucketError]);

  React.useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
      counterValueRef.current = 0;
      setCounter(0);
    };
  }, []);

  /** Datatable */
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All"
  };

  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      minWidth: "100px",
      cell: (row: Data) => (
        <span title={row.name}>
          <Link
            className="caseNumber"
            to={{
              pathname: `/customer/bucket/${row.name}`,
              state: {
                bucketRegion: row.region
              }
            }}
          >
            {row.name}
          </Link>
        </span>
      )
    },
    {
      name: "Region",
      selector: "region",
      sortable: true,
      minWidth: "100px",
      maxWidth: "190px",
      cell: (row: Data) => {
        const completeRegion = allRegionsMap
          ? allRegionsMap.get(row.region)
          : "No region found";
        return <span title={completeRegion}>{completeRegion}</span>;
      }
    },
    {
      name: "Usage",
      selector: "usage",
      minWidth: "60px",
      maxWidth: "90px",
      cell: (row: Data) => (
        <span title={convertFileSize(row.usage)}>
          {convertFileSize(row.usage)}
        </span>
      ),
      sortable: true
    },
    {
      name: "Created On",
      selector: "creation_date",
      minWidth: "60px",
      maxWidth: "150px",
      sortable: true,
      cell: (row: Data) => {
        const offset = new Date(row.creation_date).getTimezoneOffset();

        const formattedDate = () =>
          moment(row.creation_date)
            .utcOffset(-offset)
            .format("YYYY-MM-DD");

        return <span title={formattedDate()}>{formattedDate()}</span>;
      }
    },
    {
      name: "",
      minWidth: "100px",
      maxWidth: "260px",
      cell: (row: Data) => (
        <div className="d-flex">
          {row.compliance && <LyveBadge variant="dark">Immutable</LyveBadge>}
          {row.versioned && <LyveBadge variant="dark">Versioned</LyveBadge>}
          {row.logged && <LyveBadge variant="dark">Logged</LyveBadge>}
        </div>
      )
    }
  ];

  const customSort = (
    rows: Data[],
    field: string,
    direction: "desc" | "asc"
  ) => {
    const handleField = (row: any) => {
      const val = row[field];
      if (val && typeof val === "string") {
        return val.toLowerCase();
      }
      return val;
    };

    // Natural number sorting for bucket names
    if (rows && rows.length > 0 && field === "name") {
      return rows.slice(0).sort((a, b) =>
        direction === "asc"
          ? a[field].localeCompare(b[field], "en", {
              numeric: true,
              sensitivity: "base"
            })
          : b[field].localeCompare(a[field], "en", {
              numeric: true,
              sensitivity: "base"
            })
      );
    }

    return orderBy(rows, handleField, direction);
  };

  // process bucket response coming from server
  const constructBucketList = (res: ListBucketResponse) => {
    let bucketList: Data[] = [];
    if (res && res.buckets) {
      bucketList = res.buckets.map(
        (bucket: Bucket): Data => {
          return {
            name: bucket.name,
            region: bucket.region,
            creation_date: bucket.creation_date ? bucket.creation_date : "",
            // bucket size is not listed on response if it is equal to 0
            // therefore, we need to construct it for that case
            usage: bucket.size === undefined ? 0 : parseInt(bucket.size, 10),
            compliance: bucket.compliance,
            versioned: bucket.versioned,
            logged: bucket.logged,
            retentionDuration: bucket.retentionDuration,
            retentionUnit: bucket.retentionUnit,
            deletionMarker: bucket.deletionMarker
          };
        }
      );
      const nameList = bucketList.map(bucket => bucket.name);
      setBucketNameList(nameList);
    }
    dispatch(resolveBuckets([...bucketList]));
    // setBuckets(bucketList);
  };

  const handleListRefresh = () => {
    api.listBuckets().then(res => {
      constructBucketList(res);
    });
  };

  const noBucketsCreated = () => {
    return bucketsInitialized && buckets.length === 0;
  };

  const handleClearInterval = () => {
    clearInterval(intervalRef.current);
    setCounter(0);
    counterValueRef.current = 0;
  };

  const handleAllClearInterval = () => {
    handleClearInterval();
    setLoadingStateModal(false);
    setErrorStateModal(false);
    intervalModalCloseRef.current = null;
    setBucketFormData(null);
    setBucketError("");
  };

  const getLoadingStateForBucketProcess = (bucketName: string) => {
    const loadingStateList = bucketLoadingState;
    if (bucketName) {
      for (let i = 0; i < loadingStateList.length; i++) {
        loadingStateList[i] = {
          ...loadingStateList[i],
          title: `Creating bucket "${bucketName}"...`
        };
      }
    }

    return loadingStateList;
  };

  return (
    <div className="buckets-container">
      <LyvePopup
        header={false}
        title={"Create Bucket"}
        show={showCreateBucketModal}
        onHide={toggleCreateBktModal}
        customClose={true}
        baseProps={{ size: "sm", dialogClassName: "lyve-modal create-bucket-modal", keyboard: false }}
      >
        <CreatePrompt
          regionsApiStatus={regionsApiStatus}
          hideModal={() => {
            setShowCreateBucketModal(false)
            handleClearInterval();
            intervalModalCloseRef.current = null;
            setLoadingStateModal(false);
            setErrorStateModal(false);
            setBucketFormData(null);
            setBucketError("");
          }}
          formData={formData}
          handleClearInterval={() => {
            handleClearInterval();
            setBucketError("");
          }}
          handleInterval={(formValues: any) => {
            intervalModalCloseRef.current = () => setShowCreateBucketModal(false);
            setBucketFormData(formValues);
            intervalRef.current = setInterval(() => {
              if (counterValueRef.current === BUCKET_CREATION_TIME_LIMIT) {
                handleAllClearInterval();
                handleListRefresh();
              } else {
                counterValueRef.current = counterValueRef.current + 1;
                setCounter(counterValueRef.current);
              }
            }, 1000);
          }}
          regionList={regions}
          allRegionsMap={allRegionsMap}
          onSuccess={async (
              isComplianceCreated: boolean,
              bucketName?: string
          ) => {
            setBucketError("");
            if (counterValueRef.current < BUCKET_CREATION_TIME_LIMIT + 1) {
              if (isComplianceCreated) {
                dispatch(
                    notificationActions.setNotification(
                        "Bucket created successfully"
                    )
                );
              } else {
                dispatch(
                    notificationActions.setWarningNotification(
                        <>
                          Bucket created, but immutability mode could not be
                          configured,
                          <span
                              style={{
                                textDecoration: "underline",
                                marginLeft: 5,
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                history.push(`/customer/bucket/${bucketName}`);
                                dispatch(notificationActions.clearNotification());
                              }}
                          >
                      please try again
                    </span>
                        </>,
                        false
                    )
                );
              }

              handleClearInterval();
              setLoadingStateModal(false);
              setErrorStateModal(false);
              setBucketFormData(null);
              setShowCreateBucketModal(false)
              handleListRefresh();
              if (userType !== "tenant") {
                const config = await api.getSmartStartConfig();
                if (!config.closedCards.includes(CardType.ADD_BUCKET)) {
                  await removeAddBucketCard();
                  setUserConfig({
                    ...config,
                    closedCards: [...config.closedCards, CardType.ADD_BUCKET]
                  });
                } else {
                  setUserConfig(config);
                }
              }
            } else if (counterValueRef.current > BUCKET_CREATION_TIME_LIMIT) {
              handleAllClearInterval();
              handleListRefresh();
            }
          }}
          onFailure={() => {
            if (
                counterValueRef.current > BUCKET_CREATION_TIME_LIMIT ||
                counterValueRef.current === BUCKET_CREATION_TIME_LIMIT
            ) {
              handleAllClearInterval();
            } else {
              clearInterval(intervalRef.current);
              setBucketError("error");
              setLoadingStateModal(false);
            }
          }}
          bucketNameList={bucketNameList}
        />
      </LyvePopup>
      {!(
        !bucketsInitialized ||
        bucketInfo.status === "loading" ||
        processBuckets
      ) ? (
        <>
          <LyveHeadingLink
            title="Buckets"
            subTitle="Create and manage storage buckets."
            helpLink={getLink(linkNames.Buckets_Learn_More)}
          >
            <Can perform={BUCKETS_EDIT}>
              <DefaultButton
                variant="primary"
                onClick={toggleCreateBktModal}
              >
                <Plus size="24" className="add-icon" />
                Create Bucket
              </DefaultButton>
            </Can>
          </LyveHeadingLink>

          {noBucketsCreated() && (
            <Can perform={BUCKETS_EDIT} no={"There are no buckets"}>
              You do not have any buckets. Create one now.
            </Can>
          )}
          {!noBucketsCreated() && (
            <LyveTable
              columns={columns}
              data={buckets}
              sortFunction={customSort}
              defaultSortField="creation_date"
              defaultSortAsc={false}
              selectableRows={false}
              pagination={true}
              noHeader={true}
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 25, 50]}
              highlightOnHover={true}
              paginationComponentOptions={paginationOptions}
              noDataComponent={<div />}
              paginationIconFirstPage={false}
              paginationIconLastPage={false}
              responsive={true}
            />
          )}

          {showLoadingStateModal && (
            <LoadingStateModal
              stateLabelList={getLoadingStateForBucketProcess(
                bucketFormData?.bucketName ?? ""
              )}
              intervalTime={24}
              handleErrorCondition={() => {
                setLoadingStateModal(false);
                setErrorStateModal(true);
              }}
            />
          )}
          {showErrorStateModal && (
            <Modal
              key={`show-confirmation-modal`}
              show={showErrorStateModal}
              size={"sm"}
              keyboard={false}
              backdrop="static"
              dialogClassName="loading-error-bucket-state-modal"
            >
              <Modal.Body>
                <button
                  className="custom-close-icon"
                  onClick={() => {
                    handleClearInterval();
                    setLoadingStateModal(false);
                    setErrorStateModal(false);
                    setBucketError("");
                    setBucketFormData(null);
                    intervalModalCloseRef.current = null;
                  }}
                >
                  <CloseIcon size="24" color="var(--gray-9)" />
                </button>
                <div className="d-flex flex-column">
                  <div className="title">Bucket creation failed</div>
                  <div className="subtitle">
                    Please try to{" "}
                    <span
                      onClick={() => {
                        setFormData(bucketFormData);
                        handleClearInterval();
                        setLoadingStateModal(false);
                        setErrorStateModal(false);
                        setBucketError("");
                      }}
                    >
                      create bucket
                    </span>{" "}
                    again.
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </>
      ) : (
        <div className="data-loader">
          <Spinner animation="border" role="status" className="spinner">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
};

export default BucketList;
