import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { setegid } from "process";
import { Data } from ".";
import Input from "./../Input";

/** Redux */
import { AppState } from "./../../store";
import { setNotificationRecipients } from "./reducer";
import * as notificationActions from "./../Notification/actions";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import api from "./../../services/api";
import storage from "local-storage-fallback";
import { ButtonLoader } from "./../LyveLoader";
import { Link } from "react-router-dom";
import LyveHeadingLink from "../LyveHeadingLink";
import { validateUnicodeName } from "../../utils/functions";
import LyveLink from "../LyveHeadingLink/LyveLink";

interface Props {
  usersList: Data[];
  userDetails: userDetails | null;
  type: string;
  closeModal?: () => void;
  onSuccess?: () => void;
  onFailure?: () => void;
}

type userDetails = Data;

interface OwnProps {
  closeModal?: () => void;
  initCount?: number;
}
interface StateProps {
  notificationRecipients: Data[];
}
interface DispatchProps {
  setNotificationRecipients: (recipientList: Data[]) => any;
  setErrorNotification: (message: string) => any;
  setNotification: (message: string) => any;
}

type NewRecipientProps = StateProps & DispatchProps & Props;

const AddRecipientModal = ({
  usersList,
  userDetails,
  type,
  setNotification,
  setErrorNotification,
  setNotificationRecipients,
  onSuccess,
  onFailure,
  closeModal
}: NewRecipientProps) => {
  const [firstname, setFirstName] = useState<string>("");
  const [lastname, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  /** States */
  const [editDetails, setEditDetails] = useState<Data | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [customerId, setCustomerId] = useState<string>("null");

  /** Effect */
  let isMounted: boolean = false;
  useEffect(() => {
    isMounted = true;
    const input: any = document.querySelector("#firstName");
    input && input.focus();

    const companyId = storage.getItem("company_id");
    const customer_id = companyId ? companyId : "";
    setCustomerId(customer_id);

    if (type === "edit") {
      userDetails && initalDetails(userDetails);
    } else if (type === "add" && !processing) {
      clearDetails();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  /** Functions */
  const initalDetails = (userDetails: userDetails) => {
    setFirstName(userDetails.firstname);
    setLastName(userDetails.lastname);
    setEmail(userDetails.email);
    setEditDetails(userDetails);
  };

  const addRecipient = (firstName: string, lastName: string, email: string) => {
    setProcessing(true);
    api
      .addNotificationRecipient(customerId, firstName, lastName, email)
      .then(() => {
        setProcessing(false);
        setNotification("New recipient was added.");
        if (typeof closeModal !== "undefined") {
          closeModal();
        }
        if (typeof onSuccess !== "undefined") {
          onSuccess();
        }
      })
      .catch(err => {
        // hideModal();
        // clearDetails();
        // console.log('err', err);
        // console.log('err', err.code);
        setProcessing(false);
        let isRecipientExists =
          usersList.find(user => user.email == email) || "";
        if (isRecipientExists) {
          setErrorNotification("The recipient is already existed.");
        } else {
          if (err.code === 14) {
            setErrorNotification(err.message);
          } else {
            setErrorNotification("Failed to add recipient.");
          }
        }
        //setErrorNotification('Failed to add recipient.');
        if (typeof closeModal !== "undefined") {
          closeModal();
        }
        if (typeof onFailure !== "undefined") {
          onFailure();
        }
      });
  };

  const editRecipient = (
    firstName: string,
    lastName: string,
    email: string
  ) => {
    setProcessing(true);
    const id = editDetails ? editDetails.id : "";
    // console.log(id);
    api
      .editNotificationRecipient(id, firstName, lastName, email)
      .then(() => {
        //on success
        setProcessing(false);
        setNotification("Recipient is updated.");
        if (typeof closeModal !== "undefined") {
          closeModal();
        }
        if (typeof onSuccess !== "undefined") {
          onSuccess();
        }
      })
      .catch(err => {
        // console.log(err);
        // hideModal();
        // clearDetails();
        // setLoading(false);
        // setProcessing(false);

        // On failure
        setProcessing(false);
        if (err.code === 14) {
          setErrorNotification(err.message);
        } else {
          setErrorNotification("Failed to edit recipient.");
        }
        if (typeof closeModal !== "undefined") {
          closeModal();
        }
      });
  };

  const handleSubmit = () => {
    // if (typeof closeModal !== "undefined") {
    //   closeModal();
    // }

    if (type === "add") {
      addRecipient(
        firstname.trim(),
        lastname.trim(),
        email.trim().toLowerCase()
      );
    } else {
      editRecipient(
        firstname.trim(),
        lastname.trim(),
        email.trim().toLowerCase()
      );
    }
  };

  const validateEmail = () => {
    if (
      /\S/.test(email) &&
      email.length > 0 &&
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const clearDetails = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
  };

  return (
    <div>
      <Form
        onSubmit={(e: any) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {/* <Modal.Body> */}
        <Input
          controlId="firstName"
          label="First Name"
          value={firstname}
          onChange={(e: any) => setFirstName(e.target.value)}
          isInvalid={firstname.length > 0 && !validateUnicodeName(firstname)}
          error={`Only alphanumeric, '-' or space is allowed.`}
        />
        <Input
          controlId="lastName"
          label="Last Name"
          value={lastname}
          onChange={(e: any) => setLastName(e.target.value)}
          isInvalid={lastname.length > 0 && !validateUnicodeName(lastname)}
          error={`Only alphanumeric, '-' or space is allowed.`}
        />
        <Input
          controlId="email"
          label="Email"
          type="email"
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
          isInvalid={email.length > 0 && !validateEmail()}
          readOnly={type === "edit"}
          error={`Please enter a valid email address.`}
        />
        {/* </Modal.Body>
          <Modal.Footer> */}
        <Button
          type="submit"
          disabled={
            validateEmail() &&
            validateUnicodeName(firstname) &&
            validateUnicodeName(lastname) &&
            !processing
              ? false
              : true
          }
        >
          {processing ? (
            <div>
              <ButtonLoader animation="border" />
              {type === "add" ? " Adding" : " Editing"}
            </div>
          ) : type === "add" ? (
            "Add"
          ) : (
            "Save"
          )}
        </Button>
        {/* </Modal.Footer> */}
      </Form>
      {/* </Modal> */}
    </div>
  );
};

// export default AddRecipientModal;
const mapStateToProps = (state: AppState): StateProps => ({
  notificationRecipients: state.notificationRecipients.recipientList
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setNotificationRecipients: (recipientList: Data[]) =>
    dispatch(setNotificationRecipients(recipientList)),
  setErrorNotification: (message: string) =>
    dispatch(notificationActions.setErrorNotification(message)),
  setNotification: (message: string) =>
    dispatch(notificationActions.setNotification(message))
});

export default compose<NewRecipientProps, Props>(
  connect(mapStateToProps, mapDispatchToProps)
)(AddRecipientModal);
