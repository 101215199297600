import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import orderBy from "lodash/orderBy";
import { compose } from "recompose";
import { connect, useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import storage from "local-storage-fallback";
import {
  createStyles,
  withStyles,
  makeStyles,
  Theme
} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import IconClose from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import { Button as B4Button } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import ResetPasswordPrompt from "./components/ResetPasswordPrompt/index";
import api from "../../../../services/api/index";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Tooltip from "@material-ui/core/Tooltip";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { green, red } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as userActions from "./actions";
import { getModalDisplayStatus } from "./selectors";
import { AppState } from "../../../../store";
import Can from "../../../../components/Can";
import { USERS_EDIT } from "../../../../authRules";
import {
  MoreHoriz,
  Add as AddIcon,
  ShowChartOutlined
} from "@material-ui/icons";
import { Data as NotificationRecipient } from "../../../../components/NotificationRecipients/index";
import LyveTable from "../../../../components/LyveTable";
import ContextMenu from "../../../../components/ContextMenu";
import { WhoAmI } from "../../../../state/UserInfo/types";
import { IAMInfo } from "../../../../state/IAM/types";
import LyveHeadingLink from "../../../../components/LyveHeadingLink";
import { ModalContainer } from "../../../../components/Modal";
import ResetMFAPrompt from "../../../../components/IAM/Users/ResetMFAPrompt";
import { SAMLConfig } from "../../../../components/IAM/SAMLFederation/types";
import { IS_SAML_CONFIGURED } from "../../../../state/IAM/actions";
import CreateUser from "../../../../components/IAM/Users/CreateUser";
import { setNotificationRecipients } from "../../../../components/NotificationRecipients/reducer";
import * as notificationActions from "../../../../components/Notification/actions";
import DeleteUser from "../../../../components/IAM/Users/DeleteUser";
import LyveStatusDot from "../../../../components/LyveBadge/LyveStatusDot";
import useSmartStartKit from "../../../SmartStartHere/useSmartStartKit";
import omit from "lodash/omit";
import {
  getLink,
  linkNames
} from "../../../../components/LyveHeadingLink/LinkUtil";

interface Data {
  first_name: string;
  last_name: string;
  role: string;
  id: string;
  email: string;
  is_me: boolean;
  auth_type: string;
  authenticator: string;
  enabled: boolean;
}

interface User {
  first_name: string;
  last_name: string;
  role: string;
  id: string;
  email: string;
  auth_type: string;
  authenticator: string;
  is_me: boolean;
  enabled: boolean;
}

interface ListUserResponse {
  users: User[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      marginTop: "41px"
    },
    heading: {
      display: "flex",
      marginBottom: "32px"
    },
    headingText: {
      alignSelf: "center",
      color: "#1a1a1a",
      fontSize: 16,
      fontWeight: 400
    },
    mainHeading: {
      display: "block",
      lineHeight: "32px",
      fontWeight: 300,
      marginBottom: "8px",
      fontSize: 24
    },
    subHeading: {
      fontSize: 16,
      fontWeight: 400,
      color: "#737373",
      lineHeight: "24px",
      display: "block"
    },
    spinnerContainer: {
      height: "100%"
    },
    createIcon: {
      position: "relative",
      bottom: theme.spacing(0.125),
      fontSize: 50,
      color: theme.palette.grey[400],
      marginBottom: theme.spacing(0.5)
    },
    createIcon2: {
      fontSize: 20,
      width: 20,
      height: 20
    },
    buttonIcon: {
      marginRight: theme.spacing(0.375)
    },
    modalContainer: {
      overflow: "scroll",
      maxWidth: 640,
      maxHeight: "calc(82vh - 10px)",
      margin: "18vh auto 10px"
    },
    modal: {
      position: "absolute",
      width: 640,
      backgroundColor: theme.palette.background.paper,
      padding: "24px",
      outline: "none",
      borderRadius: 6,
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.08)"
    },
    modalClose: {
      position: "absolute",
      right: "12px",
      top: "20px",
      color: "#666",
      "&:hover": {
        backgroundColor: "transparent"
      },
      "& .MuiSvgIcon-root": {
        fontSize: 16
      }
    },
    addCard: {
      background: "none",
      cursor: "pointer",
      height: 225,
      border: `1px dashed ${theme.palette.grey[500]}`,
      borderRadius: 6,
      boxShadow: "none"
    },
    addCardContent: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      "&:last-child": {
        paddingBottom: theme.spacing(2)
      }
    },
    cardNameYou: {
      position: "relative",
      top: -1,
      backgroundColor: theme.palette.grey[200],
      marginLeft: theme.spacing(1),
      borderRadius: 24,
      fontSize: 13,
      padding: theme.spacing(0.25, 1.5)
    },
    cardKeyButton: {
      float: "right",
      borderRadius: 4,
      padding: theme.spacing(1),
      color: theme.palette.grey[600],
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.grey[800]
      }
    },
    buttonTooltip: {
      position: "relative",
      bottom: 10,
      backgroundColor: theme.palette.grey[800]
    },
    cardKeyIcon: {
      fontSize: 14
    },
    card: {
      height: 225
    },
    cardContent: {
      paddingRight: theme.spacing(1.5)
    },
    cardName: {
      fontSize: 18,
      color: theme.palette.grey[900],
      opacity: 1,
      fontWeight: 500
    },
    cardOrgName: {
      fontSize: 14,
      marginTop: theme.spacing(0.25),
      color: theme.palette.grey[400]
    },
    cardEmail: {
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.grey[900],
      marginTop: theme.spacing(2.5)
    },
    cardStatusContainer: {
      marginTop: theme.spacing(0.75)
    },
    cardStatusButton: {
      padding: theme.spacing(0.75, 1.25),
      textTransform: "capitalize"
    },
    cardStatusLabel: {
      fontSize: 12,
      color: theme.palette.grey[500],
      marginTop: theme.spacing(4)
    },
    statusSpinner: {
      marginTop: theme.spacing(2)
    }
  })
);

interface UserProps {
  modalDisplayStatus?: boolean;
  displayModal?: any;
  hideUserModal?: any;
  setNotification?: any;
  changeTabToSAML?: any;
  tabKey?: string;
  setNotificationRecipients?: any;
}

const GreenButton = withStyles((theme: Theme) => ({
  root: {
    // color: theme.palette.primary.contrastText,
    // backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    padding: "9px 16px",
    backgroundColor: "#6ebe49",
    height: "40px",
    fontWeight: 500,
    lineHeight: "20px",
    fontFamily: "Roboto, sans-serif",
    minWidth: "64px",
    textTransform: "capitalize",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: green[700]
    }
  }
}))(Button);

const RedButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    textTransform: "capitalize",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: red[700]
    }
  }
}))(Button);

const UsersPage: React.FC<UserProps> = ({
  displayModal,
  modalDisplayStatus,
  setNotification,
  setNotificationRecipients
}) => {
  const history: any = useHistory();
  const location = useLocation();
  const isCustomer = location.pathname.includes("customer");
  const accountType = isCustomer ? "customer" : "partner";

  const hideModal = () => {
    setSelected(null);
  };

  const classes = useStyles();
  const { showModal } = ModalContainer.useContainer();
  const { userConfig, setUserConfig } = useSmartStartKit();

  const [users, setUsers] = useState<Data[]>([]);
  const [usersInitialized, setUsersInitialized] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>(null);
  const [isFormProcessing, setFormProcessing] = useState<boolean>(false);
  // The ID of the account whose status is being updated
  const [statusProcessingId, setStatusProcessingId] = useState<string | null>(
    null
  );
  const [customerId, setCustomerId] = useState<string>("null");
  const [authType, setAuthType] = useState<string>("");
  const [showMenuId, setShowMenuId] = useState("");
  const whoAmI = useSelector<AppState, WhoAmI>(state => state.userInfo.whoAmI);
  const IAMInfo = useSelector<AppState, IAMInfo>(
    state => state.IAMInfo.IAMInfo
  );
  const dispatch = useDispatch();

  // construct user objects w/usage field temporarily from frontend
  const constructUserList = (res: ListUserResponse) => {
    let userList: Data[] = [];
    if (res && res.users) {
      // temporarily construct usage field
      userList = res.users.map(
        (b: User): Data => {
          return {
            id: b.id,
            first_name: b.first_name,
            last_name: b.last_name,
            role: b.role,
            email: b.email,
            is_me: b.is_me,
            enabled: b.enabled,
            auth_type: b.auth_type,
            authenticator: b.authenticator
          };
        }
      );
    }
    userList = orderBy(userList, ["email"], ["asc"]);
    setUsers(userList);
  };

  const fetchUsers = async (isMounted?: boolean) => {
    try {
      const userList = await api.listUsers();
      if (isMounted) {
        constructUserList(userList);
        setUsersInitialized(true);
      }
      const companyId: string | null = storage.getItem("company_id");
      const customer_Id = companyId ? companyId : "";
      setCustomerId(customer_Id);
    } catch (error) {
      if (isMounted) setUsersInitialized(true);
    }
  };

  const fetchSAML = async () => {
    try {
      if (IAMInfo.isSAMLConfigured === undefined && isCustomer) {
        const res: SAMLConfig = await api.getSAMLConfiguration();
        dispatch({
          type: IS_SAML_CONFIGURED,
          payload: true
        });
      }
    } catch (err) {
      dispatch({
        type: IS_SAML_CONFIGURED,
        payload: false
      });
    }
  };

  React.useEffect(() => {
    let isMounted = true;
    fetchUsers(isMounted);
    return () => {
      isMounted = false;
    };
  }, [IAMInfo.mfa_preference]); // refetch list if whoAmI info changes (MFA preference updates auth_type values)

  React.useEffect(() => {
    // Fetch if unknown (SAML API call hasn't been made yet in this session)
    fetchSAML();
  }, [IAMInfo.isSAMLConfigured]);

  const roleDisplayMap: { [k: string]: string } = {
    admin: "Admin",
    auditor: "Auditor",
    "customer-manager": "Customer Manager",
    "storage-admin": "Storage Admin"
  };

  const authenticatorDisplayMap: { [k: string]: string } = {
    undefined: "Password + MFA (Not Enrolled)",
    otp: "Password + OTP",
    sms: "Password + SMS",
    "sms+otp": "Password + SMS"
  };

  const getAuthenticationType = (row: Data) => {
    if (row.auth_type === "federated") {
      return "Federated";
    } else if (row.auth_type === "password") {
      return "Password";
    } else {
      return authenticatorDisplayMap[row.authenticator || "undefined"];
    }
  };

  const hasMFA = (row: Data) => {
    return row.auth_type === "mfa" && row.authenticator;
  };

  /** Datatable */
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All"
  };
  const columns = [
    {
      name: "First Name",
      selector: "first_name",
      sortable: true,
      cell: (row: Data) => (
        <span
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden"
          }}
          title={row.first_name}
        >
          {row.first_name}
        </span>
      )
    },
    {
      name: "Last Name",
      selector: "last_name",
      sortable: true,
      cell: (row: Data) => (
        <span
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden"
          }}
          title={row.last_name}
        >
          {row.last_name}
        </span>
      )
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      cell: (row: Data) => (
        <span
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden"
          }}
          title={row.email}
        >
          {row.email}
        </span>
      )
    },
    {
      name: "Authentication Type",
      selector: "auth_type",
      sortable: true,
      minWidth: "250px",
      cell: (row: Data) => (
        <span
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden"
          }}
          title={getAuthenticationType(row)}
        >
          {getAuthenticationType(row)}
        </span>
      )
    },
    {
      name: "Role",
      selector: "role",
      sortable: true,
      cell: (row: Data) => (
        <span
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden"
          }}
          title={roleDisplayMap[row.role] || row.role}
        >
          {roleDisplayMap[row.role] || row.role}
        </span>
      )
    },
    {
      name: "Status",
      selector: "enabled",
      sortable: true,
      cell: (row: Data) =>
        // currentUser.id !== row.id &&
        row.id === statusProcessingId ? (
          <div className="col-12">
            <div
              className="spinner-border spinner-border-sm m-3"
              style={{ color: "#6ebe49" }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          renderReadOnlyStatus(row)
        )
    },
    {
      name: "",
      right: true,
      cell: (row: Data) => {
        if (row.id === whoAmI.account_id) {
          // Federated auditors don't have any options
          if (row.auth_type === "federated" && row.role === "auditor") {
            return null;
          }
          return (
            <ContextMenu
              id="users"
              trigger={
                showMenuId === row.id ? (
                  <button
                    type="button"
                    className="btn btn-icon table-close-icon"
                    onClick={() => setShowMenuId("")}
                  >
                    <i className="material-icons">close</i>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-icon"
                    onClick={() => setShowMenuId(row.id)}
                  >
                    <MoreHoriz />
                  </button>
                )
              }
              parentDropdownClass="table-menu"
              drop={"down"}
              hasManualToggle={true}
              manualShow={showMenuId === row.id}
              alignRight={false}
              onToggle={(isOpen, _event, _meta) => {
                if (!isOpen) {
                  setShowMenuId("");
                }
              }}
              minWidth="200px"
              options={getUserActions(row)}
            />
          );
        } else {
          return (
            <Can perform={USERS_EDIT}>
              <ContextMenu
                id="users"
                drop={"down"}
                trigger={
                  <div>
                    {showMenuId === row.id ? (
                      <button
                        type="button"
                        className="btn btn-icon table-close-icon"
                        onClick={() => setShowMenuId("")}
                      >
                        <i className="material-icons">close</i>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-icon"
                        onClick={() => setShowMenuId(row.id)}
                      >
                        <MoreHoriz />
                      </button>
                    )}
                  </div>
                }
                parentDropdownClass="table-menu"
                hasManualToggle={true}
                onToggle={(isOpen, _event, _meta) => {
                  if (!isOpen) {
                    setShowMenuId("");
                  }
                }}
                manualShow={showMenuId === row.id}
                alignRight={false}
                minWidth="200px"
                options={getUserActions(row)}
              />
            </Can>
          );
        }
      },
      sortable: false
    }
  ];

  const showEditUserModal = (row: Data) => {
    showModal({
      header: false,
      body: closeModal => (
        <CreateUser
          firstName={row.first_name}
          lastName={row.last_name}
          userEmail={row.email}
          userRole={row.role}
          editId={row.id}
          emailList={users.map(user => user.email)}
          type={"edit"}
          mfa={IAMInfo.mfa_preference}
          authType={getAuthenticationType(row)}
          editingSelf={row.id === whoAmI.account_id ? true : false}
          SAMLConfigured={IAMInfo.isSAMLConfigured}
          accountType={accountType}
          closeModal={closeModal}
          customerId={customerId}
          onSuccess={(userList: any) => {
            constructUserList(userList);
          }}
          onError={() => {
            console.log("error");
          }}
        />
      ),
      baseProps: { size: "sm" }
    });
  };

  const getUserActions = (row: Data) => {
    const options: any = [
      {
        action: USERS_EDIT,
        label: (
          <span>
            <i className="material-icons">edit</i>
            Edit
          </span>
        ),
        onClick: () => {
          showEditUserModal(row);
          setShowMenuId("");
        }
      }
    ];

    if (row.auth_type !== "federated") {
      options.push({
        label: (
          <span>
            <i className="material-icons">vpn_key</i>
            Reset Password
          </span>
        ),
        onClick: () => {
          // setSelected(row);
          // displayModal();

          showResetPasswordPrompt(row);
          setShowMenuId("");
        }
      });
    }

    if (row.id !== whoAmI.account_id) {
      if (row.enabled) {
        options.push({
          label: (
            <span>
              <i className="material-icons">close</i>
              Disable
            </span>
          ),
          onClick: () => {
            handleDisableUser(row.id);
            setShowMenuId("");
          }
        });
      } else {
        options.push({
          label: (
            <span>
              <i className="material-icons">done</i>
              Enable
            </span>
          ),
          onClick: () => {
            handleEnableUser(row.id);
            setShowMenuId("");
          }
        });
      }
    }

    if (row.id !== whoAmI.account_id) {
      //if (row.enabled) {
      options.push({
        label: (
          <span>
            <i className="material-icons">delete</i>
            Delete
          </span>
        ),
        onClick: () => {
          showDeleteUser(row);
        }
      });
      //}
    }

    if (hasMFA(row)) {
      options.push({
        action: USERS_EDIT,
        label: (
          <span>
            <i className="material-icons">autorenew</i>
            Reset MFA
          </span>
        ),
        onClick: () => {
          showResetMFAModal(row.email, row.id);
        }
      });
    }

    return options;
  };

  const showResetMFAModal = (email: string, id: string) => {
    showModal({
      header: false,
      // title: "",
      body: closeModal => (
        <ResetMFAPrompt
          email={email}
          id={id}
          closeModal={closeModal}
          onSuccess={() => {
            api.listUsers().then(userList => {
              constructUserList(userList);
            });
          }}
          onError={() => {
            console.log("error");
          }}
        />
      ),
      // footer: closeModal => <div>yolo</div>,
      baseProps: { size: "sm" }
    });
  };

  const customSort = (
    rows: Data[],
    field: string,
    direction: "desc" | "asc"
  ) => {
    const handleField = (row: any) => {
      const val = row[field];
      if (val && typeof val === "string") {
        return val.toLowerCase();
      }
      return val;
    };

    return orderBy(rows, handleField, direction);
  };
  const NoData = (
    // <p style={{marginTop: "170px"}}>You do not have any {NoDataConst} tickets for now.</p>

    <table className="table empty-table">
      <thead>
        <tr>
          <th>
            First Name <i className="fa fa-fw fa-sort-down" />
          </th>
          <th>
            Last Name <i className="fa fa-fw fa-sort" />
          </th>
          <th>
            Email <i className="fa fa-fw fa-sort" />
          </th>
          <th>
            Authentication Type <i className="fa fa-fw fa-sort" />
          </th>
          <th>
            Role <i className="fa fa-fw fa-sort" />
          </th>
          <th>
            Status <i className="fa fa-fw fa-sort" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={7}>There are no users.</td>
        </tr>
      </tbody>
    </table>
  );

  const validateEmail = (value: string) => {
    if (
      /^[a-z][-_.+!#$%&'*/=?^`{|]{0,1}([a-z0-9][-_.+!#$%&'*/=?^`{|]{0,1})*[a-z0-9]@[a-z0-9][-.]{0,1}([a-z][-.]{0,1})*[a-z0-9].[a-z0-9]{1,}([.-]{0,1}[a-z]){0,}[a-z0-9]{0,}$/.test(
        value
      )
    ) {
      return true;
    }
  };

  const clearDetails = () => {
    setAuthType("");
  };

  const [showResetPwd, setShowResetPwd] = useState<boolean>(false);

  const showResetPasswordPrompt = (row: Data) => {
    setSelected(row);
    setShowResetPwd(true);
  };

  const handleResetPasswordUser = (name: string, email: string) => {
    setFormProcessing(true);
    api
      .userResetPassword(name, email)
      .then(() => {
        setFormProcessing(false);
        setShowResetPwd(false);
        setNotification(`A password reset Email has been sent to ${email}`);
      })
      .catch(err => {
        setFormProcessing(false);
        setShowResetPwd(false);
        setNotification(
          `Failed to send reset email invite to ${email}. Please try again later`
        );
      });
  };

  const handleUserChangePassword = (
    oldPassword: string,
    newPassword: string
  ) => {
    setFormProcessing(true);
    api
      .userChangePassword(oldPassword, newPassword)
      .then(() => {
        setFormProcessing(false);
        hideModal();
        //Need to fix this and put it the right place
        storage.removeItem("token");
        storage.removeItem("user_id");
        storage.removeItem("user_email");
        storage.removeItem("user_name");
        // in partner
        // window.location.href = api.getLoginUrl();
        // history.push not in partner
        history.push("/");
        setNotification(
          "The password was changed successfully. Please log in with the new password."
        );
      })
      .catch(err => {
        setFormProcessing(false);
      });
  };

  const handleEnableUser = (id: string) => {
    setStatusProcessingId(id);
    api
      .userEnable(id)
      .then(() => {
        Promise.all([
          api.getAllNotificationRecipients(customerId),
          api.listUsers()
        ]).then(values => {
          let notificationRecipientList = values[0];
          let userList = values[1];
          setNotificationRecipients(
            notificationRecipientList.notificationRecipient
          );
          constructUserList(userList);
          setStatusProcessingId(null);
          clearDetails();
        });
      })
      .catch(err => {
        setStatusProcessingId(null);
      });
  };
  const handleDisableUser = (id: string) => {
    setStatusProcessingId(id);
    api
      .userDisable(id)
      .then(() => {
        Promise.all([
          api.getAllNotificationRecipients(customerId),
          api.listUsers()
        ]).then(values => {
          let notificationRecipientList = values[0];
          let userList = values[1];
          setNotificationRecipients(
            notificationRecipientList.notificationRecipient
          );
          constructUserList(userList);
          setStatusProcessingId(null);
          clearDetails();
        });
      })
      .catch(err => {
        setStatusProcessingId(null);
      });
  };

  // const handleAddUserClose = (e: React.MouseEvent<HTMLButtonElement>) => {
  // 	e.preventDefault();
  // 	clearDetails();
  // 	hideModal();
  // };

  const selectedUserExists = () => {
    return selected;
  };

  const noUsersCreated = () => {
    return usersInitialized && users.length === 0;
  };

  const renderPasswordReset = (row: Data, title: string) => {
    return (
      <span>
        <Tooltip
          classes={{
            tooltip: classes.buttonTooltip
          }}
          title={title}
        >
          <IconButton
            className={classes.cardKeyButton}
            onClick={() => {
              // event.preventDefault();
              setSelected(row);
              displayModal();
            }}
          >
            <VpnKeyIcon className={classes.cardKeyIcon} />
          </IconButton>
        </Tooltip>
      </span>
    );
  };

  const renderReadOnlyStatus = (row: Data) => {
    if (row.enabled) {
      return (
        <LyveStatusDot color="var(--green-3)" size={8}>
          <span title="Enabled">Enabled</span>
        </LyveStatusDot>
      );
    } else {
      return (
        <LyveStatusDot color="var(--gray-6)" size={8}>
          <span title="Disabled">Disabled</span>
        </LyveStatusDot>
      );
    }
  };

  // const [showDeletePrompt, setShowDeletePrompt] = useState<boolean>(false);
  const showDeleteUser = (row: Data) => {
    showDeleteUserModal(row);
  };

  const showDeleteUserModal = (row: Data) => {
    showModal({
      header: false,
      body: closeModal => (
        <DeleteUser
          closeModal={closeModal}
          userId={row.id}
          email={row.email}
          customerId={customerId}
          firstName={row.first_name}
          lastName={row.last_name}
          onSuccess={async (userList: any) => {
            constructUserList(userList);
            if (isCustomer) {
              const config = await api.getSmartStartConfig();
              setUserConfig({
                ...omit(config, ["ownedBuckets"]),
                ownedBuckets: userConfig.ownedBuckets
              });
            }
          }}
          onError={() => {
            console.log("error");
          }}
        />
      ),
      baseProps: { size: "sm" }
    });
  };

  const { toggleShowSmartStartKit, fromLocation } = useSmartStartKit();
  const processSmartStartKit = () => {
    if (fromLocation.current === "add-user-card") {
      fromLocation.current = "";
      // it's a requirement to toggle Smart Start Kit after 2 sec, not a hack
      setTimeout(() => {
        toggleShowSmartStartKit?.();
      }, 2000);
    }
  };

  const showAddUserModal = () => {
    showModal({
      header: false,
      body: closeModal => (
        <CreateUser
          firstName=""
          lastName=""
          userEmail=""
          userRole="admin"
          emailList={users.map(user => user.email)}
          type={"add"}
          mfa={IAMInfo.mfa_preference}
          authType={authType}
          SAMLConfigured={IAMInfo.isSAMLConfigured}
          accountType={accountType}
          closeModal={closeModal}
          customerId={customerId}
          onSuccess={(userList: any) => {
            if (isCustomer) {
              setUserConfig({
                ...userConfig,
                createdUsers: userConfig.createdUsers + 1
              });
            }
            constructUserList(userList);
          }}
          onError={() => {
            console.log("error");
          }}
        />
      ),
      baseProps: {
        size: "sm",
        onExit: isCustomer ? processSmartStartKit : undefined
      }
    });
  };

  React.useEffect(() => {
    if (usersInitialized) {
      if (fromLocation.current === "add-user-card" && isCustomer) {
        showAddUserModal();
      }
    }
  }, [fromLocation.current, usersInitialized]);

  return (
    <div className="IAM-support-page-bodyContent">
      {usersInitialized ? (
        <React.Fragment>
          <LyveHeadingLink
            title="Users"
            subTitle="View and manage all Lyve Cloud console users."
            helpLink={getLink(linkNames.Users)}
          >
            <Can perform={USERS_EDIT}>
              <B4Button
                variant="primary"
                onClick={() => {
                  // event.preventDefault();
                  setSelected(null);
                  showAddUserModal();
                }}
                disabled={!usersInitialized}
              >
                <AddIcon
                  className={`${classes.buttonIcon} ${classes.createIcon2}`}
                />
                Add User
              </B4Button>
            </Can>
          </LyveHeadingLink>

          <div>
            <LyveTable
              columns={columns}
              data={users}
              sortFunction={customSort}
              defaultSortField="first_name"
              defaultSortAsc={true}
              selectableRows={false}
              // pagination={users.length > 10 ? true : false}
              pagination={true}
              noHeader={true}
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 25, 50]}
              highlightOnHover={true}
              paginationComponentOptions={paginationOptions}
              noDataComponent={NoData}
              paginationIconFirstPage={false}
              paginationIconLastPage={false}
              responsive={true}
            />
          </div>

          <Modal
            disableBackdropClick={true}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={showResetPwd}
            onClose={event => {
              setShowResetPwd(false);
            }}
            // className={classes.modalContainer}
          >
            <div
              style={{
                // width: "450px",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)"
              }}
              className={classes.modal}
            >
              <IconButton
                className={classes.modalClose}
                onClick={event => {
                  event.preventDefault();
                  setShowResetPwd(false);
                }}
              >
                <IconClose />
              </IconButton>

              <ResetPasswordPrompt
                selected={selected}
                handleResetPassword={(user: any) =>
                  handleResetPasswordUser(user.name, user.email)
                }
                isFormProcessing={isFormProcessing}
                setShowResetPwd={setShowResetPwd}
              />
            </div>
          </Modal>

          {/* {showDeletePrompt && (
            <DeleteUserPrompt
              selected={selected}
              setShowDeletePrompt={setShowDeletePrompt}
              handleDeleteUser={(selected: any) => handleDeleteUser(selected)}
              isFormProcessing={isFormProcessing}
            />
          )} */}
        </React.Fragment>
      ) : (
        <Grid
          container
          className={classes.spinnerContainer}
          justify="center"
          alignItems="center"
        >
          <CircularProgress />
        </Grid>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  modalDisplayStatus: getModalDisplayStatus(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setNotificationRecipients: (recipientList: NotificationRecipient[]) => {
    dispatch(setNotificationRecipients(recipientList));
  },
  displayModal: () => dispatch(userActions.displayModal()),
  hideUserModal: () => dispatch(userActions.hideModal()),
  setNotification: (message: string) =>
    dispatch(notificationActions.setNotification(message))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(UsersPage);
