import api from "../services/api";
import store from "../store";
import {
  loadingRegions,
  Region,
  rejectRegions,
  resolveRegions
} from "../state/regions";
import {
  loadingRegionsWithSG,
  RegionWithSG,
  rejectRegionsWithSG,
  resolveRegionsWithSG
} from "../state/regionswithsg";
import { toSnakeCaseKeys } from "./index";
import { StoreStateStatus } from "../state/types";

export const fetchRegions: () => Promise<Region[] | null> = async () => {
  const state = store.getState();
  if (
    state.regions.status === StoreStateStatus.IDLE ||
    state.regions.status === StoreStateStatus.REJECTED
  ) {
    try {
      store.dispatch(loadingRegions());
      const resp = await api.getAllRegions();
      store.dispatch(resolveRegions(resp.regions));
      return resp?.regions ?? [];
    } catch (e) {
      store.dispatch(rejectRegions(e));
      return null;
    }
  } else {
    return state.regions.data;
  }
};
export const fetchRegionsWithSG: () => Promise<RegionWithSG[]> = async () => {
  const state = store.getState();
  if (
    state.regionswithsg.status === StoreStateStatus.IDLE ||
    state.regionswithsg.status === StoreStateStatus.REJECTED
  ) {
    try {
      store.dispatch(loadingRegionsWithSG());
      const resp = await api.getRegionsWithSG();
      store.dispatch(resolveRegionsWithSG(resp.regions));
      return resp?.regions ?? [];
    } catch (e) {
      store.dispatch(rejectRegionsWithSG(e));
      return [];
    }
  } else {
    return state.regionswithsg.data;
  }
};

export function canDeleteBucketWithURL(name: string, baseURL: string) {
  const url = `${baseURL}/api/v2/buckets/${name}/delete_check`;
  return api.makeRequest("GET", url);
}

export const deleteBucketWithURL = async (name: string, baseURL: string) => {
  const url = `${baseURL}/api/v2/buckets/${name}`;
  try {
    const canDeleteBucket = await canDeleteBucketWithURL(name, baseURL);
    if (canDeleteBucket && !!canDeleteBucket.status) {
      const data = { name };
      return api.makeRequest("DELETE", url, data);
    }
  } catch (e) {
    return e;
  }
};

type DELETE_RESOURCES_PAYLOAD = {
  bucketName: string | null;
  permission?: string | null;
  serviceAccount?: string | null;
};

export const deleteBucketResources = async (
  baseURL: string,
  data: DELETE_RESOURCES_PAYLOAD
) => {
  const url = `${baseURL}/api/v2/user/start-here-buckets`;
  try {
    return api.makeRequest("DELETE", url, toSnakeCaseKeys(data));
  } catch (e) {
    return e;
  }
};
