import React from "react";
import { DefaultButton } from "../../../../../../components/LyveButton";

import "./styles.scss";

interface ErrorPromptProps {
    closeModal: () => void;
    title: string;
    content: string;
}

const ErrorPrompt: React.FC<ErrorPromptProps> = ({
    closeModal,
    title,
    content
}) => {


    return (
        <div className="error-permission-module">
            <h4 className="error-permission-title">
                {title}
            </h4>
            <p className="error-permission-subtitle">
                {content}
            </p>
            <div>
                <DefaultButton
                    variant="danger"
                    onClick={closeModal}
                >
                    OK
                </DefaultButton>
            </div>
        </div>
    );
};

export default ErrorPrompt;
