import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { getModalDisplayStatus } from "./selector";
import * as TenantActions from "./actions";
import { AppState } from "../../../../store";
import CreateTenantPrompt from "./components/CreatePrompt";
import partner_api from "../../../../services/Partner_api/index";
import orderBy from "lodash/orderBy";
import storage from "local-storage-fallback";
import * as notificationActions from "./../../../../components/Notification/actions";
// import { useFlags } from 'launchdarkly-react-client-sdk';
import Can from "../../../../components/Can";
import { CUSTOMERS_EDIT } from "../../../../authRules";
import { last } from "lodash";
import LyveHeading from "../../../../components/LyveHeading";
import { DefaultButton } from "../../../../components/LyveButton";
import { Plus } from "react-bootstrap-icons";
import LyveTable from "../../../../components/LyveTable";
import { LyveStatusDot } from "../../../../components/LyveBadge";
import { ModalContainer } from "../../../../components/Modal";
import { MoreHoriz, Add as AddIcon } from "@material-ui/icons";
import ContextMenu from "../../../../components/ContextMenu";
import { X as CloseIcon } from "react-bootstrap-icons";

import "./styles.scss";

interface TenantProps {
  setNotification?: any;
}

interface Data {
  id: string;
  name: string;
  short_name: string;
  enabled: boolean;
  partner_id: string;
  sys_created_by: string;
  first_name: string;
  last_name: string;
  org_name: string;
  first_admin: string;
}

interface Tenant {
  id: string;
  name: string;
  short_name: string;
  enabled: boolean;
  partner_id: string;
  sys_created_by: string;
  first_name: string;
  last_name: string;
  org_name: string;
  first_admin: string;
}

interface ListTenantResponse {
  tenants: Tenant[];
}

const Tenant: React.FC<TenantProps> = ({ setNotification }) => {
  const [dataInitialized, setDataInitialized] = React.useState<boolean>(false);
  const [tenant, setTenant] = React.useState<Data[]>([]);
  const { showModal } = ModalContainer.useContainer();
  const [showMenuId, setShowMenuId] = React.useState("");
  const [statusProcessingId, setStatusProcessingId] = React.useState<
    string | null
  >(null);

  const constructUserList = (res: ListTenantResponse) => {
    let tenantList: Data[] = [];
    if (res && res.tenants) {
      // temporarily construct usage field
      tenantList = res.tenants.map(
        (b: Tenant): Data => {
          return {
            id: b.id,
            name: b.name,
            short_name: b.short_name,
            partner_id: b.partner_id,
            enabled: b?.enabled ?? false,
            sys_created_by: b.sys_created_by,
            first_name: b.first_name,
            last_name: b.last_name,
            org_name: b.org_name,
            first_admin: b.first_admin
          };
        }
      );
    }
    setTenant(tenantList);
  };

  React.useEffect(() => {
    partner_api
      .listTenants()
      .then(res => {
        constructUserList(res);
        setDataInitialized(true);
      })
      .catch(err => {
        setDataInitialized(true);
      });
  }, []); // empty array ensures the effect is ran only once on component mount

  const noTenantCreated = () => {
    return dataInitialized && tenant.length === 0;
  };

  const handleUpdateCustomer = (
    id: string,
    short_name: string,
    enabled: boolean
  ) => {
    setStatusProcessingId(id);
    partner_api
      .updateTenant(short_name, enabled)
      .then(() => {
        partner_api.listTenants().then(res => {
          constructUserList(res);
        });
        setStatusProcessingId(null);
        if (enabled) {
          setNotification(`${short_name} has been enabled .`);
        } else {
          setNotification(`${short_name} has been disabled .`);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const listAllTenants = (email: string) => {
    setDataInitialized(false);
    setNotification(`An invitation email has been sent to ${email}.`);
    partner_api
      .listTenants()
      .then(res => {
        constructUserList(res);
        setDataInitialized(true);
      })
      .catch(err => {
        setDataInitialized(true);
      });
  };

  /** Datatable */
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All"
  };

  const customSort = (
    rows: Data[],
    field: string,
    direction: "desc" | "asc"
  ) => {
    const handleField = (row: any) => {
      const val = row[field];
      if (val && typeof val === "string") {
        return val.toLowerCase();
      }
      return val;
    };

    return orderBy(rows, handleField, direction);
  };

  const columns = [
    {
      name: "Organization Name",
      selector: "org_name",
      sortable: true,
      minWidth: "300px",
      maxWidth: "400px",
      cell: (row: Data) => <span title={row.org_name}>{row.org_name}</span>
    },
    {
      name: "First Name",
      selector: "first_name",
      sortable: true,
      minWidth: "200px",
      maxWidth: "350px",
      cell: (row: Data) => {
        return <span title={row.first_name}>{row.first_name}</span>;
      }
    },
    {
      name: "Last Name",
      selector: "last_name",
      sortable: true,
      minWidth: "200px",
      maxWidth: "650px",
      cell: (row: Data) => <span title={row.last_name}>{row.last_name}</span>
    },
    {
      name: "Email",
      selector: "first_admin",
      minWidth: "300px",
      maxWidth: "200px",
      sortable: true,
      cell: (row: Data) => (
        <span title={row.first_admin}>{row.first_admin}</span>
      )
    },
    {
      name: "Status",
      selector: "enabled",
      minWidth: "100px",
      maxWidth: "200px",
      sortable: true,
      cell: (row: Data) =>
        row.id === statusProcessingId ? (
          <div className="col-12">
            <div
              className="spinner-border spinner-border-sm m-3"
              style={{ color: "#6ebe49" }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          renderReadOnlyStatus(row)
        )
    },
    {
      name: "",
      minWidth: "100px",
      maxWidth: "500px",
      right: true,
      cell: (row: Data) => (
        <Can perform={CUSTOMERS_EDIT}>
          <ContextMenu
            id="customers"
            drop={"down"}
            trigger={
              <div>
                {showMenuId === row.id ? (
                  <button
                    type="button"
                    className="btn btn-icon table-close-icon"
                    onClick={() => setShowMenuId("")}
                    onBlur={() => setShowMenuId("")}
                  >
                    <CloseIcon size="24" color="var(--gray-9)" />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-icon"
                    onClick={() => setShowMenuId(row.id)}
                    onBlur={() => setShowMenuId("")}
                  >
                    <MoreHoriz />
                  </button>
                )}
              </div>
            }
            parentDropdownClass="table-menu"
            hasManualToggle={true}
            onToggle={(isOpen, _event, _meta) => {
              if (!isOpen) {
                setShowMenuId("");
              }
            }}
            manualShow={showMenuId === row.id}
            alignRight={true}
            minWidth="200px"
            options={getCustomerActions(row)}
          />
        </Can>
      )
    }
  ];

  const getCustomerActions = (row: Data) => {
    const options: any = [
      {
        label: row.enabled ? "Disable" : "Enable",
        onClick: () => {
          setShowMenuId("");
          handleUpdateCustomer(
            row.id,
            row.short_name,
            row.enabled ? false : true
          );
        }
      }
    ];

    return options;
  };

  const renderReadOnlyStatus = (row: Data) => {
    if (row.enabled) {
      return (
        <LyveStatusDot color="var(--green-3)" size={8}>
          <span title="Enabled">Enabled</span>
        </LyveStatusDot>
      );
    } else {
      return (
        <LyveStatusDot color="var(--gray-6)" size={8}>
          <span title="Disabled">Disabled</span>
        </LyveStatusDot>
      );
    }
  };

  const showAddCustomerModal = () => {
    showModal({
      header: true,
      title: "Create Customer",
      body: closeModal => (
        <CreateTenantPrompt
          hideModal={closeModal}
          onSuccess={(email: string) => {
            listAllTenants(email);
          }}
          onFailure={() => {
            console.log("error");
          }}
        />
      ),
      baseProps: { size: "sm", dialogClassName: "lyve-modal" }
    });
  };

  return (
    <div className="container-fluid">
      <div className="row no-gutter">
        {dataInitialized ? (
          <div className="col">
            <div className="customers-wrapper">
              <LyveHeading
                title="Customers"
                subTitle="Create and manage customers."
              >
                <Can perform={CUSTOMERS_EDIT}>
                  <DefaultButton
                    variant="primary"
                    onClick={showAddCustomerModal}
                  >
                    <Plus size="24" className="add-icon" />
                    Create Customer
                  </DefaultButton>
                </Can>
              </LyveHeading>
              {noTenantCreated() && (
                <Can perform={CUSTOMERS_EDIT} no={"There are no customers"}>
                  You do not have any customers. Create one now.
                </Can>
              )}
              {!noTenantCreated() && (
                <LyveTable
                  columns={columns}
                  data={tenant}
                  sortFunction={customSort}
                  defaultSortField="name"
                  defaultSortAsc={true}
                  selectableRows={false}
                  pagination={true}
                  noHeader={true}
                  paginationPerPage={10}
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  highlightOnHover={true}
                  paginationComponentOptions={paginationOptions}
                  noDataComponent={<div />}
                  paginationIconFirstPage={false}
                  paginationIconLastPage={false}
                  responsive={true}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="col">
            <div className="row align-items-center" style={{ height: 600 }}>
              <div className="col text-center">
                <div
                  className="spinner-border"
                  style={{ color: "#6ebe49" }}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state: AppState) => ({
  createModalDisplayStatus: getModalDisplayStatus(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleDisplayModal: () => dispatch(TenantActions.toggleDisplayModal()),
  setNotification: (message: string) =>
    dispatch(notificationActions.setNotification(message))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Tenant);
