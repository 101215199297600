import React from "react";
import BaseCard from "./BaseCard";
import { CardProps } from "../../scenes/SmartStartHere/types.d";
import { ChevronRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { getLink, linkNames } from "../LyveHeadingLink/LinkUtil";

const SThreeClientsCard = (props: CardProps) => {
  return (
    <BaseCard
      {...props}
      className="sthree-clients-card"
      title="Managing bucket data"
      description="Read and write, copy, move files, and more, using S3 compatible browsers such as Cyberduck or S3 browser."
      footer={
        <a
          href={getLink(linkNames.Connect_s3_to_lyveCloud)}
          target="_blank"
          className="base-card-footer-link"
        >
          <span className="mr-1"> Learn more </span>
          <ChevronRight size={10} />
        </a>
      }
    />
  );
};

export default SThreeClientsCard;
