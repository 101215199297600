export enum StoreStateStatus {
  IDLE = 'idle',
  LOADING = "loading",
  RESOLVED = "resolved",
  REJECTED = "rejected"
}

export declare type SliceState<D> = {
  status: StoreStateStatus;
  error: Error | null;
  data: D;
};
