import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { createUseStyles } from "react-jss";
import api from "../../../services/api/index";

const useStyles = createUseStyles({
  modalBody: {
    padding: "0px !important",
  },
  error: {
    fontSize: 14,
    lineHeight: "20px",
    color: "#dc1f2e",
    marginTop: "0px",
  },
  success: {
    fontSize: 14,
    lineHeight: "20px",
    color: "#6ebe49",
    marginTop: "0px",
  },
});

export default function PartnerJoinMd(props: any) {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [error, setError] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const handleClose = () => {
    setFullName("");
    setEmail("");
    setCompanyName("");
    setShow(false);
    props.handleClose();
  };

  const handleBeAParnter = () => {
    if (!fullName.trim()) {
      setError("Please input Full Name.");
    } else if (!email.trim()) {
      setError("Please input Email.");
    } else if (!companyName.trim()) {
      setError("Please input Company Name.");
    } else if (!validateEmail(email.trim())) {
      setError("Please enter a valid email address.");
    } else {
      //send mail to
      api
        .beAPartner(fullName, email, companyName)
        .then((resBody: any) => {
          alert("Send email successfully.");
          handleClose();
        })
        .catch((err) => {
          setError(err);
        });
    }
  };

  const validateEmail = (value: string) => {
    if (
      /^[a-z0-9][-_.+!#$%&'*/=?^`{|]{0,1}([a-z0-9][-_.+!#$%&'*/=?^`{|]{0,1})*[a-z0-9]@[a-z0-9][-.]{0,1}([a-z][-.]{0,1})*[a-z0-9].[a-z0-9]{1,}([.-]{0,1}[a-z]){0,}[a-z0-9]{0,}$/.test(
        value
      )
    ) {
      return true;
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        animation={false}
        backdrop={false}
        size="lg"
      >
        {/* <Modal.Header closeButton>
          <Modal.Title style={{ paddingLeft: 15 }}>
            
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body className={classes.modalBody}>
          <div className="leftModal">
            <h6>
              Welcome!
              <br />
              Feed us some details
            </h6>
            <h5>We are more than just storage solution</h5>
            <p>
              We provide not just what you need but what you'll love the most.
              We offer services that is vital for your business and your
              everyday life.
            </p>

            <p>
              By providing us your details, you will know more about our awesome
              service.
            </p>
          </div>

          <div className="rightModal">
            <div className="close">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true" style={{ cursor: "pointer" }}>
                  x
                </span>
              </button>
            </div>
            <div className="formCtn">
              <form>
                <div className="form-group">
                  <span className="modalLabel">Full Name *</span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    value={fullName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setError("");
                      setFullName(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group">
                  <span className="modalLabel">Email *</span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setError("");
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group">
                  <span className="modalLabel">Company Name</span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    value={companyName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setError("");
                      setCompanyName(e.target.value);
                    }}
                  />
                </div>
                {error !== "" && (
                  <div className={classes.error} color="error">
                    {`${error}`}
                  </div>
                )}
                {/* {message !== "" && (
                  <div className={classes.success} color="error">
                    {`${message}`}
                  </div>
                )} */}
                <button
                  type="button"
                  className="btn btn-success btnBaseColor"
                  style={{ width: "100%" }}
                  onClick={handleBeAParnter}
                >
                  Be A Partner
                </button>
              </form>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
}
