import React from "react";
import cx from "classnames";
import PaperCard from "./PaperCard";

interface IOwnProps {
  /** Title of the card */
  title?: React.ReactNode | null;
  /** Body of the card */
  body?: React.ReactNode;
  /** Optional classes */
  className?: string;
  /** To show the loading state */
  loading?: boolean;
}

/**
 * A basic variant of the card consisting of a title and a body.
 * @returns {*} React Component
 */
const BasicCard = ({ title, body, className, loading }: IOwnProps) => {
  const cardProps: any = { className: cx("basic-card", className), loading }

  if (body) {
    cardProps.body = <div className="body">{body}</div>
  }

  if (title) {
    cardProps.header = <div className="title">{title}</div>
  }

  return (
    <PaperCard {...cardProps} />
  );
};

export default BasicCard;
