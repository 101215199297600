import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as UserActions from '../../actions';
import { DefaultButton } from '../../../../../../components/LyveButton';
import { ButtonLoader } from '../../../../../../components/LyveLoader';
import api from '../../../../../../services/api';

interface OwnProps {
	selected: any;
	handleResetPassword?: any;
	isFormProcessing: boolean;
	setShowResetPwd: any;
	closeModal?: () => void;
	onSuccess?: () => void;
	onFailure?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		lyveModalHeading: {
			fontSize: 20,
			fontWeight: 500,
			marginBottom: '20px',
			lineHeight: '26px',
			color: '#1A1A1A'
		},
		lyveModalBody: {
			padding: 0
		},
		lyveModalClose: {
			height: 20,
			width: 20,
			display: 'inline-block'
		}
	})
);

const ResetPasswordPrompt: React.FC<OwnProps> = ({
	selected,
	handleResetPassword,
	isFormProcessing,
	setShowResetPwd
}) => {
	const classes = useStyles();

	return (
		 <>
		  {
		    <div className={`text-left ${classes.lyveModalBody}`}>
		      <h4 className={classes.lyveModalHeading}>
		        Are you sure you want to reset password for{" "}
		        <strong>{selected.email}</strong>?
		      </h4>
		    </div>
		  }
		  <div>
		    <DefaultButton
		      type="button"
		      className="mr-2"
		      disabled={isFormProcessing}
		      onClick={event => {
		        event.preventDefault();
		        handleResetPassword({
		          name: selected.first_name,
		          email: selected.email
		        });
		      }}
		    >
		      {isFormProcessing ? <ButtonLoader animation="border" /> : "Yes"}
		    </DefaultButton>

		    <DefaultButton
		      type="button"
		      variant="outline-secondary"
		      disabled={isFormProcessing}
		      onClick={event => {
		        event.preventDefault();
		        setShowResetPwd(false);
		      }}
		    >
		      No
		    </DefaultButton>
		  </div>
		</>
	);
};

export default ResetPasswordPrompt;
