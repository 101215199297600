import { SelectedMonth, SelectedMonthRange } from "./types";

export const SET_MONTHRANGE = 'SET_MONTHRANGE';
export const CLEAR_MONTHRANGE = 'CLEAR_MONTHRANGE';
export const SET_MONTHITEMS = 'SET_MONTHITEMS';

// Type checking and action creator
export interface setMonthRangeAction {
    type: typeof SET_MONTHRANGE;
    payload: SelectedMonthRange;
}

export interface clearMonthRangeAction {
    type: typeof CLEAR_MONTHRANGE;
}

export interface setMonthItemAction {
    type: typeof SET_MONTHITEMS;
    payload: Array<SelectedMonth>;
}

// Action Items
export type MonthItemActionTypes =
    | setMonthRangeAction
    | clearMonthRangeAction
    | setMonthItemAction