import React from "react";
import { Modal } from "react-bootstrap";
import LoadingAnimation from "./LoadingAnimation";

import "./styles.scss";

interface IStateLabel {
  title: string;
  subtitle: string;
}

interface LoadingStateOverlayProps {
  stateLabelList: IStateLabel[];
  intervalTime?: number;
  handleErrorCondition: () => void;
}

const LoadingStateOverlay: React.FC<LoadingStateOverlayProps> = ({
  stateLabelList,
  intervalTime = 4,
  handleErrorCondition
}) => {
  const [counter, setCounter] = React.useState(0);
  const intervalRef = React.useRef<any>(null);
  const counterValueRef = React.useRef(0);

  React.useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (counterValueRef.current >= intervalTime) {
        clearInterval(intervalRef.current);
        setCounter(0);
        counterValueRef.current = 0;
        handleErrorCondition();
      } else {
        counterValueRef.current = counterValueRef.current + 1;
        setCounter(counterValueRef.current);
      }
    }, 5000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <Modal
      show={true}
      backdrop="static"
      centered={true}
      dialogClassName="loading-state-modal"
    >
      <Modal.Body>
        <div className="d-flex flex-column align-items-center">
          <div className="title mt-3">{stateLabelList[counter].title}</div>
          <div className="subtitle mb-2">
            {stateLabelList[counter].subtitle}
          </div>
          <LoadingAnimation />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoadingStateOverlay;
