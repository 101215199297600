import React from "react";
import cx from "classnames";

import PaperCard from "./PaperCard";

interface IOwnProps {
  /** URL of the external resource */
  resourceURL: string;
  /** Label used for the external resource */
  resourceLabel: React.ReactNode;
  /**
   * Target tab. eg: "_blank"
   * @default ""
   */
  target?: string;
  /** Icon element */
  icon?: React.ReactNode;
  /** Description of the card */
  description?: React.ReactNode;
  /** Image source */
  imgSrc?: string;
  /** Image overlay component */
  imgOverlay?: React.ReactNode;
  /** Class Name */
  className?: string;
}

/**
 * A media card consisting of content related to resources.
 * @returns {*} React Component
 */
const MediaCard = ({
  resourceURL,
  resourceLabel,
  target = "",
  description,
  icon,
  imgOverlay,
  imgSrc,
  className,
}: IOwnProps) => {
  const renderBody = () => (
    <>
      {icon && <div className="resource-icon">{icon}</div>}
      <div>
        <a href={resourceURL} target={target} className="resource-url">
          {resourceLabel}
        </a>
        <p className="description">{description}</p>
      </div>
    </>
  );

  return (
    <PaperCard
      src={imgSrc}
      alt={resourceLabel as string}
      imgOverlay={imgOverlay}
      body={renderBody()}
      className={cx("media-card", className)}
    />
  );
};

export default MediaCard;
