import React, { useState } from "react";
import { Modal, Row, Col, CardDeck, Card } from "react-bootstrap";

import image1 from "../../../../../images/support/R3-HowtoCreateBuckets.png";
import image2 from "../../../../../images/support/R3-HowtoDeleteBuckets.png";
import image3 from "../../../../../images/support/LyveCloudConsole.png";
import { MediaCard } from "../../../../../components/Card";
import { VideoPlayer } from "../../../../../components/Media";
import LyveHeading from "../../../../../components/LyveHeading";

// TODO: move this to relevant config file
const resources = [
  {
    label: "Administrator’s Guide",
    url: "/resources/pdf/Console Administrator's Guide.pdf",
    icon: "fa fa-book-open support-page-icon card-icon",
    description:
      "Comprehensive information for setting up and managing Lyve Cloud."
  },
  {
    label: "API Guide",
    url: "/resources/pdf/API Compatibility Guide.pdf",
    icon: "fa fa-cubes support-page-icon card-icon",
    description:
      "Reference manual containing the information required to work with the Lyve Cloud API."
  },
  {
    label: "Connecting Open Source Browsers",
    url: "/resources/pdf/Connecting S3 clients to LyveCloud.pdf",
    icon: "fa fa-desktop support-page-icon card-icon",
    description:
      "Instructions on how to connect open source cloud storage browsers such as S3 Browser and Cyberduck."
  }
];

const videoResources = [
  {
    imgSrc: image1,
    imgOverlay: "R3-HowtoCreateBuckets",
    resourceLabel: "How to Create Buckets",
    description: "This video shows you how to create buckets and write data."
  },
  {
    imgSrc: image2,
    imgOverlay: "R3-HowtoDeleteBuckets",
    resourceLabel: "How to Delete Buckets",
    description: "This video shows you how to remove buckets from Lyve Cloud."
  },
  {
    imgSrc: image3,
    imgOverlay: "LyveCloudConsole",
    resourceLabel: "Lyve Cloud Console",
    description:
      "This video walks you through the Lyve Cloud console page to track and monitor usage, buckets, support tickets, and more."
  }
];

const Resource: React.FC = () => {
  const [show, setShow] = useState(false);
  const [video, setVideo] = useState<string>("");
  const handleClose = () => setShow(false);

  const handleShow = (e: React.MouseEvent<HTMLDivElement>, value: string) => {
    setVideo(value);
    setShow(true);
  };

  const preventRightClick = (e: React.MouseEvent<HTMLVideoElement>) => {
    e.preventDefault();
  };

  const renderImageOverlay = (videoURL: string) => (
    <div
      className="resource-video"
      onClick={e => {
        handleShow(e, videoURL);
      }}
    >
      <div className="overlay-icon">
        <i className="far fa-play-circle"></i>
      </div>
    </div>
  );

  return (
    <>
      <div className="support-root">
        <Row className="support-resource">
          <Col xs="12" sm="10" md="10" lg="12">
            <div className="IAM-support-page-bodyContent scroll">
              <Row>
                <Col>
                  <LyveHeading
                    title="Resources"
                    subTitle=""
                    marginRightStyle={true}
                  ></LyveHeading>
                </Col>
              </Row>
              <Row className="card-deck-row pt-0">
                <Col xs="12" md="4">
                  <MediaCard
                    resourceLabel="Quick Start Guide"
                    resourceURL="/resources/pdf/Console Quick Start Guide.pdf"
                    target="_blank"
                    icon={
                      <i className="fa fa-clipboard-list support-page-icon card-icon" />
                    }
                    description="Fast and easy instructions to get started with Lyve Cloud."
                  />
                </Col>
              </Row>
              <div className="row section-header">
                <Col>
                  <LyveHeading
                    title="Guides"
                    subTitle="A collection of guides designed to help users work
                    effectively with Lyve Cloud."
                    marginRightStyle={true}
                  ></LyveHeading>
                </Col>
                {/* <div className="col-12 col-sm-12">
                  <h3 className="support-page-header">Guides</h3>
                </div>
                <div className="col-12 col-sm-12">
                  <p className="support-page-subtitle">
                    A collection of guides designed to help users work
                    effectively with Lyve Cloud
                  </p>
                </div> */}
              </div>
              <CardDeck className="card-deck-row">
                {resources.map((resource, index) => (
                  <MediaCard
                    key={`resource-${index}`}
                    resourceLabel={resource.label}
                    resourceURL={resource.url}
                    target="_blank"
                    icon={<i className={resource.icon} />}
                    description={resource.description}
                  />
                ))}
              </CardDeck>
              <div className="row section-header">
                <Col>
                  <LyveHeading
                    title="Training"
                    subTitle="Short tutorial videos on how to perform some of the
                    workflows."
                    marginRightStyle={true}
                  ></LyveHeading>
                </Col>
                {/* <div className="col-12 col-sm-12">
                  <h3 className="support-page-header">Training</h3>
                </div>
                <div className="col-12 col-sm-12">
                  <p className="support-page-subtitle">
                    Short tutorial videos on how to perform some of the
                    workflows
                  </p>
                </div> */}
              </div>
              <CardDeck className="card-deck-row">
                {videoResources.map((resource, index) => (
                  <MediaCard
                    key={`video-resource-${index}`}
                    imgSrc={resource.imgSrc}
                    imgOverlay={renderImageOverlay(resource.imgOverlay)}
                    resourceLabel={resource.resourceLabel}
                    resourceURL="#"
                    description={resource.description}
                  />
                ))}
              </CardDeck>
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        size="lg"
        className={"support-resource-video-modal"}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header
          closeButton
          className={"support-resource-video-modal-header"}
        ></Modal.Header>
        <Modal.Body className={"support-resource-video-modal-body"}>
          <div className={"support-resource-video player-wrapper"}>
            <VideoPlayer
              url={"/resources/videos/" + video + ".mp4"}
              playing={true}
              width="100%"
              height="100%"
              controls={true}
              volume={1}
              onContextMenu={preventRightClick}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                    disablePictureInPicture: true
                  }
                }
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Resource;
