import api from "../api";
import storage from "local-storage-fallback";

export class Common {
  logoutCleanup = (res: any) => {
    api.cleanLocalUserStorage();
    if (res) {
      window.location.href = res.redirect_url;
    } else {
      const loginUrl = api.getLoginUrl();
      window.location.href = loginUrl;
    }
  };

  isPartner(type: string | null) {
    return type === "partner";
  }

  isTenant(type: string | null) {
    return type === "tenant";
  }

  isDirectCustomer(type: string | null) {
    return type === "direct";
  }

  isAnyCustomer(type: string | null) {
    return type === "direct" || type === "tenant";
  }

  isValidType(type: string | null) {
    return type === "direct" || type === "tenant" || type === "partner";
  }
}

const common = new Common();
export default common;


export const setBreakGlassUser = () => {
  storage.setItem("break-glass-user", "1")
}

export const removeBreakGlassUser = () => {
  storage.removeItem("break-glass-user")
}

export const checkBreakGlassUser = () => {
  return storage.getItem("break-glass-user") === "1"
}
