import React from "react";
import { QuestionCircleFill } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { MetricCard } from "../../../../../../components/Card";
import { WhoAmI } from "../../../../../../state/UserInfo/types";
import { AppState } from "../../../../../../store";

const AvailabilityCard: React.FC = () => {
  const whoAmI = useSelector<AppState, WhoAmI>(state => state.userInfo.whoAmI);
  const getPercentage = (value: number) => (value * 100 || 0).toFixed(2);
  const slaPercentage = getPercentage(parseFloat(whoAmI.sla_availability!))
  const slaAvailability = Number(slaPercentage)

  const renderLabelWithTooltip = (
    label: string,
    tooltipId: string,
    description: string
  ) => {
    return (
      <>
        <span className="label">{label}</span>
        <QuestionCircleFill
          size="16"
          data-for={tooltipId}
          data-tip={description}
        />
        <ReactTooltip
          id={tooltipId}
          effect="solid"
          multiline
          place="right"
          className="metric-card-tooltip"
        />
      </>
    );
  };

  const renderAvailability = () => {
    return (
      <span className="availability-section">
        {slaPercentage}
        <span className="subtext">%</span>
      </span>
    );
  };

  if (!slaAvailability) {
    return null
  }

  return (
    <MetricCard
      value={renderAvailability()}
      label={renderLabelWithTooltip(
        "Availability",
        "availability",
        "Lyve Cloud availability for this month"
      )}
    />
  );
};

export default AvailabilityCard;
