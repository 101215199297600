import React from "react";
import { RouteComponentProps } from "react-router";
import queryString from "query-string";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import request from "superagent";
import newlogo from "../../images/LyveCloud-CenterAlign.png";
import background from "../../images/bg.png";
import api from "../../services/api";

//http://awsdikubl030-usc1s.aws.seagate.com:31000/activate-account?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0IjoiMmY0MGI5MjgtZGVjMS00YmQ2LTllZTQtY2RlNTY3OTE4YTZhIn0.4sLOPnqrpF0Fw1jVdiehHe7tynNsv7vsnZfOU-bUcdE

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "flex-start",
      flexGrow: 1,
      height: "100vh",
      backgroundColor: theme.palette.background.default,
      backgroundImage: `url(${background})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      overflow: "auto",
    },
    container: {
      marginTop: theme.spacing(7.5),
    },
    paper: {
      width: 700,
      padding: theme.spacing(5, 6),
    },
    mainHeading: {
      width: 150,
      marginBottom: theme.spacing(2.75),
    },
    subHeading: {
      fontSize: 24,
      fontWeight: 500,
      marginBottom: theme.spacing(0.75),
    },
    emailText: {
      display: "block",
      textAlign: "center",
      marginBottom: theme.spacing(2.25),
      fontSize: 16,
    },
    emailTextBold: {
      fontWeight: 500,
    },
    error: {
      fontSize: 11,
      color: theme.palette.error.light,
      marginTop: theme.spacing(0.5),
    },
    formControl: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    inputLabel: {
      fontSize: 18.6,
      lineHeight: "26.6px",
      color: theme.palette.grey[600],
      "&.Mui-focused": {
        color: theme.palette.grey[600],
      },
      "& + .MuiInput-formControl": {
        marginTop: theme.spacing(3.5),
      },
    },
    input: {
      fontSize: 13,
      borderRadius: 3,
      border: "1px solid #d4d4d4",
      padding: theme.spacing(0.125, 0, 0.125, 1.25),
      "&.Mui-focused": {
        borderColor: "#777",
      },
    },
    iconContainer: {
      left: theme.spacing(0.25),
      "&:hover": {
        backgroundColor: "#ffffff",
      },
    },
    inputIcon: {
      fontSize: 14,
      color: theme.palette.primary.main,
    },
    inputIconVisible: {
      fontSize: 14,
      color: theme.palette.grey[400],
    },
    validationContainer: {
      marginTop: theme.spacing(0.5),
      padding: theme.spacing(0.125, 0),
    },
    validationItem: {
      display: "inline-flex",
      marginRight: theme.spacing(0.5),
      fontSize: 11,
      color: theme.palette.grey[700],
      alignItems: "initial",
    },
    validationIcon: {
      fontSize: 13,
      marginRight: theme.spacing(0.375),
      color: "#d4d4d4",
    },
    validationIconValid: {
      color: "#6ebe49",
    },
    spinner: {
      color: theme.palette.primary.contrastText,
      position: "relative",
      top: theme.spacing(0.375),
    },
    submit: {
      display: "block",
      paddingTop: theme.spacing(0.625),
      paddingBottom: theme.spacing(0.625),
      fontSize: 12,
      fontWeight: 500,
      textTransform: "capitalize",
      backgroundColor: theme.palette.primary.main,
      "&.Mui-disabled": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        opacity: 0.6,
      },
    },
    bottomText: {
      textAlign: "center",
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(0.75),
      fontSize: 14,
    },
    bottomLink: {
      textAlign: "center",
      fontSize: 13,
      marginBottom: theme.spacing(4.5),
    },
    copyright: {
      textAlign: "center",
      marginTop: theme.spacing(4),
      fontSize: 12,
      color: "#adb5bd",
    },
  })
);

const ActivateAccount: React.FC<RouteComponentProps> = ({ location }) => {
  const classes = useStyles();
  const [invalidToken, setInvalidToken] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");

  React.useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed && parsed.t && typeof parsed.t === "string") {
      const urlToken = parsed.t;
      activeAccount(urlToken);
    }
  }, []);

  const activeAccount = (url_token: String) => {
    const url = "/api/v2/users/activate_account";
    return request("POST", url)
      .send({
        url_token: url_token,
      })
      .then((res) => {
        window.location.href = api.getLoginUrl();
      })
      .catch((e) => {
        // debugger;
        console.log("errors");
        console.log(e);
        const errMsg = e.response.body.message;
        // debugger;
        if (errMsg.indexOf("invalid URL Token") > -1) {
          setError("The token provided is invalid.");
        } else if (errMsg.indexOf("expired-token") > -1) {
          let expiredParts = errMsg.split("|");
          if (expiredParts.length == 2) {
            let userName = expiredParts[1];
            window.location.href = "/expired-token/" + userName;
          } else {
            setError(errMsg);
          }
        } else {
          setError(errMsg);
        }
        setInvalidToken(true);
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.root}>
        <Grid
          className={classes.container}
          container
          spacing={2}
          justify="center"
          direction="row"
        >
          <Grid container justify="center">
            <img
              src={newlogo}
              className={classes.mainHeading}
              alt="Seagate Lyve Services"
            />
          </Grid>
          <Paper className={classes.paper}>
            {invalidToken ? (
              <Grid item xs={12}>
                {/* <Typography
                  className={classes.subHeading}
                  align="center"
                  variant="h4"
                >
                  Wrong Token
                </Typography> */}
                <Typography
                  className={classes.subHeading}
                  align="center"
                  variant="body1"
                >
                  {error}
                </Typography>
                <Typography align="center">
                  <p>
                    <Link href="mailto:support.lyvecloud@seagate.com">
                      Contact Support
                    </Link>
                  </p>
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
          </Paper>
          <Grid container justify="center" className={classes.copyright}>
            Copyright 2020 Seagate Technology LLC
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ActivateAccount;
