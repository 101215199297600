import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Routes from "./Routes";

import "bootstrap/dist/css/bootstrap.css";
import "./styles/custom-highcharts.scss";
// import "bootstrap-icons/font/bootstrap-icons.css";
import "./index.css";
import "./styles/material-ui-icon.css";
import "./styles/custom-style.css";
import "./styles/fonts/fonts-googleapis.css";
import "./styles/green-theme.css";

import "./styles/assets/fontawesome-free-5.12.1-web/css/all.css";

import "typeface-roboto";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme/main-theme";
import { ModalContainer } from "./components/Modal";

import "./theme/variables.scss";
import { SmartStartProvider } from "./scenes/SmartStartHere/useSmartStartKit";
import { GlobalProvider } from "./state/GlobalStateContext";
import store from "./store";
import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";

import "./styles/global.scss";

declare global {
  interface Window {
    // REACT_APP_SHOWMETRICS: any;
    // REACT_APP_LDCLIENTID: any;
    REACT_APP_TENANTS: any;
  }
}

const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ModalContainer.Provider>
          <GlobalProvider>
            <SmartStartProvider>
              <Routes />
            </SmartStartProvider>
          </GlobalProvider>
        </ModalContainer.Provider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
