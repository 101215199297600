import {
  TOGGLE_USER_DISPLAY_MODAL,
  USER_DISPLAY_MODAL,
  USER_HIDE_MODAL,
  ToggleUserDisplayModalActionTypes,
  UserDisplayModalAction,
  UserHideModalAction,
} from './types'

export function toggleDisplayModal(): ToggleUserDisplayModalActionTypes {
  return {
    type: TOGGLE_USER_DISPLAY_MODAL,
  }
}
export function displayModal(): UserDisplayModalAction {
  return {
    type: USER_DISPLAY_MODAL,
  }
}
export function hideModal(): UserHideModalAction  {
  return {
    type: USER_HIDE_MODAL,
  }
}
