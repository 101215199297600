export const TOGGLE_USER_DISPLAY_MODAL = 'TOGGLE_USER_DISPLAY_MODAL';
export const USER_DISPLAY_MODAL = 'USER_DISPLAY_MODAL';
export const USER_HIDE_MODAL = 'USER_HIDE_MODAL';

export interface UsersState {
  displayModal: boolean,
};

interface ToggleUserDisplayModalAction {
  type: typeof TOGGLE_USER_DISPLAY_MODAL
}

export interface UserDisplayModalAction {
  type: typeof USER_DISPLAY_MODAL
}

export interface UserHideModalAction {
  type: typeof USER_HIDE_MODAL
}

export type ToggleUserDisplayModalActionTypes = ToggleUserDisplayModalAction
