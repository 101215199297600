import React from "react";

import { Breadcrumb } from "react-bootstrap";
import { BreadCrumData } from "./types";

type IOwnProps = {
    breadcrumbs: BreadCrumData[];
    customClasses?: string;
};

const DefaultBreadCrumb: React.FC<IOwnProps> = (props) => {
    return (
        <Breadcrumb className={`lyve-breadcrumb ${(typeof props.customClasses !== "undefined") ? props.customClasses : ""}`}>
            {
                props.breadcrumbs.map((breadcrumb, index) => {
                    return (
                        <Breadcrumb.Item
                            href={breadcrumb.href}
                            target={breadcrumb.target}
                            key={index}
                            active={breadcrumb.active}
                            onClick={breadcrumb.onClick}>
                            {breadcrumb.title}
                        </Breadcrumb.Item>
                    );
                })
            }
        </Breadcrumb>
    );
};

export default DefaultBreadCrumb;