import React from "react";
import { CardDeck } from "react-bootstrap";

import { BasicCard } from "../../../../../../../../components/Card";
import LyveLink from "../../../../../../../../components/LyveHeadingLink/LyveLink";
import {
  getLink,
  linkNames
} from "../../../../../../../../components/LyveHeadingLink/LinkUtil";
import { DefaultButton } from "../../../../../../../../components/LyveButton";
import { getMasterAccountType } from "../../../../../../../../utils/subaccounts";

import "./styles.scss"
import moment from "moment";
import cx from "classnames";
import ToggleEnableSubAccount from "../../../ToggleEnableSubAccount";
import DeleteSubAccount from "../../../DeleteSubAccount";
import Can from "../../../../../../../../components/Can";
import {
  SUBACCOUNTS_MANAGE
} from "../../../../../../../../authRules";

export interface SubAccount {
  id: string,
  name: string,
  short_name: string,
  admin_first_name: string,
  admin_last_name: string,
  admin_email: string,
  org_name: string,
  address_line1: string,
  address_line2: string,
  city: string,
  state: string,
  country: string,
  zip_code: string,
  phone_number: string,
  parent_short_name: string,
  is_master: boolean,
  is_enabled: boolean,
  created_date: string,
  start_date: string,
  end_date: string,
  account_type: string,
  sub_account_max_limit: number,
  sub_account_support: boolean,
  sub_account_trial: boolean,
  sub_account_trial_ext_limit: number,
  sub_account_diff_org: boolean,
  view_support_ticket: boolean
}

interface IOwnProps {
  subaccount: SubAccount,
  masterAccount: SubAccount | null
}

const Settings: React.FC<IOwnProps> = ({
  subaccount,
  masterAccount,
}) => {
  const [isEnabled, setIsEnabled] = React.useState(subaccount.is_enabled);
  const toggleIsEnabled = () => setIsEnabled(!isEnabled)

  const daysSinceAccountDisabled = moment().diff(moment(subaccount.end_date), "days")
  const canDeleteSubAccount = !subaccount.is_enabled && daysSinceAccountDisabled >= 30
  const accountType = getMasterAccountType(masterAccount);

  return (
    <>
      <CardDeck>
        <BasicCard
          className="org-info-card"
          title="Organization Information"
          body={
            <div>
              <div className="settings-info-block">
                <div className="settings-info-title">Organization Name</div>
                <div className="settings-info-value">{subaccount.org_name}</div>
              </div>
              {
                subaccount.sub_account_support ?
                <>
                <div className="settings-info-block">
                  <div className="settings-info-title">Address</div>
                  <div className="settings-info-value">{subaccount.address_line1}</div>
                </div>
                <div className="setings-info-row">
                  <div className="settings-info-block">
                  <div className="settings-info-title">City</div>
                  <div className="settings-info-value">{subaccount.city}</div>
                </div>
                <div className="settings-info-block">
                  <div className="settings-info-title">State</div>
                  <div className="settings-info-value">{subaccount.state}</div>
                </div>
                <div className="settings-info-block">
                  <div className="settings-info-title">Zip Code</div>
                  <div className="settings-info-value">{subaccount.zip_code}</div>
                </div>
                <div className="settings-info-block">
                  <div className="settings-info-title">Country/Region</div>
                  <div className="settings-info-value">{subaccount && subaccount.country}</div>
                </div>
                </div>
                <div className="settings-info-block last">
                  <div className="settings-info-title">Phone Number</div>
                  <div className="settings-info-value">{subaccount.phone_number}</div>
                </div>
              </> : null
              }
            </div>
          }
        />
      </CardDeck>

      <CardDeck>
        <BasicCard
          title="Admin Details"
          body={
            <div>
              <div className="setings-info-row">
                <div className="settings-info-block last">
                  <div className="settings-info-title">First Name</div>
                  <div className="settings-info-value">{subaccount.admin_first_name}</div>
                </div>
                <div className="settings-info-block last">
                  <div className="settings-info-title">Last Name</div>
                  <div className="settings-info-value">{subaccount.admin_last_name}</div>
                </div>
                <div className="settings-info-block last">
                  <div className="settings-info-title">Email</div>
                  <div className="settings-info-value">{subaccount.admin_email}</div>
                </div>
              </div>
            </div>
          }
        />
      </CardDeck>


      {masterAccount && !accountType.isReseller ?
        <Can perform={SUBACCOUNTS_MANAGE}>
          <div className="danger-zone-title">Danger Zone</div>
          <CardDeck>
            <BasicCard
              className={cx({ "danger-action": isEnabled })}
              title={
                <div className="action-title">
                  <div>
                    <div className="header">
                      {isEnabled ? "Disable Sub-account" : "Enable Sub-account"}
                      <LyveLink link={getLink(linkNames.DISABLE_SUBACCOUNT)} />
                    </div>
                    <div className="settings-trial">
                      Enable / Disable sub-account.
                    </div>
                  </div>
                  <ToggleEnableSubAccount
                    subaccount={subaccount}
                    isEnabled={isEnabled}
                    onSuccess={toggleIsEnabled}
                  >
                    <DefaultButton
                      variant={isEnabled ? "danger" : "outline-dark"}
                      className="ml-3"
                    >
                      {isEnabled ? "Disable" : "Enable"}
                    </DefaultButton>
                  </ToggleEnableSubAccount>
                </div>
              }
            />
          </CardDeck>
          <CardDeck>
            <BasicCard
              className={cx({ "danger-action": canDeleteSubAccount })}
              title={
                <div className="action-title">
                  <div>
                    <div className="header">
                      Delete Sub-account
                      <LyveLink link={getLink(linkNames.DELETE_SUBACCOUNT)} />
                    </div>
                    <div className="settings-trial">
                        Sub-account can only be deleted at a minimum of 30 days after the account has been disabled.
                    </div>
                  </div>
                  <DeleteSubAccount subaccount={subaccount}>
                    <DefaultButton
                      variant="danger"
                      className={cx(
                        "ml-3",
                        { "not-allowed": !canDeleteSubAccount }
                      )}
                      disabled={!canDeleteSubAccount}
                    >
                      Delete
                    </DefaultButton>
                  </DeleteSubAccount>
                </div>
              }
            />
          </CardDeck>
        </Can>
      : null }
    </>
  );
};

export default Settings;
