export const TOGGLE_BUCKET_CREATE_MODAL = "TOGGLE_BUCKET_CREATE_MODAL";

export interface BucketsState {
  displayCreateModal: boolean;
}

export interface Bucket {
  name: string;
  creation_date: string;
  size: string;
  region: string;
  usage: number;
  compliance: boolean;
  versioned: boolean;
  logged: boolean;
  retentionDuration: number; //number of "days" or "years"
  retentionUnit: string;
  deletionMarker: boolean;
  complianceOnCreation?: boolean
}

export interface ViewBucketDetails {
  region: string;
  versioned?: boolean;
  compliance: {
    compliance?: boolean;
    retentionUnit: "Days" | "Years";
    retentionDuration: number;
    deletionMarker?: boolean;
    complianceOnCreation?: boolean;
  };
  auditLogs: AuditLogSettings;
  replicationRules: ReplicationRules;
  CustomerReplicationEnabled: boolean
}

export interface ViewBucketDetailsMeta {
  region: string;
  versioned?: boolean;
  compliance: {
    compliance?: boolean;
    retentionUnit: "Days" | "Years";
    retentionDuration: number;
    deletionMarker?: boolean;
    complianceOnCreation?: boolean;
  };
  auditLogs: AuditLogSettings;
  created_by: string;
  created_at: string;
  size: number;
  customerReplicationEnabled: boolean
}

export interface AuditLogSettings {
  bucketS3AuditLog?: boolean;
  TenantS3APIAuditLogsEnabled?: boolean;
  TenantlogAllBucketsEnabled?: boolean;
}

export interface ReplicationRules {
  bucket_replication_list: ReplicationRule[];
}

export interface ReplicationRule {
  source_bucket_name: string;
  target_bucket_name: string;
  replication_started: string;
}

export interface ListBucketResponse {
  buckets: Bucket[];
}

interface ToggleBucketCreateModalAction {
  type: typeof TOGGLE_BUCKET_CREATE_MODAL;
}

export type ToggleBucketCreateModalActionTypes = ToggleBucketCreateModalAction;

export interface ITag {
  key: string;
  value: string;
}

export interface BucketReplicationRule {
  replication_id: string;
  name: string;
  is_enabled: boolean;
  limited_scope: boolean;
  replicate_delete_markers: boolean;
  created_on: string;
  source_bucket_name: string;
  target_bucket_name: string;
  priority: number;
  prefix: string;
  tags: ITag[];
  source_region: string;
  target_region: string;
  // replication_started: string;
}