import { isNull } from "lodash";
import React from "react";
import api from "../../../../services/api";
import regionApis from "../../../../services/RegionApis";
import { Region } from "./index";
import { removeAddBucketCard } from "../../../../utils";

interface FormFields {
  bucketName: string;
  region: string;
  compliance: boolean;
  retentionDuration: number;
  retentionUnit: "Days" | "Years";
  deletionMarker: boolean;
}

interface FormValidations {
  bucketNameLength: boolean;
  bucketNameCharacters: boolean;
  bucketNameEdges: boolean;
  retentionDuration: boolean;
}

interface Error {
  error?: string;
  code?: number;
  message?: string;
}

const useCreateBucket = (
  regionList: Region[],
  allRegionsMap: Map<string, string> | undefined,
  onSuccess?: (isComplianceCreated: boolean, bucketName?: string) => void,
  onFailure?: () => void,
  handleInterval?: (formValues: any) => void,
  handleClearInterval?: () => void,
  formData?: any
) => {
  const defaultRegion = allRegionsMap?.keys()?.next()?.value;
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [serverError, setServerError] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error | null>(null);
  const defaultFormValues: FormFields = {
    bucketName: "",
    region: defaultRegion,
    compliance: false,
    retentionDuration: 30,
    retentionUnit: "Days",
    deletionMarker: false
  };
  const [formValues, setFormValues] = React.useState<FormFields>(
    defaultFormValues
  );
  const [validations, setValidations] = React.useState<FormValidations>({
    bucketNameLength: false,
    bucketNameCharacters: false,
    bucketNameEdges: false,
    retentionDuration: true
  });

  React.useEffect(() => {
    const bucketName = formValues.bucketName;
    const bucketNameLength = bucketName.length >= 3 && bucketName.length <= 63;
    const bucketNameCharacters = /^[a-z\d-]+$/.test(bucketName);
    const start = bucketName.charAt(0);
    const end = bucketName.charAt(bucketName.length - 1);
    const bucketNameEdges = /^[a-z0-9]$/.test(start)
      ? /^[a-z0-9]$/.test(end)
      : false;
    const retentionDuration = isRetentionDurationValid(
      formValues.retentionDuration,
      formValues.retentionUnit
    );

    setValidations({
      bucketNameLength,
      bucketNameCharacters,
      bucketNameEdges,
      retentionDuration
    });
  }, [
    formValues.bucketName,
    formValues.retentionDuration,
    formValues.retentionUnit
  ]);

  React.useEffect(() => {
    if (formData) {
      setFormValues({ ...defaultFormValues, ...formData });
    }
  }, [formData]);

  const isRetentionDurationValid = (value: number, unit: "Days" | "Years") => {
    //const pattern = /^[1-9]\d+$/;
    // const pattern = /^(?!.)\d+$/;
    const pattern = /^-?\d+$/;
    const isNumeric = pattern.test(value.toString());
    if (!isNumeric || value <= 0) return false; // Only positive integers

    // Max value 100 years
    if (unit === "Days" && value > 36500) return false;
    if (unit === "Years" && value > 100) return false;

    return true;
  };

  const handleFieldChange = (event: {
    target: { name: string; value: any };
  }) => {
    // if(event.target.name == "compliance" && !event.target.value && !formValues.retentionDuration){
    if (event.target.name == "compliance") {
      setValidations({
        ...validations,
        retentionDuration: true
      });

      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
        retentionDuration: 30
      });
    } else {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value
      });
    }

    setError(null);
    setServerError(false);
  };

  const handleSubmit = async () => {
    const region = formValues.region;

    const regionUrlList = regionList.map(regionObj =>
      regionObj.name === region ? regionObj.url : ""
    );
    const regionUrl = regionUrlList.find(url => url !== "");

    setProcessing(true);

    if (handleClearInterval) {
      handleClearInterval();
    }

    if (handleInterval) {
      handleInterval(formValues);
    }

    regionApis
      .makeBucket(
        regionUrl || "",
        formValues.bucketName,
        1,
        formValues.region,
        formValues.compliance,
        formValues.deletionMarker
      )
      .then((res: any) => {
        if (res?.name && formValues.compliance) {
          regionApis
            .updateComplianceDetails(
              regionUrl || "",
              formValues.bucketName,
              formValues.retentionDuration,
              formValues.retentionUnit,
              formValues.deletionMarker
            )
            .then(() => {
              onSuccess?.(true);
              setProcessing(false);
            })
            .catch(() => {
              onSuccess?.(false, formValues.bucketName);
              setProcessing(false);
            });
        } else {
          onSuccess?.(true);
          setProcessing(false);
        }
      })
      .catch(err => {
        setError(err);
        setServerError(true);
        setProcessing(false);
        onFailure?.();
      });
  };

  const handleSubmitToRegion = async (regionUrl: string) => {
    setProcessing(true);
    try {
      await regionApis.makeBucket(
        regionUrl,
        formValues.bucketName,
        1,
        formValues.region,
        formValues.compliance,
        formValues.deletionMarker
      );
      setProcessing(false);
      onSuccess?.(false);
    } catch (err) {
      setError(err);
      setServerError(true);
      setProcessing(false);
      onFailure?.();
    }
  };

  const resetForm = () => {
    setFormValues(defaultFormValues);
    setError(null);
    setServerError(false);
  };

  return {
    formValues,
    processing,
    error,
    serverError,
    handleSubmit,
    // handleSubmitToRegion,
    handleFieldChange,
    validations,
    resetForm
  };
};

export default useCreateBucket;
