import request from 'superagent';
import storage from 'local-storage-fallback';
import common from '../common';

export class PartnerAPI {
	// Request basics
	requestWithAuth(method: string, url: string) {
		const token: string = window.sessionStorage.getItem("token")!;
		return request(method, url).set('sessionId', token);
	}

	makeRequest(method: string, url: string, data?: object) {
		return this.requestWithAuth(method, url)
			.send(data)
			.then((res) => res.body)
			.catch((err) => this.errorHandler(err));
	}

	makeRequestWithCode(method: string, url: string, data?: object) {
		return this.requestWithAuth(method, url)
			.send(data)
			.then((res) => res.body)
			.catch((err) => this.errorHandler(err, true));
	}

	errorHandler(err: any, isCodeRequest?: boolean) {
		if (err.status) {
			// console.log(err)
			const errMessage: string = (err.response.body && err.response.body.error) || err.status.toString(10);
			// console.log(errMessage)
			let code = "" 
			if (err.response.body !== null){
				code = err.response.body.code;
			}
			if (err.status === 401) {
				common.logoutCleanup(null);
			  } else {
			if (isCodeRequest){
				return Promise.reject({message: errMessage, code: code});
			}else{
				return Promise.reject(errMessage);
			}
		}
		} else {
			return Promise.reject('Unknown error');
		}
	}

	setUserInfo(whoAmI: any): void {
		storage.setItem('partner_user_id', whoAmI.user.id);
		storage.setItem('partner_user_email', whoAmI.user.email);
		storage.setItem('partner_user_name', whoAmI.user.name);
		storage.setItem('partner_name', whoAmI.company);
	}

	// Login/logout
	setAccessToken(token: string): void {
		storage.setItem('token', token);
	}

	isLoggedIn() {
		return !!storage.getItem('token');
	}

	getPartnerUserInfo() {
		return {
			id: storage.getItem('partner_user_id'),
			email: storage.getItem('partner_user_email'),
			name: storage.getItem('partner_user_name')
		};
	}

	listTenants() {
		const url = '/api/v2/partner/tenants';
		return this.makeRequest('GET', url);
	}

	addTenant(name: string, first_name: string, last_name: string, email: string) {
		const url = '/api/v2/partner/tenants';
		const data = {
			name: name,
			shortname: name,
			user_first_name: first_name,
			user_last_name: last_name,
			user_email: email
		};
		return this.makeRequestWithCode('POST', url, data);
	}

	updateTenant(shortname: string, enabled: boolean) {
		const url = '/api/v2/partner/tenants/update';
		const data = {
			short_name: shortname,
			enabled: enabled
		};
		return this.makeRequest('POST', url, data);
	}

	getDashboardTrend(partner_id: string, periodicity: string, from_date: string, to_date: string) {
		const url = '/api/v2/partner/dashboardtrend';
		const data = {
			partner_id,
			periodicity,
			from_date,
			to_date
		};
		return this.makeRequest('POST', url, data);
	}

	getDashboardSummary(partner_id: string) {
		const url = '/api/v2/partner/dashboardsummary';
		const data = {
			partner_id
		};
		return this.makeRequest('POST', url, data);
	}

	downloadUsageReport(from_date: string, to_date: string, partnerId: string) {
		const url = '/api/v2/partner/dashboard-download-report';
		const data = {
			partner_id: partnerId,
			from_date: from_date,
			to_date: to_date
		};
		return this.makeRequest('POST', url, data)
	}

	getTicketCountByPartnerId(partner_id: string) {
		//console.log(partner_id);
		///api/v2/partner/ticket-count?Partner={PartnerName}
		//const url = "/api/v2/partner/ticket-count?Partner= ${Partner};
		const url = `/api/v2/partner/ticket-count?Partner=${partner_id}`;
		/*const data = {
      partner_id
    };*/
		return this.makeRequest('GET', url);
	}

	getTicketCountByCustomer(partner_id: string, customer: string) {
		//console.log(partner_id);
		///api/v2/partner/ticket-count?Partner={PartnerName}
		//const url = "/api/v2/partner/ticket-count?Partner= ${Partner};
		const url = `/api/v2/partner/ticket-count?Partner=${partner_id}&Customer=${customer}`;
		/*const data = {
      partner_id
    };*/
		return this.makeRequest('GET', url);
	}

	checkShortName(shortname: string) {
		const url = `/api/v2/partner/tenants/nameexists/${shortname}`;
		return this.makeRequestWithCode('GET', url);
	}

	checkEmail(email: string) {
		const url = `/api/v2/users/emailexists/${email}`;
		return this.makeRequest('GET', url);
	}
}

const partner_api = new PartnerAPI();
export default partner_api;
