import React from "react";
import Input from "../Input";
import { CheckCircleFill, QuestionCircleFill } from "react-bootstrap-icons";
import cx from "classnames";

import { callAll } from "../../../utils/functions";
import { FormControlProps } from "react-bootstrap";
import omit from "lodash/omit";

import "./styles.scss";
import LyveTooltip from "../../LyveTooltip";

type ValidationRule = {
  message: string;
  isValid: boolean;
};

export declare type ValidationMessagesProps = {
  rule: ValidationRule;
};

const ValidationMessage: React.FC<ValidationMessagesProps> = ({ rule }) => {
  const { isValid, message } = rule;

  return (
    <div className={`validation-block ${isValid ? "valid" : "invalid"}`}>
      <CheckCircleFill size="16px" />
      <span className="message"> {message} </span>
    </div>
  );
};

declare interface InputWithRulesProps extends FormControlProps {
  label: string;
  onBlur?: () => void;
  id: string;
  tooltip?: string;
  error?: string;
  rules: ValidationRule[];
  placeholder?: string;
  hideRulesOnBlur?: boolean;
}

const InputWithRules: React.FC<InputWithRulesProps> = ({
  label,
  id,
  children,
  rules,
  tooltip,
  error,
  hideRulesOnBlur = true,
  placeholder,
  isInvalid,
  ...rest
}) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const focus = () => setIsFocused(true);
  const blur = () => setIsFocused(false);

  const inputLabel = tooltip ? (
    <>
      <span className="mr-1">{label}</span>
      <LyveTooltip
        placement="right"
        text={tooltip}
        enabled
        id={`${id}-tooltip`}
        className="input-rules-tooltip"
      >
        <QuestionCircleFill />
      </LyveTooltip>
    </>
  ) : (
    label
  );

  const ruleWithError = rules.find(rule => !rule.isValid);
  const isRuleInvalid = hideRulesOnBlur && !!rest.value && !!ruleWithError;

  return (
    <div className="input-with-rules">
      <Input
        controlId={id}
        placeholder={placeholder || ""}
        label={inputLabel}
        onFocus={focus}
        onBlur={callAll(rest.onBlur, blur)}
        isInvalid={isRuleInvalid || isInvalid}
        error={ruleWithError?.message || error}
        {...omit(rest, "onBlur", "isInvalid", "error")}
      />
      <div
        className={cx("input-validators", {
          "d-none": !isFocused && hideRulesOnBlur,
          "input-validators-focused": isFocused && hideRulesOnBlur
        })}
      >
        {rules.map((rule, idx) => (
          <ValidationMessage
            key={idx}
            rule={{
              message: rule.message,
              isValid: !!rest.value && rule.isValid
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default InputWithRules;
