export const TOGGLE_PERMISSION_CREATE_MODAL = 'TOGGLE_PERMISSION_CREATE_MODAL';

export interface PermissionsState {
  displayCreateModal: boolean,
};

interface TogglePermissionCreateModalAction {
  type: typeof TOGGLE_PERMISSION_CREATE_MODAL
}

export type TogglePermissionCreateModalActionTypes = TogglePermissionCreateModalAction