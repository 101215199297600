import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { formType } from "./../../Models/formTypeEnum";
import {
  CreateTicket,
  TicketAttachment,
  EditTicket
} from "../../Models/Ticket";
import SimpleReactValidator from "simple-react-validator";
import salesforceApi from "../../../../../../services/SalesforceAPI";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import api from "../../../../../../services/api/index";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  checkFileSize,
  toBase64,
  getPartnerDetails
} from "../../Helpers/SupportHelpers";
import moment from "moment";
import Input from "../../../../../../components/Input";
import Modal from "react-bootstrap/Modal";
import LyveLink from "../../../../../../components/LyveHeadingLink/LyveLink";
import {
  getLink,
  linkNames
} from "../../../../../../components/LyveHeadingLink/LinkUtil";
import piexifjs from "piexifjs";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../../store";
import { SubaccountsStore } from "../../../../../../state/Subaccounts/types";
import { WhoAmI } from "../../../../../../state/UserInfo/types";

let customers_array: any[] = [];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorTxtSubject: {
      fontSize: 13,
      marginRight: theme.spacing(0.375),
      color: "#fc397a"
    },
    noErrorTxtSubject: {
      color: "#FFFFFF",
      display: "none"
    },
    isInvaildTextSubject: {
      border: "1px solid #dc3545"
    },
    isValidTextSubject: {
      border: "1px solid #ced4da"
    },

    errorTxtDesc: {
      fontSize: 13,
      marginRight: theme.spacing(0.375),
      color: "#fc397a"
    },
    noErrorTxtDesc: {
      color: "#FFFFFF",
      display: "none"
    },
    isInvaildTextDesc: {
      border: "1px solid #dc3545"
    },
    isValidTextDesc: {
      border: "1px solid #ced4da"
    },
    spinner: {
      color: theme.palette.primary.contrastText,
      position: "relative",
      top: theme.spacing(0.375)
    }
  })
);

export interface Tenant {
  id: string;
  name: string;
  short_name: string;
  enabled: boolean;
  partner_id: string;
  sys_created_by: string;
}

interface CreatePromptProps {
  type: formType;
  closePopup?: () => void;
  updateCaseList?: () => void;
  output?: (status?: boolean, message?: string) => void;
  hideModal: () => void;
}

const SupportCreateNewTicket: React.FC<CreatePromptProps> = ({
  type,
  closePopup,
  updateCaseList,
  output,
  hideModal
}) => {
  const whoAmI = useSelector<AppState, WhoAmI>(state => state.userInfo.whoAmI);
  const { masterAccount } = useSelector<AppState, SubaccountsStore>(
    state => state.subaccounts
  );
  const [updateForms, setupdateForms] = useState(false);
  const [isFormProcessing, setFormProcessing] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  let errorString = useRef("");
  const [initCreation, setInitCreation] = useState(false);

  const [showFileValidationMessage, setFileValidationMessage] = useState(false);
  const [
    showFileTypeValidationMessage,
    setFileTypeValidationMessage
  ] = useState(false);

  const [
    attachmentValidationFlag,
    setAttachmentValidationFlag
  ] = React.useState<boolean>(false);

  // useEffect(() => {

  // }, [hideModal]);

  const [createTicket, setCreateTicket] = useState<CreateTicket>({
    SalesforceID: "",
    Customer: "None",
    User: "",
    Email: "",
    Subject: "",
    DetailsofIssue: "",
    Attachments: [],
    UserComments: ""
  });
  const partnerDetails = getPartnerDetails(whoAmI);
  const [startedTyping, setStartedTyping] = React.useState<boolean>(false);
  const [startedTypingDesc, setStartedTypingDesc] = React.useState<boolean>(
    false
  );
  const classes = useStyles();

  const withinCharacterLimit = (): boolean => {
    return createTicket.Subject.length <= 255;
  };

  const withinCharacterLimitDesc = (): boolean => {
    return createTicket.DetailsofIssue.length <= 5000;
  };

  const getCurrentDate = (): any => {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    const time = newDate.getTime();
    let separator = "-";
    let separator1 = " ";
    return `${year}${separator}${
      month < 10 ? `0${month}` : `${month}`
    }${separator}${date}${separator1}${msToTime(time)}`;
  };

  const msToTime = (s: number): any => {
    var time = new Date(s).toISOString().slice(11, -1);
    var str = time.substring(0, 8);
    return str;
  };

  // Auto Hide Error Message in 3 second from Subject
  const checkErrorSubject = () => {
    if (!validator.current.fieldValid("subject")) {
      setTimeout(() => {
        document.querySelectorAll(".text-danger").forEach(function(a) {
          a.remove();
        });
      }, 3000);
    } else {
      setInitCreation(true);
    }
  };

  // Auto Hide Error Message in 3 second from Description
  const checkErrorDesc = () => {
    if (!validator.current.fieldValid("detailsOfIssue")) {
      setTimeout(() => {
        document.querySelectorAll(".text-danger").forEach(function(a) {
          a.remove();
        });
      }, 3000);
    } else {
      setInitCreation(true);
    }
  };

  /** Update Fields */
  const updateField = (e: any) => {
    let str = e.target.value.replace(/["]/g, "");
    // console.log(str);
    setCreateTicket({
      ...createTicket,
      [e.target.name]: str
    });
  };

  const checkErrors = (e: any) => {
    // console.log("attachmentValidationFlag: "+ attachmentValidationFlag);
    // console.log("Disabled = "+initCreation);
    // console.log(e.target.name);

    if (attachmentValidationFlag) {
      setInitCreation(true);
      // console.log("Returning... ");
      return;
    }
    // console.log("Executing... ");
    validator.current.fieldValid("subject") &&
    validator.current.fieldValid("detailsOfIssue")
      ? setInitCreation(false)
      : setInitCreation(true);
  };

  /** Validation */
  const [, forceUpdate] = useState<number>();
  let validator = useRef(
    new SimpleReactValidator({
      className: "text-danger",
      messages: {
        required: "This field is required",
        max: "You have reached the maximum characters limit."
      }
    })
  );

  const subjectValidate = validator.current.message(
    "subject",
    createTicket.Subject,
    "required|max:255"
  );
  const detailsOfIssueValidate = validator.current.message(
    "detailsOfIssue",
    createTicket.DetailsofIssue,
    "required|max:5000"
  );

  // GET Customers from API and populate the combo box
  const getCustomers = async () => {
    setupdateForms(true);
    if (!partnerDetails.isAnyCustomer) {
      await salesforceApi
        .getTenants()
        .then(res => {
          if (res.tenants.length > 0) {
            let temp_array: Array<string> = [];
            for (var i = 0; i < res.tenants.length; i++) {
              temp_array.push(res.tenants[i].name);
            }
            customers_array = temp_array;
            setupdateForms(false);
          } else {
            customers_array = [];
            setupdateForms(false);
          }
          setupdateForms(false);
        })
        .catch(err => {
          setupdateForms(false);
        });
    } else {
      setupdateForms(false);
    }
  };

  // GET subaccounts from API
  const getSubaccounts = async () => {
    setupdateForms(true);
    if (masterAccount?.is_master) {
      await api
        .getSubaccounts(masterAccount.short_name)
        .then(res => {
          if (res?.sub_accounts?.length > 0) {
            let temp_array: Array<string> = [];
            for (var i = 0; i < res?.sub_accounts?.length; i++) {
              temp_array.push(res?.sub_accounts[i]?.name);
            }
            customers_array = temp_array;
            setupdateForms(false);
          } else {
            customers_array = [];
            setupdateForms(false);
          }
          setupdateForms(false);
        })
        .catch(err => {
          setupdateForms(false);
        });
    } else {
      setupdateForms(false);
    }
  };

  useEffect(() => {
    /** Populate Default User Data */
    errorString.current = "";
    const partner = whoAmI?.company_name ?? "";
    let email = whoAmI?.email ?? "";
    let username = whoAmI?.name ?? "";
    const newTicket: CreateTicket = {
      SalesforceID: partner,
      Customer: "None",
      User: username,
      Email: email,
      Subject: "",
      DetailsofIssue: "",
      Attachments: [],
      UserComments: ""
    };
    setInitCreation(true);
    setCreateTicket(newTicket);

    const input: any = document.querySelector("#subject");
    input.focus();

    // Get Customers from API
    if (masterAccount?.is_master) {
      getSubaccounts();
    } else {
      getCustomers();
    }
  }, [masterAccount, whoAmI]);

  // Functoin to get base64
  const toBase64 = (file: any) =>
    new Promise<string>((resolve, reject) => {
      const fileType = file.name
        .split(".")
        .pop()
        .toLowerCase();
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result instanceof ArrayBuffer) {
          resolve("");
        } else if (
          reader.result !== null &&
          typeof reader.result !== "undefined"
        ) {
          if (fileType === "jpg" || fileType === "jpeg") {
            resolve(piexifjs.remove(reader.result));
          } else {
            resolve(reader.result);
          }
        }
      };
      reader.onerror = error => reject(error);
    });

  // Functon to increase textarea size if user enters large text
  const handleKeyDown = (e: any) => {
    e.target.style.height = "inherit";
    // e.target.style.height = `${e.target.scrollHeight}px`;
    // In case you have a limitation
    e.target.style.height = `${Math.min(e.target.scrollHeight, 254)}px`;
  };

  const handleClick = (event: any) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  // Function to check if Attachment already exixts
  const checkAttachmentExists = (file: any): boolean => {
    let name: string = file.name;
    if (typeof createTicket.Attachments === "undefined") {
      return true;
    } else {
      let exists = createTicket.Attachments.findIndex(a => a.Name === name);
      if (exists !== -1) {
        return false;
      } else {
        return true;
      }
    }
  };

  // Function to check attachemnt type and size
  const handleFileUpload = async (e: any) => {
    const fileSize = e.target.files.length;
    // console.log(fileSize);
    let allFiles = e.target.files;
    let errStr = "";
    setAttachmentValidationFlag(false);
    setInitCreation(false);

    for (let i = 0; i < fileSize; i++) {
      setFileTypeValidationMessage(false);
      setFileValidationMessage(false);
      errStr = "";

      // console.log(i);
      // console.log(allFiles);

      if (allFiles[i] !== null) {
        // Check if file already exists in the list
        if (checkAttachmentExists(allFiles[i])) {
          const file = allFiles[i];
          // console.log(file.name);

          // Check file types
          if (checkFileType(file.name.split(".").pop())) {
            // console.log("file format vaild");
            setFileTypeValidationMessage(false);

            // Check file Size
            const filesizevalid = checkFileSize(file);
            if (filesizevalid) {
              setFileValidationMessage(true);
              errStr = "This file exceeds the 4MB attachment limit.";
              // console.log("file size invaild");
              setInitCreation(true);
            } else {
              let fileSize = file.size;
              if (fileSize > 0) {
                // console.log("%c file size is valid", "color: pink;font-weight: bold;")
                setFileValidationMessage(false);
                setAttachmentValidationFlag(false);
                setInitCreation(false);
              } else {
                setFileValidationMessage(true);
                errStr = "The uploaded file cannot be an empty file (0 byte).";
                setInitCreation(true);
              }
            }
          } else {
            // console.log("file format invalid");
            setFileTypeValidationMessage(true);
            errStr = "This file format is not supported.";
            setAttachmentValidationFlag(true);
            setInitCreation(true);
          }

          // Add in array
          let body: string;
          const filesizevalid = checkFileSize(file);
          if (filesizevalid) {
            body = "";
          } else {
            // Check file for zero lenght
            let fileSize = file.size;
            if (fileSize > 0) {
              body = await toBase64(file);
              body = body.substring(body.indexOf(",") + 1);
            } else {
              body = "";
            }
          }

          let filename = file.name;
          let ticketAttachment: TicketAttachment = {
            Name: filename,
            Body: body,
            Description: "",
            CreatedDate: moment
              .utc(moment())
              .local()
              .format("YYYY-MM-DD hh:mm A"),
            ErrorMmessage: errStr
          };
          let newAttachments = createTicket.Attachments;
          newAttachments.push(ticketAttachment);
          // console.log(newTicketAttachment);
          setCreateTicket({
            ...createTicket,
            Attachments: newAttachments
          });
          // console.log(newTicketAttachment);
        }
      }
    }

    checkAttachment();
  };

  // Check Error in attachements
  const checkAttachment = () => {
    // Filter for checking error in attachemnts
    // console.log(createTicket);
    let errorCount = createTicket.Attachments.filter(
      attachment => attachment.ErrorMmessage !== ""
    );
    // console.log(errorCount.length);
    if (errorCount.length === 0) {
      setInitCreation(false);
      setAttachmentValidationFlag(false);
    } else {
      setInitCreation(true);
      setAttachmentValidationFlag(true);
    }
  };

  // Function to check vaild file types
  function checkFileType(fileType: any): boolean {
    switch (fileType) {
      case "exe":
        return false;
      case "bin":
        return false;
      case "com":
        return false;
      case "cmd":
        return false;
      case "msi":
        return false;
      default:
        return true;
    }
  }

  // Functon to remove attachments from list
  const handleDel = (index: number) => {
    let exists: TicketAttachment | undefined = createTicket.Attachments[index];
    if (typeof exists !== "undefined") {
      let newAttachments = createTicket.Attachments;
      newAttachments.splice(index, 1);

      setCreateTicket({
        ...createTicket,
        Attachments: newAttachments
      });

      checkAttachment();
    }

    // Filter for checking error in attachemnts
    // let errorCount = createTicket.Attachments.some(
    //   attachment => attachment.ErrorMmessage !== "" || typeof attachment.ErrorMmessage !== null
    // );

    // Filter for checking error in attachemnts
    let errorCount = createTicket.Attachments.filter(
      attachment => attachment.ErrorMmessage !== ""
    );

    if (errorCount.length === 0) {
      // Check if the subject field is valid
      if (
        validator.current.fieldValid("subject") &&
        validator.current.fieldValid("detailsOfIssue")
      ) {
        setInitCreation(false);
      } else {
        setInitCreation(true);
      }
    }
  };

  // Functon to save changes in ticket object
  const handleCustomerChange = (selectedItem: any) => {
    const selectedCustomer = selectedItem.target.value;
    setCreateTicket({
      ...createTicket,
      Customer: selectedCustomer
    });
  };

  // Function to Save Ticket
  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (validator.current.allValid()) {
      // setupdateForms(true);
      setInitCreation(true);
      setFormProcessing(true);
      setError(false);

      // console.log(createTicket);

      let tmpstrSub = createTicket.Subject.replace(/\\/g, "\\\\")
        .replace(/\n/g, "\\n")
        .replace(/"/g, '\\"');

      let tmpstrDesc = createTicket.DetailsofIssue.replace(/\\/g, "\\\\")
        .replace(/\n/g, "\\n")
        .replace(/"/g, '\\"');

      const newTicket = {
        SalesforceID: "",
        Customer: createTicket.Customer,
        User: createTicket.User,
        Email: createTicket.Email,
        Subject: tmpstrSub,
        DetailsofIssue: tmpstrDesc,
        Attachments: createTicket.Attachments,
        UserComments: createTicket.UserComments
      };
      // console.log(newTicket);

      // Call Salesforce API to create ticket
      salesforceApi
        .createTicket(newTicket)
        .then((res: any) => {
          if (res.data.Status === "pass") {
            if (typeof output !== "undefined") {
              // console.log(res.data);
            }

            if (typeof closePopup !== "undefined") {
              closePopup();
            }

            if (typeof hideModal !== "undefined") {
              hideModal();
            }

            setError(false);
          } else if (res.data.Status === "fail") {
            /** Send message */
            if (typeof output !== "undefined") {
              // console.log(res.data);
            }
            setError(true);
          }
          // setupdateForms(false);
          setFormProcessing(false);
          setInitCreation(false);
        })
        .catch(err => {
          // console.log(err);
          if (err.code === 14) {
            errorString.current = err.message;
          } else {
            errorString.current =
              "An error has occurred. Please try again later.";
          }
          /** Send message */
          if (typeof output !== "undefined") {
            // console.log(err);
            // props.output(false, "There was a problem creating the ticket.");
          }
          // setupdateForms(false);
          setFormProcessing(false);
          setError(true);
          setInitCreation(false);
        });
    } else {
      /** Show error messages if form not validated */
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <div className="support-create-new-ticket-root loader-content">
      <Modal.Title
        id="contained-modal-title-vcenter"
        style={{ marginTop: "20px", marginBottom: "20px" }}
      >
        Create New Ticket
        <LyveLink link={getLink(linkNames.Create_New_ticket)} />
      </Modal.Title>

      <Form onSubmit={handleSubmit}>
        {updateForms && (
          <div className="load-content--2">
            <Spinner animation="grow" />
          </div>
        )}
        <div className="group-a activated-3sec-error">
          <Input
            controlId="subject"
            label="Subject"
            required
            name="Subject"
            maxLength={256}
            value={createTicket.Subject}
            isInvalid={!!subjectValidate}
            error={subjectValidate}
            onChange={updateField}
            onKeyPress={() => {
              validator.current.showMessageFor("subject");
            }}
            onKeyDown={checkErrorSubject}
            // onBlur={() => {
            // 	validator.current.fieldValid('subject') ? setInitCreation(false) : setInitCreation(true);
            // }}
            onBlur={checkErrors}
          />
          {startedTyping && (
            <span
              className={`${classes.errorTxtSubject} ${withinCharacterLimit() &&
                classes.noErrorTxtSubject}`}
            >
              You have reached the maximum characters limit
            </span>
          )}

          <Form.Group controlId="detailsOfIssue">
            <Form.Label>Description</Form.Label>
            <Form.Label className="required">*</Form.Label>

            <Form.Control
              maxLength={5001}
              as="textarea"
              rows={4}
              name="DetailsofIssue"
              value={createTicket.DetailsofIssue}
              onChange={updateField}
              isInvalid={detailsOfIssueValidate ? true : false}
              onKeyPress={() => {
                validator.current.showMessageFor("detailsOfIssue");
              }}
              onKeyDown={checkErrorDesc}
              // onFocus={() => { validator.current.showMessageFor("detailsOfIssue") }}
              // onBlur={() => {
              // 	validator.current.fieldValid('detailsOfIssue')
              // 		? setInitCreation(false)
              // 		: setInitCreation(true);
              // }}
              onBlur={checkErrors}
              // onKeyDown={handleKeyDown}
            />
            {detailsOfIssueValidate && (
              <Form.Control.Feedback type="invalid">
                {detailsOfIssueValidate}
              </Form.Control.Feedback>
            )}
            {startedTypingDesc && (
              <span
                className={`${
                  classes.errorTxtDesc
                } ${withinCharacterLimitDesc() && classes.noErrorTxtDesc}`}
              >
                You have reached the maximum characters limit
              </span>
            )}
          </Form.Group>
          {(!partnerDetails.isAnyCustomer || masterAccount?.is_master) &&
            customers_array.length > 0 && (
            <Row>
              <Col className="col-md-6">
                <Form.Group controlId="customer">
                  <Form.Label>Reported By</Form.Label>
                  <select
                    className="form-control form-control-sm"
                    name="filter"
                    id="filter"
                    onChange={handleCustomerChange}
                  >
                    <option key="none" />
                    {customers_array.map((val, index) => {
                      return (
                        <option key={index} value={val}>
                          {val}
                        </option>
                      );
                    })}
                  </select>
                </Form.Group>
              </Col>
            </Row>
          )}

          <Form.Group controlId="attachment" className="last-group">
            <Form.Label>Attachments</Form.Label>

            <Row
              style={{ marginRight: "0px", marginLeft: "0px" }}
              className="no-gutters"
            >
              {createTicket.Attachments.length > 0 && (
                <Col>
                  <div
                    className="attachment-area"
                    style={{ marginLeft: "0px" }}
                  >
                    {createTicket.Attachments.map(
                      (attachment: TicketAttachment, i: number) => {
                        return (
                          <div key={i}>
                            <Row>
                              <Col
                                className=""
                                style={{
                                  display: "flex",
                                  paddingLeft: "16px !important",
                                  paddingRight: "16px !important"
                                }}
                              >
                                <div className="attachmant-holder">
                                  <div className="new-attachments">
                                    <span className="attachment-link">
                                      {attachment.Name}
                                    </span>
                                    <span style={{ marginLeft: "10px" }}>
                                      {attachment.CreatedDate}
                                    </span>
                                    <Link
                                      className="attachment-icon"
                                      style={{ float: "right" }}
                                      to="#"
                                      onClick={() => {
                                        handleDel(i);
                                      }}
                                    >
                                      <CloseIcon />
                                    </Link>
                                  </div>
                                  <span className="error-messages">
                                    {attachment.ErrorMmessage}
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        );
                      }
                    )}
                  </div>
                </Col>
              )}
            </Row>

            <span className="btn btn-outline-secondary btn-file">
              <span className="btn-title">
                <PublishOutlinedIcon fontSize="small" /> Upload
              </span>
              {/* <Form.Control type="file" size="sm" onChange={handleFileUpload} multiple /> */}
              <Form.Control
                type="file"
                size="sm"
                data-testid="file-upload"
                onChange={handleFileUpload}
                onClick={handleClick}
                multiple
              />
            </span>
          </Form.Group>
        </div>

        <Button
          variant="primary"
          type="submit"
          style={{ minWidth: "100px", height: "40px", width: "auto" }}
          className={type === formType.modalform ? "btn-block" : ""}
          disabled={initCreation}
        >
          {isFormProcessing && (
            <CircularProgress
              className={classes.spinner}
              size={14}
              style={{ marginRight: "5px" }}
            />
          )}{" "}
          {isFormProcessing ? "Creating" : "Create"}
        </Button>
        {error && (
          <span className={classes.errorTxtSubject}>{errorString.current}</span>
        )}
      </Form>
    </div>
  );
};

export default SupportCreateNewTicket;
