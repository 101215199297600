import { Styles } from "react-select";
import { ISelectOption } from "./LyveSelect";

export const customStyles: Styles<ISelectOption, boolean> = {
  control: (styles, { menuIsOpen, isFocused }) => ({
    ...styles,
    ...(menuIsOpen || isFocused
      ? {
          borderColor: "var(--gray-14)",
          boxShadow: "none"
        }
      : {}),
    ":hover": {
      borderColor: "var(--gray-14)",
      boxShadow: "none"
    }
  }),
  input: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled ? "var(gray-14)" : "var(--gray-9)",
    padding: 0
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    backgroundColor: "none"
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    padding: "7px 12px 7px 4px"
  }),
  menu: (styles, state) => ({
    ...styles,
    padding: 10,
    margin: 0
  }),
  menuList: (styles, state) => ({
    ...styles,
    maxHeight: "276px",
    padding: 0
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    cursor: "pointer",
    padding: "6px 10px",
    backgroundColor: "var(--white)",
    borderRadius: 4,
    ".form-group": {
      marginBottom: 0
    },
    ".form-check": {
      height: 16
    },
    ".form-check-label": {
      cursor: "pointer"
    },
    ".custom-multi-option": {
      display: "flex",
      "input[type=checkbox]": {
        marginRight: 0,
        height: 16,
        position: "absolute",
        zIndex: 1,
        backgroundColor: "transparent"
      },
      "input[type=checkbox]:checked": {
        borderColor: "var(--green-3)"
      }
    },
    ...(isSelected
      ? {
          backgroundColor: "var(--gray-4)",
          fontWeight: 400,
          color: "var(--gray-14)",
          ".custom-form-group .form-check-label": {
            fontWeight: "inherit"
          }
        }
      : {}),
    ":hover": { backgroundColor: "var(--gray-3)" },
    ":active": {
      backgroundColor: "var(--gray-4)"
    }
  }),
  placeholder: (styles, { isDisabled }) => ({
    ...styles,
    color: "var(--gray-6)",
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 400
  }),
  valueContainer: (styles, state) => ({
    ...styles,
    padding: "4px 6px 4px 9px"
  }),
  multiValue: (styles, state) => ({
    ...styles,
    backgroundColor: "var(--gray-4)",
    margin: "3px"
  }),
  multiValueLabel: (styles, state) => ({
    ...styles,
    padding: "2px 6px",
    color: "var(--gray-13)",
    ".form-group": {
      marginBottom: 0
    }
  }),
  multiValueRemove: (styles, state) => ({
    ...styles,
    color: "var(--gray-14)",
    svg: {
      width: 16,
      height: 16
    },
    ":hover": {
      backgroundColor: "var(--gray-12)",
      color: "var(--white)"
    }
  }),
  singleValue: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled ? "var(--gray-9)" : "var(--gray-13)",
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 400
  })
};

export const customDatePickerStyles: Styles<ISelectOption, boolean> = {
  menu: (styles, state) => ({
    ...styles,
    padding: 0,
    margin: 0,
    display: "table",
    overflow: "auto",
    right: 0
  }),
  menuList: (styles, state) => ({
    ...styles,
    maxHeight: "276px",
    padding: 10,
    width: "224px"
  })
};
