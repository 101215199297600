import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../store";

export interface LyveCloudPlan {
  id: string;
  product_name: string;
  allowedUsage: number;
  uom: string;
  product_price: number;
  costUnit: string;
  unit_price: number;
  friendly_name: string;
}

export enum LCCustomerType {
  PAYING = "PAYING",
  FIXED_PRICE = "FIXED_PRICE"
}

export interface LyveCloud {
  cancelled: boolean
  currentPlan: LyveCloudPlan | null
  currentMonthCost: number | null
  monthToDateUsage: number
  estimatedCost: number
  trialEnd: string
  customerType: LCCustomerType
  payment_failed: boolean;
  estimate_cost_card: boolean;
}

interface ILyveCloudState<T> {
  data: T
}

const initialValue = {
  cancelled: false,
  currentPlan: null as LyveCloudPlan | null,
  monthToDateUsage: 0,
  currentMonthCost: null,
  estimatedCost: 0,
  trialEnd: '',
  customerType: LCCustomerType.FIXED_PRICE,
  payment_failed: false,
  estimate_cost_card: false
}

type State = ILyveCloudState<LyveCloud>;

const initialState: State = { data: initialValue };

const lyveCloudSlice: any = createSlice({
  name: "lyveCloud",
  initialState,
  reducers: {
    setLyveCloudDetails: (state, action: PayloadAction<Partial<LyveCloud>>) => {
      state.data = { ...state.data, ...action.payload }
    },
    cancelLyveCloud: (state) => {
      state.data.cancelled = true
    },
    setMonthToDateUsage: (state, action: PayloadAction<number>) => {
      state.data.monthToDateUsage = action.payload
    },
    changeLyveCloudPlan: (state, action: PayloadAction<LyveCloudPlan | null>) => {
      state.data.cancelled = false
      state.data.currentPlan = action.payload
    }
  }
});

export const {
  setLyveCloudDetails,
  cancelLyveCloud,
  setMonthToDateUsage,
  changeLyveCloudPlan
} = lyveCloudSlice.actions;

export default lyveCloudSlice.reducer;

export const selectLyveCloud = (state: AppState) => state.lyveCloud;
