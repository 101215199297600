import { ERROR_EVAL_INFO, FETCHING_EVAL_INFO, SAVE_EVAL_INFO } from "./actions";
import { EvalActionTypes, EvalStore } from "./types";

const initialState: EvalStore = {
  evalData: null,
  loading: false,
  error: null
};

export function reducer(
  state: EvalStore = initialState,
  action: EvalActionTypes
): EvalStore {
  switch (action.type) {
    case SAVE_EVAL_INFO:
      return {
        ...state,
        loading: false,
        evalData: action.payload
      };

    case FETCHING_EVAL_INFO:
      return { ...state, loading: true };

    case ERROR_EVAL_INFO:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
}
