import React from "react";
import { Controller } from "react-hook-form";
import omit from "lodash/omit";

import LyveCard from "../../../../../../components/Card/LyveCard";
import LyveLink from "../../../../../../components/LyveHeadingLink/LyveLink";
import {
  getLink,
  linkNames
} from "../../../../../../components/LyveHeadingLink/LinkUtil";
import Input from "../../../../../../components/Input";

import { useFormContext } from "./FormContext";
import { FormControlProps } from "react-bootstrap";

declare interface AdminDetailsProps extends FormControlProps {}

const AdminDetails: React.FC<AdminDetailsProps> = ({...rest}) => {
  const { control, errors, getValues, clearErrors, setError } = useFormContext();

  const onChange = () => {
    const { email, confirmEmail } = getValues()
    if (!!confirmEmail) {
      const emailRegex =  /^[\w-\.]+@([\w-]+\.)+[\w-]{1,63}$/g
      const errorMsg = "Email Addresses don't match"
      const confEmailError = errors?.confirmEmail?.message

      if (!email && errors?.email?.type !== "required") {
        setError("email", {message: "Email is required", type: "required"})
      }

      if (emailRegex.test(email) && errors?.email?.type === "email") {
        clearErrors("email")
      }

      if (email !== confirmEmail && errors?.confirmEmail?.type !== "oneOf") {
        setError("confirmEmail", {message: errorMsg, type: "oneOf"})
      }

      if (email === confirmEmail && errors?.confirmEmail?.type === "oneOf") {
        clearErrors("confirmEmail")
      }
    }
  }


  return (
    <LyveCard
      title="Admin Details"
      subtitle={
        <span>
          This admin will be the only person who will have access to the account when it is first created.
          <LyveLink link={getLink(linkNames.CREATE_SUBACCOUNT)} />
        </span>
      }
    >
      <Controller
        name="fName"
        control={control}
        render={field => (
          <Input
            label="First Name"
            controlId="fName"
            isInvalid={!!errors.fName?.message}
            error={errors.fName?.message}
            {...rest}
            {...omit(field, "ref")}
          />
        )}
      />
      <Controller
        name="lName"
        control={control}
        render={field => (
          <Input
            label="Last Name"
            controlId="lName"
            isInvalid={!!errors.lName?.message}
            error={errors.lName?.message}
            {...rest}
            {...omit(field, "ref")}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        render={field => (
          <Input
            label="Email"
            controlId="email"
            onChangeAdd={onChange}
            isInvalid={!!errors.email?.message}
            error={errors.email?.message}
            {...rest}
            {...omit(field, "ref")}
          />
        )}
      />
      <Controller
        name="confirmEmail"
        control={control}
        render={field => (
          <Input
            label="Confirm Email"
            controlId="confirmEmail"
            onChangeAdd={onChange}
            isInvalid={!!errors.confirmEmail?.message}
            error={errors.confirmEmail?.message}
            {...rest}
            {...omit(field, "ref")}
          />
        )}
      />
    </LyveCard>
  );
};

export default AdminDetails;
