import React from "react";
import "./bucket-animation-styles.scss";
import cx from "classnames";
import useAddBucketCard from "./AddBucketContext";

const BucketAnimation = () => {
  const { activeStep } = useAddBucketCard();
  const pausedShapes = activeStep.state !== "wip";
  const pausedItems = activeStep.number !== 0 || activeStep.state !== "wip";

  return (
    <div className="lcm-wrapper">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 250 210"
      >
        <defs>
          <clipPath id="clipPath-lcm-02">
            <rect id="mask" y="0.16" width="250" height="210" fill="none" />
          </clipPath>
          <linearGradient
            id="linear-gradient"
            x1="-521.08"
            y1="47.1"
            x2="-510.31"
            y2="47.1"
            gradientTransform="matrix(-1, 0, 0, 1, -374.95, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#6ebe49" stopOpacity="0.8" />
            <stop offset="0.24" stopColor="#6ebe49" stopOpacity="0.53" />
            <stop offset="0.48" stopColor="#6ebe49" stopOpacity="0.3" />
            <stop offset="0.69" stopColor="#6ebe49" stopOpacity="0.14" />
            <stop offset="0.87" stopColor="#6ebe49" stopOpacity="0.04" />
            <stop offset="1" stopColor="#6ebe49" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="-510.31"
            y1="73.61"
            x2="-409.75"
            y2="73.61"
            gradientTransform="matrix(-1, 0, 0, 1, -374.95, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#fff" />
            <stop offset="1" stopColor="#d1e9c5" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-3"
            x1="-484.43"
            y1="57.07"
            x2="-431.2"
            y2="57.07"
            gradientTransform="matrix(-1, 0, 0, 1, -374.95, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#ccc" />
            <stop offset="1" stopColor="#fff" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-4"
            x1="-448"
            y1="99.94"
            x2="-409.75"
            y2="99.94"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-5"
            x1="-521.08"
            y1="47.1"
            x2="-510.31"
            y2="47.1"
            gradientTransform="matrix(-1, 0, 0, 1, -374.95, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#f8fcf6" stopOpacity="0.87" />
            <stop offset="1" stopColor="#fff" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-6"
            x1="-428.87"
            y1="127.96"
            x2="-428.87"
            y2="71.97"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-7"
            x1="-506.99"
            y1="37.4"
            x2="-470.89"
            y2="37.4"
            xlinkHref="#linear-gradient-3"
          />
          <linearGradient
            id="linear-gradient-8"
            x1="-527.3"
            y1="83.4"
            x2="-426.74"
            y2="83.4"
            xlinkHref="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-10"
            x1="106.1"
            y1="83.01"
            x2="110.27"
            y2="83.01"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#4d4d4d" stopOpacity="0.3" />
            <stop offset="1" stopColor="#666" stopOpacity="0.8" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-11"
            x1="96.74"
            y1="62.89"
            x2="103.67"
            y2="97.64"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#6ebe49" />
            <stop offset="1" stopColor="#006837" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-12"
            x1="95.81"
            y1="99.25"
            x2="100.44"
            y2="99.25"
            xlinkHref="#linear-gradient-11"
          />
          <linearGradient
            id="linear-gradient-13"
            x1="115.12"
            y1="66.85"
            x2="119.44"
            y2="66.85"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#004b28" />
            <stop offset="0.28" stopColor="#004e29" />
            <stop offset="0.5" stopColor="#00582d" />
            <stop offset="0.7" stopColor="#006934" />
            <stop offset="0.89" stopColor="#00803e" />
            <stop offset="1" stopColor="#009245" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-14"
            x1="96.15"
            y1="80.32"
            x2="110.75"
            y2="80.32"
            xlinkHref="#linear-gradient-11"
          />
          <linearGradient
            id="linear-gradient-15"
            x1="87.27"
            y1="80.32"
            x2="119.46"
            y2="80.32"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#004b28" />
            <stop offset="0.49" stopColor="#009245" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-16"
            x1="116.7"
            y1="172.25"
            x2="77.33"
            y2="104.07"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#fff" />
            <stop offset="1" stopColor="#89ff56" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-17"
            x1="134.39"
            y1="154.56"
            x2="95.02"
            y2="86.38"
            xlinkHref="#linear-gradient-16"
          />
          <linearGradient
            id="linear-gradient-18"
            x1="152.21"
            y1="136.74"
            x2="112.84"
            y2="68.56"
            xlinkHref="#linear-gradient-16"
          />
          <linearGradient
            id="linear-gradient-19"
            x1="170.82"
            y1="119.36"
            x2="137.33"
            y2="61.36"
            xlinkHref="#linear-gradient-16"
          />
          <linearGradient
            id="linear-gradient-20"
            x1="185.8"
            y1="104.37"
            x2="152.22"
            y2="46.22"
            xlinkHref="#linear-gradient-16"
          />
          <radialGradient
            id="New_Gradient_Swatch"
            cx="59.54"
            cy="115.17"
            r="4"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#89ff56" />
            <stop offset="1" stopColor="#6ebe49" />
          </radialGradient>
          <radialGradient
            id="New_Gradient_Swatch-2"
            cx="76.35"
            cy="97.23"
            r="4"
            xlinkHref="#New_Gradient_Swatch"
          />
          <radialGradient
            id="New_Gradient_Swatch-3"
            cx="100.87"
            cy="83.43"
            r="4"
            xlinkHref="#New_Gradient_Swatch"
          />
          <radialGradient
            id="New_Gradient_Swatch-4"
            cx="124.81"
            cy="71.97"
            r="4"
            xlinkHref="#New_Gradient_Swatch"
          />
          <radialGradient
            id="New_Gradient_Swatch-5"
            cx="69.4"
            cy="80.58"
            r="4.39"
            gradientTransform="translate(72.31 -18.11) scale(0.91 0.91)"
            xlinkHref="#New_Gradient_Swatch"
          />
          <linearGradient
            id="linear-gradient-21"
            x1="171.53"
            y1="74.58"
            x2="171.53"
            y2="178.1"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#b9e0a8" />
            <stop offset="1" stopColor="#308d12" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-22"
            x1="157.68"
            y1="112.84"
            x2="211.81"
            y2="158.38"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#004b28" />
            <stop offset="0.87" stopColor="#009245" />
          </linearGradient>
        </defs>
        <g style={{ isolation: "isolate" }}>
          <g id="LCM-02.02">
            <g id="LCM-02.01-2" data-name="LCM-02.01">
              <g clipPath="url(#clipPath-lcm-02)">
                <g id="cloud" className={cx(pausedItems && "pause")}>
                  <path
                    d="M135.36,44a16.49,16.49,0,0,1,1,6.07,16.75,16.75,0,0,1,5.33-1,8.32,8.32,0,0,1,4.4,1.13h0Z"
                    fill="url(#linear-gradient)"
                  />
                  <path
                    id="path5550"
                    d="M77.69,35.41c-11.84,6.68-21.45,22.9-21.45,36.25h0v.14c-.71.31-1.43.66-2.15,1.06-10.66,6-19.3,20.61-19.3,32.62s8.64,16.88,19.3,10.87h0l2.14-1.2L120.59,78.9a28,28,0,0,0,14.77-25.11h0c0-10.67-4.2-17.15-14.77-13.54,0-10.68-7.68-15-17.16-9.67h0A30.59,30.59,0,0,0,96,36.7c-3.89-5-10.82-5.49-18.3-1.29Z"
                    fill="url(#linear-gradient-2)"
                  />
                  <path
                    d="M92.7,33.89c-4-2.15-9.32-1.67-15,1.52C71.77,38.75,66.41,44.47,62.53,51s-6.29,14-6.29,20.63l16.81,9.91a.05.05,0,0,1,0,0,.71.71,0,0,1,0-.14c0-13.35,9.6-29.58,21.45-36.25,5.65-3.18,11-3.67,15-1.56L96.63,36c-.22.22-2.68-1.46-3.93-2.13"
                    fill="url(#linear-gradient-3)"
                  />
                  <path
                    d="M58.68,128c-4.1-1.68-6.7-6.11-6.7-12.69A36.48,36.48,0,0,1,56.36,99c3.54-6.85,8.91-13,14.93-16.37.59-.32,1.17-.62,1.76-.89L56,71.92a20.74,20.74,0,0,0-1.89.94c-10.66,6-19.3,20.61-19.3,32.62,0,5.27,1.66,9.16,4.43,11.37l2.42,1.39,16,9.26Z"
                    fill="url(#linear-gradient-4)"
                  />
                  <path
                    d="M135.36,44a16.49,16.49,0,0,1,1,6.07,16.75,16.75,0,0,1,5.33-1,8.32,8.32,0,0,1,4.4,1.13h0Z"
                    fill="url(#linear-gradient-5)"
                  />
                  <path
                    d="M58.68,128c-4.1-1.68-6.7-6.11-6.7-12.69A36.48,36.48,0,0,1,56.36,99c3.54-6.85,8.91-13,14.93-16.37.59-.32,1.17-.62,1.76-.89L56,72c-.63.28-1.26.6-1.89,1-10.66,6-19.3,20.61-19.3,32.62,0,5.27,1.66,9.16,4.43,11.37l2.42,1.39,16,9.2Z"
                    fill="url(#linear-gradient-6)"
                  />
                  <path
                    d="M95.94,36.7l10.59,5.78a10.41,10.41,0,0,1,7.06,4A33.68,33.68,0,0,1,117.21,43,26.79,26.79,0,0,1,121,40.37l.13-.07c4.13-2.29,7.91-2.74,10.85-1.63h0l-16.74-9.4-1-.41c-3-1.09-6.78-.61-10.94,1.73a27.54,27.54,0,0,0-3.82,2.64A34.68,34.68,0,0,0,95.94,36.7Z"
                    fill="url(#linear-gradient-7)"
                  />
                  <path
                    id="path5550-2"
                    data-name="path5550"
                    d="M94.68,45.21C82.84,51.88,73.23,68.1,73.23,81.45h0v.14c-.71.31-1.43.67-2.15,1.07-10.66,6-19.31,20.61-19.31,32.61s8.65,16.88,19.31,10.88h0c.72-.42,2.14-1.21,2.14-1.21L137.58,88.7a28.06,28.06,0,0,0,14.77-25.12h0c0-10.67-4.2-17.15-14.77-13.54,0-10.68-7.68-15-17.16-9.67h0A30.23,30.23,0,0,0,113,46.5c-3.89-5-10.82-5.5-18.3-1.29Z"
                    opacity="0.7"
                    fill="url(#linear-gradient-8)"
                  />
                  <path
                    d="M95.94,36.7l10.59,5.78a10.41,10.41,0,0,1,7.06,4A33.68,33.68,0,0,1,117.21,43,26.79,26.79,0,0,1,121,40.37l.13-.07c4.13-2.29,7.91-2.74,10.85-1.63h0l-16.74-9.4-1-.41c-3-1.09-6.78-.61-10.94,1.73a27.54,27.54,0,0,0-3.82,2.64A34.68,34.68,0,0,0,95.94,36.7Z"
                    fill="url(#linear-gradient-7)"
                  />
                  <path
                    d="M56.15,99c3.54-6.85,8.92-13,14.94-16.38.71-.39,1.44-.75,2.15-1.06,0,0,0-.1,0-.14,0-13.35,9.61-29.57,21.45-36.25,7.48-4.2,14.41-3.71,18.3,1.29A34.52,34.52,0,0,1,116.6,43a26.79,26.79,0,0,1,3.82-2.65l.13-.07c4.52-2.51,8.63-2.81,11.68-1.27l.75.43c2.85,1.84,4.6,5.49,4.6,10.58a16.71,16.71,0,0,1,5.33-1c6.67,0,9.44,5.89,9.44,14.54A28.06,28.06,0,0,1,137.58,88.7L73.23,124.94l-.8.45c-.45.25-1,.55-1.34.76-10.67,6-19.3,1.13-19.3-10.88A36.41,36.41,0,0,1,56.15,99Z"
                    fill="none"
                    stroke="#fff"
                    strokeLinejoin="round"
                    strokeWidth="1.51"
                  />
                </g>
                <g id="gear" className={cx(pausedItems && "pause")}>
                  <path
                    d="M106.1,86.64l.16-.17,0,0c.12-.12.24-.25.35-.38l0,0a.1.1,0,0,0,0,0c.19-.21.37-.43.55-.66v0l0,0c.14-.18.28-.37.41-.56l0,0,0,0c.13-.18.25-.37.37-.56l0,0a.56.56,0,0,1,0-.08l.29-.47,0-.08,0,0c.1-.18.21-.36.3-.54l0,0a.41.41,0,0,1,0-.09l.22-.44a.93.93,0,0,1,0-.1s0,0,0-.05c.09-.19.18-.38.26-.57v0l0-.06.21-.52,0-.08,0-.07.22-.61v0c.07-.22.14-.43.2-.64a.24.24,0,0,1,0-.08"
                    stroke="#fff"
                    strokeMiterlimit="10"
                    strokeWidth="0.35"
                    fill="url(#linear-gradient-10)"
                  />
                  <path
                    d="M89.64,98.59l2-2.77c-.12-.16-.25-.32-.36-.49a.91.91,0,0,1-.11-.16l-.24-.36a2,2,0,0,0-.11-.2l-.21-.39L90.49,94c-.09-.19-.18-.4-.26-.61s-.13-.33-.19-.51,0-.1-.05-.15l-.12-.4,0-.16c0-.19-.1-.37-.14-.56h0L87.3,93.47V90.24h0l0-4.51,2.4-.93h0c0-.2.07-.4.11-.61q.1-.57.24-1.14h0c0-.13.07-.27.1-.4h0c0-.13.07-.27.11-.4h0l.12-.44h0c.11-.36.21-.71.33-1.06h0l.06-.17h0c.13-.36.26-.73.4-1.09h0l.06-.17h0l.35-.86h0l-2-.49,2.55-4.43,1.92-3.33h0l.27-.46,1.43,1.45h0l.37-.48h0l.28-.36h0l.26-.31h0c.12-.15.25-.29.37-.43s.11-.13.17-.2h0c.19-.21.37-.42.57-.63l.11-.12h0l.69-.71h0a1.85,1.85,0,0,0,.14-.14h0l.64-.6h0l.2-.19h0l.3-.27.47-.4h0l-.4-2.53,6.73-3.88-.39,3h0l.62-.19h0l.23-.06h0l.38-.09h0l.15,0a3.58,3.58,0,0,1,.45-.09h0l.35,0a8.59,8.59,0,0,1,1.59-.06h.35l.47.06.62-1.37.79-1.72L115,60.88l2,1.17-5.62-3.27-4.78-2.72-1.39,3-4.24-2.47-6.73,3.89.4,2.53c-.27.22-.53.44-.79.68l-.21.19-.63.59-.1.1,0,0-.69.71-.16.18-.52.57-.11.12-.13.16-.3.35-.26.32c-.09.12-.19.23-.28.36l0,0-1.76-1L84,74.66l1.76,1-.14.33a.14.14,0,0,1,0,.06.56.56,0,0,0,0,.12c-.14.36-.27.73-.4,1.1a.49.49,0,0,1,0,.11s0,0,0,.07l-.09.27-.15.49-.09.3c0,.1-.06.2-.08.29a.94.94,0,0,0,0,.16c0,.13-.07.26-.11.39s-.07.27-.1.41l-.09.39a1.84,1.84,0,0,0,0,.25l0,.11c-.07.33-.13.66-.19,1h0l-2.4.94,0,7.73,4.23,2.47L84,95.32Z"
                    fill="url(#linear-gradient-11)"
                  />
                  <path
                    d="M95.83,98.22l0,0,4.24,2.47.39-3A9.8,9.8,0,0,1,95.83,98.22Z"
                    fill="url(#linear-gradient-12)"
                  />
                  <path
                    d="M115.12,64.82A9.75,9.75,0,0,1,117,69l2.4-1.83L115.2,64.7Z"
                    fill="url(#linear-gradient-13)"
                  />
                  <path
                    d="M109.45,72l-.06-.07-.18-.17a.6.6,0,0,0-.08-.08l-.18-.14-.09-.07-.21-.14a4.41,4.41,0,0,0-3.69-.24,8,8,0,0,1,.17,1.68c0,.21,0,.43,0,.64a.66.66,0,0,0,0,.14c0,.2,0,.42-.06.63l0,.17c0,.1,0,.2,0,.3s0,.14,0,.2,0,.2-.05.29,0,.11,0,.16c0,.25-.11.5-.18.74l0,.12c-.06.22-.13.43-.2.64v.05l-.22.61-.06.16-.22.52,0,.07c-.08.19-.17.38-.26.57l-.07.15c-.07.15-.15.3-.23.44a.91.91,0,0,1-.07.14l-.3.55-.06.1-.29.47-.09.14c-.12.18-.24.37-.37.55l-.06.09-.41.55-.05.07c-.18.23-.36.45-.55.67l-.07.08-.35.39,0,0-.37.38-.08.08-.51.47,0,0q-.27.24-.54.45l-.11.08-.54.4-.1.06-.58.37-.47.25-.13.06-.35.16-.13.06-.32.12-.08,0-.14,0a4.42,4.42,0,0,0,2,3.07h0l0,0h0l.27.14h0l.1,0,.29.1.06,0,.36.08h0l.09,0h0l.33,0h.84l.26,0,.18,0,.16,0,.1,0h0l.2,0,.4-.12.08,0,.32-.13h0l.11,0h0l.35-.16,0,0,.09,0,.18-.09.29-.16.31-.19.27-.17.1-.07c.18-.12.36-.26.54-.39l.11-.08.54-.45,0,0,.51-.47a.6.6,0,0,1,.08-.08h0a.12.12,0,0,1,0,0h0a17.45,17.45,0,0,0,4.39-7.66h0c0-.19.09-.37.13-.56h0l0-.16h0c0-.1,0-.19,0-.29s0-.13,0-.2,0-.2,0-.3h0l0-.18h0l.06-.63s0-.09,0-.13,0-.2,0-.3,0-.23,0-.34,0-.36,0-.53,0-.1,0-.14,0-.26,0-.39,0-.08,0-.12,0-.32-.08-.47,0,0,0-.07l-.09-.37a.65.65,0,0,0,0-.13c0-.11-.07-.22-.1-.32a.36.36,0,0,1,0-.09c0-.13-.1-.25-.16-.38l0-.08L110,72.7l-.06-.1a2.61,2.61,0,0,0-.19-.27l0,0A2.75,2.75,0,0,0,109.45,72Z"
                    fill="url(#linear-gradient-14)"
                  />
                  <path
                    d="M119.46,74.9l-2.4.94a29.69,29.69,0,0,1-1.89,6.38l2,.49-4.75,8.22L111,89.48a29,29,0,0,1-4.59,4.84l.4,2.53-6.73,3.89.39-3a9.8,9.8,0,0,1-4.61.46l-1.41,3.09-4.78-2.72,2-2.77a9.75,9.75,0,0,1-1.92-4.18L87.3,93.47l0-7.74,2.4-.93a29.36,29.36,0,0,1,1.89-6.38l-2-.49,4.74-8.22,1.43,1.45a29.08,29.08,0,0,1,4.59-4.85l-.4-2.53,6.73-3.88-.39,3a9.8,9.8,0,0,1,4.61-.46l1.41-3.09,4.78,2.72-2,2.77A9.75,9.75,0,0,1,117,69l2.4-1.83ZM103.39,88.8a16.21,16.21,0,0,0,7.36-12.74c0-4.69-3.33-6.58-7.41-4.22A16.21,16.21,0,0,0,96,84.58c0,4.69,3.33,6.58,7.41,4.22"
                    fill="url(#linear-gradient-15)"
                  />
                </g>
                <g id="lines" className={cx(pausedItems && "pause")}>
                  <line
                    id="line5"
                    className={cx(pausedShapes && "pause")}
                    x1="135.38"
                    y1="160.3"
                    x2="58.65"
                    y2="116.01"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    strokeDasharray="0 5"
                    stroke="url(#linear-gradient-16)"
                  />
                  <line
                    id="line4"
                    className={cx(pausedShapes && "pause")}
                    x1="153.07"
                    y1="142.62"
                    x2="76.34"
                    y2="98.32"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    strokeDasharray="0 5"
                    stroke="url(#linear-gradient-17)"
                  />
                  <line
                    id="line3"
                    className={cx(pausedShapes && "pause")}
                    x1="170.89"
                    y1="124.8"
                    x2="94.16"
                    y2="80.5"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    strokeDasharray="0 5"
                    stroke="url(#linear-gradient-18)"
                  />
                  <line
                    id="line2"
                    className={cx(pausedShapes && "pause")}
                    x1="186.56"
                    y1="109.12"
                    x2="121.59"
                    y2="71.61"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    strokeDasharray="0 5"
                    stroke="url(#linear-gradient-19)"
                  />
                  <line
                    id="line1"
                    className={cx(pausedShapes && "pause")}
                    x1="201.58"
                    y1="94.1"
                    x2="136.43"
                    y2="56.49"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    strokeDasharray="0 5"
                    stroke="url(#linear-gradient-20)"
                  />
                </g>
                <g id="dots">
                  <circle
                    id="dot5"
                    className={cx(pausedShapes && "pause")}
                    cx="60.45"
                    cy="116.34"
                    r="4"
                    fill="url(#New_Gradient_Swatch)"
                  />
                  <circle
                    id="dot4"
                    className={cx(pausedShapes && "pause")}
                    cx="77.25"
                    cy="98.39"
                    r="4"
                    fill="url(#New_Gradient_Swatch-2)"
                  />
                  <circle
                    id="dot3"
                    className={cx(pausedShapes && "pause")}
                    cx="101.77"
                    cy="84.59"
                    r="4"
                    fill="url(#New_Gradient_Swatch-3)"
                  />
                  <circle
                    id="dot2"
                    className={cx(pausedShapes && "pause")}
                    cx="125.72"
                    cy="73.14"
                    r="4"
                    fill="url(#New_Gradient_Swatch-4)"
                  />
                  <circle
                    id="dot1"
                    className={cx(pausedShapes && "pause")}
                    cx="136.42"
                    cy="56.44"
                    r="4"
                    fill="url(#New_Gradient_Swatch-5)"
                  />
                </g>
                <g id="ui" className={cx(pausedItems && "pause")}>
                  <path
                    d="M203.73,140.86l-63.91,36.86c-1.77,1-3.21-.1-3.23-2.5L136.16,118a7.68,7.68,0,0,1,3.16-6.19L203.24,75c1.76-1,3.21.1,3.23,2.5l.43,57.22C206.91,137.08,205.5,139.85,203.73,140.86Z"
                    opacity="0.7"
                    fill="url(#linear-gradient-21)"
                  />
                  <path
                    d="M210.59,144.79l-63.91,36.86c-1.77,1-3.21-.1-3.23-2.5L143,121.93a7.68,7.68,0,0,1,3.16-6.19L210.1,78.88c1.77-1,3.21.11,3.23,2.51l.43,57.21A7.66,7.66,0,0,1,210.59,144.79Z"
                    fill="#1a1a1a"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.46"
                  />
                  <path
                    d="M143.05,125.52l0-3.59a7.68,7.68,0,0,1,3.16-6.19L210.1,78.88c1.77-1,3.21.11,3.23,2.51l0,3.59Z"
                    fill="#6ebe49"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.46"
                  />
                  <ellipse
                    cx="147.69"
                    cy="119.09"
                    rx="1.12"
                    ry="0.64"
                    transform="translate(-17.75 211.95) rotate(-68.19)"
                    fill="#fff"
                  />
                  <ellipse
                    cx="151.2"
                    cy="117.06"
                    rx="1.12"
                    ry="0.64"
                    transform="translate(-13.66 213.94) rotate(-68.19)"
                    fill="#333"
                  />
                  <ellipse
                    cx="154.72"
                    cy="115.03"
                    rx="1.12"
                    ry="0.64"
                    transform="translate(-9.57 215.93) rotate(-68.19)"
                    fill="#333"
                  />
                  <polygon
                    points="188.71 112.47 146.89 136.58 146.82 127.24 188.64 103.13 188.71 112.47"
                    fill="#4ec131"
                  />
                  <polygon
                    points="210.12 100.12 191.25 111 191.22 106.33 210.08 95.45 210.12 100.12"
                    fill="#666"
                    opacity="0.55"
                  />
                  <polygon
                    points="165.97 166.45 147.2 177.28 146.92 139.62 165.69 128.8 165.97 166.45"
                    fill="#666"
                    opacity="0.55"
                  />
                  <polygon
                    points="210.23 115.18 168.4 139.31 168.31 127.29 210.14 103.16 210.23 115.18"
                    fill="#666"
                    opacity="0.55"
                  />
                  <polygon
                    points="210.42 140.81 168.59 164.94 168.43 143.52 210.26 119.4 210.42 140.81"
                    fill="#666"
                    opacity="0.55"
                  />
                  <line
                    x1="153.4"
                    y1="142.13"
                    x2="160.29"
                    y2="138.16"
                    fill="none"
                    stroke="#6dbd4a"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.58"
                  />
                  <ellipse
                    cx="151.32"
                    cy="143.35"
                    rx="1.04"
                    ry="0.59"
                    transform="translate(-37.99 230.57) rotate(-68.19)"
                    fill="none"
                    stroke="#6dbd4a"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.58"
                  />
                  <line
                    x1="153.43"
                    y1="146.57"
                    x2="157.13"
                    y2="144.44"
                    fill="none"
                    stroke="#6dbd4a"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.58"
                  />
                  <ellipse
                    cx="151.35"
                    cy="147.78"
                    rx="1.04"
                    ry="0.59"
                    transform="translate(-42.09 233.39) rotate(-68.19)"
                    fill="none"
                    stroke="#6dbd4a"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.58"
                  />
                  <line
                    x1="153.46"
                    y1="151"
                    x2="158.28"
                    y2="148.23"
                    fill="none"
                    stroke="#6dbd4a"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.58"
                  />
                  <ellipse
                    cx="151.39"
                    cy="152.22"
                    rx="1.04"
                    ry="0.59"
                    transform="matrix(0.37, -0.93, 0.93, 0.37, -46.19, 236.2)"
                    fill="none"
                    stroke="#6dbd4a"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.58"
                  />
                  <line
                    x1="153.5"
                    y1="155.44"
                    x2="156.71"
                    y2="153.58"
                    fill="none"
                    stroke="#6dbd4a"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.58"
                  />
                  <ellipse
                    cx="151.42"
                    cy="156.65"
                    rx="1.04"
                    ry="0.59"
                    transform="translate(-50.28 239.02) rotate(-68.19)"
                    fill="none"
                    stroke="#6dbd4a"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.58"
                  />
                  <line
                    x1="173.3"
                    y1="130.66"
                    x2="191.26"
                    y2="120.3"
                    fill="none"
                    stroke="#6dbd4a"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.58"
                  />
                  <line
                    x1="195.35"
                    y1="117.94"
                    x2="200.52"
                    y2="114.96"
                    fill="none"
                    stroke="#6dbd4a"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.58"
                  />
                  <path
                    d="M210.59,144.79l-63.91,36.86c-1.77,1-3.21-.1-3.23-2.5L143,121.93a7.68,7.68,0,0,1,3.16-6.19L210.1,78.88c1.77-1,3.21.11,3.23,2.51l.43,57.21A7.66,7.66,0,0,1,210.59,144.79Z"
                    opacity="0.32"
                    fill="url(#linear-gradient-22)"
                    style={{ mixBlendMode: "hue" }}
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default BucketAnimation;
