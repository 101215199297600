import React from "react";
import { CircleFill } from "react-bootstrap-icons";

interface Props {
  enabled: boolean;
}

const ReadOnlyStatus: React.FC<Props> = ({ enabled }) => {
  return (
    <div>
      <CircleFill
        color={enabled ? "var(--green-3)" : "var(--gray-10)"}
        size="7"
        style={{ marginRight: 5 }}
      />
      {enabled ? "Enabled" : "Disabled"}
    </div>
  );
};

export default ReadOnlyStatus;
