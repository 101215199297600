import React from "react";
import cx from "classnames";
import { Button, ButtonProps, Spinner } from "react-bootstrap";

interface IOwnProps extends ButtonProps {
  children?: React.ReactNode;
  form?: string;
  className?: string;
  name?: string;
  onClick?: (e: React.MouseEvent) => void;
  style?: React.CSSProperties;
  loading?: boolean;
}

const DefaultButton = ({ children, loading, ...props }: IOwnProps) => {
  return (
    <Button {...props} className={cx("default-button", props.className)}>
      {loading ? (
        <Spinner animation="border" size="sm" role="status" className="spinner">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        children
      )}
    </Button>
  );
};

export default DefaultButton;
