import React from "react";
import "./styles.scss";
import Button from "react-bootstrap/Button";
import { X as CloseIcon } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import CustomToggle from "../../../../../../components/CustomToggleButton";
import { ButtonLoader, DefaultLoader } from "../../../../../../components/LyveLoader";
import ComplianceDetails from "../ComplianceDetails";
import useCreateBucket from "../../useCreateBucket";
import LyveLink from "../../../../../../components/LyveHeadingLink/LyveLink";
import {
  getLink,
  linkNames
} from "../../../../../../components/LyveHeadingLink/LinkUtil";
import { StoreStateStatus } from "../../../../../../state/types";
import { asyncDebounce } from "../../../../../../utils";
import partner_api from "../../../../../../services/Partner_api";
import api from "../../../../../../services/api";

export interface Region {
  name: string;
  description: string;
  url: string;
  default: boolean;
  accessType?: string | undefined;
  S3DomainURL?: string | undefined;
  status?: string | undefined;
  pricingRegion?: string | undefined;
}
interface CreatePromptProps {
  regionsApiStatus: StoreStateStatus
  hideModal?: () => void;
  regionList: Region[];
  allRegionsMap: Map<string, string> | undefined;
  handleInterval?: (formValues: any) => void;
  formData?: any;
  handleClearInterval?: () => void;
  onSuccess?: (isComplianceCreated: boolean, bucketName?: string) => void;
  onFailure?: () => void;
  bucketNameList: string[];
}

const CreatePrompt: React.FC<CreatePromptProps> = ({
  hideModal,
  regionList,
  allRegionsMap,
  onSuccess,
  onFailure,
  handleInterval,
  formData,
  handleClearInterval,
  bucketNameList,
  regionsApiStatus
}) => {
  const {
    resetForm,
    handleSubmit,
    // handleSubmitToRegion,
    processing,
    error,
    serverError,
    validations,
    handleFieldChange,
    formValues
  } = useCreateBucket(
    regionList,
    allRegionsMap,
    async (isComplianceCreated: boolean, bucketName?: string) => {
      await onSuccess?.(isComplianceCreated, bucketName);
    },
    onFailure,
    handleInterval,
    handleClearInterval,
    formData
  );

  const [isBucketNameUnique, setIsBucketNameUnique] = React.useState<boolean>(true);
  const getRegionDropDown = () => {
    const regionArray =
      allRegionsMap &&
      Array.from(allRegionsMap, ([key, value]) => ({
        key,
        value
      }));

    return regionArray
      ? regionArray?.map(region => {
        return (
          <option key={region.key} value={region.key}>
            {region.value}
          </option>
        );
      })
      : null;
  };

  const isDisabled = () => {
    const {
      bucketNameLength,
      bucketNameCharacters,
      bucketNameEdges
    } = validations;
    return !bucketNameLength || !bucketNameCharacters || !bucketNameEdges
      ? true
      : false;
  };

  const checkBucketNameExist = (value: string): Promise<boolean> => {
    return api.isBucketExists(value)
      .then(res => false)
      .catch(e => true);
  };

  const debouncedValidator = React.useCallback(
    asyncDebounce(checkBucketNameExist, 300),
    []
  );

  const validateBucketName = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value?.trim();
    if (!!value) {
      const bucketExist = await debouncedValidator(value);
      setIsBucketNameUnique(!bucketExist)
    } else {
      setIsBucketNameUnique(false);
    }
  };

  const showChecks = () => (
    <div className="checks">
      <div className="d-flex pb-1">
        <i
          className={`fa fa-check-circle icon ${validations.bucketNameLength ? "icon-valid" : "icon-invalid"
            }`}
          aria-hidden="true"
        />
        <span>Length must be between 3 and 63</span>
      </div>
      <div className="d-flex pb-1">
        <i
          className={`fa fa-check-circle icon ${validations.bucketNameCharacters ? "icon-valid" : "icon-invalid"
            }`}
          aria-hidden="true"
        />
        <span>Only lowercase characters, numbers, and "-" allowed</span>
      </div>
      <div className="d-flex pb-1">
        <i
          className={`fa fa-check-circle icon ${validations.bucketNameEdges ? "icon-valid" : "icon-invalid"
            }`}
          aria-hidden="true"
        />
        <span>
          Start with a number or a lower-case character. The bucket name can be
          an alpha-numeric string(cannot end with "-").
        </span>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div className="bucket-form-title-section">
        <div className="modal-title h4">Create Bucket</div>
        <button
          className="bucket-form-close"
          onClick={() => {
            resetForm();
            hideModal?.();
          }}
        >
          <CloseIcon size="24" color="var(--gray-9)" />
        </button>
      </div>
      <Form
        onSubmit={(e: any) => {
          e.preventDefault();
          handleSubmit();
          // handleSubmitToRegion();
        }}
      >
        <Form.Group controlId="bucketName">
          <Form.Label>Bucket Name</Form.Label>
          <Form.Control
            placeholder="Enter Bucket Name"
            value={formValues.bucketName}
            name="bucketName"
            onChange={(e: any) => {
              validateBucketName(e)
              handleFieldChange(e)
            }}
            data-testid="bucket-name-input"
          />
          {(bucketNameList.includes(formValues.bucketName) || (!isBucketNameUnique && formValues.bucketName.length > 0 && !isDisabled())) && (
            <div
              className="text-danger mb-1"
              style={{ fontSize: "12px" }}
              data-testid="duplicate-bucketName-error"
            >
              The bucket name is already in use. Please use a different name or
              edit the existing bucket name.
            </div>
          )}
          {error?.message ===
            "The bucket name is already in use. Please use a different name or edit the existing bucket name." && (
              <div className="text-danger mb-1" style={{ fontSize: "12px" }}>
                The bucket name is already in use. Please use a different name or
                edit the existing bucket name.
              </div>
            )}
          {showChecks()}
        </Form.Group>
        <Form.Group controlId="exampleForm.SelectCustomSizeSm">
          <Form.Label>Region (Metro)</Form.Label>
          {regionsApiStatus === "loading"
            ? <div className="region-api-loader">
              <DefaultLoader animation="border" />
              <span className="loading-text">Loading</span>
            </div>
            : <Form.Control
              as="select"
              name="region"
              value={formValues?.region ?? ""}
              onChange={(e: any) => handleFieldChange(e)}
            >
              {getRegionDropDown()}
            </Form.Control>
          }
        </Form.Group>
        <div className="compliance-mode-area d-flex">
          <div className="d-flex flex-column">
            <h4 className="cm-heading">Object Immutability</h4>
            <div className="cm-para">
              Protects against deletion and corruption of objects for the
              specified duration.
              <br />
              <LyveLink
                isMarginLeft={false}
                link={getLink(linkNames.Object_Immutability)}
              />
            </div>
          </div>
          <div className="p-2 ml-auto">
            <CustomToggle
              selected={formValues.compliance}
              toggleSelected={() =>
                handleFieldChange({
                  target: { name: "compliance", value: !formValues.compliance }
                })
              }
            />
          </div>
        </div>

        <div className="compliance-area">
          <div className="compliance-notice">
            <span className="compliance-notice-bold-text">Note:</span> Object
            versioning is automatically enabled in this mode.
          </div>
          {formValues.compliance && (
            <div className="compliance-sub-area pt-4">
              <Form.Label>Set Duration</Form.Label>
              <ComplianceDetails
                retentionDuration={formValues.retentionDuration}
                handleRetentionValueChange={handleFieldChange}
                retentionUnit={formValues.retentionUnit}
                handleRetentionUnitChange={handleFieldChange}
              />
            </div>
          )}
        </div>

        {!processing && error && (
          <div className="text-danger mb-1" style={{ fontSize: "12px" }}>
            {error.code !== 13
              ? error.message ||
              "The bucket cannot be created at this time, please try again or contact support."
              : "The bucket cannot be created at this time, please try again or contact support."}
          </div>
        )}
        <Button
          data-testid="create-bucket-submit"
          type="submit"
          disabled={
            isDisabled() ||
            !validations.retentionDuration ||
            bucketNameList.includes(formValues.bucketName) ||
            serverError ||
            !formValues?.region || !isBucketNameUnique
          }
        >
          {processing ? (
            <div className="d-flex justify-content-center">
              <ButtonLoader animation="border" />
            </div>
          ) : (
            "Create"
          )}
        </Button>
      </Form>
    </React.Fragment>
  );
};

export default CreatePrompt;
