import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../store";
import { SliceState, StoreStateStatus } from "./types";

export declare interface Permission {
  id: string;
  name: string;
  slug: string;
  description: string;
  effect: string;
  resources: any[];
  actions: any[];
  type?: string;
  creation_date?: string;
  SACount?: number;
  SAName?: Array<any>;
}

type State = SliceState<Permission[]>;

const initialState: State = { status: StoreStateStatus.IDLE, error: null, data: [] };

const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    loadingPermissions: state => {
      state.status = StoreStateStatus.LOADING;
    },
    resolvePermissions: (state, action: PayloadAction<Permission[]>) => {
      state.status = StoreStateStatus.RESOLVED;
      state.data = action.payload || [];
    },
    rejectPermissions: (state, action: PayloadAction<Error>) => {
      state.status = StoreStateStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export const {
  loadingPermissions,
  resolvePermissions,
  rejectPermissions
} = permissionsSlice.actions;
export default permissionsSlice.reducer;

export const selectPermissions = (state: AppState) => state.permission
