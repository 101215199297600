import { useState, useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import api, { API } from "../../../../services/api";
import { useAppDispatch } from "../../../../store";
import { AllRegions } from "../Buckets";
import regionApi from "../../../../services/RegionApis";

interface CSFormData {
  access_key: string;
  profile_id: string;
  signed_field_names: string;
  locale: string;
  transaction_type: string;
  amount: string;
  currency: string;
  bill_to_forename: string;
  bill_to_surname: string;
  bill_to_email: string;
  bill_to_address_line1: string;
  bill_to_address_city: string;
  bill_to_phone: string;
  bill_to_address_country: string;
  bill_to_address_postal_code: string;
  bill_to_address_state: string;
  transaction_uuid: string;
  reference_number: string;
  signed_date_time: string;
  merchant_defined_data5: string;
  merchant_defined_data6: string;
  merchant_defined_data7: string;
  merchant_defined_data8: string;
  allow_payment_token_update: string;
  payment_token: string;
}

interface GenerateCyberSourceFormDataResponse {
  cybersource_endpoint: string;
  signature: string;
  form_data: CSFormData;
}

export interface IGetCybersourceDataRequest {
  success_url: string;
  failure_url: string;
}

const useCybersourceFormData = (customerType: string | undefined, userRole: string) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPaymentModal, setPaymentModal] = useState<boolean>(false);
  const [
    formData,
    setFormData
  ] = useState<GenerateCyberSourceFormDataResponse | null>(null);
  const [error, setError] = useState<string>("");
  let location = useLocation();
  const searchQuery = location.search ? location.search.split("=")[1] : "";
  const formRef = useRef<HTMLFormElement | null>(null);

  const triggerFormSubmit = () => formRef?.current?.submit();

  const setIsCybersourceRedirected = (cybersourceRedirected: string) => {
    window.sessionStorage.setItem("cybersource_redirected", cybersourceRedirected);
  };

  const getIsCybersourceRedirected = () => {
    return window.sessionStorage.getItem("cybersource_redirected");
  };

  const getCybersourceFormData = useCallback(async () => {
    const postData: IGetCybersourceDataRequest = {
      success_url: `${window.location.origin}${window.location.pathname}?s=1`,
      failure_url: `${window.location.origin}${window.location.pathname}?s=0`
    };
    setIsLoading(true);
    try {
      const resp: AllRegions = await api.getAllRegions();
      let defaultRegionUrl = "";
      if (resp.regions.length > 0) {
        defaultRegionUrl = resp.regions.filter(regionInfo => regionInfo.default).map(region => region.url).toString();
      }
      const res: GenerateCyberSourceFormDataResponse = await regionApi.getCybersourceData(defaultRegionUrl || "", postData);
      
      const initialValue = {
        merchant_defined_data5: "",
        merchant_defined_data6: "",
        merchant_defined_data7: "",
        merchant_defined_data8: ""
      };

      res.form_data = { ...initialValue, ...res.form_data };
      setFormData(res);
      setIsCybersourceRedirected("yes");
      setIsLoading(false);
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (formData) {
      triggerFormSubmit();
    }
  }, [formData]);

  useEffect(() => {
    if (searchQuery === "1" || searchQuery === "0") {
      setPaymentModal(true);
    }
  }, [searchQuery]);

  const handleClick = () => {
    if (
      !!userRole &&
      userRole !== "storage-admin" &&
      !!customerType &&
      customerType === "FIXED_PRICE"
    ) {
      getCybersourceFormData();
    }
  };

  return {
    isLoading,
    error,
    searchQuery,
    formData,
    setIsCybersourceRedirected,
    getIsCybersourceRedirected,
    handleClick,
    showPaymentModal,
    formRef,
    setPaymentModal
  };
};

export default useCybersourceFormData;
