import React from "react";
import { Modal } from "react-bootstrap";
import { DefaultButton } from "../../../../../../components/LyveButton";

import "./styles.scss";

interface ExpirySettingModalProps {
  title: string;
  subtitle: string;
  handleClick: () => void;
}

const ExpirySettingModal: React.FC<ExpirySettingModalProps> = ({
  title,
  subtitle,
  handleClick
}) => {
  return (
    <Modal
      show={true}
      backdrop="static"
      centered={true}
      size={"sm"}
      dialogClassName="expiry-setting-modal"
    >
      <Modal.Body>
        <div className="d-flex flex-column">
          <div className="title">{title}</div>
          <div className="subtitle">{subtitle}</div>
          <div>
            <DefaultButton onClick={handleClick} className="mr-2">
              OK
            </DefaultButton>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ExpirySettingModal;
