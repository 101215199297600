import request from "superagent";
import common from "../common";
import { ICreateReplicationRuleProps } from "../../scenes/Dashboard/components/Buckets/components/ViewBucket/CreateEditReplicationRule";
import { IUpdatePriorityProps } from "../../scenes/Dashboard/components/Buckets/components/ViewBucket/Replication/ChangePriority";
import { IGetCybersourceDataRequest } from "../../scenes/Dashboard/components/Billing/useCybersourceFormData";

class API {
  // Request basics
  requestWithAuth(method: string, baseUrl: string, url: string) {
    const token: string = window.sessionStorage.getItem("token")!;
    return request(method, baseUrl + url).set("sessionId", token);
  }

  makeRequest(
    method: string,
    baseUrl: string,
    url: string,
    data?: object,
    returnErr?: boolean
  ) {
    return this.requestWithAuth(method, baseUrl, url)
      .send(data)
      .then(res => res.body)
      .catch(err => this.errorHandler(err, returnErr));
  }

  errorHandler(err: any, returnErr?: boolean) {
    if (err.status) {
      const errMessage: string =
        (err.response.body && err.response.body.error) ||
        err.status.toString(10);
      console.log("errMessage: ", errMessage);
      console.log("err: ", err.response.body);
      if (err.status === 401) {
        common.logoutCleanup(null);
      } else {
        if (err.response.body && returnErr) {
          let out = err.response.body;
          out.status = err.status;
          return Promise.reject(out);
        } else {
          return Promise.reject(errMessage);
        }
      }
    } else {
      return Promise.reject("Unknown error");
    }
  }
  makeStartHereBucket(
    baseUrl: string,
    name: string,
    region: string,
    permname: string,
    permdesc: string,
    svcname: string,
    svcdesc: string
  ) {
    const url = "/api/v2/start-here-bucket";
    const data = {
      name: name,
      region: region,
      permname: permname,
      permdesc: permdesc,
      svcname: svcname,
      svcdesc: svcdesc
    };
    return this.makeRequest("POST", baseUrl, url, data);
  }
  makeBucket(
    baseUrl: string,
    name: string,
    policy: number,
    region: string,
    compliance: boolean,
    deletionMarker: boolean,
    type: "standard" | "archival" = "standard"
  ) {
    const url = "/api/v2/buckets"
    const data: any = {
      name: name,
      policy: policy,
      compliance: compliance,
      deleteObjectMarker: deletionMarker,
      region: region
    };

    if (type === "archival") {
      data.bucketType = type
    }

    return this.makeRequest("POST", baseUrl, url, data);
  }

  canDeleteBucket(baseUrl: string, name: string) {
    const url = `/api/v2/buckets/${name}/delete_check`;
    return this.makeRequest("GET", baseUrl, url);
  }

  deleteBucket(baseUrl: string, name: string, bucketType: string = "standard") {
    let url = `/api/v2/buckets/${name}`;
    if (bucketType === "archival") {
      url =  `/api/v2/archivalbuckets/${name}`
    }

    const data = { name: name };
    return this.makeRequest("DELETE", baseUrl, url, data);
  }

  updateConfig(baseUrl: string, config: any) {
    const url = "/api/v2/config";
    return this.makeRequest("POST", baseUrl, url, config);
  }

  addReplicationRule(
    baseUrl: string,
    source_bucket_name: string,
    target_bucket_name: string
  ) {
    const url = `/api/v2/bucket_replication`;
    const body = {
      source_bucket_name: source_bucket_name,
      target_bucket_name: target_bucket_name
    };

    return this.makeRequest("POST", baseUrl, url, body);
  }

  getReplicationTargetBucketList(baseUrl: string, bucketName: string) {
    const url = `/api/v2/bucket/${bucketName}/list_replication`;

    return this.makeRequest("GET", baseUrl, url);
  }

  createReplicationRule(baseUrl: string, data: ICreateReplicationRuleProps) {
    const url = "/api/v2/set_bucket_replication";

    return this.makeRequest("POST", baseUrl, url, data);
  }

  editReplicationRule(baseUrl: string, id: string, data: ICreateReplicationRuleProps) {
    const url = `/api/v2/bucket_replication/${id}/edit`;

    return this.makeRequest("PATCH", baseUrl, url, data);
  }

  getReplicationRulesList(baseUrl: string, bucketName: string) {
    const url = `/api/v2/buckets/${bucketName}/replication_rules`;
    return this.makeRequest("GET", baseUrl, url);
  }

  deleteReplicationRule(
    baseUrl: string,
    source_bucket_name: string,
    target_bucket_name: string
  ) {
    const url = `/api/v2/bucket_replication`;
    const body = {
      source_bucket_name: source_bucket_name,
      target_bucket_name: target_bucket_name
    };
    return this.makeRequest("DELETE", baseUrl, url, body);
  }

  // Replication Rules V2

  getBucketDetailsMeta(baseUrl: string, name: string) {
    const url = `/api/v2/buckets/${name}/meta`;
    return this.makeRequest("GET", baseUrl, url);
  }

  getReplicationRulesListV2(baseUrl: string, bucketName: string, limit?: Number, offset?: Number) {
    const url = `/api/v2/buckets/${bucketName}/replication_rules?limit=${limit ? limit : 0}&offset=${offset ? offset : 0
      }`;
    return this.makeRequest("GET", baseUrl, url);
  }

  getReplicationRuleById(baseUrl: string, bucketName: string, replicationId: string) {
    const url = `/api/v2/buckets/${bucketName}/replication_rules/${replicationId}`;
    return this.makeRequest("GET", baseUrl, url);
  }

  enableReplicationRule(baseUrl: string, replicationId: string) {
    const url = `/api/v2/replication_rule/enable`;
    const body = {
      replication_id: replicationId
    };
    return this.makeRequest("PUT", baseUrl, url, body);
  }

  disableReplicationRule(baseUrl: string, replicationId: string) {
    const url = `/api/v2/replication_rule/disable`;
    const body = {
      replication_id: replicationId
    };
    return this.makeRequest("PUT", baseUrl, url, body);
  }

  deleteReplication(baseUrl: string, replicationId: string) {
    const url = `/api/v2/replication_rule/remove_replication`;
    const body = {
      rule_id: replicationId
    };
    return this.makeRequest("DELETE", baseUrl, url, body);
  }

  changeReplicationPriority(
    baseUrl: string,
    source_bucket: string,
    target_bucket: string,
    bucket_rule_details: IUpdatePriorityProps[]
  ) {
    const url = `/api/v2/replication_rule/change_priority`;
    const body = {
      source_bucket: source_bucket,
      target_bucket: target_bucket,
      bucket_rule_details: bucket_rule_details,
    };
    return this.makeRequest("PUT", baseUrl, url, body);
  }

  getBucketDetails(baseUrl: string, name: string) {
    const url = `/api/v2/buckets/${name}`;
    return this.makeRequest("GET", baseUrl, url);
  }

  getComplianceDetails(baseUrl: string, name: string) {
    const url = `/api/v2/buckets/${name}/compliance`;

    return this.makeRequest("GET", baseUrl, url);
  }

  updateComplianceDetails(
    baseUrl: string,
    name: string,
    retentionDuration: number,
    retentionUnit: string,
    deletionMarker: boolean
  ) {
    const url = `/api/v2/buckets/${name}/compliance`;
    const data = {
      name: name,
      retentionDuration: Math.abs(retentionDuration),
      retentionUnit: retentionUnit,
      deletionMarker: deletionMarker
    };

    return this.makeRequest("PATCH", baseUrl, url, data);
  }

  disableCompliance(baseUrl: string, name: string) {
    const url = `/api/v2/buckets/${name}/disable_compliance`;
    const data = {
      name: name
    };
    return this.makeRequest("PATCH", baseUrl, url, data);
  }

  toggleS3Logging(baseUrl: string, name: string, logging: boolean) {
    const url = `/api/v2/buckets/${name}/logging`;
    const data = {
      name: name,
      enable: logging
    };
    return this.makeRequest("PATCH", baseUrl, url, data);
  }
  addPermission(
    name: string,
    description: string,
    effect: string,
    resources: string[],
    actions: string[],
    option: string,
    prefix: string,
    baseUrl: string
  ) {
    const url = `/api/v2/permissions`;
    const data = {
      name: name,
      description: description,
      effect: effect,
      resources: resources,
      actions: actions,
      option,
      prefix: option === "prefix" ? prefix : undefined
    };
    return this.makeRequest("POST", baseUrl, url, data);
  }

  updatePermission(
    id: string,
    name: string,
    description: string,
    effect: string,
    resources: string[],
    actions: string[],
    option: string,
    prefix: string,
    baseUrl: string
  ) {
    const url = `/api/v2/permissions/${id}`;
    const data = {
      id: id,
      name: name,
      description: description,
      effect: effect,
      resources: resources,
      actions: actions,
      option,
      prefix: option === "prefix" ? prefix : undefined
    };
    return this.makeRequest("PUT", baseUrl, url, data);
  }

  deletePermission(id: string, baseUrl: string) {
    const url = `/api/v2/permissions/${id}`;
    const data = {
      id: id
    };
    return this.makeRequest("DELETE", baseUrl, url, data);
  }
  listPermissions(baseUrl: string) {
    const url = "/api/v2/permissions";
    return this.makeRequest("GET", baseUrl, url);
  }

  addPolicyPermission(
    name: string,
    description: string,
    policy_content: string,
    baseUrl: string
  ) {
    const url = "/api/v2/importpolicy";
    const data = {
      name: name,
      description: description,
      policy_content: policy_content
    };
    return this.makeRequest("POST", baseUrl, url, data, true);
  }

  updatePolicyPermission(
    id: string,
    name: string,
    description: string,
    policy_content: string,
    baseUrl: string
  ) {
    const url = "/api/v2/updatepermission";
    const data = {
      id: id,
      name: name,
      description: description,
      policy_content: policy_content
    };
    return this.makeRequest("PUT", baseUrl, url, data, true);
  }

  getPolicyPermissionwithID(id: string, baseUrl: string) {
    const url = `/api/v2/permission/${id}`;
    return this.makeRequest("GET", baseUrl, url);
  }
  // Service Accounts operations
  listServiceAccounts(baseUrl: string) {
    const url = "/api/v2/service_accounts";
    return this.makeRequest("GET", baseUrl, url);
  }

  getServiceAccount(id: string, baseUrl: string) {
    const url = `/api/v2/service_accounts/${id}`;
    return this.makeRequest("GET", baseUrl, url);
  }

  enableServiceAccount(id: string, baseUrl: string) {
    const url = `/api/v2/service_accounts/${id}/enable`;
    return this.makeRequest("POST", baseUrl, url);
  }

  disableServiceAccount(id: string, baseUrl: string) {
    const url = `/api/v2/service_accounts/${id}/disable`;
    return this.makeRequest("POST", baseUrl, url);
  }

  removeServiceAccount(id: string, baseUrl: string) {
    const url = `/api/v2/service_accounts/${id}`;
    return this.makeRequest("DELETE", baseUrl, url);
  }

  addServiceAccount(
    name: string,
    permission_ids: string,
    full_access: boolean,
    baseUrl: string
  ) {
    const url = "/api/v2/service_accounts";
    const data = {
      name: name,
      permission_ids: permission_ids,
      full_access: full_access
    };
    return this.makeRequest("POST", baseUrl, url, data);
  }

  updateServiceAccount(
    id: string,
    name: string,
    permission_ids: string,
    enabled: boolean,
    full_access: boolean,
    baseUrl: string
  ) {
    const url = `/api/v2/service_accounts/${id}`;
    const data = {
      name: name,
      permission_ids: permission_ids,
      enabled: enabled,
      full_access: full_access
    };
    return this.makeRequest("PUT", baseUrl, url, data);
  }

  s3DomainUrl(baseUrl: string) {
    const url = `/api/v2/s3domain`;
    return this.makeRequest("GET", baseUrl, url);
  }

  s3EndpointUrl(baseUrl: string) {
    const url = `/api/v2/s3endpoint`;
    return this.makeRequest("GET", baseUrl, url);
  }

  getCybersourceData(baseUrl: string, data: IGetCybersourceDataRequest) {
    const url = "/api/v2/manage-card";
    return this.makeRequest("POST", baseUrl, url, data);
  }
}

const api = new API();
export default api;
