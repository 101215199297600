import React from "react";
import { Card } from "react-bootstrap";
import cx from "classnames";

import { DefaultLoader } from "../LyveLoader";

interface IOwnProps {
  /** Body of the card */
  body?: React.ReactNode;
  /** Header of the card */
  header?: React.ReactNode;
  /** Footer of the card */
  footer?: React.ReactNode;
  /** Source of the image in image section */
  src?: string;
  /** Alt of the image */
  alt?: string;
  /**
   * Variant of the image
   * @type {("top" | "bottom")}
   */
  variant?: "top" | "bottom";
  /** Overlay component of the image */
  imgOverlay?: React.ReactNode;
  /**
   * Class names
   * @default ""
   */
  className?: string;
  /** To show the loading state. Used for async data loading */
  loading?: boolean;
  /** To show the loading state. Used for async data loading */
  /** title at the top */
  title?: string | React.ReactNode;
  titleClass?: string;
  hoverTitle?: string;
}

/**
 * The default card component.
 * @returns {*} React Component
 */
const PaperCard = ({
  header,
  body,
  footer,
  src,
  alt,
  variant,
  imgOverlay,
  loading,
  className = "",
  title,
  titleClass,
  hoverTitle
}: IOwnProps) => {
  return (
    <>
      {title && !loading && (
        <div className={cx("custom-paper-card-title", titleClass)}>{title}</div>
      )}
      <Card
        className={cx("custom-paper-card", className)}
        title={hoverTitle ? hoverTitle : ""}
      >
        {header && <div className="paper-card-header">{header}</div>}
        {loading ? (
          <DefaultLoader animation="border" />
        ) : (
          <>
            {src && (
              <div className="image-container">
                <Card.Img src={src} alt={alt} variant={variant} />
                {imgOverlay && <Card.ImgOverlay>{imgOverlay}</Card.ImgOverlay>}
              </div>
            )}
            {body && (
              <Card.Body className={hoverTitle ? "custom-card-body" : ""}>
                {body}
              </Card.Body>
            )}
            {footer && <Card.Footer>{footer}</Card.Footer>}
          </>
        )}
      </Card>
    </>
  );
};

export default PaperCard;
