import React from "react";
import { useHistory } from "react-router-dom"
import BaseCard from "./BaseCard";
import { CardProps } from "../../scenes/SmartStartHere/types.d";
import { DefaultButton } from "../LyveButton";
import { ChevronRight } from "react-bootstrap-icons";
import useSmartStartKit from "../../scenes/SmartStartHere/useSmartStartKit";

const SupportCard = (props: CardProps) => {
  const history = useHistory()
  const { toggleShowSmartStartKit, fromLocation } = useSmartStartKit();

  const navigateToSupportTicketPage = (event: React.MouseEvent<Element, MouseEvent>) => {
    toggleShowSmartStartKit?.();
    fromLocation.current = "support-card";
    const target = event.target as HTMLButtonElement
    target.blur()
    history.push("/customer/support");
  };

  const navigateToSupportPage = () => history.push("/customer/support")

  return (
    <BaseCard
      {...props}
      title="We're here to help"
      description="Any issues? Questions?"
      body={
        <div className="d-flex">
          {/* <DefaultButton
            className="base-card-btn"
            variant="outline-secondary"
            onClick={navigateToSupportTicketPage}
          >
            Create Support Ticket
          </DefaultButton> */}
          <button className="base-card-link" onClick={navigateToSupportPage}>
            <span className="mr-1"> Support </span>
            <ChevronRight size={10} />
          </button>
        </div>
      }
    />
  );
};

export default SupportCard;
