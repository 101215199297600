import request from "superagent";
import storage from "local-storage-fallback";
import { stringifyUrl } from "query-string";
import common from "../common";
import { SmartStartCard, SmartStartUPDATE } from "./types";
import { toCamelCaseKeys, toSnakeCaseKeys } from "../../utils";
import { ClosedCard } from "../../scenes/SmartStartHere/types.d";
import { SubAccountForm } from "../../scenes/Dashboard/components/Subaccounts/components/Create";
import { Region } from "../../scenes/Dashboard/components/ArchiveBuckets";

const nocache = require("superagent-no-cache");

export class API {
  // Request basics
  requestWithAuth(method: string, url: string) {
    // user cookie still got issue, so add the code temporarily
    const token: string = window.sessionStorage.getItem("token")!;
    return request(method, url).set("sessionId", token);
  }

  makeRequest(method: string, url: string, data?: object) {
    return this.requestWithAuth(method, url)
      .send(data)
      .then(res => res.body)
      .catch(err => this.errorHandler(err, false));
  }

  makeNoCacheReq(method: string, url: string, data?: object) {
    return this.requestWithAuth(method, url)
      .use(nocache)
      .send(data)
      .then(res => res.body)
      .catch(err => this.errorHandler(err, true));
  }

  makeRequestWithCode(method: string, url: string, data?: object) {
    return this.requestWithAuth(method, url)
      .send(data)
      .then(res => res.body)
      .catch(err => this.errorHandler(err, true));
  }

  errorHandler(err: any, isCodeRequest?: boolean) {
    if (err.status) {
      const errMessage: string =
        (err.response.body && err.response.body.error) ||
        err.status.toString(10);
      // console.log('errMessage: ', errMessage);
      // console.log('err: ', err.response.body);
      if (err.status === 401) {
        common.logoutCleanup(null);
      } else {
        if (isCodeRequest) {
          return Promise.reject({
            message: errMessage,
            code: err.response.body.code
          });
        } else {
          return Promise.reject(errMessage);
        }
      }
    } else {
      return Promise.reject("Unknown error");
    }
  }

  validateUserSession(state: string, code: string) {
    const url = "/api/v2/validate_code";
    return this.makeRequest("POST", url, { state, code });
  }

  // Login/logout
  setAccessToken(token: string): void {
    storage.setItem("token", token);
    window.sessionStorage.setItem("token", token);
  }
  setHelpJWTURL(url: string): void {
    storage.setItem("help_jwt", url);
  }

  setDirectCustomer(resBody: any): void {
    const partners = resBody.partners;

    var str = "";
    if (partners === undefined) {
      str = "true";
    } else {
      str = "false";
    }

    setTimeout(() => {
      storage.setItem("direct_customer", str);
    }, 500);
  }

  setUserInfo(whoAmI: any): void {
    // "account_id": "35cba4b4-df7a-2399-1cfa-46e132539727",
    // "type": "partner",
    // "email": "ceozhg@auth0.com",
    // "name": "HongGang Zhu",
    // "enabled": true,
    // "company_id": "12d85b5d-bd81-f800-aba2-2196eedfef31",
    // "company_name": "Seagate",
    // "company_short_name": "STX",
    // "jwt_token": "2b53e9fed466931b21cfd19dd9cb4f5550f5bf0522013ac0b24457a0a11ce4b2"

    //console.log(whoAmI);

    storage.setItem("user_type", whoAmI.type);
    storage.setItem("user_role", whoAmI.role);
    storage.setItem("user_id", whoAmI.account_id);
    storage.setItem("user_email", whoAmI.email);
    storage.setItem("token", whoAmI.jwt_token);
    storage.setItem("user_name", whoAmI.name);
    storage.setItem("enabled", whoAmI.enabled);
    storage.setItem("company_id", whoAmI.company_id);
    storage.setItem("company_name", whoAmI.company_name);
    storage.setItem("company_short_name", whoAmI.company_short_name);
    storage.setItem("created_date_ft", whoAmI.created_date_ft);
    storage.setItem("created_at", whoAmI.created_at);
    storage.setItem("customer_type", whoAmI.customer_type);
    // storage.setItem("mfa_preference", whoAmI.mfa_preference);
    // storage.setItem("company_name", whoAmI.company);
  }

  getUserInfo() {
    return {
      type: storage.getItem("user_type"),
      role: storage.getItem("user_role"),
      id: storage.getItem("user_id"),
      email: storage.getItem("user_email"),
      name: storage.getItem("user_name"),
      enabled: storage.getItem("enabled"),
      company_id: storage.getItem("company_id"),
      company_name: storage.getItem("company_name"),
      company_short_name: storage.getItem("company_short_name"),
      remember_me: storage.getItem("remember_me"),
      direct_customer: storage.getItem("direct_customer"),
      created_date_ft: storage.getItem("created_date_ft"),
      created_at: storage.getItem("created_at"),
      customer_type: storage.getItem("customer_type")
    };
  }

  setCustomerType(customerType: string) {
    storage.setItem("customer_type", customerType);
  }

  getCustomerType() {
    return storage.getItem("customer_type");
  }

  setUserSelection(userSelection: any) {
    storage.setItem("user_selection", JSON.stringify(userSelection));
  }

  getUserSelection() {
    return storage.getItem("user_selection");
  }

  getCustomersLocal() {
    return storage.getItem("CustomerCountList");
  }

  getUserRole() {
    return storage.getItem("user_role");
  }

  getUserType() {
    return storage.getItem("user_type");
  }

  setRememberMe(rememberMe: string) {
    storage.setItem("remember_me", rememberMe);
  }

  getMFAPreference() {
    const url = "/api/v2/org/mfa_preference";
    return this.makeRequest("GET", url);
  }

  whoami() {
    const url = "/api/v2/users/whoami";
    return this.makeNoCacheReq("GET", url);
  }

  login(email: string, password: string): Promise<string> {
    const url = "/api/v2/users/login";
    return request
      .post(url)
      .send({
        email: email,
        password: password
      })
      .then((res: any) => {
        const resBody = res.body;

        api.setDirectCustomer(resBody);

        const jwt_token = resBody.jwt_token;
        if (jwt_token) {
          api.setAccessToken(resBody.jwt_token);
        } else {
          console.error("Exception error: access token is empty !!!");
        }
        return resBody;
      })
      .catch(err => this.errorHandler(err));
  }

  loginContext(accountId: string) {
    const url = "/api/v2/users/login_context";
    const data = {
      accountId: accountId
    };
    return this.makeRequest("POST", url, data);
  }

  logout() {
    const url = "/api/v2/logout";
    return this.logOutRequest("GET", url);
  }

  logOutRequest(method: string, url: string, data?: object) {
    return this.requestWithAuth(method, url)
      .send(data)
      .then(res => res.body)
      .catch(err => {
        const loginUrl = this.getLoginUrl();
        this.cleanLocalUserStorage();
        window.location.href = loginUrl;
      });
  }

  getLoginUrl = () => {
    const company = window.location.host.split(".")[0];
    const path = encodeURIComponent(window.location.pathname);
    const token: string = window.sessionStorage.getItem("token")!;
    const tabs = token ? 1 : 0;
    return `${window.location.origin}/api/v2/login/redirect?company=${company}&path=${path}&tabs=${tabs}`;
  };

  cleanLocalUserStorage = () => {
    storage.removeItem("token");
    storage.removeItem("user_type");
    storage.removeItem("user_id");
    storage.removeItem("user_email");
    storage.removeItem("user_name");
    storage.removeItem("enabled");
    storage.removeItem("company_id");
    storage.removeItem("company_name");
    storage.removeItem("company_short_name");
    storage.removeItem("user_selection");
    storage.removeItem("direct_customer");
    storage.removeItem("customer_type");
    storage.removeItem("help_loggedIn");
    storage.removeItem("created_date_ft");
    storage.removeItem("created_at");
  };

  cleanLocalUserWhenSwitch = () => {
    storage.removeItem("user_type");
    storage.removeItem("user_id");
    storage.removeItem("user_email");
    storage.removeItem("user_name");
    storage.removeItem("enabled");
    storage.removeItem("company_id");
    storage.removeItem("company_name");
    storage.removeItem("company_short_name");
    storage.removeItem("direct_customer");
    storage.removeItem("customer_type");
    storage.removeItem("help_loggedIn");
    storage.removeItem("created_date_ft");
    storage.removeItem("created_at");
  };

  getLoginUserType() {
    let loginUserType = "";
    const token = storage.getItem("token");
    const user_type = storage.getItem("user_type");
    const user_id = storage.getItem("user_id");
    if (token && user_id && common.isValidType(user_type)) {
      loginUserType = user_type!;
    }

    return loginUserType;
    // return !!storage.getItem("token");
  }

  checkUrlChange(pathname: string | null, usertype: string | null) {
    if (pathname && usertype) {
      const currPathName = pathname.toLowerCase();
      let matches = currPathName.match(/partner/g);
      //console.log('partner matches: ' + matches);
      if (matches) {
        if (!common.isPartner(usertype)) {
          console.log("current user is not a partner user!!");
          window.location.href = "/customer/dashboard";
        }
      } else {
        if (!common.isAnyCustomer(usertype)) {
          console.log("current user is not a tenant user!!");
          window.location.href = "/partner/dashboard";
        }
      }
    }
  }

  beAPartner(
    name: string,
    email: string,
    company_name: string
  ): Promise<string> {
    const url = "/api/v2/support/be_a_partner";
    return request
      .post(url)
      .send({
        name: name,
        email: email,
        company_name: company_name
      })
      .then((res: any) => {
        const resBody = res.body;
        return resBody;
      })
      .catch(err => this.errorHandler(err));
  }

  // Metrics operations
  getMetrics(query: any) {
    const url = stringifyUrl({ url: "/api/v2/costmetricspublic", query });
    return this.makeRequest("GET", url);
  }

  getMasterMetrics(data: any) {
    const url = "/api/v2/master/costmetrics";
    return this.makeRequest("POST", url, data);
  }

  getBucketUsage(query: any) {
    const url = stringifyUrl({ url: "/api/v2/bucketusagepublic", query });
    return this.makeRequest("GET", url);
  }

  // Enabled and Provisioned Regions
  getAllRegions() {
    const url = "/api/v2/available_regions";
    return this.makeRequest("GET", url);
  }

  // Bucket operations
  makeBucket(
    name: string,
    policy: number,
    region: string,
    compliance: boolean,
    retentionDuration: number,
    retentionUnit: string,
    deletionMarker: boolean
  ) {
    const url = "/api/v2/buckets";
    let retentionObject = {
      quantity: 0,
      unit: ""
    };
    if (compliance) {
      retentionObject = {
        quantity: Math.abs(retentionDuration),
        unit: retentionUnit
      };
    }
    const data = {
      name: name,
      policy: policy,
      compliance: compliance,
      RetentionDuration: retentionObject,
      deleteObjectMarker: deletionMarker,
      region: region
    };
    return this.makeRequest("POST", url, data);
  }

  deleteBucket(name: string) {
    const url = `/api/v2/buckets/${name}`;
    const data = { name: name };
    return this.makeRequest("DELETE", url, data);
  }

  canDeleteBucket(name: string) {
    const url = `/api/v2/buckets/${name}/delete_check`;
    return this.makeRequest("GET", url);
  }

  updateComplianceDetails(
    name: string,
    retentionDuration: number,
    retentionUnit: string,
    deletionMarker: boolean
  ) {
    const url = `/api/v2/buckets/${name}/compliance`;
    const data = {
      name: name,
      retentionDuration: Math.abs(retentionDuration),
      retentionUnit: retentionUnit,
      deletionMarker: deletionMarker
    };
    return this.makeRequest("PATCH", url, data);
  }

  getComplianceDetails(name: string) {
    const url = `/api/v2/buckets/${name}/compliance`;

    return this.makeRequest("GET", url);
  }

  disableCompliance(name: string) {
    const url = `/api/v2/buckets/${name}/disable_compliance`;
    const data = {
      name: name
    };
    return this.makeRequest("PATCH", url, data);
  }

  toggleS3Logging(name: string, logging: boolean) {
    const url = `/api/v2/buckets/${name}/logging`;
    const data = {
      name: name,
      enable: logging
    };
    return this.makeRequest("PATCH", url, data);
  }

  getS3Logging(name: string) {
    const url = `/api/v2/buckets/${name}/logging`;
    const data = {
      name: name
    };
    return this.makeRequest("GET", url, data);
  }

  listBuckets() {
    const url = "/api/v2/buckets";
    return this.makeRequest("GET", url);
  }

  async listArchiveBuckets() {
    const url = "/api/v2/buckets?bucket_type=archival";
    const data = await this.makeRequest("GET", url);
    return data?.buckets?.map((bucket: any) => ({
      ...bucket,
      usage: bucket?.size ? parseInt(bucket.size, 10) : 0
    }));
  }

  async listArchiveRegions() {
    const url = "/api/v2/archival/regions";
    const data = await this.makeRequest("GET", url);
    return data?.regions
      .sort((region1: Region, region2: Region) => {
        if (region1.default) {
          return -1;
        } else if (region2.default) {
          return 1;
        }
        return 0;
      })
      .map((region: Region) => ({
        name: region.name,
        description: region.description,
        url: region.url
      }));
  }

  getBucketDetails(name: string) {
    const url = `/api/v2/buckets/${name}`;
    return this.makeRequest("GET", url);
  }

  getBucketSettings(name: string) {
    const url = `/api/v2/buckets/${name}/settings`;
    return this.makeRequest("GET", url);
  }

  isBucketExists(name: string) {
    const url = `/api/v2/user/unique-bucketcheck?name=${name}`;
    const data = {
      name: name
    };
    return this.makeRequest("GET", url, data);
  }

  makeStartHereBucket(
    name: string,
    region: string,
    permname: string,
    permdesc: string,
    svcname: string,
    svcdesc: string
  ) {
    const url = "/api/v2/start-here-bucket";
    const data = {
      name: name,
      region: region,
      permname: permname,
      permdesc: permdesc,
      svcname: svcname,
      svcdesc: svcdesc
    };
    return this.makeRequest("POST", url, data);
  }

  getBucketCounter() {
    const url = "/api/v2/user/bucketcounter";
    return this.makeRequest("GET", url);
  }

  getS3Domain() {
    const url = "/api/v2/s3domain";
    return this.makeRequest("GET", url);
  }

  getRegionsWithSG() {
    const url = "/api/v2/regionswithsg";
    return this.makeRequest("GET", url);
  }

  countBucket() {
    const url = "/api/v2/buckets/count";
    return this.makeRequest("GET", url);
  }

  // bucket replication APIs

  getReplicationRules(bucketName: string) {
    const url = `/api/v2/bucket_replication/${bucketName}`;
    return this.makeRequest("GET", url);
  }

  addReplicationRule(source_bucket_name: string, target_bucket_name: string) {
    const url = `/api/v2/bucket_replication`;
    const body = {
      source_bucket_name: source_bucket_name,
      target_bucket_name: target_bucket_name
    };

    return this.makeRequest("POST", url, body);
  }

  deleteReplicationRule(
    source_bucket_name: string,
    target_bucket_name: string
  ) {
    const url = `/api/v2/bucket_replication`;
    const body = {
      source_bucket_name: source_bucket_name,
      target_bucket_name: target_bucket_name
    };
    return this.makeRequest("DELETE", url, body);
  }

  replicationBucketList(bucketName: string) {
    const url = `/api/v2/bucket/${bucketName}/list_replication`;
    return this.makeRequest("GET", url);
  }

  // bucket compliance APIs
  updateBucketCompliance(bucketId: string, updateTo: boolean) {
    //true = on, false = off
    const data = {
      bucket: bucketId,
      bucketCompliance: updateTo
    };
    const url = `/api/v2/org/updateCompliance`;
    return this.makeRequest("POST", url, data);
  }

  // User Invitation operations
  userAddInvitation(
    firstName: string,
    lastName: string,
    email: string,
    role: string,
    authType: string
  ) {
    const url = `/api/v2/users/add_invite`;
    const auth_type = authType === "federated" ? "federated" : "mfa";
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      role: role,
      auth_type: auth_type
    };
    return this.makeRequest("POST", url, data);
  }

  updateUserDetails(
    userId: string,
    firstName: string,
    lastName: string,
    role: string
  ) {
    const url = `/api/v2/users/update`; // API for updating user details
    const data = {
      user_id: userId,
      first_name: firstName,
      last_name: lastName,
      role: role
    };
    return this.makeRequest("POST", url, data);
  }

  userResetPassword(name: string, email: string) {
    const url = "/api/v2/users/reset_invite";
    const data = {
      name: name,
      email: email
    };
    return this.makeRequest("POST", url, data);
  }

  // User operations
  userChangePassword(oldPassword: string, newPassword: string) {
    const url = "/api/v2/users/change_password";
    const data = {
      old_password: oldPassword,
      new_password: newPassword
    };
    return this.makeRequest("PATCH", url, data);
  }

  userEnable(id: string) {
    const url = `/api/v2/users/${id}/enable`;
    const data = {};
    return this.makeRequest("POST", url, data);
  }

  userDisable(id: string) {
    const url = `/api/v2/users/${id}/disable`;
    const data = {};
    return this.makeRequest("POST", url, data);
  }

  listUsers() {
    const url = "/api/v2/users";
    return this.makeRequest("GET", url);
  }

  validateInvite(urlToken: string) {
    const url = "/api/v2/validate_invite";
    const data = {
      url_token: urlToken
    };
    return this.makeRequest("POST", url, data);
  }

  setPassword(urlToken: string, password: string) {
    const url = "/api/v2/users/set_password";
    const data = {
      url_token: urlToken,
      password: password
    };
    return this.makeRequest("POST", url, data);
  }

  forgotPassword(email: string) {
    const url = "/api/v2/users/forgot_password";
    const data = {
      // company: company.toLocaleLowerCase(),
      email: email
    };
    return this.makeRequest("POST", url, data);
  }

  // Permissions operations

  taskStatus(id: string, action_type: string) {
    const url = `/api/v2/status/${id}/${action_type}`;
    return this.makeRequest("GET", url);
  }

  addPermission(
    name: string,
    description: string,
    effect: string,
    resources: string[],
    actions: string[],
    option: string,
    prefix: string
  ) {
    const url = "/api/v2/permissions";
    const data = {
      name: name,
      description: description,
      effect: effect,
      resources: resources,
      actions: actions,
      option,
      prefix: option === "prefix" ? prefix : undefined
    };
    return this.makeRequest("POST", url, data);
  }

  updatePermission(
    id: string,
    name: string,
    description: string,
    effect: string,
    resources: string[],
    actions: string[],
    option: string,
    prefix: string
  ) {
    const url = `/api/v2/permissions/${id}`;
    const data = {
      id: id,
      name: name,
      description: description,
      effect: effect,
      resources: resources,
      actions: actions,
      option,
      prefix: option === "prefix" ? prefix : undefined
    };
    return this.makeRequest("PUT", url, data);
  }

  deletePermission(id: string) {
    const url = `/api/v2/permissions/${id}`;
    const data = {
      id: id
    };
    return this.makeRequest("DELETE", url, data);
  }

  listPermissions(limit?: Number, offset?: Number) {
    const url = `/api/v2/permissions?limit=${limit ? limit : 0}&offset=${
      offset ? offset : 0
    }`;
    return this.makeRequest("GET", url);
  }

  addPolicyPermission(
    name: string,
    description: string,
    policy_content: string
  ) {
    const url = "/api/v2/importpolicy";
    const data = {
      name: name,
      description: description,
      policy_content: policy_content
    };
    return this.makeRequest("POST", url, data);
  }

  updatePolicyPermission(
    id: string,
    name: string,
    description: string,
    policy_content: string
  ) {
    const url = "/api/v2/updatepermission";
    const data = {
      id: id,
      name: name,
      description: description,
      policy_content: policy_content
    };
    return this.makeRequest("PUT", url, data);
  }

  getPolicyPermissionwithID(id: string) {
    const url = `/api/v2/permission/${id}`;
    return this.makeRequest("GET", url);
  }
  // Service Accounts operations
  listServiceAccounts(limit?: Number, offset?: Number) {
    const url = `/api/v2/service_accounts?limit=${limit ? limit : 0}&offset=${
      offset ? offset : 0
    }`;
    return this.makeRequest("GET", url);
  }

  getServiceAccount(id: string) {
    const url = `/api/v2/service_accounts/${id}`;
    return this.makeRequest("GET", url);
  }

  enableServiceAccount(id: string) {
    const url = `/api/v2/service_accounts/${id}/enable`;
    return this.makeRequest("POST", url);
  }

  disableServiceAccount(id: string) {
    const url = `/api/v2/service_accounts/${id}/disable`;
    return this.makeRequest("POST", url);
  }

  removeServiceAccount(id: string) {
    const url = `/api/v2/service_accounts/${id}`;
    return this.makeRequest("DELETE", url);
  }

  addServiceAccount(
    name: string,
    permission_ids: string,
    full_access: boolean
  ) {
    const url = "/api/v2/service_accounts";
    const data = {
      name: name,
      permission_ids: permission_ids,
      full_access: full_access
    };
    return this.makeRequest("POST", url, data);
  }

  updateServiceAccount(
    id: string,
    name: string,
    permission_ids: string,
    enabled: boolean,
    full_access: boolean
  ) {
    const url = `/api/v2/service_accounts/${id}`;
    const data = {
      name: name,
      permission_ids: permission_ids,
      enabled: enabled,
      full_access: full_access
    };
    return this.makeRequest("PUT", url, data);
  }

  fetchSecretandAcessKey(id: string) {
    const url = `/api/v2/get-sa-vault`;
    const data = {
      id: id
    };
    return this.makeRequest("POST", url, data);
  }

  // Notification API's
  notificationUnsubscribe(code: string) {
    const url = `/api/v2/notificationunsubscribe`;
    const data = {
      code: code
    };
    return this.makeRequest("POST", url, data);
  }

  getAllNotificationRecipients(customerId: string) {
    const url = `/api/v2/notificationrecipientslist`;
    return this.makeRequest("GET", url);
  }

  addNotificationRecipient(
    customerId: string,
    firstName: string,
    lastName: string,
    email: string
  ) {
    const url = `/api/v2/notificationrecipient`;
    const data = {
      customer_id: customerId,
      firstname: firstName,
      lastname: lastName,
      email: email
    };
    return this.makeRequestWithCode("POST", url, data);
  }

  editNotificationRecipient(
    userId: string,
    firstName: string,
    lastName: string,
    email: string
  ) {
    const url = `/api/v2/notificationrecipient/${userId}`;
    const data = {
      firstname: firstName,
      lastname: lastName,
      email: email
    };
    return this.makeRequestWithCode("PUT", url, data);
  }

  removeNotificationRecipient(id: string) {
    const url = `/api/v2/notificationrecipient/${id}`;
    return this.makeRequest("DELETE", url);
  }

  updateMFAPreference(mfa_preference: string) {
    const data = {
      mfa_preference: mfa_preference
    };
    const url = `/api/v2/org/mfa_preference`;
    return this.makeRequest("POST", url, data);
  }

  resetUserMFA(id: string) {
    const url = `/api/v2/users/${id}/reset_mfa`;
    return this.makeRequest("POST", url);
  }

  getSAMLConfiguration() {
    const url = "/api/v2/saml";
    return this.makeRequest("GET", url);
  }

  deleteSAMLConfiguration() {
    const url = "/api/v2/saml";
    return this.makeRequest("DELETE", url);
  }

  uploadSAMLMetadata(fileContent: string) {
    const url = "/api/v2/saml";
    const data = {
      metadataXML: fileContent
    };
    return this.makeRequest("POST", url, data);
  }

  updateSAMLMetadata(fileContent: string) {
    const url = "/api/v2/saml";
    const data = {
      metadataXML: fileContent
    };
    return this.makeRequest("PATCH", url, data);
  }

  resetMFA(userId: string) {
    const url = `/api/v2/users/${userId}/reset_mfa`;
    return this.makeRequest("POST", url);
  }

  getAllConfig() {
    const url = "/api/v2/config";
    return this.makeRequest("GET", url);
  }

  getConfig(configName: string) {
    const url = `/api/v2/config/${configName}`;
    return this.makeRequest("GET", url);
  }

  updateConfig(config: any) {
    const url = "/api/v2/config";
    return this.makeRequest("POST", url, config);
  }

  deleteUser(id: string) {
    const url = `/api/v2/users/${id}`;
    return this.makeRequestWithCode("DELETE", url);
  }

  // Get eval status API
  getEvalStatus(customerName: string) {
    const url = `/api/v2/eval/syncbydomain/${customerName}`;
    return this.makeRequest("GET", url);
  }

  // Get eval sf urls API
  getEvalUrls(sfId: string) {
    const url = `/api/v2/eval/sfurl/${sfId}`;
    return this.makeRequest("GET", url);
  }

  downloadUsageReport(from_date: string, to_date: string) {
    const url = "/api/v2/downloadusagereport";
    const data = {
      from_date: from_date,
      to_date: to_date
    };
    return this.makeRequest("POST", url, data);
  }

  getAvailability() {
    const url = "/api/v2/lcavailability";
    return this.makeRequest("GET", url);
  }

  getTenantEstimatedCost() {
    const url = "/api/v2/tenant-estimated-cost";
    return this.makeRequest("GET", url);
  }

  getFixedCustomerDetails() {
    const url = "/api/v2/fixed-customer";
    return this.makeRequest("GET", url);
  }

  cancelLyveCloudService() {
    const url = "/api/v2/cancel-lc-service";
    return this.makeRequest("POST", url);
  }

  getHelpURl() {
    const url = "/api/v2/help_url";
    return this.makeRequest("GET", url);
  }

  listInvoices(startDate: string, endDate: string, timezone?: string) {
    const url = `/api/v2/erp-invoice-data?timezone=${timezone}&fromDate=${startDate}&toDate=${endDate}`;
    return this.makeRequest("GET", url);
  }

  getProductList() {
    const url = "/api/v2/products";
    return this.makeRequest("GET", url);
  }

  getInvoicePDF(
    billto_customer_name: string,
    invoiceNumber: string,
    projectNumber: string,
    invoiceDate: string
  ) {
    const url = `/api/v2/retrieveinvoice?billto_customerNumber=${billto_customer_name}&invoiceNumber=${invoiceNumber}&projectNumber=${projectNumber}&invoiceDate=${invoiceDate}`;
    return this.makeRequest("GET", url);
  }

  // getHelpJWT() {
  // 	storage.removeItem('help_jwt');
  // 	api
  // 		.whoami()
  // 		.then((res) => {
  // 			if (res.account_id !== undefined) {
  // 				api.getHelpURl().then((res) => {
  // 					var url = res.help_url;
  // 					api.setHelpJWTURL(url);
  // 				});
  // 			}
  // 		})
  // 		.catch((err) => {
  // 			//	api.setHelpJWTURL('');
  // 			console.log('Exception error: got error when checking currect user identity.');
  // 		});
  // }

  // smart-start
  smartStartUrl = "/api/v2/user/smart-start-here";
  async getSmartStartConfig() {
    const res: any = await this.makeRequest("GET", this.smartStartUrl);
    if (res?.code === 200) {
      let data = toCamelCaseKeys(res.data);
      return {
        allBuckets: data.allBuckets || 0,
        ownedBuckets: data.ownedBuckets || 0,
        createdUsers: data.createdUsers || 0,
        closedCards: data.closedCards
          ? data.closedCards.map((card: ClosedCard) => card.cardName)
          : [],
        autoExpandOnLogin: data.autoExpandOnLogin || false,
        movedToProfileDropdown: data.movedToProfileDropdown || false
      };
    }
    return {
      allBuckets: 0,
      ownedBuckets: 0,
      createdUsers: 0,
      closedCards: [],
      autoExpandOnLogin: true,
      movedToProfileDropdown: false
    };
  }

  updateSmartStartConfig(data: SmartStartUPDATE) {
    return this.makeRequest("PUT", this.smartStartUrl, toSnakeCaseKeys(data));
  }

  updateSmartStartCard(data: SmartStartCard) {
    const url = "/api/v2/user/start-here-cards";
    return this.makeRequest("PATCH", url, toSnakeCaseKeys(data));
  }

  async shouldReleaseMarketplace() {
    const url = "/api/v2/get-marketplace-link";
    const res = await this.makeRequest("GET", url);
    return toCamelCaseKeys(res);
  }

  acceptLyveTerms(acceptedTimestamp: string) {
    const url = "/api/v2/break-glass-tnc";
    return this.makeRequest("PUT", url, { tnc_date: acceptedTimestamp });
  }

  getCurrentEnv() {
    const url = "/api/v2/get-current-env";
    return this.makeRequest("GET", url);
  }

  getSubaccount(account_short_name: string) {
    const url = `/api/v2/subaccount/${account_short_name}`;
    return this.makeRequest("GET", url);
  }

  getSubaccounts(parent_account_short_name: string) {
    const url = `/api/v2/subaccount/${parent_account_short_name}/list`;
    return this.makeRequest("GET", url);
  }

  createSubAccount(account: SubAccountForm, retryRequestId: string) {
    const url = "/api/v2/subaccounts/create";
    const companyShortName = storage.getItem("company_short_name");

    const info = {
      name: account.accName,
      short_name: account.accId,
      admin_first_name: account.fName,
      admin_last_name: account.lName,
      admin_email: account.email?.toLowerCase(),
      org_name: account.orgName,
      parent_short_name: companyShortName
    };

    const address = {
      address_line1: account.streetAddr1,
      address_line2: account.streetAddr2,
      city: account.city,
      state: account.state,
      zip_code: account.zipCode,
      country: account.country,
      phone_number: account.number
    };

    const sub_account = { ...info, ...(!!account.streetAddr1 ? address : {}) };
    return this.makeRequest("POST", url, {
      sub_account: sub_account,
      retry_request_id: retryRequestId
    });
  }

  getServiceAccountExpiry() {
    const url = `/api/v2/service_account_setting`;
    return this.makeRequest("GET", url);
  }

  disableServiceAccountExpiry() {
    const url = `/api/v2/service_account_setting/disable`;
    return this.makeRequest("DELETE", url);
  }

  updateServiceAccountExpiry(data: any) {
    const url = `/api/v2/service_account_setting/enable`;
    return this.makeRequest("PUT", url, data);
  }
  getDashboardTrend(
    master_account_id: string,
    periodicity: string,
    from_date: string,
    to_date: string,
    timezone: string
  ) {
    const url = "/api/v2/master/dashboardtrend";
    //need a discussion about timezone
    const data = {
      master_account_id,
      periodicity,
      from_date,
      to_date
      // timezone
    };
    return this.makeRequest("POST", url, data);
  }

  getDashboardSummary(account_id: string) {
    const url = "/api/v2/master/dashboardsummary";
    const data = {
      account_id
    };
    return this.makeRequest("POST", url, data);
  }

  downloadMasterUsageReport(
    from_date: string,
    to_date: string,
    accountId: string
  ) {
    const url = "/api/v2/master/dashboard-download-report";
    const data = {
      account_id: accountId,
      from_date: from_date,
      to_date: to_date
    };
    return this.makeRequest("POST", url, data);
  }

  getSubaccountCostMetrics(
    account_id: string,
    from_date: string,
    to_date: string,
    frequency: string,
    timezone: string
  ) {
    const url = "/api/v2/sub-account/costmetrics";
    const data = {
      account_id: account_id,
      from_date: from_date,
      to_date: to_date,
      frequency: frequency,
      timezone: timezone
    };
    return this.makeRequest("POST", url, data);
  }

  downloadSubaccountBucketUsage(
    account_id: string,
    from_date: string,
    to_date: string,
    frequency: string,
    timezone: string
  ) {
    const url = "/api/v2/sub-account/bucketusage";
    const data = {
      account_id: account_id,
      from_date: from_date,
      to_date: to_date,
      frequency: frequency,
      timezone: timezone
    };
    return this.makeRequest("POST", url, data);
  }

  downloadSubaccountUsageReport(
    from_date: string,
    to_date: string,
    accountId: string
  ) {
    const url = "/api/v2/sub-account/dashboard-download-report";
    const data = {
      account_id: accountId,
      from_date: from_date,
      to_date: to_date
    };
    return this.makeRequest("POST", url, data);
  }

  getSubaccountBuckets(account_id: string) {
    const url = `/api/v2/sub-account/${account_id}/buckets-count`;
    return this.makeRequest("GET", url);
  }

  toggleSubAccount(sub_account_short_name: string, isEnabled: boolean) {
    const url = `/api/v2/subaccounts/${sub_account_short_name}/${
      isEnabled ? "disable" : "enable"
    }`;
    const data = {
      sub_account_short_name: sub_account_short_name
    };
    return this.makeRequest("POST", url, data);
  }

  async deleteSubAccount(sub_account_short_name: string) {
    const url = `/api/v2/subaccounts/${sub_account_short_name}/delete`;
    const data = { sub_account_short_name };
    return this.makeRequest("POST", url, data);
  }

  getMPOboardingInfo(partnerName: string = "") {
    const url = `/api/v2/mp_onboarding_info${
      partnerName ? `?partner=${partnerName}` : ""
    }`;
    return this.makeRequest("GET", url);
  }

  getDataMoverWorkerDownload() {
    const url = `/api/v2/datamover/worker`;
    return this.makeRequest("GET", url);
  }

  getDataMoverPortalDownload() {
    const url = `/api/v2/datamover/portal`;
    return this.makeRequest("GET", url);
  }

  // features flag
  getEnabledFeatures() {
    const url = `/api/v2/enabled_features`;
    return this.makeRequest("GET", url);
  }

  getDomain() {
    const url = `/api/v2/env`;
    return this.makeRequest("GET", url);
  }

  getAccountAPIExpirationDate() {
    const url = `/api/v2/getaccountapiexpirydate`;
    return this.makeRequest("GET", url);
  }

  fetchAccessKeyAndSecretKeyForAccountAPI() {
    const url = `/api/v2/generate/accountapi/credentials`;
    return this.makeRequest("GET", url);
  }

  regenerateAccountAPICredentials() {
    const url = `/api/v2/regeneratekeys`;
    return this.makeRequest("GET", url);
  }

  deleteAccountAPICredentials() {
    const url = `/api/v2/accountapi/deletekeys`;
    return this.makeRequest("DELETE", url);
  }

  getRetrySubaccount(requestId: string) {
    const encodedRequestId = encodeURIComponent(requestId);
    const url = `/api/v2/subaccount/${encodedRequestId}/retry`;
    return this.makeRequest("GET", url, {});
  }

  dismissFailedSubaccount(requestId: string) {
    const encodedRequestId = encodeURIComponent(requestId);
    const url = `/api/v2/subaccount/${encodedRequestId}/dismiss`;
    return this.makeRequest("PUT", url, {});
  }

  getCancellationEstimatedCost(){
    const url = `/api/v2/estimate-cost-on-cancel`;
    return this.makeRequest('GET', url);
  }
}

const api = new API();
export default api;
