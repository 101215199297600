export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const SET_ERROR_NOTIFICATION = "SET_ERROR_NOTIFICATION"
export const SET_WARNING_NOTIFICATION = "SET_WARNING_NOTIFICATION"

export interface NotificationState {
  show: boolean;
  message: string | React.ReactNode;
  error: boolean;
  warning: boolean;
}

interface SetNotificationAction {
  type: typeof SET_NOTIFICATION;
  message: string;
}

interface SetErrorNotificationAction {
  type: typeof SET_ERROR_NOTIFICATION;
  message: string;
}

interface SetWarningNotificationAction {
  type: typeof SET_WARNING_NOTIFICATION;
  message: string;
}

interface ClearNotificationAction {
  type: typeof CLEAR_NOTIFICATION;
}

export type NotificationActionTypes =
  | SetNotificationAction
  | SetErrorNotificationAction
  | ClearNotificationAction
  | SetWarningNotificationAction;
