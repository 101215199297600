import React from "react";
import BaseCard from "./BaseCard";

import "./styles.scss";
import { ChevronRight, FileEarmarkText } from "react-bootstrap-icons";
import { CardProps } from "../../scenes/SmartStartHere/types.d";
import { getLink, linkNames } from "../LyveHeadingLink/LinkUtil";

declare type LinkProps = {
  link: string;
  title: string;
};

const CardLink: React.FC<LinkProps> = ({ link, title }) => (
  <div className="links">
    <FileEarmarkText />
    <a className="ml-2" href={link} target="_blank">
      {title}
    </a>
  </div>
);

const UsefulResourcesCard = (props: CardProps) => {
  return (
    <BaseCard
      {...props}
      className="resources-card"
      title="Useful Resources"
      description="Here are a few resources you might find useful:"
      body={
        <div className="d-flex flex-column justify-content-center">
          <CardLink
            link={getLink(linkNames.Quick_start_guide)}
            title="Quick start guide"
          />
          <CardLink link={getLink(linkNames.API_Guide)} title="API guide" />
          <CardLink link={getLink(linkNames.FAQ)} title="FAQ" />
        </div>
      }
      footer={
        <div className="footer">
          <a
            href={getLink(linkNames.Help)}
            className="base-card-footer-link"
            target="_blank"
          >
            <span className="mr-1"> More support resources </span>
            <ChevronRight size={10} />
          </a>
        </div>
      }
    />
  );
};

export default UsefulResourcesCard;
