import React from "react";
import ReactTooltip from "react-tooltip";
import moment from "moment-timezone";

import { subaccount_summary } from "../index";
import history from "../../../../../history";
import LyveTable from "../../../../../components/LyveTable";
import { convertFileSize } from "../../../../../utils";

interface SubaccountsUsageChartProps {
  subaccountList?: any;
  loading: boolean
}

const SubaccountsUsageChart: React.FC<SubaccountsUsageChartProps> = ({ subaccountList, loading }) => {
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All"
  };

  const NoData = (
    <table className="table empty-table">
      <tbody>
        <tr>
          <td colSpan={7}>
            <div>
              <div
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  fontWeight: 500
                }}
              >
                You do not have any customers.
                <span className="pointer">
                  <a onClick={() => history.push("/partner/tenant")}>
                    {" "}
                    Create one now
                  </a>
                </span>{" "}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );

  const columns = [
    {
      name: "Account Name",
      selector: "master_or_sub_account_name",
      minWidth: "200px",
      sortable: true,
      cell: (row: subaccount_summary) => (
        <span title={row.master_or_sub_account_name}>
          {row?.master_or_sub_account_name ?? ""}
        </span>
      )
    },
    {
      name: "Users",
      selector: "users_count",
      sortable: true,
      cell: (row: subaccount_summary) => (
        <span title={String(row?.users_count ?? 0)}>
          {row?.users_count ?? 0}
        </span>
      )
    },
    {
      name: "Service Accounts",
      selector: "service_accounts_count",
      sortable: true,
      cell: (row: subaccount_summary) => (
        <span title={String(row?.service_accounts_count ?? 0)}>
          {row?.service_accounts_count ?? 0}
        </span>
      )
    },
    {
      name: "Buckets",
      selector: "buckets_count",
      sortable: true,
      cell: (row: subaccount_summary) => (
        <span title={String(row?.buckets_count ?? 0)}>
          {row?.buckets_count ?? 0}
        </span>
      )
    },
    {
      name: (
        <span>
          Average Usage
          <span
            data-for="table-with-data"
            className="badge badge-dark f-tooltip-bagde"
            data-tip="The average amount of data stored, per day,<br /> from the beginning of the current month to date."
          >
            ?
          </span>
          <ReactTooltip
            id="table-with-data"
            place="bottom"
            multiline
            effect="solid"
            className="f-tooltip"
          />
        </span>
      ),

      cell: (row: subaccount_summary) => (
        <div
          className="d-flex align-items-center"
          style={{ width: "100%", height: 27, position: "relative" }}
        >
          {
            <div
              style={{
                width: row.usage_percent,
                height: "100%",
                background: "#E3F3DC",
                border: "1px solid #6EBE49"
              }}
            />
          }

          <div
            className="d-flex align-items-center justify-content-between w-100"
            style={{ position: "absolute" }}
          >
            <span style={{ marginLeft: 5 }}>
              {convertFileSize(row?.usage ?? 0)}
            </span>

            {row.usage_percent !== undefined && (
              <span style={{ marginRight: 5 }}>{row.usage_percent}</span>
            )}
          </div>
        </div>
      ),
      minWidth: "250px",
      selector: "usage",
      sortable: true,
    },
    {
      name: "Created On",
      selector: "onboard_date",
      sortable: true,
      cell: (row: subaccount_summary) => row.onboard_date ? (
        <span title={row.onboard_date}>
          {moment.utc(row.onboard_date).local().format('YYYY-MM-DD')}
        </span>
      ) : <span />
    }
  ];

  return (
    <div>
      <LyveTable
        columns={columns}
        data={subaccountList}
        defaultSortField="usage"
        defaultSortAsc={true}
        selectableRows={false}
        pagination={true}
        // noHeader={true}
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 25, 50]}
        highlightOnHover={true}
        paginationIconFirstPage={false}
        paginationIconLastPage={false}
        paginationComponentOptions={paginationOptions}
        noDataComponent={NoData}
        progressPending={loading}
        persistTableHead={true}
      />
    </div>
  );
};

export default SubaccountsUsageChart;
