import React from "react";
import { Spinner } from "react-bootstrap";

import api from "../../../../../../services/RegionApis";
import { DefaultButton } from "../../../../../../components/LyveButton";

import "./styles.scss";
import {useAppSelector} from "../../../../../../store";
import {selectRegionsWithSG} from "../../../../../../state/regionswithsg";

interface DeletePromptProps {
  permission: any;
  baseUrl: string;
  closeModal: () => void;
  onSuccess?: (data: any) => void;
  onError?: (err: any) => void;
}

const DeletePrompt: React.FC<DeletePromptProps> = ({
  permission,
  baseUrl,
  closeModal,
  onSuccess = () => null,
  onError = () => null
}) => {
  const [error, setError] = React.useState<string>("");
  const [isFormProcessing, setFormProcessing] = React.useState<boolean>(false);
  const {data: regionswithsg} = useAppSelector(selectRegionsWithSG);

  const handleDeletePermission = (url: string) => {
    setFormProcessing(true);
    api
      .deletePermission(permission.id, url)
      .then(data => {
        setFormProcessing(false);
        onSuccess(data);
      })
      .catch(err => {
        if(regionswithsg.length !== 0){
            let index = regionswithsg.map(function(e){
                return e.url;
            }).indexOf(url);
            if(index == regionswithsg.length-1){
                setFormProcessing(false);
                setError(err);
                onError(err);
            } else {
                handleDeletePermission(regionswithsg[index+1].url);
            }
        }  else {
            setFormProcessing(false);
            setError(err);
            onError(err);
        }

      });
  };

  return (
    <div className="delete-permission-module">
      <h4 className="delete-permission-title">Delete Permission</h4>
      <h4 className="delete-permission-title2">
        Are you sure you want to delete this permission?
      </h4>
      <p className="delete-permission-subtitle">
        This action will permanently delete the permission. You can't undo this
        action.
      </p>
      <div style={{ marginBottom: 24 }}>
        <label className="permission-label">Permission Name</label>
        <p className="permission-name">{permission.name || ""}</p>
        {error !== "" && <p className="validation-error">{error}</p>}
      </div>
      <div>
        <DefaultButton
          className="mr-2"
          variant="danger"
          disabled={isFormProcessing}
          onClick={event => {
            handleDeletePermission(baseUrl);
          }}
        >
          {isFormProcessing ? (
            <Spinner animation="border" role="status" className="spinner">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            "Yes"
          )}
        </DefaultButton>
        <DefaultButton
          disabled={isFormProcessing}
          onClick={closeModal}
          variant="outline-secondary"
        >
          No
        </DefaultButton>
      </div>
    </div>
  );
};

export default DeletePrompt;
