import React from "react";

import SupportDashboard from "../../../../PartnerPortal/Dashboard/components/Support/components/SupportDashboard";

import "./styles.scss";
import cx from "classnames";
import LyveLink from "../../../../components/LyveHeadingLink/LyveLink";
import {
  getLink,
  linkNames
} from "../../../../components/LyveHeadingLink/LinkUtil";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store";
import { SubAccountData } from "../../../../state/Subaccounts/types";
import Forbidden from "../../../../components/Forbidden";
import { Spinner } from "react-bootstrap";

interface IOwnProps {}

const CustomerSupport: React.FC<IOwnProps> = () => {
  const account = useSelector<AppState, SubAccountData | null>(
    state => state.subaccounts.masterAccount
  );

  if (!account) {
    return (
      <div className="data-loader">
        <Spinner animation="border" role="status" className="spinner">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return account.is_master || account.sub_account_support ? (
    <div className="customer-support-wrapper">
      <div className="heading-area">
        <h4 className={cx("lyve-page-main-title", { "m-remove": true })}>
          Support
          <LyveLink link={getLink(linkNames.Support)} />
        </h4>
      </div>

      <SupportDashboard />
    </div>
  ) : (
    <Forbidden />
  );
};

export default CustomerSupport;
