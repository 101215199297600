export const bucketLoadingState = [
	{
		title: "Creating bucket...",
		subtitle: "Please wait."
	},
	{
		title: "Creating bucket...",
		subtitle: "Still working on it."
	},
	{
		title: "Creating bucket...",
		subtitle: "Please wait."
	},
	{
		title: "Creating bucket...",
		subtitle: "Still working on it."
	},
	{
		title: "Creating bucket...",
		subtitle: "Please wait."
	},
	{
		title: "Creating bucket...",
		subtitle: "Still working on it."
	},
	{
		title: "Creating bucket...",
		subtitle: "Please wait."
	},
	{
		title: "Creating bucket...",
		subtitle: "Still working on it."
	},
	{
		title: "Creating bucket...",
		subtitle: "Please wait."
	},
	{
		title: "Creating bucket...",
		subtitle: "Still working on it."
	},
	{
		title: "Creating bucket...",
		subtitle: "Please wait."
	},
	{
		title: "Creating bucket...",
		subtitle: "Still working on it."
	},
	{
		title: "Creating bucket...",
		subtitle: "Please wait."
	},
	{
		title: "Creating bucket...",
		subtitle: "Still working on it."
	},
	{
		title: "Creating bucket...",
		subtitle: "Please wait."
	},
	{
		title: "Creating bucket...",
		subtitle: "Still working on it."
	},
	{
		title: "Creating bucket...",
		subtitle: "Please wait."
	},
	{
		title: "Creating bucket...",
		subtitle: "Still working on it."
	},
	{
		title: "Creating bucket...",
		subtitle: "Please wait."
	},
	{
		title: "Creating bucket...",
		subtitle: "Still working on it."
	},
	{
		title: "Creating bucket...",
		subtitle: "Please wait."
	},
	{
		title: "Creating bucket...",
		subtitle: "Still working on it."
	},
	{
		title: "Creating bucket...",
		subtitle: "Please wait."
	},
	{
		title: "Creating bucket...",
		subtitle: "Still working on it."
	}
]
