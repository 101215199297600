import React from "react";
import Input from "../Input";
import "./styles.scss";
import { DefaultButton } from "../LyveButton";
import api from "../../services/api";
import common, {
  checkBreakGlassUser,
  removeBreakGlassUser
} from "../../services/common";
import Navbar from "react-bootstrap/Navbar";
import Spinner from "react-bootstrap/Spinner";
import logoURL from "../../images/Lyve-Cloud-Logo-Horiz-2-CLR.svg";
import moment from "moment-timezone";
import storage from "local-storage-fallback";
import { ECustomerType } from "../../scenes/Layout";

declare interface LinkProps {
  id: string;
  link: string;
  href: string;
  handleLink: () => void;
  handleCheckbox: () => void;
  termId: number;
  readingStates: number[];
  acceptedStates: number[];
}

const Condition: React.FC<LinkProps> = ({
  readingStates,
  termId,
  acceptedStates,
  ...rest
}) => {
  const [checked, setChecked] = React.useState(false);
  const isValid = readingStates.includes(termId) || !checked;
  const shouldCheck = isValid && acceptedStates.includes(termId);

  const handleChange = () => {
    if (readingStates.includes(termId)) {
      rest.handleCheckbox();
    }
    setChecked(!checked);
  };

  return (
    <div className="condition">
      <Input
        controlId={rest.id}
        controlAs="checkbox"
        onChange={handleChange}
        defaultChecked={shouldCheck}
        isInvalid={!isValid}
        error="Please click on the link and read before accepting."
        label={
          <>
            <span> I have read and accept </span>
            <a
              href={rest.href}
              target="_blank"
              rel="noopener noreferrer"
              onClick={rest.handleLink}
              className="helplink"
            >
              {rest.link}.
            </a>
          </>
        }
      />
    </div>
  );
};

const LyveTerms: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [readingStates, setReadingStates] = React.useState<number[]>([]);
  const [acceptedStates, setAcceptedStates] = React.useState<number[]>([]);
  const isSSCustomer = api.getCustomerType() === ECustomerType.FIXED_PRICE;

  const goToDashboard = () => {
    const userType = api.getUserType();
    if (common.isPartner(userType || "")) {
      window.open("/partner/dashboard", "_self");
    } else if (common.isAnyCustomer(userType || "")) {
      window.open("/customer/dashboard", "_self");
    }
  };

  const reload = (event: PageTransitionEvent) => {
    if (event.persisted) {
      window.location.reload();
    }
  };

  React.useLayoutEffect(() => {
    window.addEventListener("pageshow", reload);

    const token = storage.getItem("token")!;
    if (!token) {
      window.location.href = api.getLoginUrl();
    } else if (!checkBreakGlassUser()) {
      goToDashboard();
    }

    return () => {
      window.removeEventListener("pageshow", reload);
    };
  }, []);

  const handleConditionAcceptance = (conditionId: number) => {
    const conditionIdx = acceptedStates.indexOf(conditionId);
    if (conditionIdx >= 0) {
      const dupAcceptedStates = [...acceptedStates];
      dupAcceptedStates.splice(conditionIdx, 1);
      setAcceptedStates([...dupAcceptedStates]);
    } else {
      setAcceptedStates([...acceptedStates, conditionId]);
    }
  };

  const handleReadAcceptance = (conditionId: number) => {
    if (!readingStates.includes(conditionId)) {
      setReadingStates([...readingStates, conditionId]);
    }
  };

  const readAgreement = () => handleReadAcceptance(2);

  const acceptAgreement = () => handleConditionAcceptance(2);

  const shouldAcceptTerms =
    readingStates.length === 1 && acceptedStates.length === 1;

  const logout = () =>
    api.logout().then(res => {
      common.logoutCleanup(res);
    });

  const handleLogoClick = () => {
    window.location.href = window.location.origin;
  };

  const acceptLyveTerms = () => {
    setLoading(true);
    api
      .acceptLyveTerms(moment().format("YYYY-MM-DD HH:mm:ss"))
      .then(async res => {
        setLoading(false);
        if (res.code === 200) {
          removeBreakGlassUser();
          goToDashboard();
        } else {
          window.location.href = api.getLoginUrl();
        }
      })
      .catch(() => {
        setLoading(false);
        window.location.href = api.getLoginUrl();
      });
  };

  return (
    <React.Fragment>
      <Navbar expand="md" className="app-header">
        <button
          tabIndex={0}
          onClick={handleLogoClick}
          className="lyve-terms-logo"
        >
          <img src={logoURL} alt="logo" />
        </button>
      </Navbar>
      <div className="lyve-terms-container">
        <div className="lyve-terms">
          <h4> Terms and Conditions </h4>
          <strong>
            In order to use the Lyve Cloud services, you must first read and
            agree to the following terms.
          </strong>
          <div className="conditions">
            <Condition
              id="master-agreement"
              link="Lyve Services Clickwrap Agreement"
              href={
                isSSCustomer
                  ? "https://www.seagate.com/legal/lyve-cloud/h-order-service-terms/"
                  : "https://www.seagate.com/legal-privacy/lyve-cloud/service-terms/"
              }
              handleLink={readAgreement}
              handleCheckbox={acceptAgreement}
              termId={2}
              readingStates={readingStates}
              acceptedStates={acceptedStates}
            />
          </div>
          <DefaultButton
            className="lyve-terms-confirm-btn"
            disabled={!shouldAcceptTerms || loading}
            onClick={acceptLyveTerms}
          >
            {loading ? <Spinner animation="border" size="sm" /> : "Continue"}
          </DefaultButton>
          <DefaultButton variant="outline-secondary" onClick={logout}>
            Cancel
          </DefaultButton>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LyveTerms;
