import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import api from "../../services/api";
import common, { setBreakGlassUser } from "../../services/common";
import { UPDATE_WHOAMI } from "../../state/UserInfo/actions";

const LoginCallback: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  // commented code is kept in case we need it when we implement cookies, will be removed later
  //user cookie still got issue, so add the code temporarily
  // React.useEffect(() => {
  //   const queryParams = new URLSearchParams(location.search);
  //   const encodedSessionId = queryParams.get("sessionid");
  //   if (encodedSessionId) {
  //     const sessionId = atob(encodedSessionId);
  //     api.setAccessToken(sessionId);
  //     api.whoami().then(response => {
  //       if (response) {
  //         const userType = response.type;
  //         api.cleanLocalUserWhenSwitch();
  //         // it will be remove in next version
  //         api.setUserInfo(response);
  //         dispatch({ type: UPDATE_WHOAMI, payload: response });
  //         if (common.isPartner(userType)) {
  //           history.push("/partner/dashboard");
  //         } else if (common.isAnyCustomer(userType)) {
  //           history.push("/customer/dashboard");
  //         }
  //       }
  //     });
  //   } else {
  //     history.push("/error");
  //   }
  // }, [location]);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const state = queryParams.get("state");
    const code = queryParams.get("code");

    if (state && code) {
      api
        .validateUserSession(state, code)
        .then(async res => {
          if (res?.sessionid) {
            const sessionId = atob(res.sessionid);
            const redirectURL: string = res?.redirect_url || "";
            await api.setAccessToken(sessionId);
            const whoAmI = await api.whoami();
            const userType = whoAmI.type;
            dispatch({ type: UPDATE_WHOAMI, payload: whoAmI });
            api.setUserInfo(whoAmI);
            const parts = redirectURL.split("/");
            if (!redirectURL || parts[parts.length - 1] === "dashboard") {
              if (common.isPartner(userType)) {
                history.push("/partner/dashboard");
              } else if (common.isAnyCustomer(userType)) {
                history.push("/customer/dashboard");
              }
            } else {
              if (parts[parts.length - 1] === "terms&conditions") {
                setBreakGlassUser();
              }
              window.open(res.redirect_url, "_self");
            }
          } else {
            window.open(res.redirect_url || "/error", "_self");
          }
        })
        .catch(res => {
          common.logoutCleanup(null);
        });
    } else {
      history.push("/error");
    }
  }, [location]);

  //user cookie still got issue, so remove the code temporarily
  // React.useEffect(() => {
  //   const queryParams = new URLSearchParams(location.search);
  //   const state = queryParams.get("State");
  //   const code = queryParams.get("Code");

  //   // new login flow
  //   if (state && code) {
  //     api
  //       .validateUserSession(state, code)
  //       .then(res => {
  //         if (res.error && res.redirect_url) {
  //           window.open(res.redirect_url, "_self");
  //         } else {
  //           api
  //             .whoami()
  //             .then(response => {
  //               if (response) {
  //                 const userType = response.type;
  //                 api.cleanLocalUserWhenSwitch();
  //                 dispatch({ type: UPDATE_WHOAMI, payload: response });
  //                 if (common.isPartner(userType)) {
  //                   history.push("/partner/dashboard");
  //                 } else if (common.isAnyCustomer(userType)) {
  //                   history.push("/customer/dashboard");
  //                 }
  //               }
  //             })
  //             .catch(res => {
  //               common.logoutCleanup(null);
  //             });
  //         }
  //       })
  //       .catch(res => {
  //         common.logoutCleanup(null);
  //       });
  //   } else {
  //     history.push("/error");
  //   }
  // }, [location]);

  return (
    <div className="row align-items-center" style={{ height: "100vh" }}>
      <div className="col text-center">
        <div
          className="spinner-border"
          style={{ color: "#6ebe49" }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default LoginCallback;
