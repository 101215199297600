import React, { useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { BoxArrowUpRight } from "react-bootstrap-icons";
import { Ticket } from "../../Models/Ticket";
import AddIcon from "@material-ui/icons/Add";
import SupportCreateNewTicket from "../SupportCreateNewTicket";
import { formType } from "../../Models/formTypeEnum";
import {
  getPartnerDetails,
} from "../../Helpers/SupportHelpers";
import salesforceApi from "../../../../../../services/SalesforceAPI";
import listSubject from "../../Helpers/ListUpdateObserver";
import moment from "moment-timezone";
import Can from "../../../../../../components/Can";
import { SUPPORT_EDIT } from "../../../../../../authRules";
import common from "../../../../../../services/common";
import LyveTable from "../../../../../../components/LyveTable";
import { ModalContainer } from "../../../../../../components/Modal";
import { DefaultButton } from "../../../../../../components/LyveButton";
import DefaultAlert from "../../../../../../components/LyveAlert/DefaultAlert";
import { LyveSelect } from "../../../../../../components/LyveDropdown";
import useSmartStartKit from "../../../../../../scenes/SmartStartHere/useSmartStartKit";
import TablePagination from "@material-ui/core/TablePagination";
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { WhoAmI } from "../../../../../../state/UserInfo/types";
import { AppState } from "../../../../../../store";
import IconClose from "@material-ui/icons/HighlightOff";
import api from "../../../../../../services/api";

import "./styles.scss"

declare type TablePaginationActionsProps = {
  count: number;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  page: number;
  rowsPerPage: number;
};

const SupportTicketList: React.FC<{
  handleDetailsPoupShow?: (cNumber: string) => void;
  updateLoadingStatus?: (status: boolean) => void;
  updateCounts?: () => void;
  initialTickets?: Array<Ticket>;
}> = props => {
  const whoAmI = useSelector<AppState, WhoAmI>(state => state.userInfo.whoAmI);
  const [output, setOutput] = useState(false);
  const [errStatus, setErrStatus] = useState(false);
  const [errorMesssage, setErrorMesssage] = useState("");
  const [ticketCreationStatus, setTicketCreationStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user_type: string = whoAmI?.type ?? "";
  const user_type_mention: string = common.isAnyCustomer(user_type)
    ? "customer"
    : "partner";
  const partnerDetails = getPartnerDetails(whoAmI);
  const isAnyCustomer: boolean = partnerDetails.isAnyCustomer;
  const { showModal } = ModalContainer.useContainer();
  const [supportUrl, setSupportUrl] = useState("");
  const [showWarningMessage, setShowWarningMessage] = useState(true);

  const location: any = useLocation();

  /** Datatable */
  let columns = [
    {
      name: "Ticket No",
      selector: "CaseNumber",
      sortable: true,
      cell: (row: Ticket) => (
        <span title={row.CaseNumber}>
          <Link
            className="caseNumber"
            to={`/${user_type_mention}/support-dashboard/ticket-details/${row.CaseNumber}`}
          >
            {row.CaseNumber}
          </Link>
        </span>
      ),
      ignoreRowClick: true,
      width: "120px"
    },
    {
      name: "Subject",
      selector: "Subject",
      sortable: true,
      width: "25%",
      cell: (row: Ticket) => <span title={row.Subject}>{row.Subject}</span>
    },
    {
      name: "Reported By",
      selector: "Customer",
      sortable: true,
      width: "200px",
      cell: (row: Ticket) => (
        <span title={row.Customer}>
          {row.Customer !== "None" ? row.Customer : ""}
        </span>
      )
    },
    {
      name: "Submitted By",
      selector: "Email",
      sortable: true,
      cell: (row: Ticket) => <span title={row.Email}>{row.Email}</span>
    },
    {
      name: "Status",
      selector: "State",
      sortable: true,
      cell: (row: Ticket) => <span title={row.State}>{row.State}</span>
    },
    {
      name: "Severity",
      selector: "Severity",
      sortable: true,
      cell: (row: Ticket) => <span title={row.Severity}>{row.Severity}</span>
    },
    {
      name: "Created On",
      selector: "TimeCreatedDate",
      sortable: true,
      cell: (row: Ticket) => (
        // <span title={formatDate(row.TimeCreated, 'YYYY-MM-DD h:mm A')}>{formatDate(row.TimeCreated, 'YYYY-MM-DD h:mm A')}</span>
        <span title={row.TimeCreated}>{row.TimeCreated}</span>
      ),
      //format: (row: Ticket) => row.TimeCreated
    },
    {
      name: "	Aging (Day)",
      selector: "Aging",
      sortable: false,
      cell: (row: Ticket) => <span title={row.Aging}>{row.Aging}</span>
    }
  ];

  // if (isAnyCustomer) {
  //   columns = columns.filter(col => {
  //     return col.name !== "Reported By";
  //   });
  // }

  const [selectableRows, setSelectableRows] = React.useState(false);
  const [pagination, setPagination] = React.useState(true);
  const [highlight, setHighlight] = React.useState(true);
  // const [pointer, setPointer] = React.useState(false);
  // const [persist, setPersist] = React.useState(false);
  // const [tableHead, setNoHead] = React.useState(false);
  // const [noContextMenu, setNoContextMenu] = React.useState(false);
  // const [loading, setLoading] = React.useState(false);
  const [noHeader, setNoHeader] = React.useState(true);
  // const paginationOptions = {
  //   rowsPerPageText: "Rows per page:",
  //   rangeSeparatorText: "of",
  //   selectAllRowsItem: true,
  //   selectAllRowsItemText: "All"
  // };
  /** Datatable */

  /** Details popup */
  const [selectedCaseNumber, setSelectedCaseNumber] = useState("");
  const [ticketDetailsPopupShow, setTicketDetailsPopupShow] = useState(false);
  const handleTicketDetailsPopupClose = () => {
    setTicketDetailsPopupShow(false);
    updateCaseList();
  };
  const handleTicketDetailsPopupShow = () => setTicketDetailsPopupShow(true);
  const handleDetailsPoupShow = (cNumber: string) => {
    setSelectedCaseNumber(cNumber);
    setTicketDetailsPopupShow(true);
    handleTicketDetailsPopupShow();
  };

  const [NoDataConst, setNoDataConst] = React.useState("");
  const NoData = (
    // <p style={{marginTop: "170px"}}>You do not have any {NoDataConst} tickets for now.</p>

    <table className="table empty-table">
      <thead>
        <tr>
          <th>
            Ticket No <i className="fa fa-fw fa-sort-down"></i>
          </th>
          <th>
            Subject <i className="fa fa-fw fa-sort"></i>
          </th>
          {!isAnyCustomer && (
            <th>
              Reported By <i className="fa fa-fw fa-sort"></i>
            </th>
          )}
          <th>
            Submitted By <i className="fa fa-fw fa-sort"></i>
          </th>
          <th>
            Status <i className="fa fa-fw fa-sort"></i>
          </th>
          <th>
            Severtiy <i className="fa fa-fw fa-sort"></i>
          </th>
          <th>
            Created On <i className="fa fa-fw fa-sort"></i>
          </th>
          <th>Aging (Day)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={7}>
            You do not have any {NoDataConst} tickets for now.
          </td>
        </tr>
      </tbody>
    </table>
  );

  const getNumberOfPages = (rowCount: number, rowsPerPage: number) => Math.ceil(rowCount / rowsPerPage);

  // TablePaginationActions - Table Pagination actions
  function TablePaginationActions({ count, page, rowsPerPage, onChangePage }: any) {

    const handleBackButtonClick = () => {
      onChangePage(page);
    };

    const handleNextButtonClick = () => {
      onChangePage(page + 2);
    };

    const useStyles = makeStyles(theme => ({
      pageButtonPre: {
        marginLeft: "40px",
      },
      pageButtonNext: {
        marginRight: "40px",
      }
    }));
    const classes = useStyles();

    return (
      <>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
          className={classes.pageButtonPre}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= getNumberOfPages(count, rowsPerPage) - 1}
          aria-label="next page"
          className={classes.pageButtonNext}
        >
          <KeyboardArrowRight />
        </IconButton>
      </>
    );
  }

  const CustomMaterialPagination = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage,
    currentPage,
  }: any) => (
    <TablePagination
      rowsPerPageOptions={[10, 25, 50, { label: 'All', value: tempTickets.length }]}
      component="nav"
      count={rowCount}
      rowsPerPage={rowsPerPage}
      page={currentPage - 1}
      onChangePage={onChangePage}
      onChangeRowsPerPage={(event: any) => {
        let ticketLen = event.target.value
        onChangeRowsPerPage(parseInt(ticketLen));
      }}
      ActionsComponent={TablePaginationActions}
    />
  );

  CustomMaterialPagination.propTypes = {
    rowsPerPage: PropTypes.number.isRequired, // calculated rows per page state from DataTable
    rowCount: PropTypes.number.isRequired, // calculated row count from DataTable
    onChangePage: PropTypes.func.isRequired, // you want to "callback" the updated page number to DataTable so it can update its state
    onChangeRowsPerPage: PropTypes.func.isRequired, // you want to "callback" the updated rows per (newRowsPerPage, currentPage) to DataTable so it can update its state
    currentPage: PropTypes.number.isRequired, // the current page state from DataTable
  };

  /** Create Popup */
  const [show, setShow] = useState(false);
  const handleCloseWithStatus = () => {
    // setTicketCreationStatus(true);
    /** Update the list */
    updateCaseList();
    setShow(false);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Call back fucntion to update ticket list when new ticket is created
  const handleNewTicketCallBack = (status?: boolean, message?: string) => {
    console.log(status);
    // setTicketCreationStatus(false);
    if (typeof status !== "undefined") {
      setOutput(true);
      setErrStatus(status);
      if (status) {
        if (typeof message !== "undefined") {
          setErrorMesssage(message);
          setTimeout(() => {
            listSubject.updateList();
            updateTicketList();
          }, 500);
        } else {
          setErrorMesssage("The ticket has been added successfully.");
          setTimeout(() => {
            listSubject.updateList();
            updateTicketList();
          }, 500);
        }
      } else {
        if (typeof message !== "undefined") {
          setErrorMesssage(message);
        } else {
          setErrorMesssage("There was a problem creating the ticket.");
        }
      }
    } else {
      console.log("No status defined");
    }
  };

  const updateTicketList = () => {
    setOutput(false);
    setErrorMesssage("");
    setErrStatus(false);

    /** Update the list */
    updateCaseList();
  };

  // Update ticket list when new ticket is added.
  const updateCaseList = async () => {
    try {
      // Check if IDB is available
      // const isAvailabeIDB = await checkIfIDBIsAvailable();
      // console.log("checkIfIDBIsAvailable : " + isAvailabeIDB);

      setIsLoadingParent(true);
      // console.log("Previous Ticket Count: " + tickets.length);

      // Get all tickets from Salsforce
      const allTickets = await getAllTickets();
      // let allTicketsIDB: Array<Ticket> = [];
      // let ticketCountIDB = 0;

      // if (isAvailabeIDB) {
      //   // Get current ticket count from IDB
      //   allTicketsIDB = await getAllTicketsIDB();
      //   ticketCountIDB = allTicketsIDB.length;
      // }

      // Get ticket count form Salesforce
      // let ticketCountSF = 0;
      // ticketCountSF = allTickets.length;
      // console.log("IDB Ticket Count: " + ticketCountIDB);
      // console.log("SF Ticket Count: " + ticketCountSF);

      setTickets(allTickets);
      setTempTickets(allTickets);
      setIsLoadingParent(false);
      listSubject.updateList();

      // if (tickets.length !== 0 && tickets.length !== undefined) {
      //   if (tickets.length !== ticketCountSF) {
      let myElement: any = document.getElementById("row-0");
      myElement.classList.add("highlight-new-row");
      //   }
      // }
    } catch (error) {
      console.log(error);
      setIsLoadingParent(false);
    }
  };
  const [tickets, setTickets] = useState<Ticket[]>(props.initialTickets || []);
  const [tempTickets, setTempTickets] = useState<Ticket[]>(
    props.initialTickets || []
  );

  const __getAllTicketsFromSalesforce = (
    partnerName: string = "",
    customerName: string = "",
    isAnyCustomer: boolean = false
  ): Promise<Ticket[]> => {
    return new Promise((resolve, reject) => {
      salesforceApi
        .listTickets(partnerName, customerName, isAnyCustomer)
        .then(res => {
          let tickets: Ticket[] = [];
          let responseData = res.data;
          /** Check if array empty */
          if (responseData.length > 0) {
            /** If array is not empty then check if it has case number in it */
            if (responseData[0].hasOwnProperty("CaseNumber")) {
              tickets = res.data.map((ticket: Ticket) => {
                const newticket: Ticket = ticket;

                // var createdDate = moment.tz(newticket.TimeCreated, "America/Los_Angeles");
                // var currentDate = moment.tz(dt, "America/Los_Angeles");
                // console.log("-----------------------")
                // console.log(newticket.CaseNumber)
                // console.log("createdDate LA: " + createdDate.format());
                // console.log("currentDate LA: " + currentDate.format());
                // var Difference_In_Time = currentDate.toDate().getTime() - createdDate.toDate().getTime();
                // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                // console.log("Difference_In_Days LA: " + Difference_In_Days);
                // console.log("Difference_In_Days LA: " + Math.ceil(Difference_In_Days));
                // console.log("createdDate local time : " + createdDateIN.format());
                // console.log("currentDate local time : " + currentDateIN.format());
                // var Difference_In_Time_IN = currentDateIN.toDate().getTime() - createdDateIN.toDate().getTime();
                // var Difference_In_Days_IN = Difference_In_Time_IN / (1000 * 3600 * 24);
                // console.log("Difference_In_Time_IN : " + Difference_In_Time_IN);
                // console.log("Difference_In_Days : " + Difference_In_Days_IN);
                // newticket.Aging = Math.round(Difference_In_Days);
                // newticket.Aging = String(Math.ceil(Difference_In_Days_IN)) + " : " + result;
                // newticket.TimeCreated = moment.utc(newticket.TimeCreated).utcOffset(moment().utcOffset()).format("L LT");

                // var dt = new Date();
                var dateFormat = 'YYYY-MM-DD hh:mm A';
                var createdDateUtc = moment.utc(newticket.TimeCreated);
                var createdDateIN = createdDateUtc.local();
                var currentDateIN = moment.tz(new Date(), moment.tz.guess());
                var localDate = createdDateUtc.local();

                newticket.Aging = String(moment(currentDateIN, dateFormat).diff(moment(createdDateIN, dateFormat), 'days'));
                newticket.TimeCreated = localDate.format(dateFormat);
                newticket.TimeCreatedDate = moment(newticket.TimeCreated).toDate();

                return newticket;
              });
            }
          }
          resolve(tickets);
        })
        .catch(err => reject(err));
    });
  };

  const getAllTickets = async (): Promise<Ticket[]> => {
    try {
      if (common.isAnyCustomer(partnerDetails.user_type)) {
        const allTickets = await __getAllTicketsFromSalesforce(
          partnerDetails.company_name,
          "",
          true
        );
        return Promise.resolve(allTickets);
      } else {
        const allTickets = await __getAllTicketsFromSalesforce(
          partnerDetails.company_name,
          ""
        );
        return Promise.resolve(allTickets);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  // const getAllTicketsIDB = async () => {
  //   try {
  //     let tickets = await idbHelper.__retriveTicketsIDB();

  //     return Promise.resolve(tickets);
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // };

  const setIsLoadingParent = (status: boolean) => {
    setIsLoading(status);
    if (typeof props.updateLoadingStatus !== "undefined") {
      props.updateLoadingStatus(status);
    }
  };

  const filterResults = (e: any) => {
    const state = e.value;
    if (state === "New") {
      isFilter.current = "New";
      let newTickets = tempTickets.filter(ticket => ticket.State === "New");
      // console.log(newTickets.length);
      if (newTickets.length === 0) {
        setNoDataConst("New");
      }
      isFilterRow.current = String(newTickets.length)
      setTickets(newTickets);
    } else if (state === "In Progress") {
      isFilter.current = "In Progress";
      let newTickets = tempTickets.filter(
        ticket => ticket.State === "In Progress"
      );
      // console.log(typeof newTickets.length);
      if (newTickets.length == 0) {
        setNoDataConst("In Progress");
      }
      isFilterRow.current = String(newTickets.length)
      setTickets(newTickets);
    } else if (state === "Resolved") {
      isFilter.current = "Resolved";
      let newTickets = tempTickets.filter(
        ticket => ticket.State === "Resolved"
      );
      // console.log(newTickets.length);
      isFilterRow.current = String(newTickets.length)
      if (newTickets.length === 0) {
        setNoDataConst("Resolved");
      }
      setTickets(newTickets);
    } else {
      isFilter.current = "All";
      if (tempTickets.length === 0) {
        setNoDataConst("");
      }
      isFilterRow.current = String(tempTickets.length)
      setTickets(tempTickets);
    }
  };

  // async function ticketWorkerMessage(currentWorker: MessageEvent) {
  //   // console.log("---Update List from ticketWorkerMessage---");
  //   // console.log(currentWorker.data)

  //   // Check if IDB is available
  //   const isAvailabeIDB = await checkIfIDBIsAvailable();
  //   // console.log("checkIfIDBIsAvailable : " + isAvailabeIDB);

  //   const getDataFromDB = async () => {
  //     // console.log("---Update List DONE---")
  //     try {
  //       let allTickets: Array<Ticket> = [];

  //       if (isAvailabeIDB) {
  //         allTickets = await getAllTicketsIDB();
  //         // console.log(`allTickets IDB: `, allTickets);

  //         // If connection is insecure IDB won't work
  //         if (allTickets.length === 0) {
  //           allTickets = await getAllTickets();
  //         }
  //       } else {
  //         allTickets = await getAllTickets();
  //         // console.log(`allTickets backend: `, allTickets);
  //       }

  //       // setTempTickets(allTickets);

  //       if (isMounted.current) {
  //         if (isFilter.current === "New"){
  //           let newTickets = allTickets.filter(ticket => ticket.State === "New");
  //           if (newTickets.length === 0) {
  //             setNoDataConst("New");
  //           }
  //           isFilterRow.current = String(newTickets.length)
  //           setTickets(newTickets);
  //           setTempTickets(allTickets);

  //         } else if (isFilter.current === "In Progress") {
  //           let newTickets = allTickets.filter(ticket => ticket.State === "In Progress");
  //           // console.log(typeof newTickets.length);
  //           if (newTickets.length == 0) {
  //             setNoDataConst("In Progress");
  //           }
  //           isFilterRow.current = String(newTickets.length)
  //           setTickets(newTickets);
  //           setTempTickets(allTickets);
  //         }else if (isFilter.current === "Resolved") {
  //           let newTickets = allTickets.filter(ticket => ticket.State === "Resolved");
  //           if (newTickets.length === 0) {
  //             setNoDataConst("Resolved");
  //           }
  //           isFilterRow.current = String(newTickets.length)
  //           setTickets(newTickets);
  //           setTempTickets(allTickets);
  //         } else if (isFilter.current === "All") {
  //           isFilterRow.current = String(allTickets.length)
  //           setTickets(allTickets);
  //           setTempTickets(allTickets);
  //         }
  //         // setTickets(allTickets);
  //         // setTempTickets(allTickets);
  //       }
  //       // allTickets = await getAllTicketsIDB();
  //       // if (allTickets.length === 0) {
  //       //     // console.log("%cNo data in IDB", "font-weight: 500;color: orange;")
  //       //     allTickets = await getAllTickets();
  //       // } else {
  //       //     setTickets(allTickets);
  //       //     setTempTickets(allTickets);
  //       // }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getDataFromDB();
  // }

  // function onError(e: ErrorEvent) {
  //   // console.log('ERROR: Line ', e.lineno, ' in ', e.filename, ': ', e.message);
  //   // console.log("ticketWorker Error List");
  //   // console.log(e);
  // }

  let isMounted = useRef(false);
  let isFilter = useRef("");
  let isFilterRow = useRef("");

  React.useEffect(() => {
    isMounted.current = true;
    isFilter.current = "All";
    isFilterRow.current = "10";

    const fetchData = async () => {
      try {
        // Set loading state if initialTickets is empty
        if (!props.initialTickets) {
          setIsLoadingParent(true);
        }
        // const allTickets = await getAllTickets();
        let allTickets: Array<Ticket> = [];

        // // Call functon to check if IDB is available
        // const isAvailabeIDB = await checkIfIDBIsAvailable();
        // // console.log("checkIfIDBIsAvailable : " + isAvailabeIDB);

        // // If avaialble get from IDB or call Salesfoece
        // if (isAvailabeIDB) {
        //   // Get from IDB
        //   // console.log("IDB is available getting tickets from IDB");
        //   if (checkWorkerSupport()) {
        //     allTickets = await getAllTicketsIDB();
        //     if (allTickets.length === 0) {
        //       // console.log("No data in IDB");
        //       allTickets = await getAllTickets();
        //     }
        //   }
        // } else {
        // Get from Salesforce
        // console.log("IDB not available getting tickets from Salesforce");
        allTickets = await getAllTickets();
        // }

        setTempTickets(allTickets);

        // if (checkWorkerSupport()) {
        //     allTickets = await getAllTicketsIDB();
        //     if (allTickets.length === 0) {
        //         console.log("%cNo data in IDB", "font-weight: 500;color: orange;")
        //         allTickets = await getAllTickets();
        //     }
        // } else {

        // }

        // console.log(tickets);
        if (isMounted.current) {

          if (isFilter.current === "New") {
            let newTickets = allTickets.filter(ticket => ticket.State === "New");
            if (newTickets.length === 0) {
              setNoDataConst("New");
            }
            isFilterRow.current = String(newTickets.length)
            setTickets(newTickets);
            setTempTickets(allTickets);
          } else if (isFilter.current === "In Progress") {
            let newTickets = allTickets.filter(ticket => ticket.State === "In Progress");
            if (newTickets.length == 0) {
              setNoDataConst("In Progress");
            }
            isFilterRow.current = String(newTickets.length)
            setTickets(newTickets);
            setTempTickets(allTickets);
          } else if (isFilter.current === "Resolved") {
            let newTickets = allTickets.filter(ticket => ticket.State === "Resolved");
            if (newTickets.length === 0) {
              setNoDataConst("Resolved");
            }
            isFilterRow.current = String(newTickets.length)
            setTickets(newTickets);
            setTempTickets(allTickets);
          } else if (isFilter.current === "All") {
            isFilterRow.current = String(allTickets.length)
            if (allTickets.length === 0) {
              setNoDataConst("");
            }
            setTickets(allTickets);
            setTempTickets(allTickets);
          }

          // setTickets(allTickets);
          // setTempTickets(allTickets);
          setIsLoadingParent(false);

        }
        // Innitialize web worker
        // if (isAvailabeIDB) {
        //   if (checkWorkerSupport()) {
        //     const [ticketWorker] = WebWorker([TicketWorker]);

        //     const token: string = localStorage.getItem("token") || "";
        //     const company_name: string =
        //       localStorage.getItem("company_name") || "";
        //     const customer: string = localStorage.getItem("customer") || "";
        //     const user_type: string = localStorage.getItem("user_type") || "";
        //     let direct_customer_str: string =
        //       localStorage.getItem("direct_customer") || "false";
        //     let direct_customer: boolean = JSON.parse(direct_customer_str);

        //     ticketWorker.addEventListener("message", ticketWorkerMessage);
        //     ticketWorker.addEventListener("error", onError);
        //     ticketWorker.postMessage({
        //       type: "START",
        //       partner: company_name,
        //       customer: customer,
        //       token: token,
        //       user_type: user_type,
        //       direct_customer: direct_customer
        //     });

        //     return () => {
        //       ticketWorker.postMessage("STOP");
        //     };
        //   }
        // }
      } catch (error) {
        console.log(error);
        setIsLoadingParent(false);
      }
    };

    const fetchSupportURl = () => {
      api.getDomain().then(res => {
        setSupportUrl(res?.platform_support_url || "")
      })
    }

    fetchSupportURl();

    fetchData();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const { toggleShowSmartStartKit, fromLocation } = useSmartStartKit();
  const processSmartStartKit = () => {
    if (fromLocation.current === "support-card") {
      fromLocation.current = "";
      // it's a requirement to toggle Smart Start Kit after 2 sec, not a hack
      setTimeout(() => {
        toggleShowSmartStartKit?.();
      }, 2000);
    }
  };

  const showCreateNewTicketPopup = () => {
    showModal({
      header: false,

      body: closeModal => (
        <div>
          <SupportCreateNewTicket
            type={formType.modalform}
            closePopup={handleCloseWithStatus}
            output={handleNewTicketCallBack}
            hideModal={closeModal}
          />
        </div>
      ),
      baseProps: {
        dialogClassName: "lyve-modal new-ticket-popup",
        onExit: processSmartStartKit
      }
    });
  };

  React.useEffect(() => {
    if (fromLocation.current === "support-card") {
      showCreateNewTicketPopup();
    }
  }, [fromLocation.current]);

  const filters = [
    {
      value: "All",
      label: "All"
    },
    {
      value: "New",
      label: "New"
    },
    {
      value: "In Progress",
      label: "In Progress"
    },
    {
      value: "Resolved",
      label: "Resolved"
    }
  ];

  return (
    <div>
      {isLoading ? (
        <div className="row align-items-center" style={{ height: 600 }}>
          <div className="col text-center">
            <div
              className="spinner-border"
              style={{ color: "#6ebe49" }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <>
          {
            showWarningMessage && supportUrl ?
              <div className="information-banner information-banner-warning">
                <p className="support-message"> Support Requests will now be submitted through the Lyve Support Center.
                  Please visit the following link: {" "}
                  <a href={supportUrl} className="message-url" target="_blank">
                    Lyve Support Center
                  </a>.
                  All requests generated here before September 30, 2023 will remain here until December 20th 2023. This view will be removed December 20th 2023 along with the existing tickets.
                </p>
                <div className="close-icon-wrapper" onClick={() => setShowWarningMessage(false)}>
                  <IconClose className="close-icon" />
                </div>
              </div >
              :
              null
          }
          <div className="support-ticket-list-root mb-4">
            <div className="filter-div ">
              <span className="filter-span">Filter </span>
              <LyveSelect
                id="filter"
                name="filter"
                className="ticket-filter"
                defaultValue={filters[0]}
                options={filters}
                onChange={filterResults}
              />

              {
                !supportUrl ?
                  <div className="ml-auto">
                    <Can perform={SUPPORT_EDIT}>
                      <DefaultButton
                        name="create_btn"
                        onClick={() => showCreateNewTicketPopup()}
                        disabled={isLoading}
                      >
                        <AddIcon />
                        Create New Ticket
                      </DefaultButton>
                    </Can>
                  </div>
                  : null
              }
            </div>

            <Card
              className="datatable-container support-card"
              style={{ margin: "32px 0" }}
            >
              <Row>
                <Col>
                  {output && (
                    <DefaultAlert
                      variant={errStatus ? "success" : "danger"}
                      onClose={() => setOutput(false)}
                      dismissible
                    >
                      {errorMesssage}
                    </DefaultAlert>
                  )}
                </Col>
              </Row>
              <LyveTable
                columns={columns}
                className="support-table"
                data={tickets}
                defaultSortField="CaseNumber"
                defaultSortAsc={false}
                selectableRows={selectableRows}
                pagination={true}
                noHeader={noHeader}
                highlightOnHover={highlight}
                noDataComponent={NoData}
                paginationIconFirstPage={false}
                paginationIconLastPage={false}
                responsive={true}
                paginationComponent={CustomMaterialPagination}
              />
            </Card>
          </div>
        </>
      )}
    </div>
  );
};

export default SupportTicketList;
