import React from "react";
import LyveHeading from "../../../../components/LyveHeading";
import SupportDashboard from "./components/SupportDashboard";

import "./styles.scss";

const Support: React.FC = () => {
  return (
    <div className="partner-support-wrapper">
      <LyveHeading title="Support" subTitle="" titleMarginRightRemoved={true} />

      <SupportDashboard />
    </div>
  );
};

export default Support;
