import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { DefaultButton } from "../../../LyveButton";
import api from "../../../../services/api";

interface Props {
  email: string;
  id: string;
  closeModal: () => void;
  onSuccess?: () => void;
  onError?: () => void;
}

const ResetMFAPrompt: React.FC<Props> = ({
  email,
  id,
  closeModal,
  onSuccess
}) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleResetMFA = () => {
    setProcessing(true);
    api
      .resetMFA(id)
      .then(res => {
        setProcessing(false);
        if (typeof onSuccess !== "undefined") {
          onSuccess();
        }
        closeModal();
      })
      .catch(err => {
        setError("Failed to reset MFA.");
        setProcessing(false);
        closeModal();
      });
  };

  return (
    <div>
      <div className="pb-4 font-weight-bold">
        Are you sure you want to reset MFA for
        <div>{email}</div>
      </div>

      {!processing && error && <div className="text-danger">{error}</div>}
      <div>
        <DefaultButton
          className="mr-2"
          variant="primary"
          disabled={processing}
          onClick={() => {
            handleResetMFA();
          }}
        >
          {processing ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-1"
            />
          ) : (
            "Yes"
          )}
        </DefaultButton>
        <DefaultButton
          disabled={processing}
          onClick={closeModal}
          variant="outline-secondary"
        >
          No
        </DefaultButton>
      </div>
    </div>
  );
};

export default ResetMFAPrompt;
