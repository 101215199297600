import React from "react";
import { QuestionCircleFill } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { MetricCard } from "../../../../../components/Card";
import { AppState, useAppSelector } from "../../../../../store";
import useFixedCustomerDetails from "../hooks/useFixedCustomerDetails";
import { selectLyveCloud } from "../../../../../state/lyveCloud";
import ReactTooltip from "react-tooltip";

interface MonthCostCardProps {}

const MonthCostCard: React.FC<MonthCostCardProps> = () => {
  const lyveCloud: any = useAppSelector(selectLyveCloud);
  const { evalData, whoAmI } = useSelector((state: AppState) => ({
    evalData: state.eval.evalData,
    whoAmI: state.userInfo.whoAmI
  }));
  const estimatedCost = lyveCloud.data.estimatedCost;
  const isShowEstimatedCostCard = lyveCloud.data.estimate_cost_card;
  const currentPlan = lyveCloud.data.currentPlan;

  const {
    isLoading: monthCostLoading,
    error: monthCostError
  } = useFixedCustomerDetails(evalData?.customer_type, whoAmI?.role ?? "");

  const renderLabelWithTooltip = (
    label: string,
    tooltipId: string,
    description: string
  ) => {
    return (
      <>
        <span className="label">{label}</span>
        <QuestionCircleFill
          size="16"
          data-for={tooltipId}
          data-tip={description}
        />
        <ReactTooltip
          id={tooltipId}
          effect="solid"
          multiline
          place="left"
          className="estimate-cost-card-tooltip"
        />
      </>
    );
  };

  const renderEstimatedCost = () => {
    return <span className="estimated-cost-section">{estimatedCost}</span>;
  };

  return (
    <>
      {evalData?.customer_type === "FIXED_PRICE" &&
        !monthCostError &&
        estimatedCost !== null &&
        estimatedCost >= 0 &&
        isShowEstimatedCostCard && (
          <MetricCard
            label={renderLabelWithTooltip(
              "Estimated Cost (USD)",
              "cost",
              `The estimated cost is maximum of estimated usage(based <br/> on current month to date usage) or ${currentPlan?.friendly_name} ${currentPlan?.uom} at ${currentPlan?.costUnit}${currentPlan?.unit_price}/${currentPlan?.uom}`
            )}
            value={renderEstimatedCost()}
            loading={monthCostLoading}
            className="estimated-cost-card"
          />
        )}
    </>
  );
};

export default MonthCostCard;
