import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { AppState } from "../../store";
import { NotificationState } from "./types";
import { getNotification } from "./selectors";
import * as notificationActions from "./actions";
import { ExclamationTriangleFill, X as CloseIcon } from "react-bootstrap-icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1000,
      width: "80%"
    },
    container: {
      fontSize: 16,
      fontWeight: 500,
      backgroundColor: "#FFF6DB",
      color: "#8F6B00",
      minWidth: 0,
      minHeight: 40,
      borderRadius: 48,
      border: `1px solid #F0E4C0`,
      padding: "10px 24px",
      boxShadow: "none",
      "& .MuiSnackbarContent-message": {
        padding: 0
      }
    },
    loginContainer: {
      position: "relative",
      top: theme.spacing(-3)
    },
    icon: {
      color: "#8F6B00",
      marginRight: 10
    },
    closeIcon: {
      color: "#1A1A1A",
      marginLeft: 24,
      cursor: "pointer"
    }
  })
);

interface WarningNotificationProps {
  notification: NotificationState;
  clearNotification: () => void;
}

const WarningNotification: React.FC<WarningNotificationProps> = ({
  notification,
  clearNotification
}) => {
  const classes = useStyles();

  if (
    notification.show &&
    !notification.error &&
    notification.warning &&
    notification.message !== ""
  ) {
    return (
      <Snackbar
        open={true}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        className={clsx(classes.root)}
      >
        <SnackbarContent
          className={clsx(classes.container)}
          message={
            <Grid container alignItems="center" justify="center">
              <ExclamationTriangleFill size={16} className={classes.icon} />
              {notification.message}
              <CloseIcon
                size="20"
                className={classes.closeIcon}
                onClick={() => clearNotification()}
              />
            </Grid>
          }
        />
      </Snackbar>
    );
  } else {
    return <noscript />;
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    notification: getNotification(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    clearNotification: () => dispatch(notificationActions.clearNotification())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WarningNotification);
