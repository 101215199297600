import React from "react";
import cx from "classnames";
import moment from "moment-timezone";

export interface MonthProps {
  month: string;
  year: number;
  index: number;
  shortMonth?: boolean;
  disabled?: boolean;
  startIndex?: number | null;
  startIndexYear?: number | null;
  endIndex?: number | null;
  endIndexYear?: number | null;
  selected?: boolean;
  selectsRange?: boolean;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent) => void;
}

const getAriaLabel = (month: string, disabled: boolean) => {
  const prefix = disabled ? "Not available" : "Choose";

  return `${prefix} ${month}`;
};

const isBetweenTheRange = ({
  startIndex,
  endIndex,
  startIndexYear,
  endIndexYear,
  year,
  index
}: {
  startIndex: number | null | undefined;
  endIndex: number | null | undefined;
  startIndexYear: number | null | undefined;
  endIndexYear: number | null | undefined;
  year: number;
  index: number;
}) => {
  return (
    startIndex !== null &&
    startIndex !== undefined &&
    endIndex !== null &&
    endIndex !== undefined &&
    startIndexYear !== null &&
    startIndexYear !== undefined &&
    endIndexYear !== null &&
    endIndexYear !== undefined &&
    moment({ year, month: index }).toDate() >
      moment({ year: startIndexYear, month: startIndex }).toDate() &&
    moment({ year, month: index }).toDate() <
      moment({ year: endIndexYear, month: endIndex }).toDate()
  );
};

const Month = ({
  month,
  year,
  index,
  disabled,
  startIndex,
  startIndexYear,
  endIndex,
  endIndexYear,
  selectsRange,
  shortMonth,
  selected,
  ...rest
}: MonthProps) => {
  return (
    <div
      className={cx("lyve-month-picker__month", {
        "lyve-month-picker__month-range-start":
          year === startIndexYear && startIndex === index,
        "lyve-month-picker__month-range-end":
          year === endIndexYear && endIndex === index,
        "lyve-month-picker__month-range-in-between": isBetweenTheRange({
          startIndex,
          endIndex,
          startIndexYear,
          endIndexYear,
          year,
          index
        }),
        "lyve-month-picker__month-single": !selectsRange && !!selected,
        "lyve-month-picker__month-disabled": disabled
      })}
      aria-label={getAriaLabel(month, disabled || false)}
      {...rest}
    >
      {shortMonth ? month.slice(0, 3) : month}
    </div>
  );
};

export default Month;
