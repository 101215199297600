import {
  SAVE_MASTER_ACCOUNT_INFO,
  FETCHING_MASTER_ACCOUNT_INFO,
  ERROR_MASTER_ACCOUNT_INFO
} from "./actions";
import { SubaccountsActionTypes, SubaccountsStore } from "./types";

const initialState: SubaccountsStore = {
  masterAccount: null,
  loading: false,
  error: null
};

export function reducer(
  state: SubaccountsStore = initialState,
  action: SubaccountsActionTypes
): SubaccountsStore {
  switch (action.type) {
    case SAVE_MASTER_ACCOUNT_INFO:
      return {
        ...state,
        masterAccount: action.payload,
        loading: false,
        error: null
      };

    case FETCHING_MASTER_ACCOUNT_INFO:
      return { ...state, loading: true };

    case ERROR_MASTER_ACCOUNT_INFO:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
}
