import React, { useState } from "react";
import "./styles.scss";
import CustomToggle from "../../../../../../../components/CustomToggleButton";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { ModalContainer } from "../../../../../../../components/Modal";
import ComplianceDetails from "../../ComplianceDetails";
import Card from "react-bootstrap/Card";
import Can from "../../../../../../../components/Can";
import { BUCKETS_EDIT } from "../../../../../../../authRules";
import regionApis from "../../../../../../../services/RegionApis";
import LyveLink from "../../../../../../../components/LyveHeadingLink/LyveLink";
import {
  getLink,
  linkNames
} from "../../../../../../../components/LyveHeadingLink/LinkUtil";

interface Props {
  bucketName: string;
  initialCompliance: boolean;
  initialRetentionDuration: number;
  initialRetentionUnit: "Days" | "Years";
  toggleCompliance: (newCompliance: boolean) => void;
  setInitialUnit: any;
  setInitialDuration: any;
  viewBucketRegionURL: string;
}

const Index: React.FC<Props> = ({
  bucketName,
  initialCompliance,
  initialRetentionDuration,
  initialRetentionUnit,
  toggleCompliance,
  setInitialUnit,
  setInitialDuration,
  viewBucketRegionURL
}) => {
  const { showModal } = ModalContainer.useContainer();
  const [compliance, setCompliance] = useState<boolean>(initialCompliance);
  const [toggleLoader, setToggleLoader] = useState<boolean>(false);
  const [editingCompliance, setEditingCompliance] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [retentionDuration, setRetentionDuration] = useState<number>(
    initialRetentionDuration
  );
  const [retentionUnit, setRetentionUnit] = useState<"Days" | "Years">(
    initialRetentionUnit
  );
  const [turningComplianceOn, setTurningComplianceOn] = useState<boolean>(
    false
  );

  const handleUpdateCompliance = (
    retentionDuration: number,
    retentionUnit: "Days" | "Years"
  ) => {
    setProcessing(true);
    regionApis
      .updateComplianceDetails(
        viewBucketRegionURL,
        bucketName,
        retentionDuration,
        retentionUnit,
        false
      )
      .then((resp: any) => {
        if (resp.status) {
          setCompliance(true);
          setRetentionDuration(retentionDuration);
          setRetentionUnit(retentionUnit);
          setInitialUnit(retentionUnit);
          setInitialDuration(retentionDuration);
          setProcessing(false);
          setEditingCompliance(false);
          toggleCompliance(true);
          setTurningComplianceOn(false);
        }
      })
      .catch(err => {
        setProcessing(false);
        cancelEditingCompliance();
      });
  };

  const disableCompliance = () => {
    setToggleLoader(true);
    regionApis
      .disableCompliance(viewBucketRegionURL, bucketName)
      .then(res => {
        if (res.disabled) {
          setCompliance(false);
          toggleCompliance(false);
          setToggleLoader(false);
        } else if (res.error) {
          const errorList = res.error.split(",");
          showComplianceDisableError(errorList);
          setToggleLoader(false);
        }
      })
      .catch(err => {
        setToggleLoader(false);
      });
  };

  const updateCompliance = () => {
    if (compliance) {
      disableCompliance();
    } else {
      setToggleLoader(true);
      setTurningComplianceOn(true);
      regionApis
        .getComplianceDetails(viewBucketRegionURL, bucketName)
        .then(details => {
          const currentRententionDuration = details.retentionDuration || 0;
          const currentRententionUnit = details.retentionUnit;
          setCompliance(true);
          setEditingCompliance(true);

          setInitialUnit(currentRententionUnit);
          setInitialDuration(currentRententionDuration);
          setToggleLoader(false);
          setRetentionDuration(currentRententionDuration);
          setRetentionUnit(currentRententionUnit);
        })
        .catch(err => {
          setCompliance(false);
          setEditingCompliance(false);
          setToggleLoader(false);
        });
    }
  };

  const handleRetentionValueChange = (e: any) => {
    setRetentionDuration(e.target.value);
  };
  const handleRetentionPeriodChange = (e: any) => {
    setRetentionUnit(e.target.value);
  };

  const cancelEditingCompliance = () => {
    setEditingCompliance(false);
    initialRetentionDuration
      ? setRetentionDuration(initialRetentionDuration)
      : setRetentionDuration(30);
    initialRetentionUnit
      ? setRetentionUnit(initialRetentionUnit)
      : setRetentionUnit("Days");
    initialCompliance ? setCompliance(initialCompliance) : setCompliance(true);
    turningComplianceOn && setCompliance(false);
  };

  const showComplianceDisableError = (errorList: string[]) => {
    showModal({
      header: true,
      title: "Disabling compliance",
      body: closeModal => (
        <div className="p-2 bucket-delete-error">
          <div className="delete-header">
            Compliance mode for this bucket cannot be disabled:{" "}
          </div>
          <ul className="delete-ul">
            {errorList.map(err => (
              <li>{err}.</li>
            ))}
          </ul>
        </div>
      ),
      //   footer: closeModal => <div>yolo</div>,
      baseProps: { size: "sm" }
    });
  };

  const isValid = (value: number, unit: "Days" | "Years") => {
    const pattern = /^\d+$/;
    const isNumeric = pattern.test(value.toString());
    if (!isNumeric || value <= 0) return false; // Only positive integers

    // Max value 100 years
    if (unit === "Days" && value > 36500) return false;
    if (unit === "Years" && value > 100) return false;

    return true;
  };

  return (
    <Card className="view-bucket-card">
      <Card.Body>
        <div className="d-flex">
          <div className=" d-flex flex-column">
            <div className="VB-card-title">Object Immutability</div>
            <div className="VB-card-description">
              Protects against deletion and corruption of objects for the
              specified duration. &nbsp;
              <LyveLink
                isMarginLeft={false}
                link={getLink(linkNames.Object_Immutability)}
              />
            </div>
          </div>
          <Can perform={BUCKETS_EDIT}>
            <div className=" ml-auto">
              <CustomToggle
                selected={compliance}
                toggleSelected={updateCompliance}
                processing={toggleLoader}
              />
            </div>
          </Can>
        </div>
        {compliance && (
          <div>
            <div className="d-flex pt-4">
              {" "}
              <Form.Label className="VB-card-details-a">
                Set Duration
              </Form.Label>
              {!editingCompliance && (
                <Can perform={BUCKETS_EDIT}>
                  <div className=" pl-3 ">
                    <i
                      className="fas fa-edit cursor-pointer"
                      onClick={() => setEditingCompliance(true)}
                    />
                  </div>
                </Can>
              )}
            </div>
            {!editingCompliance ? (
              <div>
                <div className="retention-period">
                  {retentionDuration} {retentionUnit}
                </div>
              </div>
            ) : (
              <div>
                <ComplianceDetails
                  retentionDuration={retentionDuration}
                  handleRetentionValueChange={handleRetentionValueChange}
                  retentionUnit={retentionUnit}
                  handleRetentionUnitChange={handleRetentionPeriodChange}
                />
                <div className="mt-2">
                  <Button
                    variant="primary"
                    className="mr-2"
                    disabled={!isValid(retentionDuration, retentionUnit)}
                    onClick={() => {
                      handleUpdateCompliance(retentionDuration, retentionUnit);
                    }}
                  >
                    {processing ? (
                      <div className="d-flex justify-content-center">
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="mr-1"
                        />
                      </div>
                    ) : (
                      "Save"
                    )}
                  </Button>
                  <Button
                    variant="outline-secondary"
                    onClick={cancelEditingCompliance}
                  >
                    cancel
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default Index;
