import React from "react";
import cx from "classnames";
import { Form, Spinner } from "react-bootstrap";
import Input from "../../../../../../components/Input";
import { DefaultButton } from "../../../../../../components/LyveButton";
import api from "../../../../../../services/RegionApis";
import { PermissionData } from "../../types";
import PermissionsTable from "./PermissionsTable";
import {
  CheckCircleFill,
  ExclamationTriangleFill,
  QuestionCircleFill,
  X as CloseIcon
} from "react-bootstrap-icons";
import "./styles.scss";
import { isElement, isEqual } from "lodash";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../../../store";
import { selectRegionsWithSG } from "../../../../../../state/regionswithsg";
import { couldStartTrivia } from "typescript";
import LyveLink from "../../../../../../components/LyveHeadingLink/LyveLink";
import {
  getLink,
  linkNames
} from "../../../../../../components/LyveHeadingLink/LinkUtil";
import commonAPI from "../../../../../../services/api";
import ReactTooltip from "react-tooltip";

interface Data {
  id: string;
  name: string;
  enabled: boolean;
}

interface CreatePromptProps {
  items?: PermissionData[];
  baseUrl: string;
  account?: any;
  readOnly?: boolean;
  isClone?: boolean;
  handleCheckExpirySetting?: () => void;
  handleModalClose: () => void;
  showAllAllowCheckbox: boolean;
  handleInterval?: (name: string, permissionIds: any, account: any) => void;
  handleClearInterval?: () => void;
  accountsList?: Data[];
  onSuccess?: (data: any) => void;
  onError?: (err: any) => void;
}

const CreatePrompt: React.FC<CreatePromptProps> = ({
  items = [],
  baseUrl,
  account,
  readOnly,
  isClone,
  showAllAllowCheckbox,
  handleInterval,
  handleModalClose,
  handleClearInterval,
  handleCheckExpirySetting,
  accountsList,
  onSuccess = () => null,
  onError = () => null
}) => {
  const initialValues = account || { name: "" };
  const [name, setName] = React.useState<string>(initialValues.name);
  const [permissions, setPermissions] = React.useState<PermissionData[]>([]);
  const [error, setError] = React.useState<string>("");
  const [isFormProcessing, setFormProcessing] = React.useState<boolean>(false);
  const [allBucketFullAccess, setAllBucketFullAccess] = React.useState<boolean>(
    false
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [startedTyping, setStartedTyping] = React.useState<boolean>(false);
  const [accountError, setAccountError] = React.useState<string>("");

  // Temporory name & permissions
  const tempName: string = initialValues.name;
  const [tempPermissions, setTempPermissions] = React.useState<
    PermissionData[]
  >([]);
  const [saExpirySetting, setExpirySetting] = React.useState<any>(null);
  const [saExpirySettingLoading, setExpirySettingLoading] = React.useState<
    boolean
  >(false);

  const { data: regionswithsg } = useAppSelector(selectRegionsWithSG);
  const nameValidation = (): boolean => {
    if (!name.trim() || !/^[a-zA-Z\d-_ ]+$/.test(name)) return false;
    else return true;
  };
  const nameLengthValidation = () => !!name && name.length <= 256;

  React.useEffect(() => {
    if (account && account.id) {
      setLoading(true);
      api
        .getServiceAccount(account.id, "")
        .then(res => {
          setAccountError("");
          setTempPermissions(res.permissions);
          setPermissions(res.permissions);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          setAccountError(err);
        });
    }
  }, [account]);

  React.useEffect(() => {
    if (!account || isClone) {
      setExpirySettingLoading(true);
      commonAPI
        .getServiceAccountExpiry()
        .then(res => {
          setExpirySetting(res);
          setExpirySettingLoading(false);
        })
        .catch(err => {
          setExpirySetting(null);
          setExpirySettingLoading(false);
        });
    }
  }, []);

  const formIsValid = () => {
    let error = "";
    let isValid = true;

    //checking the service account name already exist in db
    accountsList?.forEach((a: any) => {
      if (a.name === name && account?.name !== a.name) {
        //   if (accountsList?.name !== a.name && a.name === name) {
        error =
          "The service account name is already in use.  Please use a different name or edit the existing service account.";
        setError(error);
        isValid = false;
      }
    });

    if (!name.trim() || !/^[a-zA-Z\d-_ ]+$/.test(name)) {
      error =
        "The Service Account name format is invalid. Please use only alphanumeric characters, '-', '_' or space.";
      setError(error);
      isValid = false;
    }

    if (!allBucketFullAccess) {
      if (permissions.length < 1) {
        error =
          "Permission cannot be null. Please select permission(s) to create a service account.";
        setError(error);
        isValid = false;
      }
    }

    return isValid;
  };

  const createAccount = (permissionIds: any, sg_url: string) => {
    if (handleClearInterval) {
      handleClearInterval();
    }

    if (handleInterval) {
      handleInterval(name, permissionIds, null);
    }

    api
      .addServiceAccount(name, permissionIds, allBucketFullAccess, sg_url)
      .then(res => {
        setFormProcessing(false);
        setError("");
        onSuccess(res);
      })
      .catch(err => {
        if (regionswithsg.length !== 0) {
          let index = regionswithsg
            .map(function(e) {
              return e.url;
            })
            .indexOf(sg_url);
          if (index == regionswithsg.length - 1) {
            setFormProcessing(false);
            setError(err);
            onError(err);
          } else {
            createAccount(permissionIds, regionswithsg[index + 1].url);
          }
        } else {
          setFormProcessing(false);
          setError(err);
          onError(err);
        }
      });
  };

  const updateAccount = (permissionIds: any, sg_url: string) => {
    if (handleClearInterval) {
      handleClearInterval();
    }
    if (handleInterval) {
      handleInterval(name, permissionIds, account);
    }

    api
      .updateServiceAccount(
        account.id,
        name,
        permissionIds,
        account.enabled,
        allBucketFullAccess,
        sg_url
      )
      .then(res => {
        setFormProcessing(false);
        setError("");
        onSuccess(res);
      })
      .catch(err => {
        if (regionswithsg.length !== 0) {
          let index = regionswithsg
            .map(function(e) {
              return e.url;
            })
            .indexOf(sg_url);
          if (index == regionswithsg.length - 1) {
            setFormProcessing(false);
            setError(err);
            onError(err);
          } else {
            updateAccount(permissionIds, regionswithsg[index + 1].url);
          }
        } else {
          setFormProcessing(false);
          setError(err);
          onError(err);
        }
      });
  };

  const handleOnSave = async () => {
    if (!formIsValid()) {
      return;
    }
    setError("");
    setFormProcessing(true);
    const permission_ids: any = allBucketFullAccess
      ? []
      : permissions.map((resource: any) => resource.id);

    if (account && !isClone) {
      updateAccount(permission_ids, baseUrl);
    } else {
      commonAPI
        .getServiceAccountExpiry()
        .then(res => {
          if (
            saExpirySetting?.sa_config_feature?.value ===
              res?.sa_config_feature?.value &&
            saExpirySetting?.sa_config_feature?.uom ===
              res?.sa_config_feature?.uom
          ) {
            createAccount(permission_ids, baseUrl);
          } else {
            if (handleCheckExpirySetting) {
              handleCheckExpirySetting();
            }
          }
        })
        .catch(() => {
          if (saExpirySetting === null) {
            createAccount(permission_ids, baseUrl);
          } else {
            if (handleCheckExpirySetting) {
              handleCheckExpirySetting();
            }
          }
        });
    }
  };

  const handleSelectAllowAllBuckets = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAllBucketFullAccess(e.target.checked);
  };

  const isAccountUpdated = (): boolean => {
    let isChanged = true;
    if (tempPermissions.length === permissions.length) {
      const results = permissions.filter(permission1 => {
        return !tempPermissions.some(permission2 => {
          return permission2.name == permission1.name;
        });
      });

      if (results.length > 0) {
        isChanged = true;
      } else {
        isChanged = false;
      }
    } else {
      isChanged = true;
    }
    return isChanged;
  };

  const isButtonEnabled = (): boolean => {
    let isEnabled = true;
    if (
      loading ||
      isFormProcessing ||
      name.length === 0 ||
      !nameLengthValidation() ||
      !nameValidation() ||
      permissions.length === 0
    ) {
      isEnabled = false;
    } else {
      // MARK: Check if the edit mode
      if (account) {
        if (tempName !== name || isAccountUpdated() || isClone) {
          isEnabled = true;
        } else {
          isEnabled = false;
        }
      }
    }

    return isEnabled;
  };

  return (
    <div className="create-service-account-module">
      <button className="custom-close-icon" onClick={handleModalClose}>
        <CloseIcon size="24" color="var(--gray-9)" />
      </button>
      <h4 className="create-service-account-title">
        {readOnly
          ? "View Service Account"
          : !!account && !isClone
          ? "Edit Service Account"
          : "Create Service Account"}
        <LyveLink
          link={
            !!account && !isClone
              ? getLink(linkNames.Edit_Service_Account)
              : getLink(linkNames.Create_Service_Account)
          }
        />
      </h4>

      <Form
        id="service-account-form"
        onSubmit={(event: React.FormEvent) => {
          event.preventDefault();
          handleOnSave();
        }}
      >
        <div style={{ overflowY: "auto" }}>
          <div className="form-section">
            <Input
              controlId="service-account-name"
              label="Service Account Name"
              value={name}
              disabled={readOnly}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
              }
              //onFocus={() => setStartedTyping(true)}
            />
            <div className="validation-container">
              <div className="validation-item pb-1">
                <CheckCircleFill
                  className={cx("validation-icon", {
                    valid: nameValidation()
                  })}
                />
                <span>
                  Only alphanumeric names are allowed, including also '-', '_',
                  or space.
                </span>
              </div>
              <div className="validation-item">
                <CheckCircleFill
                  className={cx("validation-icon", {
                    valid: nameLengthValidation()
                  })}
                />
                <span>Maximum 256 characters</span>
              </div>
            </div>
          </div>
          {/* {showAllAllowCheckbox && (
						<div className="form-section all-access">
							<Input
								controlId="all-access"
								controlAs="checkbox"
								label="Allow create, delete and full access to all buckets in this account"
								defaultChecked={allBucketFullAccess}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									handleSelectAllowAllBuckets(e);
								}}
							/>
						</div>
					)} */}
          {!!account && !isClone ? (
            <div className="d-flex expires-wrapper">
              <div className="d-flex flex-column">
                <span className="sa-key">Access Key</span>
                <span className="sa-value">{account?.access_key}</span>
              </div>
              <div className="d-flex flex-column expires-col">
                <span className="sa-key">Expires In</span>
                <span className="sa-value">{account?.expires_in}</span>
              </div>
            </div>
          ) : (
            <>
              {!saExpirySettingLoading && (
                <div className="d-flex expires-wrapper">
                  <div className="d-flex flex-column">
                    <span className="sa-key d-flex">
                      Secret Key Expiration Duration
                      <QuestionCircleFill
                        size="16"
                        className="question-mark-icon"
                        data-for={"secret-key-expiration-duration"}
                        data-tip={
                          "To edit the secret key expiration duration, please go to Settings"
                        }
                      />
                      <ReactTooltip
                        id={"secret-key-expiration-duration"}
                        effect="solid"
                        multiline
                        place="right"
                        type="dark"
                        className="secret-key-expiration-duration-tooltip"
                      />
                    </span>
                    <span className="sa-value">
                      {saExpirySetting
                        ? `${saExpirySetting?.sa_config_feature?.value} ${saExpirySetting?.sa_config_feature?.uom}`
                        : "Never"}
                    </span>
                  </div>
                </div>
              )}
            </>
          )}

          {accountError === "" ? (
            <div className="form-section">
              <label className="form-input-label">
                {readOnly ? "Selected Permissions" : "Select Permissions"}
              </label>
              <PermissionsTable
                initialValue={permissions}
                items={items}
                setItems={items => setPermissions(items)}
                readOnly={readOnly}
                allBucketFullAccess={allBucketFullAccess}
                loading={loading}
              />
            </div>
          ) : (
            <p className="validation-error">{accountError}</p>
          )}
        </div>
        {error !== "" && <p className="validation-error">{error}</p>}
        {!readOnly && (
          <DefaultButton
            type="submit"
            form="service-account-form"
            disabled={!isButtonEnabled()}
            className="submit-button"
          >
            {isFormProcessing ? (
              <Spinner animation="border" role="status" className="spinner">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : !!account && !isClone ? (
              "Save"
            ) : (
              "Create"
            )}
          </DefaultButton>
        )}
      </Form>
    </div>
  );
};

export default CreatePrompt;
