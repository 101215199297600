import React from "react";
import { useLocation } from "react-router-dom";
import { DefaultErrorPage } from "../../../components/LyveErrorPage";

const UniformError: React.FC = () => {
  const location = useLocation();
  const [code, setCode] = React.useState<string>("");
  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const errCode = queryParams.get("code");

    if (errCode == null) {
      setCode("");
    } else {
      setCode(errCode);
    }
  }, [location]);

  return (
    <>
      {(() => {
        switch (code) {
          case "e0001": //ErrCodeInternal
            return (
              <DefaultErrorPage
                heading="An unexpected error has occurred"
                description="Lyve Cloud is currently unavailable. Please try again later or contact Lyve Cloud support."
              />
            );
          case "e0002": //ErrCodeUnauthenticated
            return (
              <DefaultErrorPage
                heading="You do not have permission to access Lyve Cloud"
                description="Please contact your Administrator for the necessary permissions."
              />
            );
          case "e0003": //ErrCodeDisabled
            return (
              <DefaultErrorPage
                heading="Your Lyve Cloud account is disabled"
                description="Please contact your Administrator for more details."
              />
            );
          case "e0004": //ErrCodeExpired
            return (
              <DefaultErrorPage
                heading="The link is expired"
                description="Please request a new link to reset your Lyve Cloud access."
              />
            );
          case "e0005": //AuthenticationFailed
            return (
              <DefaultErrorPage heading="Authentication failed, please try again." />
            );
          case "e0006": //ExpiredPassword
            return (
              <DefaultErrorPage
                heading="Your password has expired."
                description="Please check your inbox for a reset password email."
              />
            );
          case "e999999": //Not supported Browser version
            return (
              <DefaultErrorPage
                heading="An error has occurred"
                description="Lyve Cloud console is not supported by the current version of your browser. Please contact support if you need any further support."
              />
            );

          default:
            return (
              <DefaultErrorPage
                heading="An unexpected error has occurred"
                description="Lyve Cloud is currently unavailable. Please try again later or contact Lyve Cloud support."
              />
            );
        }
      })()}
    </>
  );
};

export default UniformError;
