import { isEmpty } from "lodash";
import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { rules } from "../../authRules";
import api from "../../services/api";
import { WhoAmI } from "../../state/UserInfo/types";
import { AppState } from "../../store";

const check = (
  rules: { [role: string]: string[] },
  role: string,
  action: string
) => {
  const allowedActions = rules[role];
  if (!allowedActions) {
    // role is not present in the rules
    return false;
  }

  return allowedActions.includes(action);
};

type CanProps = {
  perform: string;
  no?: ReactNode;
};

const Can: React.FC<CanProps> = props => {
  const whoAmI = useSelector<AppState, WhoAmI>(state => state.userInfo.whoAmI);
  let userType = whoAmI?.type ?? "";
  let userRole = whoAmI?.role ?? "";

  if (!isEmpty(whoAmI)) {
    userType = whoAmI.type!;
    userRole = whoAmI.role!;
  }

  const role = `${userType}-${userRole}`;
  return <>{check(rules, role, props.perform) ? props.children : props.no}</>;
};

Can.defaultProps = {
  perform: "",
  no: null
};

export default Can;
