import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

export default function AgreeTerms(props: any) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    props.handleClose();
  };
  //   const handleShow = () => setShow(true);

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        animation={false}
        backdrop={false}
        size="lg"
      >
        <Modal.Header closeButton>
          {props.type === "terms" ? (
            <Modal.Title style={{ paddingLeft: 15 }}>
              Legal Disclaimer
            </Modal.Title>
          ) : (
            <Modal.Title style={{ paddingLeft: 15 }}>
              Legal Disclaimer
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          The Seagate Lyve Cloud&trade; system is the property of Seagate
          Technology LLC ("Seagate"). All rights are reserved. Your
          storage-as-a-service provider ("Provider") must have an agreement with
          Seagate that governs your access to and use of this Lyve Cloud system.
          Your access to and use of this Lyve Cloud system outside of such
          Provider or outside such an agreement is unauthorized and prohibited
          by law. Should you have any questions, please contact your Provider or{" "}
          <a href="mailto:support.lyvecloud@seagate.com">
            support.lyvecloud@seagate.com
          </a>
          .
        </Modal.Body>
      </Modal>
    </div>
  );
}
