import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import newlogo from "../../images/LyveCloud-CenterAlign.png";
import background from "../../images/bg.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "flex-start",
      flexGrow: 1,
      height: "100vh",
      backgroundColor: theme.palette.background.default,
      // backgroundImage: `url(${background})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      overflow: "auto",
    },
    container: {
      marginTop: theme.spacing(4),
    },
    paper: {
      width: 583,
      padding: theme.spacing(4.5, 2.5),
      fontFamily: "sans-serif",
      fontSize: "16px",
      fontWeight: "normal",
      // boxShadow: "0px 0px 0px 0px #ccc",
    },
    mainHeading: {
      width: 150,
      marginBottom: theme.spacing(2.75),
    },
    subHeading: {
      fontSize: 24,
      fontWeight: 500,
      marginBottom: theme.spacing(0.75),
    },
    emailText: {
      display: "block",
      textAlign: "center",
      marginBottom: theme.spacing(2.25),
      fontSize: 16,
    },
    emailTextBold: {
      fontWeight: 500,
    },
    error: {
      fontSize: 11,
      color: theme.palette.error.light,
      marginTop: theme.spacing(0.5),
    },
    formControl: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    inputLabel: {
      fontSize: 18.6,
      lineHeight: "26.6px",
      color: theme.palette.grey[600],
      "&.Mui-focused": {
        color: theme.palette.grey[600],
      },
      "& + .MuiInput-formControl": {
        marginTop: theme.spacing(3.5),
      },
    },
    input: {
      fontSize: 13,
      borderRadius: 3,
      border: "1px solid #d4d4d4",
      padding: theme.spacing(0.125, 0, 0.125, 1.25),
      "&.Mui-focused": {
        borderColor: "#777",
      },
    },
    iconContainer: {
      left: theme.spacing(0.25),
      "&:hover": {
        backgroundColor: "#ffffff",
      },
    },
    inputIcon: {
      fontSize: 14,
      color: theme.palette.primary.main,
    },
    inputIconVisible: {
      fontSize: 14,
      color: theme.palette.grey[400],
    },
    validationContainer: {
      marginTop: theme.spacing(0.5),
      padding: theme.spacing(0.125, 0),
    },
    validationItem: {
      display: "inline-flex",
      marginRight: theme.spacing(0.5),
      fontSize: 11,
      color: theme.palette.grey[700],
      alignItems: "initial",
    },
    validationIcon: {
      fontSize: 13,
      marginRight: theme.spacing(0.375),
      color: "#d4d4d4",
    },
    validationIconValid: {
      color: "#6ebe49",
    },
    spinner: {
      color: theme.palette.primary.contrastText,
      position: "relative",
      top: theme.spacing(0.375),
    },
    submit: {
      display: "block",
      paddingTop: theme.spacing(0.625),
      paddingBottom: theme.spacing(0.625),
      fontSize: 12,
      fontWeight: 500,
      textTransform: "capitalize",
      backgroundColor: theme.palette.primary.main,
      "&.Mui-disabled": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        opacity: 0.6,
      },
    },
    bottomText: {
      textAlign: "center",
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(0.75),
      fontSize: 14,
    },
    bottomLink: {
      textAlign: "center",
      fontSize: 13,
      marginBottom: theme.spacing(4.5),
    },
    copyright: {
      textAlign: "center",
      marginTop: theme.spacing(4),
      fontSize: 12,
      color: "#adb5bd",
    },
  })
);

const ExpiredToken = (props: any) => {
  const {
    match: { params },
  } = props;
  const userName = params.userName;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid
        className={classes.container}
        container
        spacing={2}
        justify="center"
        direction="row"
      >
        <Paper className={classes.paper}>
          <div>
            <img
              src={newlogo}
              className={classes.mainHeading}
              alt="Seagate Lyve Services"
            />
          </div>
          <div style={{ paddingLeft: "10px" }}>
            <div style={{ marginBottom: "18px" }}>Dear {userName},</div>
            <div style={{ marginBottom: "18px" }}>
              Your email invite to register as an administrator for your
              organization’s cloud storage services on Seagate Lyve Cloud has
              expired.
            </div>
            <div style={{ marginBottom: "24px" }}>
              Select the following link "Forgot Password" to request a new password or contact your administrator to
              reset your password.
            </div>
            <div style={{ marginBottom: "24px" }}>
              <a
                href="/reset-password"
                target="_blank"
                style={{
                  display: "inline-block",
                  color: "#ffffff",
                  backgroundColor: "#6EBE49",
                  border: "solid 1px #6EBE49",
                  borderRadius: "3px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                  textDecoration: "none",
                  fontSize: "16px",
                  fontWeight: "bold",
                  lineHeight: "22px",
                  margin: "0",
                  padding: "10px 25px",
                  textTransform: "capitalize",
                }}
              >
                <strong style={{ color: "#ffffff" }}>Forgot Password</strong>
              </a>
            </div>
            <div>Thank You,</div>
            <div style={{ marginBottom: "18px" }}>Lyve Cloud team</div>
          </div>
        </Paper>
      </Grid>
    </div>
  );
};

export default ExpiredToken;
