import React, { useState } from "react";
import { Pair } from "./CelebrateBucketCreation";
import { Step } from "./CreateBucket";
import { Region } from "../../../state/regions";

export type NewBucket = {
  name: string;
  region: Region | null;
  phase: "preparation" | "creation" | "celebration" | "error";
  info: Pair[];
  error: Error | null;
  downloaded: boolean;
};

declare interface AddBucketCardContext {
  newBucket: NewBucket;
  setNewBucket: (newBucket: NewBucket) => void;
  activeStep: Step;
  setActiveStep: (step: Step) => void;
  createdResources: CreatedResources;
  loadResource: (resourceName: keyof CreatedResources) => void;
  setResource: (resourceName: keyof CreatedResources, data: Data) => void;
  rejectResource: (resourceName: keyof CreatedResources, error?: Error) => void;
  resetResources: () => void;
  isFinalStepInProgress: boolean;
  setIsFinalStepInProgress: (isFinalStepInProgress: boolean) => void;
}

export type ResourceStatus = "resolved" | "rejected" | "loading" | "idle";

export declare interface Resource<T> {
  status: ResourceStatus;
  data: T;
}

export type BucketResource = {
  name: string
  regionUrl: string
}

export type PermissionResource = {
  id: string
  name: string
}

export type ServiceAccountResource = {
  name: string;
  secretKey: string;
  accessKey: string;
  id: string;
  s3Url: string;
}

export type StartHereBucketResource = {
  bucketName: string;
  serviceAccountName: string;
  serviceAccountId: string;
  permissionName: string;
  permissionId: string;
}

export type CreatedResources = {
  startHereBucket: Resource<StartHereBucketResource | null>
  bucket: Resource<BucketResource | null>;
  permission: Resource<PermissionResource | null>;
  serviceAccount: Resource<ServiceAccountResource | null>;
};

const defaultResource: Resource<null> = { status: "idle", data: null };
const initialResources = {
  startHereBucket: defaultResource,
  bucket: defaultResource,
  permission: defaultResource,
  serviceAccount: defaultResource
};

type Data = BucketResource | PermissionResource | ServiceAccountResource

type ResourceAction =
  | { status: "idle" }
  | { status: "resolved"; data: Data; name: keyof CreatedResources }
  | { status: "rejected"; error: Error; name: keyof CreatedResources }
  | { status: "loading"; name: keyof CreatedResources };

// @ts-ignore
const AddBucketCardContext = React.createContext<AddBucketCardContext>();

const resourceReducer = (
  state: CreatedResources,
  action: ResourceAction
): CreatedResources => {
  switch (action.status) {
    case "loading":
      return { ...state, [action.name]: { status: "loading", data: null } };
    case "resolved":
      return {
        ...state,
        [action.name]: { status: "resolved", data: action.data }
      };
    case "rejected":
      return { ...state, [action.name]: { status: "rejected", data: action.error } };
    case "idle":
      return {
        startHereBucket: defaultResource,
        bucket: defaultResource,
        permission: defaultResource,
        serviceAccount: defaultResource
      };
    default:
      throw new Error(`Unsupported action status`);
  }
};

export const AddBucketCardProvider: React.FC = props => {
  const getInitialBucket: () => NewBucket = () => ({
    name: "",
    phase: "preparation",
    region: null,
    info: [],
    error: null,
    downloaded: false
  });

  const [newBucket, setNewBucket] = React.useState<NewBucket>(getInitialBucket);

  const [activeStep, setActiveStep] = useState<Step>({
    number: 0,
    state: "wip"
  });

  const [isFinalStepInProgress, setIsFinalStepInProgress] = React.useState<boolean>(false);

  const [createdResources, setCreatedResources] = React.useReducer(
    resourceReducer,
    initialResources
  );

  const resetResources = () => setCreatedResources({ status: "idle" });

  const loadResource = (resourceName: keyof CreatedResources) =>
    setCreatedResources({ status: "loading", name: resourceName });

  const setResource = (resourceName: keyof CreatedResources, data: Data) => {
    setCreatedResources({ status: "resolved", name: resourceName, data });
  };

  const rejectResource = (
    resourceName: keyof CreatedResources,
    error = new Error()
  ) =>
    setCreatedResources({
      status: "rejected",
      name: resourceName,
      error
    });

  const value = {
    newBucket,
    setNewBucket,
    activeStep,
    setActiveStep,
    createdResources,
    loadResource,
    setResource,
    rejectResource,
    resetResources,
    isFinalStepInProgress,
    setIsFinalStepInProgress
  };

  return (
    <AddBucketCardContext.Provider value={value}>
      {props.children}
    </AddBucketCardContext.Provider>
  );
};

const useAddBucketCard = () => {
  const context = React.useContext(AddBucketCardContext);
  if (context === undefined) {
    throw new Error(
      "useAddBucketCard must be used within a AddBucketCardProvider"
    );
  }
  return context;
};

export default useAddBucketCard;
