export const helpLinks: Record<string, string> = {
  Buckets_Learn_More: "/en/managing-buckets.html",
  Object_Immutability: "/en/using-object-immutability.html",
  Permissions: "/en/managing-bucket-access-permissions.html",
  Add_Replication_Rule: "/en/setting-replication-rules.html",
  Create_Bucket_Permission:
    "/en/managing-bucket-access-permissions.html#creating-bucket-access-permissions",
  Edit_Bucket_Permission:
    "/en/managing-bucket-access-permissions.html#editing-bucket-permissions",
  Create_Policy_Permission:
    "/en/managing-bucket-access-permissions.html#creating-a-policy-permission",
  Service_Account: "/en/managing-service-accounts.html",
  Create_Service_Account: "/en/managing-service-accounts.html#creating-service-accounts",
  Edit_Service_Account: "/en/managing-service-accounts.html#editing-service-accounts",
  Users: "/en/managing-users-and-roles.html",
  Add_New_User: "/en/managing-users-and-roles.html#adding-a-user",
  Edit_New_User: "/en/managing-users-and-roles.html#viewing-and-editing-a-user-3254",
  Audit_Log_Target_Bucket:
    "/en/console-audit-log.html#editing-audit-log-target-bucket",
  S3_API_Audit_Logs: "/en/s3-api-audit-logs.html",
  Console_Audit_Logs: "/en/console-audit-log.html",
  Support: "/en/managing-support-tickets.html",
  Create_New_ticket: "/en/creating-a-support-ticket.html",
  Connect_s3_to_lyveCloud: "/en/connecting-s3-clients-to-lyve-cloud.html",
  Quick_start_guide: "/en/quick-start-guide.html",
  API_Guide: "/en/api-compatibility-guide.html",
  MFA: "/en/configuring-multi-factor-authentication--mfa-.html",
  Federation_Login: "/en/configuring-federated-login.html",
  Notification_Recipient: "/en/managing-notification-recipients.html",
  Add_Notification_Recipient: "/en/managing-notification-recipients.html#adding-a-recipient",
  Edit_Notification_Recipient: "/en/managing-notification-recipients.html#editing-a-recipient",
  Billing: "/en/understanding-billing.html",
  FAQ: "/en/frequently-asked-questions.html",
  Video_Library: "/en/video-library.html",
  SERVICE_ACCOUNT_SETTING: "/en/service-account-settings.html",
  SUBACCOUNT: "/en/managing-sub-accounts.html",
  CREATE_SUBACCOUNT: "/en/managing-sub-accounts.html#creating-a-sub-account",
  DISABLE_SUBACCOUNT: "/en/managing-sub-accounts.html#disabling-enabling-a-sub-account",
  DELETE_SUBACCOUNT: "/en/managing-sub-accounts.html#deleting-sub-account",
  DATA_MOVER: "/en/installing-data-mover-portal-and-data-mover-worker.html",
  Help: "/",
  ACCOUNT_API_LEARN_MORE: "/en/using-account-api.html"
};

export enum linkNames {
  Buckets_Learn_More = "Buckets_Learn_More",
  Object_Immutability = "Object_Immutability",
  Permissions = "Permissions",
  Create_Bucket_Permission = "Create_Bucket_Permission",
  Create_Policy_Permission = "Create_Policy_Permission",
  Edit_Bucket_Permission = "Edit_Bucket_Permission",
  Service_Account = "Service_Account",
  Create_Service_Account = "Create_Service_Account",
  Edit_Service_Account = "Edit_Service_Account",
  Users = "Users",
  Add_New_User = "Add_New_User",
  Edit_New_User = "Edit_New_User",
  Audit_Log_Target_Bucket = "Audit_Log_Target_Bucket",
  S3_API_Audit_Logs = "S3_API_Audit_Logs",
  SERVICE_ACCOUNT_SETTING = "SERVICE_ACCOUNT_SETTING",
  Console_Audit_Logs = "Console_Audit_Logs",
  Support = "Support",
  Create_New_ticket = "Create_New_ticket",
  Connect_s3_to_lyveCloud = "Connect_s3_to_lyveCloud",
  Quick_start_guide = "Quick_start_guide",
  API_Guide = "API_Guide",
  Federation_Login = "Federation_Login",
  Notification_Recipient = "Notification_Recipient",
  Add_Notification_Recipient = "Add_Notification_Recipient",
  Edit_Notification_Recipient = "Edit_Notification_Recipient",
  Add_Replication_Rule = "Add_Replication_Rule",
  Billing = "Billing",
  Video_Library = "Video_Library",
  MFA = "MFA",
  FAQ = "FAQ",
  Help = "Help",
  SUBACCOUNT = "SUBACCOUNT",
  CREATE_SUBACCOUNT = "CREATE_SUBACCOUNT",
  DISABLE_SUBACCOUNT = "DISABLE_SUBACCOUNT",
  DELETE_SUBACCOUNT = "DELETE_SUBACCOUNT",
  DATA_MOVER = "DATA_MOVER",
  ACCOUNT_API_LEARN_MORE = "ACCOUNT_API_LEARN_MORE"
}

export const getDocsBaseUrl = () => {
  const currentEnv = localStorage.getItem("CURRENT_ENV");
  const baseEnv = currentEnv === "production" ? "help" : "help-stg"
  return `${baseEnv}.lyvecloud.seagate.com`
}

export const getLink = (linkFor: linkNames) => {
  const CURRENT_ENV = "CURRENT_ENV";
  const currentEnv = localStorage.getItem(CURRENT_ENV);
    if (currentEnv === "production") {
      return `https://help.lyvecloud.seagate.com${helpLinks[linkFor]}`;
    } else {
      return `https://help-stg.lyvecloud.seagate.com${helpLinks[linkFor]}`;
    }
};
