import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../store";
import { SliceState, StoreStateStatus } from "./types";

export interface Bucket {
  name: string;
  creation_date: string;
  // size: string
  region: string;
  usage: number;
  compliance: boolean;
  versioned: boolean;
  logged: boolean;
  retentionDuration: number; //number of "days" or "years"
  retentionUnit: string;
  deletionMarker: boolean;
}

type State = SliceState<Bucket[]>;

const initialState: State = {
  status: StoreStateStatus.IDLE,
  error: null,
  data: []
};

const bucketsSlice = createSlice({
  name: "buckets",
  initialState,
  reducers: {
    loadingBuckets: state => {
      state.status = StoreStateStatus.LOADING;
    },
    resolveBuckets: (state, action: PayloadAction<Bucket[]>) => {
      state.status = StoreStateStatus.RESOLVED;
      state.data = action.payload || [];
    },
    rejectBuckets: (state, action: PayloadAction<Error>) => {
      state.status = StoreStateStatus.REJECTED;
      state.error = action.payload;
    },
    removeBucket: (state, action: PayloadAction<string>) => {
      state.status = StoreStateStatus.RESOLVED;
      const buckets = [...state.data];
      const matchedBucketIdx = buckets.findIndex(
        bucket => bucket.name === action.payload
      );
      buckets.splice(matchedBucketIdx, 1);
      state.data = [...buckets];
    }
  }
});

export const {
  loadingBuckets,
  resolveBuckets,
  rejectBuckets,
  removeBucket
} = bucketsSlice.actions;
export default bucketsSlice.reducer;

export const selectBuckets = (state: AppState) => state.buckets;
