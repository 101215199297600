import React from "react";
import LyveTooltip from "../../../components/LyveTooltip";
import { Clipboard, FileCode, X } from "react-bootstrap-icons";
import DefaultAlert from "../../../components/LyveAlert";
import { Spinner } from "react-bootstrap";
import { DefaultButton } from "../../../components/LyveButton";
import { PrepareResourcesProps } from "../../../components/CreateResources";

import "./styles.scss";

export declare interface AccountApi {
  access_key: string;
  secret_key: string;
}

const PrepareResources = ({
  showLoader,
  data,
  closePopup
}: PrepareResourcesProps<AccountApi>) => {
  const [isDownloaded, setDownloaded] = React.useState<boolean>(false);
  const markDownloaded = () => setDownloaded(true)

  const [copiedAccessKey, setCopiedAccessKey] = React.useState(false);
  const [copiedSecretKey, setCopiedSecretKey] = React.useState(false);

  const copyAccessKey = () => {
    setCopiedAccessKey(true);
    navigator.clipboard.writeText(data.access_key);
    const copyTooltipTimer = setTimeout(() => {
      setCopiedAccessKey(false);
      clearTimeout(copyTooltipTimer);
    }, 2000);
  };

  const copySecretKey = () => {
    setCopiedSecretKey(true);
    navigator.clipboard.writeText(data.secret_key);
    const copyTooltipTimer = setTimeout(() => {
      setCopiedSecretKey(false);
      clearTimeout(copyTooltipTimer);
    }, 2000);
  };

  const name = "Account API";

  const generateCSV = () => {
    const rows = [
      ["Name", "Access Key", "Secret Key"],
      [name, data.access_key, data.secret_key]
    ];
    const csvContent =
      "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
    return encodeURI(csvContent);
  };

  const generateJSON = () => {
    const account = {
      name,
      accessKey: data.access_key,
      secretKey: data.secret_key
    };
    const csvContent =
      "data:text/json;charset=utf-8," + JSON.stringify(account) + "\n";
    return encodeURI(csvContent);
  };

  const togglePopup = () => {
    if (showLoader || (!showLoader && isDownloaded)) {
      closePopup();
    }
  };

  return (
    <div className="account-api-confirmation">
      <div className="confirmation-title-container">
        <h4 className="confirmation-title">Account API Credentials Created</h4>
        <LyveTooltip
          text="Please make sure to download the service account details before exiting."
          id="acc-api-popup-close-btn"
          enabled={!showLoader && !isDownloaded}
        >
          <button className="icon-btn" onClick={togglePopup}>
            <X size="24" />
          </button>
        </LyveTooltip>
      </div>
      <DefaultAlert variant="warning">
        Make sure to copy or download the secret key to a secured location, as
        these won't be available for copy or download once this dialog box is
        closed.
      </DefaultAlert>
      <div className="field-container">
        <label className="field-label">Access Key</label>
        <div className="field-value">
          {!showLoader ? (
            <>
              <div>{data.access_key}</div>
              <LyveTooltip
                text={
                  copiedAccessKey ? "Copied to clipboard" : "Copy Access Key"
                }
                id="copy-acc-access"
                enabled={!showLoader}
              >
                <button className="icon-btn" onClick={copyAccessKey}>
                  <Clipboard />
                </button>
              </LyveTooltip>
            </>
          ) : (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-1"
            />
          )}
        </div>
      </div>
      <div className="field-container">
        <label className="field-label">Secret Key</label>
        <div className="field-value">
          {!showLoader ? (
            <>
              <div>{data.secret_key}</div>
              <LyveTooltip
                text={
                  copiedSecretKey ? "Copied to clipboard" : "Copy Secret Key"
                }
                id="copy-acc-secret"
                enabled={!showLoader}
              >
                <button className="icon-btn" onClick={copySecretKey}>
                  <Clipboard />
                </button>
              </LyveTooltip>
            </>
          ) : (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-1"
            />
          )}
        </div>
      </div>
      <div className="field-container">
        <label className="field-label">Download as</label>
        <div>
          <a
            download={`${name}.csv`}
            href={generateCSV()}
            onClick={markDownloaded}
          >
            <DefaultButton
              variant="outline-secondary"
              className="mr-2"
              disabled={showLoader}
            >
              <FileCode size="16" />
              CSV
            </DefaultButton>
          </a>
          <a
            download={`${name}.json`}
            href={generateJSON()}
            onClick={markDownloaded}
          >
            <DefaultButton variant="outline-secondary" disabled={showLoader}>
              <FileCode size="16" />
              JSON
            </DefaultButton>
          </a>
        </div>
      </div>
    </div>
  );
};

export default PrepareResources;
