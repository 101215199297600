import {
  TOGGLE_USER_DISPLAY_MODAL,
  USER_DISPLAY_MODAL,
  USER_HIDE_MODAL,
  UsersState,
  ToggleUserDisplayModalActionTypes,
  UserDisplayModalAction,
  UserHideModalAction,
} from './types';

const initialState: UsersState = {
  displayModal: false,
}

export function reducer(
  state = initialState,
  action: ToggleUserDisplayModalActionTypes | UserDisplayModalAction | UserHideModalAction): UsersState {
  switch (action.type) {
    case TOGGLE_USER_DISPLAY_MODAL:
      return {
        ...state,
        displayModal: !state.displayModal,
      }
    case USER_DISPLAY_MODAL:
      return {
        ...state,
        displayModal: true,
      }
    case USER_HIDE_MODAL:
      return {
        ...state,
        displayModal: false,
      }
    default:
      return state;
  }
}
