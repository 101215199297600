import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunk from "redux-thunk";
import { reducer as DashboardReducer } from "./scenes/Dashboard/reducer";
import { reducer as NotificationReducer } from "./components/Notification/reducer";
import { reducer as NotificationRecipientReducer } from "./components/NotificationRecipients/reducer";
import { reducer as PartnerDashboardReducer } from "./PartnerPortal/Dashboard/reducer";
import { reducer as MonthPickerReducer } from "./PartnerPortal/Dashboard/components/Metric/MonthPicker/store/reducer";
import { reducer as UserInfoReducer } from "./state/UserInfo/reducer";
import { reducer as IAMInfoReducer } from "./state/IAM/reducer";
import { reducer as SubaccountsReducer } from "./state/Subaccounts/reducer";
import { reducer as EvalReducer } from "./state/Eval/reducer";
import BucketReducer from "./state/buckets";
import ArchiveBucketReducer from "./state/archiveBuckets";
import PermissionReducer from "./state/permissions";
import ServiceAccountReducer from "./state/serviceAccounts";
import regionsReducer from "./state/regions";
import regionswithsgReducer from "./state/regionswithsg";
import startherebucketReducer from "./state/startherebucket";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import lyveCloudReducer from "./state/lyveCloud";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

// Disable Redux Devtools in production
const composeEnhancers =
  (window &&
    process.env.NODE_ENV !== "production" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const appReducer = combineReducers({
  dashboard: DashboardReducer,
  notification: NotificationReducer,
  notificationRecipients: NotificationRecipientReducer,
  partnerdashboard: PartnerDashboardReducer,
  monthpicker: MonthPickerReducer,
  userInfo: UserInfoReducer,
  IAMInfo: IAMInfoReducer,
  eval: EvalReducer,
  buckets: BucketReducer,
  archiveBuckets: ArchiveBucketReducer,
  permission: PermissionReducer,
  serviceAccounts: ServiceAccountReducer,
  regions: regionsReducer,
  regionswithsg: regionswithsgReducer,
  startherebucket: startherebucketReducer,
  lyveCloud: lyveCloudReducer,
  subaccounts: SubaccountsReducer
});

function configureStore() {
  return createStore(appReducer, composeEnhancers(applyMiddleware(thunk)));
}

const store = configureStore();

export default store;
export type AppState = ReturnType<typeof appReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
