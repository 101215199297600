import {
  IS_SAML_CONFIGURED,
  UPDATE_MFA_PREFERENCE,
  IAMInfoActionTypes
} from "./actions";
import { IAMInfoState } from "./types";

const initialState: IAMInfoState = {
  IAMInfo: {
    mfa_preference: "passwordOnly",
    isSAMLConfigured: undefined
  }
};

export function reducer(
  state = initialState,
  action: IAMInfoActionTypes
): IAMInfoState {
  switch (action.type) {
    case IS_SAML_CONFIGURED:
      return {
        ...state,
        IAMInfo: { ...state.IAMInfo, isSAMLConfigured: action.payload }
      };
    case UPDATE_MFA_PREFERENCE:
      return {
        ...state,
        IAMInfo: { ...state.IAMInfo, mfa_preference: action.payload }
      };
    default:
      return state;
  }
}
