import React from "react";
import cx from "classnames";
import { Spinner } from "react-bootstrap";
import { ArrowDown } from "react-bootstrap-icons";
import DataTable from "react-data-table-component";
import "./styles.scss";

const DataLoader = () => (
  <div style={{ padding: 30, color: "var(--green-3)" }}>
    <Spinner animation="border" role="status" className="spinner">
      <span className="sr-only">Loading...</span>
    </Spinner>
  </div>
);

const LyveTable: React.FC<any> = ({ className, children, ...props }) => {
  return (
    <div className={cx("lyve-table-card", className)}>
      <DataTable
        sortIcon={<ArrowDown size="16" color="var(--gray-14)" />}
        progressComponent={<DataLoader />}
        className={props.progressPending ? "lyve-datatable-loading" : "lyve-datatable"}
        {...props}
      />
      {children}
    </div>
  );
};

export default LyveTable;
