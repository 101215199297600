import React from "react";
import { DefaultButton } from "../../LyveButton";
import useAddBucketCard from "./AddBucketContext";
import LyveTooltip from "../../LyveTooltip";
import { Clipboard } from "react-bootstrap-icons";

import "./styles.scss";

export type Pair = {
  key: string;
  name: string;
  value: string;
};

type ShowBucketInfoProps = {
  infoPairs: Pair[];
  handleBucketCreationCelebration: () => void;
};

const generateCSV = (infoPairs: Pair[]) => {
  const rows = [
    infoPairs.map(pair => pair.name),
    infoPairs.map(pair => pair.value)
  ];
  const csvContent =
    "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
  return encodeURI(csvContent);
};

const generateJSON = (infoPairs: Pair[]) => {
  const account = infoPairs.reduce((acc: any, pair) => {
    acc[pair.name] = pair.value;
    return acc;
  }, {});
  const csvContent =
    "data:text/json;charset=utf-8," + JSON.stringify(account) + "\n";
  return encodeURI(csvContent);
};

const CelebrateBucketCreation: React.FC<ShowBucketInfoProps> = props => {
  const [showCopyTooltip, setShowCopyTooltip] = React.useState(true);
  const [info, setInfo] = React.useState({ csv: "", json: "" });
  const { newBucket, setNewBucket } = useAddBucketCard();
  let windowObj: any = window;

  const handleInfoDownload = () =>
    setNewBucket({ ...newBucket, downloaded: true });

  React.useEffect(() => {
    const csv = generateCSV(props.infoPairs);
    const json = generateJSON(props.infoPairs);
    setInfo({ csv, json });
  }, [props.infoPairs]);

  const { infoPairs } = props;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(infoPairs[1].value);
    setShowCopyTooltip(false);
    const copyTooltipTimer = setTimeout(() => {
      setShowCopyTooltip(true);
      clearTimeout(copyTooltipTimer)
    }, 1000)
  };

  return (
    <>
      <div className="subtitle">
        Use the service account information below to access this bucket via the
        Lyve Cloud API.
      </div>
      <p className="note">
        Make sure to download the service account information as it includes the
        secret key and it is the only time it will be provided!
      </p>
      <div className="pair">
        <span className="pair-key"> {infoPairs[0].name}: </span>{" "}
        <span> {infoPairs[0].value}</span>
      </div>
      <div className="pair d-flex">
        <span className="pair-key"> {infoPairs[1].name}: </span>{" "}
        <span className="d-flex">
          <span className="pair-value"> {infoPairs[1].value}</span>
          <LyveTooltip
            text="Copied to clipboard"
            id="copy-bucket-info"
            variant="secondary"
            placement="top"
            enabled={!showCopyTooltip}
          >
            <button
              className="icon-btn"
              onClick={copyToClipboard}
              title={showCopyTooltip ? `Copy ${infoPairs[1].name}` : ""}
            >
              <Clipboard />
            </button>
          </LyveTooltip>
        </span>
      </div>
      <div className="downloads">
        <DefaultButton
          variant="outline-light"
          className="mr-3"
          onClick={handleInfoDownload}
        >
          <a href={info.csv} download={windowObj.serviceAccountName+".csv"}>
            Download CSV
          </a>
        </DefaultButton>
        <DefaultButton variant="outline-light" onClick={handleInfoDownload}>
          <a href={info.json} download={windowObj.serviceAccountName+".json"}>
            Download JSON
          </a>
        </DefaultButton>
      </div>
    </>
  );
};

export default CelebrateBucketCreation;
