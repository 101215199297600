import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../store";
import { SliceState, StoreStateStatus } from "./types";

export declare interface ServiceAccount {
  id: string;
  name: string;
  slug: string;
  access_key: string;
  enabled: boolean;
}

type State = SliceState<ServiceAccount[]>;

const initialState: State = { status: StoreStateStatus.IDLE, error: null, data: [] };

const serviceAccountsSlice = createSlice({
  name: "serviceAccounts",
  initialState,
  reducers: {
    loadingServiceAccounts: state => {
      state.status = StoreStateStatus.LOADING;
    },
    resolveServiceAccounts: (
      state,
      action: PayloadAction<ServiceAccount[]>
    ) => {
      state.status = StoreStateStatus.RESOLVED;
      state.data = action.payload || [];
    },
    rejectServiceAccounts: (state, action: PayloadAction<Error>) => {
      state.status = StoreStateStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export const {
  loadingServiceAccounts,
  resolveServiceAccounts,
  rejectServiceAccounts
} = serviceAccountsSlice.actions;
export default serviceAccountsSlice.reducer;

export const selectServiceAccounts = (state: AppState) => state.serviceAccounts
