import React from "react";
import cx from "classnames";

import "./index.scss";
import { Link } from "react-router-dom";
import LyveLink from "./LyveLink";

interface IOwnProps {
  title: string;
  subTitle: string;
  subTitleNewLine?: string;
  helpLink?: string;
  marginRightStyle?: boolean;
  titleMarginRightRemoved?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const LyveHeadingLink = ({
  title,
  subTitle,
  subTitleNewLine,
  helpLink,
  marginRightStyle,
  titleMarginRightRemoved,
  children,
  className
}: IOwnProps) => {
  const isMarginRemoved: boolean =
    typeof marginRightStyle !== "undefined" ? marginRightStyle : false;
  const isSubTitle: boolean = subTitle !== "" ? false : true;
  const isHelpLink: boolean = helpLink !== "" ? false : true;

  return (
    <div
      className={`${cx("lyve-page-title", {
        "m-title-remove": titleMarginRightRemoved
      })} ${typeof className !== "undefined" ? className : ""}`}
    >
      <div className="heading-area">
        <h4 className={cx("lyve-page-main-title", { "m-remove": isSubTitle })}>
          {title}
          {subTitle.length == 0 &&
            (helpLink ? <LyveLink link={helpLink} /> : null)}
        </h4>
        <p className="lyve-page-sub-title">
          {subTitle}
          {helpLink ? <LyveLink link={helpLink} /> : null}
        </p>
      </div>
      <div className={cx("button-area", { "m-remove": isMarginRemoved })}>
        {children}
      </div>
    </div>
  );
};

export default LyveHeadingLink;
