import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#edfae6",
      main: "#6ebe49",
      dark: "#60a640",
      contrastText: "#ffffff"
    },
    secondary: {
      light: "#e0f6d5",
      main: "#0044ff",
      dark: "#bce6a9"
    },
    error: {
      light: "#e03a48",
      main: "#dc1f2e",
      contrastText: "#ffffff"
    },
    grey: {
      50: "#fafafa",
      100: "#f0f0f0",
      200: "#e6e6e6",
      300: "#cccccc",
      400: "#999999",
      500: "#8c8c8c",
      600: "#737373",
      700: "#666666",
      800: "#4d4d4d",
      900: "#333333"
    },
    background: {
      default: "#fafafa"
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none"
        },
        "&:active": {
          boxShadow: "none"
        }
      }
    },
    MuiButtonGroup: {
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none"
        },
        "&:active": {
          boxShadow: "none"
        }
      }
    }
  }
});

export default theme;
