import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { DefaultBreadCrumb } from ".";
import { BreadCrumData } from "./types";

type IOwnProps = {
    breadcrumbs?: BreadCrumData[];
    customClasses?: string;
    heading: string;
};

const HeadingBreadCrumb: React.FC<IOwnProps> = (props) => {
    return (
        <div className="lyve-heading-breadcrumb">
            <DefaultBreadCrumb
                breadcrumbs={(typeof props.breadcrumbs !== "undefined") ? props.breadcrumbs : []}
                customClasses={props.customClasses}
            />

            <h1>{props.heading}</h1>
        </div>
    );
};

export default HeadingBreadCrumb;