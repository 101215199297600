import React, { Component, PureComponent, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { CSVLink } from "react-csv";
import partner_api from "../../../../services/Partner_api";
import api from "../../../../services/api";
import filesize from "filesize";
import "react-dropdown/style.css";
import moment, { Moment } from "moment";
import storage from "local-storage-fallback";
import CustomerDetails from "./CustomerDetailsTable";
import idbHelper from "../Support/Helpers/IDBHelper";
import { Ticket } from "../Support/Models/Ticket";
import {
  getPartnerDetails,
  checkWorkerSupport
} from "../Support/Helpers/SupportHelpers";
import PartnerTrendChart from "./charts/PartnerTrendChart";
import CustomerTrendChart from "./charts/CustomerTrendChart";
import PartnerTrendBarChart from "./charts/PartnerTrendBarChart";
import ReactTooltip from "react-tooltip";
import Highcharts from "highcharts";
import MonthPicker from "./MonthPicker";
import { SelectedMonthRange } from "./MonthPicker/store/types";
import * as MonthPickerActions from "./MonthPicker/store/actionCreators";
import MonthDropDownPrompt from "./MonthPicker/monthdropdown";
import Overlay from "react-bootstrap/Overlay";
import { Badge, CardDeck, OverlayTrigger } from "react-bootstrap";
import { MetricCard } from "../../../../components/Card";
import { QuestionCircleFill } from "react-bootstrap-icons";
import { ThreeDots } from "react-bootstrap-icons";
import { LyveMenu, LyveSelect } from "../../../../components/LyveDropdown";
import PaperCard from "../../../../components/Card/PaperCard";
import { OptionTypeBase } from "react-select";

import "./styles.scss";
import LyveDateRangeSelect from "../../../../components/LyveDropdown/LyveDateRangeSelect";
import { AppState } from "../../../../store";
import { IListOption } from "../../../../components/LyveDropdown/LyveDateRangeSelect/LyveDateRangeSelect";
import {
  convertFileSize,
  getCurrentMonthStartDate,
  getFrequencyByRange,
  getLastMonthsDate,
  isDaily
} from "../../../../utils";
import { UsageFrequency } from "../../../../scenes/Dashboard/components/Metrics/hooks/useUsageMetric";
import { start } from "repl";
import LyveHeading from "../../../../components/LyveHeading";
import { convertFileSizeInGB } from "../../../../scenes/Dashboard/components/Metrics";
import { WhoAmI } from "../../../../state/UserInfo/types";

// function useWindowSize() {
// 	// Initialize state with undefined width/height so server and client renders match
// 	// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
// 	const [windowSize, setWindowSize] = React.useState({
// 		width: 0,
// 		height: 0
// 	});

// 	React.useEffect(() => {
// 		// Handler to call on window resize
// 		function handleResize() {
// 			// Set window width/height to state
// 			setWindowSize({
// 				width: window.innerWidth,
// 				height: window.innerHeight
// 			});
// 		}

// 		// Add event listener
// 		window.addEventListener('resize', handleResize);

// 		// Call handler right away so state gets updated with initial window size
// 		handleResize();

// 		// Remove event listener on cleanup
// 		return () => window.removeEventListener('resize', handleResize);
// 	}, []); // Empty array ensures that effect is only run on mount

// 	return windowSize;
// }

interface dashboardsummary {
  partner_summary: partner_summary;
  tenant_summary: tenant_summary[];
  //buckets_count: Usage[];
}

interface partner_summary {
  tenant_or_partner_name: string;
  users_count: number;
  service_accounts_count: number;
  buckets_count: number;
  usage: number;
  usage_percent: string;
  onboard_date: string;
}

export interface tenant_summary {
  tenant_or_partner_name: string;
  users_count: string;
  service_accounts_count: number;
  buckets_count: number;
  usage: number;
  usage_percent: string;
  onboard_date: string;
  open_ticket: number;
  converted_usage: string;
}

interface dashboard_trend {
  partner_trend: partner_trend[];
  tenants_trend: tenants_trend[];
}
interface partner_trend {
  date: string;
  usage: number;
}

interface tenants_trend {
  tenant_name: string;
  tenant_trend: tenant_trend[];
}

interface tenant_trend {
  tenant_name: string;
  date: string;
  usage: string;
}

interface DTicket {
  code: number;
  data: TicketCount;
  message: string;
}

interface TicketCount {
  TotalResolvedCount: number;
  TotalNewCount: number;
  TotalInProgressCount: number;
  CustomerCountList: CustomerCountList[];
}

interface CustomerCountList {
  ResolvedCount: number;
  NewCount: number;
  InProgressCount: number;
  Customer: string;
}

interface ChartData {
  name: string;
  data: number[];
}

const CustomizedTick: React.FC<any> = ({
  payload: { value },
  verticalAnchor,
  visibleTicksCount,
  ...rest
}) => (
  <text
    {...rest}
    className="bar-chart-tick"
    style={{ fontSize: "8pt" }}
    dy={20}
  >
    {parseInt(value.split("-")[2].slice(0, 2), 10)}
  </text>
);

interface FilterRange {
  fromMonth?: string;
  toMonth?: string;
  fromMonthDate?: Date;
  toMonthDate?: Date;
}

interface ISelectOption extends OptionTypeBase {
  label: string;
  value: string | number;
  disabled?: boolean;
}

interface IBucketUsage {
  time: string;
  usageInByte: string;
  usageInGB: string;
  org_name: string;
}

interface IBucketUsageResponse {
  time: string;
  usage: string;
  org_name: string;
}

interface IDownloadReport {
  records: IBucketUsageResponse[];
}

const CSVHeaders = [
  { label: "Month", key: "time" },
  { label: "Organization name", key: "org_name" },
  { label: "Usage (byte)", key: "usageInByte" },
  { label: "Usage (GB)", key: "usageInGB" }
];

const PartnerMetric: React.FC<MetriOwnProps> = props => {
  const whoAmI = useSelector<AppState, WhoAmI>(state => state.userInfo.whoAmI);
  //const classes = useStyles();
  const [defaultDataInitialized, setDefaultDataInitialized] = React.useState<
    boolean
  >(false);
  const [summaryDataInitialized, setSummaryDataInitialized] = React.useState<
    boolean
  >(false);
  const [ticketDataInitialized, setTicketDataInitialized] = React.useState<
    boolean
  >(false);

  const [selectedMonth, setSelectedMonth] = React.useState(0);
  const [totalBuckets, setTotalBuckets] = React.useState<number>(0);
  const [totalUsage, setTotalUsage] = React.useState<string>("");
  const [totalCustomers, setTotalCustomers] = React.useState<number>(0);

  const [Tenant_summaryData, setTenant_summaryData] = React.useState<
    tenant_summary[]
  >([]);

  const [totalTickets, setTotalTickets] = React.useState<number>(0);
  const [openTickets, setOpenTickets] = React.useState<number>(0);
  const [companyId, setCompanyId] = React.useState<string>("");
  const [partnerName, setPartnerName] = React.useState<string>("");

  const [partnerXData, setPartnerXData] = React.useState<string[]>();
  const [partnerYData, setPartnerYData] = React.useState<number[]>();
  const [partnerAverageValue, setPartnerAverageValue] = React.useState<number>(
    0
  );
  const [monthOptions, setMonthOptions] = React.useState<Array<any>>([]);

  const [customerChartData, setCustomerChartData] = React.useState<ChartData[]>(
    []
  );

  const [isActiveButton, setIsActiveButton] = React.useState<string>("1");

  const [loadingCustTicket, setLoadingCustTicket] = React.useState<boolean>(
    false
  );
  const [frequency, SetFequency] = React.useState<UsageFrequency>(
    UsageFrequency.DAILY
  );
  //const size = useWindowSize();
  const [selectedMetricRange, setSelectedMetricRange] = React.useState<
    FilterRange
  >({});
  const [prevMonth, setPrevMonth] = React.useState<Date>(new Date());
  const [defaultSelectedRange, setDefaultSelectedRange] = React.useState<
    SelectedMonthRange | undefined
  >();

  const [startMonth, setStartMonth] = React.useState<string>("");
  const [endMonth, setEndMonth] = React.useState<string>("");
  const [showDropdownModal, setShowDropdownModal] = React.useState<boolean>(
    false
  );

  const [startMonthText, setStartMonthText] = React.useState<string>("");
  const [endMonthText, setEndMonthText] = React.useState<string>("");
  const { createdAt, userCreatedAt } = useSelector((state: AppState) => ({
    createdAt: state.userInfo.whoAmI.created_at,
    userCreatedAt: state.userInfo.whoAmI.created_date_ft
  }));

  const [startDate, setStartDate] = useState<Moment | undefined>(
    getCurrentMonthStartDate(createdAt || userCreatedAt)
  );
  const [endDate, setEndDate] = useState<Moment | undefined>(moment());

  const yearsCount =
    createdAt || userCreatedAt
      ? moment(createdAt || userCreatedAt).get("year") < moment().get("year")
        ? 2
        : 1
      : 1;

  const dateOptions: IListOption[] = [
    {
      label: "This month",
      value: 0,
      calendarType: "year",
      selectsRange: false,
      min:
        createdAt || userCreatedAt
          ? moment(createdAt || userCreatedAt)
          : undefined,
      max: moment(),
      yearsCount
    },
    {
      label: "Last 6 months",
      value: 1,
      calendarType: "year",
      selectsRange: true,
      min:
        createdAt || userCreatedAt
          ? moment(createdAt || userCreatedAt)
          : undefined,
      max: moment(),
      yearsCount
    },
    {
      label: "Custom time range",
      value: 2,
      calendarType: "year",
      selectsRange: true,
      min:
        createdAt || userCreatedAt
          ? moment(createdAt || userCreatedAt)
          : undefined,
      max: moment(),
      yearsCount,
      isCustom: true
    }
  ];

  const [value, setValue] = useState<IListOption>(dateOptions[0]);
  const [selectedOption, setSelectedOption] = React.useState<IListOption>({
    ...dateOptions[0],
    startDate,
    endDate
  });

  const [bucketUsageData, setBucketUsageData] = React.useState<IBucketUsage[]>(
    []
  );

  // Month Picker Properties
  //   const monthNames: Array<string> = [
  //     "January",
  //     "February",
  //     "March",
  //     "April",
  //     "May",
  //     "June",
  //     "July",
  //     "August",
  //     "September",
  //     "October",
  //     "November",
  //     "December"
  //   ];
  //   let currentMonth: Date = new Date();

  //   const currentDate = () => moment();
  //   const dateOptions = [
  //     {
  //       label: currentDate()
  //         .endOf("month")
  //         .format("MMMM YYYY"),
  //       month: currentDate().month() + 1,
  //       year: currentDate().year(),
  //       queryString: currentDate()
  //         .startOf("month")
  //         .format("YYYY-MM-DD"),
  //       noOfDays: currentDate()
  //         .endOf("month")
  //         .date()
  //     },
  //     {
  //       label: currentDate()
  //         .subtract(1, "months")
  //         .endOf("month")
  //         .format("MMMM YYYY"),
  //       month:
  //         currentDate()
  //           .subtract(1, "months")
  //           .month() + 1,
  //       year: currentDate()
  //         .subtract(1, "months")
  //         .year(),
  //       queryString: currentDate()
  //         .subtract(1, "months")
  //         .startOf("month")
  //         .format("YYYY-MM-DD"),
  //       noOfDays: currentDate()
  //         .subtract(1, "months")
  //         .endOf("month")
  //         .date()
  //     },
  //     {
  //       label: currentDate()
  //         .subtract(2, "months")
  //         .endOf("month")
  //         .format("MMMM YYYY"),
  //       month:
  //         currentDate()
  //           .subtract(2, "months")
  //           .month() + 1,
  //       year: currentDate()
  //         .subtract(2, "months")
  //         .year(),
  //       queryString: currentDate()
  //         .subtract(2, "months")
  //         .startOf("month")
  //         .format("YYYY-MM-DD"),
  //       noOfDays: currentDate()
  //         .subtract(2, "months")
  //         .endOf("month")
  //         .date()
  //     },
  //     {
  //       label: currentDate()
  //         .subtract(3, "months")
  //         .endOf("month")
  //         .format("MMMM YYYY"),
  //       month:
  //         currentDate()
  //           .subtract(3, "months")
  //           .month() + 1,
  //       year: currentDate()
  //         .subtract(3, "months")
  //         .year(),
  //       queryString: currentDate()
  //         .subtract(3, "months")
  //         .startOf("month")
  //         .format("YYYY-MM-DD"),
  //       noOfDays: currentDate()
  //         .subtract(3, "months")
  //         .endOf("month")
  //         .date()
  //     }
  //   ];

  //   const getOptions = () => {
  //     const options: ISelectOption[] = [];

  //     dateOptions.map((option, index) => {
  //       if (index === 0) {
  //         options.push({
  //           label: `This month: ${option.label}`,
  //           value: index
  //         });
  //       } else {
  //         options.push({
  //           label: option.label,
  //           value: index
  //         });
  //       }
  //     });

  //     return options;
  //   };

  //   const getSelectedOption = (selectedValue: number) => {
  //     const selectedOption = getOptions().find(
  //       option => option.value === selectedValue
  //     );

  //     return selectedOption ? selectedOption : null;
  //   };

  //   const handleChange = (option: ISelectOption) => {
  //     // setSelectedMonth(option.value as number);
  //     // getMonthlyData(option.value as number);
  //   };

  // const convertFileSize = (value: any) => {
  //   let convertedValue = filesize(value, { spacer: "" });
  //
  //   if (convertedValue.search("KB") > -1) {
  //     convertedValue = convertedValue.replace("KB", "KiB");
  //     return convertedValue;
  //   } else if (convertedValue.search("MB") > -1) {
  //     convertedValue = convertedValue.replace("MB", "MiB");
  //     return convertedValue;
  //   } else if (convertedValue.search("GB") > -1) {
  //     convertedValue = convertedValue.replace("GB", "GiB");
  //     return convertedValue;
  //   } else if (convertedValue.search("TB") > -1) {
  //     convertedValue = convertedValue.replace("TB", "TiB");
  //     return convertedValue;
  //   } else if (convertedValue.search("PB") > -1) {
  //     convertedValue = convertedValue.replace("PB", "PiB");
  //     return convertedValue;
  //   } else {
  //     return convertedValue;
  //   }
  // };

  //   const setDefaultCustomRange = () => {
  //     var startmonth: string = currentDate()
  //       .subtract(2, "months")
  //       .endOf("month")
  //       .format("MMMM YYYY");

  //     var endmonth: string = currentDate().format("MMMM YYYY");

  //     setStartMonth(startmonth);

  //     setEndMonth(endmonth);

  //     setStartMonthText(startmonth);
  //     setEndMonthText(endmonth);
  //   };

  //   const getMonthRange = (startDate: string) => {
  //     setDefaultCustomRange();

  //     let data: Array<any> = [];

  //     var each = {
  //       index: 1,
  //       label: currentDate()
  //         .endOf("month")
  //         .format("MMMM YYYY"),
  //       month: currentDate().month() + 1,
  //       year: currentDate().year(),
  //       noOfDays: currentDate()
  //         .endOf("month")
  //         .date(),
  //       labelMonth: currentDate()
  //         .endOf("month")
  //         .format("MMMM")
  //     };
  //     data.push(each);

  //     var start = moment(startDate);

  //     const diff = currentDate().diff(start);
  //     const diffDuration = moment.duration(diff);

  //     var totalMonths = Number(diffDuration.asMonths()) | 0;
  //     if (totalMonths < 3) {
  //       var i: number = 1;
  //       for (i = 1; i < 3; i++) {
  //         each = {
  //           index: i + 1,
  //           label: currentDate()
  //             .subtract(i, "months")
  //             .endOf("month")
  //             .format("MMMM YYYY"),
  //           month:
  //             currentDate()
  //               .subtract(i, "months")
  //               .month() + 1,
  //           year: currentDate()
  //             .subtract(i, "months")
  //             .year(),
  //           noOfDays: currentDate()
  //             .subtract(i, "months")
  //             .endOf("month")
  //             .date(),
  //           labelMonth: currentDate()
  //             .subtract(i, "months")
  //             .endOf("month")
  //             .format("MMMM")
  //         };
  //         data.push(each);
  //       }
  //     } else {
  //       var i: number = 1;
  //       for (i = 1; i <= totalMonths; i++) {
  //         var each = {
  //           index: i + 1,
  //           label: currentDate()
  //             .subtract(i, "months")
  //             .endOf("month")
  //             .format("MMMM YYYY"),
  //           month:
  //             currentDate()
  //               .subtract(i, "months")
  //               .month() + 1,
  //           year: currentDate()
  //             .subtract(i, "months")
  //             .year(),
  //           noOfDays: currentDate()
  //             .subtract(i, "months")
  //             .endOf("month")
  //             .date(),
  //           labelMonth: currentDate()
  //             .subtract(i, "months")
  //             .endOf("month")
  //             .format("MMMM")
  //         };
  //         data.push(each);
  //       }
  //     }

  //     setMonthOptions(data);
  //   };

  React.useEffect(() => {
    console.log("Call Ticket Count from API .....");

    api.whoami().then((response: any) => {
      //   if (response !== undefined) {
      //     let onboardDate: any = response.created_date_ft;
      //     //onboardDate = '2019-09-17 12:58:53';
      //     // getMonthRange(onboardDate);
      //   }
    });

    // const User = api.getUserInfo();
    const companyId: string | null = whoAmI?.company_id ?? "";
    const pName: string | null = whoAmI?.company_name ?? "";

    if (companyId !== null) {
      setCompanyId(companyId);
    }

    if (pName !== null) {
      setPartnerName(pName);
    }

    getTicketDatabyAPI(pName);
    getTicketData(pName);

    const partnerId = companyId;
    defaultChartData(partnerId);

    setTimeout(() => {
      getSummaryData(partnerId, pName);
    }, 1000);

    //Run the worker
    // if (checkWorkerSupport()) {
    // 	const [ ticketWorker ] = WebWorker([ TicketWorker ]);

    // 	const token: string = localStorage.getItem('token') || '';
    // 	const companyName: string = pName !== null ? pName : '';
    // 	const customer: string = localStorage.getItem('customer') || '';

    // 	ticketWorker.postMessage({ type: 'START', partner: companyName, customer: customer, token: token });

    // 	ticketWorker.addEventListener('message', function(currentWorker: MessageEvent) {
    // 		console.log(currentWorker.data);
    // 		const message: any = currentWorker.data;
    // 		if (typeof message.status !== 'undefined' || message.status) {
    // 			// Get ticket data when the tickets gets synced with IDB
    // 			getTicketData(pName);
    // 		} else {
    // 			// Should get the data from API
    // 		}
    // 	});

    // 	ticketWorker.addEventListener('error', function(e: ErrorEvent) {
    // 		console.log('ERROR: Line ', e.lineno, ' in ', e.filename, ': ', e.message);
    // 	});

    // 	// Stop when going out from the Ticket worker
    // 	return () => {
    // 		ticketWorker.postMessage('STOP');
    // 	};
    // } else {
    // 	// Should get the data from API
    // 	getTicketDatabyAPI(pName);
    // }
  }, []); // empty array ensures the effect is ran only once on component mount

  const getTicketData = async (partnerId: any) => {
    let temp: Array<Ticket> = [];
    let ticketByPartner: Array<Ticket> = [];
    let NewTickets: Array<Ticket> = [];
    let InProcessTickets: Array<Ticket> = [];
    let openticket: number = 0;
    if (checkWorkerSupport()) {
      temp = await getAllTicketsIDB();
    }

    ticketByPartner = temp.filter(obj => obj.AccountName === partnerId);
    NewTickets = ticketByPartner.filter(obj => obj.State === "New");
    InProcessTickets = ticketByPartner.filter(obj => obj.State === "Progress");
    //openticket = NewTickets.length + InProcessTickets.length;
    openticket = NewTickets.length;

    setOpenTickets(NewTickets.length);
    setTotalTickets(ticketByPartner.length);

    //setTicketDataInitialized(true);
  };

  const getTicketDatabyAPI = (partnerId: any) => {
    partner_api
      .getTicketCountByPartnerId(partnerId)
      .then(res => {
        constructTicketData(res);
      })
      .catch(err => {
        console.log(err);
        setTotalTickets(0);
        setOpenTickets(0);
      });
    //setTicketDataInitialized(true);
  };

  const constructTicketData = (res: DTicket) => {
    let obj = res.data;
    let new_ticket = obj.TotalNewCount === undefined ? 0 : obj.TotalNewCount;
    let inProgress_ticket =
      obj.TotalInProgressCount === undefined ? 0 : obj.TotalInProgressCount;
    let resolved_ticket =
      obj.TotalResolvedCount === undefined ? 0 : obj.TotalResolvedCount;

    let total = inProgress_ticket + new_ticket + resolved_ticket;

    setTotalTickets(total);
    setOpenTickets(new_ticket);

    // Adding Cusomer List to local storage
    storage.setItem("CustomerCountList", JSON.stringify(obj.CustomerCountList));
  };

  const getSummaryData = (partnerId: any, partnerName: any) => {
    partner_api
      .getDashboardSummary(partnerId)
      .then(res => {
        setSummaryData(res, partnerName);
        setSummaryDataInitialized(true);
      })
      .catch(err => {
        console.log(err);
        setTotalBuckets(0);
        setTotalUsage(convertFileSize(0));
        //setTenant_summaryData([]);
        setSummaryDataInitialized(true);
      });
  };

  const getAllTicketsIDB = async () => {
    try {
      let tickets = await idbHelper.__retriveTicketsIDB();
      return Promise.resolve(tickets);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const setSummaryData = (res: dashboardsummary, partnerName: any) => {
    if (res.partner_summary) {
      setPartner_summary(res.partner_summary);
    }

    if (res.tenant_summary) {
      let obj: tenant_summary[] = [];
      let strCustomerTicket: any =
        storage.getItem("CustomerCountList") !== null
          ? storage.getItem("CustomerCountList")
          : "";
      let objCustomerTickets: CustomerCountList[] = [];
      try {
        objCustomerTickets =
          strCustomerTicket !== "" ? JSON.parse(strCustomerTicket) : [];
      } catch (error) {}

      let temp = res.tenant_summary;

      try {
        setTotalCustomers(temp !== undefined ? temp.length : 0);
      } catch (error) {}

      temp.map(async d => {
        setLoadingCustTicket(true);
        let data: tenant_summary = d;

        // let allTickets: Array<Ticket> = [];
        // if (checkWorkerSupport()) {
        // 	allTickets = await getAllTicketsIDB();
        // }

        // let a = allTickets.filter(
        // 	(obj) => obj.Customer === d.tenant_or_partner_name && obj.State !== 'Resolved'
        // );

        // get data from local storage instead of from IndexedDB
        let a = objCustomerTickets.filter(
          obj => obj.Customer === d.tenant_or_partner_name
        );

        a.map(t => {
          var progressCount: number =
            t.InProgressCount === undefined ? 0 : t.InProgressCount;
          var newCount: number = t.NewCount === undefined ? 0 : t.NewCount;

          data.open_ticket = progressCount + newCount;
        });

        //data.open_ticket = a.length;
        // if (data.usage === undefined) {
        //   data.usage = 0;
        // }

        obj.push(data);
      });

      setTimeout(() => {
        obj.sort((a, b) =>
          a.tenant_or_partner_name > b.tenant_or_partner_name ? 1 : -1
        );

        setTenant_summaryData(obj);
        setLoadingCustTicket(false);
        setTicketDataInitialized(true);
      }, 1000);
    } else {
      setTicketDataInitialized(true);
    }
  };

  const setPartner_summary = (res: partner_summary) => {
    if (res.buckets_count) {
      setTotalBuckets(res.buckets_count);
    } else {
      setTotalBuckets(0);
    }

    if (res.usage) {
      setTotalUsage(convertFileSize(res.usage));
    } else {
      setTotalUsage(convertFileSize(0));
    }
  };

  const getDashboardTrend = (
    startdate: string,
    enddate: string,
    partnerId: any,
    periodicity: string
  ) => {
    setPartnerXData([]);
    setPartnerYData([]);
    setCustomerChartData([]);
    partner_api
      .getDashboardTrend(partnerId, periodicity, startdate, enddate)
      .then(res => {
        getPartnerTrendData(res);
        getTenantTrendData(res);
        const d = getTenantName(res);
        //setTenantLabels(d);
        setDefaultDataInitialized(true);
      })
      .catch(err => {
        console.log(err);
        setDefaultDataInitialized(true);
      });
  };

  const getPartnerTrendData = (res: dashboard_trend) => {
    //setPartnerChartData(generatePartnerChartData(res.partner_trend));

    generatePartnerChartData(res.partner_trend);
  };

  const generatePartnerChartData = (partnertrend: partner_trend[]) => {
    let xdata: any = [];
    let ydata: Array<number> = [];
    const data = partnertrend.map(du => {
      var dateLimit = moment(du.date);
      var now = moment();
      if (now.isAfter(dateLimit)) {
        var chartDate = moment(du.date).format("MMM DD, YYYY");

        if (
          now.format("MMM DD, YYYY") === chartDate &&
          du.usage === undefined
        ) {
          console.log(
            "No data generate yet for " +
              chartDate +
              " and the data is :" +
              du.usage
          );
        } else {
          xdata.push(chartDate);
          let usage = du.usage === undefined ? 0 : du.usage;
          ydata.push(usage);
        }
      }
    });
    const chartData = {
      data: ydata,
      name: xdata
    };

    //ydata.sort((a, b) => a - b);
    setPartnerXData(xdata);
    setPartnerYData(ydata);
    try {
      var total = 0;
      for (var i = 0; i < ydata.length; i++) {
        total += ydata[i] ? ydata[i] : 0;
      }
      var avg = total / ydata.length;

      setPartnerAverageValue(avg);
    } catch (e) {
      console.error(e);
    }
  };

  const getTenantTrendData = (res: dashboard_trend) => {
    generateTenantChartDataNew(res.tenants_trend);
  };

  const generateTenantChartDataNew = (
    tenantstrend: tenants_trend[]
  ): Array<any> => {
    const data = tenantstrend.map(obj => {
      let tname = obj.tenant_name;
      let nData: Array<number> = [];
      obj.tenant_trend.map(t => {
        var dateLimit = moment(t.date);
        var now = moment();
        if (now.isAfter(dateLimit)) {
          var chartDate = moment(dateLimit).format("MMM DD, YYYY");

          if (
            now.format("MMM DD, YYYY") === chartDate &&
            t.usage === undefined
          ) {
            console.log(
              "No data generate yet for Tenant Chart, Date: " +
                chartDate +
                " and the data is :" +
                t.usage
            );
          } else {
            //let usage = t.usage === undefined ? 0 : t.usage;
            let usage: any = t.usage;
            nData.push(Number(usage));
          }
        }
      });
      //nData.sort((a, b) => a - b);
      return {
        name: tname,
        data: nData
      };
    });

    setCustomerChartData(data.sort((a, b) => a.name.localeCompare(b.name)));

    return data;
  };

  function adjustLegend() {
    try {
      var charts: any = Highcharts.charts;

      var count = 0;
      var firstChartMargin = 0;
      var secondChartMargin = 0;

      charts.filter((a: any) => a !== undefined);

      charts.forEach((chart: any) => {
        if (chart !== undefined) {
          if (count == 0) {
            firstChartMargin = chart.marginBottom;
          } else {
            secondChartMargin = chart.marginBottom;
          }

          count++;
        }
      });

      charts.forEach((chart: any) => {
        if (chart !== undefined) {
          if (chart.marginBottom < firstChartMargin) {
            chart.update({
              chart: {
                marginBottom: firstChartMargin
              }
            });
          } else if (chart.marginBottom < secondChartMargin) {
            chart.update({
              chart: {
                marginBottom: secondChartMargin
              }
            });
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  const getTenantName = (res: dashboard_trend) => {
    let arr: string[] = [];

    res.tenants_trend.map(d => {
      arr.push(d.tenant_name);
    });

    return arr;
  };

  const defaultChartData = (partnerId: any) => {
    let label = dateOptions[0].label;
    let newDate = new Date();

    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    dateOptions.map(o => {
      if (o.month === month && o.year === year) {
        label = o.label;
        const month = o.queryString.split("-")[1].slice(0, 2);
        const year = o.queryString.split("-")[0].slice(0, 4);
        let startdate = year + "-" + month + "-" + "01";
        let enddate = year + "-" + month + "-" + o.noOfDays;

        getDashboardTrend(startdate, enddate, partnerId, "DAILY");
        return;
      }
    });

    // setSelectedMonth(label);
  };

  //-------------------------- Chart Selection Buttons ------------------------------
  //   const getCurrentMonthEndDate = () => {
  //     var month: string = (currentDate().month() + 1).toString();
  //     month = String(month).padStart(2, "0");

  //     var year = currentDate().year();
  //     var noOfDays = currentDate()
  //       .endOf("month")
  //       .date();

  //     var endDate = year + "-" + month + "-" + noOfDays;

  //     return endDate;
  //   };
  //   const handleThisMonth = () => {
  //     setIsActiveButton("1");
  //     setShowDaily(true);
  //     // Clear custom range
  //     setDefaultCustomRange();

  //     var month: string = (currentDate().month() + 1).toString();
  //     month = String(month).padStart(2, "0");

  //     var year = currentDate().year();

  //     var startDate = year + "-" + month + "-" + "01";
  //     var endDate = getCurrentMonthEndDate();
  //     setShowDropdownModal(false);
  //     getDashboardTrend(startDate, endDate, companyId, "DAILY");
  //   };

  //   const handleLastThreeMonths = () => {
  //     setIsActiveButton("2");
  //     setShowDaily(false);
  //     // Clear custom range
  //     setDefaultCustomRange();

  //     var lastthreemonths = moment().subtract(2, "months");
  //     var month: string = (lastthreemonths.month() + 1).toString();
  //     month = String(month).padStart(2, "0");

  //     var year = lastthreemonths.year();
  //     var startDate = year + "-" + month + "-01";
  //     var endDate = getCurrentMonthEndDate();
  //     setShowDropdownModal(false);
  //     getDashboardTrend(startDate, endDate, companyId, "MONTHLY");
  //   };

  const [left, setLeft] = React.useState<string>("");

  //   const handleCustomSelection = () => {
  //     if (isActiveButton !== "3") {
  //       setDefaultCustomRange();
  //       generateCustomRangeData();
  //       setIsActiveButton("3");
  //       setShowDaily(false);
  //     }
  //     setShowDropdownModal(true);
  //   };

  //   const [showDaily, setShowDaily] = React.useState<boolean>(false);
  //   const generateCustomRangeData = () => {
  //     setStartMonthText(startMonth);
  //     setEndMonthText(endMonth);

  //     var from: any = monthOptions.find(o => o.label === endMonth);
  //     var to: any = monthOptions.find(o => o.label === startMonth);

  //     var startmonth: string = String(to.month).padStart(2, "0");
  //     var endmonth: string = String(from.month).padStart(2, "0");

  //     var startDate: string = to.year + "-" + startmonth + "-01";
  //     var endDate: string = from.year + "-" + endmonth + "-" + from.noOfDays;

  //     if (startmonth === endmonth) {
  //       setShowDaily(true);
  //       getDashboardTrend(startDate, endDate, companyId, "DAILY");
  //     } else {
  //       setShowDaily(false);
  //       getDashboardTrend(startDate, endDate, companyId, "MONTHLY");
  //     }

  //     //getDashboardTrend(startDate, endDate, companyId, 'MONTHLY');
  //   };

  React.useEffect(() => {
    if (companyId) {
      const format = "YYYY-MM-DD";
      const start = moment(startDate);
      const end = moment(endDate);
      getDashboardTrend(
        start.format(format),
        end.format(format),
        companyId,
        getFrequencyByRange(start, end)
      );
      downloadUsageReport();
      // const firstTimeLoad = !isMounted.current;
      // getUsageMetric(
      //   {
      //     timezone,
      //     from_date: start.format(format),
      //     to_date: end.endOf("month").format(format),
      //     frequency: getFrequencyByRange(start, end)
      //   },
      //   firstTimeLoad
      // );
      // isMounted.current = true;
    }
  }, [selectedOption, companyId]);

  //   const handleApplyClick = () => {
  //     generateCustomRangeData();
  //     setShowDropdownModal(false);
  //   };

  const getUpdatedOptions = () =>
    dateOptions.map(option =>
      option.value === selectedOption.value ? selectedOption : option
    );

  const renderLabelWithTooltip = (
    label: string,
    tooltipId: string,
    description: string
  ) => {
    return (
      <>
        <span className="label pr-1">{label}</span>
        <QuestionCircleFill
          size="16"
          data-for={tooltipId}
          data-tip={description}
        />
        <ReactTooltip
          id={tooltipId}
          effect="solid"
          multiline
          place="bottom"
          className="metric-card-tooltip"
        />
      </>
    );
  };

  const handleChange = (option: IListOption) => {
    setSelectedOption({
      ...value,
      startDate,
      endDate
    });
    SetFequency(getFrequencyByRange(moment(startDate), moment(endDate)));
  };

  const handleCancel = (option: IListOption) => {
    const {
      startDate: prevStartDate,
      endDate: prevEndDate,
      ...restSelectedOption
    } = selectedOption;
    setValue(restSelectedOption);
    setStartDate(prevStartDate);
    setEndDate(prevEndDate);
  };

  const handleValueChange = (option: IListOption) => {
    setValue(option);
    if (option.value === 0) {
      setStartDate(getCurrentMonthStartDate(createdAt || userCreatedAt));
      setEndDate(moment());
    } else if (option.value === 1) {
      setStartDate(getLastMonthsDate(6, createdAt || userCreatedAt));
      setEndDate(moment());
    } else {
      setStartDate(
        selectedOption.value !== option.value
          ? undefined
          : selectedOption.startDate
      );
      setEndDate(
        selectedOption.value !== option.value
          ? undefined
          : selectedOption.endDate
      );
    }
  };

  const handleDateClick = (range: (Moment | undefined)[]) => {
    const custom = dateOptions.find(option => option.isCustom);
    if (custom) {
      setValue(custom);
      setStartDate(range[0]);
      setEndDate(range[1]);
    }
  };

  const rangeOptions = getUpdatedOptions();

  const isDailyUsage = isDaily(
    selectedOption.startDate,
    selectedOption.endDate
  );

  const getFileName = (startDate: string, endDate: string) => {
    const start = moment(startDate);
    const end = moment(endDate);
    const companyShortName = storage.getItem("company_short_name");
    if (isDailyUsage) {
      return `${companyShortName}-${start.format("MMMM-YYYY")}.csv`;
    } else {
      return `${companyShortName}-${start.format("MMMM-YYYY")}-${end.format(
        "MMMM-YYYY"
      )}.csv`;
    }
  };

  const generateBucketUsageData = (bucketUsageList: IBucketUsageResponse[]) => {
    const inputFormat = "YYYY-MM-DD hh:mm:ss";
    const newBucketUsage: IBucketUsage[] = [];

    if (bucketUsageList.length) {
      bucketUsageList.forEach(bucketUsage => {
        newBucketUsage.push({
          time: moment(bucketUsage.time, inputFormat).format(
            isDailyUsage ? "YYYY-MM-DD" : "MMM-YYYY"
          ),
          org_name: bucketUsage?.org_name ?? "",
          usageInByte: bucketUsage.usage ? bucketUsage.usage : "",
          usageInGB: bucketUsage.usage
            ? convertFileSizeInGB(bucketUsage.usage)
            : ""
        });
      });
    }

    setBucketUsageData(newBucketUsage);
  };

  const downloadUsageReport = () => {
    setBucketUsageData([]);
    if (startDate != undefined && endDate != undefined && companyId) {
      let from_date = startDate.format("YYYY-MM-DD hh:mm:ss");
      let to_date = endDate.format("YYYY-MM-DD hh:mm:ss");
      partner_api
        .downloadUsageReport(from_date, to_date, companyId)
        .then((res: IDownloadReport) => {
          generateBucketUsageData(res.records);
        })
        .catch(_err => {});
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="partner-dashboard-wrapper">
          {/* {defaultDataInitialized && summaryDataInitialized && ticketDataInitialized ? ( */}
          {defaultDataInitialized && summaryDataInitialized ? (
            <div>
              <input
                type="hidden"
                ref={input => {
                  setTimeout(() => {
                    adjustLegend();
                  }, 500);
                }}
              />
              {/*<div className="row no-gutter">*/}
              {/* <ResponsiveContainer> */}
              {/*<div*/}
              {/*	className="row"*/}
              {/*	style={{*/}
              {/*		width: '100%',*/}
              {/*		marginRight: '0px',*/}
              {/*		marginLeft: '0px'*/}
              {/*	}}*/}
              {/*>*/}
              <LyveHeading title="Home" subTitle="" />
              <CardDeck style={{ padding: "0px 0px 16px 0px" }}>
                <MetricCard
                  label="Customers"
                  value={totalCustomers}
                  className="custom-metric-card"
                />
                <MetricCard
                  label="Buckets"
                  value={totalBuckets}
                  className="custom-metric-card"
                />
                <MetricCard
                  className="custom-metric-card"
                  label={renderLabelWithTooltip(
                    "Average Usage",
                    "avg-usage",
                    "The average amount of data stored, per day,<br /> from the beginning of the current month to date."
                  )}
                  value={
                    <>
                      {totalUsage.replace(/[a-zA-Z]/g, "")}
                      <span className="small">
                        {totalUsage.replace(/[0-9 .]/g, "")}
                      </span>
                    </>
                  }
                />
                <MetricCard
                  label="Tickets"
                  value={
                    <div className="d-flex align-items-center">
                      {totalTickets}
                      <h5 className="d-inline ml-3">
                        <Badge bsPrefix="lyve-badge"> NEW {openTickets} </Badge>
                      </h5>
                    </div>
                  }
                  className="custom-metric-card"
                />
                {/*<div className="dashboardcard">*/}
                {/*	<div className="card basic dashboard">*/}
                {/*		<div className="kpi title plus">*/}
                {/*			Tickets*/}
                {/*			<br />*/}
                {/*		</div>*/}
                {/*		<div className="iconKPI">*/}
                {/*			<div*/}
                {/*				className="dashboard  ticketIcon"*/}
                {/*				style={{*/}
                {/*					display: 'flex',*/}
                {/*					justifyContent: 'flex-end',*/}
                {/*					width: '40px'*/}
                {/*				}}*/}
                {/*			/>*/}
                {/*		</div>*/}

                {/*		<div className="kpi ticketCt">*/}
                {/*			{totalTickets}*/}
                {/*			<div*/}
                {/*				className="kpi ticketCt total"*/}
                {/*				style={{ display: 'flex', paddingLeft: '40%' }}*/}
                {/*			>*/}
                {/*				<div className="kpi newTicketCt">*/}
                {/*					{' '}*/}
                {/*					<span style={{ paddingLeft: '5px' }}>NEW</span>{' '}*/}
                {/*					<span className="kpi ticketCt total span">{openTickets}</span>*/}
                {/*				</div>*/}
                {/*			</div>*/}
                {/*		</div>*/}
                {/*	</div>*/}
                {/*</div>*/}
              </CardDeck>
              {/*</div>*/}
              {/* </ResponsiveContainer> */}
              {/*</div>*/}
              <CardDeck style={{ padding: "16px 0", flexDirection: "column" }}>
                <PaperCard
                  title={
                    <>
                      <div className="dashboard-datepicker">
                        <div className="dashboard-datepicker-title">
                          <span>Usage Trend</span>
                        </div>
                        <div className="d-flex">
                          <LyveDateRangeSelect
                            startDate={startDate}
                            endDate={endDate}
                            value={value}
                            onChange={handleValueChange}
                            onDateClick={handleDateClick}
                            isSearchable={false}
                            defaultValue={selectedOption}
                            options={rangeOptions}
                            onSubmit={handleChange}
                            onCancel={handleCancel}
                          />
                          <CSVLink
                            data={bucketUsageData}
                            className={`csv-download-btn ${
                              bucketUsageData.length
                                ? ""
                                : "csv-download-btn-disabled"
                            }`}
                            headers={CSVHeaders}
                            filename={getFileName(
                              startDate?.toString() ?? "",
                              endDate?.toString() ?? ""
                            ).toLowerCase()}
                          >
                            Download
                          </CSVLink>
                        </div>
                      </div>
                    </>
                  }
                  titleClass="d-flex justify-content-between align-items-center"
                  body={
                    <div className="row trend">
                      <div className="col-12 col-sm-6">
                        <div className="graph totalUsage">
                          {frequency === UsageFrequency.DAILY ? (
                            <PartnerTrendChart
                              partnerXData={partnerXData}
                              partnerYData={partnerYData}
                              averageValue={partnerAverageValue}
                              periodicity={frequency}
                            />
                          ) : (
                            <PartnerTrendBarChart
                              partnerXData={partnerXData}
                              partnerYData={partnerYData}
                              averageValue={partnerAverageValue}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div
                          className="graph customerUsage"
                          style={{ width: "auto" }}
                        >
                          <CustomerTrendChart
                            partnerXData={partnerXData}
                            customerData={customerChartData}
                            periodicity={frequency}
                          />
                          <div
                            id="customtooltip"
                            style={{
                              textAlign: "left",
                              backgroundColor: "black",
                              width: "20%",
                              opacity: "0"
                            }}
                          >
                            <div
                              style={{
                                color: "white",
                                fontSize: "14px",
                                marginTop: "10px",
                                marginLeft: "10px"
                              }}
                              id="tooltipDate"
                            />
                            <div
                              style={{
                                color: "white",
                                fontSize: "14px",
                                marginLeft: "10px",
                                fontWeight: "bold"
                              }}
                              id="tooltipCust"
                            />
                            <div
                              style={{
                                color: "#84E657",
                                fontWeight: "bold",
                                fontSize: "16px",
                                marginLeft: "10px"
                              }}
                              id="tooltipUsage"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                />
              </CardDeck>
              {/*<div className="row no-gutter">*/}
              {/*  <div className="col-12 col-sm-12">*/}
              {/*    <div className="card basic">*/}
              {/*      <div className="row">*/}
              {/*        <div className="col">*/}
              {/*          <div className="dashboard usage"> Usage Trend</div>*/}
              {/*        </div>*/}
              {/*        <div className="col-auto">*/}
              {/*          <button*/}
              {/*            type="button"*/}
              {/*            className={`btn ${*/}
              {/*              isActiveButton === "1" ? "btn-success" : "btn-light"*/}
              {/*            } btnBaseColor metric`}*/}
              {/*            onClick={handleThisMonth}*/}
              {/*          >*/}
              {/*            This Month*/}
              {/*          </button>*/}
              {/*          <button*/}
              {/*            type="button"*/}
              {/*            className={`btn ${*/}
              {/*              isActiveButton === "2" ? "btn-success" : "btn-light"*/}
              {/*            } btnBaseColor metric`}*/}
              {/*            onClick={handleLastThreeMonths}*/}
              {/*          >*/}
              {/*            Last 3 Months*/}
              {/*          </button>*/}
              {/*          <button*/}
              {/*            id="btnCustomRange"*/}
              {/*            type="button"*/}
              {/*            className={`btn ${*/}
              {/*              isActiveButton === "3" ? "btn-success" : "btn-light"*/}
              {/*            } btnBaseColor metric`}*/}
              {/*            onClick={handleCustomSelection}*/}
              {/*          >*/}
              {/*            <span className="custom-month-range-wrapper">*/}
              {/*              Custom Range*/}
              {/*              {isActiveButton === "3" && (*/}
              {/*                <span className="custom-month-range">*/}
              {/*                  :{" "}*/}
              {/*                  <span className="from-month">*/}
              {/*                    {startMonthText}*/}
              {/*                  </span>{" "}*/}
              {/*                  <span className="sep-hyphen">-</span>{" "}*/}
              {/*                  <span className="to-month">{endMonthText}</span>*/}
              {/*                </span>*/}
              {/*              )}*/}
              {/*            </span>*/}
              {/*          </button>*/}
              {/*        </div>*/}
              {/*      </div>*/}

              {/*  <div className="row" />*/}
              {/*  <div className="row trend">*/}
              {/*    <div className="col-12 col-sm-6">*/}
              {/*      /!* <div className="cardTitle">Total Usage Trend</div> *!/*/}
              {/*      <div className="graph totalUsage">*/}
              {/*        {isActiveButton === "1" || showDaily ? (*/}
              {/*          <PartnerTrendChart*/}
              {/*            partnerXData={partnerXData}*/}
              {/*            partnerYData={partnerYData}*/}
              {/*            averageValue={partnerAverageValue}*/}
              {/*            periodicity={*/}
              {/*              isActiveButton === "1" || showDaily*/}
              {/*                ? "DAILY"*/}
              {/*                : "MONTHLY"*/}
              {/*            }*/}
              {/*          />*/}
              {/*        ) : (*/}
              {/*          <PartnerTrendBarChart*/}
              {/*            partnerXData={partnerXData}*/}
              {/*            partnerYData={partnerYData}*/}
              {/*            averageValue={partnerAverageValue}*/}
              {/*          />*/}
              {/*        )}*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div className="col-12 col-sm-6">*/}
              {/*      /!* <div className="cardTitle">Customer Usage Trend</div> *!/*/}
              {/*      <div*/}
              {/*        className="graph customerUsage"*/}
              {/*        style={{ width: "auto" }}*/}
              {/*      >*/}
              {/*        <CustomerTrendChart*/}
              {/*          partnerXData={partnerXData}*/}
              {/*          customerData={customerChartData}*/}
              {/*          periodicity={*/}
              {/*            isActiveButton === "1" || showDaily*/}
              {/*              ? "DAILY"*/}
              {/*              : "MONTHLY"*/}
              {/*          }*/}
              {/*        />*/}
              {/*        <div*/}
              {/*          id="customtooltip"*/}
              {/*          style={{*/}
              {/*            textAlign: "left",*/}
              {/*            backgroundColor: "black",*/}
              {/*            width: "20%",*/}
              {/*            opacity: "0"*/}
              {/*          }}*/}
              {/*        >*/}
              {/*          <div*/}
              {/*            style={{*/}
              {/*              color: "white",*/}
              {/*              fontSize: "14px",*/}
              {/*              marginTop: "10px",*/}
              {/*              marginLeft: "10px"*/}
              {/*            }}*/}
              {/*            id="tooltipDate"*/}
              {/*          />*/}
              {/*          <div*/}
              {/*            style={{*/}
              {/*              color: "white",*/}
              {/*              fontSize: "14px",*/}
              {/*              marginLeft: "10px",*/}
              {/*              fontWeight: "bold"*/}
              {/*            }}*/}
              {/*            id="tooltipCust"*/}
              {/*          />*/}
              {/*          <div*/}
              {/*            style={{*/}
              {/*              color: "#84E657",*/}
              {/*              fontWeight: "bold",*/}
              {/*              fontSize: "16px",*/}
              {/*              marginLeft: "10px"*/}
              {/*            }}*/}
              {/*            id="tooltipUsage"*/}
              {/*          />*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  {showDropdownModal && (*/}
              {/*    <MonthDropDownPrompt*/}
              {/*      startMonth={startMonth}*/}
              {/*      setStartMonth={setStartMonth}*/}
              {/*      endMonth={endMonth}*/}
              {/*      setEndMonth={setEndMonth}*/}
              {/*      dateOptions={monthOptions}*/}
              {/*      handleApplyClick={handleApplyClick}*/}
              {/*      showDropdownModal={showDropdownModal}*/}
              {/*      setShowDropdownModal={setShowDropdownModal}*/}
              {/*    />*/}
              {/*  )}*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*</div>*/}

              {/*<div />*/}

              {/*<div className="row no-gutter">*/}
              {/*  <div className="col-12 col-sm-12">*/}
              {/*    <div className="card basic">*/}
              {/*      <div className="custDist title">*/}
              {/*        <div className="cardTitle">Customer Distributions</div>*/}
              {/*        <CustomerDetails customerlist={Tenant_summaryData} />*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <p>&nbsp;</p>*/}
              {/*  <p>&nbsp;</p>*/}
              {/*</div>*/}
              <CardDeck
                style={{ padding: "16px 0px 0px 0px", flexDirection: "column" }}
              >
                <PaperCard
                  title={
                    <div style={{ fontWeight: 300 }}>
                      Current Customer Distributions
                    </div>
                  }
                  body={<CustomerDetails customerlist={Tenant_summaryData} />}
                  className="customer-distribution-card"
                />
              </CardDeck>
            </div>
          ) : (
            <div className="col">
              <div className="row align-items-center" style={{ height: 600 }}>
                <div className="col text-center">
                  <div
                    className="spinner-border"
                    style={{ color: "#6ebe49" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    clearMonthRange: () => dispatch(MonthPickerActions.clearMonthRange())
  };
};

type MetriOwnProps = ReturnType<typeof mapDispatchToProps>;

export default connect(null, mapDispatchToProps)(PartnerMetric);

function test(o: {
  label: string;
  month: number;
  year: number;
  queryString: string;
  noOfDays: number;
}): React.ReactNode {
  return o.label;
}
