import React from "react";
import ConfigureSAML from "./components/ConfigureSAML";
import ViewSAML from "./components/ViewSAML";

const SAMLFederation: React.FC = () => {
  const [screen, setScreen] = React.useState<"view" | "configure">("view");
  const [configMode, setConfigMode] = React.useState<"upload" | "update">(
    "upload"
  );

  if (screen === "configure") {
    return (
      <ConfigureSAML
        mode={configMode}
        onCancelClick={() => setScreen("view")}
      />
    );
  }

  return (
    <ViewSAML
      onConfigureClick={(isConfigured: boolean) => {
        if (isConfigured) {
          setConfigMode("update");
        } else {
          setConfigMode("upload");
        }
        setScreen("configure");
      }}
    />
  );
};

export default SAMLFederation;
