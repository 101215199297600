import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { AppState } from "../../store";
import { NotificationState } from "./types";
import { getNotification } from "./selectors";
import * as notificationActions from "./actions";
import {ExclamationTriangle, ExclamationTriangleFill} from "react-bootstrap-icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      fontSize: 13,
      fontWeight: 500,
      backgroundColor: "#F8D7DA",
      color: "#C21322",
      minWidth: 0,
      minHeight: 35,
      borderRadius: 35,
      border: `1px solid #F5C6CB`,
      padding: theme.spacing(0, 2),
      boxShadow: "none",
      "& .MuiSnackbarContent-message": {
        padding: 0,
      },
    },
    loginContainer: {
      position: "relative",
      top: theme.spacing(-3),
    },
    icon: {
      fontSize: 16,
      color: "#DC1F2E",
      marginRight: theme.spacing(0.5),
    },
  })
);

interface ErrorNotificationProps {
  notification: NotificationState;
}

const ErrorNotification: React.FC<ErrorNotificationProps> = ({
  notification,
}) => {
  const classes = useStyles();

  if (notification.show && notification.error && notification.message !== "") {
    return (
      <Snackbar
        open={true}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <SnackbarContent
          className={clsx(classes.container)}
          message={
            <Grid container alignItems="center" justify="center">
              <ExclamationTriangleFill className={classes.icon} />
              {notification.message}
            </Grid>
          }
        />
      </Snackbar>
    );
  } else {
    return <noscript />;
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    notification: getNotification(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    clearNotification: () => dispatch(notificationActions.clearNotification()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorNotification);
