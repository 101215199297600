import { SubAccountData } from "./types";

export const SAVE_MASTER_ACCOUNT_INFO = "SAVE_MASTER_ACCOUNT_INFO";
export const FETCHING_MASTER_ACCOUNT_INFO = "FETCHING_MASTER_ACCOUNT_INFO";
export const ERROR_MASTER_ACCOUNT_INFO = "ERROR_MASTER_ACCOUNT_INFO";

export const saveMasterAccountInfo = (masterAccount: SubAccountData | null) => ({
  type: SAVE_MASTER_ACCOUNT_INFO,
  payload: masterAccount
});

export const fetchingMasterAccountInfo = () => ({
  type: FETCHING_MASTER_ACCOUNT_INFO
});

export const errorMasterAccountInfo = (error: any) => ({
  type: ERROR_MASTER_ACCOUNT_INFO,
  payload: error
});
