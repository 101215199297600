import React from "react";
import { Dropdown, DropdownProps } from "react-bootstrap";

import "./styles.scss";
import { Placement } from "react-bootstrap/Overlay";
import LyveTooltip from "../../LyveTooltip";
import useClickOutside from "../../../utils/hooks/useClickOutside";

type TooltipProps = {
    text: string
    placement: Placement
    id: string
    enabled: boolean
}

export type LyveDropdownOption = {
    label: React.ReactNode;
    onClick?: (e?: React.MouseEvent) => void;
    active?: boolean;
    hasDivider?: boolean;
    action?: string;
    header?: string | JSX.Element;
    tooltip?: TooltipProps
};

type LyveDropdownProps = {
    trigger?: React.ReactNode;
    id: string;
    options: LyveDropdownOption[];
    className?: string;
    dropdownClass?: string;
    minWidth?: string;
    width?: string;
    hasManualToggle?: boolean;
    manualShow?: boolean;
    parentDropdownClass?: string;
    autoClose?: "outside"
} & DropdownProps;

type OptionProps = {
    option: LyveDropdownOption
    dropdownClass: string | undefined
    index: number
}

const LyveDropdown = React.forwardRef<HTMLElement, LyveDropdownProps>(({
    trigger,
    id,
    options,
    className,
    drop,
    alignRight,
    dropdownClass,
    minWidth,
    onToggle,
    width,
    hasManualToggle,
    manualShow,
    parentDropdownClass,
    autoClose
}, ref) => {
    const clickedOutside = React.useRef(false)

    const dropdownRef: any = React.useRef({ current: '' })
    useClickOutside(ref, () => clickedOutside.current = true)

    const toggle: DropdownProps["onToggle"] = (...args) => {
        if (!autoClose || (autoClose && clickedOutside.current)) {
            onToggle?.(...args)
            clickedOutside.current = false
        }
    }

    return (
        <Dropdown
            className={`${parentDropdownClass || ""}`}
            drop={drop}
            alignRight={alignRight}
            {...(hasManualToggle ? { show: manualShow } : {})}
            onToggle={toggle}
        >
            <Dropdown.Toggle id={id} as={"div"}>
                {trigger}
            </Dropdown.Toggle>

            <Dropdown.Menu
                ref={dropdownRef}
                className={`${className || ""}`}
                style={{ minWidth, width }}
            >
                {options.map((option, index) =>
                    <React.Fragment key={Math.random() + id + index}>
                        {option.hasDivider && <Dropdown.Divider />}
                        {option.header ? (
                            <Dropdown.Header>{option.header}</Dropdown.Header>
                        ) : null}
                        {option.tooltip ? (
                            <LyveTooltip
                                text={option.tooltip.text}
                                placement={option.tooltip.placement}
                                id={option.tooltip.id}
                                enabled={option.tooltip.enabled}
                                defaultShow={true}
                                className="smart-start-popover"
                            >
                                <Dropdown.Item
                                    as="div"
                                    active={option.active}
                                    key={`context-menu-option-${index}`}
                                    //@ts-ignore
                                    onClick={(e: React.MouseEvent) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        if (option.onClick) {
                                            option.onClick(e);
                                        }
                                    }}
                                    className={`${dropdownClass} ${option.action ? 'dropdown-action' : ''}`}
                                >
                                    {option.label}
                                </Dropdown.Item>
                            </LyveTooltip>
                        ) : (
                            <Dropdown.Item
                                as="div"
                                active={option.active}
                                key={`context-menu-option-${index}`}
                                //@ts-ignore
                                onClick={(e: React.MouseEvent) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    if (option.onClick) {
                                        option.onClick(e);
                                    }
                                }}
                                className={`${dropdownClass} ${option.action ? 'dropdown-action' : ''}`}
                            >
                                {option.label}
                            </Dropdown.Item>
                        )}
                    </React.Fragment>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
});

LyveDropdown.defaultProps = {
    alignRight: true,
    dropdownClass: '',
    width: "max-content",
    parentDropdownClass: "",
};

export default LyveDropdown;
