import React, { useState } from "react";
import LyveHeading from "../LyveHeading";
import { Button, Card, Spinner } from "react-bootstrap";
import LyveLink from "../LyveHeadingLink/LyveLink";
import { getLink, linkNames } from "../LyveHeadingLink/LinkUtil";
import Can from "../Can";
import { SETTINGS_EDIT } from "../../authRules";
import ReadOnlyStatus from "../ReadOnlyStatus";
import CustomToggle from "../CustomToggleButton";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import api from "../../services/api";

const ServiceAccountExpiration: React.FC = () => {
  const [saExpired, setSaExpired] = React.useState(false);

  const [editExpiry, setEditExpiry] = React.useState(false);
  const flipEditExpiry = () => setEditExpiry(!editExpiry);

  const [duration, setDuration] = useState("");
  const [unit, setUnit] = useState("days");

  const updateDuration = (e: React.FormEvent<HTMLInputElement>) =>
    setDuration(e.currentTarget.value);

  const updateUnit = (e: React.FormEvent<HTMLSelectElement>) =>
    setUnit(e.currentTarget.value);

  const { current: preVals } = React.useRef({ duration, unit });

  const [processing, setProcessing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    api
      .getServiceAccountExpiry()
      .then(res => {
        setSaExpired(res?.enabled ?? false);
        setEditExpiry(true);
        const durationVal = res?.sa_config_feature?.value || "";
        const unitVal = res?.sa_config_feature?.uom || "days";
        preVals.duration = durationVal;
        preVals.unit = unitVal;
        setDuration(durationVal);
        setUnit(unitVal);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const toggleExpiry = () => {
    if (saExpired) {
      api
        .disableServiceAccountExpiry()
        .then(() => {
          setSaExpired(false);
          setEditExpiry(false);
          const durationVal = "";
          const unitVal = "days";
          preVals.duration = durationVal;
          preVals.unit = unitVal;
          setDuration(durationVal);
          setUnit(unitVal);
        })
        .catch(() => {
          // do something
        });
    } else {
      setSaExpired(true);
    }
  };

  const updateExpiry = (e: React.FormEvent) => {
    e.preventDefault();
    setProcessing(true);
    preVals.duration = duration;
    preVals.unit = unit;

    const data = {
      sa_config_feature: {
        uom: unit,
        value: +duration
      },
      enabled: saExpired
    };

    api.updateServiceAccountExpiry(data);
    setProcessing(false);
    flipEditExpiry();
  };

  const cancelExpiry = () => {
    flipEditExpiry();
    setDuration(preVals.duration);
    setUnit(preVals.unit);
  };

  const isWholePositiveNum = () => {
    const num = +duration;
    const pattern = /^\d+$/;
    return pattern.test(num.toString()) && num > 0;
  };

  const exceedMaxLimit = () => {
    if (
      (+duration > 10 && unit === "years") ||
      (+duration > 3650 && unit === "days")
    ) {
      return true;
    }
  };

  const isSaveDisabled =
    (duration === preVals.duration && unit === preVals.unit) ||
    !isWholePositiveNum() ||
    exceedMaxLimit();

  return (
    <>
      <LyveHeading
        title="Service Account Settings"
        subTitle=""
      />
      <Card className="view-bucket-card" style={{ minWidth: 840 }}>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: 200 }}
          >
            <Spinner
              as="span"
              animation="border"
              variant="primary"
              role="status"
              aria-hidden="true"
              className="mr-1"
            />
          </div>
        ) : (
          <Card.Body>
            <div className="d-flex mb-3">
              <div className="d-flex flex-column">
                <div className="VB-card-title">Service Account Expiration</div>
                <div className="VB-card-description">
                  Set all service accounts with expiration duration to enforce
                  secret rotation.
                  <LyveLink link={getLink(linkNames.SERVICE_ACCOUNT_SETTING)} />
                </div>
              </div>
              <div className="ml-auto">
                <Can
                  perform={SETTINGS_EDIT}
                  no={<ReadOnlyStatus enabled={saExpired} />}
                >
                  <CustomToggle
                    selected={saExpired}
                    toggleSelected={toggleExpiry}
                  />
                </Can>
              </div>
            </div>
            {saExpired && (
              <div>
                <div className="d-flex pt-4">
                  <Form.Label className="VB-card-details-a">
                    Set Duration
                  </Form.Label>
                  {editExpiry && (
                    <Can perform={SETTINGS_EDIT}>
                      <div className="pl-3 mt-n1">
                        <i
                          className="fas fa-edit cursor-pointer"
                          onClick={flipEditExpiry}
                        />
                      </div>
                    </Can>
                  )}
                </div>
                {editExpiry ? (
                  <div>
                    <div className="retention-period">
                      {duration} {unit}
                    </div>
                  </div>
                ) : (
                  <Form onSubmit={updateExpiry}>
                    <div>
                      <InputGroup className="retention-values">
                        <Form.Group
                          controlId="validationFormik103"
                          style={{ width: "50%" }}
                        >
                          <Form.Control
                            type="text"
                            name="duration"
                            onChange={updateDuration}
                            value={duration}
                          />
                        </Form.Group>

                        <Form.Control
                          as="select"
                          name="unit"
                          onChange={updateUnit}
                          value={unit}
                        >
                          <option value="days">Day(s)</option>
                          <option value="years">Year(s)</option>
                        </Form.Control>
                      </InputGroup>
                      <span className=" retention-duration-error">
                        Only whole positive numbers allowed. (10 Years / 3650
                        Days max)
                      </span>
                    </div>
                    <div className="mt-2">
                      <Button
                        type="submit"
                        variant="primary"
                        className="mr-2"
                        disabled={isSaveDisabled}
                      >
                        {processing ? (
                          <div className="d-flex justify-content-center">
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="mr-1"
                            />
                          </div>
                        ) : (
                          "Save"
                        )}
                      </Button>
                      <Button
                        variant="outline-secondary"
                        onClick={cancelExpiry}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                )}
              </div>
            )}
          </Card.Body>
        )}
      </Card>
    </>
  );
};

export default ServiceAccountExpiration;
