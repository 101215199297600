import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import history from '../../history';
import api from '../../services/api';
import logo from '../../images/logo.svg';
import newlogo from '../../images/LyveCloud-CenterAlign.png';
import background from '../../images/bg.png';
import * as notificationActions from '../../components/Notification/actions';
import AgreeTerms from '../Login/components/AgreeTerms';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			alignItems: 'flex-start',
			flexGrow: 1,
			height: '100vh',
			overflow: 'auto',
			backgroundColor: theme.palette.background.default
		},
		container: {
			marginTop: theme.spacing(7.5),
			backgroundImage: `url(${background})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: 'cover'
		},
		paper: {
			width: 456,
			padding: theme.spacing(5, 6)
		},
		logo: {
			width: 150,
			marginBottom: theme.spacing(2.75)
		},
		mainHeading: {
			fontSize: 24,
			lineHeight: '32px',
			fontWeight: 500,
			marginBottom: theme.spacing(3)
		},
		subHeading: {
			display: 'block',
			textAlign: 'center',
			marginBottom: theme.spacing(2.25),
			fontSize: 16
		},
		formControl: {
			width: '100%',
			marginBottom: theme.spacing(2.5)
		},
		inputLabel: {
			fontSize: 18.6,
			lineHeight: '26.6px',
			color: theme.palette.grey[600],
			'&.Mui-focused': {
				color: theme.palette.grey[600]
			},
			'& + .MuiInput-formControl': {
				marginTop: theme.spacing(3.5)
			}
		},
		input: {
			fontSize: 16,
			borderRadius: 3,
			border: '1px solid #ccc',
			padding: '8px 12px',
			'&.Mui-focused': {
				borderColor: '#333'
			},
			'& .MuiInputBase-input': {
				'&:-webkit-autofill::first-line': {
					fontSize: '16px'
				},
				height: '22px',
				lineHeight: '22px',
				padding: 0
			}
		},
		error: {
			fontSize: 11,
			color: theme.palette.error.light,
			marginTop: theme.spacing(0.75)
		},
		buttonContainer: {
			maxWidth: 'none'
		},
		button: {
			display: 'block',
			paddingTop: theme.spacing(0.625),
			paddingBottom: theme.spacing(0.625),
			fontSize: 12,
			fontWeight: 500,
			textTransform: 'capitalize'
		},
		spinner: {
			color: theme.palette.primary.contrastText,
			position: 'relative',
			top: theme.spacing(0.375)
		},
		submitButton: {
			display: 'block',
			paddingTop: theme.spacing(1.25),
			paddingBottom: theme.spacing(1.25),
			fontSize: 14,
			lineHeight: '20px',
			fontWeight: 500,
			//borderColor: "#007bff",
			border: '1px solid #6ebe49',
			backgroundColor: theme.palette.primary.main,
			'&.Mui-disabled': {
				borderColor: theme.palette.primary.main,
				backgroundColor: theme.palette.primary.main,
				color: theme.palette.primary.contrastText,
				opacity: 0.6
			}
		},
		cancelButton: {
			display: 'block',
			paddingTop: theme.spacing(1.25),
			paddingBottom: theme.spacing(1.25),
			fontSize: 14,
			lineHeight: '20px',
			fontWeight: 500,
			borderColor: theme.palette.grey[600],
			color: theme.palette.grey[600],
			'&:hover': {
				borderColor: theme.palette.grey[600],
				color: theme.palette.grey[600],
				backgroundColor: theme.palette.grey[200]
			}
		},
		bottomText: {
			textAlign: 'center',
			marginTop: theme.spacing(1.5),
			marginBottom: theme.spacing(0.75),
			fontSize: 14
		},
		bottomLink: {
			textAlign: 'center',
			fontSize: 13
		},
		copyright: {
			textAlign: 'center',
			marginTop: theme.spacing(4),
			fontSize: 12,
			color: '#adb5bd'
		}
	})
);

interface ResetPasswordProps {
	setNotification: any;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({ setNotification }) => {
	const classes = useStyles();

	// const [organization, setOrganization] = React.useState<string>("");
	const [ email, setEmail ] = React.useState<string>('');
	const [ isFormProcessing, setFormProcessing ] = React.useState<boolean>(false);
	const [ error, setError ] = React.useState<string>('');
	const [ openAgreeTerms, setOpenAgreeTerms ] = React.useState(false);

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setFormProcessing(true);
		setError('');
		api
			.forgotPassword(email)
			.then(() => {
				setFormProcessing(false);
				history.push('/');
				setNotification(`Password reset email sent successfully to ${email}`);
			})
			.catch((err) => {
				setFormProcessing(false);
				setError(err);
			});
	};

	const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		history.push('/');
	};

	const handleOpenAgreeTerms = () => {
		setOpenAgreeTerms(true);
	};

	const handleCloseAgreeTerms = () => {
		setOpenAgreeTerms(false);
	};

	return (
		<div className={classes.root}>
			<Grid className={classes.container} container spacing={2} justify="center" direction="row">
				<Grid container justify="center">
					<img src={newlogo} className={classes.logo} alt="Seagate Lyve Services" />
				</Grid>
				<Paper className={classes.paper}>
					<Grid item xs={12}>
						<Typography className={classes.mainHeading} align="center" variant="h4">
							Reset Password
						</Typography>
						<Typography className={classes.subHeading} variant="body1">
							A link to reset your password will be sent to the email address you provided
						</Typography>
						<form id="reset-password" onSubmit={handleSubmit}>
							{/* <FormControl className={classes.formControl}>
                <InputLabel className={classes.inputLabel} shrink>
                  Organization
                </InputLabel>
                <Input
                  id="txtOrgName"
                  className={classes.input}
                  type="text"
                  value={organization}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setOrganization(e.target.value);
                  }}
                  disableUnderline={true}
                  fullWidth={true}
                />
              </FormControl> */}
							<FormControl className={classes.formControl}>
								<InputLabel className={classes.inputLabel} shrink>
									Email
								</InputLabel>
								<Input
									id="txtEmail"
									className={classes.input}
									type="text"
									value={email}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										setEmail(e.target.value);
									}}
									disableUnderline={true}
									fullWidth={true}
								/>
								{error !== '' && (
									<Typography className={classes.error} color="error">
										{`${error}`}
									</Typography>
								)}
							</FormControl>
							<Grid container className={classes.buttonContainer} spacing={2}>
								<Grid item xs={6}>
									<Button
										type="submit"
										id="btnSubmit"
										form="reset-password"
										disabled={isFormProcessing}
										className={`${classes.button} ${classes.submitButton}`}
										variant="contained"
										color="primary"
										fullWidth={true}
										size="large"
									>
										{isFormProcessing ? (
											<CircularProgress className={classes.spinner} size={14} />
										) : (
											'Submit'
										)}
									</Button>
								</Grid>
								<Grid item xs={6}>
									<Button
										id="btnCancel"
										onClick={handleCancel}
										disabled={isFormProcessing}
										className={`${classes.button} ${classes.cancelButton}`}
										variant="outlined"
										color="primary"
										fullWidth={true}
										size="large"
									>
										Cancel
									</Button>
								</Grid>
							</Grid>
							<Grid item xs={12} className={classes.bottomText}>
								<div>
									<p>
										Looking for more details?
										<Link href="mailto:support.lyvecloud@seagate.com"> Contact Support</Link>
									</p>
								</div>
							</Grid>
						</form>
					</Grid>
				</Paper>
				<Grid container justify="center" className={classes.copyright}>
					Copyright 2020 Seagate Technology LLC
				</Grid>
			</Grid>
			<AgreeTerms show={openAgreeTerms} handleClose={handleCloseAgreeTerms} />
		</div>
	);
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setNotification: (message: string) => dispatch(notificationActions.setNotification(message))
});

export default connect(null, mapDispatchToProps)(ResetPassword);
