import React from "react";
import logo from "../../images/Lyve-Cloud-Logo-Horiz-GRN-nopad-326x24.svg";
import "./styles.scss";

type IOwnProps = {
    heading?: string;
    description?: string;
    visualImage?: string;
};

const DefaultErrorPage: React.FC<IOwnProps> = (props) => {
    return (
        <div className="error-page-wrapper">
            <div className="main-container">
                <div className="logo">
                    <img className="logo-img" alt="Lyve Cloud" src={logo}
                    />
                    <h1 className="logo-heading">Lyve Cloud</h1>
                </div>
                <div className="message">
                    <div className="message-text">
                        <h2 className="message-heading">{props.heading}</h2>
                        <p className="message-description">{props.description}</p>
                    </div>
                    {props.visualImage && <div className="message-visual" style={{ background: `url(${props.visualImage}) right top no-repeat`}}></div>}
                </div>
            </div>
        </div>
    );
};

export default DefaultErrorPage;