import React from "react";
import { CardDeck } from "react-bootstrap";
import { QuestionCircleFill } from "react-bootstrap-icons";
import ReactTooltip from "react-tooltip";
import { BasicCard } from "../../../../../../../../components/Card";
import { convertFileSize } from "../../../../../../../../utils";
import { UsageFrequency } from "../../../../../Metrics/hooks/useUsageMetric";

import UsageGraph from "./UsageGraph";

interface ICostUsage {
  time: string;
  usage?: number;
  cost?: number;
}

interface Subaccount {
  id: string;
  name: string;
  short_name: string;
  admin_first_name: string;
  admin_last_name: string;
  admin_email: string;
  org_name: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  phone_number: string;
  parent_short_name: string;
  is_master: boolean;
  is_enabled: boolean;
  created_date: string;
  start_date: string;
  end_date: string;
  account_type: string;
  sub_account_max_limit: number;
  sub_account_support: boolean;
  sub_account_trial: boolean;
  sub_account_trial_ext_limit: number;
  sub_account_diff_org: boolean;
  view_support_ticket: boolean;
}

interface ChartData {
  name: string;
  data: number[];
}

interface IOwnProps {
  subaccount: Subaccount;
  subaccountXData: any;
  subaccountYData: any;
  frequency: UsageFrequency;
  subaccountUsageList: any;
  isDailyUsage: boolean;
}

const Usage: React.FC<IOwnProps> = ({
  subaccountXData,
  subaccountYData,
  frequency,
  subaccountUsageList,
  isDailyUsage
}) => {
  const renderSubaccountChart = () => {
    return (
      <UsageGraph
        subaccountUsageXData={subaccountXData}
        subaccountUsageYData={[
          {
            name: "Usage",
            data: subaccountYData,
            custom: {
              color: "#1a1a1a",
              symbol: "bubble",
              formatter: (value: number) => {
                const fileSize = convertFileSize(value);
                return fileSize.replace(/kB/, "KB");
              },
              ...(isDailyUsage
                ? {
                    avg:
                      subaccountYData.reduce(
                        (a: number, c: number | null) => a + (c || 0),
                        0
                      ) / (subaccountUsageList?.length || 1)
                  }
                : {}),
              opposite: false,
              legendStyle:
                "display: inline-block; margin-top: 10px; width: 8px; height: 8px; border: 1px solid #ffffff; border-radius: 50%; background-color: #1a1a1a; margin-right: 5px"
            }
          }
        ]}
        subaccountUsageListCount={subaccountUsageList?.length || 0}
        frequency={frequency}
      />
    );
  };

  const renderSubaccountChartTitle = () => {
    return (
      <div className="chart-title-section">
        <div className="label-section">
          {renderLabelWithTooltip(
            "Daily Average Usage",
            "chart",
            "Computed as average of all the daily average usage of the month"
          )}
        </div>
      </div>
    );
  };

  const renderLabelWithTooltip = (
    label: string,
    tooltipId: string,
    description: string
  ) => {
    return (
      <>
        <span className="label pr-1">{label}</span>
        <QuestionCircleFill
          size="16"
          data-for={tooltipId}
          data-tip={description}
        />
        <ReactTooltip
          id={tooltipId}
          effect="solid"
          multiline
          place="bottom"
          className="metric-card-tooltip"
        />
      </>
    );
  };

  return (
    <>
      <CardDeck>
        <BasicCard
          title={renderSubaccountChartTitle()}
          body={renderSubaccountChart()}
        />
      </CardDeck>
    </>
  );
};

export default Usage;
