import React, { useState, useEffect } from "react";
import moment from "moment";
import { BUCKETS_EDIT } from "../../../../../../authRules";
import Can from "../../../../../../components/Can";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { ChevronRight, Delete } from "@material-ui/icons";
import { Bucket, ReplicationRule, ListBucketResponse } from "../../types";
import { Button } from "react-bootstrap";
import AddReplicationRulePrompt from "./AddReplicationRulePrompt";
import DeleteReplicationRulePrompt from "./DeleteReplicationRulePrompt";
import api from "../../../../../../services/api";
import DataTable from "react-data-table-component";
import { ReplicationRules } from "../../types";
import { ModalContainer } from "../../../../../../components/Modal";
import { Region } from "../../index";

import "./styles.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonIcon: {
      marginRight: theme.spacing(0.375)
    },
    deleteIcon: {
      position: "relative",
      bottom: theme.spacing(0.125),
      fontSize: 16
    }
  })
);

export interface Props {
  bucketName: string;
  compliance: boolean;
  region: string;
  regionName: string;
  initialReplicationRules?: ReplicationRules;
  setVersioned: (versioned: boolean) => void;
  regionList: Region[];
  complianceOnCreation: boolean;
  allBucketList: Bucket[];
}

const BucketReplicationRules: React.FC<Props> = (props: Props) => {
  const {
    bucketName,
    compliance,
    region,
    regionName,
    initialReplicationRules,
    setVersioned,
    complianceOnCreation,
    allBucketList
  } = props;

  const classes = useStyles();
  const { showModal } = ModalContainer.useContainer();
  const [buckets, setBuckets] = React.useState<Bucket[]>([]);
  const [replicationRules, setReplicationRules] = React.useState<
    ReplicationRule[]
  >([]);
  const [showAddButton, setShowAddButton] = React.useState(false);

  const sourceBucket: Props = props;

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    constructRulesList(initialReplicationRules);
  }, [initialReplicationRules]);

  React.useEffect(() => {
    constructBucketList();
  }, []);

  React.useEffect(() => {
    let exists = false;
    if (replicationRules.length > 0) {
      exists = replicationRules.some(
        b => b.source_bucket_name === sourceBucket.bucketName
      );
    }
    setShowAddButton(!exists); // Only show button if replication rule doesn't exist
  }, [replicationRules, sourceBucket]);

  const constructBucketList = () => {
    const allOtherBuckets = allBucketList.filter(b => b.name !== bucketName);

    if (complianceOnCreation) {
      const complianceModeBuckets = allOtherBuckets.filter(
        b => b.complianceOnCreation
      );
      setBuckets(complianceModeBuckets);
    } else {
      setBuckets(allOtherBuckets);
    }
  };

  const constructRulesList = (replicationRules?: ReplicationRules) => {
    setLoading(true);
    if (replicationRules === undefined) {
      api
        .getReplicationRules(bucketName)
        .then((res: ReplicationRules) => {
          if (res && res.bucket_replication_list) {
            setReplicationRules(res.bucket_replication_list);
          } else {
            setReplicationRules([]);
          }
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
        });
    } else {
      if (replicationRules && replicationRules.bucket_replication_list) {
        setReplicationRules(replicationRules.bucket_replication_list);
      } else {
        setReplicationRules([]);
      }
      setLoading(false);
    }
  };

  const columns = [
    {
      name: "Source Bucket",
      minWidth: "300px",
      cell: (row: any) => {
        const offset = new Date(row.replication_started).getTimezoneOffset();
        return (
          <div>
            <div title={row.source_bucket_name}>{row.source_bucket_name}</div>
            <div className="card-subtitle mt-1">
              Replication started{" "}
              {moment(row.replication_started)
                .utcOffset(-offset)
                .format("YYYY.MM.DD HH:mm:ss ")}
            </div>
          </div>
        );
      }
    },
    {
      name: "",
      width: "50px",
      cell: (row: any) => {
        return (
          <div>
            <ChevronRight />
          </div>
        );
      }
    },
    {
      name: "Destination Bucket",
      minWidth: "160px",
      maxWidth: "200px",
      cell: (row: any) => (
        <span title={row.target_bucket_name}>{row.target_bucket_name}</span>
      )
    },
    {
      name: "",
      width: "50px",
      cell: (row: any) => {
        return (
          row.source_bucket_name === bucketName && (
            <Can perform={BUCKETS_EDIT}>
              <button
                className="btn replication-delete-button"
                onClick={() => {
                  // setSelectedReplicationRule(row);
                  showDeleteModal(row);
                }}
              >
                <Delete
                  className={`${classes.buttonIcon} ${classes.deleteIcon}`}
                />
              </button>
            </Can>
          )
        );
      }
    }
  ];

  const showAddModal = () => {
    showModal({
      header: false,
      body: closeModal => (
        <AddReplicationRulePrompt
          hideModal={closeModal}
          sourceBucket={sourceBucket}
          bucketName={bucketName}
          region={region}
          regionName={regionName}
          regionList={props.regionList}
          onSuccess={(versioned: boolean) => {
            setVersioned(versioned);
            constructRulesList();
          }}
        />
      ),
      baseProps: { size: "sm" }
    });
  };

  const showDeleteModal = (row: ReplicationRule) => {
    showModal({
      header: false,
      body: closeModal => (
        <DeleteReplicationRulePrompt
          bucketName={bucketName}
          hideModal={closeModal}
          replicationRule={row}
          region={props.region}
          regionName={regionName}
          regionList={props.regionList}
          onSuccess={(versioned: boolean) => {
            setVersioned(versioned);
            constructRulesList();
          }}
        />
      ),
      baseProps: { size: "sm" }
    });
  };

  return (
    <div>
      <div className="d-flex justify-content-between mb-2 replication-rules-card-header">
        <div className="VB-card-title">Replication Rules</div>
        {showAddButton && (
          <Can perform={BUCKETS_EDIT}>
            <Button variant="outline-secondary" onClick={() => showAddModal()}>
              Add Rule
            </Button>
          </Can>
        )}
      </div>
      {loading ? (
        <div className="col-12 d-flex align-items-center justify-content-center mb-2">
          <div
            className="spinner-border"
            style={{ color: "#6ebe49" }}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : replicationRules.length === 0 ? (
        <div className="mb-4" style={{ paddingLeft: "20px" }}>
          No replication set up for this bucket.
        </div>
      ) : (
        <div>
          <DataTable
            className="replication-rules-table"
            columns={columns}
            data={replicationRules}
            selectableRows={false}
            pagination={false}
            noHeader={true}
            highlightOnHover={false}
            noDataComponent={<div />}
            responsive={true}
            customStyles={{
              headCells: {
                style: { backgroundColor: "#fff", padding: "15px 20px" }
              },
              cells: {
                style: {
                  padding: "15px 20px"
                }
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default BucketReplicationRules;
