import React from "react";
import { Spinner } from "react-bootstrap";

import { DefaultButton } from "../../../../../../components/LyveButton";
import api from "../../../../../../services/RegionApis";

import "./styles.scss";
import {useAppSelector} from "../../../../../../store";
import {selectRegionsWithSG} from "../../../../../../state/regionswithsg";

interface DeletePromptProps {
  account: any;
  baseUrl: string;
  closeModal: () => void;
  onSuccess?: (data: any) => void;
  onError?: (err: any) => void;
}

const DeletePrompt: React.FC<DeletePromptProps> = ({
  account,
  baseUrl,
  closeModal,
  onSuccess = () => null,
  onError = () => null
}) => {
  const [error, setError] = React.useState<string>("");
  const [isFormProcessing, setFormProcessing] = React.useState<boolean>(false);
  const {data: regionswithsg} = useAppSelector(selectRegionsWithSG);

  const handleDeleteServiceAccount = (sg_url: string) => {
    setFormProcessing(true);
    api
      .removeServiceAccount(account.id,sg_url)
      .then(data => {
        setFormProcessing(false);
        onSuccess(data);
      })
      .catch(err => {
          if(regionswithsg.length !== 0){
              let index = regionswithsg.map(function(e){
                  return e.url;
              }).indexOf(sg_url);
              if(index == regionswithsg.length-1){
                  setFormProcessing(false);
                  setError(err);
                  onError(err);
              } else {
                  handleDeleteServiceAccount(regionswithsg[index+1].url);
              }
          } else {
              setFormProcessing(false);
              setError(err);
              onError(err);
          }
      });
  };

  return (
    <div className="delete-service-account-module">
      <h4 className="delete-service-account-title">Delete Service Account</h4>
      <h4 className="delete-service-account-title2">
        Are you sure you want to delete this Service Account?
      </h4>

      <p className="delete-service-account-subtitle">
        This action will permanently delete service account. You can't undo this
        action.
      </p>

      <div style={{ marginBottom: 24 }}>
        <label className="service-account-label">Service Account Name</label>
        <p className="service-account-name">{account.name || ""}</p>
        {error !== "" && <p className="validation-error">{error}</p>}
      </div>

      <div>
        <DefaultButton
          className="mr-2"
          variant="danger"
          disabled={isFormProcessing}
          onClick={event => {
          handleDeleteServiceAccount(baseUrl);
          }}
        >
          {isFormProcessing ? (
            <Spinner animation="border" role="status" className="spinner">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            "Yes"
          )}
        </DefaultButton>
        <DefaultButton
          disabled={isFormProcessing}
          onClick={closeModal}
          variant="outline-secondary"
        >
          No
        </DefaultButton>
      </div>
    </div>
  );
};

export default DeletePrompt;
