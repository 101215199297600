import React from "react";
import { Moment } from "moment-timezone";
import { components, SingleValueProps } from "react-select";

import { IListOption } from "./LyveDateRangeSelect";
import { formatDate } from "../../../utils";

interface ISingleValueProps extends SingleValueProps<IListOption> {
  startDate?: Moment;
  endDate?: Moment;
}

const getRangeValue = (startDate: Moment, endDate: Moment) => {
  const formattedStartDate = formatDate(startDate, "MMM YYYY");
  const formattedEndDate = formatDate(endDate, "MMM YYYY");

  return `${formattedStartDate} - ${formattedEndDate}`;
};

const SingleValue = (props: ISingleValueProps) => {
  const { children, startDate, endDate, ...rest } = props;
  const menuIsOpen = props.selectProps.menuIsOpen;

  return (
    <components.SingleValue {...rest}>
      {`${
        !menuIsOpen && rest.data.isCustom
          ? startDate && endDate
            ? getRangeValue(startDate, endDate)
            : children
          : children
      }`}
    </components.SingleValue>
  );
};

export default SingleValue;
