import React from "react";
import LyveCard from "../../../../../../components/Card/LyveCard";
import LyveLink from "../../../../../../components/LyveHeadingLink/LyveLink";
import {
  getLink,
  linkNames
} from "../../../../../../components/LyveHeadingLink/LinkUtil";
import omit from "lodash/omit";
import { InputWithRules } from "../../../../../../components/Input";
import { useController } from "react-hook-form";
import partner_api from "../../../../../../services/Partner_api";
import { asyncDebounce, getValidatedRules } from "../../../../../../utils";
import { useFormContext } from "./FormContext";
import rules from "./rules";
import { callAll } from "../../../../../../utils/functions";
import { FormControlProps } from "react-bootstrap";

declare interface AccountInfoProps extends FormControlProps {
  isAccIdUnique: boolean;
  setIsAccIdUnique: (isUnique: boolean) => void;
}

const AccountInfo: React.FC<AccountInfoProps> = ({
  isAccIdUnique,
  setIsAccIdUnique,
  ...rest
}) => {
  const { control, errors } = useFormContext();

  const { field: accName } = useController({ name: "accName", control });
  const { field: accId } = useController({ name: "accId", control });

  const checkAccIdUnique = async (value: string): Promise<boolean> => {
    try {
      const res = await partner_api.checkShortName(value);
      return !res?.exists;
    } catch {
      return false;
    }
  };

  const debouncedValidator = React.useCallback(
    asyncDebounce(checkAccIdUnique, 300),
    []
  );

  const validateAccId = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value?.trim();
    if (!!value) {
      const isUnique = await debouncedValidator(value);
      setIsAccIdUnique(isUnique);
    } else {
      setIsAccIdUnique(false);
    }
  };

  const accNameRules = getValidatedRules(
    omit(rules, "unique", "matchesLowerAlphaNumericDash", "dash"),
    Object.keys(errors.accName?.types || {})
  );

  const accIdErrors = Object.keys(errors.accId?.types || {});
  if (!isAccIdUnique) {
    accIdErrors.push("unique");
  }
  const accIDRules = getValidatedRules(
    omit(rules, "matchesAlphaNumericSpecialCharacter"),
    accIdErrors
  );

  React.useEffect(() => {
    if (accId) {
      debouncedValidator(accId.value).then(isUnique => setIsAccIdUnique(isUnique))
    }
  }, []);

  return (
    <LyveCard
      title="Account Information"
      subtitle={
        <span>
          An account provides the basis for creating, enabling and using all
          Lyve Cloud services.
          <LyveLink link={getLink(linkNames.CREATE_SUBACCOUNT)} />
        </span>
      }
    >
      <InputWithRules
        id="account-name"
        label="Account Name"
        rules={accNameRules}
        isInvalid={!!errors.accName?.message}
        error={errors.accName?.message}
        {...rest}
        {...omit(accName, "ref")}
      />

      <InputWithRules
        id="account-id"
        label="Account ID"
        tooltip="The management console URL is https://<account-id>.console.lyvecloud.seagate.com"
        rules={accIDRules}
        isInvalid={!!errors.accId?.message}
        error={errors.accId?.message}
        onChange={callAll(accId.onChange, validateAccId)}
        {...rest}
        {...omit(accId, "ref", "onChange")}
      />
    </LyveCard>
  );
};

export default AccountInfo;
