import React from "react";
import orderBy from "lodash/orderBy";
import { Spinner, Row, Col } from "react-bootstrap";
import {
  Pencil,
  Plus,
  Trash,
  Upload,
  ThreeDots,
  X
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import ErrorPrompt from "./components/ErrorPrompt";
import DeletePrompt from "./components/DeletePrompt";
import CreatePrompt from "./components/CreatePrompt";
import CreatePolicyPrompt from "./components/CreatePolicyPrompt";
import { PERMISSIONS_EDIT, PERMISSIONS_VIEW } from "../../../../authRules";
import Can from "../../../../components/Can";
import { Card } from "../../../../components/Card";
import LyveTable from "../../../../components/LyveTable";
import LyveHeadingLink from "../../../../components/LyveHeadingLink";
import { DefaultButton } from "../../../../components/LyveButton";
import { ModalContainer } from "../../../../components/Modal";
import { LyveBadge } from "../../../../components/LyveBadge";
import api from "../../../../services/api";
import regionApis from "../../../../services/RegionApis";
import ReactTooltip from "react-tooltip";
import moment from "moment-timezone";
import ContextMenu from "../../../../components/ContextMenu";
import "./styles.scss";
import { AppState, useAppDispatch, useAppSelector } from "../../../../store";
import {
  loadingPermissions,
  rejectPermissions,
  resolvePermissions,
  selectPermissions
} from "../../../../state/permissions";
import { formatDate } from "../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import * as notificationActions from "./../../../../components/Notification/actions";
import { fetchRegionsWithSG } from "../../../../utils/api";
import {
  loadingRegionsWithSG,
  resolveRegionsWithSG,
  selectRegionsWithSG
} from "../../../../state/regionswithsg";
import { WhoAmI } from "../../../../state/UserInfo/types";
import {
  getLink,
  linkNames
} from "../../../../components/LyveHeadingLink/LinkUtil";
import LyvePopup from "../../../../components/Modal/LyvePopup";

interface BucketData {
  label: string;
  value: string;
  usage: number;
}

interface Bucket {
  name: string;
  usage: number;
}

interface ListBucketResponse {
  buckets: Bucket[];
}

interface Data {
  id: string;
  name: string;
  description: string;
  effect: string;
  status: string;
}

interface Permission {
  id: string;
  name: string;
  slug: string;
  description: string;
  effect: string;
  resources: any[];
  actions: any[];
  type: string;
  creation_date: string;
  SACount: number;
  SAName: Array<any>;
  status: string;
}

interface SelectedPolicy {
  name: string;
  desc: string;
  policy: string;
}

interface ListPermissionResponse {
  permissions: Permission[];
  total: number;
}

interface RegionsWithSG {
  regions: Region[];
}

interface Region {
  name: string;
  url: string;
}

var dateFormat = "YYYY-MM-DD hh:mm A";

const PermissionsPage = () => {
  const { showModal } = ModalContainer.useContainer();
  const whoAmI = useSelector<AppState, WhoAmI>(state => state.userInfo.whoAmI);
  const dispatch = useAppDispatch();
  const notificationDispatch = useDispatch();
  const { data: permissionsList } = useAppSelector(selectPermissions);

  const [showCreatePermModal, setShowCreatePermModal] = React.useState(false);
  const toggleCreatePermModal = () => setShowCreatePermModal(!showCreatePermModal)
  const [selectedPermission, setSelectedPermission] = React.useState<Permission | undefined>();

  const [buckets, setBuckets] = React.useState<BucketData[]>([]);
  const [loadingBuckets, setLoadingBuckets] = React.useState(false);
  const [permissions, setPermissions] = React.useState<Data[]>([]);
  const [permissionsInitialized, setPermissionsInitialized] = React.useState<
    boolean
  >(false);
  const [isAuditor, setIsAuditor] = React.useState<boolean>(false);
  const [showContextMenu, setShowContextMenu] = React.useState<any>(null);
  const [showServiceAccountCard, setshowServiceAccountCard] = React.useState<
    any
  >(null);

  const { data: regionswithsg } = useAppSelector(selectRegionsWithSG);
  const [baseUrl, setBaseUrl] = React.useState<string>("");
  // const [totalRows, setTotalRows] = React.useState<number>(0);
  // const [perPage, setPerPage] = React.useState(10);

  // construct permission objects w/usage field temporarily from frontend
  const constructPermissionList = (res: ListPermissionResponse) => {
    let permissionList: Permission[] = [];
    if (res && res.permissions) {
      //setTotalRows
      // setTotalRows(res?.total)
      // temporarily construct usage field
      permissionList = res.permissions.map(
        (b: Permission): Permission => {
          if (!b.SACount) b.SACount = 0;
          return {
            ...b,
            SACount: b.SACount ? b.SACount : 0
          };
        }
      );
    }
    setPermissions(permissionList);
    dispatch(resolvePermissions([...permissionList]));
  };

  const constructBucketList = (res: ListBucketResponse) => {
    let bucketList: BucketData[] = [];
    if (res && res.buckets) {
      // temporarily construct usage field
      bucketList = res.buckets.map(
        (b: Bucket): BucketData => {
          return { value: b.name, label: b.name, usage: 0 };
        }
      );
    }
    setBuckets(bucketList);
  };

  React.useEffect(() => {
    async function getAllRegionsWithSG() {
      try {
        await fetchRegionsWithSG().then(regionList => {
          let sg_regions = regionList.filter(
            (obj, index, self) =>
              index ===
              self.findIndex(t => t.name === obj.name && t.url === obj.url)
          );
          setBaseUrl(sg_regions.length > 0 ? sg_regions[0].url : "");
          dispatch(resolveRegionsWithSG([...sg_regions]));
        });
      } catch (e) {
        console.log(e);
      }
    }
    if (!!regionswithsg) {
      getAllRegionsWithSG();
    }
  }, []);

  React.useEffect(() => {
    if (whoAmI.role === "auditor") setIsAuditor(true);
    // let shortName: string = userInfo.company_short_name ? userInfo.company_short_name : '';
    let isMounted = true;

    setLoadingBuckets(true)
    api.listBuckets().then(res => {
      if (isMounted) constructBucketList(res);
    }).finally(() => setLoadingBuckets(false));
    dispatch(loadingPermissions());
    api
      .listPermissions()
      .then(res => {
        if (isMounted) {
          constructPermissionList(res);
          setPermissionsInitialized(true);
        }
        //getUserConfigFromLocalStorage(shortName);
      })
      .catch(err => {
        // TODO: Handle errors while listing permissions
        if (isMounted) {
          dispatch(rejectPermissions(err));
          setPermissionsInitialized(true);
        }
      });
    return () => {
      isMounted = false;
    };
  }, []);

  /** Datatable */
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All"
  };

  const checkSchedularTaskStatus = (
    permissionID: string,
    permissionName: string,
    action: string
  ) => {
    api
      .taskStatus(permissionID, "permission")
      .then(data => {
        if (data.status == "Applied") {
          if (action == "CREATE") {
            notificationDispatch(
              notificationActions.setNotification(
                `${data.name} permission is created.`
              )
            );
          }
          if (action == "EDIT") {
            notificationDispatch(
              notificationActions.setNotification(
                `${data.name} permission is updated.`
              )
            );
          }
          api.listPermissions().then(res => {
            constructPermissionList(res);
          });
        }
        // else if(data.status == "DELETED"){
        //   if(action == "DELETE"){
        //     notificationDispatch(
        //         notificationActions.setNotification(
        //             `${data.name} permission is deleted.`
        //         )
        //     );
        //   }
        //   api.listPermissions().then(res => {
        //     constructPermissionList(res);
        //   });
        // }
        else {
          setTimeout(function() {
            checkSchedularTaskStatus(permissionID, permissionName, action);
          }, 5000);
        }
      })
      .catch(err => {
        console.log(err);
        if (err == "Permission deleted or not exist" && action == "DELETE") {
          notificationDispatch(
            notificationActions.setNotification(`${permissionName} ${err}.`)
          );
          api.listPermissions().then(res => {
            constructPermissionList(res);
          });
        }
      });
  };

  const handleCreatePermissionSuccess = (
    data: any,
    permission?: Permission,
    closeModal: () => void = () => null
  ) => {
    closeModal();
    // if (permission && permission.id) {
    //   checkSchedularTaskStatus(permission.id, permission.name, "EDIT");
    // } else {
    //   checkSchedularTaskStatus(data.id, data.name, "CREATE");
    // }
    api.listPermissions().then(res => {
      constructPermissionList(res);
    });
  };

  const handleDeletePermissionSuccess = (
    data: any,
    permission?: Permission,
    closeModal: () => void = () => null
  ) => {
    closeModal();
    // permission &&
    //   checkSchedularTaskStatus(permission.id, permission.name, "DELETE");
    api.listPermissions().then(res => {
      constructPermissionList(res);
    });
  };

  const handleCreatePolicyPermissionSuccess = (
    data: any,
    permission?: Permission,
    closeModal: () => void = () => null
  ) => {
    closeModal();
    // if (permission && permission.id) {
    //   checkSchedularTaskStatus(permission.id, permission.name, "EDIT");
    // } else {
    //   checkSchedularTaskStatus(data.id, data.name, "CREATE");
    // }
    api.listPermissions().then(res => {
      constructPermissionList(res);
    });
  };

  // For toggling create permission modal
  const showCreatePermissionModal = (selectedPermission?: Permission) => {
    setShowCreatePermModal(true)
    setSelectedPermission(selectedPermission)
  };

  // For toggling delete permission modal
  const showDeletePermissionModal = (selectedPermission: Permission) => {
    showModal({
      body: closeModal => (
        <DeletePrompt
          permission={selectedPermission}
          baseUrl={baseUrl}
          closeModal={closeModal}
          onSuccess={data =>
            handleDeletePermissionSuccess(data, selectedPermission, closeModal)
          }
        />
      ),
      baseProps: { size: "sm" }
    });
  };

  const showCreatePolicyPermissionModal = (
    selectedPermission?: Permission,
    selectedPolicy?: SelectedPolicy
  ) => {
    showModal({
      body: closeModal => (
        <CreatePolicyPrompt
          permission={selectedPermission}
          permissions={permissions}
          selectedPolicy={selectedPolicy}
          baseUrl={baseUrl}
          onSuccess={data =>
            handleCreatePolicyPermissionSuccess(
              data,
              selectedPermission,
              closeModal
            )
          }
        />
      ),
      baseProps: { size: "sm" }
    });
  };

  const getPolicyPermissionWithId = (
    id: string,
    selectedPermission: Permission,
    sg_url: string
  ) => {
    regionApis
      .getPolicyPermissionwithID(id, sg_url)
      .then(res => {
        showCreatePolicyPermissionModal(selectedPermission, res);
      })
      .catch(err => {
        if (err.includes("Service is currently unavailable.")) {
          showErrorPrompt("Edit Error", err);
        } else if (regionswithsg.length !== 0) {
          let index = regionswithsg
            .map(function(e) {
              return e.url;
            })
            .indexOf(sg_url);
          if (index == regionswithsg.length - 1) {
            showErrorPrompt(
              "Edit Error",
              "Policy Edit failed. Please try agian later."
            );
          } else {
            getPolicyPermissionWithId(
              id,
              selectedPermission,
              regionswithsg[index + 1].url
            );
          }
        } else {
          showErrorPrompt(
            "Edit Error",
            "Policy Edit failed. Please try agian later."
          );
        }
      });
  };
  // For showing Error message on delete permission with service modal attached
  const showErrorPrompt = (title: string, content: string) => {
    showModal({
      body: closeModal => (
        <ErrorPrompt closeModal={closeModal} title={title} content={content} />
      ),
      baseProps: { size: "sm" }
    });
  };

  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      maxWidth: "250px",
      cell: (row: Permission) => <span title={row.name}>{row.name}</span>
    },
    {
      name: "Description",
      selector: "description",
      sortable: true,
      cell: (row: Permission) => (
        <span title={row.description}>{row.description}</span>
      )
    },
    {
      name: "Type",
      selector: "type",
      maxWidth: "200px",
      sortable: true,
      cell: (row: Permission) => <span title={row.type}>{row.type}</span>
    },
    {
      name: (
        <div className="tooltipText">
          Service Accounts
          <span
            data-for="avg"
            className="badge badge-dark f-tooltip-bagde light ml-2"
            data-tip="The number of service accounts to which this permission<br /> is attached. Hover over the number to see<br /> the names of the attached service accounts."
          >
            ?
          </span>
          <ReactTooltip
            id="avg"
            place="bottom"
            multiline
            effect="solid"
            className="f-tooltip"
          />
        </div>
      ),
      center: true,
      selector: "SACount",
      width: "200px",
      sortable: true,
      cell: (row: Permission, index: number) => {
        return (
          <>
            <div className="d-flex justify-content-center">
              <LyveBadge
                variant="light"
                pill
                className="serviceAccount-badge"
                // @ts-ignore
                onMouseEnter={() => {
                  if (row.SACount > 0) openServiceAccountDetails(index);
                }}
              >
                {row.SACount}
              </LyveBadge>
            </div>
            {showServiceAccountCard === index
              ? serviceAccountDetails(row.SAName ?? [])
              : null}
          </>
        );
      }
    },
    {
      name: "Created On",
      selector: "creation_date",
      width: "180px",
      sortable: true,
      cell: (row: Permission) => (
        <span
          title={moment
            .utc(row.creation_date)
            .local()
            .format(dateFormat)}
        >
          {moment
            .utc(row.creation_date)
            .local()
            .format(dateFormat)}
        </span>
      )
    },
    {
      name: "",
      width: "100px",
      right: true,
      cell: (row: Permission, index: number) => {
        return (
          <>
            {row.type === "Policy Permission" && isAuditor && (
              <Can perform={PERMISSIONS_VIEW}>
                <div className="tooltipText">
                  <DefaultButton
                    variant="outline-secondary"
                    size="sm"
                    data-for="download"
                    data-tip="Download policy file in JSON format"
                    onClick={() => handleDownload(row.id, baseUrl)}
                  >
                    Download
                  </DefaultButton>
                  <ReactTooltip
                    id="download"
                    place="left"
                    effect="solid"
                    className="f-tooltip"
                  />
                </div>
              </Can>
            )}
            <Can perform={PERMISSIONS_EDIT}>
              <ContextMenu
                id="users"
                trigger={
                  showContextMenu === index ? (
                    <X
                      className="btn btn-icon table-close-icon"
                      onClick={() => closeContextMenu(index)}
                    />
                  ) : (
                    <ThreeDots
                      className="threedot-icon btn btn-icon"
                      onClick={() => openContextMenu(index)}
                    />
                  )
                }
                parentDropdownClass="table-menu"
                drop={"down"}
                hasManualToggle={true}
                manualShow={showContextMenu === index}
                onToggle={(isOpen, _event, _meta) => {
                  if (!isOpen) {
                    setShowContextMenu(null);
                  }
                }}
                minWidth="200px"
                options={getUserActions(row)}
              />
            </Can>
          </>
        );
      }
    }
  ];

  const openContextMenu = (index: any) => {
    if (index === showContextMenu) setShowContextMenu(null);
    else setShowContextMenu(index);
  };

  const closeContextMenu = (index: any) => {
    if (index === showContextMenu) setShowContextMenu(null);
    else setShowContextMenu(index);
  };

  const getUserActions = (row: Permission) => {
    if (row.type === "Policy Permission") {
      const options: any = [
        {
          label: (
            <span>
              <Row className="context-menu-item">
                <Col>
                  <Pencil size="16" className="edit-icon" />
                </Col>
                <Col>Edit</Col>
              </Row>
            </span>
          ),
          onClick: (event: any) => {
            event.preventDefault();
            closeContextMenu(null);
            getPolicyPermissionWithId(row.id, row, baseUrl);
          }
        },
        {
          label: (
            <span>
              <Row className="context-menu-item">
                <Col>
                  <Trash size="16" className="delete-icon" />
                </Col>
                <Col>Delete</Col>
              </Row>
            </span>
          ),
          onClick: (event: any) => {
            event.preventDefault();
            closeContextMenu(null);
            if (row.SACount > 0) {
              showErrorPrompt(
                "Delete Permission",
                "This permission cannot be deleted since it is attached to a service account."
              );
            } else {
              showDeletePermissionModal(row);
            }
          }
        }
      ];
      return options;
    } else {
      const options: any = [
        {
          label: (
            <span>
              <Row className="context-menu-item">
                <Col>
                  <Pencil size="16" className="edit-icon" />
                </Col>
                <Col>Edit</Col>
              </Row>
            </span>
          ),
          onClick: (event: any) => {
            event.preventDefault();
            closeContextMenu(null);
            showCreatePermissionModal(row);
          }
        },
        {
          label: (
            <span>
              <Row className="context-menu-item">
                <Col>
                  <Trash size="16" className="delete-icon" />
                </Col>
                <Col>Delete</Col>
              </Row>
            </span>
          ),
          onClick: (event: any) => {
            event.preventDefault();
            closeContextMenu(null);
            if (row.SACount > 0) {
              showErrorPrompt(
                "Delete Permission",
                "This permission cannot be deleted since it is attached to a service account."
              );
            } else {
              showDeletePermissionModal(row);
            }
          }
        }
      ];
      return options;
    }
  };

  // const creationDateFormat = (date: string) => {
  //   const stripped = date.replace(/ \+0000/g, ""); //for firefox browser
  //   var stillUtc = moment.utc(stripped).toDate();
  //   if (date && stillUtc)
  //     return moment(stillUtc)
  //       .local()
  //       .format("MMM DD, YYYY h:mm A");
  //   return "NA";
  // };

  const handleDownload = (id: string, sg_url: string) => {
    regionApis
      .getPolicyPermissionwithID(id, sg_url)
      .then(response => {
        const policyContent = response.policy;
        const fileName = response.name;
        const element = document.createElement("a");
        const file = new Blob([policyContent], { type: "application/json" });
        element.href = URL.createObjectURL(file);
        element.download = fileName;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
      })
      .catch(err => {
        console.log(err);
        if (regionswithsg.length !== 0) {
          let index = regionswithsg
            .map(function(e) {
              return e.url;
            })
            .indexOf(sg_url);
          if (index == regionswithsg.length - 1) {
            showErrorPrompt(
              "Download Error",
              "File download failed. Please try again later."
            );
          } else {
            handleDownload(id, regionswithsg[index + 1].url);
          }
        } else {
          showErrorPrompt(
            "Download Error",
            "File download failed. Please try again later."
          );
        }
      });
  };

  const openServiceAccountDetails = (index: any) => {
    if (index === showServiceAccountCard) setshowServiceAccountCard(null);
    else setshowServiceAccountCard(index);
  };

  const getServiceAccountCardText = (data: Array<any>) => {
    let serviceAccountCardText = data?.map((accounts: any, key: number) => {
      return (
        <div key={key}>
          <p>{accounts}</p>
          {key + 1 === data?.length ? null : <hr />}
        </div>
      );
    });
    return serviceAccountCardText;
  };

  const serviceAccountDetails = (data: Array<any>) => (
    <div
      className="serviceAccountCard"
      onMouseLeave={() => {
        setshowServiceAccountCard(null);
      }}
    >
      <div
        className="serviceAccountBody"
        style={{ backgroundColor: "#ffffff" }}
      >
        {getServiceAccountCardText(data)}
      </div>
    </div>
  );

  const customSort = (
    rows: Data[],
    field: string,
    direction: "desc" | "asc"
  ) => {
    const handleField = (row: any) => {
      const val = row[field];
      if (val && typeof val === "string") {
        return val.toLowerCase();
      }
      return val;
    };

    return orderBy(rows, handleField, direction);
  };

  const noPermissionsCreated = () => {
    return permissionsInitialized && permissions.length === 0;
  };

  const getPermissionsData = () => {
    return permissions.filter(
      permissionData => permissionData.effect?.toLowerCase() !== "deny"
      //&& permissionData.status?.toLowerCase() !== "pending"
    );
  };

  const NoData = (
    <table className="table empty-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>Type</th>
          <th>Service Accounts</th>
          <th>Created On</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={7}>There are no permissions.</td>
        </tr>
      </tbody>
    </table>
  );

  //** in case need in future */
  // const handlePageChange = (page:number) =>{
  //   fetchPermission(perPage,(page-1)*perPage)
  // }

  // const fetchPermission = (limit:number,offset:number) =>{
  //   api.listPermissions(limit,offset).then(res => {
  //     constructPermissionList(res);
  //   });
  // }

  // const handlePerRowsChange = (perPage: number) =>{
  //   setPerPage(perPage)
  //   fetchPermission(perPage,perPage*0)
  // }

  return (
    <div className="permissions-container">
      <LyvePopup
        show={showCreatePermModal}
        onHide={toggleCreatePermModal}
        baseProps={{ size: "sm", dialogClassName: "create-perm-popup" }}
      >
        {(closeModal: any) => (
          <CreatePrompt
            items={buckets}
            itemsLoading={loadingBuckets}
            permission={selectedPermission}
            permissions={permissions}
            baseUrl={baseUrl}
            onSuccess={data =>
              handleCreatePermissionSuccess(data, selectedPermission, closeModal)
            }
          />
        )}
      </LyvePopup>
      {permissionsInitialized ? (
        <>
          <LyveHeadingLink
            title="Permissions"
            subTitle="Manage access permissions to Lyve Cloud buckets."
            helpLink={getLink(linkNames.Permissions)}
          >
            <Can perform={PERMISSIONS_EDIT}>
              <DefaultButton
                variant="primary"
                className="mr-3"
                onClick={() => showCreatePermissionModal()}
              >
                <Plus size="24" className="add-icon" />
                Create Bucket Permission
              </DefaultButton>

              <DefaultButton
                variant="outline-secondary"
                onClick={() => showCreatePolicyPermissionModal()}
              >
                <Upload size="14" className="upload-icon" />
                Create Policy Permission
              </DefaultButton>
            </Can>
          </LyveHeadingLink>
          <div>
            {noPermissionsCreated() ? (
              <Can perform={PERMISSIONS_EDIT} no="There are no permissions.">
                You do not have any permissions. Create one now.
              </Can>
            ) : (
              <LyveTable
                className="permissions-table"
                columns={columns}
                data={getPermissionsData()}
                sortFunction={customSort}
                defaultSortField="creation_date"
                defaultSortAsc={false}
                selectableRows={false}
                pagination={true}
                noHeader={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
                highlightOnHover={true}
                paginationComponentOptions={paginationOptions}
                noDataComponent={NoData}
                paginationIconFirstPage={false}
                paginationIconLastPage={false}
                responsive={true}
                // paginationPerPage={perPage}
                // paginationTotalRows={totalRows}
                // paginationServer
                // onChangePage={handlePageChange}
                // onChangeRowsPerPage={handlePerRowsChange}
              />
            )}
          </div>
        </>
      ) : (
        <div className="data-loader">
          <Spinner animation="border" role="status" className="spinner">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
};

export default PermissionsPage;
