import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import CustomToggle from "../../../../../../components/CustomToggleButton";
import api from "../../../../../../services/api/index";
import regionApis from "../../../../../../services/RegionApis";
import { useAppDispatch, useAppSelector } from "../../../../../../store";
import { selectBuckets } from "../../../../../../state/buckets";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import BucketReplicationRules from "../ReplicationRules/index";
import {
  ViewBucketDetails,
  AuditLogSettings,
  ReplicationRules
} from "../../types";
import DeleteBucketPrompt from "../DeletePrompt";
import { Link } from "react-router-dom";
import { Region, AllRegions } from "../../index";
import { ModalContainer } from "../../../../../../components/Modal";
import ComplianceCard from "./ComplianceCard";
import Can from "../../../../../../components/Can";
import { BUCKETS_EDIT } from "../../../../../../authRules";
import _ from "lodash";
import useSmartStartKit from "../../../../../SmartStartHere/useSmartStartKit";
import LyveTooltip from "../../../../../../components/LyveTooltip";

import "./styles.scss";
// import { useLocation } from "react-router-dom";
import { ListBucketResponse, Bucket } from "../../types";
import { Clipboard } from "react-bootstrap-icons";
import { DefaultButton } from "../../../../../../components/LyveButton";

const ViewBucket = () => {
  const [allBucketList, setAllBucketList] = useState<Bucket[]>([]);
  const pathName = window.location.pathname;
  const defaultAuditSettings: AuditLogSettings = {
    TenantS3APIAuditLogsEnabled: false,
    TenantlogAllBucketsEnabled: false,
    bucketS3AuditLog: false
  };
  const bucketName = pathName.substring(pathName.lastIndexOf("/") + 1);
  const { showModal } = ModalContainer.useContainer();
  const [initialDuration, setInitialDuration] = useState<number>(30);
  const [initialUnit, setInitialUnit] = useState<"Days" | "Years">("Days");
  const [auditLogSetting, setAuditLogSetting] = useState<AuditLogSettings>(
    defaultAuditSettings
  );
  const [processingS3Logging, setProcessingS3Logging] = useState<boolean>(
    false
  );
  const [compliance, setCompliance] = useState<boolean>(false);
  const [complianceDuringCreation, setComplianceDuringCreation] = useState<
    boolean
  >(false);
  const [region, setregion] = useState<string>("");
  const [versioned, setversioned] = useState<boolean>(false);
  const [replicationRules, setReplicationRules] = useState<ReplicationRules>();
  const [gotBucketDetails, setGotBucketDetails] = useState<boolean>(false);
  const defaultRegion = new Map();
  const [allRegionsMap, setAllRegionsMap] = useState<Map<string, string>>(
    defaultRegion.set("us-east-1", "US - Virginia (us-east-1)")
  );
  const [noDetailsFound, setNoDetailsFound] = useState<boolean>(false);
  const [regionList, setRegionList] = useState<Region[]>([]);
  const [showCopyEndpointTooltip, setShowCopyEndpointTooltip] = React.useState(
    true
  );
  const [viewBucketRegionURL, setViewBucketRegionURL] = useState("");
  // const [s3URL, sets3URL] = useState("");
  const [s3endpoint, setS3endpoint] = useState("");
  const [showReplication, setShowReplication] = useState(false);

  const getBucketDetails = async () => {
    try {
      // const s3DomainURL = await api.getS3Domain();
      // if (s3DomainURL.s3domain.length > 0 && s3DomainURL.s3domain !== null) {
      //   sets3URL(s3DomainURL.s3domain);
      // }
      const resp: AllRegions = await api.getAllRegions();
      let regionsMap = new Map<string, string>();
      resp.regions.map(region =>
        regionsMap.set(region.name, region.description)
      );
      setAllRegionsMap(regionsMap);
      setRegionList(resp.regions);

      const bucketList: ListBucketResponse = await api.listBuckets();
      setAllBucketList(bucketList.buckets);
      const viewBucketRegion =
        bucketList.buckets.find(bucket => bucket.name === bucketName)?.region ||
        "";

      const regionUrlList = resp.regions.map(regionObj =>
        regionObj.name === viewBucketRegion ? regionObj.url : ""
      );
      const regionUrl = regionUrlList.find(url => url !== "") || "";
      setViewBucketRegionURL(regionUrl);

      const s3EndpointUrl = await regionApis.s3EndpointUrl(regionUrl || "");
      if (
        s3EndpointUrl.s3endpount.length > 0 &&
        s3EndpointUrl.s3endpount !== null
      ) {
        setS3endpoint(s3EndpointUrl.s3endpount);
      }

      const details: ViewBucketDetails = await regionApis.getBucketDetails(
        regionUrl || "",
        bucketName
      );
      const complainceDetails = details.compliance;
      const rententionDuration = complainceDetails.retentionDuration
        ? complainceDetails.retentionDuration
        : 30;
      const rententionUnit = complainceDetails.retentionUnit
        ? complainceDetails.retentionUnit
        : "Days";
      setComplianceDuringCreation(
        complainceDetails.complianceOnCreation ? true : false
      );
      setCompliance(complainceDetails.compliance ? true : false);
      setInitialUnit(rententionUnit);
      setInitialDuration(rententionDuration);
      setAuditLogSetting(details.auditLogs);
      setregion(details.region);
      setversioned(details.versioned ? true : false);
      setReplicationRules(details.replicationRules);
      setShowReplication(details.CustomerReplicationEnabled ? true : false);
      if (
        _.isEmpty(details.compliance) &&
        _.isEmpty(details.auditLogs) &&
        _.isEmpty(details.replicationRules)
      ) {
        setNoDetailsFound(true);
      }
      setGotBucketDetails(true);
    } catch (error) {
      console.log("failed to fetch bucket details: ", error);
      setNoDetailsFound(true);
      setGotBucketDetails(true);
    }
  };

  useEffect(() => {
    getBucketDetails();
  }, []);

  const toggleCompliance = (newCompliance: boolean) => {
    setCompliance(newCompliance);
  };

  const handleToggleS3Logging = () => {
    const s3Logged = auditLogSetting?.bucketS3AuditLog
      ? auditLogSetting?.bucketS3AuditLog
      : false;
    setProcessingS3Logging(true);
    regionApis
      .toggleS3Logging(viewBucketRegionURL, bucketName, !s3Logged)
      .then(res => {
        api //TODO change to regionAPI ?
          .getS3Logging(bucketName)
          .then(S3logging => {
            const logged = S3logging.logged ? S3logging.logged : false;
            let logSetting = auditLogSetting;
            logSetting.bucketS3AuditLog = logged;
            setAuditLogSetting(logSetting);
            setProcessingS3Logging(false);
          })
          .catch(err => {
            setProcessingS3Logging(false);
          });
      })
      .catch(err => {
        setProcessingS3Logging(false);
      });
  };

  const showLoggedBadge = (): boolean => {
    let TenantS3APIAuditLogsEnabled = false;
    let bucketS3AuditLog = false;
    let TenantlogAllBucketsEnabled = false;
    if (auditLogSetting !== undefined) {
      TenantS3APIAuditLogsEnabled = auditLogSetting.TenantS3APIAuditLogsEnabled
        ? true
        : false;
      bucketS3AuditLog = auditLogSetting.bucketS3AuditLog ? true : false;
      TenantlogAllBucketsEnabled = auditLogSetting.TenantlogAllBucketsEnabled
        ? true
        : false;
    }
    return !TenantS3APIAuditLogsEnabled
      ? false
      : TenantS3APIAuditLogsEnabled && TenantlogAllBucketsEnabled
      ? true
      : TenantS3APIAuditLogsEnabled &&
        !TenantlogAllBucketsEnabled &&
        bucketS3AuditLog;
  };

  const showLoggedCard = (): boolean => {
    let TenantS3APIAuditLogsEnabled = false;
    let bucketS3AuditLog = false;
    let TenantlogAllBucketsEnabled = false;
    if (auditLogSetting !== undefined) {
      TenantS3APIAuditLogsEnabled = auditLogSetting.TenantS3APIAuditLogsEnabled
        ? true
        : false;
      bucketS3AuditLog = auditLogSetting.bucketS3AuditLog ? true : false;
      TenantlogAllBucketsEnabled = auditLogSetting.TenantlogAllBucketsEnabled
        ? true
        : false;
    }
    return !TenantS3APIAuditLogsEnabled
      ? false
      : TenantS3APIAuditLogsEnabled && TenantlogAllBucketsEnabled
      ? false
      : TenantS3APIAuditLogsEnabled && !TenantlogAllBucketsEnabled && true;
  };

  const showDeleteModal = () => {
    const regionUrlList = regionList.map(regionObj =>
      regionObj.name === region ? regionObj.url : ""
    );
    const regionUrl = regionUrlList.find(url => url !== "");

    showModal({
      body: closeModal => (
        <DeleteBucketPrompt
          regionUrl={regionUrl}
          bucket={bucketName}
          closeModal={closeModal}
        />
      ),
      baseProps: { size: "sm" }
    });
  };

  const setVersioned = (versioned: boolean) => {
    setversioned(versioned);
  };

  const copyToClipboard = (url: string) => {
    const copyText = url;
    navigator.clipboard.writeText(copyText);
    setShowCopyEndpointTooltip(false);
    const copyTooltipTimer = setTimeout(() => {
      setShowCopyEndpointTooltip(true);
      clearTimeout(copyTooltipTimer);
    }, 1000);
  };

  return (
    <div className="container-fluid IAM-support-page-bodyContent">
      <div className="row no-gutter">
        <div className="col">
          <div>
            <div>
              <Link className="view-bucket-heading" to={`/customer/buckets`}>
                {"Buckets"}
              </Link>
              <span className="view-bucket-heading-span">{" >"}</span>
            </div>
            <div className="view-bucket-name">{bucketName}</div>
            {/* <LyveHeading title={bucketName} subTitle="" /> */}

            {gotBucketDetails ? (
              !noDetailsFound ? (
                <>
                  <div className="d-flex view-bucket-badges view-bucket-badges">
                    <div className="d-flex">
                      <div className="d-flex view-bucket-buttons">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-globe mr-1"
                            viewBox="0 0 16 16"
                          >
                            <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
                          </svg>
                        </div>
                        <div className="view-bucket-region">
                          {allRegionsMap
                            ? allRegionsMap.get(region)
                            : "No region found"}
                        </div>
                      </div>
                      {compliance && (
                        <div className="bucket-badge">Immutable</div>
                      )}
                      {versioned && (
                        <div className="bucket-badge">Versioned</div>
                      )}
                      {showLoggedBadge() && (
                        <div className="bucket-badge">Logged</div>
                      )}
                    </div>
                    <div className="copy-btn-wrapper">
                      <LyveTooltip
                        text="Copied to clipboard"
                        id="copy-s3-endpoint"
                        variant="primary"
                        placement="top"
                        enabled={!showCopyEndpointTooltip}
                      >
                        <button
                          className="copy-endpoint-btn"
                          onClick={() =>
                            s3endpoint ? copyToClipboard(s3endpoint) : null
                          }
                          title={
                            showCopyEndpointTooltip && region
                              ? "Click to copy S3 endpoint URL"
                              : ""
                          }
                        >
                          <Clipboard />
                          <span>{s3endpoint}</span>
                        </button>
                      </LyveTooltip>
                    </div>
                  </div>

                  {complianceDuringCreation && (
                    <ComplianceCard
                      bucketName={bucketName}
                      initialCompliance={compliance}
                      initialRetentionDuration={initialDuration}
                      initialRetentionUnit={initialUnit}
                      toggleCompliance={toggleCompliance}
                      setInitialUnit={setInitialUnit}
                      setInitialDuration={setInitialDuration}
                      viewBucketRegionURL={viewBucketRegionURL}
                    />
                  )}

                  {showLoggedCard() && (
                    <Card className="view-bucket-card">
                      <Card.Body>
                        <div className="d-flex">
                          <div className=" d-flex flex-column">
                            <div className="VB-card-title">
                              S3 API Audit Logs
                            </div>
                            <div className="VB-card-description">
                              {auditLogSetting.bucketS3AuditLog
                                ? "All S3 API activities on this bucket will be logged."
                                : "Disabled"}
                            </div>
                          </div>
                          <Can perform={BUCKETS_EDIT}>
                            <div className=" ml-auto">
                              <CustomToggle
                                selected={
                                  auditLogSetting?.bucketS3AuditLog
                                    ? auditLogSetting?.bucketS3AuditLog
                                    : false
                                }
                                toggleSelected={handleToggleS3Logging}
                                processing={processingS3Logging}
                              />
                            </div>
                          </Can>
                        </div>
                      </Card.Body>
                    </Card>
                  )}

                  {showReplication && (
                    <Card className="view-bucket-card replication-rules-card">
                      <Card.Body>
                        <BucketReplicationRules
                          bucketName={bucketName}
                          compliance={compliance}
                          region={viewBucketRegionURL}
                          regionName={region}
                          initialReplicationRules={replicationRules}
                          setVersioned={setVersioned}
                          regionList={regionList}
                          complianceOnCreation={complianceDuringCreation}
                          allBucketList={allBucketList}
                        />
                      </Card.Body>
                    </Card>
                  )}

                  <Can perform={BUCKETS_EDIT}>
                    <Card className="view-bucket-card">
                      <Card.Body>
                        <div className="d-flex">
                          <div className=" VB-card-title">Delete Bucket</div>
                          <div className=" ml-auto">
                            <Button
                              variant="outline-secondary"
                              onClick={showDeleteModal}
                            >
                              Delete
                            </Button>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Can>
                </>
              ) : (
                <div className="view-bucket-name">No bucket details found.</div>
              )
            ) : (
              <div className="d-flex justify-content-center">
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-1"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface DisableProps {
  openDisableComplianceModal: boolean;
  hideModal: () => void;
}
const DisableComplianceModal: React.FC<DisableProps> = (
  props: DisableProps
) => {
  const { openDisableComplianceModal, hideModal } = props;
  return (
    <React.Fragment>
      <Modal
        show={openDisableComplianceModal}
        onHide={hideModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-60w"
        className="lyve-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Compliance Mode Disable Error
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Compliance mode cannot be disabled for this bucket as it the targeted
          audit log.
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => hideModal()}>ok</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ViewBucket;
