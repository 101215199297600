import React from 'react';
import filesize from 'filesize';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { values } from 'lodash';
import moment from 'moment';

import { convertFileSize, formatDate } from "../../../../../utils";

interface SubaccountTrendChartProps {
	masterXData?: Array<string | null>;
	subaccountData?: ChartData[];
	periodicity?: string;
}

interface ChartData {
	name: string;
	data: (number | null)[];
}

const SubaccountTrendChart: React.FC<SubaccountTrendChartProps> = ({ masterXData, subaccountData, periodicity }) => {

	const options = {
		chart: {
			type: 'line',
			id: 'subaccountChart',
			marginRight: 60,
			marginTop: 26,
			height: 250,
			styledMode: true
		},
		title: {
			text: "",
			align: "left"
		},
		credits: {
			enabled: false
		},
		xAxis: {
			labels: {
				formatter: function(y: any): string {
					var label = '';
					try {
						const vals = y.value.split(' ')
						label = vals[1].slice(0, 2);
						label = label.replace(/^0+/, '');
						label = Number(label) !== 1 ? label : `${label} ${vals[0].slice(0, 3)}`

						if (periodicity === 'MONTHLY') {
							label = vals[0].slice(0, 3);
							label = label !== "Jan"
								? label
								: `${label} ${vals[vals.length - 1]}`
						}
					} catch (e) {
						//console.error(e);
					}
					return label;
				},
				style: {
					fontSize: '12px',
					color: "#1a1a1a",
					transform: 'translate(0, 0) rotate(0deg)'
				}
			},
			tickInterval: 1,
			categories: masterXData,
			crosshair: {
				width: 0.5,
				color: '#a8a8a8'
			},
			title: ''
		},
		yAxis: {
			labels: {
				formatter: function(value: any): string {
					return convertFileSize(value.pos);
				},
				style: {
					fontSize: '12px',
					color: "#1a1a1a"
				}
			},
			allowDecimals: false,
			gridLineDashStyle: 'longdash',
			crosshair: false,
			title: ''
		},
		legend: {
			layout: 'horizontal',
			align: 'center',
			verticalAlign: 'bottom',
			maxHeight: 55
		},

		plotOptions: {
			pointStart: 1,
			series: {
				marker: {
					enabled: false,
					symbol: 'circle',
					radius: 2,
					lineWidth: 1
				}
			}
		},

		series: subaccountData,

		tooltip: {
			shared: true,
			useHTML: true,

			formatter: function(): any {
				var value: any = this;
				var title: string = value.x;
				var month: string = '';
				var year: string = '';

				if (periodicity === 'MONTHLY') {
					month = title.split(' ')[0].slice(0, 3);
					year = title.split(' ')[2].slice(0, 4);
					title = month + ', ' + year;
				}
				var str: string =
					'<span style="color: #ffffff;"><b>' + title + '</b></span><br/><table style="margin-top: 10px;"> ';
				var points: any = value.points;
				points.map((obj: any, index: number) => {
					str =
						str +
						`<tr style="color: #ffffff;"> <td><span class="tooltip-text-${index}">&#9673;</span>&nbsp;
						${obj.series.name}
						: </td> <td> <b>
						${convertFileSize(Number(obj.y))}
						</b></td></tr>`;
				});

				str = str + '</table>';
				return str;
			},

			backgroundColor: 'black',
			borderWidth: 2,
			borderColor: 'black',
			style: {
				color: '#CCCCCC',
				opacity: 0.85
			}
		},

		responsive: {
			rules: [
				{
					condition: {
						maxWidth: 400,
						maxHeight: 400
					},
					chartOptions: {
						legend: {
							layout: 'horizontal',
							align: 'center',
							verticalAlign: 'bottom'
						}
					}
				}
			]
		}
	};

	return (
		<div>
			<HighchartsReact id="customerChart" highcharts={Highcharts} options={options} />
		</div>
	);
};

export default SubaccountTrendChart;
