import React from "react";
import { useController } from "react-hook-form";
import { Form, FormControlProps } from "react-bootstrap";
import omit from "lodash/omit";

import LyveCard from "../../../../../../components/Card/LyveCard";
import {
  getLink,
  linkNames
} from "../../../../../../components/LyveHeadingLink/LinkUtil";
import LyveLink from "../../../../../../components/LyveHeadingLink/LyveLink";
import Input, { InputWithRules } from "../../../../../../components/Input";
import { SubAccountData } from "../../../../../../state/Subaccounts/types";
import { getValidatedRules } from "../../../../../../utils";

import { useFormContext } from "./FormContext";
import rules from "./rules";
import "./styles.scss";

declare interface OrgInfoProps extends FormControlProps {
  account: SubAccountData | null;
}

const OrgInfo: React.FC<OrgInfoProps> = ({ account, ...rest }) => {
  const { control, errors } = useFormContext();

  const { field: orgName } = useController({ name: "orgName", control });
  const { field: streetAddr1 } = useController({
    name: "streetAddr1",
    control
  });
  const { field: streetAddr2 } = useController({
    name: "streetAddr2",
    control
  });
  const { field: city } = useController({ name: "city", control });
  const { field: state } = useController({ name: "state", control });
  const { field: zipCode } = useController({ name: "zipCode", control });
  const { field: country } = useController({ name: "country", control });
  const { field: number } = useController({ name: "number", control });

  const canHaveDiffOrg = account?.sub_account_diff_org;
  const canHaveSupport = account?.sub_account_support;

  const orgNameRules = getValidatedRules(
    omit(rules, "unique", "matchesLowerAlphaNumericDash", "dash"),
    Object.keys(errors.orgName?.types || {})
  );

  return (
    <LyveCard
      title="Organization Information"
      subtitle={
        <span>
          An organization represents a legal entity that a sub account belongs.
          <LyveLink link={getLink(linkNames.CREATE_SUBACCOUNT)} />
        </span>
      }
    >
      {canHaveDiffOrg ? (
        <InputWithRules
          id="org-name"
          label="Organization Name"
          disabled={!canHaveDiffOrg || rest.disabled}
          rules={orgNameRules}
          isInvalid={!!errors.orgName?.message}
          error={errors.orgName?.message}
          {...omit(orgName, "ref")}
        />
      ) : (
        <Form.Group controlId="org-name">
          <Form.Label>Organization Name</Form.Label>
          <Form.Control value={account?.short_name} readOnly={true} {...rest}/>
        </Form.Group>
      )}
      {canHaveSupport && (
        <>
          <Input
            controlId="street-addr-1"
            label="Street Address"
            isInvalid={!!errors.streetAddr1?.message}
            error={errors.streetAddr1?.message}
            {...rest}
            {...omit(streetAddr1, "ref")}
          />
          <Input
            controlId="street-addr-2"
            label={
              <>
                <span> Street Address 2 </span>
                <span className="label-info"> (Optional) </span>
              </>
            }
            isInvalid={!!errors.streetAddr2?.message}
            error={errors.streetAddr2?.message}
            {...rest}
            {...omit(streetAddr2, "ref")}
          />
          <div className="form-input-row">
            <Input
              controlId="city"
              label="City"
              isInvalid={!!errors.city?.message}
              error={errors.city?.message}
              {...rest}
              {...omit(city, "ref")}
            />
            <Input
              controlId="state"
              label="State"
              isInvalid={!!errors.state?.message}
              error={errors.state?.message}
              {...rest}
              {...omit(state, "ref")}
            />
          </div>
          <div className="form-input-row">
            <Input
              controlId="zipCode"
              label="Zip Code"
              isInvalid={!!errors.zipCode?.message}
              error={errors.zipCode?.message}
              {...rest}
              {...omit(zipCode, "ref")}
            />
            <Input
              controlId="country"
              label="Country/Region"
              isInvalid={!!errors.country?.message}
              error={errors.country?.message}
              {...rest}
              {...omit(country, "ref")}
            />
          </div>
          <Input
            controlId="number"
            label="Phone Number"
            isInvalid={!!errors.number?.message}
            error={errors.number?.message}
            {...rest}
            {...omit(number, "ref")}
          />
        </>
      )}
    </LyveCard>
  );
};

export default OrgInfo;
