import React from "react";
import { Card, Button } from "react-bootstrap";
import "./styles.css";
import vectorLogo from "../../../../../../images/Lyve-Cloud-Logo-Stack-GRN-nopad.png";
import api from "../../../../../../services/api";

const AccountDisabledModal = () => {
  return (
    <div className="ADM-main">
      <Card className="text-center ADM-card">
        <div className="d-flex flex-column justify-content-center">
          <div>
            <img src={vectorLogo} width="100px" height="30px" />
          </div>
          <div className="ADM-title">
            You have been logged out because your account is disabled.
          </div>
          <div className="ADM-body">
            Once your account is reactivated, you can login again.
          </div>
          <div>
            <Button
              className="ADM-btn"
              onClick={() => {
                window.location.href = api.getLoginUrl();
              }}
            >
              Go to login
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AccountDisabledModal;
