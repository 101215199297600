import {
  TOGGLE_TENANT_DISPLAY_MODAL,
  TENANT_DISPLAY_MODAL,
  TENANT_HIDE_MODAL,
  TenantsState,
  ToggleTenantDisplayModalActionTypes,
  TenantDisplayModalAction,
  TenantHideModalAction
} from "./types";

const initialState: TenantsState = {
  displayModal: false
};

export function reducer(
  state = initialState,
  action:
    | ToggleTenantDisplayModalActionTypes
    | TenantDisplayModalAction
    | TenantHideModalAction
): TenantsState {
  switch (action.type) {
    case TOGGLE_TENANT_DISPLAY_MODAL:
      return {
        ...state,
        displayModal: !state.displayModal
      };
    case TENANT_DISPLAY_MODAL:
      return {
        ...state,
        displayModal: true
      };
    case TENANT_HIDE_MODAL:
      return {
        ...state,
        displayModal: false
      };
    default:
      return state;
  }
}
