import { openDB } from "idb";
import { Ticket } from "../Models/Ticket";
import { WindowObj } from "../Models/Ticket";

class IDBHelper {
    async __dbRef() {
        try {
            // console.log("IDB: __dbRef");
            const db = await openDB('TicketsStore', 2, {
                upgrade(db) {
                    if (!db.objectStoreNames.contains('tickets')) {
                        const store = db.createObjectStore('tickets', { keyPath: 'CaseNumber', autoIncrement: true });
                        store.createIndex("CaseNumber", "CaseNumber");
                    }

                    if (!db.objectStoreNames.contains('windowStore')) {
                        const store2 = db.createObjectStore('windowStore', { keyPath: 'WindowID' });
                        store2.createIndex("WindowID", "WindowID");
                    }
                },
            });

            return Promise.resolve(db);
        } catch (error) {
            // console.log("IDB:error __dbRef");
            console.log(error);
            return Promise.reject(error);
        }
    }

    async __saveTicketsIDB(tickets: Ticket[]) {
        try {
            // console.log("IDB: __saveTicketsIDB");
            // console.log("IDB: start saving the tickets")
            const dbRef = await this.__dbRef();

            const __transaction = dbRef.transaction('tickets', 'readwrite');
            tickets.forEach(ticket => {
                __transaction.store.put(ticket);
            });
            await __transaction.done;
            return Promise.resolve(true);
        } catch (error) {
            // console.log("IDB:error __saveTicketsIDB");
            console.log(error);
            return Promise.reject(error);
        }
    }

    async __retriveTicketsIDB(index?: any) {
        try {
            // console.log("IDB: __retriveTicketsIDB");
            const dbRef = await this.__dbRef();
            const __records: Array<Ticket> = await dbRef.getAllFromIndex('tickets', 'CaseNumber')
            return Promise.resolve(__records);
        } catch (error) {
            // console.log("IDB:error __retriveTicketsIDB");
            console.log(error);
            return Promise.reject(error);
        }
    }

    async __retriveOneTicketIDB(index?: any) {
        try {
            // console.log("IDB: __retriveOneTicketIDB");
            const dbRef = await this.__dbRef();
            const __record = await dbRef.get('tickets', index)
            return Promise.resolve(__record);
        } catch (error) {
            // console.log("IDB:error __retriveOneTicketIDB");
            console.log(error);
            return Promise.reject(error);
        }
    }

    async __saveWindowObjIDB(windows: WindowObj) {
        try {
            // console.log("IDB: __saveWindowObjIDB")
            const dbRef = await this.__dbRef();
            const __tx = dbRef.transaction('windowStore', 'readwrite');
            __tx.store.put(windows);
            await __tx.done;
            return Promise.resolve(true);
        } catch (error) {
            // console.log("IDB:error __saveWindowObjIDB")
            console.log(error);
            return Promise.reject(error);
        }
    }
}

const idbHelper = new IDBHelper();
export default idbHelper;