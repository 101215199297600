import React from "react";
import orderBy from "lodash/orderBy";
import { Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Eye,
  Pencil,
  Plus,
  Trash,
  ThreeDots,
  XCircle,
  CheckCircle,
  Files
} from "react-bootstrap-icons";

import { SA_EDIT } from "../../../../authRules";
import Can from "../../../../components/Can";
import api from "../../../../services/api";
import regionApis from "../../../../services/RegionApis";
import LyveTable from "../../../../components/LyveTable";
import LyveHeadingLink from "../../../../components/LyveHeadingLink";
import { DefaultButton, MultiButton } from "../../../../components/LyveButton";
import { Card } from "../../../../components/Card";
import { ModalContainer } from "../../../../components/Modal";
import { LyveStatusDot } from "../../../../components/LyveBadge";
import ConfirmationModal from "./components/ConfirmationModal";
import DeletePrompt from "./components/DeletePrompt";
import CreatePrompt from "./components/CreatePrompt";
import ErrorPrompt from "./components/ErrorPrompt";
import { PermissionData } from "./types";

import "./styles.scss";
import { AppState, useAppDispatch, useAppSelector } from "../../../../store";
import {
  loadingPermissions,
  resolvePermissions,
  selectPermissions
} from "../../../../state/permissions";
import {
  loadingServiceAccounts,
  rejectServiceAccounts,
  resolveServiceAccounts,
  selectServiceAccounts,
  ServiceAccount
} from "../../../../state/serviceAccounts";
import { fetchRegionsWithSG } from "../../../../utils/api";
import {
  loadingRegionsWithSG,
  resolveRegionsWithSG,
  selectRegionsWithSG
} from "../../../../state/regionswithsg";
import { useDispatch, useSelector } from "react-redux";
import * as notificationActions from "./../../../../components/Notification/actions";
import {
  LoadingStateModal,
  ErrorStateModal
} from "../../../../components/LyveErrorStateOverlay";
import { WhoAmI } from "../../../../state/UserInfo/types";
import {
  getLink,
  linkNames
} from "../../../../components/LyveHeadingLink/LinkUtil";
import ContextMenu from "../../../../components/ContextMenu";
import ExpirySettingModal from "./components/ExpirySettingModal";

interface Data {
  id: string;
  name: string;
  enabled: boolean;
  is_expired: boolean;
}

interface Account {
  id: string;
  name: string;
  slug: string;
  access_key: string;
  enabled: boolean;
  seen: boolean;
  createdby: boolean;
  creation_date: string;
  expires_in: string;
  is_expired: boolean;
}

interface ListAccountsResponse {
  service_accounts: Account[];
  total: number;
}

interface Permission {
  id: string;
  name: string;
  slug: string;
  description: string;
  effect: string;
  resources: any[];
  actions: any[];
  status: string;
}

interface ListPermissionResponse {
  permissions: Permission[];
}

interface RegionsWithSG {
  regions: Region[];
}

interface Region {
  name: string;
  url: string;
}

const loadingStateLabelList = [
  {
    title: "Creating Service Account…",
    subtitle: "Please wait."
  },
  {
    title: "We are still working on it…",
    subtitle: "Thank you for you patience."
  },
  {
    title: "Almost there!",
    subtitle: "Your service account will be ready soon."
  },
  {
    title: "Creating Service Account…",
    subtitle: "Please wait."
  },
  {
    title: "We are still working on it…",
    subtitle: "Thank you for you patience."
  }
];

const ServiceAccounts: React.FC = () => {
  // const classes = useStyles();
  const { showModal } = ModalContainer.useContainer();
  const whoAmI = useSelector<AppState, WhoAmI>(state => state.userInfo.whoAmI);

  const dispatch = useAppDispatch();
  const notificationDispatch = useDispatch();

  const [accounts, setAccounts] = React.useState<Data[]>([]);
  const [accountsInitialized, setAccountsInitialized] = React.useState<boolean>(
    false
  );
  const [permissions, setPermissions] = React.useState<PermissionData[]>([]);
  // const [isFormProcessing, setFormProcessing] = React.useState<boolean>(false);
  // The ID of the account whose status is being updated
  const [statusProcessingId, setStatusProcessingId] = React.useState<
    string | null
  >(null);
  const [showAllAllowCheckbox, setShowAllAllowCheckbox] = React.useState<
    boolean
  >(false);
  const { data: regionswithsg } = useAppSelector(selectRegionsWithSG);
  const [baseUrl, setBaseUrl] = React.useState<string>("");

  const [totalRows, setTotalRows] = React.useState<number>(0);
  const [perPage, setPerPage] = React.useState(10);
  const [showLoadingStateModal, setLoadingStateModal] = React.useState(false);
  const [showErrorStateModal, setErrorStateModal] = React.useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = React.useState(
    false
  );
  const [confirmationAccountData, setConfirmationAccountData] = React.useState<
    any
  >({});

  const [counter, setCounter] = React.useState(0);
  const intervalRef = React.useRef<any>(null);
  const counterValueRef = React.useRef(0);
  const intervalModalCloseRef = React.useRef<any>(null);
  const [error, setError] = React.useState<string>("");
  const [isFormProcessing, setFormProcessing] = React.useState<boolean>(false);
  const [
    selectedServiceAccountRow,
    setSelectedServiceAccountRow
  ] = React.useState<any>(null);
  const [permissionIds, setPermissionIds] = React.useState<any>([]);
  const [serviceAccountName, setServiceAccountName] = React.useState<string>(
    ""
  );
  const [showMenuId, setShowMenuId] = React.useState("");
  const [showExpirySettingModal, setExpirySettingModal] = React.useState(false);

  const constructPermissionList = (res: ListPermissionResponse) => {
    let permissionList: Permission[] = [];
    if (res && res.permissions) {
      // temporarily construct usage field
      permissionList = res.permissions.map(
        (b: Permission): Permission => {
          return {
            ...b
          };
        }
      );
      permissionList = permissionList.filter(
        Permission =>
          //Permission.status?.toLowerCase() !== "pending" &&
          Permission.effect?.toLowerCase() !== "deny"
      );
    }
    setPermissions(permissionList);
    if (res && res.permissions) {
      dispatch(resolvePermissions([...res.permissions]));
    }
  };

  const constructServiceAccountsList = (res: ListAccountsResponse) => {
    let accountsList: Account[] = [];
    if (res && res.service_accounts) {
      //setTotalRows
      setTotalRows(res?.total);
      // temporarily construct usage field
      accountsList = res.service_accounts.map(
        (b: Account): Account => {
          return {
            ...b
          };
        }
      );
    }
    setAccounts(accountsList);
    dispatch(resolveServiceAccounts([...accountsList]));
  };

  React.useEffect(() => {
    async function getAllRegionsWithSG() {
      try {
        await fetchRegionsWithSG().then(regionList => {
          let sg_regions = regionList?.filter(
            (obj, index, self) =>
              index ===
              self.findIndex(t => t.name === obj.name && t.url === obj.url)
          );
          dispatch(resolveRegionsWithSG([...sg_regions]));
          setBaseUrl(sg_regions?.length > 0 ? sg_regions[0].url : "");
        });
      } catch (e) {
        console.log(e);
      }
    }
    if (!!regionswithsg) {
      getAllRegionsWithSG();
    }
  }, []);

  React.useEffect(() => {
    const companyShortName: any = whoAmI.company_short_name;

    let tenants: any = window.REACT_APP_TENANTS;

    if (tenants !== undefined) {
      var array: Array<any> = tenants.split(",");
      try {
        for (var i = 0; i < array.length; i++) {
          if (array[i] === companyShortName) {
            setShowAllAllowCheckbox(true);
          }
        }
      } catch (e) {
        console.error("Not allow all bucket access.");
      }
    }

    // let shortName: string = userInfo.company_short_name ? userInfo.company_short_name : '';
    let isMounted = true;

    api.listPermissions().then(res => {
      if (isMounted) {
        constructPermissionList(res);
        dispatch(loadingServiceAccounts());
        api
          .listServiceAccounts()
          .then(res => {
            if (isMounted) {
              constructServiceAccountsList(res);
              setAccountsInitialized(true);
            }
            //getUserConfigFromLocalStorage(shortName);
          })
          .catch(err => {
            // TODO: Handle errors while listing service accounts
            if (isMounted) {
              dispatch(rejectServiceAccounts(err));
              setAccountsInitialized(true);
            }
          });
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  React.useEffect(() => {
    if (
      intervalModalCloseRef &&
      intervalModalCloseRef.current &&
      (counter === 5 || counterValueRef.current === 5)
    ) {
      intervalModalCloseRef.current();
      setLoadingStateModal(true);
      setErrorStateModal(false);
      setError("");
    }

    if (counter === 0 || counterValueRef.current === 0) {
      setLoadingStateModal(false);
    }
  }, [counter, counterValueRef.current]);

  React.useEffect(() => {
    if (error && (counter > 4 || counterValueRef.current > 4)) {
      setLoadingStateModal(false);
      intervalModalCloseRef.current();
      setErrorStateModal(true);
    }
  }, [counter, counterValueRef.current, error]);

  React.useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
      counterValueRef.current = 0;
      setCounter(0);
    };
  }, []);

  const handleEnableAccount = (id: string) => {
    setStatusProcessingId(id);
    regionApis
      .enableServiceAccount(id, baseUrl)
      .then(() => {
        setStatusProcessingId(null);
        api.listServiceAccounts().then(res => {
          constructServiceAccountsList(res);
          // setSelectedAccount(null);
        });
      })
      .catch(err => {
        setStatusProcessingId(null);
      });
  };

  const handleDisableAccount = (id: string) => {
    setStatusProcessingId(id);
    regionApis
      .disableServiceAccount(id, baseUrl)
      .then(() => {
        setStatusProcessingId(null);
        api.listServiceAccounts().then(res => {
          constructServiceAccountsList(res);
          // setSelectedAccount(null);
        });
      })
      .catch(err => {
        setStatusProcessingId(null);
      });
  };

  const noAccountsCreated = () => {
    return accountsInitialized && accounts.length === 0;
  };

  const checkSchedularTaskStatus = (
    serviceaccountID: string,
    serviceAccountName: string,
    action: string
  ) => {
    api
      .taskStatus(serviceaccountID, "serviceaccount")
      .then(data => {
        if (data.status == "Applied") {
          if (action == "CREATE") {
            notificationDispatch(
              notificationActions.setNotification(
                `${data.name} service account is created.`
              )
            );
          }
          if (action == "EDIT") {
            notificationDispatch(
              notificationActions.setNotification(
                `${data.name} service account is updated.`
              )
            );
          }
          api.listServiceAccounts().then(res => {
            constructServiceAccountsList(res);
          });
        } else {
          setTimeout(function() {
            checkSchedularTaskStatus(
              serviceaccountID,
              serviceAccountName,
              action
            );
          }, 5000);
        }
      })
      .catch(err => {
        console.log(err);
        if (
          err == "ServiceAccount deleted or not exist" &&
          action == "DELETE"
        ) {
          notificationDispatch(
            notificationActions.setNotification(`${serviceAccountName} ${err}.`)
          );
          api.listServiceAccounts().then(res => {
            constructServiceAccountsList(res);
          });
        }
      });
  };

  const handleDeleteServiceAccountSuccess = (
    service_account?: Account,
    closeModal: () => void = () => null
  ) => {
    closeModal();
    api.listServiceAccounts().then(res => {
      constructServiceAccountsList(res);
    });
    // service_account &&
    //   checkSchedularTaskStatus(
    //     service_account.id,
    //     service_account.name,
    //     "DELETE"
    //   );
  };

  const handleCreateServiceAccountSuccess = (
    data: any,
    selectedAccount?: Account,
    closeModal?: () => void | undefined
  ) => {
    if (closeModal) {
      closeModal();
    } else {
      setShowConfirmationModal(true);
      setConfirmationAccountData(data);
    }
    api.listServiceAccounts().then(res => {
      constructServiceAccountsList(res);
    });
    // !!selectedAccount
    //   ? checkSchedularTaskStatus(
    //       data.service_account.id,
    //       data.service_account.name,
    //       "EDIT"
    //     )
    //   : checkSchedularTaskStatus(
    //       data.service_account.id,
    //       data.service_account.name,
    //       "CREATE"
    //     );
  };

  // const handleFetchSecretAndAccessKey = (saID: string, saName: string) => {
  //   api
  //     .fetchSecretandAcessKey(saID)
  //     .then(data => {
  //       data.name = saName;
  //       showConfirmationModal(data);
  //     })
  //     .catch(err => {
  //       console.log(err);
  //       showErrorPrompt(
  //         "Fetch Error",
  //         "Fetching secret key and access token for " +
  //           saName +
  //           " service account has failed. Please try again later."
  //       );
  //     });
  // };

  const handleListRefresh = () => {
    api.listServiceAccounts().then(res => {
      constructServiceAccountsList(res);
    });
  };

  const createAccount = () => {
    handleClearInterval();

    intervalRef.current = setInterval(() => {
      if (counterValueRef.current === 30) {
        clearInterval(intervalRef.current);
        setCounter(0);
        counterValueRef.current = 0;
      } else {
        counterValueRef.current = counterValueRef.current + 1;
        setCounter(counterValueRef.current);
      }
    }, 1000);

    regionApis
      .addServiceAccount(serviceAccountName, permissionIds, false, baseUrl)
      .then(res => {
        if (
          counterValueRef.current &&
          ((counterValueRef.current > 5 && counterValueRef.current < 31) ||
            counterValueRef.current < 6)
        ) {
          handleClearInterval();
          setServiceAccountName("");
          setPermissionIds([]);
          setSelectedServiceAccountRow(null);
          handleCreateServiceAccountSuccess(
            res,
            selectedServiceAccountRow,
            //closeModal
            undefined
          );
        } else if (counterValueRef.current && counterValueRef.current > 30) {
          setLoadingStateModal(false);
          clearInterval(intervalRef.current);
          setErrorStateModal(true);
        }

        setFormProcessing(false);
        setError("");
        setErrorStateModal(false);
        setLoadingStateModal(false);
      })
      .catch(err => {
        setLoadingStateModal(false);
        setFormProcessing(false);
        clearInterval(intervalRef.current);
        setError(err);
      });
  };

  const updateAccount = () => {
    handleClearInterval();

    intervalRef.current = setInterval(() => {
      if (counterValueRef.current === 30) {
        clearInterval(intervalRef.current);
        setCounter(0);
        counterValueRef.current = 0;
      } else {
        counterValueRef.current = counterValueRef.current + 1;
        setCounter(counterValueRef.current);
      }
    }, 1000);

    regionApis
      .updateServiceAccount(
        selectedServiceAccountRow.id,
        serviceAccountName,
        permissionIds,
        selectedServiceAccountRow.enabled,
        false,
        baseUrl
      )
      .then(res => {
        if (
          counterValueRef.current &&
          ((counterValueRef.current > 5 && counterValueRef.current < 31) ||
            counterValueRef.current < 6)
        ) {
          handleClearInterval();
          setServiceAccountName("");
          setPermissionIds([]);
          setSelectedServiceAccountRow(null);
          handleListRefresh();
        } else if (counterValueRef.current && counterValueRef.current > 30) {
          setLoadingStateModal(false);
          clearInterval(intervalRef.current);
          setErrorStateModal(true);
        }

        setFormProcessing(false);
        setError("");
        setErrorStateModal(false);
        setLoadingStateModal(false);
      })
      .catch(err => {
        setLoadingStateModal(false);
        setFormProcessing(false);
        clearInterval(intervalRef.current);
        setError(err);
      });
  };

  // For toggling delete service account modal
  const showDeleteServiceAccountModal = (selectedAccount?: Account) => {
    showModal({
      body: closeModal => (
        <DeletePrompt
          account={selectedAccount}
          baseUrl={baseUrl}
          closeModal={closeModal}
          onSuccess={() =>
            handleDeleteServiceAccountSuccess(selectedAccount, closeModal)
          }
        />
      ),
      baseProps: { size: "sm" }
    });
  };

  const handleClearInterval = () => {
    clearInterval(intervalRef.current);
    setCounter(0);
    counterValueRef.current = 0;
  };

  // For toggling create service account modal
  const showCreateServiceAccountModal = (
    selectedAccount?: Account,
    readOnly: boolean = false,
    isClone: boolean = false
  ) => {
    showModal({
      body: closeModal => (
        <CreatePrompt
          items={permissions}
          handleClearInterval={handleClearInterval}
          handleCheckExpirySetting={() => {
            closeModal();
            setExpirySettingModal(true);
          }}
          handleModalClose={() => {
            closeModal();
            handleListRefresh();
            handleClearInterval();
            intervalModalCloseRef.current = null;
            setLoadingStateModal(false);
            setErrorStateModal(false);
            setServiceAccountName("");
            setPermissionIds([]);
            setSelectedServiceAccountRow(null);
            setError("");
          }}
          onError={err => {
            setError(err);
            setLoadingStateModal(false);
            clearInterval(intervalRef.current);
          }}
          handleInterval={(name: string, permissionIds: any, account: any) => {
            intervalModalCloseRef.current = closeModal;
            setServiceAccountName(name);
            setPermissionIds(permissionIds);
            setSelectedServiceAccountRow(account ? account : null);
            intervalRef.current = setInterval(() => {
              if (counterValueRef.current === 30) {
                clearInterval(intervalRef.current);
                setCounter(0);
                counterValueRef.current = 0;
              } else {
                counterValueRef.current = counterValueRef.current + 1;
                setCounter(counterValueRef.current);
              }
            }, 1000);
          }}
          baseUrl={baseUrl}
          accountsList={accounts}
          account={selectedAccount}
          readOnly={readOnly}
          isClone={isClone}
          onSuccess={data => {
            setError("");
            if (
              counterValueRef.current &&
              ((counterValueRef.current > 5 && counterValueRef.current < 31) ||
                counterValueRef.current < 6)
            ) {
              handleClearInterval();
              setLoadingStateModal(false);
              setErrorStateModal(false);
              setServiceAccountName("");
              closeModal();
              setPermissionIds([]);
              setSelectedServiceAccountRow(null);
              handleCreateServiceAccountSuccess(
                data,
                selectedAccount,
                //closeModal
                !!selectedAccount && !isClone ? closeModal : undefined
              );
            } else if (
              counterValueRef.current &&
              counterValueRef.current > 30
            ) {
              setLoadingStateModal(false);
              clearInterval(intervalRef.current);
              setErrorStateModal(true);
            }
          }}
          showAllAllowCheckbox={showAllAllowCheckbox}
        />
      ),
      baseProps: { size: "lg", keyboard: false },
      customClose: true
    });
  };

  // For toggling download confirmation modal
  // const showConfirmationModal = (selectedAccount?: any) => {
  //   showModal({
  //     body: closeModal => (
  //       <ConfirmationModal
  //         account={selectedAccount || {}}
  //         closeModal={closeModal}
  //         onSuccessDownload={() => handleListRefresh()}
  //       />
  //     ),
  //     customClose: true,
  //     baseProps: { size: "sm", keyboard: false }
  //   });
  // };

  // For showing Error message on delete permission with service modal attached
  const showErrorPrompt = (title: string, content: string) => {
    showModal({
      body: closeModal => (
        <ErrorPrompt closeModal={closeModal} title={title} content={content} />
      ),
      baseProps: { size: "sm" }
    });
  };

  /** Datatable */
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All"
  };

  const renderReadOnlyStatus = (row: Data) => {
    if (row.is_expired) {
      return (
        <LyveStatusDot color="var(--gray-6)" size={8}>
          <span title="Expired">Expired</span>
        </LyveStatusDot>
      );
    }
    if (row.enabled) {
      return (
        <LyveStatusDot color="var(--green-3)" size={8}>
          <span title="Enabled">Enabled</span>
        </LyveStatusDot>
      );
    } else {
      return (
        <LyveStatusDot color="var(--gray-6)" size={8}>
          <span title="Disabled">Disabled</span>
        </LyveStatusDot>
      );
    }
  };

  const columns = [
    {
      name: "Creation Date",
      selector: "creation_date",
      sortable: true,
      omit: true,
      cell: (row: Account) => (
        <span title={row.creation_date}>{row.creation_date}</span>
      )
    },
    {
      name: "Name",
      selector: "name",
      maxWidth: "600px",
      sortable: true,
      cell: (row: Account) => <span title={row.name}>{row.name}</span>
    },
    {
      name: "Access Key",
      selector: "access_key",
      maxWidth: "352px",
      sortable: true,
      cell: (row: Account) => (
        <span title={row.access_key}>{row.access_key}</span>
      )
    },
    {
      name: "Expires In",
      selector: "expires_in",
      maxWidth: "200px",
      sortable: true,
      cell: (row: Account) => (
        <span
          title={row.expires_in}
          className={row.is_expired ? "sa-expired" : ""}
        >
          {row.expires_in}
        </span>
      )
    },
    {
      name: "Status",
      selector: "enabled",
      maxWidth: "200px",
      sortable: true,
      cell: (row: Account) => {
        return (
          <>
            {row.id === statusProcessingId ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                className="status-spinner"
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              renderReadOnlyStatus(row)
            )}
          </>
        );
      }
    },
    {
      name: "",
      right: true,
      cell: (row: Account) => {
        return (
          <>
            <Can perform={SA_EDIT} no={renderViewAction(row)}>
              <ContextMenu
                id="users"
                drop={"down"}
                trigger={
                  <ThreeDots
                    className={`table-three-dots-icon ${
                      showMenuId === row.id
                        ? "table-three-dots-icon-active"
                        : ""
                    }`}
                    onClick={() => setShowMenuId(row.id)}
                  />
                }
                parentDropdownClass="table-menu"
                hasManualToggle={true}
                manualShow={showMenuId === row.id}
                onToggle={(isOpen, _event, _meta) => {
                  if (!isOpen) {
                    setShowMenuId("");
                  }
                }}
                minWidth="200px"
                options={getServiceAccountActions(row)}
              />
            </Can>
            {/*{!row.seen && row.createdby ? (*/}
            {/*  <DefaultButton*/}
            {/*    variant="outline-secondary"*/}
            {/*    size="sm"*/}
            {/*    onClick={() => {*/}
            {/*      handleFetchSecretAndAccessKey(row.id, row.name);*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <Key size="16" className="key-icon" />*/}
            {/*    Credentials*/}
            {/*  </DefaultButton>*/}
            {/*) : null}*/}
          </>
        );
      }
    }
  ];

  const getServiceAccountActions = (row: Account) => {
    const options: any = [];
    if (!row.is_expired) {
      options.push({
        label: (
          <span>
            <Pencil size="16" className="mr-2" />
            Edit
          </span>
        ),
        onClick: () => {
          showCreateServiceAccountModal(row, false, false);
          setShowMenuId("");
        }
      });
    }

    if (!row.is_expired) {
      if (row.enabled) {
        options.push({
          label: (
            <span>
              <XCircle size="16" className="mr-2" />
              Disable
            </span>
          ),
          onClick: () => {
            handleDisableAccount(row.id);
            setShowMenuId("");
          }
        });
      } else {
        options.push({
          label: (
            <span>
              <CheckCircle size="16" className="mr-2" />
              Enable
            </span>
          ),
          onClick: () => {
            handleEnableAccount(row.id);
            setShowMenuId("");
          }
        });
      }
    }

    options.push({
      label: (
        <span>
          <Files size="16" className="mr-2" />
          Clone
        </span>
      ),
      onClick: () => {
        let cloneRow = Object.assign({}, row);
        cloneRow.name = "Copy of " + cloneRow.name;
        showCreateServiceAccountModal(cloneRow, false, true);
        setShowMenuId("");
      }
    });

    options.push({
      label: (
        <span>
          <Trash size="16" className="mr-2" />
          Delete
        </span>
      ),
      onClick: () => {
        showDeleteServiceAccountModal(row);
        setShowMenuId("");
      }
    });

    return options;
  };

  const renderViewAction = (row: Account) => {
    // AP-11541
    // Can component "no" props expects JSX instead of JSON
    // Hence changing from json to JSX as like before
    return (
      <DefaultButton
        variant="outline-secondary"
        size="sm"
        onClick={() => {
          showCreateServiceAccountModal(row, true);
          setShowMenuId("");
        }}
      >
        <Eye size="16" className="view-icon" />
        View
      </DefaultButton>
    );
  };

  const customSort = (
    rows: Data[],
    field: string,
    direction: "desc" | "asc"
  ) => {
    const handleField = (row: any) => {
      const val = row[field];
      if (val && typeof val === "string") {
        return val.toLowerCase();
      }
      return val;
    };

    return orderBy(rows, handleField, direction);
  };

  // const handlePageChange = (page:number) =>{
  //   fetchPermission(perPage,(page-1)*perPage)
  // }
  //
  // const fetchPermission = (limit:number,offset:number) =>{
  //   api.listServiceAccounts(limit,offset).then(res => {
  //     constructServiceAccountsList(res);
  //   });
  // }
  //
  // const handlePerRowsChange = (perPage: number) =>{
  //   setPerPage(perPage)
  //   fetchPermission(perPage,perPage*0)
  // }

  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
    setConfirmationAccountData({});
  };

  return (
    <div className="service-accounts-container">
      {accountsInitialized ? (
        <>
          <LyveHeadingLink
            title="Service Accounts"
            subTitle="Service Accounts allow applications to authenticate and access Lyve Cloud resources and
            services."
            helpLink={getLink(linkNames.Service_Account)}
          >
            <Can perform={SA_EDIT}>
              <DefaultButton
                variant="primary"
                onClick={() => showCreateServiceAccountModal()}
              >
                <Plus size="24" className="add-icon" />
                Create Service Account
              </DefaultButton>
            </Can>
          </LyveHeadingLink>

          {permissions.length > 0 ? (
            <>
              {noAccountsCreated() ? (
                <Can perform={SA_EDIT} no="There are no service accounts.">
                  You do not have any service accounts. Create one now.
                </Can>
              ) : (
                <LyveTable
                  columns={columns}
                  data={accounts}
                  sortFunction={customSort}
                  defaultSortField="creation_date"
                  defaultSortAsc={false}
                  selectableRows={false}
                  pagination={true}
                  noHeader={true}
                  paginationPerPage={10}
                  paginationRowsPerPageOptions={[10, 25, 50]}
                  highlightOnHover={true}
                  paginationComponentOptions={paginationOptions}
                  noDataComponent={<div />}
                  paginationIconFirstPage={false}
                  paginationIconLastPage={false}
                  responsive={true}
                  // paginationPerPage={perPage}
                  // paginationTotalRows={totalRows}
                  // paginationServer
                  // onChangePage={handlePageChange}
                  // onChangeRowsPerPage={handlePerRowsChange}
                />
              )}
              {/* {modalContent} */}
            </>
          ) : (
            <div>
              <Can perform={SA_EDIT} no="There are no service accounts.">
                <p className="prerequisite-text">
                  Service accounts are associated with permissions. There are no
                  permissions created. Please{" "}
                  <Link to="/customer/permissions" className="permission-link">
                    create permissions
                  </Link>{" "}
                  first.
                </p>
              </Can>
            </div>
          )}

          {showConfirmationModal && (
            <Modal
              key={`show-confirmation-modal`}
              show={showConfirmationModal}
              size={"sm"}
              keyboard={false}
              backdrop="static"
            >
              <Modal.Body>
                <ConfirmationModal
                  account={confirmationAccountData || {}}
                  closeModal={handleConfirmationModalClose}
                  onSuccessDownload={() => handleListRefresh()}
                />
              </Modal.Body>
            </Modal>
          )}

          {showLoadingStateModal && (
            <LoadingStateModal
              stateLabelList={loadingStateLabelList}
              handleErrorCondition={() => {
                setLoadingStateModal(false);
                setErrorStateModal(true);
              }}
            />
          )}
          {showExpirySettingModal && (
            <ExpirySettingModal
              title="Service Account Expiration Duration Change"
              subtitle="Your service account expiration duration has recently been changed. 
                 Please review the new duration before creating the service account."
              handleClick={() => {
                setExpirySettingModal(false);
                showCreateServiceAccountModal();
              }}
            />
          )}
          {showErrorStateModal && (
            <ErrorStateModal
              title={
                error
                  ? "Service account creation failed"
                  : "Service account creation is taking longer than expected"
              }
              subtitle={
                error
                  ? "Please click Retry below to try again, or Close to go back to the menu."
                  : "Please check back again later for your service account status."
              }
              retryBtnLabel={error ? "Retry" : ""}
              closeBtnLabel={"Close"}
              error={!isFormProcessing ? error : ""}
              isLoading={isFormProcessing}
              handleClose={() => {
                handleClearInterval();
                setLoadingStateModal(false);
                setErrorStateModal(false);
                setServiceAccountName("");
                setPermissionIds([]);
                setSelectedServiceAccountRow(null);
                intervalModalCloseRef.current = null;
                setError("");
              }}
              handleRetry={() => {
                setFormProcessing(true);
                if (selectedServiceAccountRow) {
                  updateAccount();
                } else {
                  createAccount();
                }
              }}
            />
          )}
        </>
      ) : (
        <div className="data-loader">
          <Spinner animation="border" role="status" className="spinner">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
};

export default ServiceAccounts;
