export const IS_SAML_CONFIGURED = "IS_SAML_CONFIGURED";
export const UPDATE_MFA_PREFERENCE = "UPDATE_MFA_PREFERENCE";

export interface isSAMLConfiguredAction {
  type: typeof IS_SAML_CONFIGURED;
  payload: boolean;
}

export interface UpdateMFAPreferenceAction {
  type: typeof UPDATE_MFA_PREFERENCE;
  payload: string;
}

export type IAMInfoActionTypes =
  | isSAMLConfiguredAction
  | UpdateMFAPreferenceAction;
