import React from "react";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import { X } from "react-bootstrap-icons";
import { ModalHeaderProps } from "react-bootstrap/ModalHeader";
import { ICommonElementProps } from "./@types";

import "./styles.scss"

export declare interface LyvePopupProps {
  show: boolean;
  header?: boolean;
  customClose?: boolean;
  onHide: (event?: React.MouseEvent<unknown>) => void;
  baseProps?: ModalProps;
  title?: React.ReactNode | string;
  subtitle?: React.ReactNode | string;
  headerProps?: ModalHeaderProps;
  titleProps?: ICommonElementProps;
  bodyProps?: ICommonElementProps;
  footerProps?: ICommonElementProps;
  footer?: React.ReactNode | string;
}

const LyvePopup: React.FC<LyvePopupProps> = (modal) => {
  return (
    <Modal
      show={modal.show}
      onHide={modal.onHide}
      backdrop="static"
      className="lyve-popup"
      {...(modal.baseProps || {})}
    >
      {modal.header ? (
        <Modal.Header closeButton={true} {...(modal.headerProps || {})}>
          <Modal.Title
            {...({ ...modal.titleProps, as: modal.titleProps?.as as any } ||
              {})}
          >
            {modal.title || "Supply Title"}
          </Modal.Title>
        </Modal.Header>
      ) : (
        !modal.customClose && (
          <button className="custom-close-icon" onClick={modal.onHide}>
            <X size="24" color="var(--gray-9)" />
          </button>
        )
      )}
      {modal.subtitle && (
        <div className="popup-subtitle"> {modal.subtitle} </div>
      )}
      <Modal.Body
        {...({ ...modal.bodyProps, as: modal.bodyProps?.as as any } || {})}
      >
        {typeof modal.children === "function" ? modal.children(modal.onHide) : modal.children}
      </Modal.Body>
      {modal.footer && (
        <Modal.Footer
          {...({ ...modal.footerProps, as: modal.footerProps?.as as any } ||
            {})}
        >
          {modal.footer}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default LyvePopup;
