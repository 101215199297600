import React from "react";
import { Check2Circle, Star, X } from "react-bootstrap-icons";

import "./styles.scss";
import { CardType, RenderCardProps, Roles } from "./types.d";
import useSmartStartKit from "./useSmartStartKit";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { WhoAmI } from "../../state/UserInfo/types";
import api from "../../services/api";
import useSmartStartCards from "./useSmartStartCards";
import cx from "classnames";
import { Card } from "react-bootstrap";
import useGlobalContext from "../../state/GlobalStateContext";
import { AddBucketCardProvider } from "../../components/SmartStartHereCards/AddBucketCard/AddBucketContext";

declare interface UserInfo {
  role: Roles;
  email: string;
  accountName: string;
}

const RenderCards = ({ cards, ...props }: RenderCardProps) => {
  const { toggleShowAccountDropdown } = useGlobalContext();
  const {
    toggleShowSmartStartKit,
    toggleTooltip,
    userConfig,
    setUserConfig
  } = useSmartStartKit();

  const moveSmartStartToProfileDropdown = () => {
    toggleShowSmartStartKit();
    toggleTooltip();
    toggleShowAccountDropdown();
    setUserConfig({ ...userConfig, movedToProfileDropdown: true });
    api.updateSmartStartConfig({
      movedToProfileDropdown: true
    });
  };

  return (
    <div className="smart-container">
      {cards.map(
        ({ component: CardComponent, width, priority, ...cardProps }) => (
          <div key={cardProps.id} className="component">
            {cardProps.type === CardType.ADD_BUCKET ? (
              <AddBucketCardProvider>
                <CardComponent {...props} {...cardProps} />
              </AddBucketCardProvider>
            ) : (
              <CardComponent {...props} {...cardProps} />
            )}
          </div>
        )
      )}
      {!userConfig.movedToProfileDropdown && (
        <div className="component">
          <Card
            className="base-card base-card-last"
            onClick={moveSmartStartToProfileDropdown}
          >
            <Card.Body>
              <Check2Circle size={16} />
              <span> I won't need this Start Here menu any more </span>
            </Card.Body>
          </Card>
        </div>
      )}
    </div>
  );
};

const SmartStartKit = () => {
  const whoAmI = useSelector<AppState, WhoAmI>(state => state.userInfo.whoAmI);
  const formatAccountName = (strInput: string | undefined | null) =>
    strInput
      ? strInput
          .toLowerCase()
          .trim()
          .replace(/-+[!^-]+/g, "")
          .replace(/\s+/g, "-")
          .replace(/[^a-z0-9-]/g, "")
      : "";

  let userInfo: UserInfo = {
    role: whoAmI.role as Roles,
    email: whoAmI.email || "",
    accountName: formatAccountName(whoAmI.company_short_name) || ""
  };

  const {
    showSmartStartKit,
    toggleShowSmartStartKit,
    userConfig,
    setShowSmartStartKit,
    setUserConfig
  } = useSmartStartKit();

  const { smartStartCards, removeCard } = useSmartStartCards(
    userConfig,
    userInfo.role
  );

  const setKitEnvironment = async () => {
    const config = await api.getSmartStartConfig();
    const resourceItems = localStorage.getItem("createdResources");
    if (resourceItems) {
      setShowSmartStartKit(true);
      setUserConfig({ ...config, ownedBuckets: 0 });
    } else {
      setShowSmartStartKit(config.autoExpandOnLogin);
      setUserConfig(config);
    }
  };

  const [showToolkit, setShowToolkit] = React.useState(false);

  React.useEffect(() => {
    setKitEnvironment();
  }, []);

  React.useEffect(() => {
    if (showSmartStartKit) {
      setShowToolkit(true);
    }
  }, [showSmartStartKit]);

  return (
    <>
      {!userConfig.movedToProfileDropdown && (
        <div
          className={cx(
            "topbar-right-icon",
            showSmartStartKit && "topbar-pressed-icon"
          )}
          onClick={toggleShowSmartStartKit}
        >
          <Star size={16} />
          <span className="topbar-right-label">Start Here</span>
        </div>
      )}
      {(showSmartStartKit || showToolkit) && (
        <div
          className={`smart-start overlay ${
            showSmartStartKit
              ? "smart-slide-in smart-start-shadow"
              : "smart-slide-out"
          }`}
        >
          <div className="title-bar d-flex justify-content-between">
            <span> Start Here </span>
            <X
              className="close-button"
              size="24"
              color="var(--gray-14)"
              onClick={toggleShowSmartStartKit}
            />
          </div>
          <RenderCards
            cards={smartStartCards}
            email={userInfo.email}
            accountName={userInfo.accountName}
            removeCard={removeCard}
          />
        </div>
      )}
    </>
  );
};

export default SmartStartKit;
