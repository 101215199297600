import {
  SET_NOTIFICATION,
  SET_ERROR_NOTIFICATION,
  CLEAR_NOTIFICATION,
  NotificationActionTypes,
  SET_WARNING_NOTIFICATION
} from "./types";

export function setNotification(message: string): any {
  return (dispatch: any, getState: any) => {
    // fade notification after 5 seconds
    setTimeout(() => {
      dispatch({
        type: CLEAR_NOTIFICATION
      });
    }, 5000);
    dispatch({
      type: SET_NOTIFICATION,
      message: message
    });
  };
}

export function setErrorNotification(message: string): any {
  return (dispatch: any, getState: any) => {
    // fade notification after 5 seconds
    setTimeout(() => {
      dispatch({
        type: CLEAR_NOTIFICATION
      });
    }, 5000);
    dispatch({
      type: SET_ERROR_NOTIFICATION,
      message: message
    });
  };
}

export function setWarningNotification(
  message: string | React.ReactNode,
  isAutoClose: boolean = true
): any {
  return (dispatch: any, getState: any) => {
    // fade notification after 5 seconds
    if (isAutoClose) {
      setTimeout(() => {
        dispatch({
          type: CLEAR_NOTIFICATION
        });
      }, 5000);
    }
    dispatch({
      type: SET_WARNING_NOTIFICATION,
      message: message
    });
  };
}

export function clearNotification(): NotificationActionTypes {
  return {
    type: CLEAR_NOTIFICATION
  };
}
