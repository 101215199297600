import React from "react";

import "./styles.scss"
import { Card } from "react-bootstrap";

declare interface LyveCardProps {
  title: string
  subtitle: string | React.ReactNode
}

const LvyeCard: React.FC<LyveCardProps> = (props) => {
  return (
    <Card className="lyvecard">
      <div className="title">{props.title}</div>
      <div className="sub-title">{props.subtitle}</div>
      {props.children}
    </Card>
  );
};

export default LvyeCard;