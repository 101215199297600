import React from "react";
import cx from "classnames";
import { Alert } from "react-bootstrap";
import {
  CheckCircleFill,
  ExclamationCircleFill,
  ExclamationTriangleFill
} from "react-bootstrap-icons";
interface IOwnProps {
  variant?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "dark"
    | "light";
  dismissible?: boolean;
  show?: boolean;
  onClose?: () => void;
  closeLabel?: string;
  customClasses?: string;
  heading?: React.ReactNode;
  showIcon?: boolean;
}

const getIcon = (
  variantType:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "dark"
    | "light"
    | undefined
) => {
  switch (variantType) {
    case "success":
      return <CheckCircleFill size="16" color="#6ebe49" />;
    case "warning":
      return <ExclamationTriangleFill size="16" color="#f5af18" />;
    case "danger":
      return <ExclamationTriangleFill size="16" color="#fa5a65" />;
    case "info":
      return <ExclamationCircleFill size="16" color="#709dff" />;

    default:
      return null;
  }
};

const DefaultAlert: React.FC<IOwnProps> = ({
  variant,
  dismissible,
  show,
  onClose,
  closeLabel,
  customClasses,
  showIcon = false,
  heading,
  children
}) => {
  return (
    <Alert
      variant={variant}
      dismissible={dismissible}
      show={show}
      onClose={onClose}
      closeLabel={closeLabel}
      className={cx("lyve-alert", customClasses)}
    >
      {heading ? (
        <div className="d-flex align-items-center">
          {showIcon ? getIcon(variant) : null}
          <Alert.Heading className="ml-2">{heading}</Alert.Heading>
        </div>
      ) : null}
      <div
        className={`d-flex align-items-center ${
          heading && showIcon ? "ml-4" : ""
        }`}
      >
        {!heading && showIcon ? getIcon(variant) : null}
        <div className={!heading && showIcon ? "ml-2" : ""}>{children}</div>
      </div>
    </Alert>
  );
};

export default DefaultAlert;
