import React, { useEffect, useState } from "react";
import moment, { Moment } from "moment-timezone";

import Header, { CustomHeaderProp } from "./Header";
import MonthLayout, { MonthLayoutProps } from "./MonthLayout";

import "./styles.scss";

interface IOwnProps {
  selected?: Moment | null;
  min?: Moment;
  max?: Moment;
  startDate?: Moment;
  endDate?: Moment;
  yearsCount?: number;
  columnLayout?: number;
  selectsRange?: boolean;
  shortMonth?: boolean;
  customPicker?: boolean;
  onChange?: (
    date: [Moment] | [Moment | null, Moment | null],
    e?: React.MouseEvent
  ) => void;
  CustomHeader?: (props: CustomHeaderProp) => JSX.Element;
  CustomMonthsLayout?: (props: MonthLayoutProps) => JSX.Element;
}

interface MonthYearIndex {
  month: number | null;
  year: number | null;
}

const getCurrentCalendarDate = (
  min: Moment | undefined,
  max: Moment | undefined,
  yearsCount: number
) => {
  const current = moment();
  return min && min.get("year") < current.get("year")
    ? current.subtract(yearsCount - 1, "year").startOf("year")
    : current;
};

const isBeforeCurrentSelection = (
  monthIndex: number,
  year: number,
  monthYearIndex: MonthYearIndex
) => {
  if (monthYearIndex.month && monthYearIndex.year) {
    if (year < monthYearIndex.year) {
      return true;
    } else if (year === monthYearIndex.year) {
      return monthIndex < monthYearIndex.month;
    } else {
      return false;
    }
  }

  return false;
};

const initialMonthYearIndexes: [MonthYearIndex, MonthYearIndex] = [
  { month: null, year: null },
  { month: null, year: null }
];

const MonthPicker = ({
  selected,
  yearsCount = 1,
  columnLayout = 4,
  selectsRange = true,
  shortMonth = true,
  customPicker = false,
  onChange,
  CustomHeader,
  CustomMonthsLayout,
  ...dates
}: IOwnProps) => {
  // custom prop assignments
  const PickerHeader = CustomHeader || Header;
  const PickerMonthsLayout = CustomMonthsLayout || MonthLayout;

  // timezone
  const min = dates.min;
  const max = dates.max;
  const startDate = dates.startDate;
  const endDate = dates.endDate;

  // state variable
  const [current, setCurrent] = useState<Moment>(
    getCurrentCalendarDate(min, max, yearsCount)
  );
  const [monthYearIndexes, setMonthYearIndexes] = useState<
    [MonthYearIndex, MonthYearIndex]
  >(initialMonthYearIndexes);
  const [counter, setCounter] = useState<number>(0);

  useEffect(() => {
    if (startDate) {
      setMonthYearIndexes(prevState => [
        { month: startDate.get("month"), year: startDate.get("year") },
        prevState[1]
      ]);
      setCounter(1);
    }

    if (endDate) {
      setMonthYearIndexes(prevState => [
        prevState[0],
        { month: endDate.get("month"), year: endDate.get("year") }
      ]);
      setCounter(0);
    }
  }, [startDate, endDate]);

  const handleMonthClick = (
    monthIndex: number,
    year: number,
    e: React.MouseEvent
  ) => {
    let newMonthIndexes: [MonthYearIndex, MonthYearIndex] = [
      ...initialMonthYearIndexes
    ];

    const monthYearIndex = { month: monthIndex, year };
    if (selectsRange) {
      if (counter === 0) {
        newMonthIndexes = [monthYearIndex, initialMonthYearIndexes[1]];
      } else {
        // if selected a previous month than start date of the range
        if (isBeforeCurrentSelection(monthIndex, year, monthYearIndexes[0])) {
          newMonthIndexes = [monthYearIndex, initialMonthYearIndexes[1]];
        } else {
          newMonthIndexes = [monthYearIndexes[0], monthYearIndex];
        }
      }
    } else {
      newMonthIndexes = [monthYearIndex, initialMonthYearIndexes[1]];
    }

    const newDates: [Moment] | [Moment | null, Moment | null] = !selectsRange
      ? [
          moment({
            year,
            month: newMonthIndexes[0]?.month || 0
          })
        ]
      : [
          newMonthIndexes[0].month !== null
            ? moment({
                year: newMonthIndexes[0].year || 0,
                month: newMonthIndexes[0].month
              })
            : null,
          newMonthIndexes[1].month !== null
            ? moment({
                year: newMonthIndexes[1].year || 0,
                month: newMonthIndexes[1].month
              }).endOf("month")
            : null
        ];

    onChange?.(newDates, e);
  };

  const handleIncrease = () => setCurrent(s => moment(s).add(1, "year"));

  const handleDecrease = () => setCurrent(s => moment(s).subtract(1, "year"));

  return (
    <div className="lyve-month-picker">
      {Array.apply(null, Array(yearsCount)).map((_, index) => (
        <div key={index} className="lyve-month-picker__container">
          <PickerHeader
            year={current.get("year") + index}
            showPrev={index + 1 === 1}
            showNext={index + 1 === yearsCount}
            prevDisabled={min && current.get("year") + index <= min.get("year")}
            nextDisabled={max && current.get("year") + index >= max.get("year")}
            onIncrease={handleIncrease}
            onDecrease={handleDecrease}
          />
          <PickerMonthsLayout
            selected={selected}
            min={min}
            max={max}
            columnLayout={columnLayout}
            startDate={startDate}
            endDate={endDate}
            year={current.get("year") + index}
            selectsRange={selectsRange}
            shortMonth={shortMonth}
            onChange={(monthIndex: number, e: React.MouseEvent) => {
              handleMonthClick(monthIndex, current.get("year") + index, e);
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default MonthPicker;
