import React from "react";
import filesize from "filesize";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import moment from "moment";
import { convertFileSize } from "../../../../../utils";

interface PartnerTrendBarChart {
  partnerXData?: (string | null)[];
  partnerYData?: (number | null)[];
  averageValue: number;
  isCustomerPortal?: boolean
  loading?: boolean
}

const PartnerTrendBarChart: React.FC<PartnerTrendBarChart> = ({
  partnerXData,
  partnerYData,
  averageValue,
  isCustomerPortal = false,
  loading = false
}) => {
  // const convertFileSize = (value: number) => {
  // 	let convertedValue = filesize(value, { round: 2 });
  //
  // 	if (convertedValue.search('KB') > -1) {
  // 		convertedValue = convertedValue.replace('KB', 'KiB');
  // 		return convertedValue;
  // 	} else if (convertedValue.search('MB') > -1) {
  // 		convertedValue = convertedValue.replace('MB', 'MiB');
  // 		return convertedValue;
  // 	} else if (convertedValue.search('GB') > -1) {
  // 		convertedValue = convertedValue.replace('GB', 'GiB');
  // 		return convertedValue;
  // 	} else if (convertedValue.search('TB') > -1) {
  // 		convertedValue = convertedValue.replace('TB', 'TiB');
  // 		return convertedValue;
  // 	} else if (convertedValue.search('PB') > -1) {
  // 		convertedValue = convertedValue.replace('PB', 'PiB');
  // 		return convertedValue;
  // 	} else {
  // 		return convertedValue;
  // 	}
  // };

  const options = {
    chart: {
      type: "column",
      marginRight: 60,
      styledMode: true
    },
    title: {
      text: isCustomerPortal ? "" : "Total Usage Trend",
      align: "left"
    },
    credits: {
      enabled: false
    },
    xAxis: {
      labels: {
        formatter: function(y: any): string | null {
          if (loading) {
            return null
          }
          var month = "";

          try {
            if (isCustomerPortal) {
              const vals = y.value.split(' ')
              month = vals[0].slice(0, 3);
              month = month !== "Jan"
                ? month
                : `${month} ${vals[vals.length - 1]}`
            } else {
              month = y.value.split(" ")[0].slice(0, 3);
            }
          } catch (e) {}

          return month;
        },
        style: {
          fontSize: "9px"
        }
      },
      tickInterval: 1,
      categories: partnerXData,
      crosshair: {
        width: 0.5,
        color: "#a8a8a8"
      },
      title: ""
    },
    legend: {
      enabled: false
    },
    yAxis: {
      labels: {
        formatter: function(value: any): string {
          let num: number = Number(value.pos);
          return convertFileSize(num);
        },
        style: {
          fontSize: "9px"
        }
      },
      allowDecimals: false,
      gridLineDashStyle: "longdash",
      crosshair: false,
      title: "",
      plotLines: [
        {
          color: "lightgrey", // Color value
          value: averageValue, // Value of where the line will appear
          width: 2, // Width of the line
          label: {
            useHTML: true,
            text: "Avg.</br>" + convertFileSize(averageValue),
            align: "right",
            x: 50,
            y: -15,
            overflow: "allow",
            style: {
              fontSize: "9px"
            }
          }
        }
      ]
    },
    plotOptions: {
      pointStart: 1,
      series: {
        label: {
          connectorAllowed: false
        },
        color: "#6ebe49",
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          lineWidth: 1
        }
      }
    },

    series: [
      {
        data: partnerYData,
        states: {
          hover: {
            color: "#438027"
          }
        }
      }
    ],

    tooltip: {
      shared: true,
      useHTML: true,

      formatter: function(): any {
        var value: any = this;
        var str: string = "<table > ";
        var points: any = value.points;

        points.map((obj: any) => {
          var colorcode = obj.color;

          str =
            str +
            '<tr style="color: #ffffff;"> <td><span style="color: ' +
            colorcode +
            ';font-size:10px;">&#9673;</span>&nbsp;' +
            moment(value.x, "MMM DD, YYYY").format("MMM YYYY") +
            "&emsp;</td> <td> <b>" +
            convertFileSize(Number(obj.y)) +
            " </b></td></tr>";

          // str =
          // 	str +
          // 	'<tr style="color: #ffffff;"> <td><span style="color: #D3D3D3;font-size:10px;">&#9673;</span>&nbsp;Monthly Average &emsp;</td> <td> <b>' +
          // 	convertFileSize(averageValue) +
          // 	' </b></td></tr>';
        });

        str = str + "</table>";
        return str;
      },

      backgroundColor: "black",
      borderWidth: 2,
      borderColor: "black",
      style: {
        color: "#CCCCCC",
        opacity: 0.85
      }
    },

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 400,
            maxHeight: 400
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom"
            }
          }
        }
      ]
    }
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default PartnerTrendBarChart;
