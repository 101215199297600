import { Data as Recipient } from "./index";
const SET_NOTIFICATION_RECIPIENTS = "SET_NOTIFICATION_RECIPIENTS";

interface SetNotificationRecipientAction {
  type: typeof SET_NOTIFICATION_RECIPIENTS;
  payload: Recipient[];
}

interface NotificationRecipientState {
  recipientList: Recipient[];
}

const initialState: NotificationRecipientState = {
  recipientList: []
};

export function setNotificationRecipients(recipientList: Recipient[]): any {
  //console.log("yolo - ", recipientList);
  return {
    type: SET_NOTIFICATION_RECIPIENTS,
    payload: recipientList
  };
}

export function reducer(
  state = initialState,
  action: SetNotificationRecipientAction
) {
  switch (action.type) {
    case SET_NOTIFICATION_RECIPIENTS:
      return {
        ...state,
        recipientList: action.payload
      };
    default:
      return state;
  }
}
