import React from 'react';
import logo404 from '../../../images/404.svg';
import { DefaultErrorPage } from "../../../components/LyveErrorPage";

const NoMatchError: React.FC = () => {
	// storage.removeItem('help_loggedIn');
	return <DefaultErrorPage heading="Sorry, we can’t find the page you’re looking for." visualImage={logo404} />;
};

export default NoMatchError;
