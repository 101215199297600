import React, { useRef } from "react";
import { components, MenuProps } from "react-select";
import { Moment } from "moment-timezone";

import { DefaultButton } from "../../LyveButton";
import MonthPicker from "../../MonthPicker";
import useOnClickOutside from "../useOnClickOutside";
import { IListOption } from "./LyveDateRangeSelect";

interface IMenuProps extends MenuProps<IListOption, boolean> {
  startDate?: Moment;
  endDate?: Moment;
  defaultValue: IListOption;
  onSubmit: (option: IListOption) => void;
  onCancel: () => void;
  onDateClick?: (range: (Moment | undefined)[]) => void;
}

const Menu = (props: IMenuProps) => {
  const {
    onCancel,
    onSubmit,
    startDate,
    endDate,
    onDateClick,
    ...rest
  } = props;
  const selected = props.getValue()[0];
  const ref = useRef<any>(null);

  const handleCloseWithoutChange = () => {
    onCancel();
  };

  useOnClickOutside(ref, handleCloseWithoutChange);

  const handleChange = (range: any) => {
    onDateClick?.(range);
  };

  const monthPickerProps = {
    onChange: handleChange,
    ...(!!selected
      ? {
          selectsRange: selected.selectsRange,
          min: selected.min,
          max: selected.max,
          yearsCount: selected.yearsCount,
          customPicker: selected.isCustom,
          ...(selected.selectsRange
            ? {
                startDate,
                endDate
              }
            : { selected: startDate })
        }
      : {})
  };

  return (
    <components.Menu {...rest} innerRef={ref}>
      <div className="custom-menu-wrapper">
        <div className="custom-datepicker-wrapper">
          <div className="datepicker">
            <MonthPicker {...monthPickerProps} />
          </div>
          <div className="action-section">
            <DefaultButton
              variant="primary"
              size="sm"
              className="mr-2"
              disabled={
                selected.selectsRange ? !startDate || !endDate : !startDate
              }
              onClick={() =>
                onSubmit({
                  ...selected,
                  startDate,
                  endDate
                })
              }
            >
              Apply
            </DefaultButton>
            <DefaultButton
              onClick={handleCloseWithoutChange}
              variant="outline-secondary"
              size="sm"
            >
              Cancel
            </DefaultButton>
          </div>
        </div>
        {props.children}
      </div>
    </components.Menu>
  );
};

export default Menu;
