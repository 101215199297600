import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import api from "../../../../services/api/index";
import Modal from "react-bootstrap/Modal";
import Can from "../../../Can";
import { USERS_EDIT } from "../../../../authRules";
import { useDispatch, useSelector } from "react-redux";
import history from "../../../../history";
import { setNotificationRecipients } from "../../../NotificationRecipients/reducer";
import * as notificationActions from "./../../../Notification/actions";
import "./style.scss";
import { AppState } from "../../../../store";
import { WhoAmI } from "../../../../state/UserInfo/types";
import { validateUnicodeName } from "../../../../utils/functions";
import LyveLink from "../../../LyveHeadingLink/LyveLink";
import { getLink, linkNames } from "../../../LyveHeadingLink/LinkUtil";
import REGEX from "../../../../constants/regex";

interface Props {
  firstName: string;
  lastName: string;
  userEmail: string;
  userRole: string;
  editId?: string;
  emailList: string[];
  oldemail?: string;
  type: string;
  authType?: string | null;
  editingSelf?: boolean;
  SAMLConfigured?: boolean;
  mfa?: string;
  accountType: "partner" | "customer";
  customerId: string;
  closeModal: () => void;
  onSuccess?: (email: any) => void;
  onError?: () => void;
}

const CreateUser: React.FC<Props> = ({
  firstName,
  lastName,
  userEmail,
  userRole,
  editId,
  emailList,
  type,
  authType,
  editingSelf,
  SAMLConfigured,
  mfa,
  accountType,
  customerId,
  closeModal,
  onSuccess,
  onError
}) => {
  const dispatch = useDispatch();
  const whoAmI = useSelector<AppState, WhoAmI>(state => state.userInfo.whoAmI);
  const [firstname, setFirstname] = useState<string>(firstName);
  const [lastname, setLastname] = useState<string>(lastName);
  const [useremail, setUseremail] = useState<string>(userEmail);
  const [userrole, setUserrole] = useState<string>(userRole);
  const [userAuthType, setUserAuthType] = useState<"mfa" | "federated">(
    "federated"
  );
  const [isFormProcessing, setFormProcessing] = useState<boolean>(false);
  const [errors, setErrors] = useState<string>("");

  useEffect(() => {
    const input: any = document.querySelector("#firstName");
    input && input.focus();
  }, []);

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!editingSelf) {
      setUserrole((event.target as HTMLInputElement).value);
    }
  };

  const userRoles = () => {
    return (
      <RadioGroup
        aria-label="userRole"
        name="userRole"
        value={userrole}
        onChange={handleRoleChange}
        className="user-role-radio"
      >
        <FormControlLabel
          value="admin"
          control={<Radio />}
          className={`user-role ${userrole === "admin" &&
            "user-role-selected"} ${editingSelf && "user-role-disabled"}`}
          label={
            <div>
              <div className="user-role-heading">Admin</div>
              <div className="user-role-info">Full access</div>
            </div>
          }
        />
        {accountType === "partner" ? (
          <FormControlLabel
            value="customer-manager"
            control={<Radio />}
            className={`user-role ${userrole === "customer-manager" &&
              "user-role-selected"} ${editingSelf && "user-role-disabled"}`}
            label={
              <div>
                <div className="user-role-heading">Customer Manager</div>
                <div className="user-role-info">
                  Create and manage customer accounts, view usage stats
                </div>
              </div>
            }
          />
        ) : (
          <FormControlLabel
            value="storage-admin"
            control={<Radio />}
            className={`user-role ${userrole === "storage-admin" &&
              "user-role-selected"} ${editingSelf && "user-role-disabled"}`}
            label={
              <div>
                <div className="user-role-heading">Storage Admin</div>
                <div className="user-role-info">
                  Manage buckets, permissions and service accounts
                </div>
              </div>
            }
          />
        )}

        <FormControlLabel
          value="auditor"
          control={<Radio />}
          className={`user-role ${userrole === "auditor" &&
            "user-role-selected"} ${editingSelf && "user-role-disabled"}`}
          label={
            <div>
              <div className="user-role-heading">Auditor</div>
              <div className="user-role-info">Read-only access</div>
            </div>
          }
        />
      </RadioGroup>
    );
  };

  const [fNameCheck, setFNameCheck] = React.useState<boolean>(false);
  const [lNameCheck, setLNameCheck] = React.useState<boolean>(false);
  const [fNameError, setFNameError] = React.useState<boolean>(false);
  const [lNameError, setLNameError] = React.useState<boolean>(false);
  const [errorStrFName, setErrorStrFName] = React.useState<string>("");
  const [errorStrLName, setErrorStrLName] = React.useState<string>("");

  const validateEmail = () => {
    let emailValidation = new RegExp(
        REGEX.emailRegex
    );
    if (emailValidation.test(useremail)) {
      return true;
    }
  };

  const isDisabled = (): boolean => {
    return type === "add"
      ? validateEmail() &&
        !emailExists() &&
        validateUnicodeName(firstname) &&
        validateUnicodeName(lastname) &&
        !fNameCheck &&
        !lNameCheck &&
        !isFormProcessing
        ? false
        : true
      : validateEmail() &&
        validateUnicodeName(firstname) &&
        validateUnicodeName(lastname) &&
        !fNameCheck &&
        !lNameCheck &&
        !isFormProcessing &&
        (firstname !== firstName ||
          lastname !== lastName ||
          userrole !== userRole)
      ? false
      : true;
  };

  const handleSubmit = () => {
    handleUserModalSubmit(
      type,
      firstname.trim(),
      lastname.trim(),
      useremail.trim().toLowerCase(),
      userrole,
      userAuthType
    );
    // clearFields();
  };

  const clearFields = () => {
    setFirstname("");
    setLastname("");
    setUseremail("");
    setUserrole("admin");
  };

  const handleUserModalSubmit = (
    type: string,
    firstName: string,
    lastName: string,
    email: string,
    userRole: string,
    userAuthType: string
  ) => {
    let user_auth_type: string = userAuthType;
    if (!SAMLConfigured) {
      user_auth_type = "mfa";
    }
    type === "add"
      ? handleAddUser(firstName, lastName, email, userRole, user_auth_type)
      : handleEditUser(firstName, lastName, userRole);
  };

  const handleAddUser = (
    firstName: string,
    lastName: string,
    email: string,
    userRole: string,
    userAuthType: string
  ) => {
    // if (!formIsValid()) return;
    setFormProcessing(true);
    api
      .userAddInvitation(firstName, lastName, email, userRole, userAuthType)
      .then(() => {
        Promise.all([
          api.getAllNotificationRecipients(customerId),
          api.listUsers()
        ]).then(values => {
          let notificationRecipientList = values[0];
          let userList = values[1];
          if (typeof onSuccess !== "undefined") {
            onSuccess(userList);
          }
          dispatch(
            setNotificationRecipients(
              notificationRecipientList.notificationRecipient
            )
          );
          clearFields();
          if (userAuthType !== "federated") {
            dispatch(
              notificationActions.setNotification(
                ` Email has been sent to ${email}.`
              )
            );
          } else {
            dispatch(
              notificationActions.setNotification(` The user has been added.`)
            );
          }
          setFormProcessing(false);
          if (typeof closeModal !== "undefined") {
            closeModal();
          }
        });
      })
      .catch(err => {
        setFormProcessing(false);
        setErrors(err);
      });
  };

  const handleEditUser = (
    firstName: string,
    lastName: string,
    userRole: string
  ) => {
    // if (!formIsValid()) return;
    const edit_id = editId ? editId : "";
    setFormProcessing(true);
    api
      .updateUserDetails(edit_id, firstName, lastName, userRole)
      .then(() => {
        setFormProcessing(false);
        Promise.all([
          api.getAllNotificationRecipients(customerId),
          api.listUsers()
        ]).then(values => {
          let notificationRecipientList = values[0];
          let userList = values[1];
          dispatch(
            setNotificationRecipients(
              notificationRecipientList.notificationRecipient
            )
          );
          if (typeof onSuccess !== "undefined") {
            onSuccess(userList);
          }
          clearFields();
          dispatch(
            notificationActions.setNotification(
              `Details for ${userEmail} have been successfully updated${
                userEmail === whoAmI.email
                  ? ".The change will take effect after you logout and login again."
                  : ""
              }`
            )
          );
        });

        if (typeof closeModal !== "undefined") {
          closeModal();
        }
      })
      .catch(err => {
        setFormProcessing(false);
        setErrors(err);
      });
  };

  const changeTab = () => {
    clearFields();
    accountType === "partner"
      ? history.push("/partner/saml-federation")
      : history.push("/customer/saml-federation");

    if (typeof closeModal !== "undefined") {
      closeModal();
    }
  };

  const emailExists = () => {
    return emailList.includes(useremail) ? true : false;
  };

  const handleChangeLName = (e: any) => {
    // Check if lenght is less than 64
    if (e.target.value.length > 64) {
      setLNameCheck(true);
      setLNameError(true);
      setErrorStrLName("Last Name cannot be more than 64 characters");
    } else {
      // Check if name string is valid
      if (validateUnicodeName(e.target.value)) {
        setLNameError(false);
        setLNameCheck(false);
      } else {
        if (e.target.value.length > 0) {
          setLNameError(true);
          setErrorStrLName("Only alphanumeric, '-' or space is allowed.");
          setLNameCheck(true);
        }
      }
    }
    setLastname(e.target.value);
  };

  const handleChangeFName = (e: any) => {
    // Check if lenght is less than 64
    if (e.target.value.length > 64) {
      setFNameCheck(true);
      setFNameError(true);
      setErrorStrFName("First Name cannot be more than 64 characters");
    } else {
      // Check if name string is valid
      if (validateUnicodeName(e.target.value)) {
        setFNameError(false);
        setFNameCheck(false);
      } else {
        if (e.target.value.length > 0) {
          setFNameError(true);
          setErrorStrFName("Only alphanumeric, '-' or space is allowed.");
          setFNameCheck(true);
        }
      }
    }
    setFirstname(e.target.value);
  };

  return (
    <>
      <Modal.Header style={{ padding: "1rem 0" }}>
        <Modal.Title id="contained-modal-title-vcenter">
          {type === "add" ? "Add New User" : "Edit User"}
          <LyveLink
            link={
              type === "add"
                ? getLink(linkNames.Add_New_User)
                : getLink(linkNames.Edit_New_User)
            }
          />
        </Modal.Title>
      </Modal.Header>

      <Form
        onSubmit={(e: any) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Form.Group controlId="firstName">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            // placeholder="First Name"
            value={firstname}
            onChange={(e: any) => handleChangeFName(e)}
            // autoFocus={true}
            isInvalid={fNameError}
          />
          {/* <Form.Control.Feedback type="invalid">
            Only alphanumeric, '-' or space is allowed.
          </Form.Control.Feedback> */}
          {fNameError && (
            <div className="text-danger mb-1" style={{ fontSize: "12px" }}>
              {errorStrFName}
            </div>
          )}
        </Form.Group>
        <Form.Group controlId="lastName">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            // placeholder="Last Name"
            value={lastname}
            onChange={(e: any) => handleChangeLName(e)}
            isInvalid={lNameError}
          />
          {lNameError && (
            <div className="text-danger mb-1" style={{ fontSize: "12px" }}>
              {errorStrLName}
            </div>
          )}
          {/* <Form.Control.Feedback type="invalid">
            Only alphanumeric, '-' or space is allowed.
          </Form.Control.Feedback> */}
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            // placeholder="Enter email"
            value={useremail}
            onChange={(e: any) => setUseremail(e.target.value)}
            isInvalid={
              useremail.length > 0 && !validateEmail()
              // || checkError()
            }
            readOnly={type === "edit" ? true : false}
          />
          <Form.Control.Feedback type="invalid">
            {"Please enter a valid email address."}
          </Form.Control.Feedback>
          {type === "add" && emailExists() && (
            <div className="text-danger">
              This email address is already being used. Please enter a different
              one.
            </div>
          )}
        </Form.Group>

        <Form.Group>
          <Form.Label>Role</Form.Label>
          {userRoles()}
        </Form.Group>
        {type === "add" ? (
          !SAMLConfigured ? (
            <Can perform={USERS_EDIT}>
              <div>
                Authentication type is:{"   "}
                <span style={{ fontWeight: 600 }}>
                  {mfa === "passwordOnly" ? "Password" : "Multi-Factor"}
                </span>
              </div>
              <div style={{ fontWeight: 550, fontSize: "14px" }}>
                * To enable Federated Authentication, please click{" "}
                <span className="configure-link" onClick={changeTab}>
                  here
                </span>
              </div>
            </Can>
          ) : (
            <Form.Group controlId="authType">
              <Form.Label>Authentication type:</Form.Label>
              <Form.Control
                as="select"
                // custom
                value={userAuthType}
                onChange={(e: any) => setUserAuthType(e.target.value)}
              >
                <option value={"federated"}>Federated</option>
                {mfa === "passwordOnly" ? (
                  <option value={"mfa"}>Password</option>
                ) : (
                  <option value={"mfa"}>Multi-Factor</option>
                )}
              </Form.Control>
            </Form.Group>
          )
        ) : (
          <Can perform={USERS_EDIT}>
            <div>
              ** Authentication type is {authType}. <br />
              {useremail === whoAmI.email
                ? "** The change will take effect after you logout and login again"
                : null}
            </div>
          </Can>
        )}
        {!isFormProcessing && errors && (
          <div className="text-danger">
            Failed to {type === "add" ? "add" : "edit"} user : {errors}
          </div>
        )}

        <div className="pt-3">
          <Button type="submit" disabled={isDisabled()}>
            {isFormProcessing ? (
              <div className="d-flex justify-content-center">
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-1"
                />
                {type === "add" ? "Adding" : "Editing"}
              </div>
            ) : type === "add" ? (
              "Add User"
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default CreateUser;
