import React from "react";
import { ReactComponent as Logo } from "../../images/Lyve-Cloud-Logo-Horiz-GRN-nopad-326x24.svg";

import "./styles.scss";

const LyveGoodBye: React.FC = () => (
  <div className="lyve-goodbye">
    <div className="container">
      <Logo width={326} />
      <div className="info">
        <h2 className="title">
          {" "}
          Your request to cancel Lyve Cloud has been successfully submitted{" "}
        </h2>
        <p className="subtitle">
          {" "}
          If this was done by mistake, please contact{" "}
          <a href="mailto:support.lyvecloud@seagate.com">
            {" "}
            Lyve Cloud Support{" "}
          </a>{" "}
          immediately.{" "}
        </p>
      </div>
    </div>
  </div>
);

export default LyveGoodBye;
