import React from "react";
import { Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { DefaultButton } from "../../../LyveButton";
import cx from "classnames";
import { useAppSelector } from "../../../../store";
import { Bucket, selectBuckets } from "../../../../state/buckets";
import { fetchBuckets } from "../../helpers";
import { fetchRegions } from "../../../../utils/api";
import { selectRegions } from "../../../../state/regions";
import { LyveSelect } from "../../../LyveDropdown";
import { PrepareBucketProps, FormInputs } from "./types";
import api from "../../../../services/api";
import {
  ControlledInput,
  validateChars,
  validateEdges,
  validateLength,
  ValidationMessage,
  validators
} from "./helpers";

import "./styles.scss";
import clsx from "clsx";
import { StoreStateStatus } from "../../../../state/types";
import { ISelectOption } from "../../../LyveDropdown/LyveSelect";

const PrepareBucket: React.FC<PrepareBucketProps> = props => {
  const bucketInfo = useAppSelector(selectBuckets);
  const regionInfo = useAppSelector(selectRegions);

  const [isFormValid, setIsFormValid] = React.useState(false);

  const [bucketInputInFocus, setBucketInputInFocus] = React.useState(false);
  const setFocus = () => setBucketInputInFocus(true);
  const setBlur = () => setBucketInputInFocus(false);

  const defaultBucketName = `${props.accountName}-bucket-${props.runId}`;
  const defaultRegion = [
    {
      label: regionInfo.data[0].description,
      value: regionInfo.data[0].name,
      url: regionInfo.data[0].url,
      default: regionInfo.data[0].default
    }
  ];
  const [selectedRegion, setSelectedRegion] = React.useState<object>(
    defaultRegion[0]
  );
  const {
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting, errors }
  } = useForm<FormInputs>({
    defaultValues: {
      bucketName: defaultBucketName,
      region: defaultRegion[0]
    },
    mode: "onSubmit"
  });
  const { bucketName, region } = watch();

  const validateBucketNotExists = (buckets: Bucket[], bucketName: string) =>
    !!bucketName && buckets.every(bucket => bucket.name !== bucketName);

  const isValidBucketName = () => {
    return validators.every(validator => validator(bucketName));
  };

  const onSubmit = (value: FormInputs) => {
    if (isFormValid && !!value.region) {
      props.handleBucketName(bucketName, {
        description: value.region.label,
        name: value.region.value,
        url: value.region.url,
        default: value.region.default
      });
    }
  };

  React.useEffect(() => {
    const isFormValid = isValidBucketName();
    setIsFormValid(isFormValid);
  }, [bucketName]);

  React.useEffect(() => {
    props.setIsSubmitting(isSubmitting);
  }, [isSubmitting]);

  React.useEffect(() => {
    fetchRegions();
  }, []);

  React.useEffect(() => {
    fetchBuckets();
  }, []);

  const regionPickerOptions = regionInfo.data.map(region => ({
    label: region.description,
    value: region.name,
    url: region.url,
    default: region.default
  }));

  const handleRegionChange = (option: ISelectOption) => {
    setSelectedRegion(option);
  };

  const validateBucketExists = async (value: string) => {
    let buckets: Bucket[] | Error = bucketInfo.data;
    if (bucketInfo.status !== "resolved") {
      buckets = await fetchBuckets();
    }
    let isBucketExists = false;
    if (!bucketInputInFocus) {
      await api
        .isBucketExists(value)
        .then(res => {
          isBucketExists = false;
        })
        .catch(e => {
          isBucketExists = true;
        });
    }
    const err =
      "The bucket name is already in use. Please use a different name or edit the existing bucket name.";
    if (isBucketExists) {
      return err;
    }

    if (buckets instanceof Array) {
      return !value || validateBucketNotExists(buckets, value) || err;
    }
    return "Something went wrong.";
  };

  const isBucketNameValid =
    validateLength(bucketName) &&
    validateChars(bucketName) &&
    validateEdges(bucketName);

  return (
    <div className="fill-bucket-name">
      <div className="subtitle">
        Create your first bucket and start using it in minutes
      </div>
      <Form className={cx("form")} onSubmit={handleSubmit(onSubmit)}>
        <ControlledInput
          label="Bucket Name"
          control={control}
          name="bucketName"
          setFocus={setFocus}
          setBlur={setBlur}
          error={errors.bucketName?.message}
          rules={{
            validate: { validateBucketExists }
          }}
          controlProps={{
            className: cx(
              "controlled-input",
              errors.bucketName?.message && "controlled-input-error"
            )
          }}
        >
          <Form.Control
            type="text"
            isInvalid={!isBucketNameValid && !bucketInputInFocus}
          />
        </ControlledInput>
        <div
          className={cx(
            "validations",
            (defaultBucketName === bucketName ||
              errors.bucketName?.message ||
              !bucketInputInFocus) &&
              "d-none"
          )}
        >
          <ValidationMessage
            message="Length must be between 3 and 63"
            value={bucketName}
            validator={validateLength}
          />
          {/*<ValidationMessage*/}
          {/*  message="No spaces, no upper case, no underscores"*/}
          {/*  value={bucketName}*/}
          {/*  validator={validateSpecialChars}*/}
          {/*/>*/}
          <ValidationMessage
            message='Only lowercase characters, numbers, and "-" allowed'
            value={bucketName}
            validator={validateChars}
          />
          <ValidationMessage
            message="Start with a number or a lower-case character. The bucket name can be
          an alpha-numeric string."
            value={bucketName}
            validator={validateEdges}
          />
        </div>

        <ControlledInput
          name="region"
          label="Region (Metro)"
          control={control}
          error={errors.region?.message}
          rules={{ required: "Region is required." }}
        >
          <LyveSelect
            placeholder="Select region"
            options={regionPickerOptions}
            value={selectedRegion}
            onChange={handleRegionChange}
            className={clsx(
              "region-picker",
              errors.region?.message && "region-picker-error"
            )}
            isLoading={regionInfo.status === StoreStateStatus.LOADING}
            isSearchable={false}
          />
        </ControlledInput>

        <DefaultButton
          className={`create-bucket-btn ${isSubmitting &&
            "create-bucket-btn-loading"}`}
          variant="dark"
          type="submit"
          disabled={!isFormValid || !!errors.bucketName?.message || !region}
        >
          {isSubmitting ? (
            <Spinner animation="border" role="status" size="sm" />
          ) : (
            "Create Bucket"
          )}
        </DefaultButton>
      </Form>
    </div>
  );
};

export default PrepareBucket;
