import React from "react";
import { Spinner } from "react-bootstrap";

interface IOwnProps {
  animation: "border" | "grow";
  className?: string;
}

const ButtonLoader: React.FC<IOwnProps> = props => {
  return (
    <Spinner
      as="span"
      size="sm"
      role="status"
      aria-hidden="true"
      {...props}
    ></Spinner>
  );
};

export default ButtonLoader;
