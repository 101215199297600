import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { DefaultButton } from "../../../../../../components/LyveButton";
import api from "../../../../../../services/api";
import history from "../../../../../../history";
import "./styles.scss";
import useSmartStartKit from "../../../../../SmartStartHere/useSmartStartKit";
import useGlobalContext from "../../../../../../state/GlobalStateContext";
import regionApis from "../../../../../../services/RegionApis";
interface DeletePromptProps {
  regionUrl?: string;
  bucket: string;
  closeModal: () => void;
  onSuccess?: (data: any) => void;
  onError?: (err: any) => void;
  bucketType?: "standard" | "archival"
}

const DeletePrompt: React.FC<DeletePromptProps> = ({
  regionUrl,
  bucket,
  closeModal,
  bucketType = "standard"
}) => {
  console.log("regg", regionUrl);
  const isStandardBucket = bucketType === "standard"
  const { shouldCelebrate, setShouldCelebrate } = useGlobalContext();
  const { setUserConfig } = useSmartStartKit();
  const [processing, setProcessing] = useState<boolean>(false);
  const [deletionError, setDeletionError] = useState<string>("");
  const [canDeleteBucket, setCanDeleteBucket] = useState<boolean>(false);
  const [checkingCanDelete, setCheckingCanDelete] = useState<boolean>(true);

  useEffect(() => {
    // if (openDeletionModal) {
    setCheckingCanDelete(true);
    regionApis
      .canDeleteBucket(regionUrl || "", bucket)
      .then(resp => {
        if (resp.status) {
          setCanDeleteBucket(true);
        } else {
          setCanDeleteBucket(false);
          setDeletionError(resp.error);
        }
        setCheckingCanDelete(false);
      })
      .catch(err => {
        console.log("failed to check: ", err);
        setCheckingCanDelete(false);
        setDeletionError("Failed to check if the bucket can be deleted");
      });
    // }
  }, []);

  const deleteBucket = (name: string) => {
    setProcessing(true);
    regionApis
      .deleteBucket(regionUrl || "", name, bucketType)
      .then(resp => {
        setProcessing(false);
        api.getSmartStartConfig().then(setUserConfig);
        if (shouldCelebrate) {
          setShouldCelebrate(false);
        }

        history.push("/customer/buckets");
        if (typeof closeModal !== "undefined") {
          closeModal();
        }
      })
      .catch(error => {
        console.log("failed to delete bucket ", error);
        setProcessing(false);
        if (typeof closeModal !== "undefined") {
          closeModal();
        }
      });
  };

  const deletionErrors = () => {
    const errorArray = deletionError.split(",");
    return (
      <div className="p-2 bucket-delete-error">
        <div className="delete-header">This bucket cannot be deleted: </div>
        <ul className="delete-ul">
          {errorArray.map(err => (
            <li>{err}.</li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="delete-bucket-module">
      <h4 className="delete-bucket-title">Delete {!isStandardBucket && "Archive"} Bucket</h4>
      {canDeleteBucket && (
        <h4 className="delete-bucket-title2">
          Are you sure you want to delete this bucket?
        </h4>
      )}

      {checkingCanDelete ? (
        <div className="d-flex justify-content-center">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="mr-1"
          />
        </div>
      ) : !canDeleteBucket ? (
        deletionErrors()
      ) : (
        <div className="delete-bucket-subtitle">
          This action will permanently delete the bucket. You can't undo this
          action.
        </div>
      )}
      <div>
        <div className="bucket-label">Bucket Name:</div>
        <div className="bucket-name">{bucket}</div>
      </div>
      {checkingCanDelete ? null : !canDeleteBucket ? (
        <Button onClick={closeModal}>ok</Button>
      ) : (
        <div>
          <DefaultButton
            className="mr-2"
            variant="danger"
            disabled={processing}
            onClick={() => {
              deleteBucket(bucket);
            }}
          >
            {processing ? (
              <Spinner animation="border" role="status" className="spinner">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              "Yes"
            )}
          </DefaultButton>
          <DefaultButton
            disabled={processing}
            onClick={closeModal}
            variant="outline-secondary"
          >
            No
          </DefaultButton>
        </div>
      )}
    </div>
  );
};

export default DeletePrompt;
