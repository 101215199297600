import React, { useState, createRef, useImperativeHandle } from 'react';
import Dropzone, { DropzoneProps } from 'react-dropzone';
import './styles.scss';

type DropEvent = React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement> | DragEvent | Event;
interface FileError {
	message: string;
	code: 'file-too-large' | 'file-too-small' | 'too-many-files' | 'file-invalid-type';
}
interface FileRejection {
	file: File;
	errors: FileError[];
}

interface customProps {
	p1?: string;
	p2?: string;
}

interface IOwnProps extends DropzoneProps, customProps {
	dropzoneRef?: any;
	/** Set accepted File Types, can be a string/ string[] */
	accept?: any;

	/**Enable/Disable the dropzone */
	disabled?: boolean;

	/**
   * Use this to provide a custom file aggregator
   *
   * @param {(DragEvent|Event)} event A drag event or input change event (if files were selected via the file dialog)
   */
	getFilesFromEvent?: (event: DropEvent) => Promise<(File | DataTransferItem)[]>;

	/** Maximum file size (in bytes) */
	maxSize?: number;

	/** Minimum file size (in bytes) */
	minSize?: number;

	/**
   * Allow drag 'n' drop (or selection from the file dialog) of multiple files
   */
	multiple?: boolean;

	/**
   * Maximum accepted number of files
   * The default value is 0 which means there is no limitation to how many files are accepted.
   */
	maxFiles?: number;

	/**
   * Cb for when the `dragenter` event occurs.
   *
   * @param {DragEvent} event
   */
	onDragEnter?: (e: React.DragEvent<HTMLElement>) => void;

	/**
   * Cb for when the `dragleave` event occurs
   *
   * @param {DragEvent} event
   */
	onDragLeave?: (e: React.DragEvent<HTMLElement>) => void;

	/**
   * Cb for when the `dragover` event occurs
   *
   * @param {DragEvent} event
   */
	onDragOver?: (e: React.DragEvent<HTMLElement>) => void;

	/*
   * @param {File[]} acceptedFiles
   * @param {FileRejection[]} fileRejections
   * @param {(DragEvent|Event)} event A drag event or input change event (if files were selected via the file dialog)
   */
	onDrop?: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void;

	/**
   * Cb for when the `drop` event occurs.
   * Note that if no files are accepted, this callback is not invoked.
   *
   * @param {File[]} files
   * @param {(DragEvent|Event)} event
   */
	onDropAccepted?: <T extends File>(files: T[], event: DropEvent) => void;

	/**
   * Cb for when the `drop` event occurs.
   * Note that if no files are rejected, this callback is not invoked.
   *
   * @param {FileRejection[]} fileRejections
   * @param {(DragEvent|Event)} event
   */
	onDropRejected?: (fileRejections: FileRejection[], event: DropEvent) => void;

	/** Cb for when closing the file dialog with no selection */
	onFileDialogCancel?: () => void;

	/** If false, allow dropped items to take over the current browser window */
	preventDropOnDocument?: boolean;

	/** If true, disables click to open the native file selection dialog */
	noClick?: boolean;

	/**
   * If true, disables SPACE/ENTER to open the native file selection dialog.
   * Note that it also stops tracking the focus state.
   */
	noKeyboard?: boolean;

	/** If true, disables drag 'n' drop */
	noDrag?: boolean;

	/** If true, stops drag event propagation to parents */
	noDragEventsBubbling?: boolean;

	/** Active drag is in progress */
	isDragActive?: boolean;

	/** Dragged files are accepted */
	isDragAccept?: boolean;

	/** Some dragged files are rejected */
	isDragReject?: boolean;
}
const DropZone = React.forwardRef(
	(
		{
			p1,
			p2,
			accept,
			disabled,
			getFilesFromEvent,
			maxSize,
			minSize,
			multiple = false,
			maxFiles,
			onDragEnter,
			onDragLeave,
			onDragOver,
			onDrop,
			onDropAccepted,
			onDropRejected,
			onFileDialogCancel,
			preventDropOnDocument,
			noClick,
			noKeyboard,
			noDrag,
			noDragEventsBubbling,
			isDragActive,
			isDragAccept,
			isDragReject
		}: IOwnProps,
		ref
	) => {
		const [onDragStyle, setOnDragStyle] = useState(false);

		const dropzoneRef = createRef<any>();
		useImperativeHandle(ref, () => ({
			showFileDialog() {
				if (dropzoneRef.current) {
					dropzoneRef.current.open();
				}
			}
		}));

		return (
			<Dropzone
				ref={dropzoneRef}
				onDrop={onDrop}
				maxFiles={maxFiles}
				maxSize={maxSize}
				onDropRejected={onDropRejected}
				onFileDialogCancel={() => setOnDragStyle(false)}
			>
				{({ getRootProps, getInputProps }) => (
					<section className="custom-dropzone-container container">
						<div
							{...getRootProps({
								className: `dropzone ${onDragStyle ? 'on-drag-style' : ''}`,
								onDragOver: () => setOnDragStyle(true),
								onDragLeave: () => setOnDragStyle(false)
							})}
						>
							<input className="form-control" id="custom-dropzone-input" {...getInputProps()} />
							<p>
								{p1}
								<a href="#"> {p2}</a>
							</p>
						</div>
					</section>
				)}
			</Dropzone>
		);
	}
);
export default DropZone;
