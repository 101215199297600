import React from "react";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";

export interface CustomHeaderProp {
  year: string | number;
  showPrev?: boolean;
  showNext?: boolean;
  prevDisabled?: boolean;
  nextDisabled?: boolean;
  onIncrease: () => void;
  onDecrease: () => void;
}

const Header = (props: CustomHeaderProp) => {
  return (
    <div className="lyve-month-picker__header">
      {!!props.showPrev && (
        <button
          className="lyve-month-picker__arrows prev"
          onClick={props.onDecrease}
          disabled={props.prevDisabled}
        >
          <ChevronLeft size="16" />
        </button>
      )}
      <span className="lyve-month-picker__year">{props.year}</span>
      {!!props.showNext && (
        <button
          className="lyve-month-picker__arrows next"
          onClick={props.onIncrease}
          disabled={props.nextDisabled}
        >
          <ChevronRight size="16" />
        </button>
      )}
    </div>
  );
};

export default Header;
