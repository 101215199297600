import request from "superagent";
import storage from "local-storage-fallback";
import common from "../common";

export class SalesforceAPI {
  // Request basics
  /*requestWithAuth(method: string, url: string) {
    const req = request(method, url)
      .withCredentials()
      .set("Content-Type", "application/json")
      .set("Cookie", `${document.cookie}`);
    return req;
  }*/

  requestWithAuth(method: string, url: string) {
    const token: string = window.sessionStorage.getItem("token")!;
    return request(method, url).set("sessionId", token);
  }

  makeRequest(method: string, url: string, data?: object) {
		return this.requestWithAuth(method, url)
			.send(data)
			.then((res) => res.body)
			.catch((err) => this.errorHandler(err));
	}

  errorHandler(err: any) {
    // console.log(err);
    if (err.status) {
      const errMessage: string = (err.response.body && err.response.body.error) || err.status.toString(10);
      // console.log(err.response.body);
      let code = "" 
      if (err.response.body !== null){
        code = err.response.body.code;
      }
      // console.log(errMessage);
      if (err.status === 401) {
        common.logoutCleanup(null);
      } else {
      return Promise.reject({message: errMessage, code: code});
      }
    } else {
      return Promise.reject("Unknown error");
    }
  }

  /** Support APIs */
  listTickets(Partner: string = "", Customer: string = "", isAnyCustomer: boolean) {
    const url = isAnyCustomer ? "/api/v2/master/tickets" : `/api/v2/partner/tickets?AccountName=&Customer=`;
    return this.makeRequest("GET", url);
  }

  /*
   * Support API ticket count
   */
  getTicketCount(Partner: string = "", Customer: string = "", isAnyCustomer: boolean) {
    const url = isAnyCustomer ? '/api/v2/master/ticket-count' : `/api/v2/partner/ticket-count?AccountName=&Customer=`;
    return this.makeRequest("GET", url);
  }

  getTicketsSummary() {
    const url = `/api/v2/partner/tickets-summary`;
    return this.makeRequest("GET", url);
  }

  /*
   * Support API Get Tenants
   */
  getTenants() {
    const url = "/api/v2/partner/tenants";
    return this.makeRequest("GET", url);
  }

  /** Support APIs */
  getTicketDetails(casenumber: string | undefined) {
    const url = "/api/v2/partner/ticket/" + casenumber;
    return this.makeRequest("GET", url);
  }

  getAttachedFile(filenumber: string | undefined) {
    const url = "/api/v2/partner/ticket/file/" + filenumber;
    return this.makeRequest("GET", url);
  }

  createTicket(ticket: any) {
    const url = "/api/v2/partner/ticket";
    return this.makeRequest("POST", url, ticket);
  }

  updateTicket(ticket: any) {
    const url = "/api/v2/partner/ticket";
    return this.makeRequest("PUT", url, ticket);
  }
}

const salesforceApi = new SalesforceAPI();
export default salesforceApi;
