import api from "../../../../services/api";
import React from "react";
import useAddBucketCard from "../AddBucketContext";
import { toCamelCaseKeys } from "../../../../utils";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  Permission,
  rejectPermissions,
  selectPermissions
} from "../../../../state/permissions";
import {
  rejectServiceAccounts,
  selectServiceAccounts,
  ServiceAccount
} from "../../../../state/serviceAccounts";
import { Region } from "../../../../state/regions";
import regionApis from "../../../../services/RegionApis";

declare type UseResourcesParams = {
  accountName: string;
  runId: string;
  bucketName: string;
  info: any;
  region: Region | null;
};

// interface ListPermissionResponse {
//   permissions: Permission[];
// }
//
// interface ListAccountsResponse {
//   service_accounts: ServiceAccount[];
// }
//
// const validatePermissionName = (permissions: Permission[], name: string) =>
//   permissions.every(perm => perm.name !== name);

// const validateServiceAccountName = (accounts: ServiceAccount[], name: string) =>
//   accounts.every(account => account.name !== name);
//
// const constructPermissionList = (res: ListPermissionResponse) => {
//   let permissionList: Permission[] = [];
//   if (res && res.permissions) {
//     // temporarily construct usage field
//     permissionList = res.permissions.map(
//       (b: Permission): Permission => {
//         return {
//           ...b,
//           SACount: b.SACount ? b.SACount : 0
//         };
//       }
//     );
//   }
//   return permissionList;
// };
//
// const constructServiceAccountsList = (res: ListAccountsResponse) => {
//   let accountsList: ServiceAccount[] = [];
//   if (res && res.service_accounts) {
//     // temporarily construct usage field
//     accountsList = res.service_accounts.map(
//       (b: ServiceAccount): ServiceAccount => {
//         return {
//           ...b
//         };
//       }
//     );
//   }
//   return accountsList;
// };

export const useResources = ({
  info,
  accountName,
  runId,
  bucketName,
  region
}: UseResourcesParams) => {
  const dispatch = useAppDispatch();
  const {
    createdResources,
    loadResource,
    setResource,
    rejectResource
  } = useAddBucketCard();

  const { startHereBucket, bucket, permission, serviceAccount } = createdResources;
  const defaultBucketName = `${accountName}-bucket-${runId}`;

  const isMountedRef = React.useRef(true);
  React.useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // const fetchPermissions: () => Promise<Permission[] | Error> = () => {
  //   return api
  //     .listPermissions()
  //     .then(constructPermissionList)
  //     .catch(error => {
  //       const err = new Error(error);
  //       dispatch(rejectPermissions(err));
  //       return err;
  //     });
  // };

  // const fetchServiceAccounts: () => Promise<ServiceAccount[] | Error> = () => {
  //   return api
  //     .listServiceAccounts()
  //     .then(constructServiceAccountsList)
  //     .catch(error => {
  //       const err = new Error(error);
  //       dispatch(rejectServiceAccounts(err));
  //       return error;
  //     });
  // };

  // const taskStatusChecker = async (id: string, action_type: string) =>{
  //   let taskCompleted = false;
  //   await api.taskStatus(id,action_type)
  //     .then(data => {
  //       if(data.status == "Applied"){
  //         taskCompleted = true;
  //       } else {
  //         taskCompleted =  false;
  //         setTimeout(function (){
  //           taskStatusChecker(id, action_type);
  //         },3000);
  //       }
  //       return taskCompleted;
  //     });
  // };

  // const createPermission = async (windowObj: any) => {
  //   let permissions: Permission[] | Error = permissionInfo.data;
  //   if(listRegionsWithSGInfo.status !== "resolved"){
  //     await fetchRegionsWithSG();
  //   }
  //   try {
  //     if (permissionInfo.status !== "resolved") {
  //       permissions = await fetchPermissions();
  //     }
  //     if (
  //       (permission.status === "idle" || permission.status === "rejected") &&
  //       isMountedRef.current &&
  //       permissions instanceof Array
  //     ) {
  //       loadResource("permission");
  //       let name = `${accountName}-permission-${runId}`;
  //       if (
  //         defaultBucketName !== bucketName &&
  //         validatePermissionName(permissions, bucketName)
  //       ) {
  //         name = bucketName;
  //       }
  //       windowObj.permissionName = name;
  //       const baseUrl: string | "" = sgregionlist ? sgregionlist[0].url : "";
  //       const data = await regionApis.addPermission(
  //         name,
  //         `Allow all operations on bucket ${bucketName}`,
  //         "Allow",
  //         [bucketName],
  //         ["readwrite"],
  //         "selection",
  //         "",
  //          baseUrl
  //       );
  //       if (data && data.id) {
  //         setResource("permission", { id: data.id, name });
  //         return data.id;
  //       }
  //     }
  //     info.current = new Error("Failed to create resource");
  //     rejectResource("permission");
  //   } catch (e) {
  //     info.current = new Error(e);
  //     rejectResource("permission");
  //   }
  // };
  //
  // const fetchServiceAccountVault = async (id: string) => {
  //   const vault = await api.fetchSecretandAcessKey(id)
  //   return toCamelCaseKeys(vault)
  // }

  // const createServiceAccount = async (permissionId: any, windowObj: any) => {
  //   let accounts: ServiceAccount[] | Error = serviceAccountInfo.data;
  //   try {
  //     if (serviceAccountInfo.status !== "resolved") {
  //       accounts = await fetchServiceAccounts();
  //     }
  //     if (
  //       (serviceAccount.status === "idle" ||
  //         serviceAccount.status === "rejected") &&
  //       isMountedRef.current &&
  //       accounts instanceof Array
  //     ) {
  //       loadResource("serviceAccount");
  //       let name = `${accountName}-service-account-${runId}`;
  //       if (
  //         defaultBucketName !== bucketName &&
  //         validateServiceAccountName(accounts, bucketName)
  //       ) {
  //         name = bucketName;
  //       }
  //       const permissionIds: any = [permissionId];
  //       const domain = await api.getS3Domain();
  //       if ("s3domain" in domain) {
  //         windowObj.serviceAccountName = name;
  //         const baseUrl: string | "" = sgregionlist ? sgregionlist[0].url : "";
  //         let data = await regionApis.addServiceAccount(name, permissionIds, false, baseUrl);
  //         data = toCamelCaseKeys(data);
  //         if (data && data.serviceAccount?.id) {
  //           const vault = await fetchServiceAccountVault(data.serviceAccount.id)
  //           const serviceAccount = {
  //             name: data.serviceAccount.name,
  //             accessKey: vault.accessToken,
  //             id: data.serviceAccount.id,
  //             secretKey: vault.secretKey,
  //             s3Url: domain.s3domain
  //           };
  //           setResource("serviceAccount", serviceAccount);
  //           if (taskStatusChecker(data.serviceAccount.id,"serviceaccount")){
  //             return serviceAccount;
  //           }
  //         }
  //      }
  //     }
  //     info.current = new Error("Failed to create resource");
  //     rejectResource("serviceAccount");
  //   } catch (e) {
  //     info.current = new Error(e);
  //     rejectResource("serviceAccount");
  //   }
  // };

  const createBucket = async (windowObj: any) => {
    if (
      (startHereBucket.status === "idle" || startHereBucket.status === "rejected") &&
      isMountedRef.current &&
      !!region
    ) {
      try {
        const bucketResource = {
          name: bucketName,
          regionUrl: region.url
        }
        const permname =  `${accountName}-permission-${runId}`;
        const permdesc = `Allow all operation on ${bucketName}`;
        const saname = `${accountName}-service-account-${runId}`;
        const sadesc = saname;
        windowObj.bucket = bucketResource;
        windowObj.bucketName = bucketName;
        windowObj.permissionName = permname;
        windowObj.serviceAccountName = saname;
        const domain = await regionApis.s3DomainUrl(region.url);
        if("s3domain" in domain){
          let data = await regionApis.makeStartHereBucket(
              region.url,
              bucketName,
              region.name,
              permname,
              permdesc,
              saname,
              sadesc
          );
          data = toCamelCaseKeys(data);
          if (data && data.bucketName ) {
            let vault = await api.fetchSecretandAcessKey(data.serviceAccountId);
            vault = toCamelCaseKeys(vault);
            const serviceAccount = {
              name: data.serviceAccountName,
              accessKey: vault.accessToken,
              id: data.serviceAccountId,
              secretKey: vault.secretKey,
              s3Url: domain.s3domain
            };
            setResource("startHereBucket", data);
            setResource("bucket", bucketResource);
            setResource("permission", {id: data.permissionId,name: data.permissionName});
            setResource("serviceAccount",serviceAccount);
            return serviceAccount;
          }
        }
        info.current = new Error("Failed to create resource");
        rejectResource("startHereBucket");
        rejectResource("bucket");
        rejectResource("permission");
        rejectResource("serviceAccount");
      } catch (e) {
        info.current = new Error(e);
        rejectResource("startHereBucket");
        rejectResource("bucket");
        rejectResource("permission");
        rejectResource("serviceAccount");
      }
    }
  };

  return {
    createBucket
    //createPermission,
    //createServiceAccount
  };
};
