import React, { useState, Fragment } from "react";
import SupportTicketCount from "../SupportTicketCount";
import { Row, Col, Card, Spinner } from "react-bootstrap";
import SupportCreateNewTicket from "../SupportCreateNewTicket";
import { formType } from "../../Models/formTypeEnum";
import SupportTicketList from "../SupportTicketList";
import Can from "../../../../../../components/Can";
import { SUPPORT_EDIT } from "../../../../../../authRules";
import { TicketCounts } from "../../Models/TicketCounts";
import "./styles.scss";
import salesforceApi from "../../../../../../services/SalesforceAPI";
import moment from "moment";
import { Ticket } from "../../Models/Ticket";

const SupportDashboard: React.FC = () => {
  const [newTicket, setNewTicket] = useState(false);
  const [ticketCounts, setTicketCounts] = useState<TicketCounts>({
    new: 0,
    inprogress: 0,
    resolved: 0,
    total: 0
  });
  const [ticketList, setTicketList] = useState<Array<Ticket>>([]);

  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    // getTicketsSummary();
    setLoading(false);
  }, []);

  const getTicketsSummary = async () => {
    try {
      const response = await salesforceApi.getTicketsSummary();
      const summary = response.body;
      const counter: TicketCounts = {
        new: 0,
        inprogress: 0,
        resolved: 0,
        total: 0
      };
      counter.new = summary.TotalNewCount || 0;
      counter.inprogress = summary.TotalInProgressCount || 0;
      counter.resolved = summary.TotalResolvedCount || 0;
      counter.total = counter.new + counter.inprogress + counter.resolved;
      setTicketCounts(counter);

      const ticketsList = summary.TicketSummaryList;

      let tickets: Ticket[] = [];
      /** Check if array empty */
      if (ticketsList && ticketsList.length > 0) {
        /** If array is not empty then check if it has case number in it */
        if (ticketsList[0].hasOwnProperty("CaseNumber")) {
          tickets = ticketsList.map((ticket: Ticket) => {
            const newticket: Ticket = ticket;

            //Check for the date difference and assign it to newticket
            // var createdDate = new Date(newticket.TimeCreated);
            // var createdDate = moment(newticket.TimeCreated).toDate();
            // var currentDate = new Date();
            // var Difference_In_Time =
            //   currentDate.getTime() - createdDate.getTime();
            // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

             // var dt = new Date();
             var dateFormat = 'YYYY-MM-DD hh:mm A';
             var createdDateUtc = moment.utc(newticket.TimeCreated);
             var createdDateIN = createdDateUtc.local();
             var currentDateIN = moment.tz(new Date(), moment.tz.guess());
             var localDate = createdDateUtc.local();

            // newticket.Aging = Math.round(Difference_In_Days) + "";
            // newticket.Aging = String(Math.round(Difference_In_Days));
            newticket.Aging = String(moment(currentDateIN, dateFormat).diff(moment(createdDateIN, dateFormat), 'days'));
            newticket.TimeCreated = localDate.format(dateFormat);
            newticket.TimeCreatedDate = moment(newticket.TimeCreated).toDate();
            // newticket.TimeCreated = moment
            //   .utc(newticket.TimeCreated)
            //   .utcOffset(moment().utcOffset())
            //   .format("L LT");
            // newticket.TimeCreatedDate = moment(newticket.TimeCreated).toDate();

            return newticket;
          });
          setTicketList(tickets);
        }
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  /** Is case list empty */
  const isTicketsEmpty = (status: boolean) => {
    setNewTicket(status);
  };

  if (loading) {
    return (
      <div className="support-container">
        <div className="data-loader">
          <Spinner animation="border" role="status" className="spinner">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  }

  return (
    <div className="IAM-support-page-bodyContent scroll">
      <div className="support-dashboard-root">
        {/* Dashboard Ticket Counts */}

        <SupportTicketCount
          isTicketsEmpty={isTicketsEmpty}
          propTicketsCount={ticketCounts}
        />

        {/* Create New Ticket If there are no tickets */}
        {newTicket && (
          <Fragment>
            <Row>
              <Col>
                <p className="page-info">
                  You do not have any support tickets.
                  <Can perform={SUPPORT_EDIT}>
                    <span>Create one now.</span>
                  </Can>
                </p>
              </Col>
            </Row>
            <Can perform={SUPPORT_EDIT}>
              <Row>
                <Col md="4">
                  <Card>
                    <Card.Body>
                      <h4>Create New Ticket</h4>
                      {/* <SupportCreateNewTicket type={formType.newform} /> */}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Can>
          </Fragment>
        )}

        {/* List Item */}
        {!newTicket && (
          <Row>
            <Col>
              {/* <SupportTicketList initialTickets={ticketList} /> */}
              <SupportTicketList />
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default SupportDashboard;
