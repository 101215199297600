import React, { useEffect, useRef, useState } from "react";
import { Pencil, Plus, Trash } from "react-bootstrap-icons";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import storage from "local-storage-fallback";
import orderBy from "lodash/orderBy";

import AddRecipientModal from "./AddRecipient";
import RemoveModal from "./RemoveModal";
import * as notificationActions from "../Notification/actions";
import api from "../../services/api/index";
import Can from "../Can";
import { NOTIFICATIONS_EDIT } from "../../authRules";
import { AppState } from "../../store";
import { setNotificationRecipients } from "./reducer";
import LyveTable from "../LyveTable";
import LyveHeadingLink from "../LyveHeadingLink";
import { ModalContainer } from "../Modal";
import { DefaultButton } from "../LyveButton";
import { DefaultLoader } from "../LyveLoader";

import "./styles.scss";
import LyveLink from "../LyveHeadingLink/LyveLink";
import { getLink, linkNames } from "../LyveHeadingLink/LinkUtil";

interface Props {
  setErrorNotification?: any;
  setNotification?: any;
  setNotificationRecipients?: any;
  notificationRecipients?: any;
}

export type Data = {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  userType: string;
};

interface User {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  usertype: string;
}

interface ListUserResponse {
  notificationRecipient: User[];
}

const Notification: React.FC<Props> = ({
  setErrorNotification,
  setNotification,
  setNotificationRecipients,
  notificationRecipients
}) => {
  // const classes = useStyles();
  const [users, setUsers] = useState<Data[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const [
    removeRecipientDetails,
    setRemoveRecipientDetails
  ] = useState<Data | null>(null);
  const [editDetails, setEditDetails] = useState<Data | null>(null);
  const [type, setType] = useState<string>("add");
  const [customerId, setCustomerId] = useState<string>("null");
  const [loading, setLoading] = useState<boolean>(true);
  const [processing, setProcessing] = useState<boolean>(false);

  const { showModal } = ModalContainer.useContainer();
  const showAddNotificationModal = () => {
    showModal({
      // header: true,
      // title: 'Add New Recipient',
      header: true,
      title: (
        <>
          Add New Recipient
          <LyveLink link={getLink(linkNames.Add_Notification_Recipient)} />
        </>
      ),
      body: closeModal => (
        <AddRecipientModal
          usersList={users}
          userDetails={null}
          type="add"
          closeModal={closeModal}
          onSuccess={() => {
            console.log("success", customerId);
            getAll(customerId);
          }}
          onFailure={() => {
            // console.log("error");
          }}
        />
      ),
      headerProps: {
        onHide: () => {
          hideModal();
        }
      },
      baseProps: {
        dialogClassName: "lyve-modal"
      }
    });
  };

  const showEditNotificationModal = (LocalEditDetails?: Data) => {
    // Can send edit details
    showModal({
      header: true,
      title: (
        <>
          Edit Recipient
          <LyveLink link={getLink(linkNames.Edit_Notification_Recipient)} />
        </>
      ),
      body: closeModal => (
        <AddRecipientModal
          usersList={users}
          userDetails={
            typeof LocalEditDetails !== "undefined" ? LocalEditDetails : null
          }
          type="edit"
          closeModal={closeModal}
          onSuccess={() => {
            console.log("success", customerId);
            getAll(customerId);
          }}
          onFailure={() => {
            console.log("error");
          }}
        />
      ),
      headerProps: {
        onHide: () => {
          hideModal();
        }
      },
      baseProps: {
        dialogClassName: "lyve-modal"
      }
    });
  };

  const showRemoveNotificationModal = (LocalEditDetails?: Data) => {
    const localEditDetails: Data | null =
      typeof LocalEditDetails !== "undefined" ? LocalEditDetails : null;
    showModal({
      header: true,
      title: "Are you sure you want to remove the following recipient?",
      body: closeModal => (
        <RemoveModal
          // hideModal={hideRemoveModal}
          // submit={removeRecipient}
          // firstName={ localEditDetails ? localEditDetails.firstname : "" }
          // lastName={ localEditDetails ? localEditDetails.lastname : "" }
          // email={ localEditDetails ? localEditDetails.email : "" }
          closeModal={closeModal}
          onSuccess={() => {
            console.log("success", customerId);
            getAll(customerId);
          }}
          onFailure={() => {
            console.log("error");
          }}
          editDetails={localEditDetails}
        />
      ),
      headerProps: {
        onHide: () => {
          // hideModal();
        }
      },
      baseProps: {
        dialogClassName: "lyve-modal"
      }
    });
  };

  const constructRecipientList = (res: ListUserResponse) => {
    let userList: Data[] = [];
    if (res && res.notificationRecipient) {
      userList = res.notificationRecipient.map(
        (b: User): Data => {
          return {
            id: b.id,
            firstname: b.firstname,
            lastname: b.lastname,
            email: b.email,
            userType: b.usertype
          };
        }
      );
    }
    userList = orderBy(userList, ["email"], ["asc"]);
    setUsers(userList);
  };

  let isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    const companyId: string | null = storage.getItem("company_id");
    const customer_Id = companyId ? companyId : "";

    setCustomerId(customer_Id);
    getAll(customer_Id);
    return () => {
      isMounted.current = false;
    };
  }, [notificationRecipients]);

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All"
  };

  const NoData = (
    <table className="table empty-table">
      <thead>
        <tr>
          <th>
            First Name <i className="fa fa-fw fa-sort-down" />
          </th>
          <th>
            Last Name <i className="fa fa-fw fa-sort" />
          </th>
          <th>
            Email <i className="fa fa-fw fa-sort" />
          </th>
          <Can perform={NOTIFICATIONS_EDIT}>
            <th>Actions</th>
          </Can>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={7}>No recipients found.</td>
        </tr>
      </tbody>
    </table>
  );
  const columns = [
    {
      name: "First Name",
      selector: "firstname",
      sortable: true,
      cell: (row: Data) => <span title={row.firstname}>{row.firstname}</span>
    },
    {
      name: "Last Name",
      selector: "lastname",
      sortable: true,
      cell: (row: Data) => <span title={row.lastname}>{row.lastname}</span>
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      cell: (row: Data) => <span title={row.email}>{row.email}</span>
    }
  ];

  const columnsWithActions = [
    ...columns,
    {
      name: "Actions",
      selector: "actions",
      cell: (row: Data) => renderActions(row)
    }
  ];

  const renderActions = (row: Data) => {
    // console.log("object", row.userType);
    return (
      <div>
        {row.userType !== "user" && (
          <DefaultButton
            size="sm"
            variant="outline-secondary"
            onClick={() => openEditModal(row)}
            style={{ marginRight: "5px" }}
          >
            <Pencil size="16" />
            Edit
          </DefaultButton>
        )}
        <DefaultButton
          size="sm"
          variant="outline-secondary"
          onClick={() => remove(row)}
        >
          <Trash size="16" />
          Remove
        </DefaultButton>
      </div>
    );
  };

  const hideModal = () => {
    setModalOpen(false);
    clearDetails();
  };
  const hideRemoveModal = () => {
    setRemoveModal(false);
    setRemoveRecipientDetails(null);
  };
  const openEditModal = (row: Data) => {
    setEditDetails(row);
    setType("edit");
    setModalOpen(true);
    showEditNotificationModal(row);
  };
  const clearDetails = () => {
    setEditDetails(null);
    setType("add");
  };
  const remove = (row: Data) => {
    setRemoveRecipientDetails(row);
    setRemoveModal(true);
    showRemoveNotificationModal(row);
  };

  const removeRecipient = () => {
    setLoading(true);
    api
      .removeNotificationRecipient(
        removeRecipientDetails ? removeRecipientDetails.id : ""
      )
      .then(() => getAll(customerId));
    hideRemoveModal();
  };

  const getAll = (customer_Id: string) => {
    api
      .getAllNotificationRecipients(customer_Id)
      .then(resp => {
        if (isMounted.current) {
          hideModal();
          clearDetails();
          setLoading(false);
          setProcessing(false);
          constructRecipientList(resp);
        }
      })
      .catch(err => {
        if (isMounted.current) {
          setLoading(false);
        }
      });
  };

  // const addRecipient = (firstName: string, lastName: string, email: string) => {
  //   setProcessing(true);
  //   const partner_or_tenant_id = customerId ? customerId : "";
  //   api
  //     .addNotificationRecipient(
  //       partner_or_tenant_id,
  //       firstName,
  //       lastName,
  //       email
  //     )
  //     .then(() => {
  //       getAll(customerId);
  //       setNotification("New recipient was added.");
  //     })
  //     .catch((err) => {
  //       hideModal();
  //       clearDetails();
  //       console.log("err", err);
  //       setLoading(false);
  //       setProcessing(false);
  //       setErrorNotification("Failed to add recipient.");
  //     });
  // };

  // const editRecipient = (
  //   firstName: string,
  //   lastName: string,
  //   email: string,
  //   rId: string,
  // ) => {
  //   setProcessing(true);
  //   const id = editDetails ? editDetails.id : rId;
  //   console.log(id);
  //   api.editNotificationRecipient(id, firstName, lastName, email).then(() => {
  //     getAll(customer_Id);
  //   }).catch((err) => {
  //     console.log(err);
  //     hideModal();
  //     clearDetails();
  //     setLoading(false);
  //     setProcessing(false);
  //   });
  // };

  const editRecipient = (
    firstName: string,
    lastName: string,
    email: string
  ) => {
    setProcessing(true);
    const id = editDetails ? editDetails.id : "";
    api.editNotificationRecipient(id, firstName, lastName, email).then(() => {
      getAll(customerId);
    });
  };

  const customSort = (
    rows: Data[],
    field: string,
    direction: "desc" | "asc"
  ) => {
    const handleField = (row: any) => {
      const val = row[field];
      if (val && typeof val === "string") {
        return val.toLowerCase();
      }
      return val;
    };

    return orderBy(rows, handleField, direction);
  };

  return (
    <div className="container-fluid IAM-support-page-bodyContent">
      <div className="row no-gutter">
        <div className="col">
          <React.Fragment>
            {/* <div className="lyve-page-title">
              <div className="heading-area">
                <h4 className="lyve-page-main-title">Recipients</h4>
                <p className="lyve-page-sub-title">
                  You can add, remove or edit notification recipients. These
                  recipients will recieve service notification emails. They do
                  not have to be registered users.
                </p>
              </div>
              <div className="button-area">
                <Can perform={NOTIFICATIONS_EDIT}>
                  <Button
                    onClick={() => {
                      setModalOpen(true);
                    }}
                  >
                    <div>
                      <i className="material-icons">add</i>
                      Add New Recipient
                    </div>
                  </Button>
                </Can>
              </div>
            </div> */}

            <LyveHeadingLink
              title="Notification Recipients"
              subTitle="You can add, remove or edit notification recipients. These recipients will receive service notification emails. They do not have to be registered users. Registered users are added automatically to this list."
              helpLink={getLink(linkNames.Notification_Recipient)}
            >
              <Can perform={NOTIFICATIONS_EDIT}>
                <DefaultButton onClick={() => showAddNotificationModal()}>
                  <Plus size="20" />
                  Add New Recipient
                </DefaultButton>
              </Can>
            </LyveHeadingLink>

            {loading ? (
              // <Grid
              //   container
              //   className={classes.spinnerContainer}
              //   justify="center"
              //   alignItems="center"
              // >
              //   <CircularProgress />
              // </Grid>
              <div className="loader-container">
                <DefaultLoader animation="border" />
              </div>
            ) : (
              <div style={{ margin: "32px 0" }}>
                <Can
                  perform={NOTIFICATIONS_EDIT}
                  no={
                    <LyveTable
                      columns={columns}
                      data={users}
                      sortFunction={customSort}
                      defaultSortField="firstname"
                      defaultSortAsc={true}
                      selectableRows={false}
                      // pagination={users.length > 10 ? true : false}
                      pagination={true}
                      noHeader={true}
                      paginationPerPage={10}
                      paginationRowsPerPageOptions={[10, 25, 50]}
                      highlightOnHover={true}
                      paginationComponentOptions={paginationOptions}
                      noDataComponent={NoData}
                      paginationIconFirstPage={false}
                      paginationIconLastPage={false}
                      responsive={true}
                    />
                  }
                >
                  <LyveTable
                    columns={columnsWithActions}
                    data={users}
                    sortFunction={customSort}
                    defaultSortField="firstname"
                    defaultSortAsc={true}
                    selectableRows={false}
                    // pagination={users.length > 10 ? true : false}
                    pagination={true}
                    noHeader={true}
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 25, 50]}
                    highlightOnHover={true}
                    paginationComponentOptions={paginationOptions}
                    noDataComponent={NoData}
                    paginationIconFirstPage={false}
                    paginationIconLastPage={false}
                    responsive={true}
                  />
                </Can>
                {/* <AddRecipientModal
                    showModal={modalOpen}
                    hideModal={hideModal}
                    userDetails={editDetails}
                    add={addRecipient}
                    edit={editRecipient}
                    type={type}
                    processing={processing}
                  /> */}
                {/* {removeModal && (
                    <RemoveModal
                      hideModal={hideRemoveModal}
                      submit={removeRecipient}
                      firstName={
                        removeRecipientDetails
                          ? removeRecipientDetails.firstname
                          : ""
                      }
                      lastName={
                        removeRecipientDetails
                          ? removeRecipientDetails.lastname
                          : ""
                      }
                      email={
                        removeRecipientDetails ? removeRecipientDetails.email : ""
                      }
                    />
                  )} */}
              </div>
            )}
          </React.Fragment>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  notificationRecipients: state.notificationRecipients.recipientList
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setNotificationRecipients: (recipientList: Data[]) => {
    dispatch(setNotificationRecipients(recipientList));
  },
  setErrorNotification: (message: string) =>
    dispatch(notificationActions.setErrorNotification(message)),
  setNotification: (message: string) =>
    dispatch(notificationActions.setNotification(message))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Notification
);
