import { TOGGLE_PERMISSION_CREATE_MODAL, PermissionsState, TogglePermissionCreateModalActionTypes } from './types';

const initialState: PermissionsState = {
  displayCreateModal: false,
}

export function reducer(
  state = initialState,
  action: TogglePermissionCreateModalActionTypes): PermissionsState {
  switch (action.type) {
    case TOGGLE_PERMISSION_CREATE_MODAL:
      return {
        ...state,
        displayCreateModal: !state.displayCreateModal,
      }
    default:
      return state;
  }
}
