import React from "react";
import { useAsync, useInterval } from "./index";

function useResourceCreation<D>(
  initialData: D,
  creationTimeout: number,
  shouldPlayAnimationAt: number,
  onSuccess?: () => void
) {
  const [serverErr, setServerErr] = React.useState("");
  const resetServerErr = () => setServerErr("");

  const mountedRef = React.useRef(false);
  const { current: mounted } = mountedRef;

  const [counter, setCounter] = React.useState(0);
  const resetCounter = () => setCounter(0);
  const incrementCounter = () => setCounter(counter + 1);

  const { data, status, run, reset } = useAsync<D>(initialData);

  React.useLayoutEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const isCreationPending = status === "pending";
  const isCreationFailed = status === "rejected";
  const isCreationIdle = status === "idle";
  const hasApiFinishedEarly =
    !isCreationPending && counter >= shouldPlayAnimationAt;

  useInterval(
    incrementCounter,
    isCreationIdle || hasApiFinishedEarly ? null : 1000
  );

  const resetCreation = () => {
    reset();
    resetCounter();
    resetServerErr();
  };

  const runCreation = (promise: Promise<D>) =>
    run(
      promise,
      () => {
        if (mounted) {
          onSuccess?.();
        }
      },
      (e: any) => {
        if (mounted) {
          setServerErr(e?.message ?? e);
        }
      }
    );

  return {
    status,
    data,
    isCreationPending,
    isCreationFailed,
    runCreation,
    counter,
    resetCounter,
    reset,
    resetCreation,
    serverErr
  };
}

export default useResourceCreation;
