import React from "react";
import cx from "classnames";
import { Badge, BadgeProps } from "react-bootstrap";

interface IOwnProps extends BadgeProps {
  /** Content of badge */
  children?: React.ReactNode;
  /** Class name */
  className?: string;
  /** Size */
  size?: "sm" | "md";
}

/**
 * The Custom badge component
 * @returns {*} React Component
 */
const LyveBadge = ({
  variant = "light",
  size = "md",
  children,
  className,
  ...props
}: IOwnProps) => {
  return (
    <Badge {...props} className={cx("lyve-badge", size, variant, className)}>
      {children}
    </Badge>
  );
};

export default LyveBadge;
