import React, { useEffect } from "react";
import { FocusEvent } from "react";
import { CheckCircleFill } from "react-bootstrap-icons";
import { createUseStyles } from "react-jss";
import { DefaultButton } from "../../../../../../components/LyveButton";
import partner_api from "../../../../../../services/Partner_api";

import "./styles.scss";

interface CreateTenantProps {
  hideModal: () => void;
  onSuccess?: (email: string) => void;
  onFailure?: () => void;
}

const useStyles = createUseStyles({
  root: {
    height: "100vh",
    overflow: "auto",
    display: "flex",
    backgroundColor: "#121212"
  },
  input: {
    height: "40px",
    fontSize: 16,
    borderRadius: 3,
    border: "1px solid #ccc",
    padding: "5px 12px",
    "&.Mui-focused": {
      borderColor: "black"
    }
  }
});

const CreateTenantPrompt: React.FC<CreateTenantProps> = ({
  onFailure,
  onSuccess,
  hideModal
}) => {
  const classes = useStyles();

  const [shortNameTyping, setShortNameTyping] = React.useState<boolean>(false);
  const [orgFocus, setOrgFocus] = React.useState<boolean>(false);
  const [shortName, setShortName] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState("");
  const [errShortName, setErrShortName] = React.useState("");
  const [errEmail, setErrEmail] = React.useState("");
  const [usedShortName, setUsedShortName] = React.useState("");
  const [usedEmail, setUsedEmail] = React.useState("");
  const [formProcessing, setFormProcessing] = React.useState<boolean>(false);

  useEffect(() => {
    clearText();
  }, [hideModal]);

  const handleCreateCustomer = () => {
    setFormProcessing(true);
    setError("");
    setErrShortName("");
    setErrEmail("");
    //setMessage("Creating customer and it will take a few minutes .....");
    partner_api
      .addTenant(shortName, firstName, lastName, email)
      .then(() => {
        clearText();
        setFormProcessing(false);
        if (typeof hideModal !== "undefined") {
          hideModal();
        }
        if (typeof onSuccess !== "undefined") {
          onSuccess(email);
        }
      })
      .catch(err => {
        setFormProcessing(false);
          if (err.message.includes("email address is already being used")) {
            setUsedEmail(email);
            setUsedShortName("");
          }
          if (err.message.includes("organization name is already being used")) {
            setUsedShortName(shortName);
            setUsedEmail("");
          }

          if (typeof onFailure !== "undefined") {
            onFailure();
          }

          if (err.code == 14 || err.status == 503) {
            setError(err.message);
          } else {
            setError("An error has occurred. Please try again later.");
          }
      });
  };

  const checkShortName = (shortCustomerName: string) => {
    setError("");
    partner_api
      .checkShortName(shortCustomerName)
      .then(res => {
        if (res.exists) {
          setUsedShortName(shortCustomerName);
          setErrShortName(
            "This organization name is already being used. Please enter a different one."
          );
        } else {
          setUsedShortName("");
          setErrShortName("");
        }
      })
      .catch(err => {
        setUsedShortName("");
        if (err.code == 14 || err.status == 503) {
          setError(err.message);
        } else {
          setError("An error has occurred. Please try again later.");
        }
      });
  };

  const clearText = () => {
    setUsedShortName("");
    setUsedEmail("");
    setShortName("");
    setEmail("");
    setFirstName("");
    setLastName("");
    setError("");
    setErrShortName("");
    setErrEmail("");
  };

  const checkUserEmail = (email: string) => {
    setError("");
    setUsedEmail("");
    // partner_api
    // 	.checkEmail(email)
    // 	.then((res) => {
    // 		if (res.exists) {
    // 			setUsedEmail(email);
    // 			setErrEmail('This email address is already being used. Please enter a different one.');
    // 		} else {
    // 			setUsedEmail('');
    // 			setErrEmail('');
    // 		}
    // 	})
    // 	.catch((err) => {
    // 		setUsedEmail('');
    // 		setErrEmail(err);
    // 	});
  };

  const validateNames = (inputname: string): boolean => {
    //console.log("OrgName validation : " + /^[a-zA-Z\d- ]+$/.test(orgName));
    let valid = /^[a-zA-Z\d- ]+$/.test(shortName);
    if (inputname == "firstname") {
      valid = /^[a-zA-Z\d- ]+$/.test(firstName);
      //setErrUser(valid);
    }
    if (inputname == "lastname") {
      valid = /^[a-zA-Z\d- ]+$/.test(lastName);
      //setErrUser(valid);
    }
    if (inputname == "shortname") {
      valid = /^[a-zA-Z\d-]+$/.test(shortName);
      //setErrOrgName(valid);
    }

    return valid;
  };

  const checkFirstLastCharacter = (inputname: string): boolean => {
    var start = shortName.charAt(0);
    var end = shortName.charAt(shortName.length - 1);

    if (inputname == "shortname") {
      start = shortName.charAt(0);
      end = shortName.charAt(shortName.length - 1);
    }

    var startvalid = /^[a-zA-Z0-9 ]+$/.test(start);
    var endvalid = /^[a-zA-Z0-9 ]+$/.test(end);

    var valid = startvalid && endvalid ? true : false;

    return valid;
  };

  const withinCharacterLimit = (inputname: string): boolean => {
    let validate = shortName.length >= 3 && shortName.length <= 63;

    if (inputname == "shortname") {
      validate = shortName.length >= 3 && shortName.length <= 63;
    }
    //console.log("lenght of org name : " + validate);

    return validate;
  };

  const validateEmail = () => {
    if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      return true;
    }
  };

  const onOrgFocus = (event: React.FocusEvent<EventTarget>) => {
    setShortNameTyping(true);
    setOrgFocus(true);
  };

  const onOrgBlur = () => {
    //setShortNameTyping(false);
    //setOrgFocus(false);
    if (
      validateNames("shortname") &&
      withinCharacterLimit("shortname") &&
      checkFirstLastCharacter("shortname")
    ) {
      checkShortName(shortName);
    }
  };

  const onEmailBlur = () => {
    if (validateEmail()) {
      checkUserEmail(email);
    }
  };

  const showChecks = () => (
    <div className="checks">
      <div className="d-flex pb-1 align-items-center">
        <CheckCircleFill
          size={16}
          className={`checks-icon mr-2 ${
            shortName.length < 1 || withinCharacterLimit("shortname") == false
              ? "invalid"
              : "valid"
          }`}
        />
        <span>3 - 63 characters</span>
      </div>
      <div className="d-flex pb-1 align-items-center">
        <CheckCircleFill
          size={16}
          className={`checks-icon mr-2 ${
            shortName.length < 1 || validateNames("shortname") == false
              ? "invalid"
              : "valid"
          }`}
        />
        <span>No spaces, no upper case, no underscores</span>
      </div>
      <div className="d-flex pb-1 align-items-center">
        <CheckCircleFill
          size={16}
          className={`checks-icon mr-2 ${
            shortName.length < 1 || validateNames("shortname") == false
              ? "invalid"
              : "valid"
          }`}
        />
        <span>Only alpha numerical characters and dashes are allowed</span>
      </div>
      <div className="d-flex pb-1 align-items-center">
        <CheckCircleFill
          size={16}
          className={`checks-icon mr-2 ${
            shortName.length < 1 ||
            checkFirstLastCharacter("shortname") == false
              ? "invalid"
              : "valid"
          }`}
        />

        <span>
          Must start with an alpha numeric character (no dashes at beginning or
          end)
        </span>
      </div>
    </div>
  );

  return (
    <div className="create-partner-customer">
      <form>
        <div className="form-group">
          <label id="shortName" className="modalLabel">
            Organization Name
          </label>
          <input
            type="text"
            className={`form-control ${
              (shortName.length > 0 && validateNames("shortname") == false) ||
              (shortName.length > 0 &&
                withinCharacterLimit("shortname") == false) ||
              (shortName.length > 0 &&
                checkFirstLastCharacter("shortname") == false)
                ? " is-invalid"
                : ""
            }`}
            id="shortname"
            autoFocus
            value={shortName}
            autoComplete="off"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setShortName(e.target.value.toLocaleLowerCase().trim());
              setShortNameTyping(true);
            }}
            onFocus={onOrgFocus}
            onBlur={onOrgBlur}
          />
          {/* <input type="text" className={classes.input} /> */}
          {orgFocus === true && shortNameTyping === true && showChecks()}
          {usedShortName === shortName &&
            (errShortName ||
              error.includes("organization name is already being used")) && (
              <div
                style={{
                  fontSize: "14px",
                  color: "#dc3545",
                  fontWeight: 400,
                  marginTop: 8
                }}
              >
                {errShortName.length > 0 ? errShortName : error}
              </div>
            )}
          {/* <div className="invalid-feedback">
                  This is not a valid Name{" "}
                </div> */}
        </div>
        <div className="form-group">
          <label id="firstname" className="modalLabel">
            First Name
          </label>
          <input
            type="text"
            value={firstName}
            className={`form-control ${
              firstName.length > 0 && validateNames("firstname") == false
                ? " is-invalid"
                : ""
            }`}
            id="firstname"
            autoComplete="off"
            onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
              setShortNameTyping(false);
              setOrgFocus(false);
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFirstName(e.target.value);
            }}
          />
          <div
            className="invalid-feedback"
            style={{ fontSize: 14, marginTop: 8 }}
          >
            Only alphanumeric, '-' or space is allowed.
          </div>
        </div>
        <div className="form-group">
          <label id="lastname" className="modalLabel">
            Last Name
          </label>
          <input
            type="text"
            value={lastName}
            className={`form-control ${
              lastName.length > 0 && validateNames("lastname") == false
                ? " is-invalid"
                : ""
            }`}
            id="lastname"
            autoComplete="off"
            onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
              setShortNameTyping(false);
              setOrgFocus(false);
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setLastName(e.target.value);
            }}
          />
          <div
            className="invalid-feedback"
            style={{ fontSize: 14, marginTop: 8 }}
          >
            Only alphanumeric, '-' or space is allowed.
          </div>
        </div>
        <div className="form-group">
          <label id="email" className="modalLabel">
            Email
          </label>
          <input
            type="email"
            className={`form-control ${
              email.length > 0 && !validateEmail() ? " is-invalid" : ""
            }`}
            id="email"
            autoComplete="off"
            onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
              setShortNameTyping(false);
              setOrgFocus(false);
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value.trim());
            }}
            onBlur={onEmailBlur}
          />
          {!validateEmail() && (
            <div
              className="invalid-feedback"
              style={{ fontSize: 14, marginTop: 8 }}
            >
              Please enter a valid email address.
            </div>
          )}
          {validateEmail() &&
            usedEmail === email &&
            (errEmail ||
              error.includes("email address is already being used")) && (
              <div
                style={{
                  fontSize: "14px",
                  color: "#dc3545",
                  fontWeight: 400,
                  marginTop: 8
                }}
              >
                {errEmail.length > 0 ? errEmail : error}
              </div>
            )}
        </div>
        {error.includes("email address is already being used") ||
        error.includes("organization name is already being used") ? (
          <div />
        ) : (
          <div
            style={{
              fontSize: "14px",
              color: "#dc3545",
              fontWeight: 400,
              marginTop: 8
            }}
          >
            {error}
          </div>
        )}
      </form>
      <DefaultButton
        onClick={handleCreateCustomer}
        disabled={
          validateNames("shortname") &&
          validateNames("firstname") &&
          validateNames("lastname") &&
          checkFirstLastCharacter("shortname") &&
          withinCharacterLimit("shortname") &&
          validateEmail() &&
          usedShortName !== shortName &&
          usedEmail !== email &&
          !formProcessing
            ? false
            : true
        }
      >
        {formProcessing ? (
          <div>
            <div
              className="spinner-border spinner-border-sm"
              style={{ color: "white" }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
            &nbsp; Creating
          </div>
        ) : (
          "Create"
        )}
      </DefaultButton>
    </div>
  );
};

export default CreateTenantPrompt;
