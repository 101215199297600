import React from "react";
import { useState } from "react";
import { ChevronCompactRight, ThreeDots } from "react-bootstrap-icons";
import ReactTooltip from "react-tooltip";

interface IBreadCrumData {
  active?: boolean;
  title: string;
  onClick?: (e: React.MouseEvent) => void;
}

interface IOwnProps {
  breadcrumbs: IBreadCrumData[];
  isMobileView?: boolean;
}

const LyveBreadCrumb: React.FC<IOwnProps> = ({
  breadcrumbs,
  isMobileView = false
}) => {
  const [showBreadcrumbDetails, setBreadcrumbDetails] = useState(false);
  const [hasOverflowingChildren, setHasOverflowingChildren] = useState(false);

  const updateOverflow = (event: React.MouseEvent<HTMLDivElement>) => {
    const element: any = event.target;
    if (element) {
      if (element.scrollWidth > element.clientWidth) {
        setHasOverflowingChildren(true);
      } else {
        setHasOverflowingChildren(false);
      }
    }
  };

  const breadcrumbDetails = () => {
    const breadcrumbsList = [...breadcrumbs];
    return (
      <div
        className="breadCrumb-card"
        onMouseLeave={() => {
          setBreadcrumbDetails(false);
        }}
      >
        <div className="breadCrumb-body">
          {breadcrumbsList
            .slice(1)
            .reverse()
            .map((data, index) => {
              return (
                <React.Fragment key={`${data.title}-${index}`}>
                  <div
                    key={`${data.title}-${index}`}
                    onClick={e => {
                      if (data.onClick) {
                        data.onClick(e);
                      }
                    }}
                    onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) =>
                      updateOverflow(event)
                    }
                    className="breadCrumb-title"
                    data-for={`${data.title}-${index}`}
                    data-tip={data.title}
                  >
                    {data.title}
                  </div>
                  {hasOverflowingChildren && (
                    <ReactTooltip
                      id={`${data.title}-${index}`}
                      effect="solid"
                      className="breadCrumb-tooltip"
                      multiline
                      place="bottom"
                    />
                  )}
                </React.Fragment>
              );
            })}
        </div>
      </div>
    );
  };

  return (
    <div className="lyve-breadcrumb-section">
      {(breadcrumbs.length < 6 && !isMobileView) || breadcrumbs.length === 2 ? (
        breadcrumbs.map((breadcrumb, index) => {
          return (
            <React.Fragment key={`${breadcrumb.title}-${index}`}>
              <div
                onClick={e => {
                  if (breadcrumb.onClick) {
                    breadcrumb.onClick(e);
                  }
                }}
                onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) =>
                  updateOverflow(event)
                }
                key={`${breadcrumb.title}-${index}`}
                style={{
                  maxWidth:
                    index === 0 ? "100%" : `${100 / breadcrumbs.length}%`,
                  overflow: index === 0 ? "inherit" : "hidden"
                }}
                className={`breadcrumb-link ${
                  breadcrumb.active ? "active" : ""
                }`}
                data-for={`${breadcrumb.title}-${index}`}
                data-tip={breadcrumb.title}
              >
                {breadcrumb.title}
              </div>
              {index !== 0 && hasOverflowingChildren && (
                <ReactTooltip
                  id={`${breadcrumb.title}-${index}`}
                  effect="solid"
                  className="breadCrumb-tooltip"
                  multiline
                  place="bottom"
                />
              )}
              {index !== breadcrumbs.length && <ChevronCompactRight />}
            </React.Fragment>
          );
        })
      ) : (
        <>
          <div
            onClick={e => {
              if (breadcrumbs[0].onClick) {
                breadcrumbs[0].onClick(e);
              }
            }}
            className={`breadcrumb-link ${
              breadcrumbs[0].active ? "active" : ""
            }`}
            style={{
              maxWidth: "100%",
              overflow: "inherit"
            }}
          >
            {breadcrumbs[0].title}
          </div>
          <ChevronCompactRight size={16} />
          <div className="position-relative">
            <div
              className="dots-icon"
              onMouseEnter={() => setBreadcrumbDetails(true)}
            >
              <ThreeDots size={16} className="w-100" />
            </div>
            {showBreadcrumbDetails && breadcrumbDetails()}
          </div>
          <ChevronCompactRight />
          <div
            onClick={e => {
              if (breadcrumbs[breadcrumbs.length - 1].onClick) {
                const item: any = breadcrumbs[breadcrumbs.length - 1];
                item.onClick(e);
              }
            }}
            onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) =>
              updateOverflow(event)
            }
            className={`breadcrumb-link ${
              breadcrumbs[breadcrumbs.length - 1].active ? "active" : ""
            }`}
            style={{
              maxWidth: `${100 / 3}%`
            }}
            data-for={`${breadcrumbs[breadcrumbs.length - 1].title}-manual`}
            data-tip={breadcrumbs[breadcrumbs.length - 1].title}
          >
            {breadcrumbs[breadcrumbs.length - 1].title}
          </div>
          {hasOverflowingChildren && (
            <ReactTooltip
              id={`${breadcrumbs[breadcrumbs.length - 1].title}-manual`}
              effect="solid"
              className="breadCrumb-tooltip"
              multiline
              place="bottom"
            />
          )}
        </>
      )}
    </div>
  );
};

export default LyveBreadCrumb;
