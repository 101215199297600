import React, { useState, useEffect } from "react";
import { USERS_EDIT } from "../../../authRules";
import Can from "../../Can";
import mfa_logo from "../../../images/mfa-logo.png";
import CustomToggle from "../../CustomToggleButton";
import LyveHeading from "../../LyveHeading";
import { ModalContainer } from "../../Modal";
import ConfirmModal from "./components/ConfirmModal";
import api from "../../../services/api";
import { useDispatch } from "react-redux";
import { UPDATE_MFA_PREFERENCE } from "../../../state/IAM/actions";
import { Link } from "react-router-dom";
import "./styles.scss";
import LyveLink from "../../LyveHeadingLink/LyveLink";
import { getLink, linkNames } from "../../LyveHeadingLink/LinkUtil";
import DefaultAlert from "../../LyveAlert";
import {InfoCircleFill} from "react-bootstrap-icons";
import {Card} from "react-bootstrap";

type ViewMFAProps = {
  // updateMFA: (newPreference: string) => void;
  // processing: boolean;
  // currentMFA: string;
  // confirmChange: boolean;
  // setConfirmChange: any;
};

const ViewMFA: React.FC<ViewMFAProps> = props => {
  // const toggleValue = (): boolean => {
  //   return currentMFA === "passwordOnly" ? false : true;
  // };
  // const closeModalaa = {
  //   changeTabToSAML: () => setKey("saml"),
  // };
  const { showModal } = ModalContainer.useContainer();
  const initConfirmModal = () => {
    showModal({
      body: closeModal => <ConfirmModal closeModal={closeModal} />
    });
  };

  const {
    // updateMFA,
    // processing,
    // currentMFA
    // confirmChange,
    // setConfirmChange,
  } = props;

  // const update = () => {
  //   currentMFA === "passwordOnly"
  //     ? updateMFA("SMS")
  //     : updateMFA("passwordOnly");
  // };

  const dispatch = useDispatch();

  // const changeToggle = () => {
  //   if (!toggleValue()) {
  //     initConfirmModal();
  //   } else {
  //     const updatedMfa = currentMFA === "passwordOnly" ? "SMS" : "passwordOnly";
  //     api.updateMFAPreference(updatedMfa).then(mfa => {
  //       dispatch({
  //         type: UPDATE_MFA_PREFERENCE,
  //         payload: updatedMfa
  //       });
  //     });
  //   }
  // };

  return (
    <div className="container-fluid IAM-support-page-bodyContent">
      {/*<div className="row no-gutter">*/}
      {/*  <div className="col">*/}
      {/*    <div>*/}
      {/*      <div className="row">*/}
      {/*        <div className="col-12">*/}
      {/*          <LyveHeading*/}
      {/*            title="Multi-Factor Authentication (MFA)"*/}
      {/*            subTitle=""*/}
      {/*            subTitleNewLine=""*/}
      {/*            marginRightStyle={true}*/}
      {/*          >*/}
      {/*            <Can*/}
      {/*              perform={USERS_EDIT}*/}
      {/*              no={<CustomToggle selected={toggleValue()} />}*/}
      {/*            >*/}
      {/*              <CustomToggle*/}
      {/*                selected={toggleValue()}*/}
      {/*                // TODO: should we add processing to toggle ?*/}
      {/*                // toggleSelected={() => changeToggle()}*/}
      {/*                // processing={toggleValue() && processing} // loader inside toggle will only display when turning MFA off*/}
      {/*                // toggleSelected={() => setConfirmChange(true)}*/}
      {/*                toggleSelected={changeToggle}*/}
      {/*                // processing={processing}*/}
      {/*              />*/}
      {/*            </Can>*/}
      {/*          </LyveHeading>*/}
      {/*          <div className="d-flex align-items-center">*/}
      {/*            <img src={mfa_logo} height={100} className="mr-3" />*/}
      {/*            <div style={{ maxWidth: "400px" }}>*/}
      {/*              Multi-Factor Authentication adds an additional factor to the*/}
      {/*              login transaction in order to prevent unauthorized access.*/}
      {/*              Configure phone message or one-time password for enhanced*/}
      {/*              security.*/}
      {/*              <LyveLink*/}
      {/*                link={*/}
      {/*                  "https://help.lyvecloud.seagate.com/en/configuring-multi-factor-authentication--mfa-.html"*/}
      {/*                }*/}
      {/*              />*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className="d-flex flex-column my-4 align-items-left">*/}
      {/*            <div>*/}
      {/*              In addition to password, below are the supported factors:*/}
      {/*            </div>*/}
      {/*            <div className="font-weight-bold">*/}
      {/*              One-Time Password (OTP)*/}
      {/*            </div>*/}
      {/*            <div>*/}
      {/*              Provide a one-time password using an authenticator app such*/}
      {/*              as Microsoft or Oracle authenticator.*/}
      {/*            </div>*/}
      {/*            <br />*/}
      {/*            <div className="font-weight-bold">Phone Message (SMS)</div>*/}
      {/*            <div>*/}
      {/*              Receive a text message with a 6-digit verification code.*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/* {confirmChange && (
        <ConfirmChange
          close={() => setConfirmChange(false)}
          processing={processing}
          change={() => {
            update();
            // setConfirmChange(false);
          }}
        />
      )} */}
      <LyveHeading title="MFA Settings" subTitle=""></LyveHeading>
      <Card className={"view-mfa-card"}>
        <Card.Body>
          <div className="VB-card-title mb-3">Multi-Factor Authentication (MFA)</div>
          <div className="d-flex align-items-center">
            <div style={{ maxWidth: "800px" }}>
              Multi-Factor Authentication adds an additional factor to the login transaction in order to prevent unauthorized
              access. Configure phone message or one-time password for enhanced security.
              <LyveLink
                  link={
                    "https://help.lyvecloud.seagate.com/en/configuring-multi-factor-authentication--mfa-.html"
                  }
              />
            </div>
          </div>
          <div className="d-flex flex-column my-4 align-items-left">
            <DefaultAlert variant="info" customClasses={'d-flex mfa-alert-info'}>
              <InfoCircleFill size={16} className={"mr-3"} style={{color: "#00a1dd", marginTop: "3px"}}/>
              <span style={{ color: "#333333"}}>MFA is mandatory for all Lyve Cloud's Password users and cannot be disabled.</span>
            </DefaultAlert>
          </div>
          <div className="d-flex flex-column align-items-left">
            <div>Status</div>
            <div className="font-weight-bold">Enabled</div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

// const ConfirmChange = (props: any) => {
//   const { close, change, processing } = props;
//   return (
//     <div
//       className="popup delete-prompt"
//       id="exampleModal"
//       role="dialog"
//       aria-labelledby="exampleModalLabel"
//       aria-hidden="true"
//     >
//       <div className="modal-dialog modal-dialog-centered" role="document">
//         <div className="modal-content">
//           <div className="modal-header modalCenter">
//             <h5 className="modal-title" id="exampleModalLabel">
//               MFA Update Confirmation
//             </h5>
//             <button
//               type="button"
//               className="close"
//               data-dismiss="modal"
//               aria-label="Close"
//               onClick={close}
//             >
//               <span aria-hidden="true">&times;</span>
//             </button>
//           </div>
//           <div className="modal-body ">
//             You are changing your MFA configuration
//             <div>
//               Note: All users will need to be in compliance of your new MFA
//               policy to be authenticated to LyveCloud
//             </div>
//           </div>

//           <div className="modal-footer">
//             <Button
//               disabled={processing}
//               onClick={() => change()}
//             >
//               {processing && (
//                 <Spinner
//                   as="span"
//                   animation="border"
//                   size="sm"
//                   role="status"
//                   aria-hidden="true"
//                 />
//               )}{" "}
//               Confirm
//             </Button>
//             <Button
//               variant="outline-secondary"
//               onClick={close}
//             >
//               Cancel
//             </Button>
//           </div>
//         </div>
//       </div>
//       </div>
//   );
// };

export default ViewMFA;
