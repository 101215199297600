import React from "react";
import LyvePopup, { LyvePopupProps } from "../Modal/LyvePopup";
import { useResourceCreation } from "../../utils/hooks";
import LoadingAnimation from "../LyveErrorStateOverlay/LoadingAnimation";
import { DefaultButton } from "../LyveButton";
import { X } from "react-bootstrap-icons";

import "./styles.scss";
import { callAll } from "../../utils/functions";
import { ErrorStateModal } from "../LyveErrorStateOverlay";

export declare interface PrepareResourcesProps<D> {
  showLoader: boolean;
  data: D;
  closePopup: () => void;
}

declare interface CreateResourcesProps<D> extends LyvePopupProps {
  shouldPlayAnimationAt?: number;
  creationTimeout?: number;
  initialData: D;
  animationTitle: string;
  failedTitle: string;
  timeoutTitle: string;
  timeoutSubTitle: string;
  entity: string;
  onSuccess?: () => void;
  apiCallback: () => Promise<D>;
  children: (
    props: PrepareResourcesProps<D>
  ) => React.ReactElement<PrepareResourcesProps<D>>;
}

function CreateResources<D>({
  shouldPlayAnimationAt = 5,
  creationTimeout = 30,
  initialData,
  animationTitle,
  failedTitle,
  timeoutTitle,
  timeoutSubTitle,
  entity,
  children,
  onSuccess,
  apiCallback,
  ...rest
}: CreateResourcesProps<D>) {
  const {
    data,
    counter,
    isCreationPending,
    isCreationFailed,
    runCreation,
    resetCreation,
    resetCounter,
    reset
  } = useResourceCreation<D>(
    initialData,
    creationTimeout,
    shouldPlayAnimationAt
  );
  const shouldPlayAnimation =
    counter >= shouldPlayAnimationAt && isCreationPending;

  const changeSubTitles = counter % 10 >= shouldPlayAnimationAt;

  const tryCreationAgain = () => {
    resetCreation();
    runCreation(apiCallback());
  };

  React.useEffect(() => {
    if (rest.show) {
      runCreation(apiCallback());
    }
  }, [rest.show]);

  const timeout = counter > creationTimeout;

  React.useEffect(() => {
    if (timeout) {
      reset();
      rest.onHide();
    }
  }, [counter]);

  return (
    <>
      {timeout && (
        <ErrorStateModal
          title={timeoutTitle}
          subtitle={timeoutSubTitle}
          closeBtnLabel="Close"
          retryBtnLabel=""
          error=""
          isLoading={false}
          handleClose={resetCounter}
        />
      )}
      <LyvePopup
        {...rest}
        customClose={true}
        baseProps={{ dialogClassName: "create-resources-popup" }}
      >
        {shouldPlayAnimation ? (
          <div className="d-flex flex-column align-items-center">
            <h4 className="confirmation-title title">{`${animationTitle}...`}</h4>
            <div className="confirmation-subtitle">
              {changeSubTitles ? "Please wait." : "Still working on it."}
            </div>
            <LoadingAnimation />
          </div>
        ) : isCreationFailed && counter >= shouldPlayAnimationAt ? (
          <div>
            <div className="confirmation-title-container">
              <h4 className="confirmation-title">{failedTitle}</h4>
              <button
                className="custom-close-icon"
                onClick={callAll(resetCreation, rest.onHide)}
              >
                <X size="24" color="var(--gray-9)" />
              </button>
            </div>
            <div className="creation-failed">
              Please try to
              <DefaultButton variant="link" onClick={tryCreationAgain}>
                create {entity}
              </DefaultButton>
              again.
            </div>
          </div>
        ) : (
          children({
            showLoader: isCreationPending || counter < shouldPlayAnimationAt,
            data,
            closePopup: callAll(resetCreation, rest.onHide)
          })
        )}
      </LyvePopup>
    </>
  );
}

export default CreateResources;
