import React, { useState } from "react";
import { MenuProps, OptionTypeBase, SingleValueProps } from "react-select";
import { SelectComponentsProps } from "react-select/src/Select";
import { Moment } from "moment-timezone";

import LyveSelect from "../LyveSelect";
import Menu from "./DateRangeMenu";
import SingleValue from "./DateRangeSingleValue";

import { customDatePickerStyles } from "../customSelectStyles";
import "./date-range-select-styles.scss";

interface IOwnProps extends SelectComponentsProps {
  startDate?: Moment;
  endDate?: Moment;
  onDateClick?: (range: (Moment | undefined)[]) => void;
  onCancel?: (lastValue: IListOption) => void;
}

export interface IListOption extends OptionTypeBase {
  label: string;
  value: any;
  startDate?: Moment;
  endDate?: Moment;
  calendarType: "year" | "month";
  selectsRange: boolean;
  min?: Moment;
  max?: Moment;
  yearsCount: number;
  isCustom?: boolean;
}

const LyveDateRangeSelect = (props: IOwnProps) => {
  const {
    onSubmit,
    startDate,
    endDate,
    onDateClick,
    onCancel,
    ...rest
  } = props;
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const handleCancel = () => {
    setMenuIsOpen(false);
    onCancel?.(rest.defaultValue);
  };

  const handleSubmit = (option: IListOption) => {
    onSubmit(option);
    setMenuIsOpen(false);
  };

  return (
    <LyveSelect
      styles={customDatePickerStyles}
      components={{
        Menu: (menuProps: MenuProps<IListOption, boolean>) => (
          <Menu
            {...menuProps}
            startDate={startDate}
            endDate={endDate}
            defaultValue={rest.defaultValue}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            onDateClick={onDateClick}
          />
        ),
        SingleValue: (singleValueProps: SingleValueProps<IListOption>) => (
          <SingleValue
            {...singleValueProps}
            startDate={startDate}
            endDate={endDate}
          />
        )
      }}
      menuIsOpen={menuIsOpen}
      onMenuOpen={() => setMenuIsOpen(true)}
      className="lyve-date-range-select"
      {...rest}
    />
  );
};

export default LyveDateRangeSelect;
