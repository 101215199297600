import {
  // UPDATE_MFA_PREFERENCE,
  UPDATE_WHOAMI,
  UserInfoActionTypes,
} from "./actions";
import { UserInfoState } from "./types";

const initialState: UserInfoState = {
  whoAmI: {},
};

export function reducer(
  state = initialState,
  action: UserInfoActionTypes
): UserInfoState {
  switch (action.type) {
    case UPDATE_WHOAMI:
      return {
        ...state,
        whoAmI: action.payload,
      };
    // case UPDATE_MFA_PREFERENCE:
    //   return {
    //     ...state,
    //     whoAmI: { ...state.whoAmI, mfa_preference: action.payload },
    //   };
    default:
      return state;
  }
}
