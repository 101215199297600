import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import moment from 'moment';
import { UsageFrequency } from "../hooks/useUsageMetric";

interface IYData {
	name: string;
	data: (number | null)[];
	custom?: any;
}

interface PartnerTrendChart {
	partnerXData?: (string | null)[];
	partnerYData?: IYData[];
	periodicity?: string;
}

const commmonSeriesConfig = (color: string, symbol: string) => ({
	label: {
		connectorAllowed: false,
	},
	color,
	marker: {
		enabled: false,
		fillColor: "#FFFFFF",
		lineColor: null,
		symbol,
		radius: 3,
		lineWidth: 2,
		states: {
			hover: {
				fillColor: color,
				radius: 2,
				lineWidth: 2
			}
		}
	}
});

const PartnerTrendChart: React.FC<PartnerTrendChart> = ({
	partnerXData,
	partnerYData = [],
	periodicity,
}) => {
	// const convertFileSize = (value: number) => {
	// 	let convertedValue = filesize(value, { round: 2 });
	//
	// 	if (convertedValue.search('KB') > -1) {
	// 		convertedValue = convertedValue.replace('KB', 'KiB');
	// 		return convertedValue;
	// 	} else if (convertedValue.search('MB') > -1) {
	// 		convertedValue = convertedValue.replace('MB', 'MiB');
	// 		return convertedValue;
	// 	} else if (convertedValue.search('GB') > -1) {
	// 		convertedValue = convertedValue.replace('GB', 'GiB');
	// 		return convertedValue;
	// 	} else if (convertedValue.search('TB') > -1) {
	// 		convertedValue = convertedValue.replace('TB', 'TiB');
	// 		return convertedValue;
	// 	} else if (convertedValue.search('PB') > -1) {
	// 		convertedValue = convertedValue.replace('PB', 'PiB');
	// 		return convertedValue;
	// 	} else {
	// 		return convertedValue;
	// 	}
	// };

	const options = {
		chart: {
			type: 'line',
			marginRight: 60,
			height: 250,
			styledMode: true
		},
		title: {
			text: '',
			align: 'left'
		},
		credits: {
			enabled: false
		},
		xAxis: {
			labels: {
				formatter: function(y: any): string | null {
					let day = '';
					try {
						const vals = y.value.split(' ')
						day = vals[1].slice(0, 2);
						day = day.replace(/^0+/, '');
						day = Number(day) !== 1 ? day : `${day} ${vals[0].slice(0, 3)}`

						if (periodicity === 'MONTHLY') {
							day = vals[0].slice(0, 3);
							day = day !== "Jan"
								? day
								: `${day} ${vals[vals.length - 1]}`
						}
					} catch (e) {}

					return day;
				},
				style: {
					fontSize: "12px",
					color: "#1a1a1a",
					transform: 'translate(0, 0) rotate(0deg)'
				}
			},
			tickInterval: 1,
			categories: partnerXData,
			crosshair: {
				width: 0.5,
				color: '#a8a8a8'
			},
			title: ''
		},
		legend: {
			legend: {
				enabled: periodicity !== UsageFrequency.DAILY,
				align: "center",
				verticalAlign: "bottom",
				x: 0,
				y: 0
			},
		},
		yAxis: [
			...partnerYData.map((ydata, index) => ({
				labels: {
					formatter: function(value: any): string {
						let num: number = Number(value.pos);
						return ydata.custom.formatter(num);
					},
					style: {
						fontSize: "12px",
						color: "#1a1a1a"
					}
				},
				opposite: ydata.custom.opposite,
				allowDecimals: false,
				gridLineDashStyle: 'longdash',
				crosshair: false,
				title: '',
				plotLines: [
					{
						color: '#b3b3b3', // Color value
						value: ydata.custom.avg, // Value of where the line will appear
						width: 2, // Width of the line
						label: {
							text:
								ydata.data.length &&
								(ydata.custom.avg || ydata.custom.avg === 0)
									? `Avg. ${ydata.custom.formatter(ydata.custom.avg)}`
									: "",
							align: "right",
							y: -20,
							style: {
								width: "30px",
								fontSize: "12px",
								fontFamily: "Roboto",
								color: "#4d4d4d"
							}
						}
					}
				]
			}))
		],
		plotOptions: {
			pointStart: 1,
			// series: {
			// 	label: {
			// 		connectorAllowed: false
			// 	},
			// 	color: '#6ebe49',
			// 	marker: {
			// 		enabled: false,
			// 		symbol: 'circle',
			// 		radius: 2,
			// 		lineWidth: 1
			// 	}
			// }
		},

		series: partnerYData.map((usage, index) => ({
			data: usage.data,
			yAxis: index,
			connectNulls: true,
			name: usage.name,
			custom: usage.custom,
			...commmonSeriesConfig(usage.custom.color, usage.custom.symbol)
		})),

		tooltip: {
			shared: true,
			useHTML: true,
			formatter: function(): any {
				// @ts-ignore
				const { x, points } = this;

				return `<div style="color: #ffffff; font-size: 14px; font-family: Roboto, sans-serif">
        <span style="line-height: 20px">${moment.utc(x)
					.format(periodicity === UsageFrequency.DAILY ? "DD MMM, YYYY" : "MMMM, YYYY")
				}</span>${points.map((s: any, index: number) => {
					const { legendStyle, formatter } = s.series.userOptions.custom;
					return `<br/><span style="line-height: 20px"><div style="${legendStyle}"></div>${
						s.series.name
					}: ${formatter(s.y)}</span>`;
				})}</div>`;
			},
			color: "white",
			backgroundColor: "black",
			borderWidth: 2,
			borderColor: "black",
			style: {
				color: "#CCCCCC",
				opacity: 0.85
			}
		},

		responsive: {
			rules: [
				{
					condition: {
						maxWidth: 400,
						maxHeight: 400
					},
					chartOptions: {
						legend: {
							layout: 'horizontal',
							align: 'center',
							verticalAlign: 'bottom'
						}
					}
				}
			]
		}
	};

	return (
		<div>
			<HighchartsReact highcharts={Highcharts} options={options} />
		</div>
	);
};

export default PartnerTrendChart;
