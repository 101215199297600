import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FileCode, Files, X as CloseIcon } from "react-bootstrap-icons";
import get from "lodash/get";

import * as notificationActions from "./../../../../../../components/Notification/actions";
import { DefaultButton } from "../../../../../../components/LyveButton";
import Input from "../../../../../../components/Input";
import DefaultAlert from "../../../../../../components/LyveAlert";

import "./styles.scss";

interface ConfirmationModalProps {
  account: any;
  closeModal?: any;
  onSuccessDownload?: () => void;
  setNotification?: any;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  account,
  closeModal,
  onSuccessDownload = () => null,
  setNotification
}) => {
  const [isDownloaded, setDownloaded] = React.useState<boolean>(false);

  const name = get(account, "service_account.name");
  const accessKey = get(account, "service_account.access_key");
  const secretKey = get(account, "secret_key");

  const copyToClipboard = (e: any) => {
    // @ts-ignore
    document.getElementById("secret-key").select();
    document.execCommand("copy");
    e.target.focus();
    setNotification("Copied to clipboard.");
  };

  const handleDownloadClick = (event: React.MouseEvent<unknown>) => {
    event.stopPropagation();
    setDownloaded(true);
  };

  const handleModalClose = (event: React.MouseEvent<unknown>) => {
    event.preventDefault();
    if (isDownloaded) {
      closeModal();
      onSuccessDownload();
    }
  };

  const generateCSV = () => {
    const rows = [
      ["Name", "Access Key", "Secret Key"],
      [name, accessKey, secretKey]
    ];
    const csvContent =
      "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
    return encodeURI(csvContent);
  };

  const generateJSON = () => {
    const account = {
      name,
      accessKey,
      secretKey
    };
    const csvContent =
      "data:text/json;charset=utf-8," + JSON.stringify(account) + "\n";
    return encodeURI(csvContent);
  };

  return (
    <div className="service-account-confirmation">
      {isDownloaded ? (
        <button className="custom-close-icon" onClick={handleModalClose}>
          <CloseIcon size="24" color="var(--gray-9)" />
        </button>
      ) : (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="download-warning">
              Please make sure to download the service account details before
              exiting.
            </Tooltip>
          }
        >
          <button className="custom-close-icon" onClick={handleModalClose}>
            <CloseIcon size="24" color="var(--gray-9)" />
          </button>
        </OverlayTrigger>
      )}
      <h4 className="confirmation-title">New Service Account Created</h4>
      <DefaultAlert variant="warning">
        Make sure to copy or download the secret key to a secured location, as these
        won't be available for copy or download once this dialog box is closed.
      </DefaultAlert>
      <div className="field-container">
        <label className="field-label">Service Account Name</label>
        <p className="field-value service-account-name">{name}</p>
      </div>
      <div className="field-container">
        <label className="field-label">Access Key</label>
        <p className="field-value">{accessKey}</p>
      </div>
      <div className="field-container">
        <label className="field-label">Secret Key</label>
        <div className="secret-key-container">
          <Input
            controlId="secret-key"
            readOnly
            value={secretKey}
            rows="1"
            controlAs="textarea"
          />
          <DefaultButton onClick={copyToClipboard} variant="primary">
            <Files size="16" />
            Copy
          </DefaultButton>
        </div>
      </div>
      <div className="field-container">
        <label className="field-label">Download as</label>
        <div>
          <a
            download={`${name}.csv`}
            href={generateCSV()}
            onClick={handleDownloadClick}
          >
            <DefaultButton variant="outline-secondary" className="mr-2">
              <FileCode size="16" />
              CSV
            </DefaultButton>
          </a>
          <a
            download={`${name}.json`}
            href={generateJSON()}
            onClick={handleDownloadClick}
          >
            <DefaultButton variant="outline-secondary">
              <FileCode size="16" />
              JSON
            </DefaultButton>
          </a>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setNotification: (message: string) =>
      dispatch(notificationActions.setNotification(message))
  };
};

export default connect(undefined, mapDispatchToProps)(ConfirmationModal);
