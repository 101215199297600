import React from 'react';
import { Tab, TabProps as BTabProps, Tabs as BTabs, TabsProps as BTabsProps } from 'react-bootstrap'

import "./styles.scss"
import cx from "classnames";

declare interface TabProps extends Omit<BTabProps, 'ref'> {
  title: React.ReactNode;
  content: React.ReactNode
}

declare interface TabsProps extends BTabsProps {
  tabs: TabProps[]
  type?: 'default' | 'compact'
}

const Tabs: React.FC<TabsProps> = ({ tabs, type = 'default', ...rest }) => (
  <BTabs
    {...rest}
    className={cx(
      "lyve-tabs",
      type === "compact" && "lyve-tabs-compact"
    )}
  >
    {tabs.map(({ content, ...tabProps }, index) => (
      <Tab
        {...tabProps}
        key={index}
      >
        {content}
      </Tab>
    ))}
  </BTabs>
);

export default Tabs;