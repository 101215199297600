import * as React from "react";
import { UseFormMethods } from "react-hook-form";

import { SubAccountForm } from "./index";

// @ts-ignore
const FormContext = React.createContext<UseFormMethods<SubAccountForm>>();

declare interface FormProviderProps {
  value: UseFormMethods<SubAccountForm>;
}

const FormProvider: React.FC<FormProviderProps> = ({ value, children }) => {
  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

const useFormContext = () => {
  const context = React.useContext(FormContext);
  if (context === undefined) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
};

export { FormProvider, useFormContext };
