import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../store";
import { SliceState, StoreStateStatus } from "./types";

export interface RegionWithSG {
    name: string;
    url: string;
}

declare type State = SliceState<RegionWithSG[]>;

const initialState: State = { status: StoreStateStatus.IDLE, error: null, data: [] };

const regionsWithSgSlice = createSlice({
    name: "regionswithsg",
    initialState,
    reducers: {
        loadingRegionsWithSG: state => {
            state.status = StoreStateStatus.LOADING;
        },
        resolveRegionsWithSG: (state, action: PayloadAction<RegionWithSG[]>) => {
            state.status = StoreStateStatus.RESOLVED;
            state.data = action.payload || [];
        },
        rejectRegionsWithSG: (state, action: PayloadAction<Error>) => {
            state.status = StoreStateStatus.REJECTED;
            state.error = action.payload;
        },
    }
});

export const {
    loadingRegionsWithSG,
    resolveRegionsWithSG,
    rejectRegionsWithSG,
} = regionsWithSgSlice.actions;
export default regionsWithSgSlice.reducer;

export const selectRegionsWithSG = (state: AppState) => state.regionswithsg;
