import { useEffect, useState } from "react";
import api from "../../../../../services/api";

const useBucketCount = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [buckets, setBuckets] = useState<number>(0);
  const [error, setError] = useState<string>("");

  const getBucketCount = async () => {
    setLoading(true);
    try {
      const bucketResponse = await api.countBucket();
      setBuckets(bucketResponse?.count || 0);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getBucketCount();
  }, []);

  return {
    loading,
    buckets,
    error,
    getBucketCount
  };
};

export default useBucketCount;
