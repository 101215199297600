import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../store";
import { SliceState, StoreStateStatus } from "./types";

export interface ArchiveBucket {
  name: string;
  creation_date: string;
  // size: string
  region: string;
  usage: number;
  compliance: boolean;
  versioned: boolean;
  logged: boolean;
  retentionDuration: number; //number of "days" or "years"
  retentionUnit: string;
  deletionMarker: boolean;
}

type State = SliceState<ArchiveBucket[]>;

const initialState: State = {
  status: StoreStateStatus.IDLE,
  error: null,
  data: []
};

const archiveBucketsSlice = createSlice({
  name: "archiveBuckets",
  initialState,
  reducers: {
    addArchiveBuckets: (state, action: PayloadAction<ArchiveBucket[]>) => {
      state.data = action.payload || []
      state.status = StoreStateStatus.RESOLVED
    },
    removeArchiveBucket: (state, action: PayloadAction<string>) => {
      const buckets = [...state.data];
      const matchedBucketIdx = buckets.findIndex(
        bucket => bucket.name === action.payload
      );
      buckets.splice(matchedBucketIdx, 1);
      state.data = [...buckets];
    }
  }
});

export const { addArchiveBuckets, removeArchiveBucket } = archiveBucketsSlice.actions;
export default archiveBucketsSlice.reducer;

export const selectArchiveBuckets = (state: AppState) => state.archiveBuckets;
