import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { DefaultButton } from "../../../../components/LyveButton";
import "./styles.scss";
import api from "../../../../services/api/index";
import { useDispatch } from "react-redux";
import { setNotificationRecipients } from "../../../NotificationRecipients/reducer";
import * as notificationActions from "./../../../Notification/actions";

interface Props {
  userId: string;
  email: string;
  customerId: string;
  firstName: string;
  lastName: string;
  closeModal: () => void;
  onSuccess?: (email: any) => void;
  onError?: () => void;
}

const DeleteUser: React.FC<Props> = ({
  email,
  closeModal,
  onSuccess,
  onError,
  firstName,
  lastName,
  customerId,
  userId
}) => {
  const dispatch = useDispatch();
  const [processing, setprocessing] = useState<boolean>(false);

  const handleDeleteUser = () => {
    setprocessing(true);
    api
      .deleteUser(userId)
      .then(() => {
        Promise.all([
          api.getAllNotificationRecipients(customerId),
          api.listUsers()
        ]).then(values => {
          let notificationRecipientList = values[0];
          let userList = values[1];
          dispatch(
            setNotificationRecipients(
              notificationRecipientList.notificationRecipient
            )
          );
          dispatch(
            notificationActions.setNotification(
              `${firstName} ${lastName} has been deleted.`
            )
          );
          if (typeof onSuccess !== "undefined") {
            onSuccess(userList);
          }
          if (typeof closeModal !== "undefined") {
            closeModal();
          }
          setprocessing(false);
        });
      })
      .catch(err => {
        if (err.code == 14 || err.status == 503) {
          dispatch(
            notificationActions.setErrorNotification(
              err.message
            )
          );
        }else{
          dispatch(
            notificationActions.setErrorNotification(
              `Failed to delete user ${firstName} ${lastName}.`
            )
          );
        }
        setprocessing(false);
      });
  };

  return (
    <div className="delete-user-module">
      <h4 className="delete-user-title">Delete User</h4>
      <h4 className="delete-user-title2">
        Are you sure you want to delete this user?
      </h4>
      <div>
        <div className="delete-user-subtitle">
          This action will remove the user from Lyve Cloud
        </div>
        <div>
          <div className="user-label">Email</div>
          <div className="user-name">{email}</div>
        </div>
      </div>
      <div>
        <DefaultButton
          className="mr-2"
          variant="danger"
          disabled={processing}
          onClick={() => {
            handleDeleteUser();
          }}
        >
          {processing ? (
            <Spinner animation="border" role="status" className="spinner">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            "Yes"
          )}
        </DefaultButton>
        <DefaultButton
          disabled={processing}
          onClick={closeModal}
          variant="outline-secondary"
        >
          No
        </DefaultButton>
      </div>
    </div>
  );
};

export default DeleteUser;
