import React from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import Select from "react-select";
import { SETTINGS_EDIT } from "../../authRules";
import Can from "../Can";
import CustomToggle from "../CustomToggleButton";
import api from "../../services/api";
import regionApis from "../../services/RegionApis";
import {
  Bucket,
  ListBucketResponse
} from "../../scenes/Dashboard/components/Buckets/types";
import ConfirmationModal from "../ConfirmationModal";
import ReadOnlyStatus from "../ReadOnlyStatus";
import LyveHeading from "../LyveHeading";
import Input from "../Input";
import "./styles.scss";
import { AllRegions, Region } from "../../scenes/Dashboard/components/Buckets";
import { AppState } from "../../store";
import { Dispatch } from "redux";
import { compose } from "recompose";
import { connect } from "react-redux";
import * as notificationActions from "../Notification/actions";
import LyveLink from "../LyveHeadingLink/LyveLink";
import { getLink, linkNames } from "../LyveHeadingLink/LinkUtil";
import ServiceAccountExpiration from "./ServiceAccountExpiration";

const AUDIT_LOGS = "auditLogs";
const S3_ENABLED = "s3AuditLogsEnabled";
const CONSOLE_ENABLED = "consoleAuditLogsEnabled";
const TARGET_BUCKET = "targetBucket";
const LOG_ALL_BUCKETS = "logAllBuckets";

interface Props {
  hideModal: () => void;
  submit: (b: Bucket) => void;
  buckets: Bucket[];
  error: boolean;
  submitTargetBucket: boolean;
}

const formatOptionLabel = ({ label, region }: any) => (
  <div className="d-flex justify-content-between">
    <div>{label}</div>
    <div>({region})</div>
  </div>
);

export type Data = {
  message: string;
};

const AuditTargetBucketModal: React.FC<Props> = ({
  hideModal,
  buckets,
  submit,
  error,
  submitTargetBucket
}) => {
  const firstBucket = buckets[0];
  const [selectedBucket, setSelectedBucket] = React.useState<
    Bucket | undefined
  >(firstBucket ? firstBucket : undefined);

  return (
    <div
      className="popup lyve-popup"
      id="exampleModal"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Audit Log Target Bucket
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={hideModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body ">
            <div className="mb-4 modal-subtitle">
              Audit logs can only be saved in a bucket that is in immutability
              mode
            </div>
            <form>
              <div>
                {buckets.length > 0 ? (
                  <div className="form-group">
                    <div>Select Bucket</div>
                    {/* <Form.Control
                      as="select"
                      value={selectedBucket ? selectedBucket.name : undefined}
                      onChange={e => {
                        const bucketName = (e.target as HTMLSelectElement)
                          .value;
                        const bucket = buckets.find(b => b.name === bucketName);
                        setSelectedBucket(bucket);
                      }}
                    >
                      {buckets.map(b => (
                        <option key={b.name} value={b.name}>
                          <div>{b.name + " "+ b.region}</div>
                        </option>
                      ))}
                    </Form.Control> */}
                    <Select
                      name="buckets"
                      options={buckets.map(b => ({
                        label: b.name,
                        value: b.name,
                        region: b.region
                      }))}
                      isSearchable={false}
                      formatOptionLabel={formatOptionLabel}
                      styles={{
                        indicatorSeparator: styles => ({ display: "none" }),
                        control: base => ({
                          ...base,
                          border: "1px solid #ccc",
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #ccc"
                          }
                        }),
                        option: base => ({
                          ...base,
                          borderBottom: "1px solid #ccc"
                        }),
                        singleValue: base => ({
                          ...base,
                          width: "1000%",
                          maxWidth: "calc(100% - 10px)"
                        })
                      }}
                      value={
                        selectedBucket
                          ? {
                            label: selectedBucket.name,
                            value: selectedBucket.name,
                            region: selectedBucket.region
                          }
                          : undefined
                      }
                      onChange={e => {
                        if (e) {
                          const bucketName = e.value;
                          const bucket = buckets.find(
                            b => b.name === bucketName
                          );
                          setSelectedBucket(bucket);
                        }
                      }}
                    />
                  </div>
                ) : (
                  <div>No buckets found!</div>
                )}
              </div>
            </form>
            <div className="modal-note" />
            {error && (
              <div className="model-error">
                An error has occurred. Please try again later. If the issue
                persists, please contact support.
              </div>
            )}
            <div className="button-section">
              <button
                type="button"
                className="btn btn-primary"
                disabled={!selectedBucket}
                onClick={() => {
                  if (selectedBucket) {
                    submit(selectedBucket);
                  }
                }}
              >
                {submitTargetBucket && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}{" "}
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface SProps { }
interface DispatchProps {
  setErrorNotification: (message: string) => any;
  setNotification: (message: string) => any;
}

type NewRecipientProps = DispatchProps & SProps;

const Settings = ({
  setNotification,
  setErrorNotification
}: NewRecipientProps) => {
  const [s3Value, setS3Value] = React.useState(false);
  const [errors, setErrors] = React.useState(false);
  const [S3APILogsError, setS3APILogsError] = React.useState("");
  const [consoleAuditLogsError, setConsoleAuditLogsError] = React.useState("");
  const [
    errorAuditLogTargetBucket,
    setErrorAuditLogTargetBucket
  ] = React.useState(false);

  const [s3AuditLogsEnabled, setS3AuditLogsEnabled] = React.useState(false);
  const [consoleLogsEnabled, setConsoleLogEnabled] = React.useState(false);
  const [targetBucket, setTargetBucket] = React.useState<Bucket | undefined>(
    undefined
  );
  const [logAllBuckets, setLogAllBuckets] = React.useState(true);
  const [originalLogSetting, setOriginalLogSetting] = React.useState(true);
  const [displayBucketSelector, setDisplayBucketSelector] = React.useState(
    false
  );
  const [buckets, setBuckets] = React.useState<Bucket[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);
  const [submitTargetBucket, setSubmitTargetBucket] = React.useState(false);

  const [
    displayConfirmationModal,
    setDisplayConfirmationModal
  ] = React.useState("");
  const [regionList, setRegionList] = React.useState<Region[]>([]);

  const updateStateFromResponse = (config: any, buckets: Bucket[]) => {
    if (config && config.features) {
      const configValues = config.features;
      const _s3AuditLogsEnabled = configValues.find(
        (f: any) => f.key === S3_ENABLED
      );
      const _consoleLogsEnabled = configValues.find(
        (f: any) => f.key === CONSOLE_ENABLED
      );
      const _s3AuditLogsTargetBucket = configValues.find(
        (f: any) => f.key === TARGET_BUCKET
      );
      const _s3AuditLogsSettings = configValues.find(
        (f: any) => f.key === LOG_ALL_BUCKETS
      );
      if (_s3AuditLogsEnabled) {
        setS3AuditLogsEnabled(_s3AuditLogsEnabled.value === "true");
      }
      if (_consoleLogsEnabled) {
        setConsoleLogEnabled(_consoleLogsEnabled.value === "true");
      }
      if (_s3AuditLogsTargetBucket) {
        if (buckets) {
          const bucket = buckets.find(
            b => b.name === _s3AuditLogsTargetBucket.value
          );
          if (bucket) {
            setTargetBucket(bucket);
          }
        }
      }
      if (_s3AuditLogsSettings) {
        setOriginalLogSetting(_s3AuditLogsSettings.value === "true");
        setLogAllBuckets(_s3AuditLogsSettings.value === "true");
      }
    }
  };

  React.useEffect(() => {
    getBuckets_auditConfig_Regions();
  }, []);

  const getBuckets_auditConfig_Regions = async () => {
    try {
      setIsLoading(true);
      const bucketList: ListBucketResponse = await api.listBuckets();
      const compliance_buckets = bucketList.buckets.filter(b => b.compliance);
      setBuckets(compliance_buckets);
      const auditConfig = await api.getConfig(AUDIT_LOGS);
      updateStateFromResponse(auditConfig, bucketList.buckets);
      const regionList: AllRegions = await api.getAllRegions();
      setRegionList(regionList.regions);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const updateLogSetting = (event: React.ChangeEvent<HTMLInputElement>) => {
    setS3Value(true);
    setErrors(false);
    setLogAllBuckets(event.currentTarget.value === "all");
  };

  const submitS3APILogsEnabled = async (value: boolean) => {
    setSubmitting(true);
    setS3AuditLogsEnabled(value);
    const config = {
      body: [
        {
          configName: AUDIT_LOGS,
          configValues: [
            {
              key: S3_ENABLED,
              value: String(value)
            }
          ]
        }
      ]
    };
    const regionUrlList = regionList.map(regionObj =>
      regionObj.name === targetBucket?.region ? regionObj.url : ""
    );
    const regionUrl = regionUrlList.find(url => url !== "");
    try {
      await regionApis.updateConfig(regionUrl || "", config);
      // setNotification("Updated S3 API Audit Logs settings");
      setS3APILogsError("");
      setDisplayConfirmationModal("");
    } catch (err) {
      // console.log(err);
      // setErrorNotification("Failed to update S3 API Audit Logs settings");
      setS3APILogsError(
        "An error has occurred. Please try again later. If the issue persists, please contact support."
      );
      setS3AuditLogsEnabled(!value);
    } finally {
      setSubmitting(false);
    }
  };

  const submitConsoleLogsEnabled = async (value: boolean) => {
    setSubmitting(true);
    setConsoleLogEnabled(value);

    const config = {
      body: [
        {
          configName: AUDIT_LOGS,
          configValues: [
            {
              key: CONSOLE_ENABLED,
              value: String(value)
            }
          ]
        }
      ]
    };
    const regionUrlList = regionList.map(regionObj =>
      regionObj.name === targetBucket?.region ? regionObj.url : ""
    );
    const regionUrl = regionUrlList.find(url => url !== "");
    try {
      await regionApis.updateConfig(regionUrl || "", config);
      setConsoleAuditLogsError("");
      setDisplayConfirmationModal("");
    } catch (err) {
      console.log(err);
      setConsoleAuditLogsError(
        "An error has occurred. Please try again later. If the issue persists, please contact support."
      );
      setConsoleLogEnabled(!value);
    } finally {
      setSubmitting(false);
    }
  };

  const submitLogAllBuckets = async (value: boolean) => {
    // console.log("logAllBuckets : "+ logAllBuckets);
    // console.log("originalLogSetting : "+ originalLogSetting);
    setSubmitting(true);
    setErrors(false);
    const config = {
      body: [
        {
          configName: AUDIT_LOGS,
          configValues: [
            {
              key: LOG_ALL_BUCKETS,
              value: String(value)
            }
          ]
        }
      ]
    };
    const regionUrlList = regionList.map(regionObj =>
      regionObj.name === targetBucket?.region ? regionObj.url : ""
    );
    const regionUrl = regionUrlList.find(url => url !== "");
    try {
      await regionApis.updateConfig(regionUrl || "", config);
      setOriginalLogSetting(logAllBuckets);
      setS3Value(false);
    } catch (err) {
      // Set to orignal values if get error orrcured
      setS3Value(true);
      setErrors(true);
      setLogAllBuckets(originalLogSetting);
    } finally {
      setSubmitting(false);
    }
  };

  const submitAllSettingsWithBucket = async (bucket: Bucket) => {
    setSubmitTargetBucket(true);
    const config = {
      body: [
        {
          configName: AUDIT_LOGS,
          configValues: [
            {
              key: S3_ENABLED,
              value: String(s3AuditLogsEnabled)
            },
            {
              key: TARGET_BUCKET,
              value: bucket.name
            },
            {
              key: LOG_ALL_BUCKETS,
              value: String(logAllBuckets)
            },
            {
              key: CONSOLE_ENABLED,
              value: String(consoleLogsEnabled)
            }
          ]
        }
      ]
    };
    const regionUrlList = regionList.map(regionObj =>
      regionObj.name === bucket.region ? regionObj.url : ""
    );
    const regionUrl = regionUrlList.find(url => url !== "");
    // console.log("bucket.region - ", regionUrl);
    try {
      await regionApis.updateConfig(regionUrl || "", config);
      setErrorAuditLogTargetBucket(false);
      setDisplayBucketSelector(false);
      setTargetBucket(bucket);
    } catch (err) {
      // console.log(err);
      setErrorAuditLogTargetBucket(true);
    } finally {
      setSubmitTargetBucket(false);
    }
  };

  return (
    <div className="settings-container">
      {isLoading ? (
        <div className="data-loader">
          <Spinner animation="border" role="status" className="spinner">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <LyveHeading
            title="Settings"
            subTitle=""
          ></LyveHeading>
          <ServiceAccountExpiration />
          <LyveHeading
            title="Audit Log Settings"
            subTitle=""
          ></LyveHeading>
          {targetBucket && (
            <Card className="view-bucket-card" style={{ minWidth: 840 }}>
              <Card.Body>
                <div className="d-flex mb-3">
                  <div className="d-flex flex-column">
                    <div className="VB-card-title">Audit Log Target Bucket</div>
                    <div className="VB-card-description">
                      All audit logs will be saved in this bucket.
                      <LyveLink
                        link={getLink(linkNames.Audit_Log_Target_Bucket)}
                      />
                    </div>
                  </div>
                  <div className="ml-auto">
                    <Can perform={SETTINGS_EDIT}>
                      <Button
                        variant="outline-secondary"
                        onClick={(event: any) => {
                          setDisplayBucketSelector(true);
                        }}
                      >
                        Edit
                      </Button>
                    </Can>
                  </div>
                </div>
                <div>
                  {targetBucket.name} ({targetBucket.region})
                </div>
              </Card.Body>
            </Card>
          )}
          <Card className="view-bucket-card" style={{ minWidth: 840 }}>
            <Card.Body>
              <div className="d-flex mb-3">
                <div className="d-flex flex-column">
                  <div className="VB-card-title">S3 API Audit Logs</div>
                  <div className="VB-card-description">
                    Create audit log files for all S3 API operations on a
                    bucket.
                    <LyveLink link={getLink(linkNames.S3_API_Audit_Logs)} />
                  </div>
                </div>
                <div className="ml-auto">
                  <Can
                    perform={SETTINGS_EDIT}
                    no={<ReadOnlyStatus enabled={s3AuditLogsEnabled} />}
                  >
                    <CustomToggle
                      selected={s3AuditLogsEnabled}
                      toggleSelected={async () => {
                        const wantsToEnable = !s3AuditLogsEnabled;
                        if (wantsToEnable) {
                          if (!targetBucket) {
                            setS3AuditLogsEnabled(true);
                            setDisplayBucketSelector(true);
                          } else {
                            setDisplayConfirmationModal("s3LogsEnabled");
                          }
                        } else {
                          setDisplayConfirmationModal("s3LogsDisabled");
                        }
                      }}
                    />
                  </Can>
                </div>
              </div>
              <div>
                {s3AuditLogsEnabled && (
                  <Can
                    perform={SETTINGS_EDIT}
                    no={
                      <div>
                        <Input
                          controlId="all"
                          label="All buckets must be logged"
                          controlAs="radio"
                          value="all"
                          disabled
                          defaultChecked={logAllBuckets}
                          className="radio-input"
                        />
                        <Input
                          controlId="individual"
                          label="Individually set per bucket"
                          controlAs="radio"
                          value="individual"
                          disabled
                          defaultChecked={!logAllBuckets}
                          className="radio-input"
                        />
                      </div>
                    }
                  >
                    <div>
                      <Input
                        controlId="all"
                        label="All buckets must be logged"
                        controlAs="radio"
                        value="all"
                        defaultChecked={logAllBuckets}
                        onChange={updateLogSetting}
                        className="radio-input"
                      />
                      <Input
                        controlId="individual"
                        label="Individually set per bucket"
                        controlAs="radio"
                        value="individual"
                        defaultChecked={!logAllBuckets}
                        onChange={updateLogSetting}
                        className="radio-input"
                      />
                      {errors && (
                        <div className="model-error">
                          An error has occurred. Please try again later. If the
                          issue persists, please contact support.
                        </div>
                      )}
                      {s3Value && (
                        <div className="mt-2">
                          <Button
                            className="mr-2"
                            disabled={submitting}
                            onClick={async () => {
                              await submitLogAllBuckets(logAllBuckets);
                              // setOriginalLogSetting(logAllBuckets);
                            }}
                          >
                            {submitting && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}{" "}
                            Save
                          </Button>
                          <Button
                            variant="outline-secondary"
                            onClick={() => {
                              setS3Value(false);
                              setErrors(false);
                              setLogAllBuckets(originalLogSetting);
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      )}
                    </div>
                  </Can>
                )}
              </div>
            </Card.Body>
          </Card>
          <Card className="view-bucket-card" style={{ minWidth: 840 }}>
            <Card.Body>
              <div className="d-flex">
                <div className="d-flex flex-column">
                  <div className="VB-card-title">Account Audit Logs</div>
                  <div className="VB-card-description">
                    Create audit log files for account and IAM operations.
                    <LyveLink link={getLink(linkNames.Console_Audit_Logs)} />
                  </div>
                </div>
                <div className="ml-auto">
                  <Can
                    perform={SETTINGS_EDIT}
                    no={<ReadOnlyStatus enabled={consoleLogsEnabled} />}
                  >
                    <CustomToggle
                      selected={consoleLogsEnabled}
                      toggleSelected={async () => {
                        const wantsToEnable = !consoleLogsEnabled;
                        if (wantsToEnable) {
                          if (!targetBucket) {
                            setConsoleLogEnabled(true);
                            setDisplayBucketSelector(true);
                          } else {
                            setDisplayConfirmationModal("consoleLogsEnabled");
                          }
                        } else {
                          setDisplayConfirmationModal("consoleLogsDisabled");
                        }
                      }}
                    />
                  </Can>
                </div>
              </div>
            </Card.Body>
          </Card>
          {displayBucketSelector && (
            <AuditTargetBucketModal
              hideModal={() => {
                setDisplayBucketSelector(false);
                // Set enabled to false if modal is closed without selecting a bucket
                if (!targetBucket) {
                  setS3AuditLogsEnabled(false);
                  setConsoleLogEnabled(false);
                }
                setErrorAuditLogTargetBucket(false);
              }}
              submit={async bucket => {
                await submitAllSettingsWithBucket(bucket);
              }}
              buckets={buckets}
              error={errorAuditLogTargetBucket}
              submitTargetBucket={submitTargetBucket}
            />
          )}
          <div>
            <ConfirmationModal
              show={displayConfirmationModal === "s3LogsEnabled"}
              submit={async () => {
                // Upsate s3LogsEnabled
                await submitS3APILogsEnabled(true);
                // setS3AuditLogsEnabled(true);
                // setDisplayConfirmationModal("");
              }}
              onClose={() => {
                setS3AuditLogsEnabled(false);
                setDisplayConfirmationModal("");
                setS3APILogsError("");
              }}
              title="S3 API Audit Logs Confirmation"
              description={`Are you sure you want to create audit log files for all S3 API operations on bucket ${targetBucket ? targetBucket.name : ""
                }?`}
              error={S3APILogsError}
            />
            <ConfirmationModal
              show={displayConfirmationModal === "s3LogsDisabled"}
              submit={async () => {
                // Upsate s3LogsEnabled
                await submitS3APILogsEnabled(false);
                // setS3AuditLogsEnabled(false);
                // setDisplayConfirmationModal("");
              }}
              onClose={() => {
                setS3AuditLogsEnabled(true);
                setDisplayConfirmationModal("");
                setS3APILogsError("");
              }}
              title="S3 API Audit Logs Confirmation"
              description={`Are you sure you want to stop creating audit log files for S3 API operations on bucket... ${targetBucket ? targetBucket.name : ""
                }?`}
              error={S3APILogsError}
            />
            <ConfirmationModal
              show={displayConfirmationModal === "consoleLogsEnabled"}
              submit={async () => {
                await submitConsoleLogsEnabled(true);
                // setConsoleLogEnabled(true);
                // setDisplayConfirmationModal("");
              }}
              onClose={() => {
                setConsoleLogEnabled(false);
                setDisplayConfirmationModal("");
                setConsoleAuditLogsError("");
              }}
              title="Account Audit Logs Confirmation"
              description={`Are you sure you want to create audit log files for account and IAM operations on bucket ${targetBucket ? targetBucket.name : ""
                }?`}
              error={consoleAuditLogsError}
            />
            <ConfirmationModal
              show={displayConfirmationModal === "consoleLogsDisabled"}
              submit={async () => {
                await submitConsoleLogsEnabled(false);
                // setConsoleLogEnabled(false);
                // setDisplayConfirmationModal("");
              }}
              onClose={() => {
                setConsoleLogEnabled(true);
                setDisplayConfirmationModal("");
                setConsoleAuditLogsError("");
              }}
              title="Account Audit Logs Confirmation"
              description={`Are you sure you want to stop creating audit log files for account and IAM operations on bucket ${targetBucket ? targetBucket.name : ""
                }?`}
              error={consoleAuditLogsError}
            />
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  notificationRecipients: state.notification.message
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setErrorNotification: (message: string) =>
    dispatch(notificationActions.setErrorNotification(message)),
  setNotification: (message: string) =>
    dispatch(notificationActions.setNotification(message))
});

// export default Settings;
export default compose<NewRecipientProps, SProps>(
  connect(mapStateToProps, mapDispatchToProps)
)(Settings);
