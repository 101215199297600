import { WhoAmI } from "./types";

export const UPDATE_WHOAMI = "UPDATE_WHOAMI";
// export const UPDATE_MFA_PREFERENCE = "UPDATE_MFA_PREFERENCE";

export interface UpdateUserInfoAction {
  type: typeof UPDATE_WHOAMI;
  payload: WhoAmI;
}

// export interface UpdateMFAPreferenceAction {
//   type: typeof UPDATE_MFA_PREFERENCE;
//   payload: string;
// }

export type UserInfoActionTypes =
  | UpdateUserInfoAction
  // | UpdateMFAPreferenceAction;
