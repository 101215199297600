import React from "react";

const Forbidden: React.FC = () => {
  return (
    <div>
      <h1>Forbidden</h1>
      <p>You do not have the necessary permissions to visit this URL.</p>
    </div>
  );
};

export default Forbidden;
