import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import moment, { Moment } from "moment-timezone";
import { CardDeck } from "react-bootstrap";
import { QuestionCircleFill } from "react-bootstrap-icons";
import ReactTooltip from "react-tooltip";
import { CSVLink } from "react-csv";
import Highcharts from "highcharts";

import api from "../../../../services/api";
import { BasicCard, Card, MetricCard } from "../../../../components/Card";
import LyveHeading from "../../../../components/LyveHeading";
import { LyveDateRangeSelect, LyveSelect } from "../../../../components/LyveDropdown";
import { IListOption } from "../../../../components/LyveDropdown/LyveDateRangeSelect/LyveDateRangeSelect";
import { AppState, useAppDispatch } from "../../../../store";
import { DefaultLoader } from "../../../../components/LyveLoader";
import {
  convertFileSize,
  getCurrentMonthStartDate,
  getFrequencyByRange,
  getLastMonthsDate,
  isDaily,
  sortByDateTime,
  timezone
} from "../../../../utils";
import Can from "../../../../components/Can";
import { setMonthToDateUsage } from "../../../../state/lyveCloud";
import { WhoAmI } from "../../../../state/UserInfo/types";
import { DefaultButton } from "../../../../components/LyveButton";

import CustomerDailyUsageChart from "./charts/CustomerDailyUsageChart";
import SubaccountsDetails from "./charts/SubaccountsDetails";
import SubaccountTrendChart from "./charts/SubaccountTrendChart";
import MonthCostCard from "./cards/MonthCostCard";
import EstimatedCostCard from "./cards/EstimatedCostCard";
import useBucketCount from "./hooks/useBucketCount";
import useUsageMetric, { UsageFrequency } from "./hooks/useUsageMetric";
import "./styles.scss";
import PartnerTrendChart from "./charts/PartnerTrendChart";
import { ISelectOption } from "../../../../components/LyveDropdown/LyveSelect";
import { SubAccountData } from "../../../../state/Subaccounts/types";

interface ICostUsage {
  time: string;
  usage?: number;
  cost?: number;
}

interface IBucketUsageResponse {
  time: string;
  usage?: string;
  bucket_name: string;
  region_name: string;
}

interface ISubaccountUsageResponse {
  time: string;
  usage?: string;
  org_name: string;
}

interface GetMetricResponse {
  bucket_usage?: IBucketUsageResponse[];
  current_month_avg_usage: number;
  cost_metrics: ICostUsage[];
}

interface IBucketUsage {
  time: string;
  usageInByte: string;
  usageInGB: string;
  bucket_name: string;
  region_name: string;
}

interface ISubaccountUsage {
  time: string;
  usageInByte: string;
  usageInGB: string;
  org_name: string;
}

interface dashboard_trend {
  master_trend: master_trend[];
  sub_accounts_trend: sub_accounts_trend[];
}

interface master_trend {
  date: string;
  usage: number;
  user_count: number;
  service_accounts_count: number;
  buckets_count: number;
}

interface sub_accounts_trend {
  tenant_name: string;
  tenant_trend: tenant_trend[];
}

interface tenant_trend {
  date: string;
  usage: number;
  user_count: number;
  service_accounts_count: number;
  buckets_count: number;
}

interface dashboardsummary {
  master_summary: master_summary;
  subaccount_summary: subaccount_summary[];
}

interface master_summary {
  master_or_sub_account_name: string;
  users_count: number;
  service_accounts_count: number;
  buckets_count: number;
  usage: number;
  usage_percent: string;
  onboard_date: string;
}

export interface subaccount_summary {
  master_or_sub_account_name: string;
  users_count: number;
  service_accounts_count: number;
  buckets_count: number;
  usage: number;
  usage_percent: string;
  onboard_date: string;
}

interface ChartData {
  name: string;
  data: (number | null)[];
}

export const convertFileSizeInGB = (bytes: string): string => {
  const size = parseFloat(bytes) / 1000000000;

  return size.toFixed(6);
};

const Metrics: React.FC = () => {
  const dispatch = useAppDispatch();
  const whoAmI = useSelector<AppState, WhoAmI>(state => state.userInfo.whoAmI);
  const { createdAt, userCreatedAt, evalLoading } = useSelector(
    (state: AppState) => ({
      evalLoading: state.eval.loading,
      createdAt: state.userInfo.whoAmI.created_at,
      userCreatedAt: state.userInfo.whoAmI.created_date_ft
    })
  );

  const [masterDataLoading, setMasterDataLoading] = React.useState(false);
  const [subAccDataLoading, setSubAccDataLoading] = React.useState(false);
  const [costDataLoading, setCostDataLoading] = React.useState(false);

  const [summaryDataInitialized, setSummaryDataInitialized] = React.useState<
    boolean
  >(false);
  const [totalUsage, setTotalUsage] = React.useState<
    number | string
  >(0);
  const [averageUsageIncludingSubAccounts,setAverageUsageIncludingSubAccounts] = React.useState<
  number | string
>(0);
  const [customerUsageXData, setCustomerUsageXData] = React.useState<
    Array<string | null>
  >([]);
  const [subAccountXData, setSubAccountXData] = React.useState<Array<string>>([]);
  const [customerUsageYData, setCustomerUsageYData] = React.useState<
    Array<number | null>
  >([]);
  const [customerCostYData, setCustomerCostYData] = React.useState<
    Array<number | null>
  >([]);
  const [isShowCostSeries, setShowCostSeries] = React.useState(false);
  const [usageData, setUsageData] = React.useState<any[]>(
    []
  );
  const [totalBuckets, setTotalBuckets] = React.useState<number>(0);
  const [totalSubaccounts, setTotalSubaccounts] = React.useState<number>(0);
  const [Subaccount_summaryData, setSubaccount_summaryData] = React.useState<
    subaccount_summary[]
  >([]);
  const [masterName, setMasterName] = React.useState<string>("");
  const [companyId, setCompanyId] = React.useState<string>("");
  const [masterAverageValue, setMasterAverageValue] = React.useState<number>(
    0
  );
  const [subaccountTrendChartData, setSubaccountTrendChartData] = React.useState<ChartData[]>(
    []
  );
  const [startDate, setStartDate] = useState<Moment | undefined>(
    getCurrentMonthStartDate(createdAt || userCreatedAt)
  );
  const [endDate, setEndDate] = useState<Moment | undefined>(moment());
  const [frequency, SetFequency] = React.useState<UsageFrequency>(
    UsageFrequency.DAILY
  );
  const [dupFrequency, setDupFrequency] = React.useState(UsageFrequency.DAILY);

  const [enableSubaccounts, setEnableSubaccounts] = React.useState<
    boolean
  >(false);
  const [waitForAccountApi, setWaitForAccountApi] = React.useState(true);
  const [isMaster, setIsMaster] = React.useState(false);
  const [masterAccount, setMasterAccount] = React.useState<SubAccountData | null>(null)
  const [isMasterUsage, setIsMasterUsage] = React.useState(false);

  const userStartDate = whoAmI?.created_date_ft ?? "";
  const downloadBtnRef: any = React.useRef(null)
  const { loading: bucketsLoading, buckets } = useBucketCount();
  const userType = whoAmI?.type ?? "";

  const newNode = (str: string, size: string) => (
    <span>
      <span className="submetrics-label"> {str} </span>
      <span className="submetrics-value">{size}</span>
    </span>
  );

  const fileSizeNode = (value: { value: number; symbol: string }) => {
    return newNode(`${value.value}`, value.symbol.replace(/kB/, "KB"));
  };

  const yearsCount =
    createdAt || userStartDate
      ? moment(createdAt || userStartDate).get("year") < moment().get("year")
        ? 2
        : 1
      : 1;

  const dateOptions: IListOption[] = [
    {
      label: "This month",
      value: 0,
      calendarType: "year",
      selectsRange: false,
      min:
        createdAt || userStartDate
          ? moment(createdAt || userStartDate)
          : undefined,
      max: moment(),
      yearsCount
    },
    {
      label: "Last 6 months",
      value: 1,
      calendarType: "year",
      selectsRange: true,
      min:
        createdAt || userStartDate
          ? moment(createdAt || userStartDate)
          : undefined,
      max: moment(),
      yearsCount
    },
    {
      label: "Custom time range",
      value: 2,
      calendarType: "year",
      selectsRange: true,
      min:
        createdAt || userStartDate
          ? moment(createdAt || userStartDate)
          : undefined,
      max: moment(),
      yearsCount,
      isCustom: true
    }
  ];

  const [value, setValue] = useState<IListOption>(dateOptions[0]);
  const [selectedOption, setSelectedOption] = React.useState<IListOption>({
    ...dateOptions[0],
    startDate,
    endDate
  });

  const [subaccountValue, setSubaccountValue] = useState<IListOption>(dateOptions[0]);

  const constructCostMetricsData = (costMetrics: ICostUsage[]) => {
    const costMetricsData: ICostUsage[] = [];

    if (!costMetrics || costMetrics.length < 1) {
      return costMetricsData;
    }

    if (frequency === UsageFrequency.DAILY) {
      costMetrics.forEach(data => {
        costMetricsData.push({
          ...data,
          usage: data.usage ? parseFloat(data.usage.toString()) : undefined
        });
      });
    } else {
      costMetrics.forEach(data => {
        costMetricsData.push({
          ...data,
          usage: data.usage ? parseFloat(data.usage.toString()) : undefined,
          cost: data.cost ? parseFloat(data.cost.toString()) : undefined
        });
      });
    }

    return costMetricsData;
  };

  const setData = (res: GetMetricResponse) => {
    if (selectedOption.value === 0) {
      if (res.current_month_avg_usage) {
        const usage = parseInt(`${res.current_month_avg_usage}`, 10);
        setTotalUsage(usage);
        dispatch(setMonthToDateUsage(usage));
      } else {
        setTotalUsage(0);
        dispatch(setMonthToDateUsage(0));
      }
    }

    const constructedData = constructCostMetricsData(res?.cost_metrics || []);
    generateCustomerUsageChartData(constructedData);
    if (!isDailyUsage && userType !== "tenant" && !enableSubaccounts) {
      generateCustomerCostChartData(constructedData);
    }
  };

  const getSummaryData = (accountID: any, masterAccountName: any) => {
    api
      .getDashboardSummary(accountID)
      .then(res => {
        setSummaryData(res, masterAccountName);
        setSummaryDataInitialized(true);
      })
      .catch(err => {
        console.log(err);
        setTotalBuckets(0);
        setSubaccount_summaryData([]);
        setSummaryDataInitialized(true);
      });
  };

  // Set state subaccount details graph
  const setSummaryData = (res: dashboardsummary, masterAccountName: any) => {
    if (res.master_summary) {
      setMaster_summary(res.master_summary);
      setAverageUsageIncludingSubAccounts(res.master_summary?.usage)
    }

    if (res.subaccount_summary) {
      let obj: subaccount_summary[] = [];
      let temp = res.subaccount_summary;

      try {
        setTotalSubaccounts(temp !== undefined ? temp.length - 1 : 0);
      } catch (error) {}

      temp.map(async d => {
        let data: subaccount_summary = d;

        obj.push(data);
      });

      // setTimeout(() => {
        obj.sort((a, b) =>
          a.usage < b.usage ? 1 : -1
        );

        setSubaccount_summaryData(obj);
      // }, 1000);
    }
  };

  // set state for subaccount usage graph
  const setMaster_summary = (res: master_summary) => {
    if (res.buckets_count) {
      setTotalBuckets(res.buckets_count);
    } else {
      setTotalBuckets(0);
    }
  };

  const getDashboardTrend = async (
    startdate: string,
    enddate: string,
    accountID: any,
    periodicity: string,
    timezone: string,
  ) => {
    setCustomerUsageXData([]);
    setCustomerUsageYData([]);
    setCustomerCostYData([]);
    setSubAccountXData([])
    setSubaccountTrendChartData([]);
    setSubAccDataLoading(true)
    setMasterDataLoading(true)

    await api
      .getDashboardTrend(accountID, periodicity, startdate, enddate, timezone)
      .then((res) => {
        getMasterTrendData(res);
        getSubaccountTrendData(res);

        getSubacountName(res);
      })
      .catch(err => {
        console.log(err);
      }).finally(() => {
        setMasterDataLoading(false)
        setSubAccDataLoading(false)
      })

    if (!isDailyUsage) {
      setCostDataLoading(true)
      await api.getMasterMetrics({
        account_id: accountID,
        timezone,
        from_date: startdate,
        to_date: enddate,
        frequency: periodicity
      }).then(res => {
        // @ts-ignore
        const constructedData = constructCostMetricsData(res?.cost_metrics || []);
        generateCustomerCostChartData(constructedData);
      }).finally(() => {
        setCostDataLoading(false)
      })
    }
  };

  const getMasterTrendData = (res: dashboard_trend) => {
    const sortedTrend = res.master_trend.sort(
      sortByDateTime({ param: "date", inputFormat: "YYYY-MM-DD" })
    )
    generateMasterChartData(sortedTrend);
  };

  const generateMasterChartData = (mastertrend: master_trend[]) => {
    let xdata: any = [];
    let ydata: Array<number> = [];

    const data = mastertrend.map(du => {
      var dateLimit = moment(du.date);
      var now = moment();
      if (now.isAfter(dateLimit)) {
        var chartDate = moment(du.date).format("MMM DD, YYYY");

        if (
          now.format("MMM DD, YYYY") === chartDate &&
          du.usage === undefined
        ) {
          console.log(
            "No data generate yet for " +
              chartDate +
              " and the data is :" +
              du.usage
          );
        } else {
          xdata.push(chartDate);
          let usage = du.usage === undefined ? 0 : du.usage;
          ydata.push(usage);
        }
      }
    });
    const chartData = {
      data: ydata,
      name: xdata
    };

    setCustomerUsageXData(xdata);
    setCustomerUsageYData(ydata);
    try {
      var total = 0;
      for (var i = 0; i < ydata.length; i++) {
        total += ydata[i] ? ydata[i] : 0;
      }
      var avg = total / ydata.length;

      setMasterAverageValue(avg);
    } catch (e) {
      console.error(e);
    }
  };

  const getSubaccountTrendData = (res: dashboard_trend) => {
    const trends = res.sub_accounts_trend
    const sortedTrends = trends.map(trend => ({
      ...trend,
      tenant_trend:  trend.tenant_trend.sort(
        sortByDateTime({ param: "date", inputFormat: "YYYY-MM-DD" })
      )
    }))
    generateSubaccountChartDataNew(sortedTrends);
  };

  // generate data for subaccount details chart
  const generateSubaccountChartDataNew = (
    subaccountstrend: sub_accounts_trend[]
  ): Array<any> => {
    const inputFormat = "YYYY-MM-DD hh:mm:ss";
    let xdata: any = [];
    const start = moment(startDate);
    const end = moment(endDate);

    const frequency = getFrequencyByRange(start, end);

    const getLimit   = () => {
      let lowestLimit = moment(subaccountstrend[0]?.tenant_trend[0]?.date, inputFormat)
      subaccountstrend.forEach(trend => {
        const limit = moment(trend.tenant_trend[0]?.date, inputFormat);
        if (lowestLimit.isAfter(limit)) {
          lowestLimit = limit
        }
      })

      return lowestLimit
    }

    const data = subaccountstrend.map(obj => {
      let ydata: Array<number | null> = [];
      let tname = obj.tenant_name;
      const usageList = obj.tenant_trend
      if (usageList.length) {
        if (frequency === UsageFrequency.DAILY) {
          let limit = moment(startDate)
          let index= 0
          while (limit <= end.endOf("day")) {
            const isDateMatch =
              moment(usageList[index]?.date, inputFormat)
                .startOf("day")
                .format(inputFormat) === limit.startOf("day").format(inputFormat);

            // For creating x points
            xdata.push(limit.format("MMM DD, YYYY"));
            // For creating y points
            if (isDateMatch && usageList[index]?.usage !== undefined) {
              ydata.push(Number(usageList[index].usage));
              index++;
            } else {
              ydata.push(null);
            }
            limit.add(1, "day");
          }
        } else {
          const limit = getLimit()
          let index = 0;
          while (limit <= end.endOf("month")) {
            const isDateMatch =
              moment(usageList[index]?.date, inputFormat)
                .startOf("month")
                .format("MM YYYY") === limit.startOf("month").format("MM YYYY");

            // For creating x points
            xdata.push(limit.format("MMM DD, YYYY"));

            // For creating y points
            if (isDateMatch && usageList[index]?.usage !== undefined) {
              ydata.push(Number(usageList[index].usage));
            } else {
              ydata.push(null);
            }
            if (isDateMatch) {
              index++;
            }
            limit.add(1, "month");
          }
        }
      }

      setSubAccountXData(xdata)

      return {
        name: tname,
        data: ydata
      };
    });

    setSubaccountTrendChartData(data.sort((a, b) => a.name.localeCompare(b.name)));
    return data;
  };

  function adjustLegend() {
    try {
      var charts: any = Highcharts.charts;

      var count = 0;
      var firstChartMargin = 0;
      var secondChartMargin = 0;

      charts.filter((a: any) => a !== undefined);

      charts.forEach((chart: any) => {
        if (chart !== undefined) {
          if (count == 0) {
            firstChartMargin = chart.marginBottom;
          } else {
            secondChartMargin = chart.marginBottom;
          }

          count++;
        }
      });

      charts.forEach((chart: any) => {
        if (chart !== undefined) {
          if (chart.marginBottom < firstChartMargin) {
            chart.update({
              chart: {
                marginBottom: firstChartMargin
              }
            });
          } else if (chart.marginBottom < secondChartMargin) {
            chart.update({
              chart: {
                marginBottom: secondChartMargin
              }
            });
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  const getSubacountName = (res: dashboard_trend) => {
    let arr: string[] = [];

    res.sub_accounts_trend.map(d => {
      arr.push(d.tenant_name);
    });

    return arr;
  };

  const generateBucketUsageData = (bucketUsageList: IBucketUsageResponse[]) => {
    const inputFormat = "YYYY-MM-DD hh:mm:ss";
    const newBucketUsage: IBucketUsage[] = [];

    if (bucketUsageList.length) {
      bucketUsageList.forEach(bucketUsage => {
        newBucketUsage.push({
          time: moment(bucketUsage.time, inputFormat).format(
            isDailyUsage ? "YYYY-MM-DD" : "MMM-YYYY"
          ),
          bucket_name: bucketUsage.bucket_name,
          region_name: bucketUsage?.region_name ?? "",
          usageInByte: bucketUsage.usage ? bucketUsage.usage : "",
          usageInGB: bucketUsage.usage
            ? convertFileSizeInGB(bucketUsage.usage)
            : ""
        });
      });
    }

    setUsageData(newBucketUsage);
  };

  const generateSubaccountUsageData = (subaccountUsageList: ISubaccountUsageResponse[]) => {
    const inputFormat = "YYYY-MM-DD hh:mm:ss";
    const newSubaccountUsage: ISubaccountUsage[] = [];
    
    if (subaccountUsageList.length) {
      subaccountUsageList.forEach(subaccountUsage => {
        newSubaccountUsage.push({
          time: moment(subaccountUsage.time, inputFormat).format(
            isDailyUsage ? "YYYY-MM-DD" : "MMM-YYYY"
          ),
          org_name: subaccountUsage.org_name,
          usageInByte: subaccountUsage.usage ? subaccountUsage.usage : "",
          usageInGB: subaccountUsage.usage
            ? convertFileSizeInGB(subaccountUsage.usage)
            : ""
        });
      });
    }

    setUsageData(newSubaccountUsage);
  };

  const generateCustomerCostChartData = (usageList: ICostUsage[]) => {
    const inputFormat = "YYYY-MM-DD hh:mm:ss";
    const ydata: Array<number | null> = [];
    const end = moment(endDate);

    const sortedUsageList = usageList.sort(sortByDateTime({ param: "time" }));

    if (usageList.length) {
      let limit = moment(sortedUsageList[0].time, inputFormat);
      let index = 0;
      while (limit <= end.endOf("month")) {
        const isDateMatch =
          moment(sortedUsageList[index]?.time, inputFormat)
            .startOf("month")
            .format("MM YYYY") === limit.startOf("month").format("MM YYYY");

        // For creating y points
        if (isDateMatch && sortedUsageList[index]?.cost !== undefined) {
          ydata.push(Number(sortedUsageList[index].cost));
          setShowCostSeries(true);
        } else {
          ydata.push(0);
        }
        if (isDateMatch) {
          index++;
        }
        limit.add(1, "month");
      }
    }

    setCustomerCostYData(ydata);
  };

  const generateCustomerUsageChartData = (usageList: ICostUsage[]) => {
    const inputFormat = "YYYY-MM-DD hh:mm:ss";
    const xdata: Array<string | null> = [];
    const ydata: Array<number | null> = [];
    const start = moment(startDate);
    const end = moment(endDate);

    const frequency = getFrequencyByRange(start, end);

    const sortedUsageList = usageList.sort(sortByDateTime({ param: "time" }));

    if (usageList.length) {
      if (frequency === UsageFrequency.DAILY) {
        let limit = moment(startDate);
        let index = 0;
        while (limit <= end.endOf("day")) {
          const isDateMatch =
            moment(sortedUsageList[index]?.time, inputFormat)
              .startOf("day")
              .format(inputFormat) === limit.startOf("day").format(inputFormat);

          // For creating x points
          xdata.push(isDateMatch ? limit.format(inputFormat) : null);

          // For creating y points
          if (isDateMatch && sortedUsageList[index]?.usage !== undefined) {
            ydata.push(Number(sortedUsageList[index].usage));
            index++;
          } else {
            ydata.push(null);
          }
          limit.add(1, "day");
        }
      } else {
        let limit = moment(sortedUsageList[0].time, inputFormat);
        let index = 0;
        while (limit <= end.endOf("month")) {
          const isDateMatch =
            moment(sortedUsageList[index]?.time, inputFormat)
              .startOf("month")
              .format("MM YYYY") === limit.startOf("month").format("MM YYYY");

          // For creating x points
          xdata.push(isDateMatch ? limit.format(inputFormat) : null);

          // For creating y points
          if (isDateMatch && sortedUsageList[index]?.usage !== undefined) {
            ydata.push(Number(sortedUsageList[index].usage));
          } else {
            ydata.push(null);
          }
          if (isDateMatch) {
            index++;
          }
          limit.add(1, "month");
        }
      }
    }
    setCustomerUsageXData(xdata);
    setCustomerUsageYData(ydata);
  };

  const { data: usageMetric, getUsageMetric, metricsLoading } = useUsageMetric({
    onSuccess: (res: GetMetricResponse) => {
      if (isMounted.current) {
        setData(res);
      }
    },
    onError: () => {
      if (isMounted.current) {
        setTotalUsage(0);
        generateCustomerUsageChartData([]);
        generateCustomerCostChartData([]);
      }
    }
  });

  React.useEffect(() => {
    const accountShortName: string | undefined = whoAmI?.company_short_name;
    setWaitForAccountApi(true)
    if (accountShortName) {
      api
        .getSubaccount(accountShortName)
        .then(res => {
          if (res) {
            setEnableSubaccounts(res?.is_master && !!res?.sub_account_max_limit);
            setIsMaster(res?.is_master)
            setMasterAccount(res)
          }
        })
        .catch(() => {
          setEnableSubaccounts(false);
        })
        .finally(() => {
          setWaitForAccountApi(false)
        })
    }
  }, [])

  React.useEffect(() => {
    if (!enableSubaccounts)  return;

    const companyId: string | null = whoAmI?.company_id ?? "";
    const mName: string | null = whoAmI?.company_name ?? "";
    const mID = companyId;

    if (companyId !== null) setCompanyId(companyId);
    if (mName !== null) setMasterName(mName);

    setTimeout(() => {
      getSummaryData(mID, mName);
    }, 1000);
  }, [enableSubaccounts]);

  let isMounted = useRef(false); // empty array ensures the effect is ran only once on component mount

  React.useEffect(() => {
    const format = "YYYY-MM-DD";
    let start = moment(startDate);
    let end = moment(endDate);
    const firstTimeLoad = !isMounted.current;
    let frequency = getFrequencyByRange(start, end)

    if (!enableSubaccounts && !waitForAccountApi) {
      getUsageMetric(
        {
          timezone,
          from_date: start.format(format),
          to_date: end.endOf("month").format(format),
          frequency
        },
        firstTimeLoad
      );
    }
    isMounted.current = true;

    if (companyId && enableSubaccounts && !waitForAccountApi) {
      getDashboardTrend(
        start.format(format),
        end.format(format),
        companyId,
        frequency,
        timezone
      ).finally(() => {
        setDupFrequency(frequency)
      });
    }
  }, [selectedOption, companyId, waitForAccountApi]);

  const handleChange = (option: IListOption) => {
    setSelectedOption({
      ...value,
      startDate,
      endDate
    });
    SetFequency(getFrequencyByRange(moment(startDate), moment(endDate)));
    setUsageData([]);
  };

  const handleCancel = (option: IListOption) => {
    const {
      startDate: prevStartDate,
      endDate: prevEndDate,
      ...restSelectedOption
    } = selectedOption;
    setValue(restSelectedOption);
    setSubaccountValue(restSelectedOption);
    setStartDate(prevStartDate);
    setEndDate(prevEndDate);
  };

  const handleValueChange = (option: IListOption) => {
    setValue(option);
    setSubaccountValue(option);
    if (option.value === 0) {
      setStartDate(getCurrentMonthStartDate(createdAt || userCreatedAt));
      setEndDate(moment());
    } else if (option.value === 1) {
      setStartDate(getLastMonthsDate(6, createdAt || userCreatedAt));
      setEndDate(moment());
    } else {
      setStartDate(
        selectedOption.value !== option.value
          ? undefined
          : selectedOption.startDate
      );
      setEndDate(
        selectedOption.value !== option.value
          ? undefined
          : selectedOption.endDate
      );
    }
  };

  const handleDateClick = (range: (Moment | undefined)[]) => {
    const custom = dateOptions.find(option => option.isCustom);
    if (custom) {
      setValue(custom);
      setSubaccountValue(custom);
      setStartDate(range[0]);
      setEndDate(range[1]);
    }
  };

  const downloadUsageReport = () => {
    if (startDate != undefined && endDate != undefined) {
      let format = "YYYY-MM-DD HH:mm:ss";
      const start = moment(startDate);
      const end = moment(endDate);
      const from_date = start.format(format)
      const to_date = end.endOf("month").format(format)

      if (enableSubaccounts) {
        api.downloadMasterUsageReport(from_date, to_date, companyId).then(async res => {
          if (res.records && res.records.length) {
            generateSubaccountUsageData(res.records);
          } else {
            generateSubaccountUsageData([]);
          }
          downloadBtnRef?.current?.link?.click()
        })
      } else {
        format = "YYYY-MM-DD";
        api.getBucketUsage({
          timezone,
          from_date: start.format(format),
          to_date: end.endOf("month").format(format),
          frequency: getFrequencyByRange(start, end)
        }).then(async res => {
          if (res.bucket_usage && res.bucket_usage.length) {
            generateBucketUsageData(res.bucket_usage);
          } else {
            generateBucketUsageData([]);
          }
          downloadBtnRef?.current?.link?.click()
        })
      }
    }
  };

  const renderChart = () => {
    const isDailyUsage = isDaily(
      selectedOption.startDate,
      selectedOption.endDate
    );

    const yData = [
      {
        name: "Usage",
        data: customerUsageYData,
        custom: {
          color: "#1a1a1a",
          symbol: "bubble",
          formatter: (value: number) => {
            const fileSize = convertFileSize(value);
            return fileSize.replace(/kB/, "KB");
          },
          ...(isDailyUsage
            ? { avg: masterAverageValue }
            : {}),
          opposite: false,
          legendStyle:
            "display: inline-block; margin-top: 10px; width: 8px; height: 8px; border: 1px solid #ffffff; border-radius: 50%; background-color: #1a1a1a; margin-right: 5px"
        }
      },
    ]

    if (frequency !== UsageFrequency.DAILY) {
      yData.push(
          {
            name: "Cost",
            data: customerCostYData,
            custom: {
              color: "#6EBE49",
              symbol: "square",
              formatter: (value: number) => {
                return `$${value}`;
              },
              opposite: true,
              legendStyle:
                  "display: inline-block; width: 8px; height: 8px; border: 1px solid #ffffff; background-color: #6EBE49; margin-right: 5px"
            }
          }
      )
    }

    if (enableSubaccounts) {
      return (
        <PartnerTrendChart
          partnerXData={customerUsageXData}
          partnerYData={yData}
          periodicity={dupFrequency}
        />
      )
    }

    return (
      <CustomerDailyUsageChart
        customerUsageXData={customerUsageXData}
        loading={metricsLoading}
        showLegend={!!usageMetric?.cost_metrics?.length}
        customerYData={[
          {
            name: "Usage",
            data: customerUsageYData,
            custom: {
              color: "#1a1a1a",
              symbol: "bubble",
              formatter: (value: number) => {
                const fileSize = convertFileSize(value);
                return fileSize.replace(/kB/, "KB");
              },
              ...(isDailyUsage
                ? {
                    avg:
                      customerUsageYData.reduce(
                        (a: number, c: number | null) => a + (c || 0),
                        0
                      ) / (usageMetric?.cost_metrics?.length || 1)
                  }
                : {}),
              opposite: false,
              legendStyle:
                "display: inline-block; margin-top: 10px; width: 8px; height: 8px; border: 1px solid #ffffff; border-radius: 50%; background-color: #1a1a1a; margin-right: 5px"
            }
          },
          ...(!isDailyUsage && userType !== "tenant" && isShowCostSeries
            ? [
                {
                  name: "Cost",
                  data: customerCostYData,
                  custom: {
                    color: "#6EBE49",
                    symbol: "square",
                    formatter: (value: number) => {
                      return `$${value}`;
                    },
                    opposite: true,
                    legendStyle:
                      "display: inline-block; width: 8px; height: 8px; border: 1px solid #ffffff; background-color: #6EBE49; margin-right: 5px"
                  }
                }
              ]
            : [])
        ]}
        usageListCount={usageMetric?.cost_metrics?.length || 0}
        frequency={frequency}
      />
    );
  };

  const renderSubaccountChart = () => {
    return (
      <SubaccountTrendChart
        masterXData={subAccountXData}
        subaccountData={subaccountTrendChartData}
        periodicity={frequency}
      />
    );
  };

  const isDailyUsage = isDaily(
    selectedOption.startDate,
    selectedOption.endDate
  );

  const getUpdatedOptions = () =>
    dateOptions.map(option =>
      option.value === selectedOption.value ? selectedOption : option
    );

  const renderLabelWithTooltip = (
    label: string,
    tooltipId: string,
    description: string
  ) => {
    return (
      <>
        <span className="label">{label}</span>
        <QuestionCircleFill
          size="16"
          data-for={tooltipId}
          data-tip={description}
        />
        <ReactTooltip
          id={tooltipId}
          effect="solid"
          multiline
          place="bottom"
          className="metric-card-tooltip"
        />
      </>
    );
  };

  const renderChartTitle = () => {
    return (
      <div className="chart-title-section">
        <div className="label-section">
          {renderLabelWithTooltip(
            isDailyUsage ? "Daily Average Usage" : "Monthly Average Usage",
            "chart",
            isDailyUsage
              ? "The daily average usage is computed as an average of 4 measurements of<br />total consumption taken during the day"
              : "Computed as average of all the daily average usage of the month"
          )}
        </div>
      </div>
    );
  };

  const renderSubaccountChartTitle = () => {
    return (
      <div className="chart-title-section">
        <div className="label-section">
          {renderLabelWithTooltip(
            "Usage by Accounts",
            "chart",
            "Computed as average of all the daily average usage of the month"
          )}
        </div>
      </div>
    );
  };

  const loading = bucketsLoading || evalLoading;
  const rangeOptions = getUpdatedOptions();

  const getFileName = (startDate: string, endDate: string) => {
    const start = moment(startDate);
    const end = moment(endDate);
    if (isDailyUsage) {
      return `${whoAmI.company_short_name}-${
        enableSubaccounts ? isMasterUsage ? "master-report-" : "subaccounts-report-" : ""
      }${start.format("MMMM-YYYY")}.csv`;
    } else {
      return `${whoAmI.company_short_name}-${start.format(
        "MMMM-YYYY"
      )}-${end.format("MMMM-YYYY")}.csv`;
    }
  };

  const CSVHeaders = [
    { label: `${isDailyUsage ? "Date" : "Month"}`, key: "time" },
    { label: "Region Name", key: "region_name" },
    { label: "Bucket Name", key: "bucket_name" },
    { label: "Usage (byte)", key: "usageInByte" },
    { label: "Usage (GB)", key: "usageInGB" }
  ];

  const CSVHeadersForSubAccounts = [
    { label: `${isDailyUsage ? "Date" : "Month"}`, key: "time" },
    { label: "Accounts", key: "org_name" },
    { label: "Usage (byte)", key: "usageInByte" },
    { label: "Usage (GB)", key: "usageInGB" }
  ];

  const masterUsageDropdownOptions = [
    {
      label: "Account's Usage",
      value: "account-usage"
    },
    {
      label: "Master's Usage",
      value: "master-usage"
    },
  ]

  const changeUsageDropdownOption = (option: ISelectOption) => {
    if (option?.value === "account-usage") {
      downloadUsageReport()
      setIsMasterUsage(false)
    } else if (masterAccount) {
      setIsMasterUsage(true)
      const format = "YYYY-MM-DD";
      api.downloadSubaccountBucketUsage(
        masterAccount?.id,
        moment(startDate).format(format),
        moment(endDate).format(format),
        frequency,
        timezone
      ).then(async res => {
        if (res.bucket_usage && res.bucket_usage.length) {
          generateBucketUsageData(res.bucket_usage);
        } else {
          generateBucketUsageData([]);
        }
        downloadBtnRef?.current?.link?.click()
      })
    }
  }

  return (
    <div className="dashboard-wrapper">
      {loading && !summaryDataInitialized ? (
        <DefaultLoader animation="border" />
      ) : (
        <>
          <LyveHeading title="Home" subTitle="" />
            <div className="dashboard-general-title">
            <span>General Overview</span>
          </div>
          <CardDeck>
            {enableSubaccounts && (
              <MetricCard
                label="Sub-accounts"
                value={totalSubaccounts}
              />
            )}
            <MetricCard
              label="Buckets"
              // value={buckets}
              value={enableSubaccounts ? totalBuckets : buckets}
            />
            <MetricCard
              label={renderLabelWithTooltip(
                isMaster ? "Average Usage" : "Month-to-Date Usage",
                "usage",
                "The average of all daily usage averages from the beginning of the month to date"
              )}
              value={fileSizeNode(convertFileSize(enableSubaccounts ? averageUsageIncludingSubAccounts : totalUsage, "object"))}
            />
            
            <MonthCostCard />
          </CardDeck>
          <div className="dashboard-graph">
            <div className="dashboard-graph-title">
              <span>General Report</span>
            </div>
            
            {/* Download button hidden as per AP-11146 - Only requirement is download usage breakdown by sub-account for the time range selected*/}

            <div className="d-flex">
              <LyveDateRangeSelect
                startDate={startDate}
                endDate={endDate}
                value={value}
                onChange={handleValueChange}
                onDateClick={handleDateClick}
                isSearchable={false}
                defaultValue={selectedOption}
                options={rangeOptions}
                onSubmit={handleChange}
                onCancel={handleCancel}
              />
              {isMaster ? (
                <LyveSelect
                  role="button"
                  className="button-dropdown"
                  isSearchable={false}
                  placeholder="Download"
                  value="Download"
                  options={masterUsageDropdownOptions}
                  onChange={changeUsageDropdownOption}
                />
              ) : (
                <DefaultButton
                  variant="outline-secondary"
                  onClick={downloadUsageReport}
                  className="csv-download-btn"
                >
                  Download
                </DefaultButton>
              )}
              <CSVLink
                data={usageData}
                ref={downloadBtnRef}
                className="csv-download-link"
                target="_blank"
                headers={enableSubaccounts ? isMasterUsage ? CSVHeaders : CSVHeadersForSubAccounts : CSVHeaders}
                filename={getFileName(
                  startDate?.toString() ?? "",
                  endDate?.toString() ?? ""
                ).toLowerCase()}
              /> 
            </div>
          </div>
          <CardDeck>
            <BasicCard
              title={renderChartTitle()}
              body={renderChart()}
              loading={masterDataLoading || costDataLoading}
            />
          </CardDeck>
          {enableSubaccounts && (
            <>
              <div className="dashboard-graph subaccount-report">
                <div className="dashboard-graph-title">
                  <span>Usage Report</span>
                </div>
              </div>
              <CardDeck>
                <BasicCard
                  title={renderSubaccountChartTitle()}
                  body={renderSubaccountChart()}
                  loading={subAccDataLoading}
                />
              </CardDeck>
              <div className="dashboard-general-title subaccount-report">
                <span>Accounts Summary</span>
              </div>
              <CardDeck style={{ flexDirection: "column" }}>
                <Card
                  body={
                    <SubaccountsDetails subaccountList={Subaccount_summaryData} loading={!summaryDataInitialized} />
                  }
                  className="table-card"
                />
              </CardDeck>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Metrics;
