import React from "react";
import { Card } from "react-bootstrap";
import { DefaultLoader } from "../../LyveLoader";

import "./styles.scss";
import clsx from "clsx";
import { CardProps, CardType } from "../../../scenes/SmartStartHere/types.d";
import { X } from "react-bootstrap-icons";

declare interface BaseCardProps extends CardProps {
  loading?: boolean;
  className?: string;
  body?: React.ReactNode;
  footer?: React.ReactNode | string;
  title: React.ReactNode | string;
  description?: React.ReactNode | string;
  removeCard: (cardType: CardType) => void;
}

const BaseCard: React.FC<BaseCardProps> = props => {
  const handleRemoveCard = () => props.removeCard(props.type);

  return (
    <Card className={clsx("base-card", props.className)}>
      {props.loading ? (
        <DefaultLoader animation="border" />
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center pb-2">
            <div className="title">
              <span className="title-strip" />
              <span> {props.title} </span>
            </div>
            {props.dismissible && (
              <X className="dismiss-img" onClick={handleRemoveCard} />
            )}
          </div>
          {props.description && (
            <div className="description"> {props.description} </div>
          )}
          {props.body && <Card.Body>{props.body}</Card.Body>}
          {props.footer && props.footer}
        </>
      )}
    </Card>
  );
};

export default BaseCard;
