import React from "react";
import "./styles.css";
import Spinner from "react-bootstrap/Spinner";

interface Props {
  selected: boolean;
  toggleSelected?: () => void;
  processing?: boolean;
}

const CustomToggle = (props: Props) => {
  const { selected, toggleSelected, processing } = props;
  return (
    <div
      className={`toggle-container ${!selected &&
        "toggle-container-off"} ${processing && "toggle-processing"}`}
      onClick={processing ? () => {} : toggleSelected} // Disable onClick if processing (to avoid multiple triggers)
    >
      <div className={selected ? "on" : "off"}>{selected ? "ON" : "OFF"}</div>

      <div
        className={`toggle-dialog-button ${selected ? "" : "toggle-disabled"}`}
      >
        {processing && (
          <Spinner
            // variant="success"
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
      </div>
    </div>
  );
};

export default CustomToggle;
