import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import Select from "react-select";
import { Props as SourceBucket } from "../index";
import regionApis from "../../../../../../../services/RegionApis";
import regionAPI from "../../../../../../../services/RegionApis";
import { Region } from "../../../index";
import LyveLink from "../../../../../../../components/LyveHeadingLink/LyveLink";

import "./styles.scss";
import { getLink, linkNames } from "../../../../../../../components/LyveHeadingLink/LinkUtil";

interface Props {
  sourceBucket: SourceBucket;
  hideModal: () => void;
  onSuccess?: (versioned: boolean) => void;
  onError?: () => void;
  regionList: Region[];
  region: string;
  regionName: string;
  bucketName: string;
}

const getDescription = (complianceMode?: boolean) => {
  return complianceMode
    ? "Since this source bucket was created in immutable mode, the destination bucket must also be one that was created in immutable mode."
    : "Versioning will be enabled on both the source and target bucket.";
};

const formatOptionLabel = ({ label, region }: any) => (
  <div className="d-flex justify-content-between">
    <div>{label}</div>
    <div>({region})</div>
  </div>
);

const AddReplicationRulePrompt: React.FC<Props> = ({
  hideModal,
  sourceBucket,
  onSuccess,
  region,
  onError,
  regionName,
  regionList,
  bucketName
}) => {
  const [buckets, setBuckets] = useState<bucketDetail[]>([]);
  const sourceBucketName = sourceBucket.bucketName;
  const firstBucket = buckets[0];
  const [selectedBucket, setSelectedBucket] = React.useState<
    bucketDetail | undefined
  >(firstBucket ? firstBucket : undefined);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const addReplicationRule = async () => {
    if (selectedBucket) {
      setIsSubmitting(true);
      setError(null);
      try {
        const regionUrlList = regionList.map(regionObj =>
          regionObj.name === regionName ? regionObj.url : ""
        );
        const regionUrl = regionUrlList.find(url => url !== "");
        await regionApis.addReplicationRule(
          regionUrl || "",
          sourceBucket.bucketName,
          selectedBucket.name
        );
        const details = await regionApis.getBucketDetails(
          regionUrl || "",
          sourceBucket.bucketName
        );
        if (typeof onSuccess !== "undefined") {
          onSuccess(details.versioned ? details.versioned : false);
        }
        hideModal();
        setIsSubmitting(false);
      } catch (error) {
        setError("Adding Replication Rule failed.");
        setIsSubmitting(false);
      }
    }
  };

  interface bucketDetail {
    name: string;
    region: string;
  }

  interface targetResponse {
    buckets?: bucketDetail[];
    replicationEnabled?: boolean;
  }

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const targetList: targetResponse = await regionAPI.getReplicationTargetBucketList(
          region,
          bucketName
        );
        setBuckets(targetList.buckets || []);
      } catch {
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div className="add-rule-module">
      <h4 className="add-rule-title">Add Replication Rule</h4>
      <div className="add-rule-subtitle">
        {getDescription(sourceBucket.complianceOnCreation)}
        <LyveLink link={getLink(linkNames.Add_Replication_Rule)} />
      </div>
      <div className="rule-label">Source Bucket</div>
      <div className="rule-name">
        {sourceBucket.bucketName} ({regionName})
      </div>
      {loading ? (
        <div className="col-12 d-flex align-items-center justify-content-center mb-2">
          <div
            className="spinner-border"
            style={{ color: "#6ebe49" }}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <form>
          {buckets.length > 0 ? (
            <div>
              <div className="rule-label">Destination Bucket</div>
              <Select
                name="buckets"
                options={buckets
                  .filter(bucket => bucket.name !== sourceBucketName)
                  .map(b => ({
                    label: b.name,
                    value: b.name,
                    region: b.region
                  }))}
                isSearchable={false}
                formatOptionLabel={formatOptionLabel}
                styles={{
                  indicatorSeparator: styles => ({ display: "none" }),
                  control: base => ({
                    ...base,
                    border: "1px solid #ccc",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #ccc"
                    }
                  }),
                  option: base => ({
                    ...base,
                    borderBottom: "1px solid #ccc"
                  }),
                  singleValue: base => ({
                    ...base,
                    width: "1000%",
                    maxWidth: "calc(100% - 10px)"
                  })
                }}
                value={
                  selectedBucket
                    ? {
                        label: selectedBucket.name,
                        value: selectedBucket.name,
                        region: selectedBucket.region
                      }
                    : undefined
                }
                onChange={e => {
                  if (e) {
                    const bucketName = e.value;
                    const bucket = buckets.find(b => b.name === bucketName);
                    setSelectedBucket(bucket);
                  }
                }}
              />
            </div>
          ) : (
            <div>No buckets found!</div>
          )}
        </form>
      )}
      {error && <div className="text-danger">{error}</div>}
      <div className="mt-3">
        <Button
          disabled={!selectedBucket || isSubmitting}
          onClick={() => {
            addReplicationRule();
          }}
        >
          {isSubmitting ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-1"
            />
          ) : (
            "Add"
          )}
        </Button>
      </div>
    </div>
  );
};

export default AddReplicationRulePrompt;
